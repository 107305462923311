export default {
  PLURAL_DAYS: 'days',
  SINGULAR_DAYS: 'day',
  HARVESTED_AT: 'Harvested %{daysQuantity} %{daysWord} ago',
  MONITORED_AT: 'Monitored %{daysQuantity} %{daysWord} ago',
  EXECUTED_AT: 'Executed to farm %{daysQuantity} %{daysWord} ago',
  MESSAGE_1: 'You have a ',
  MESSAGE_2: 'pending validation ',
  MESSAGE_3: 'in the ',
  MESSAGE_4: 'activity %{activityType} ',
  MESSAGE_5: 'of %{surface} %{areaUnit}',
  SINGULAR_FIELDS: 'field',
  PLURAL_FIELDS: 'fields',
  VERIFY_VALUE_MESSAGE: 'Check the values entered',
}
