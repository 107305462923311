const verificationStatus = {
  VALID: {
    key: 'VALID',
    name: (t) => t('CONSTANTS').VERIFICATION_STATUS.VALID,
  },
  INVALID: {
    key: 'INVALID',
    name: (t) => t('CONSTANTS').VERIFICATION_STATUS.INVALID,
  },
}

export default verificationStatus
