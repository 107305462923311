import EMPTY_STATE_LICENSES from '../../components/LicensesEmpty/translate/en'
import CONTENT_BACKDROP_CROP_TYPES from '../../components/ContentBackDropCropTypes/translate/en'

export default {
  ALL: 'All',
  COMPONENTS: {
    EMPTY_STATE_LICENSES,
    CONTENT_BACKDROP_CROP_TYPES,
  },
}
