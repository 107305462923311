export default {
  TEXT_1: 'Planejada',
  TEXT_2: 'Desde',
  TEXT_3: 'Até',
  TEXT_4: '%{establishments} Campos %{lots} lotes',
  TEXT_5: 'Área planejada: %{surface} %{areaUnit}',
  TEXT_6: 'Tipo de %{activityType} : %{subTypeActivity}',
  TEXT_7: 'EIQ Planejado %{eiq}',
  TEXT_8: 'ENTRADA',
  TEXT_9: 'SUPRIMENTOS',
}
