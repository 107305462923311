import ENTER_PIN from '../../components/EnterPin/translate/es'
import CONFIRM_PIN from '../../components/ConfirmPin/translate/es'
import WIZARD_PIN from '../../components/WizardPin/translate/es'

export default {
  COMPONENTS: {
    ENTER_PIN,
    CONFIRM_PIN,
    WIZARD_PIN,
  },
  NEXT: 'Continuar',
}
