export default {
  TEXT_1: 'Sem permissões da câmera',
  TEXT_2: 'Por favor vá para as configurações e ative manualmente',
  TEXT_3: 'Cancelar',
  TEXT_4: 'Permitir',
  TEXT_5:
    'O arquivo que você está tentando carregar não é um tipo de formato KMZ ou foi danificado.',
  TEXT_6: 'As extençóes permitidas seriam .doc,.docx,.pdf,.png,.jpg,.zip',
  TEXT_7: 'Extenção do arquivo não é permitida',
  TEXT_8: 'ACEITAR',
  TEXT_9: 'UCROP.IT precisa permissões de localização',
  TEXT_10: 'Documentos',
  TEXT_11: 'câmera',
  TEXT_12: 'Painel',
  TEXT_13: 'Agregar evidência',
  TEXT_14: 'Procurando georreferenciamento...',
  TEXT_15: 'Por favor, não saia desta tela',
}
