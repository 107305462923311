import { gray400 } from '@common/styles'
import {
  alertText,
  carbon100,
  carbon300,
  carbon50,
  cherry100,
  cherry50,
  white,
} from '@styles/palette'
import { Platform } from 'react-native'
import StyleSheet from 'react-native-media-query'

export const getStyles = (connected: boolean, isSynchronized: boolean) => {
  if (Platform.OS === 'web') {
    if (!connected) {
      return {
        button: styles.button,
        label: styles.textButton,
        id: ids.button,
      }
    } else if (isSynchronized) {
      return {
        button: styles.disconnectedButton,
        label: styles.textDisconnectedButton,
        id: ids.disconnectedButton,
      }
    } else {
      return {
        button: styles.disabledButton,
        label: styles.textDisabledButton,
        id: ids.disabledButton,
      }
    }
  } else {
    if (!connected) {
      return {}
    } else if (isSynchronized) {
      return {
        button: styles.disconnectedMobileButton,
        label: styles.textDisconnectedMobiledButton,
        id: ids.disconnectedButton,
      }
    } else {
      return {
        button: styles.disabledMobileButton,
        label: styles.textDisabledMobileButton,
        id: ids.disabledButton,
      }
    }
  }
}

const { styles, ids } = StyleSheet.create({
  headContainer: {
    '@media (min-width: 1300px)': {
      width: 740,
    },
    '@media  (min-width: 620px) and (max-width: 960px) ': {
      width: 600,
    },
  },
  container: {
    flex: 1,
    paddingRight: 24,
    paddingHorizontal: 24,
    paddingTop: 20,
    backgroundColor: carbon50,
    paddingLeft: 180,
    '@media (max-width: 960px)': {
      paddingLeft: 24,
    },
  },
  containerMobile: {
    flex: 1,
    paddingRight: 24,
    paddingLeft: 16,
    paddingHorizontal: 24,
    paddingTop: 20,
    backgroundColor: carbon50,
  },
  providerImage: {
    width: 64,
    height: 64,
  },
  name: {
    fontSize: 24,
    marginTop: 16,
    fontWeight: '700',
    lineHeight: 32,
  },
  category: {
    color: gray400,
    fontWeight: '400',
    marginTop: 8,
    lineHeight: 24,
  },
  description: {
    fontSize: 16,
    color: '#000',
    fontWeight: '400',
    marginTop: 16,
    lineHeight: 24,
  },
  button: {
    width: 224,
    height: 38,
    top: '5%',
  },
  textButton: {
    color: white,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 30,
    textAlign: 'center',
  },
  disabledButton: {
    backgroundColor: carbon100,
    borderWidth: 1,
    right: 0,
    width: 224,
    height: 38,
  },
  textDisabledButton: {
    color: carbon300,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 30,
    textAlign: 'center',
  },
  disconnectedButton: {
    backgroundColor: cherry50,
    borderColor: cherry100,
    borderWidth: 1,
    right: 0,
    width: 224,
    height: 38,
  },
  textDisconnectedButton: {
    color: alertText,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 30,
    textAlign: 'center',
  },
  disabledMobileButton: {
    backgroundColor: carbon100,
    borderWidth: 1,
    right: 0,
  },
  textDisabledMobileButton: {
    color: carbon300,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 30,
    textAlign: 'center',
  },
  disconnectedMobileButton: {
    backgroundColor: cherry50,
    borderColor: cherry100,
    borderWidth: 1,
  },
  textDisconnectedMobiledButton: {
    color: alertText,
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 24,
    textAlign: 'center',
  },
})
