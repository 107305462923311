import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useNavigation } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { getFormikInitialValues, getFormikValidationSchema } from '../utils'
import { CommonContext } from '@contextState/common'
import useModal from '@hooks/useModal'
import useNetwork from '@utils/network'

const useSignUp = ({ route, t }) => {
  const navigation = useNavigation()
  const { params } = route
  const { email } = params
  const { doRequest } = useNetwork()
  const { countries } = useContext(CommonContext)
  const [errorEmail, setErrorEmail] = useState(null)
  const [disabledEmail, setDisabledEmail] = useState(false)

  useEffect(() => {
    if (email) {
      setDisabledEmail(true)
      onEndEditingEmail(email).catch(console.error)
    }
  }, [email])

  const { isModalVisible, toggleModal, closeModal } = useModal()

  const firstCountry = countries[1] ?? {
    value: '',
    label: '',
  }
  const formik = useFormik({
    initialValues: getFormikInitialValues(firstCountry, email),
    validationSchema: getFormikValidationSchema(t),
    enableReinitialize: true,
    onSubmit: () => {
      toggleModal()
    },
  })

  const { values, setSubmitting } = formik

  const acceptTerms = async () => {
    try {
      closeModal()
      const data = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        alpha3Code: values.valueCodeArea.alpha3Code,
        phone: values.phoneNumber,
      }
      const response = await doRequest({
        method: 'POST',
        url: 'auth/create-account',
        data,
        version: 'v2',
      })
      if (response) {
        await AsyncStorage.setItem('REGISTER_EMAIL', values.email)
        navigation.replace('VerifyCode')
      }
      setSubmitting(false)
    } catch (error) {
      console.error('Error SignUp', error)
      setSubmitting(false)
    }
  }
  const onEndEditingEmail = async (email = '') => {
    try {
      email = values.email ?? email
      await doRequest({
        method: 'POST',
        url: 'auth/validate',
        displayAlert: false,
        data: { email },
      })
    } catch (error) {
      if (error.code === 'ERR_USER_CONFIRM_REGISTRATION') {
        setDisabledEmail(true)
      } else {
        setDisabledEmail(false)
      }
      if (error.status === 401) {
        setErrorEmail(t('ERRORS.FORM_ERRORS.EMAIL_EXIST'))
      } else {
        setErrorEmail(null)
      }
      console.error('error validate email', error)
    }
  }

  const closeModalReject = () => {
    setSubmitting(false)
    closeModal()
    navigation.goBack()
  }

  const closeModalTerms = () => {
    setSubmitting(false)
    closeModal()
  }

  return {
    isModalVisible,
    closeModal,
    formik,
    acceptTerms,
    onEndEditingEmail,
    errorEmail,
    closeModalReject,
    closeModalTerms,
    disabledEmail,
  }
}

export default useSignUp
