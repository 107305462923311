import React, { useState, useContext, useEffect, useRef } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { round } from 'lodash'
import moment from 'moment'
import { PropTypes } from 'prop-types'

import { CropContext } from '@contextState/crop'
import { LicenseContext } from '@contextState/license'
import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import ChartLots from '@components/licenses/v1/ChartLots'
import SuccessPinModal from '@components/modals/SuccessPinModal'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import Accordion from '@components/common/v1/Accordion'
import useModal from '@hooks/useModal'
import { SURFACE_UNIT } from '@constants'
import { convertNumbertoString } from '@utils/number'
import {
  primary500,
  gray1,
  grayBackground,
  gray12,
  blackHighEmphasis,
  blackMediumEmphasis,
  white,
} from '@styles/palette'
import { green500 } from '@common/styles/palette'
import RouterNames from '@constants/routerNames'

const SummaryLicenseLots = ({ navigation, route }) => {
  const { t } = useContext(LanguageContext)
  const { currentCrop, setCurrentCropId } = useContext(CropContext)
  const { licenseSelected } = useContext(LicenseContext)

  const [cropName, setCropName] = useState(null)
  const [currentEstablishments, setCurrentEstablishments] = useState(null)
  const [isLoading, setIsloading] = useState(true)
  const areaUnit = useRef(SURFACE_UNIT.HA)

  const { isModalVisible, toggleModal, closeModal } = useModal()

  const {
    cropId,
    lots,
    establishments,
    hasSelected,
    companyIdentifier,
    cropNameText,
  } = route.params

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    setCurrentCropId(cropId)

    const newEstablishments = establishments
      .map((establishment) => {
        if (
          establishment.lots.filter((lot) => lot.selected || lot.licenseApplied)
            .length
        ) {
          return establishment
        }
      })
      .filter((establishment) => establishment)
    areaUnit.current = newEstablishments[0]?.areaUnit ?? ''
    setCurrentEstablishments(newEstablishments)
    setIsloading(false)
  }, [])

  /*
  CROP NAME
  */
  useEffect(() => {
    setCropName(
      `${currentCrop.cropType.codeLabel} - ${moment(
        currentCrop.dateCrop
      ).format('MM/YY')} a ${moment(currentCrop.dateHarvest).format(
        'MM/YY'
      )} - ${currentCrop.company.name}`
    )
  }, [currentCrop])

  /**
   * GO TO LICENSES
   */
  const goToLicenses = () => {
    closeModal()
    navigation.navigate(RouterNames.LICENSES_LIST, {
      cropId,
    })
  }

  /**
   * GO TO HOME
   */
  const goToHome = () => {
    closeModal()
    navigation.navigate(RouterNames.CROPS)
  }

  /**
   * ON PRESS SUBMIT BUTTON
   */
  const onPressSubmitButton = () => {
    if (!licenseSelected.hasSubLicenses) {
      toggleModal()
    } else {
      navigation.navigate(RouterNames.LICENSES_SUB_LICENSE_SELECT_COMPANIES, {
        lots: lots,
        lotsSelected: lots.length,
        has: String(round(hasSelected, 2)),
        licenseId: licenseSelected._id,
        cropId,
        companyIdentifier,
        cropNameText,
      })
    }
  }

  return (
    <SafeAreaProvider style={styles.safeAreaProvider}>
      {!isLoading ? (
        <>
          <ScrollView style={styles.container}>
            <Text style={[styles.titleStyle, styles.titleLicense]}>
              {cropName}
            </Text>
            {Boolean(licenseSelected) &&
              Boolean(licenseSelected?.normative) && (
                <View style={styles.containerText}>
                  <Text style={styles.textInformation}>
                    {t('VIEWS').SUMMARY_LICENSE_LOTS.TEXT_3}
                  </Text>
                </View>
              )}
            <View>
              <ChartLots
                percent={(hasSelected / hasSelected) * 100}
                lots={hasSelected}
                total={hasSelected}
                style={styles.containerCharts}
                areaUnit={areaUnit.current}
              />
            </View>
            <Text style={[styles.titleStyle, styles.subtitleLicense]}>
              {lots.length > 1
                ? t('VIEWS.SUMMARY_LICENSE_LOTS.TEXT_5', {
                    lots: lots.length,
                    has: String(round(hasSelected, 2)),
                    areaUnit: `${areaUnit.current}`,
                  })
                : t('VIEWS.SUMMARY_LICENSE_LOTS.TEXT_6', {
                    lots: lots.length,
                    has: String(round(hasSelected, 2)),
                    areaUnit: `${areaUnit.current}`,
                  })}
            </Text>

            <View>
              {currentEstablishments.map((establishment, key) => {
                return (
                  <Accordion
                    key={key}
                    style={styles.accordion}
                    expanded
                    title={establishment.tag}
                    titleStyle={{ color: primary500 }}
                  >
                    {establishment.lots
                      .filter((lot) => lot.selected || lot.licenseApplied)
                      .map((lot, lotKey) => (
                        <View key={lotKey} style={styles.lotTexts}>
                          <Text style={styles.lotName}>{lot.name}</Text>
                          <Text style={styles.lotSurface}>
                            {`${convertNumbertoString(round(lot.surface, 2))} ${
                              lot?.areaUnit ?? ''
                            }.`}
                          </Text>
                          <Text style={styles.lotLocation}>
                            {`${lot?.cityName ?? ''}, ${
                              lot?.provinceName ?? ''
                            }`}
                          </Text>
                        </View>
                      ))}
                  </Accordion>
                )
              })}
            </View>
          </ScrollView>

          <ButtonCustom styles={styles.button} onPress={onPressSubmitButton}>
            <Text>
              {!licenseSelected.hasSubLicenses
                ? t('VIEWS').SUMMARY_LICENSE_LOTS.TEXT_2
                : t('VIEWS').SUMMARY_LICENSE_LOTS.TEXT_4}
            </Text>
          </ButtonCustom>
        </>
      ) : (
        <ActivityIndicator
          size='large'
          color={green500}
          style={styles.loader}
        />
      )}

      <SuccessPinModal
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        title={t('VIEWS').LICENSE_LOTS_ADD.TEXT_10}
      >
        <View style={styles.contentInfo}>
          <View style={styles.contentTextInfo}>
            <Text style={styles.textInfo}>
              {t('VIEWS').LICENSE_LOTS_ADD.TEXT_11}
            </Text>

            <Text style={styles.textInfo}>
              {t('VIEWS').LICENSE_LOTS_ADD.TEXT_12}
            </Text>
          </View>

          <View style={styles.buttonsContainer}>
            <ButtonPrimary
              text={t('VIEWS').LICENSE_LOTS_ADD.TEXT_13}
              onPress={goToLicenses}
            />
            <ButtonPrimary
              marginTop={40}
              text={t('VIEWS').LICENSE_LOTS_ADD.TEXT_14}
              backgroundColor='transparent'
              colorText={white}
              fontSize={14}
              onPress={goToHome}
            />
          </View>
        </View>
      </SuccessPinModal>
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  safeAreaProvider: {
    backgroundColor: white,
  },
  container: {
    flex: 1,
  },
  loader: {
    marginTop: 100,
  },
  containerCharts: {
    backgroundColor: grayBackground,
    paddingVertical: 20,
  },
  accordion: {
    paddingVertical: 10,
    marginLeft: 50,
    paddingRight: 16,
    backgroundColor: white,
    borderBottomWidth: 1,
    borderBottomColor: gray1,
    marginBottom: 16,
  },
  lotTexts: {
    flex: 1,
    paddingLeft: 50,
    backgroundColor: grayBackground,
    paddingVertical: 16,
    borderBottomColor: gray12,
    borderBottomWidth: 1,
  },
  lotName: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 5,
  },
  lotSurface: {
    fontSize: 14,
    color: blackHighEmphasis,
    marginBottom: 5,
  },
  lotLocation: {
    fontSize: 14,
    color: blackHighEmphasis,
  },
  button: {
    marginTop: 30,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: primary500,
  },
  titleLicense: {
    color: blackHighEmphasis,
  },
  subtitleLicense: {
    color: blackMediumEmphasis,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingVertical: 16,
    paddingHorizontal: 20,
    backgroundColor: white,
  },
  contentInfo: {
    marginTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentTextInfo: {
    width: 200,
    textAlign: 'center',
    marginTop: 20,
  },
  textInfo: {
    color: white,
    fontSize: 16,
    textAlign: 'center',
  },
  buttonsContainer: {
    marginTop: 50,
    width: 300,
  },
  containerText: {
    backgroundColor: grayBackground,
    paddingHorizontal: 20,
    paddingVertical: 16,
    marginBottom: 8,
  },
  textInformation: {
    color: blackMediumEmphasis,
    fontSize: 14,
  },
})

SummaryLicenseLots.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default SummaryLicenseLots
