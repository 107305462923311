import React, { useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { LanguageContext } from '@contextState/language'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { PropTypes } from 'prop-types'
import { white } from '@styles/palette'

export const ChildrenModalSuccessLicence = ({
  onPressFirstButton,
  numberLicense,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.contentInfo}>
      <Text style={styles.textInfo}>
        {t('VIEWS.LICENSE_SIGN_V2.NUMBER_LICENSE', { numberLicense })}
      </Text>

      <View style={styles.contentButtons}>
        <ButtonDefault
          text={t('VIEWS.LICENSE_SIGN_V2.BACK_TO_LICENSES')}
          onPress={onPressFirstButton}
          typeButton='inline'
          borderRadius={8}
          height={48}
          fontSize={14}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentInfo: {
    marginTop: 41,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentTextInfo: {
    width: 200,
    textAlign: 'center',
    marginTop: 20,
  },
  textInfo: {
    color: white,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: '500',
    letterSpacing: 0.15,
  },
  contentButtons: {
    marginTop: 61,
    width: 300,
  },
})

ChildrenModalSuccessLicence.propTypes = {
  onPressFirstButton: PropTypes.func.isRequired,
  numberLicense: PropTypes.string.isRequired,
}
