import { View, StyleSheet, Text } from 'react-native'
import { RadioButton } from 'react-native-paper'
import { gray200, gray50 } from '@common/styles'
import InputText from '@components/inputs/InputText'
import { InputChemicalTextProps } from './InputChemicalText.props'
import { carbon800 } from '@styles/palette'

export const InputChemicalText = ({
  option,
  optionValue,
  text,
  onPress,
  widthBorder = false,
  textValue,
  onChangeText,
}: InputChemicalTextProps) => {
  const stylesBorder = widthBorder ? styles.withBorder : {}
  const styleDisabled = option !== optionValue ? styles.disabled : {}
  return (
    <View style={styles.contentInput}>
      <RadioButton.Android
        value={option}
        color={carbon800}
        status={option === optionValue ? 'checked' : 'unchecked'}
        onPress={(value: any) => {
          onPress(value)
        }}
      />
      <View style={[styles.contentInputText, stylesBorder]}>
        <Text style={styles.textDescription}>{text}</Text>
        <InputText
          placeholder='0.00'
          inputContainerStyle={{
            ...styles.inputContainerStyle,
            ...styleDisabled,
          }}
          inputStyle={styles.inputStyle}
          containerStyle={styles.inputContainerStyle}
          disabled={option !== optionValue}
          editable={option === optionValue}
          keyboardType='decimal-pad'
          value={textValue}
          onChangeText={onChangeText}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentInput: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentInputText: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: 16,
    marginLeft: 8,
    paddingVertical: 8,
  },
  inputStyle: {
    paddingHorizontal: 8,
    textAlign: 'right',
    width: 76,
    height: 40,
    fontWeight: '500',
    color: carbon800,
    fontFamily: 'Inter',
  },
  withBorder: {
    borderBottomWidth: 1,
    borderBottomColor: gray200,
  },
  inputContainerStyle: {
    width: 76,
    height: 40,
    backgroundColor: gray50,

    borderRadius: 4,
  },
  disabled: {
    borderColor: gray50,
  },
  textDescription: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '600',
    color: carbon800,
    fontFamily: 'Inter',
  },
})
