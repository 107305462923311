import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { white } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import IconLoading from '../IconLoading'

const FullLoaderCommon = () => {
  const { t } = React.useContext(LanguageContext)
  return (
    <View style={styles.content}>
      <View style={styles.contentLoading}>
        <IconLoading width={100} height={100} style={styles.icon} />
      </View>
      <View style={styles.contentText}>
        <Text style={styles.textInfo}>
          {t('VIEWS.COMMON_LOADER.COMPONENTS.FULL_LOADER_COMMON.TEXT_INFO_1')}
        </Text>
        <Text style={[styles.textInfo, styles.marginTop]}>{`\n${t(
          'VIEWS.COMMON_LOADER.COMPONENTS.FULL_LOADER_COMMON.TEXT_INFO_2'
        )}`}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: white,
  },
  contentLoading: {
    marginVertical: 111,
  },
  contentText: {
    paddingHorizontal: 16,
  },
  textInfo: {
    textAlign: 'center',
    fontSize: 16,
  },
  marginTop: {
    // marginTop: 30,
  },
})

export default FullLoaderCommon
