import { useEffect, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import {
  GlobalStateInterface,
  SelectionPageReducerInterface,
} from '@store/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { resetDataSelection } from '@store/slices'

interface RouteParams {
  multipleSelection?: boolean | string
  textButton?: string
  isViewReadOnly?: boolean | string
}

export const useCommonItemsSelect = () => {
  const dispatch = useDispatch()
  const { params } = useRoute()
  const {
    items,
    loading,
    callback,
    fieldNameRender,
    itemsSelected: itemsSelectedRedux,
    fieldIsSelected,
  }: SelectionPageReducerInterface = useSelector(
    (state: GlobalStateInterface) => state.selectionPageReducer
  )
  const { multipleSelection, textButton, isViewReadOnly } =
    params as RouteParams
  const isMultiple = multipleSelection === true || multipleSelection === 'true'
  const [itemsSelected, setItemsSelected] = useState<any[]>([])

  useEffect(() => {
    if (itemsSelectedRedux) {
      setItemsSelected(itemsSelectedRedux)
    }
    return () => {
      dispatch(resetDataSelection())
    }
  }, [itemsSelectedRedux])

  const onSelectItem = (item: (typeof items)[number]) => {
    if (isViewReadOnly) {
      return
    }
    setItemsSelected((prevSelectedItems) => {
      const itemIndex = prevSelectedItems.findIndex(
        (i) => i[fieldIsSelected] === item[fieldIsSelected]
      )
      if (itemIndex >= 0) {
        return isMultiple
          ? prevSelectedItems.filter(
              (i) => i[fieldIsSelected] !== item[fieldIsSelected]
            )
          : prevSelectedItems
      } else {
        return isMultiple ? [...prevSelectedItems, item] : [item]
      }
    })
  }

  const onSubmit = () => {
    if (callback) {
      callback(itemsSelected)
    }
  }

  return {
    items,
    isLoading: loading,
    onSelectItem,
    itemsSelected,
    isMultiple,
    onSubmit,
    fieldNameRender,
    textButton,
    isViewReadOnly,
    fieldIsSelected,
  }
}
