import { lots as type } from '../types'

const initialState = {
  lotsUnsynchronized: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_LOTS_UNSYNCHRONIZED: {
      return {
        ...state,
        lotsUnsynchronized: [...state.lotsUnsynchronized, action.payload],
      }
    }

    case type.CLEAR_LOTS_UNSYNCHRONIZED_BY_DRAFT_ID: {
      return {
        ...state,
        lotsUnsynchronized: state.lotsUnsynchronized.filter(
          (element) => element.draft !== action.payload.draft
        ),
      }
    }

    case type.CLEAR_LOTS_UNSYNCHRONIZED: {
      return {
        ...state,
        lotsUnsynchronized: initialState.lotsUnsynchronized,
      }
    }

    default:
      return state
  }
}

export default reducer
