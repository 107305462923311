import React from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import MobileWebPicker from './MobileWebPicker'
import WebDatePicker from './WebDatePicker'

function CustomDatePicker(props) {
  return (
    <View style={[props.containerstyles, styles.row]}>
      {Platform.OS === 'web' ? (
        <WebDatePicker {...props} />
      ) : (
        <MobileWebPicker {...props} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  row: { flexDirection: 'row' },
})

export default CustomDatePicker
