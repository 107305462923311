import { Animated, Platform, Text, View, StyleSheet } from 'react-native'
import React from 'react'
import {
  blackHighEmphasis,
  gray1,
  gray36,
  placeholderColor,
  placeholderDisabled,
  primary500,
  redError,
  white,
} from '@styles/palette'
import { isEmptyValue } from '@utils/common'
import { formatDateMoment } from '@utils/date'
import { Input } from 'react-native-elements'

export const CustomInputDate = ({
  containerStyle,
  id,
  placeholderOpacity,
  touched,
  error,
  isFocused,
  disabled,
  label,
  placeholder,
  inputStyle,
  leftIcon,
  rightIcon,
  value,
  inputRef,
  locale,
}) => {
  return (
    <View style={containerStyle}>
      {(label || placeholder) && (
        <Animated.View
          style={[
            styles.placeholderContainer,
            {
              opacity: placeholderOpacity,
            },
          ]}
        >
          <Text
            style={[
              styles.placeholder,
              touched && error ? styles.placeholderError : {},
              isFocused ? styles.focusedPlaceholder : {},
              disabled ? styles.placeholderDisabled : {},
            ]}
          >
            {label ?? placeholder}
          </Text>

          <View style={styles.placeholderBackground}></View>
        </Animated.View>
      )}

      {Platform.OS === 'web' ? (
        <Input
          id={id}
          name={name}
          inputContainerStyle={[
            styles.inputContainer,
            touched && error ? styles.borderError : {},
            isFocused ? styles.focusedInput : {},
          ]}
          inputStyle={[styles.input, inputStyle]}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          value={formatDateMoment(value.value, 'DD/MMM/YYYY', locale, true)}
          ref={inputRef}
          editable={false}
          disabled={disabled}
          placeholder={placeholder}
          placeholderTextColor='rgba(51, 51, 51, 0.7)'
          errorStyle={styles.textError}
          errorMessage={touched && error}
        />
      ) : (
        <>
          <View
            style={[
              styles.textContainer,
              touched && error ? styles.borderError : {},
              isFocused ? styles.focusedInput : {},
            ]}
          >
            <Text
              style={[
                inputStyle,
                isEmptyValue(value.label)
                  ? styles.textPlaceholder
                  : styles.text,
                disabled ? styles.placeholderDisabled : {},
              ]}
            >
              {isEmptyValue(value.label)
                ? label ?? placeholder
                : formatDateMoment(value.value, 'DD/MMM/YYYY', locale, true)}
            </Text>
          </View>

          {touched && error && (
            <Text style={styles.textErrorCustom}>{error}</Text>
          )}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: white,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: gray1,
    borderRadius: 2,
    height: 62,
    paddingHorizontal: 16,
    marginHorizontal: -10,
    zIndex: 1,
  },
  borderError: {
    borderColor: redError,
  },
  textError: {
    color: redError,
    fontSize: 12,
  },
  textErrorCustom: {
    color: redError,
    fontSize: 12,
    marginTop: -14,
    marginBottom: 8,
    marginLeft: 16,
  },
  input: {
    fontSize: 15,
  },
  textContainer: {
    backgroundColor: white,
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: gray1,
    borderRadius: 2,
    marginBottom: 20,
    zIndex: 1,
  },
  text: {
    color: gray36,
    paddingVertical: 20,
    paddingHorizontal: 16,
    fontSize: 15,
  },
  focusedInput: {
    borderColor: primary500,
  },
  placeholderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    marginLeft: 10,
    marginTop: -9,
    opacity: 0,
  },
  placeholder: {
    fontSize: 12,
    color: blackHighEmphasis,
    zIndex: 4,
    marginHorizontal: 5,
  },
  textPlaceholder: {
    color: placeholderColor,
    fontWeight: '400',
    paddingVertical: 20,
    paddingHorizontal: 16,
    fontSize: 15,
  },
  placeholderError: {
    color: redError,
  },
  placeholderDisabled: {
    color: placeholderDisabled,
  },
  focusedPlaceholder: {
    color: primary500,
  },
  placeholderBackground: {
    backgroundColor: white,
    position: 'absolute',
    width: '100%',
    height: 4,
    zIndex: 3,
    marginTop: 8.75,
  },
})
