import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { primary50, primary500 } from '@styles/palette'

const TextNewCompany = () => {
  return (
    <View style={styles.contenText}>
      <Text style={styles.textCompany}>NUEVA EMPRESA</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  contenText: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: primary50,
    borderRadius: 3,
    width: 100,
    height: 16,
  },
  textCompany: {
    color: primary500,
    fontSize: 10,
    fontWeight: 'bold',
  },
})

export default TextNewCompany
