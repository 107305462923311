import { FC, useContext, ReactElement, useEffect } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import InputText from '@components/inputs/InputText'
import { CommonIcon } from '@modules/common/components'
import { SIZES } from '@common/components/icons/interfaces'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { EEvidenceConcepts } from '@common/enum'
import {
  black,
  green100,
  green200,
  green300,
  green500,
  green700,
  gray300,
  gray500,
} from '@common/styles'
import { useSocket } from '@hooks/index'
import { buildEstablishmentsToSelector, truncateSurface } from '@common/utils'
import {
  GlobalStateInterface,
  SelectionPageReducerInterface,
} from '@store/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { makeRequest } from '@common/services'
import useOfflineCommon from '@offline/queries/common'
import {
  checkedSelectedItem,
  disabledItems,
  replaceItem,
  resetDataSelection,
  selectAllSubItems,
  setItemsSelection,
  startDataSelection,
} from '@store/slices'
import { LotInterface } from '@modules/activities/interfaces'
import { HeaderFieldSelection } from '@modules/activities/components'
import Constants from 'expo-constants'
import { useAuth } from '@common/hooks'

export const StatementForm: FC<any> = ({
  formik,
  areaUnit,
  navigateToForm,
  readOnly,
}: any): ReactElement => {
  const { t } = useContext(LanguageContext)
  const { user } = useAuth()
  const { evidenceConcept: evidenceConcepts }: any = useContext(CommonContext)
  const { connect, disconnect, on } = useSocket({
    url:
      process.env.MS_STATEMENTS_URL ??
      Constants.expoConfig?.extra?.MS_STATEMENTS_URL,
  })
  const navigation: any = useNavigation()
  const route = useRoute()
  const { params }: any = route
  const isFinished = params?.isFinished

  const { values, setFieldValue, setFieldTouched, touched, errors } = formik

  console.log('values')
  console.log(values)

  const evidenceConceptName = evidenceConcepts.find(
    ({ code }: any) => code === EEvidenceConcepts['0040']
  ).label

  useEffect(() => {
    connect()

    on(user.email, (data: any) => {
      setFieldValue('formResponse', data.formResponse.form)

      navigation.goBack()
    })

    return () => {
      disconnect()
    }
  }, [])

  useEffect(() => {
    if (values.draftId || values.activityId || isFinished) {
      dispatch(
        startDataSelection({
          itemsSelected: values.lots?.value ?? [],
          checkDisableItems: true,
        })
      )
    } else {
      setFieldValue('signers', [
        {
          userId: user._id,
          fullName: `${user.firstName} ${user.lastName}`,
          email: user.email,
          type: user?.config?.roleSelected?.value,
          identifier: user?.config?.companySelected?.identifier,
          selected: true,
        },
      ])
    }

    return () => {
      dispatch(resetDataSelection())
    }
  }, [values.draftId, values.activityId, values.typeAgreement])

  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )
  const { itemsSelected }: SelectionPageReducerInterface = useSelector(
    (state: GlobalStateInterface) => state.selectionPageReducer
  )

  const { showIndividuals } = useOfflineCommon()

  const dispatch = useDispatch()

  const onClickField = (item: any, indexFarm: number, indexField: number) => {
    const selected = !item.selected

    dispatch(
      replaceItem({
        item: { ...item, selected },
        indexItem: indexFarm,
        indexSubItem: indexField,
        checkDisabled: true,
      })
    )

    dispatch(
      disabledItems({
        indexItem: indexFarm,
      })
    )

    dispatch(
      checkedSelectedItem({
        indexItem: indexFarm,
      })
    )
  }

  const onClickFarm = (indexFarm: number) => {
    dispatch(
      selectAllSubItems({
        indexItem: indexFarm,
      })
    )
    dispatch(
      disabledItems({
        indexItem: indexFarm,
      })
    )
  }

  const callback = (lots: LotInterface[], totalSurface: number) => {
    const newLabel =
      lots.length > 1
        ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
        : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')
    const farmUuid = lots[0].farmUuid
    const lotsSend = lots.map((lot) => {
      const {
        ElementRender,
        onClickElement,
        centerBound,
        centerBoundGoogle,
        centroid,
        geometryData,
        ...newLot
      } = lot
      return newLot
    })

    setFieldValue('lots', {
      value: lotsSend,
      label: `${lots.length} ${newLabel}`,
    })

    setFieldValue('surface', String(totalSurface))

    navigation.pop(1)
  }

  const onPressLotsInput = async () => {
    const activityId = values.activityId
    let farmsAndFields: any = []

    if (isConnected) {
      const response = await makeRequest({
        method: 'GET',
        url: `crops/${values.cropId}/establishments`,
        params: {
          cropId: values.cropId,
          activityId: activityId,
        },
      })
      farmsAndFields = response.data
    } else {
      const cropResponse = await showIndividuals('crops', values.cropId)

      if (activityId) {
        const offlineActivityData = await showIndividuals(
          'activities',
          activityId
        )
        farmsAndFields = cropResponse.establishments.map((element: any) => ({
          ...element,
          lots: element.lots.filter((lot: any) =>
            offlineActivityData.lots.some(
              (lotActivity: any) => lotActivity._id === lot._id
            )
          ),
        }))
      } else {
        farmsAndFields = cropResponse.establishments.map((element: any) => ({
          ...element,
          lots: element.lots,
        }))
      }
    }

    if (farmsAndFields) {
      const allDisabled = Boolean(activityId)
      const result = buildEstablishmentsToSelector({
        fields: farmsAndFields,
        itemsSelected,
        onClickField,
        onClickFarm,
        allDisabled,
      })

      dispatch(
        setItemsSelection({
          items: result,
          callback: callback,
          HeaderRender: HeaderFieldSelection,
          fieldSum: 'surface',
        })
      )

      // @ts-ignore
      navigation.navigate('SelectItemsWithMultiLevels', {
        title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_7'),
        textButton: t('VIEWS.LOTS_ADD.TEXT_8'),
        isViewReadOnly: isFinished,
      })
    }
  }

  const RenderFormContainer = () => {
    if (readOnly) {
      return null
    }

    return !values.formResponse?.length ? (
      <View style={styles.generateEmissionBeforeSendContainer}>
        <Text style={styles.generateEmissionBeforeSendTitle}>
          {t('VIEWS.ACTIVITY_CREATE.STATEMENT_FORM.DECLARATIVE_REPORT')}
        </Text>

        <Text style={styles.generateEmissionBeforeSendText}>
          {t('VIEWS.ACTIVITY_CREATE.STATEMENT_FORM.COMPLETE_THE_FORM')}
        </Text>

        <ButtonPrimary
          text={t('VIEWS.ACTIVITY_CREATE.STATEMENT_FORM.COMPLETE_FORM')}
          onPress={() => navigateToForm({ url: values.typeAgreement.formLink })}
          backgroundColor={'transparent'}
          disabled={Boolean(!values.lots?.value?.length)}
          colorText={values.lots?.value?.length ? green500 : green300}
          marginTop={8}
        />
      </View>
    ) : (
      <View style={styles.generateEmissionAfterSendContainer}>
        <View style={styles.generateEmissionAfterSendIconContainer}>
          <CommonIcon name='CLOCK' size={SIZES.MEDIUM} />
        </View>

        <View style={styles.generateEmissionAfterSendTextContainer}>
          <Text style={styles.generateEmissionAfterSendTitle}>
            {evidenceConceptName}
          </Text>

          <Text style={styles.generateEmissionAfterSendText}>
            {t(
              'VIEWS.ACTIVITY_CREATE.STATEMENT_FORM.BY_VALIDATING_THE_DECLARATION'
            )}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <>
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={SIZES.MEDIUM}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={t('VIEWS.ACTIVITY_CREATE.STATEMENT_FORM.SELECT_FIELDS')}
        label={t('VIEWS.ACTIVITY_CREATE.STATEMENT_FORM.SELECT_FIELDS')}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.FIELD_2.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.FIELD_2.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <RenderFormContainer />
    </>
  )
}

StatementForm.displayName = 'StatementForm'

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
  generateEmissionBeforeSendContainer: {
    borderColor: green200,
    borderWidth: 1,
    backgroundColor: green100,
    borderRadius: 8,
    padding: 24,
    marginVertical: 8,
  },
  generateEmissionBeforeSendTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: green700,
  },
  generateEmissionBeforeSendText: {
    fontSize: 14,
    fontWeight: '400',
    color: green700,
    marginTop: 8,
  },
  generateEmissionAfterSendContainer: {
    flexDirection: 'row',
    paddingTop: 24,
    paddingHorizontal: 12,
    marginVertical: 8,
  },
  generateEmissionAfterSendIconContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 55,
  },
  generateEmissionAfterSendTextContainer: {
    borderBottomColor: gray300,
    borderBottomWidth: 1,
    paddingBottom: 24,
    flex: 1,
  },
  generateEmissionAfterSendTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: black,
  },
  generateEmissionAfterSendText: {
    fontSize: 14,
    fontWeight: '400',
    color: gray500,
    marginTop: 8,
  },
})
