import endpointMocks from './mocks'

type RequestOptions = {
  method: string
  url: string
  useMock?: boolean
  identifier?: string
  [key: string]: any
}

type DoRequestFunction = (options: RequestOptions) => Promise<any>

/**
 * Creates a network adapter object that can be used to make HTTP requests.
 * @param doRequestFunction - The function that performs the actual HTTP request.
 * @returns An object with a `doRequest` method that can be used to make HTTP requests.
 */
const networkAdapter = (doRequestFunction: DoRequestFunction) => {
  const doAdaptedRequest = async (options: RequestOptions) => {
    const { url, identifier = 'identifier', useMock = false, ...rest } = options

    if (useMock) {
      // Aquí puedes usar un mapa de endpoints a mocks o cualquier otra lógica para decidir qué mock devolver.
      const mockData = endpointMocks[identifier]
      return Promise.resolve(mockData)
    }

    return doRequestFunction({ url, ...rest })
  }

  return { doRequest: doAdaptedRequest }
}

export default networkAdapter
