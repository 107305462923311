import _ from 'lodash'

export const outlierOptionsRequest = (tags) => {
  let type = ''
  const tagTypes = [
    {
      name: 'SEEDS',
      type: 'sowing',
    },
    {
      name: 'FERTILIZERS',
      type: 'fertilizer',
    },
    {
      name: 'PHYTOTHERAPICS',
      type: 'active-ingredients',
    },
  ]
  for (const tag of tagTypes) {
    if (tags.includes(tag.name)) {
      type = tag.type
    }
  }
  return type
}

export const urlsOutlierParserBox = (item, optionOutlier, surface) => {
  let url, urlActiveIngredient

  const { supply, unit, total } = item

  if (optionOutlier === 'sowing') {
    url = `outliers/${optionOutlier}?code=${supply?.supplyType}&unit=${unit}&totals=${total}&surface=${surface}`
  }

  // ! Desactivado
  // if (optionOutlier === 'fertilizer') {
  //   url = `outliers/${optionOutlier}?name=${supply?.name}&total=${total}&surface=${surface}&unit=${unit}`
  // }

  if (supply?.activeIngredients.length) {
    urlActiveIngredient = `outliers/active-ingredients?supplies={ "id": "${supply._id}", "total": ${total}, "unit": "${unit}" }&surface=${surface}`
  }

  return {
    url,
    urlActiveIngredient,
  }
}

export const urlsOutlierParser = (item, optionOutlier, surface) => {
  let url

  const {
    total,
    name,
    unitTypeSupply: { key: unit },
  } = item

  if (!total) {
    return {
      url,
    }
  }

  const code = item.supplyType ? item.supplyType?.code : item?.typeSupply?.code

  if (optionOutlier === 'sowing') {
    url = `outliers/${optionOutlier}?code=${code}&unit=${unit}&totals=${total}&surface=${surface}`
  }

  // ! Desactivado
  // if (optionOutlier === 'fertilizer') {
  //   url = `outliers/${optionOutlier}?name=${name}&total=${total}&surface=${surface}&unit=${unit}`
  // }

  if (optionOutlier === 'active-ingredients') {
    url = `outliers/active-ingredients?supplies={ "id": "${item._id}", "total": ${total}, "unit": "${unit}" }&surface=${surface}`
  }

  return {
    url,
  }
}

export const getMaxOutlierLevel = (outliers) => {
  const maxLevel = _.maxBy(outliers, (outlier) => {
    return outlier.level
  })
  return maxLevel
}
