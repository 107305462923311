import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { roundNumber } from '@utils/number'
import { Title } from '../AgreementConfirm'
import { AgreementTypeLazyLoadEvidence } from '../../../../../../common/utils'
import { DisclaimerLazyLoadEvidence } from '../DisclaimerLazyLoadEvidence'

import {
  GeneralInfoCard,
  ActivityCard,
  EvidenceCard,
  CollaboratorCard,
  CardInfoParameters,
} from '..'
import { getDataRenderResumeWaterCalculation } from '../../utils/getDataRenderResumeWaterCalculation'

export const Agreement = ({
  cropName,
  companyName,
  fieldsQuantity,
  surface,
  unitArea,
  agreementTypeName,
  evidences,
  collaboratorsQuantity,
  agreementTypeKey,
  activityTypeName,
  weatherType,
  temperature,
  soilType,
  landType,
  humidityType,
}) => {
  const { t } = useContext(LanguageContext)
  const isVieWaterCalculation =
    weatherType?.value ||
    temperature ||
    soilType?.value ||
    landType?.value ||
    humidityType?.value

  const { itemsWaterCalculation } = getDataRenderResumeWaterCalculation(
    {
      weatherType,
      temperature,
      soilType,
      landType,
      humidityType,
    },
    t
  )

  return (
    <>
      <GeneralInfoCard cropName={cropName} companyName={companyName} />

      <ActivityCard
        fieldsQuantity={fieldsQuantity}
        surface={surface}
        unitArea={unitArea}
        agreementTypeName={agreementTypeName}
      />
      {isVieWaterCalculation && (
        <CardInfoParameters items={itemsWaterCalculation} />
      )}

      <EvidenceCard
        evidences={evidences}
        isShowDisclaimer={AgreementTypeLazyLoadEvidence[agreementTypeKey]}
        disclaimerEvidence={
          <>
            <DisclaimerLazyLoadEvidence
              title={agreementTypeName}
              description={
                t('VIEWS').ACTIVITY_CREATE.AGREEMENT_FORM_DISCLAIMER_EVIDENCE[
                  agreementTypeKey
                ]?.DESCRIPTION
              }
            />
          </>
        }
      />

      <CollaboratorCard collaboratorsQuantity={collaboratorsQuantity} />
    </>
  )
}

Agreement.propTypes = {
  cropName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  fieldsQuantity: PropTypes.number.isRequired,
  surface: PropTypes.number.isRequired,
  unitArea: PropTypes.string.isRequired,
  activityTypeName: PropTypes.string.isRequired,
  agreementTypeName: PropTypes.string.isRequired,
  evidences: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      uploadedBy: PropTypes.string.isRequired,
      uploadedAt: PropTypes.string.isRequired,
      filePath: PropTypes.string,
    })
  ),
  collaboratorsQuantity: PropTypes.number.isRequired,
}
