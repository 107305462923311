import GENERAL_INFO_CARD from '../../components/GeneralInfoCard/translate/en'
import ACTIVITY_CARD from '../../components/ActivityCard/translate/en'
import YIELD_CARD from '../../components/YieldCard/translate/en'
import STORAGE_CARD from '../../components/StorageCard/translate/en'
import OBSERVATION_CARD from '../../components/ObservationCard/translate/en'
import EVIDENCE_CARD from '../../components/EvidenceCard/translate/en'
import COLLABORATOR_CARD from '../../components/CollaboratorCard/translate/en'
import SUPPLY_SEED_CARD from '../../components/SupplySeedCard/translate/en'
import SUPPLY_FERTILIZER_CARD from '../../components/SupplyFertilizerCard/translate/en'
import SUPPLY_PHYTOTHERAPIC_CARD from '../../components/SupplyPhytotherapicCard/translate/en'
import SUPPLY from '../../components/Supply/translate/en'
import VALIDATE_BUTTON from '../../components/ValidateButton/translate/en'
import MODIFY_BUTTON from '../../components/ModifyButton/translate/en'

export default {
  NAVIGATION: {
    TITLE: 'Summary',
  },
  COMPONENTS: {
    GENERAL_INFO_CARD,
    ACTIVITY_CARD,
    YIELD_CARD,
    STORAGE_CARD,
    OBSERVATION_CARD,
    EVIDENCE_CARD,
    COLLABORATOR_CARD,
    SUPPLY_SEED_CARD,
    SUPPLY_FERTILIZER_CARD,
    SUPPLY_PHYTOTHERAPIC_CARD,
    SUPPLY,
    VALIDATE_BUTTON,
    MODIFY_BUTTON,
  },
  IRRIGATION: {
    SOURCE_OF_WATER: 'Water source',
    PUMPING_DEPTH: 'Pumping depth',
    WATER_CONSUMPTION: 'Water consumption',
    HORIZONTAL_IRRIGATION_DISTANCE: 'Horizontal irrigation distance',
    SOURCE_OF_ENERGY: 'Energy source',
  },
}
