import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Modal, Portal } from 'react-native-paper'
import { white, gray1 } from '@styles/palette'

function ModalCustom({ visible, hideModal, children, style }) {
  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={hideModal}
        style={styles.backgroundModal}
        contentContainerStyle={[styles.modal, style ?? styles.bgModalDefault]}
      >
        <View style={styles.container}>{children}</View>
      </Modal>
    </Portal>
  )
}

const styles = StyleSheet.create({
  modal: {
    margin: 16,
    borderRadius: 2,
  },
  container: {
    padding: 16,
  },
  bgModalDefault: {
    backgroundColor: white,
  },
  backgroundModal: {
    backgroundColor: gray1,
  },
})

export default ModalCustom
