import React, { useContext, useState } from 'react'
import { PropTypes } from 'prop-types'
import integrations from '@constants/integrations'
import { CommonContext } from '@contextState/common'
import ButtonCustom from '@components/common/ButtonCustom'
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { Card } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'

function CompanyIntegrationAgreementScreen({ route, navigation }) {
  const { servicesIntegration } = useContext(CommonContext)
  const [service, setService] = useState('')
  const [integration, setIntegration] = useState(null)
  const [id, setId] = useState('')
  const { t } = useContext(LanguageContext)

  React.useEffect(() => {
    const integration = servicesIntegration.find(
      (integration) => integration.label === route.params.service
    )

    setIntegration(integration)
    setId(route.params.id)
    setService(route.params.service)
  }, [])

  function imageSelection(service) {
    let image
    switch (service) {
      case 'auravant':
        image = integrations.auravant
        break

      default:
        image = integrations.auravant
    }
    return image
  }
  return (
    <View style={styles.container}>
      <Card
        style={{
          marginTop: 50,
          borderRadius: 10,
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 20,
          alignItems: 'center',
        }}
      >
        <View style={styles.imageContainer}>
          <Image
            style={{
              width: 100,
              height: 100,
            }}
            source={imageSelection(service)}
          />
        </View>
        <Text style={styles.title}>{service}</Text>
        <Text style={styles.textItem}>{integration?.description}</Text>
        <ButtonCustom
          onPress={() => {
            navigation.navigate('CompanyServiceIntegrationLink', {
              id: id,
              service: service,
            })
          }}
        >
          {t('VIEWS').COMPANY_INTEGRATION_AGREEMENT.TEXT_1}
        </ButtonCustom>
        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.goBack()}
        >
          <Text style={styles.buttonGreen}>
            {t('VIEWS').COMPANY_INTEGRATION_AGREEMENT.TEXT_2}
          </Text>
        </TouchableOpacity>
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  imageContainer: {
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    alignItems: 'center',
    fontSize: 22,
    marginRight: 4,
  },
  buttonGreen: {
    alignItems: 'center',
    fontSize: 15,
    padding: 10,
    color: '#4CAF50',
  },
  title: {
    padding: 12,
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: '600',
    fontSize: 28,
  },
  textItem: {
    textAlign: 'center',
    padding: 20,
    fontSize: 18,
  },
})

CompanyIntegrationAgreementScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CompanyIntegrationAgreementScreen
