import { ColorValue } from 'react-native'

export enum SIZES {
  SMALL = 12,
  MEDIUM = 24,
  BIG = 48,
}

export interface IIcon {
  name: string
  size: SIZES | number
  color?: ColorValue
  style?: any
  onPress?: () => {}
  disabled?: boolean
}
