import React, { PropsWithChildren } from 'react'
import { View, StyleSheet } from 'react-native'

import { SIZES } from '@common/components/icons/interfaces'
import { carbon200 } from '@styles/palette'

import IconActivity from '@modules/activities/screens/activitiesList/v1/components/IconActivity'

interface IconBoxProps {
  iconName?: string
  size?: SIZES
  color?: string
  style?: object
  bgColor?: string
}

export const IconBox: React.FC<PropsWithChildren<IconBoxProps>> = ({
  children,
  iconName,
  size,
  color,
  style,
  bgColor = carbon200,
}) => {
  return (
    <View style={[styles.container, { backgroundColor: bgColor }, style]}>
      {children ? (
        children
      ) : (
        <IconActivity name={iconName} size={size} color={color} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
})
