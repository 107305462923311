import MAP_CARD from '../../components/MapCard/translate/pt'
import ACTIVITY_CARD from '../../components/ActivityCard/translate/pt'
import ACTIVITY_CARD_REALIZATION from '../../components/ActivityCardRealization/translate/pt'
import FARM_LIST from '../../components/FarmList/translate/pt'
import ACCORDION_HEADER from '../../components/AccordionHeader/translate/pt'
import PLANNED_ACTIVITY_DATE from '../../components/PlannedActivityDate/translate/pt'

export default {
  COMPONENTS: {
    MAP_CARD,
    ACTIVITY_CARD,
    ACTIVITY_CARD_REALIZATION,
    FARM_LIST,
    ACCORDION_HEADER,
    PLANNED_ACTIVITY_DATE,
  },
}
