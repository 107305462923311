import { PropTypes } from 'prop-types'
import React from 'react'
import useActionSheetCustom from '@hooks/useActionSheetCustom'

import { StandardDeviation } from '../components'

export const useStandardDeviation = ({ t }) => {
  const { ActionSheetComponent, openActionSheet } = useActionSheetCustom(t)

  const ComponentStandarDeviation = () => {
    return (
      <ActionSheetComponent>
        <StandardDeviation t={t} />
      </ActionSheetComponent>
    )
  }
  return {
    ComponentStandarDeviation,
    openBackdropStandardDeviation: openActionSheet,
  }
}

useStandardDeviation.propTypes = {
  t: PropTypes.func.isRequired,
}
