import PropTypes from 'prop-types'
import React from 'react'
import { carbon200, carbon600, carbon800 } from '@styles/palette'
import { View, Text, StyleSheet } from 'react-native'

export const ItemAboutActivities = ({ description, title }) => {
  return (
    <View style={styles.containerItem}>
      <Text style={styles.titleItem}>{title}</Text>
      <Text style={styles.descriptionItem}>{description}</Text>
    </View>
  )
}

ItemAboutActivities.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const styles = StyleSheet.create({
  containerItem: {
    marginTop: 20,
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: carbon200,
  },
  titleItem: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 20,
    color: carbon800,
  },
  descriptionItem: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: carbon600,
  },
})
