import { collaboratorCompanyTypes as type } from '../types'

export const collaboratorsCompanyActions = {
  setCollaboratorsCompany: (payload) => ({
    type: type.SET_COLLABORATORS_COMPANY,
    payload,
  }),
  clearCollaboratorsCompany: () => ({
    type: type.CLEAR_COLLABORATOR_COMPANY,
  }),
}
