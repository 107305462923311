import FARM_SEARCH from '../../components/SearchFarm/translate/es'
import FARM_CARD from '../../components/FarmCard/translate/es'

export default {
  NOT_FOUND: 'No se encontraron coincidencias',
  COMPONENTS: {
    FARM_SEARCH,
    FARM_CARD,
  },
}
