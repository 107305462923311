export default {
  COUNTRY: {
    PLACEHOLDER: 'Country',
    LABEL: 'Country',
  },
  EMAIL: {
    PLACEHOLDER: 'Email',
    LABEL: 'Email',
  },
  CONFIRM_EMAIL: {
    PLACEHOLDER: 'Confirm email',
    LABEL: 'Confirm email',
  },
  COLLABORATOR_TYPE: {
    PLACEHOLDER: 'Collaborator type',
    LABEL: 'Collaborator type',
  },
  SAVE: 'SAVE',
  NEXT: 'Next',
}
