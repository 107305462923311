import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'
import { blackHighEmphasis } from '@styles/palette'
import { capitalizeFirstLetter } from '@utils/strings'

export const Title = ({ nameActivity, title, isVisible }) => {
  return isVisible ? (
    <AgreementConfirmTitle title={title} nameActivity={nameActivity} />
  ) : (
    <></>
  )
}
const AgreementConfirmTitle = ({ title, nameActivity }) => {
  return (
    <View style={styles.contentContainer}>
      <View style={styles.textContainer}>
        <Text style={styles.textBold}>
          {capitalizeFirstLetter(nameActivity)}
        </Text>
        <Text style={styles.textBold}>{title}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    marginTop: 15,
    paddingHorizontal: 10,
  },
  textContainer: {
    flex: 1,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 4,
  },
})

Title.propTypes = {
  isVisible: PropTypes.bool,
  nameActivity: PropTypes.string,
  title: PropTypes.string,
}
