export const floodFormInitialValues = ({
  cropId,
  activityId,
  company,
  draftId,
  tag,
  activityType,
}: any) => ({
  draftId: draftId,
  cropId: cropId,
  activityId: activityId,
  company: company,
  tag: tag,
  name: activityType?.label,
  type: activityType?.value,
  status: 'DONE',
  subTypeActivity: {
    value: '',
    label: '',
  },
  lots: {
    value: [],
    label: '',
  },
  surface: '',
  dateAchievement: {
    value: '',
    label: '',
  },
  irrigationWaterSource: {
    value: '',
    label: '',
  },
  pumpingDepth: '',
  waterConsumption: '',
  horizontalWateringDepth: '',
  irrigationPowerSource: {
    value: '',
    label: '',
  },
  signers: [],
  evidences: [],
  files: [],
})
