// Imports: Dependencies
import { all, fork } from 'redux-saga/effects'
import { loadVerificationTypes } from './verificationTypesSagas'

// Imports: Redux Sagas

// Redux Saga: Root Saga
export default function* rootSaga() {
  yield all([fork(loadVerificationTypes)])
}
