let deleteBeforeRemoveListener
let deleteBeforeunloadListener

export const preventLeaveViewListener = (
  navigation,
  beforeRemoveListener,
  beforeunloadListener
) => {
  if (deleteBeforeRemoveListener) {
    deleteBeforeRemoveListener()
    deleteBeforeunloadListener()
  }

  deleteBeforeRemoveListener = navigation.addListener(
    'beforeRemove',
    beforeRemoveListener
  )

  deleteBeforeunloadListener = navigation.addListener(
    'beforeRemove',
    beforeunloadListener
  )

  return () => {
    deleteBeforeRemoveListener()
    deleteBeforeunloadListener()
  }
}

export const removePreventLeaveViewListener = () => {
  if (deleteBeforeRemoveListener) {
    deleteBeforeRemoveListener()
    deleteBeforeunloadListener()
  }
}
