import React, { useContext } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'

import { licenseEmpty } from '@constants/images'

const LicensesEmpty = () => {
  const { t } = useContext(LanguageContext)
  return (
    <View style={styles.container}>
      <Image source={licenseEmpty} style={styles.emptyImage} />
      <View style={styles.textsContainer}>
        <Text style={styles.principalText}>
          {t(
            'VIEWS.LICENSE_LIST.COMPONENTS.EMPTY_STATE_LICENSES.NOT_AVAILABLE'
          )}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: 40,
  },
  emptyImage: {
    width: 180,
    height: 230,
    resizeMode: 'contain',
  },
  textsContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  principalText: {
    fontSize: 18,
    fontWeight: '500',
  },
})

export default LicensesEmpty
