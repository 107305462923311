import _ from 'lodash'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'
import { blackMediumEmphasis } from '@styles/palette'

export const CropNameDetails = ({
  cropName,
  surface,
  companyName,
  areaUnit,
}) => {
  return (
    <View style={styles.cropTitleContainer}>
      <Text style={styles.cropTitleText}>{cropName}</Text>
      <Text
        style={styles.cropSubtitleText}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {`${_.round(surface, 2)} ${areaUnit} - ${companyName}`}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  cropTitleContainer: {
    flex: 1,
    marginVertical: 20,
    paddingHorizontal: 20,
  },
  cropTitleText: {
    fontSize: 16,
    fontWeight: '500',
    color: blackMediumEmphasis,
    lineHeight: 24,
  },

  cropSubtitleText: {
    fontSize: 14,
    lineHeight: 20,
    color: blackMediumEmphasis,
  },
})

CropNameDetails.propTypes = {
  cropName: PropTypes.string,
  surface: PropTypes.number,
  companyName: PropTypes.string,
  areaUnit: PropTypes.string,
}
