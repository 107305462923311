import { WebViewRender } from '@modules/webView/components'

import { useStoriesWebView } from '@common/hooks'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'

const CropStoryDetail = ({ route }: { navigation: any; route: any }) => {
  const {
    params: { id: cropId, fieldStory, lotId },
  } = route

  const { handleIFrameMessages, url, isReady }: any = useStoriesWebView(
    !fieldStory ? 'crop-story' : 'field-story',
    { cropId, lotId }
  )

  return (
    <>
      {!isReady ? (
        <LoaderCenterContainer />
      ) : (
        <WebViewRender url={url} onMessage={handleIFrameMessages} />
      )}
    </>
  )
}

export default CropStoryDetail
