import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, Text } from 'react-native'
import { PropTypes } from 'prop-types'
import { FormikProvider, useFormik } from 'formik'
import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import useNetwork from '@utils/network'
import {
  blackMediumEmphasis,
  primary500,
  gray12,
  white,
  blackDisabled,
} from '@styles/palette'
import InputMultiline from '@components/inputs/InputMultiline'
import ActionSheetAndModal from '@components/common/v1/ActionSheetAndModal'
import { declineValidationSchema } from '../utils'
export const ModalConfirmDecline = ({
  closeActionSheet,
  modal,
  onSuccess,
  paramsRequest,
  beforeActionClose,
}) => {
  const { t } = useContext(LanguageContext)
  const { doRequest } = useNetwork()
  const [loadingReject, setLoadingReject] = useState(false)
  const formikDeclineActivity = useFormik({
    initialValues: {
      observation: '',
    },
    validationSchema: declineValidationSchema(t),
    enableReinitialize: false,
  })
  const {
    setFieldValue,
    values,
    setFieldTouched,
    touched,
    errors,
    validateForm,
    isValid,
    isSubmitting,
  } = formikDeclineActivity

  useEffect(() => {
    validateForm()
  }, [])

  const reject = async () => {
    setLoadingReject(true)
    paramsRequest.data.rejectObservation = values.observation.trim()
    const response = await doRequest(paramsRequest)
    setLoadingReject(false)
    closeActionSheet()
    onSuccess()
  }

  const onClose = () => {
    if (beforeActionClose) {
      beforeActionClose()
    }
    closeActionSheet()
  }

  return (
    <ActionSheetAndModal
      onClose={onClose}
      modal={modal}
      title={t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_DECLINE_SIGNATURE.TEXT_1}
    >
      <FormikProvider value={formikDeclineActivity}>
        <Text style={styles.subtitle}>
          {t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_DECLINE_SIGNATURE.TEXT_2}
        </Text>
        <InputMultiline
          id='observation'
          containerStyle={styles.containerInput}
          onChangeText={(value) => {
            setFieldValue('observation', value)
          }}
          value={values.observation}
          onBlur={() => setFieldTouched('observation')}
          touched={touched.observation}
          error={errors.observation}
          placeholder={
            t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_DECLINE_SIGNATURE.TEXT_3
          }
          label={
            t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_DECLINE_SIGNATURE.TEXT_3
          }
          hasIcon={false}
        />

        <Text style={[styles.observation, !isValid ? styles.marginTop : {}]}>
          {t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_DECLINE_SIGNATURE.TEXT_4}
        </Text>
        <ButtonCustom
          disabled={!isValid || loadingReject || isSubmitting}
          onPress={reject}
          styles={[
            styles.button,
            !isValid || isSubmitting ? styles.buttonDisabled : {},
          ]}
          labelStyle={[
            styles.textButton,
            !isValid ? styles.textButtonDisabled : {},
          ]}
        >
          {t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_DECLINE_SIGNATURE.TEXT_5}
        </ButtonCustom>
      </FormikProvider>
    </ActionSheetAndModal>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: primary500,
    marginVertical: 5,
  },
  buttonDisabled: {
    backgroundColor: gray12,
  },
  textButton: {
    color: white,
  },
  textButtonDisabled: {
    color: blackDisabled,
  },
  subtitle: { fontSize: 12, color: blackMediumEmphasis, marginBottom: 20 },
  observation: {
    fontSize: 10,
    color: blackMediumEmphasis,
    marginTop: -15,
    marginBottom: 20,
  },
  marginTop: { marginTop: 10 },
})

ModalConfirmDecline.propTypes = {
  ActionSheetComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  validateObservation: PropTypes.func,
  closeActionSheet: PropTypes.func,
  crop: PropTypes.object,
  activity: PropTypes.object,
  onSuccess: PropTypes.func,
}
