import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import { secondary500 } from '@styles/palette'

export const RemoveFieldButton = ({ showButton, isLoading, onSubmit }) => {
  const { t } = useContext(LanguageContext)

  return (
    showButton && (
      <ButtonCustom
        isLoading={isLoading}
        onPress={onSubmit}
        styles={styles.button}
        labelStyle={styles.textButton}
      >
        {t(
          'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.REMOVE_FIELD_BUTTON.REMOVE_FIELD'
        )}
      </ButtonCustom>
    )
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: secondary500,
    marginHorizontal: 16,
    marginTop: 20,
  },
  textButton: {
    color: 'white',
  },
})

RemoveFieldButton.propTypes = {
  showButton: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
