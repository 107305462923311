export const cropTypesSort = {
  soy: {
    order: 1,
  },
  corn: {
    order: 2,
  },
  wheat: {
    order: 3,
  },
  sunflower: {
    order: 4,
  },
  Barley: {
    order: 5,
  },
}

/**
 * @param {Array.<object>} cropTypes
 * @param {string} cropTypes.value
 * @param {string} cropTypes.label
 * @param {string} cropTypes.key
 */
export const cropTypesNotification = (cropTypes) => {
  const countOrder = Object.keys(cropTypesSort).length + 1

  return cropTypes
    .map((cropType) => {
      const cropTypeElement = cropTypesSort[cropType.key]

      const order = cropTypeElement ? cropTypeElement.order : countOrder

      return {
        _id: cropType.value,
        name: cropType.label,
        key: cropType.key,
        selected: false,
        order,
      }
    })
    .sort(function (cropTypeA, cropTypeB) {
      return (
        cropTypeA.order - cropTypeB.order ||
        cropTypeA.name.localeCompare(cropTypeB.name)
      )
    })
}
