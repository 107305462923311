export const parseDataToSave = (values) => {
  const data = {
    ...values,
    addressFloor: values.addressFloor ?? values.specificAddress ?? '',
    country: values.country.value,
    typePerson: values.typePerson.value,
  }
  data.evidences = values.files.map((file) => ({
    name: file.name,
    description: file.description ?? '',
    date: file.date,
  }))
  data.users = values.users.map((user) => {
    const role = user?.role?.value ?? user?.role
    const userReturn = {
      email: user.email,
      identifier: user.identifier,
      role,
    }
    if (!user.identifier) {
      userReturn.identifier = values.identifier
    }
    if (user.isAdmin) {
      userReturn.isAdmin = user.isAdmin
    }
    if (user.isResponsible) {
      userReturn.isResponsible = user.isResponsible
    }
    return userReturn
  })
  const formData = new FormData()
  for (const file of values.files) {
    formData.append('files', file.file)
  }
  delete data.files
  formData.append('data', JSON.stringify(data))
  return formData
}
