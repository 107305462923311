import { PropTypes } from 'prop-types'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { blackHighEmphasis, gray12 } from '@styles/palette'
import { Divider, List } from 'react-native-paper'
import { CommonIcon } from '@modules/common/components'

const ItemMenu = ({
  onPress,
  title,
  iconLeft = { name: '', size: 24, style: {} },
  iconRight = { name: '', size: 20, style: {} },
  divider = false,
  titleStyle = {},
}) => {
  return (
    <>
      <List.Item
        onPress={onPress}
        title={title}
        left={() =>
          iconLeft.name ? (
            <CommonIcon {...iconLeft} />
          ) : (
            <View style={styles.iconEmpty} />
          )
        }
        right={() => Boolean(iconRight.name) && <CommonIcon {...iconRight} />}
        titleStyle={[styles.titleItem, titleStyle]}
        style={[styles.containerItem, onPress ? styles.pointer : {}]}
      />
      {divider && <Divider style={styles.divider} />}
    </>
  )
}

const styles = StyleSheet.create({
  containerItem: { paddingHorizontal: 16 },
  titleItem: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    marginLeft: 16,
    justifyContent: 'center',
    marginTop: -3,
  },
  iconEmpty: {
    width: 24,
  },
  divider: { marginLeft: 64, color: gray12, height: 1 },
  pointer: { cursor: 'pointer' },
})

ItemMenu.propTypes = {
  onPress: PropTypes.func,
  title: PropTypes.string.isRequired,
  iconLeft: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  }),
  iconRight: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  }),
  divider: PropTypes.bool,
  titleStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
}

export default ItemMenu
