import React, { RefObject, useState } from 'react'
import { MaterialCommunityIcons as Icon } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import { black, carbon200, carbon400, primary200, white } from '@styles/palette'
import { StyleSheet, TextInput, View } from 'react-native'
import { Input } from 'react-native-elements'
import { CommonIcon } from '@common/components'
import { InputSearchProps } from './inputSearch.props'

export const InputSearch = ({
  id,
  inputRef,
  inputStyle = {},
  value = '',
  containerStyle = {},
  placeholder = '',
  onChangeText,
  handleClearSearch,
  onChange,
}: InputSearchProps) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false)
    }, 100)
  }
  return (
    <View>
      <Input
        id={id}
        ref={inputRef as unknown as RefObject<TextInput>}
        value={value}
        placeholder={placeholder}
        placeholderTextColor={carbon400}
        containerStyle={[styles.containerStyle, containerStyle]}
        inputContainerStyle={[
          styles.inputContainer,
          isFocused ? styles.focusedInput : {},
        ]}
        inputStyle={[styles.input, inputStyle]}
        leftIcon={
          <CommonIcon
            name={'SEARCH'}
            size={24}
            style={[isFocused ? styles.iconFocusedColor : styles.iconColor]}
          />
        }
        leftIconContainerStyle={styles.leftIconContainerStyle}
        rightIcon={
          value ? (
            <Icon
              name={'close'}
              size={16}
              color={black}
              onPress={handleClearSearch}
            />
          ) : undefined
        }
        rightIconContainerStyle={styles.rightIconContainerStyle}
        onChangeText={onChangeText}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        returnKeyType='search'
      />
    </View>
  )
}
const styles = StyleSheet.create({
  containerStyle: {
    paddingHorizontal: 16,
  },
  input: {
    fontSize: 16,
    lineHeight: 24,
    color: black,
  },
  inputContainer: {
    height: 44,
    backgroundColor: white,
    borderWidth: 1,
    borderColor: carbon200,
    borderRadius: 8,
  },
  focusedInput: {
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: primary200,
  },
  leftIconContainerStyle: {
    paddingStart: 20,
  },
  rightIconContainerStyle: {
    marginEnd: 18,
  },
  iconColor: {
    color: carbon400,
  },
  iconFocusedColor: {
    color: black,
  },
})
