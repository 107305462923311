import Constants from 'expo-constants'
import queryString from 'query-string'
import AsyncStorage from '@react-native-async-storage/async-storage'

const URL_DRAW_FIELDS = Constants.expoConfig.extra.DRAW_FIELDS_URL ?? ''

const parseParams = async (params = {}) => {
  const token = await AsyncStorage.getItem('USER_TOKEN')
  const queryParams = {
    accessToken: token,
    timestamp: Date.now(),
    ...params,
  }
  return queryString.stringify(queryParams)
}

const getUrlMainFarm = async ({ uuid }) => {
  const paramsString = await parseParams()
  const url = `${URL_DRAW_FIELDS}/farms/main/${uuid}?${paramsString}}`
  return encodeURI(url)
}

const getUrlCreateFarm = async (params) => {
  const paramsString = await parseParams(params)
  const url = `${URL_DRAW_FIELDS}/farms/create?${paramsString}`
  return encodeURI(url)
}

const getUrlDraftFarm = async ({ uuid }) => {
  const paramsString = await parseParams()
  const url = `${URL_DRAW_FIELDS}/farms/draft/${uuid}?${paramsString}`
  return encodeURI(url)
}

const getUrlDetailFarm = async ({ uuid }) => {
  const paramsString = await parseParams()
  const url = `${URL_DRAW_FIELDS}/farms/${uuid}?${paramsString}`
  return encodeURI(url)
}

const getUrlDetailField = async ({ uuid }) => {
  const paramsString = await parseParams()
  const url = `${URL_DRAW_FIELDS}/fields/${uuid}?${paramsString}}`
  return encodeURI(url)
}

const getUrlEditFarm = async ({ uuid }) => {
  const paramsString = await parseParams()
  const url = `${URL_DRAW_FIELDS}/farms/edit/${uuid}?${paramsString}}`
  return encodeURI(url)
}

const getUrlFarMap = async () => {
  const paramsString = await parseParams()
  const url = `${URL_DRAW_FIELDS}/farms/v2?${paramsString}}`
  return encodeURI(url)
}

const getUrlTradersBulkUpload = async () => {
  const paramsString = await parseParams()
  const url = `${URL_DRAW_FIELDS}/bulk-load?${paramsString}}`
  return encodeURI(url)
}

export const getUrlDrawFields = {
  getUrlMainFarm,
  getUrlCreateFarm,
  getUrlDraftFarm,
  getUrlDetailField,
  getUrlDetailFarm,
  getUrlEditFarm,
  getUrlFarMap,
  getUrlTradersBulkUpload,
}
