export default {
  TEXT_1: 'SAVE DRAFT',
  TEXT_2: 'VALIDATE',
  TEXT_3: 'SIGN',
  TEXT_4: 'SIGNED',
  TEXT_5: 'Draft: version %{version}',
  TEXT_6: 'by %{user} in %{date} %{hour} hs.',
  TEXT_7: 'Draft activity was saved',
  TEXT_8: 'This post is being edited by another contributor (%{userFullname}).',
  TEXT_9: 'IN REVIEW',
  TEXT_10: 'Decline',
  CROP_DESCRIPTION: {
    TEXT_1: '%{surface} ha',
  },
  HARVEST_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Select',
      LABEL: 'Selected',
      LABEL_ALT: 'Selected',
    },
    FIELD_2: {
      PLACEHOLDER: 'Area %{areaUnit}',
      LABEL: 'Area %{areaUnit}',
    },
    FIELD_3: {
      PLACEHOLDER: 'Harvest date',
      LABEL: 'Harvest date',
    },
    FIELD_4: {
      PLACEHOLDER: 'Yield unit',
      LABEL: 'Yield unit',
    },
    FIELD_5: {
      PLACEHOLDER: 'Volume',
      LABEL: 'Volume',
    },
    FIELD_6: {
      PLACEHOLDER: 'Yield',
      LABEL: 'Yield',
    },
    FIELD_VOLUME_UNIT: {
      PLACEHOLDER: 'Volume unit',
      LABEL: 'Volume unit',
    },
  },
  MONITORING_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Select',
      LABEL: 'Selected',
      LABEL_ALT: 'Selected',
    },
    FIELD_2: {
      PLACEHOLDER: 'Monitoring date',
      LABEL: 'Monitoring date',
    },
    FIELD_3: {
      PLACEHOLDER: 'Yield unit',
      LABEL: 'Yield unit',
    },
    FIELD_4: {
      PLACEHOLDER: 'Yield estimation',
      LABEL: 'Yield estimation',
    },
    FIELD_5: {
      PLACEHOLDER: 'Observation',
      LABEL: 'Observation',
    },
    FIELD_6: {
      PLACEHOLDER: 'Harvest estimation',
      LABEL: 'Harvest estimation',
    },
  },
  AGREEMENT_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Statement type',
      LABEL: 'Declaration type',
    },
    FIELD_2: {
      PLACEHOLDER: 'Select',
      LABEL: 'Selected',
      LABEL_ALT: 'Selected field',
    },
    FIELD_3: {
      PLACEHOLDER: 'Area %{areaUnit}',
      LABEL: 'Area %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Seed dry percentage',
      LABEL: 'Seed dry percentage',
    },
    FARM_FIELD: {
      SINGULAR: '%{num} Farm selected',
      PLURAL: '%{num} Farms selected',
    },
    SURFACE_FIELD: {
      LIMIT: 'The area may not exceed %{surfaceMax} %{areaUnit}.',
    },
  },
  STATEMENT_CFT_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Select farm',
      LABEL: 'Select farm',
    },
    FIELD_2: {
      PLACEHOLDER: 'Area %{areaUnit}',
      LABEL: 'Area %{areaUnit}',
    },
    EMISSION_TITLE: 'GHG Calculation',
    EMISSION_TEXT_BEFORE_SEND:
      'By filling in just a few data, we will generate a report calculating the greenhouse gases (GHG) with the CFT methodology and add it as evidence to the crop.',
    EMISSION_BUTTON: 'Generate evidence',
    EMISSION_TEXT_AFTER_SEND:
      'By validating the declaration, we will generate the report and add it as evidence to the crop.',
  },
  STATEMENT_FORM: {
    SELECT_FIELDS: 'Select fields',
    DECLARATIVE_REPORT: 'Declarative report',
    COMPLETE_THE_FORM:
      'Complete the form to generate the report and add it as evidence to your crop.',
    COMPLETE_FORM: 'Complete form',
    FORM_TYPE_DECLARATION_REPORT: 'Form type Declaration Report',
    BY_VALIDATING_THE_DECLARATION:
      'By validating the declaration, we will generate the report and add it as evidence to your crop',
  },
  IRRIGATION_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Irrigation method',
      LABEL: 'Irrigation method',
    },
    FIELD_2: {
      PLACEHOLDER: 'Select fields',
      LABEL: 'Selected',
      LABEL_ALT: 'Selected field',
    },
    FIELD_3: {
      PLACEHOLDER: 'Area %{areaUnit}',
      LABEL: 'Area %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Date of irrigation',
      LABEL: 'Date of irrigation',
    },
    FIELD_5: {
      PLACEHOLDER: 'Water source',
      LABEL: 'Water source',
    },
    FIELD_6: {
      PLACEHOLDER: 'Pumping depth %{unit}',
      LABEL: 'Pumping depth %{unit}',
    },
    FIELD_7: {
      PLACEHOLDER: 'Water consumption %{unit}',
      LABEL: 'Water consumption %{unit}',
    },
    FIELD_8: {
      PLACEHOLDER: 'Horizontal irrigation distance %{unit}',
      LABEL: 'Horizontal irrigation distance %{unit}',
    },
    FIELD_9: {
      PLACEHOLDER: 'Energy source',
      LABEL: 'Energy source',
    },
  },
  VERIFICATION_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Verification type',
      LABEL: 'Verification type',
    },
    FIELD_2: {
      PLACEHOLDER: 'Select',
      LABEL: 'Selected',
      LABEL_ALT: 'Selected',
    },
    FIELD_3: {
      PLACEHOLDER: 'Area %{areaUnit}',
      LABEL: 'Area %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Observation',
      LABEL: 'Observation',
    },
  },
  DESTINATION: {
    TEXT_1: 'Add Destination',
    MODAL_1: {
      TEXT_1: 'Add Destination',
    },
  },
  EVIDENCE: {
    TEXT_1: 'Add Evidence',
    MODAL_1: {
      TEXT_1: 'Add Evidence',
    },
  },
  SUPPLY: {
    TEXT_1: 'Add Input',
  },
  SUPPLIES_LIST: {
    TEXT_1: 'EIQ %{eiqQuantity}',
  },
  DESTINATION_LIST: {
    TEXT_1: 'Storage',
    TEXT_2: '%{tonQuantity} Tn',
  },
  COLLABORATORS: {
    TEXT_1: 'Collaborators',
    TEXT_2: 'Add collaborators',
    TEXT_3: '%{collaboratorsQuantity} selected collaborators',
  },
  MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES: {
    TEXT_1: 'Exit without saving?',
    TEXT_2: 'Changes will not be saved',
    TEXT_3: 'EXIT',
    TEXT_4: 'CANCEL',
  },
  MODAL_DELETE: {
    TEXT_1: 'Delete Draft?',
    TEXT_2: 'Uploaded evidences will be permanently deleted.',
    TEXT_3: 'KEEP',
    TEXT_4: 'DELETE',
  },
  MODAL_VALIDATE: {
    TEXT_1: 'Validate this version of the draft?',
    TEXT_2: 'There are other versions of this same record',
    TEXT_3: 'CANCEL',
    TEXT_4: 'VALIDATE',
  },
  MODAL_CONFIRM_ACTIVITY_VERIFICATION: {
    TEXT_1: 'Verification Type',
    TEXT_2:
      'If you modify the type of verification, all Activity data will be lost.',
    TEXT_3: 'MODIFY',
    TEXT_4: 'CANCEL',
  },
  MODAL_VALIDATE_VERIFICATION: {
    TEXT_1: 'Confirm verification',
    TEXT_2:
      'If you consider the verification accepted, please upload the supporting document here.',
    TEXT_3: 'ADD VERIFICATION DOCUMENT',
    TEXT_4: 'Verification %{verificationType}',
    TEXT_5: 'Condition: %{verificationStatus}',
    TEXT_6: 'Click below to complete the verification process',
    TEXT_7: 'NOT COMPLIANT',
    TEXT_8: 'VERIFIED',
  },
  MODAL_CONFIRM_VALIDATE_VERIFICATION: {
    TEXT_1: 'Confirm verification',
    TEXT_2:
      'Once validated, you will not be able to modify the registered information.',
    TEXT_3: 'CANCEL',
    TEXT_4: 'VALIDATE',
  },
  MODAL_CONFIRM_DECLINE_SIGNATURE: {
    TEXT_1: 'You are about to reject a signature',
    TEXT_2:
      'Keep in mind that this action implies that the activity will be suspended.',
    TEXT_3: 'Input an observation',
    TEXT_4: 'You can type up to 140 characters.',
    TEXT_5: 'Confirm rejection',
  },
  MODAL_LOADING: {
    COMPLETING_THE_APPLICATION: 'Completing the application',
    PLEASE_WAIT_A_FEW_MOMENTS: 'Please wait a few moments.',
    PLEASE_WAIT_A_FEW_MORE_MOMENTS: 'Please wait a few more moments.',
  },
  STATEMENT_SDP_REPORT_NEED_SURFACE_FARM: {
    TITLE: 'Farm area',
    DESCRIPTION:
      'To generate the report, we need you to upload a KMZ file with the perimeter of the farm to calculate the area accurately.',
    REDIRECT_EDIT_FARM_TEXT: 'Upload KMZ file',
  },
  AGREEMENT_FORM_DISCLAIMER_EVIDENCE: {
    SUSTAINABILITY_2BSVS: {
      TITLE: 'Landscape Sustainability Report - 2BSvs',
      DESCRIPTION:
        'Once the activity has been validated, we will generate the report in a few minutes.',
    },
    SUSTAINABILITY_EPA: {
      TITLE: 'Landscape Sustainability Report - EPA',
      DESCRIPTION:
        'Once the activity has been validated, we will generate the report in a few minutes.',
    },
    SUSTAINABILITY_MOA: {
      TITLE: 'Landscape Sustainability Report - MOA',
      DESCRIPTION:
        'Once the activity has been validated, we will generate the report in a few minutes.',
    },
    SUSTAINABILITY_CAN: {
      TITLE: 'Landscape Sustainability Report - Generic',
      DESCRIPTION:
        'Once the activity has been validated, we will generate the report in a few minutes.',
    },
  },
  WATER_CALCULATION_FORM: {
    WEATHER: 'Weather',
    TEMPERATURE: 'Temperature °%{unitTemperature}',
    LAND_TYPE: 'Soil type',
    SOIL_CHARACTERISTIC: 'Soil characteristic',
    SOIL_MOISTURE: 'Soil moisture',
    MEASURED_AT_PLANTING: 'Measured at planting',
  },
  FLOOD_FORM: {
    TIME_FLOOD_UNDER_CROP: 'Time under crop',
    WATER_MANAGEMENT: 'Water management',
    BEFORE_CROP: 'Before crop',
    DURING_CROP: 'During crop',
    DEFAULT_VALUES: 'Use default values',
    UNIT_TIME: 'Time unit',
  },
}
