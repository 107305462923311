export default {
  TEXT_1:
    'Antes de agregar un colaborador debes agregar al menos una evidencia',
  TEXT_2: 'Debes seleccionar al menos un lote',
  TEXT_3: 'AGREGAR EVIDENCIA',
  TEXT_4: 'GUARDAR',
  TEXT_5: 'FIRMAR',
  TEXT_6: 'FIRMADO',
  TEXT_7: 'FIRMAR',
}
