import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { PropTypes } from 'prop-types'
import { AnimatedCircularProgress } from 'react-native-circular-progress'
import { primary50, primary500, secondary500 } from '@styles/palette'

const CircleChart = ({
  percent = 60,
  size = 70,
  width = 8,
  extraStyleText = {},
}) => {
  return (
    <AnimatedCircularProgress
      size={size}
      width={width}
      fill={percent}
      tintColor={percent ? primary500 : secondary500}
      backgroundColor={percent ? primary50 : secondary500}
      rotation={0}
    >
      {(fill) => (
        <Text
          style={[percent ? styles.textGreen : styles.textRed, extraStyleText]}
        >
          {fill}%
        </Text>
      )}
    </AnimatedCircularProgress>
  )
}

const styles = StyleSheet.create({
  textGreen: {
    color: primary500,
    fontSize: 18,
  },
  textRed: {
    color: secondary500,
    fontSize: 18,
  },
})

CircleChart.propTypes = {
  percent: PropTypes.number,
  size: PropTypes.number,
  width: PropTypes.number,
  extraStyleText: PropTypes.object,
}

export default CircleChart
