export default {
  TEXT_1: 'Selected field',
  TEXT_2: 'Planned area',
  TEXT_3: 'Collaborators',
  TEXT_4: 'Work Done',
  TEXT_5: 'What was planned',
  TEXT_6: 'Done',
  TEXT_7: 'Add work done',
  TEXT_8: 'Executed area',
  TEXT_9: 'Validation',
  TEXT_10: 'Validated',
  TEXT_11: 'Validate',
  TEXT_12: 'Download report in pdf',
  TEXT_13: 'Draft',
  TEXT_14: 'Version',
  TEXT_15: 'In review',
  TEXT_16: '%{establishments} Campo - %{lots} Lotes',
  TEXT_17: 'Fertilization type',
  TEXT_18: 'Inputs',
  TEXT_19: 'From',
  TEXT_20: 'To',
}
