const statusEIQ = {
  VERY_HIGH: {
    key: 'VERY_HIGH',
    name: (t) => t('CONSTANTS').EIQ_STATUS.VERY_HIGH,
    color: '#FF5722',
    background: '#FBE9E7',
    starsQuantity: 1,
    title_translate: 'TEXT_1',
  },
  HIGH: {
    key: 'HIGH',
    name: (t) => t('CONSTANTS').EIQ_STATUS.HIGH,
    color: '#FF9800',
    background: '#FFF3E0',
    starsQuantity: 2,
    title_translate: 'TEXT_2',
  },
  MODERATE: {
    key: 'MODERATE',
    name: (t) => t('CONSTANTS').EIQ_STATUS.MODERATE,
    color: '#C0CA33',
    background: '#F9FBE7',
    starsQuantity: 3,
    title_translate: 'TEXT_3',
  },
  LOW: {
    key: 'LOW',
    name: (t) => t('CONSTANTS').EIQ_STATUS.LOW,
    color: '#8BC34A',
    background: '#F1F8E9',
    starsQuantity: 4,
    title_translate: 'TEXT_4',
  },
  VERY_LOW: {
    key: 'VERY_LOW',
    name: (t) => t('CONSTANTS').EIQ_STATUS.VERY_LOW,
    color: '#4CAF50',
    background: '#E8F5E9',
    starsQuantity: 5,
    title_translate: 'TEXT_5',
  },
}

export default statusEIQ
