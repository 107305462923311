import { FC, useCallback, useContext, useState } from 'react'
import { IAppCard } from './interfaces'
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native'
import { SIZES } from '@common/components/icons/interfaces'
import { LanguageContext } from '@contextState/language'
import { EKeyService } from '@modules/AppIntegrator/enum'
import RouterNames from '@constants/routerNames'
import { CommonIcon } from '@modules/common/components'
import { yellow600 } from '@common/styles'

export const AppCard: FC<IAppCard> = ({
  plugin,
  companyId,
  navigation,
  isS2,
}) => {
  const { t, i18n } = useContext(LanguageContext)
  const { name, category, icon64, key: pluginKey } = plugin
  const onPress = () => {
    if (pluginKey === EKeyService.AURAVANT) {
      return navigation.navigate(
        RouterNames.COMPANY_SERVICE_INTEGRATIONS_LIST,
        {}
      )
    } else {
      let params = { id: String(companyId), pluginKey: pluginKey } as any
      if (isS2) {
        params = {
          ...params,
          isS2,
          plugin,
        }
      }
      navigation.navigate(RouterNames.COMPANY_APP_INTEGRATION_DETAIL, params)
    }
  }

  const hasPendingValidationStatus = plugin?.status?.some(
    (status) => status.key === 'PENDING_VALIDATION'
  )

  const Validating = () => (
    <View style={styles.validatingContainer}>
      <CommonIcon name='ALERT-CIRCLE' size={21.6} color={yellow600} />
      <View style={styles.validatingDescription}>
        <Text style={styles.validating}>
          {' '}
          {t('COMPONENTS.APP_INTEGRATOR.VALIDATING')}
        </Text>
      </View>
    </View>
  )

  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardShadow}>
        <TouchableOpacity onPress={onPress} activeOpacity={0.8}>
          <View style={styles.iconContainer}>
            <View>
              <Image
                source={{
                  uri: icon64,
                }}
                style={styles.providerImage}
              />
            </View>
            <View style={styles.cardInfo}>
              <Text style={styles.name}>{name}</Text>
              <Text style={styles.description}>
                {hasPendingValidationStatus ? (
                  <Validating />
                ) : (
                  category[i18n.locale]
                )}
              </Text>
            </View>
            <View style={styles.iconChevronContainer}>
              <CommonIcon
                name={'CHEVRON-RIGHT'}
                size={SIZES.MEDIUM}
                color={'#000'}
              />
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    marginBottom: 0,
  },
  cardShadow: {
    backgroundColor: 'white',
    borderRadius: 8,
    shadowColor: 'rgba(16, 24, 40, 1)',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  cardInfo: {
    paddingLeft: 16,
    flex: 2,
  },
  iconContainer: {
    paddingVertical: 16,
    paddingStart: 20,
    paddingEnd: 16,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  name: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 28,
    color: '#000',
  },
  description: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 22,
    color: '#687083',
  },
  validating: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 22,
    color: yellow600,
  },
  validatingContainer: {
    flexDirection: 'row',
  },
  validatingDescription: {
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    paddingTop: 1,
  },
  iconChevronContainer: {
    display: 'flex',
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    marginTop: 12,
  },
  providerImage: {
    height: 48,
    width: 48,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
})
