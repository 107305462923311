import activityTypes from '@constants/activityTypes'
import {
  harvestValidationSchema,
  monitoringValidationSchema,
  agreementValidationSchema,
  verificationValidationSchema,
  irrigationValidationSchema,
  achievementUnPlannedValidationSchema,
  floodValidationSchema,
} from './index'

export const getFormikValidationSchema = (t, tag, isDirectAchievement) => {
  switch (tag) {
    case activityTypes.ACT_HARVEST.key:
      return harvestValidationSchema(t)

    case activityTypes.ACT_MONITORING.key:
      return monitoringValidationSchema(t)

    case activityTypes.ACT_AGREEMENTS.key:
      return agreementValidationSchema(t)

    case activityTypes.ACT_VERIFICATION.key:
      return verificationValidationSchema(t)

    case activityTypes.ACT_IRRIGATION.key:
      return irrigationValidationSchema(t)

    case activityTypes.ACT_FLOOD.key:
      return floodValidationSchema(t)
  }

  if (isDirectAchievement) {
    return achievementUnPlannedValidationSchema(t, tag)
  }
}
