import React from 'react'
import { CommonProvider } from './common'
import { CropProvider } from './crop'
import { SelectorProvider } from './selectors'
import { EstablishmentsProvider } from './establishment'
import { LicensesProvider } from './license'
import { LanguageProvider } from './language'

function ProviderComposer({ contexts, children }) {
  return contexts.reduceRight(
    (kids, parent) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children
  )
}

const contexts = [
  <LanguageProvider key={2} />,
  <CommonProvider key={3} />,
  <CropProvider key={4} />,
  <SelectorProvider key={5} />,
  <EstablishmentsProvider key={6} />,
  <LicensesProvider key={7} />,
]

function ContextProvider({ children }) {
  return <ProviderComposer contexts={contexts}>{children}</ProviderComposer>
}

export { ContextProvider }
