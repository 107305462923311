import moment from 'moment'
import activityTypesConstants from '@constants/activityTypes'
import { calculateEiq, getEIQRange } from '@utils/eiq'
import { formatDateMoment } from '@utils/date'
import { EEvidenceConcepts } from '../../../../../../common/enum/evidenceConcepts'

export const parseDraftDataToFormik = ({
  t,
  verificationTypes,
  unitTypes,
  agreementTypes,
  formikInitialValues,
  draft,
  subTypeActivities,
  tag,
  locale,
  unitTypesVolume,
  irrigationPowerSources,
  irrigationWaterSources,
  evidenceConcept,
  paddyWaterCultivations,
  paddyWaterPreseasons,
}) => {
  const newFormikData = {}
  const farmsDraft = draft?.farms
  let labelFormik = ''
  if (farmsDraft?.length) {
    labelFormik =
      farmsDraft.length > 1
        ? t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FARM_FIELD.PLURAL', {
            num: farmsDraft.length,
          })
        : t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FARM_FIELD.SINGULAR', {
            num: farmsDraft.length,
          })
  } else {
    const newLabel = draft?.lots?.length
      ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
      : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')
    labelFormik = draft.lots.length ? `${draft.lots.length} ${newLabel}` : ''
  }

  const labelFormikLots = labelFormik

  newFormikData.lots = {
    value: draft.lots.map((element) => ({
      _id: element._id,
      surface: element.surface,
      tag: element.tag,
      wktFormat: element.wktFormat,
      farm: element.farm,
    })),
    label: labelFormikLots,
  }

  newFormikData.surface = draft.surface

  const typeAgreementObject = agreementTypes.find(
    (element) => element.value === draft.typeAgreement
  )

  if (draft.dateHarvest) {
    newFormikData.dateHarvest = {
      value: moment(draft.dateHarvest).toDate(),
      label: moment(draft.dateHarvest).format('DD/MM/YYYY'),
    }
  }

  if (draft.dateObservation) {
    newFormikData.dateObservation = {
      value: moment(draft.dateObservation).toDate(),
      label: moment(draft.dateObservation).format('DD/MM/YYYY'),
    }
  }

  if (draft.dateEstimatedHarvest) {
    newFormikData.dateEstimatedHarvest = {
      value: moment(draft.dateEstimatedHarvest).toDate(),
      label: moment(draft.dateEstimatedHarvest).format('DD/MM/YYYY'),
    }
  }

  if (draft.verificationType) {
    newFormikData.verificationType = verificationTypes.find(
      (element) => element.value === draft.verificationType
    )
  }

  if (draft.unitType) {
    newFormikData.unitType = unitTypes.find(
      (element) => element.value === draft.unitType
    )
  }

  if (draft.typeAgreement) {
    newFormikData.typeAgreement = typeAgreementObject
  }

  if (draft.observation) {
    newFormikData.observation = draft.observation
  }

  if (draft.observations) {
    newFormikData.observations = draft.observations
  }

  if (draft.pay) {
    newFormikData.pay = String(draft.pay)
  }

  if (draft?.volume) {
    newFormikData.volume = String(draft?.volume)
  }

  if (draft?.unitVolume) {
    newFormikData.unitVolume = unitTypesVolume.find(
      (element) => element.value === draft.unitVolume
    )
  }

  if (draft.signers) {
    newFormikData.signers = draft.signers
  }

  if (draft.evidences) {
    newFormikData.evidences = draft?.isRejected
      ? filterTypeAgreementIntegrationEvidences({
          typeAgreement: typeAgreementObject,
          evidenceConcept,
          evidences: draft?.evidences,
        })
      : draft?.evidences
  }

  if (draft.storages) {
    newFormikData.storages = draft.storages
  }

  if (draft.draftGroupId) {
    newFormikData.draftGroupId = draft.draftGroupId
  }

  if (draft.isRejected) {
    newFormikData.isRejected = draft.isRejected
  }

  if (draft.subTypeActivity) {
    newFormikData.subTypeActivity = subTypeActivities.find(
      (element) => element.value === draft.subTypeActivity
    )
  }

  if (draft.dateAchievement) {
    newFormikData.dateAchievement = {
      value: moment(draft.dateAchievement).toDate(),
      label: moment(draft.dateAchievement).format('DD/MMM/YYYY'),
    }
  }

  if (draft.supplies) {
    newFormikData.supplies = draft.supplies
    if (tag === activityTypesConstants.ACT_APPLICATION.key) {
      const totalEiqAllSupplies = calculateEiq(draft.supplies, draft.surface)
      const range = getEIQRange(totalEiqAllSupplies)
      newFormikData.eiqSupplies = { totalEiqAllSupplies, range }
    }
  }

  if (draft.dateAchievement) {
    newFormikData.dateAchievement = {
      value: moment(draft.dateAchievement).toDate(),
      label: formatDateMoment(
        draft.dateAchievement,
        'DD/MMM/YYYY',
        locale,
        true
      ),
    }
  }

  if (draft.canPlanning) {
    newFormikData.canPlanning = draft.canPlanning
  }

  if (draft.seedDryContent) {
    newFormikData.seedDryContent = String(draft.seedDryContent)
  }

  if (draft.dataForCFT) {
    newFormikData.dataForCFT = draft.dataForCFT
  }

  if (draft.establishmentFormId) {
    newFormikData.establishmentFormId = draft.establishmentFormId
  }

  if (draft.emissionCO2) {
    newFormikData.emissionCO2 = draft.emissionCO2
  }

  if (draft.summaryEmissionCO2) {
    newFormikData.summaryEmissionCO2 = draft.summaryEmissionCO2
  }

  if (draft?.farms) {
    newFormikData.farms = draft?.farms.map((farm) => ({
      ...farm,
      _id: farm._id,
      tag: farm.tag ?? farm.name,
      farm: farm.farm ?? farm._id,
    }))
  }

  if (draft.irrigationPowerSource) {
    newFormikData.irrigationPowerSource = irrigationPowerSources.find(
      (element) => element.value === draft.irrigationPowerSource
    )
  }
  if (draft.irrigationWaterSource) {
    newFormikData.irrigationWaterSource = irrigationWaterSources.find(
      (element) => element.value === draft.irrigationWaterSource
    )
  }
  if (draft.pumpingDepth) {
    newFormikData.pumpingDepth = draft?.pumpingDepth
  }
  if (draft.waterConsumption) {
    newFormikData.waterConsumption = draft?.waterConsumption
  }
  if (draft.horizontalWateringDepth) {
    newFormikData.horizontalWateringDepth = draft?.horizontalWateringDepth
  }

  if (draft.weatherType) {
    newFormikData.weatherType = parseToOptionValue(draft?.weatherType, locale)
  }

  if (draft.temperature) {
    newFormikData.temperature = String(draft?.temperature)
  }

  if (draft.soilType) {
    newFormikData.soilType = parseToOptionValue(draft?.soilType, locale)
  }

  if (draft.landType) {
    newFormikData.landType = parseToOptionValue(draft?.landType)
  }

  if (draft.humidityType) {
    newFormikData.humidityType = parseToOptionValue(draft?.humidityType)
  }

  if (draft.formResponse) {
    newFormikData.formResponse = draft?.formResponse
  }

  if (draft.timeFloodUnderCrop) {
    newFormikData.timeFloodUnderCrop = String(draft?.timeFloodUnderCrop)
  }

  if (draft.unitTime) {
    newFormikData.unitTime = unitTypes.find(
      (element) => element.value === draft.unitTime
    )
  }

  if (draft.paddyWaterCultivation) {
    newFormikData.paddyWaterCultivation = paddyWaterCultivations?.find(
      (element) => element.value === draft.paddyWaterCultivation
    )
  }

  if (draft.paddyWaterPreseason) {
    newFormikData.paddyWaterPreseason = paddyWaterPreseasons?.find(
      (element) => element.value === draft.paddyWaterPreseason
    )
  }

  return {
    ...formikInitialValues,
    ...newFormikData,
  }
}

// TODO: Think to pass this logic to backend during draft generation
/**
 * The function filters a list of evidences based on the allowed evidence types specified in a type
 * agreement.
 * @returns The function `filterTypeAgreementIntegrationEvidences` returns an array of evidence types
 * that are allowed based on the `typeAgreement` and `evidences` provided as input. If `typeAgreement`
 * has a `subType` property, it checks for `evidenceTypesAllowed` in `typeAgreement` and filters the
 * `evidences` array based on whether
 */
const filterTypeAgreementIntegrationEvidences = ({
  typeAgreement,
  evidenceConcept,
  evidences = [],
}) => {
  if (typeAgreement?.subType === 'INTEGRATION') {
    const heredableEvidenceConcepts = [EEvidenceConcepts['0002']]

    return evidences.filter((evidence) => {
      const evidenceConceptObject = evidenceConcept.find(
        ({ _id }) => _id === evidence.evidenceConcept
      )

      return heredableEvidenceConcepts.includes(evidenceConceptObject.code)
    })
  }

  return evidences
}

const parseToOptionValue = (obj, locale = 'en') => {
  if (obj) {
    const hasKeys = Object.keys(obj).some(
      (key) => key === 'label' || key === 'value'
    )
    if (!hasKeys) {
      const nameByLocale = obj?.name[locale] ?? obj?.name['en']
      const parsedObj = {
        label: nameByLocale ?? '',
        value: obj._id,
      }
      return parsedObj
    }
    return obj
  }
  return null
}
