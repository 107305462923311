import { View, Text, StyleSheet } from 'react-native'
import React from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { carbon300, primary500 } from '@styles/palette'

export const ItemFarm = ({ farm, onSelectFarm, farmSelected }) => {
  return (
    <View style={styles.contentItem}>
      <View style={styles.contentIcon}>
        <MaterialCommunityIcons
          name={
            String(farm?._id) === String(farmSelected?._id)
              ? 'check-circle'
              : 'checkbox-blank-circle-outline'
          }
          size={24}
          color={primary500}
          style={styles.lotIcon}
          onPress={() => onSelectFarm(farm)}
        />
      </View>
      <View style={styles.contentText}>
        <Text style={styles.textFarm}>{farm.tag}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 63,
  },
  contentIcon: {
    marginLeft: 18,
    marginRight: 34,
  },
  contentText: {
    flex: 1,
    flexDirection: 'row',
    height: 63,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: carbon300,
  },
  textFarm: {
    fontSize: 16,
    fontWeight: '500',
  },
})
