import { useCallback, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useNavigation, useRoute } from '@react-navigation/native'

import useFetchPagination from '@common/hooks/useFetchPagination'
import { TYPES_LIST_COMPANIES } from '@utils/constants'
import useNetwork from '@utils/network'
import { CompanyInterface } from '../interfaces/company.interface'
import RouterNames from '@constants/routerNames'
import { RoleEnum } from '@common/enum'
import { getInitialRoute } from 'src/navigator/DrawerNavigator'
import { useAuth } from '@common/hooks'

const useFetchCompanies = () => {
  const route: any = useRoute()
  const navigation = useNavigation()
  const { doRequest, loading } = useNetwork()
  const { config, handleUser, isCompanyUcropit, isVerifierUcropit, fetchUser } =
    useAuth()

  const { isAdviser } = route.params
  const companyRoute =
    !isAdviser && config.companyAdmin
      ? config.companyAdmin
      : config.companySelected

  const [companySelected, setCompanySelected] = useState(companyRoute)
  const [searchValue, setSearchValue] = useState('')
  const responseCompanies = isAdviser
    ? TYPES_LIST_COMPANIES.ASSOCIATED
    : TYPES_LIST_COMPANIES.MANAGED
  const inputRef = useRef(null)
  const {
    response: { data },
    isLoading,
    nextPage,
    refreshPage,
  } = useFetchPagination({
    url: 'users/companies',
    version: 'v2',
    method: 'GET',
    params: {
      responseCompanies,
      created: Date.now(),
      search: searchValue,
    },
  })

  const isTrader = config?.roleSelected?.value === RoleEnum.PRODUCTIVE_UNIT
  const idCompanyAdmin = () =>
    config?.companyAdmin?._id || config?.companySelected?._id

  const onChangeCompany = async (company: CompanyInterface) => {
    const data = {
      companySelected: company._id,
      companyAdmin: isAdviser ? idCompanyAdmin() : company._id,
    }
    const configResponse = await doRequest({
      method: 'PUT',
      url: `configurations/${config._id}`,
      data,
    })
    await handleUser(configResponse.data)
    await fetchUser()
    const { roleSelected } = configResponse.data.config

    const initialRoute = getInitialRoute(
      roleSelected.value,
      RouterNames.FARMS
    ) as never

    navigation.navigate(initialRoute)
  }

  const handleChange = (company: CompanyInterface) => {
    setCompanySelected(company)
  }

  const goCompanyAdd = () => {
    navigation.navigate({
      name: RouterNames.COMPANY_ADD,
      params: {
        isAdviser,
      },
    } as never)
  }
  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  const searchCompanies = () => {
    refreshPage()
  }

  const handleDebounceSearch = useCallback(debounce(searchCompanies, 500), [])

  const clearSearchCompany = () => {
    setSearchValue('')
    refreshPage()
  }

  return {
    onChangeCompany,
    companySelected,
    loading,
    goCompanyAdd,
    isAdviser,
    isCompanyUcropit,
    isVerifierUcropit,
    data,
    inputRef,
    handleChange,
    nextPage,
    refreshPage,
    isLoading,
    handleSearch,
    searchValue,
    handleDebounceSearch,
    clearSearchCompany,
    isTrader,
  }
}

export default useFetchCompanies
