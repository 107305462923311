import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  unitTypesVolume: [],
}
export const unitTypeVolumeSlice = createSlice({
  name: 'unitTypesVolume',
  initialState,
  reducers: {
    setUnitTypeVolume: (state, action: PayloadAction<any>) => {
      state.unitTypesVolume = action.payload
    },
    resetUnitTypeVolume: (state) => {
      state.unitTypesVolume = initialState.unitTypesVolume
    },
  },
})

export const { setUnitTypeVolume, resetUnitTypeVolume } =
  unitTypeVolumeSlice.actions

export const { reducer: unitTypeVolumeReducer } = unitTypeVolumeSlice
