import { FC, useContext, ReactElement } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import {
  black,
  gray300,
  gray500,
  green100,
  green200,
  green500,
  green700,
} from '@common/styles'

import { LanguageContext } from '@contextState/language'
import InputText from '@components/inputs/InputText'
import { CommonIcon } from '@modules/common/components'
import { truncateSurface } from '@common/utils/truncateSurface'
import { SIZES } from '@common/components/icons/interfaces'
import ButtonPrimary from '@components/buttons/ButtonPrimary'

export const LandScapeSustainabilityReportForm: FC<any> = ({
  onPressLotsInput,
  formik,
  areaUnit,
  readOnly,
  goToEditFarm,
}: any): ReactElement => {
  const { t } = useContext(LanguageContext)

  const { values, setFieldTouched, touched, errors } = formik
  const { farms } = values

  const RenderNeedFarmSurfaceContainer = () => {
    return !Number(values.surface) && farms?.length ? (
      <View style={styles.generateEmissionBeforeSendContainer}>
        <View style={styles.generateEmissionAfterSendIconContainer}>
          <CommonIcon name='ALERT-CIRCLE' size={19.6} color={green700} />
        </View>
        <Text style={styles.generateEmissionBeforeSendTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE.STATEMENT_SDP_REPORT_NEED_SURFACE_FARM.TITLE'
          )}
        </Text>

        <Text style={styles.generateEmissionBeforeSendText}>
          {t(
            'VIEWS.ACTIVITY_CREATE.STATEMENT_SDP_REPORT_NEED_SURFACE_FARM.DESCRIPTION'
          )}
        </Text>

        <ButtonPrimary
          text={t(
            'VIEWS.ACTIVITY_CREATE.STATEMENT_SDP_REPORT_NEED_SURFACE_FARM.REDIRECT_EDIT_FARM_TEXT'
          )}
          onPress={goToEditFarm}
          backgroundColor={'transparent'}
          disabled={false}
          colorText={green500}
          marginTop={8}
        />
      </View>
    ) : (
      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />
    )
  }

  return (
    <>
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={SIZES.MEDIUM}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')}
        label={t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <RenderNeedFarmSurfaceContainer />
    </>
  )
}

LandScapeSustainabilityReportForm.displayName =
  'LandScapeSustainabilityReportForm'

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
  generateEmissionBeforeSendContainer: {
    borderColor: green200,
    borderWidth: 1,
    backgroundColor: green100,
    borderRadius: 8,
    padding: 24,
    marginVertical: 8,
  },
  generateEmissionBeforeSendTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: green700,
  },
  generateEmissionBeforeSendText: {
    fontSize: 14,
    fontWeight: '400',
    color: green700,
    marginTop: 8,
  },
  generateEmissionAfterSendContainer: {
    flexDirection: 'row',
    paddingTop: 24,
    paddingHorizontal: 12,
    marginVertical: 8,
  },
  generateEmissionAfterSendIconContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 55,
  },
  generateEmissionAfterSendTextContainer: {
    borderBottomColor: gray300,
    borderBottomWidth: 1,
    paddingBottom: 24,
    flex: 1,
  },
  generateEmissionAfterSendTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: black,
  },
  generateEmissionAfterSendText: {
    fontSize: 14,
    fontWeight: '400',
    color: gray500,
    marginTop: 8,
  },
})
