import ACTIVITY_CREATE from '../../screens/activityCreate/v1/translate/es'
import OPTIONAL_PLANNED from '../../screens/optionalPlanned/v1/translate/es'
import EMPTY_STATE from '../../screens/activitiesList/v1/translate/es'
import FILTER_ACTIVITY from '../../screens/Filter/v1/translate/es'
import SUPPLIES_ACTIVITY from '../../screens/SupplyLoader/v1/translate/es'

export default {
  ACTIVITY_CREATE,
  OPTIONAL_PLANNED,
  EMPTY_STATE,
  FILTER_ACTIVITY,
  SUPPLIES_ACTIVITY,
}
