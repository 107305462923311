import LICENSE_BUTTON from './license-button'
import LICENSE_DETAIL from './license-detail'
import LICENSES_EMPTY from './licenses-empty'
import LICENSES_LIST from './licenses-list'

export default {
  LICENSE_BUTTON,
  LICENSE_DETAIL,
  LICENSES_EMPTY,
  LICENSES_LIST,
}
