export const getOulierLevel = (
  outlierSowingLevel,
  outlierHarvestLevel,
  outlierFertilizerLevel,
  outlierActiveIngredientLevel
) => {
  const outlierLevelArray = []
  let outlierLevel
  if (outlierSowingLevel) {
    const positiveNumber = getPositiveNumber(outlierSowingLevel)
    outlierLevelArray.push(positiveNumber)
  }
  if (outlierHarvestLevel) {
    const positiveNumber = getPositiveNumber(outlierHarvestLevel)
    outlierLevelArray.push(positiveNumber)
  }
  if (outlierFertilizerLevel) {
    const positiveNumber = getPositiveNumber(outlierFertilizerLevel)
    outlierLevelArray.push(positiveNumber)
  }
  if (outlierActiveIngredientLevel) {
    const positiveNumber = getPositiveNumber(outlierActiveIngredientLevel)
    outlierLevelArray.push(positiveNumber)
  }
  if (outlierLevelArray.length) {
    outlierLevel = Math.max(...outlierLevelArray)
  }
  return outlierLevel
}

const getPositiveNumber = (number) => {
  const positiveNumber = Math.sign(number) === -1 ? number * -1 : number
  return positiveNumber
}
