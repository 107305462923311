import React from 'react'
import { FlatList, Platform } from 'react-native'
import { PropTypes } from 'prop-types'

import { FarmItem } from '../'

export const FarmList = ({
  farms,
  onPressFieldDetails,
  header,
  emptyComponent,
}) => {
  const farmRenderItem = ({ item: farm, index }) => (
    <FarmItem
      farm={farm}
      index={index}
      onPressFieldDetails={onPressFieldDetails}
    />
  )

  return (
    <FlatList
      ListHeaderComponent={header}
      data={farms}
      renderItem={farmRenderItem}
      keyExtractor={(item, index) => `FARM_${index}`}
      showsVerticalScrollIndicator={Platform.OS === 'web'}
      ListEmptyComponent={emptyComponent}
    />
  )
}

FarmList.propTypes = {
  farms: PropTypes.array.isRequired,
  header: PropTypes.object.isRequired,
  emptyComponent: PropTypes.object.isRequired,
  onPressFieldDetails: PropTypes.func.isRequired,
}
