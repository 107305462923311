import React from 'react'
import { Snackbar } from 'react-native-paper'

const SnackbarCustom = ({ visible, onToggle, children, duration = 7000 }) => {
  return (
    <Snackbar visible={visible} duration={duration} onDismiss={onToggle}>
      {children}
    </Snackbar>
  )
}

export default SnackbarCustom
