export default {
  TEXT_1:
    'Before adding a collaborator you have to add at least one piece of evidence',
  TEXT_2: 'Add destination',
  TEXT_3: 'Add evidence',
  TEXT_4: 'Ton',
  TEXT_5: 'Save',
  TEXT_6: 'Sign',
  TEXT_7: 'Signed',
  TEXT_8: 'Sign',
  TEXT_9: 'Short ton',
}
