import { useEffect, useRef } from 'react'
import { io } from 'socket.io-client'

export const useSocket = ({ url }: any) => {
  const socket = useRef<any>(null)

  useEffect(() => {
    socket.current = io(url, {
      autoConnect: false,
      // extraHeaders: {
      //   'ws-auth-token': token,
      // },
    })
  }, [])

  const emit = (event: string, data: any) => {
    socket.current.emit(event, data)
  }

  const on = (event: string, callback: any) => {
    socket.current.on(event, callback)
  }

  const off = (event: string, callback: any) => {
    socket.current.off(event, callback)
  }

  const close = () => {
    socket.current.close()
  }

  const connect = () => {
    socket.current.connect()
  }

  const disconnect = () => {
    socket.current.disconnect()
  }

  return {
    emit,
    on,
    off,
    close,
    connect,
    disconnect,
  }
}
