import React, { useContext } from 'react'
import { Text, StyleSheet } from 'react-native'
import { LanguageContext } from '@contextState/language'
import { EvidenceCard, CollaboratorCard, CardInfoParameters } from '..'
import { getDataRenderResumeFlood } from '../../utils/getDateRenderResumeFlood'

export const FloodResume = ({
  cropName,
  companyName,
  fieldsQuantity,
  surface,
  unitArea,
  dateAchievement,
  timeFloodUnderCrop,
  unitTime,
  paddyWaterCultivation,
  paddyWaterPreseason,
  evidences,
  collaboratorsQuantity,
}: any) => {
  const { t }: any = useContext(LanguageContext)

  const { itemsCrop, itemsActivity, itemsFlood, itemsTimeFlood } =
    getDataRenderResumeFlood(
      {
        cropName,
        companyName,
        fieldsQuantity,
        surface,
        unitArea,
        dateAchievement,
        timeFloodUnderCrop,
        unitTime,
        paddyWaterCultivation,
        paddyWaterPreseason,
      },
      t
    )
  const HeaderFlood = (
    <Text style={styles.titleFlood}>
      {t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.WATER_MANAGEMENT')}
    </Text>
  )

  return (
    <>
      <CardInfoParameters items={itemsCrop} />
      <CardInfoParameters items={itemsTimeFlood} />
      <CardInfoParameters items={itemsActivity} />
      <CardInfoParameters items={itemsFlood} header={HeaderFlood} />
      <EvidenceCard evidences={evidences} />
      <CollaboratorCard collaboratorsQuantity={collaboratorsQuantity} />
    </>
  )
}

const styles = StyleSheet.create({
  contentActivity: { marginLeft: 7 },
  textTypeActivity: {
    fontSize: 16,
    fontWeight: '500',
    textTransform: 'capitalize',
  },
  textSubTypeActivity: {
    fontSize: 16,
  },
  titleFlood: {
    marginTop: 14,
    marginLeft: 16,
    marginBottom: 6,
    fontWeight: '400',
  },
})
