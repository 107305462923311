export default {
  CREATE_NEW_FERTILIZER: 'Create new fertilizer',
  GENERIC: 'Generic',
  COMPOSITION: 'Composition',
  ENTER_THE_PERCENTAGE_OF_THE_COMPOSITION:
    'Enter the percentage of the composition and the unit of measurement to be used in its application.',
  THE_COMPOSITION_CANNOT_EXCEED_HUNDRED_PERCENT:
    'The composition cannot exceed 100%.',
  ENTER_THE_AMOUNT_APPLIED_IN_KG_OF_FERTILIZER:
    'Enter the amount applied in {{kg}} of fertilizer.',
  NUMBER_OF_KG: '{{Number of kg}}',
  N_NITROGEN_PERCENT: 'N Nitrogen %',
  P_PHOSPHORUS_PERCENT: 'P Phosphorus %',
  K_POTASSIUM_PERCENT: 'K Potassium %',
  CA_CALCIUM_PERCENT: 'Ca Calcium %',
  S_SULFUR_PERCENT: 'S Sulfur %',
  UNIT_OF_MEASURE: 'Unit of measurement',
  ADD_NEW_GENERIC_FERTILIZER: 'Add your generic fertilizers',
  ADD_NEW_GENERIC_FERTILIZER_EASILY:
    'Easily add them to the list from the button',
  PHOSPHORUS: 'Phosphorus',
  P2O5: 'P₂O₅',
  NITROGEN: 'Nitrogen',
  BY_COMPOSITION: 'By composition',
  FOUNTAIN: 'Fountain',
  CONVENTIONAL: 'Conventional',
  GREEN: 'Green',
  N_AMMONIACAL: 'N Ammoniacal',
  N_NITRIC: 'N Nitric',
  N_UREAIC: 'N Ureaic',
  POTASSIUM: 'Potassium',
  K2O5: 'K₂O₅',
  CALCIUM: 'Calcium',
  CACO3: 'CaCO₃',
  SULFUR: 'Sulfur',
  SO4: 'SO₄',
  CREATE_AND_SELECTED_FERTILIZER: 'Create and select new fertilizer',
  CHEMICAL_COMPOSITION: 'Chemical composition',
  ENTER_CHEMICAL_COMPOSITION:
    'Enter the chemical composition of the fertilizer.',
}
