import { getEiqOfSupply } from './eiq'
import { round } from 'lodash'

const getBrand = (brand = null, supply = null) => {
  if (brand) {
    return brand
  }

  if (typeof supply === 'object') {
    if (supply?.brand) {
      return supply?.brand
    }
  }

  return null
}

const getDescriptionDoseApplied = (
  { supply, unitName, quantity, total, areaUnit, unit, unitTypeSupply },
  surface
) => {
  const quantitySurface = surface ? total / surface : quantity

  if (supply) {
    return (
      supply
        ? `${round(Number(quantitySurface), 6)} ${
            supply?.unitTypeSupply?.keyLabel ??
            supply?.unitName ??
            supply?.unit ??
            unitName ??
            unit ??
            ''
          }/${areaUnit} ${round(Number(total), 6)} ${
            supply?.unitTypeSupply?.keyLabel ??
            supply?.unitName ??
            supply?.unit ??
            unitName ??
            unit ??
            ''
          }`
        : ''
    ).toLowerCase()
  }

  const newUnitName = unitTypeSupply?.keyLabel || unitName

  return `${round(Number(quantitySurface), 6)} ${
    newUnitName
      ? `${newUnitName.toLowerCase()}/${areaUnit} - ${round(
          Number(total),
          6
        )} ${newUnitName.toLowerCase()}`
      : ''
  }`
}

const getDescriptionSupplySelect = (
  {
    name,
    supply,
    typeId,
    typeSupply,
    eiqTotal,
    company,
    quantity,
    unitName,
    unit,
    total,
    brand,
    unitTypeSupply,
  },
  areaUnit = 'ha',
  surface
) => {
  const brandString = getBrand(brand, supply)

  const companySupply = typeof supply === 'object' ? supply?.company : null
  const supplyTypeName =
    typeId?.codeLabel || typeSupply?.codeLabel || typeSupply?.name || null

  const eiqSupply = getEiqOfSupply({ supply, eiqTotal })
  const companyText = company ?? companySupply

  const eiqText = !isNaN(eiqSupply)
    ? `EIQ ${round(Number(eiqSupply), 2)}`
    : null

  /** Get description string with applied dose per area */
  const descriptionDoseApplied = getDescriptionDoseApplied(
    {
      supply,
      unitName,
      quantity,
      total,
      areaUnit,
      unit,
      unitTypeSupply,
    },
    surface
  )

  const description = `${supplyTypeName ?? ''} ${
    companyText ? `\n${companyText}` : ''
  } ${descriptionDoseApplied ? `\n${descriptionDoseApplied}` : ''} ${
    eiqText ? `\n${eiqText}` : ''
  }`

  return {
    name: brandString ?? name,
    description: description,
  }
}

export { getDescriptionSupplySelect }
