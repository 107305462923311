import React from 'react'
import { PropTypes } from 'prop-types'
import { StyleSheet, ScrollView, View } from 'react-native'

import { FormikProvider } from 'formik'

import { InputMultiSelect, InputSelect } from '@modules/common/components'
import ButtonCustom from '@components/common/ButtonCustom'
import { AccordionList, EmptyStateInput } from './components'

import { white, primary500, grey300, blackDisabled } from '@styles/palette'
import { useNotificationFilters } from './hooks'

const Filters = () => {
  const {
    ITEM_PER_PAGE_CROP_TYPES,
    ITEM_PER_PAGE_ACTIVITY_TYPES,
    t,
    formik,
    values,
    onChangeTextFarm,
    onSelectedFarm,
    showEmptyStateFarmList,
    showEmptyStateCropList,
    onChangeTextCrop,
    onSelectedCrop,
    paginateArray,
    currentPageCropTypes,
    showloadMoreCropTypes,
    loadMoreCropTypes,
    currentPageActivityTypes,
    showloadMoreActivityTypes,
    loadMoreActivityTypes,
    onSubmit,
    isValid,
  } = useNotificationFilters()

  return (
    <View style={styles.container}>
      <ScrollView style={styles.container}>
        <FormikProvider value={formik}>
          <InputMultiSelect
            label={t('VIEWS').NOTIFICATION_FILTER.FIELD_NAME}
            placeholder={t('VIEWS').NOTIFICATION_FILTER.FIELD_NAME}
            contentStyle={styles.inputFarmName}
            data={values.farmList}
            dataSelected={values.farmListSelected}
            value={values.farmName}
            onChangeText={onChangeTextFarm}
            onSelected={onSelectedFarm}
            onRemoved={onSelectedFarm}
            showEmptyState={showEmptyStateFarmList}
            emptyStateComponent={() => (
              <EmptyStateInput name={values.farmName} />
            )}
          />

          <InputSelect
            label={t('VIEWS').NOTIFICATION_FILTER.CROP_NAME}
            placeholder={t('VIEWS').NOTIFICATION_FILTER.CROP_NAME}
            contentStyle={styles.inputCropName}
            data={values.cropList}
            value={values.cropName}
            onChangeText={onChangeTextCrop}
            onSelected={onSelectedCrop}
            showEmptyState={showEmptyStateCropList}
            emptyStateComponent={() => (
              <EmptyStateInput name={values.cropName} />
            )}
          />

          <AccordionList
            fieldName={'cropTypes'}
            title={t('VIEWS').NOTIFICATION_FILTER.TYPE_OF_CROP}
            data={paginateArray(
              values.cropTypes,
              ITEM_PER_PAGE_CROP_TYPES,
              currentPageCropTypes
            )}
            showLoadMore={showloadMoreCropTypes}
            textLoadMore={t('VIEWS').NOTIFICATION_FILTER.SEE_MORE}
            onLoadMore={loadMoreCropTypes}
          />

          <AccordionList
            fieldName={'activityTypes'}
            title={t('VIEWS').NOTIFICATION_FILTER.ACTIVITY}
            data={paginateArray(
              values.activityTypes,
              ITEM_PER_PAGE_ACTIVITY_TYPES,
              currentPageActivityTypes
            )}
            textLoadMore={t('VIEWS').NOTIFICATION_FILTER.SEE_MORE}
            seeMoreStyle={styles.seeMore}
            showLoadMore={showloadMoreActivityTypes}
            onLoadMore={loadMoreActivityTypes}
          />
        </FormikProvider>
      </ScrollView>

      <ButtonCustom
        onPress={onSubmit}
        styles={[
          styles.button,
          isValid ? styles.buttonEnable : styles.buttonDisabled,
        ]}
        labelStyle={[
          styles.textButton,
          isValid ? styles.textButtonEnable : styles.textButtonDisable,
        ]}
        disabled={!isValid}
      >
        {t('VIEWS.NOTIFICATION_FILTER.APPLY')}
      </ButtonCustom>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white,
  },
  inputFarmName: {
    marginTop: 17,
    paddingHorizontal: 16,
  },
  inputCropName: {
    marginTop: 20,
    paddingHorizontal: 16,
  },
  seeMore: {
    marginBottom: 20,
  },
  accordionList: {
    marginLeft: 16,
  },
  button: {
    backgroundColor: primary500,
    marginHorizontal: 16,
    marginVertical: 5,
  },
  buttonEnable: {
    backgroundColor: primary500,
  },
  buttonDisabled: {
    backgroundColor: grey300,
  },
  textButton: {
    color: white,
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: 14,
    letterSpacing: 0.75,
    lineHeight: 16,
  },
  textButtonEnable: {
    color: white,
  },
  textButtonDisable: {
    color: blackDisabled,
  },
})

Filters.prototype = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default Filters
