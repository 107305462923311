import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  unitTypes: [],
}
export const unitTypesSlice = createSlice({
  name: 'unitTypes',
  initialState,
  reducers: {
    /**
     * @param {object} state
     * @property {string} state.url
     * @property {func} state.onMessage
     *
     * @param {object} action
     * @property {object} action.payload
     * @property {string} action.payload.url
     * @property {func} action.payload.onMessage
     */
    setUnitTypes: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        unitTypes: action.payload,
      }
      return state
    },
    resetUnitTypes: (state) => {
      state = initialState
      return state
    },
  },
})

export const { setUnitTypes, resetUnitTypes } = unitTypesSlice.actions

export const { reducer: unitTypesReducer } = unitTypesSlice
