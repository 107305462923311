import React, { FC } from 'react'
import { StyleSheet, View } from 'react-native'
import { TimeLineDetail } from './components/detail'
import { EAppIntegrationStatus } from '@common/enum/appIntegrationStatus'

export const TimeLineEvent: FC<any> = ({ appData }) => {
  const statusFilter = appData?.some(
    (status: any) => status.key === EAppIntegrationStatus.SYNCHRONIZED
  )
    ? appData?.filter(
        (status: any) => status.key !== EAppIntegrationStatus.SYNCHRONIZING
      )
    : appData

  const hasConnected = statusFilter?.some(
    (status: any) => status.key === EAppIntegrationStatus.CONNECTED
  )
  const hasSynchronized = statusFilter?.some(
    (status: any) => status.key === EAppIntegrationStatus.SYNCHRONIZED
  )

  const hasSynchronizing = statusFilter?.some(
    (status: any) => status.key === EAppIntegrationStatus.SYNCHRONIZING
  )

  return (
    <View style={styles.container}>
      {statusFilter?.map((status: any, index: React.Key | null | undefined) => (
        <View key={index}>
          <TimeLineDetail status={status} key={String(index)} />
          {hasConnected && !hasSynchronized && !hasSynchronizing && (
            <TimeLineDetail
              status={{ key: EAppIntegrationStatus.SYNCHRONIZING }}
            />
          )}
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 50,
  },
})
