import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { List, Divider } from 'react-native-paper'
import { PropTypes } from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@modules/common/components'

function CompanyServiceIntegrationAccountScreen({ route, navigation }) {
  const { t } = useContext(LanguageContext)
  return (
    <View style={styles.container}>
      <View>
        <React.Fragment>
          <List.Item
            description={t('VIEWS').COMPANY_SERVICE_INTEGRATION_ACCOUNT.TEXT_1}
            title={t('VIEWS').COMPANY_SERVICE_INTEGRATION_ACCOUNT.TEXT_2}
            titleStyle={styles.title}
            left={() => (
              <View style={styles.iconContainer}>
                <CommonIcon name='SETTINGS' size={20} />
              </View>
            )}
            right={() => (
              <View style={styles.iconContainer}>
                <CommonIcon name='CHEVRON-RIGHT' size={20} />
              </View>
            )}
            onPress={() =>
              navigation.navigate('CompanyServiceIntegrationAdd', {
                id: route.params.id,
                mode: 'edit',
                service: route.params.service,
              })
            }
          />
          <Divider />
          <List.Item
            description={t('VIEWS').COMPANY_SERVICE_INTEGRATION_ACCOUNT.TEXT_3}
            title={t('VIEWS').COMPANY_SERVICE_INTEGRATION_ACCOUNT.TEXT_4}
            titleStyle={styles.title}
            left={(props) => (
              <View style={styles.iconContainer}>
                <CommonIcon name='X-CIRCLE' size={20} />
              </View>
            )}
            right={() => (
              <View style={styles.iconContainer}>
                <CommonIcon name='CHEVRON-RIGHT' size={20} />
              </View>
            )}
            onPress={() =>
              navigation.navigate('CompanyServiceIntegrationUnlink', {
                id: route.params.id,
                service: route.params.service,
              })
            }
          />
        </React.Fragment>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    marginBottom: 25,
    justifyContent: 'space-between',
  },
  title: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'flex-start',
    fontWeight: 'bold',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

CompanyServiceIntegrationAccountScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CompanyServiceIntegrationAccountScreen
