export default {
  TEXT_1: 'planejada',
  TEXT_2: 'ha',
  TEXT_3: 'Aplicação',
  TEXT_4: 'Desde',
  TEXT_5: 'Até',
  TEXT_6: 'lotes selecionados',
  TEXT_7: 'Superficie planejada',
  TEXT_8: 'EIQ Planejado',
  TEXT_9: 'insumos',
  TEXT_10: 'realização',
  TEXT_11: 'do planejado',
  TEXT_12: 'Feito',
  TEXT_13: 'Adicionar realização',
  TEXT_14: 'Validações',
  TEXT_15: 'EIQ Realizada',
  TEXT_16: 'Rascunho',
  TEXT_17: 'versão',
  TEXT_18: 'Superficie realizada',
}
