import FORM_SIGN_UP from '../../components/FormSignUp/translate/en'
export default {
  WELCOME: 'Welcome to ucrop.it',
  INFO_WELCOME:
    'Finish creating you account by filling in your details, you will recieve an access code in your email',
  CONTINUE: 'Continue',
  COMPONENTS: {
    FORM_SIGN_UP,
  },
}
