import moment from 'moment'
import activityTypesConstants from '@constants/activityTypes'
import { calculateEiq, getEIQRange } from '@utils/eiq'
import { formatDateMoment } from '@utils/date'
import { truncateSurface } from '@common/utils/truncateSurface'
import { parentTypes } from '@common/constants'
import { ESubtypeAgreements } from '@common/enum'

export const parseActivityDataToFormik = ({
  t,
  verificationTypes,
  unitTypes,
  agreementTypes,
  formikInitialValues,
  activity,
  subTypeActivities,
  tag,
  locale,
  unitTypesSupplies,
  unitTypesVolume,
  irrigationPowerSources,
  irrigationWaterSources,
}) => {
  const newFormikData = {}
  if (activity.typeAgreement) {
    newFormikData.typeAgreement = agreementTypes.find(
      (element) => element.value === activity.typeAgreement._id
    )
  }
  let labelFormik = ''

  if (newFormikData.typeAgreement?.subType === ESubtypeAgreements.FORM) {
    const numOfLots = activity?.lots.length
    const newLabel =
      numOfLots > 1
        ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
        : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')

    labelFormik = `${numOfLots} ${newLabel}`
  } else if (
    newFormikData.typeAgreement?.parentType === parentTypes.CROP_FARM
  ) {
    labelFormik =
      activity?.farms.length > 1
        ? t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FARM_FIELD.PLURAL', {
            num: activity?.farms.length,
          })
        : t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FARM_FIELD.SINGULAR', {
            num: activity?.farms.length,
          })
  } else {
    const newLabel = activity?.lots?.length
      ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
      : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')
    labelFormik = activity.lots.length
      ? `${activity.lots.length} ${newLabel}`
      : ''
  }

  newFormikData.lots = {
    value: activity.lotsWithSurface?.length
      ? activity.lotsWithSurface.map((element) => ({
          _id: element.lot._id,
          surfacePlanned: element.surfacePlanned,
          surfaceRealized: element.surfaceRealized,
          surface: element.surfaceRealized ?? element.surfacePlanned,
          tag: element.tag,
        }))
      : activity.lots.map((element) => ({
          _id: element._id,
          surfacePlanned: element.surface,
          surfaceRealized: element.surface,
          surface: element.surface,
          tag: element.tag,
        })),
    label: labelFormik,
  }

  newFormikData.surface = activity.lotsWithSurface?.length
    ? String(
        truncateSurface(
          activity.lotsWithSurface.reduce(
            (acumulator, currentValue) =>
              acumulator +
              (currentValue.surfaceRealized ?? currentValue.surfacePlanned),
            0
          )
        )
      )
    : String(truncateSurface(activity.surface))

  if (activity.dateHarvest) {
    newFormikData.dateHarvest = {
      value: moment(activity.dateHarvest).toDate(),
      label: moment(activity.dateHarvest).format('DD/MM/YYYY'),
    }
  }

  if (activity.dateObservation) {
    newFormikData.dateObservation = {
      value: moment(activity.dateObservation).toDate(),
      label: moment(activity.dateObservation).format('DD/MM/YYYY'),
    }
  }

  if (activity.dateEstimatedHarvest) {
    newFormikData.dateEstimatedHarvest = {
      value: moment(activity.dateEstimatedHarvest).toDate(),
      label: moment(activity.dateEstimatedHarvest).format('DD/MM/YYYY'),
    }
  }

  if (activity.verificationType) {
    newFormikData.verificationType = verificationTypes.find(
      (element) => element.value === activity.verificationType._id
    )
  }

  if (activity.subTypeActivity) {
    newFormikData.subTypeActivity = subTypeActivities.find(
      (element) => element.value === activity.subTypeActivity._id
    )
  }

  if (activity.unitType) {
    newFormikData.unitType = unitTypes.find(
      (element) => element.value === activity.unitType
    )
  }

  if (activity.observation) {
    newFormikData.observation = activity.observation
  }

  if (activity.observations) {
    newFormikData.observations = activity.observations
  }

  if (activity.pay !== undefined || activity.pay !== null) {
    newFormikData.pay = String(activity.pay)
  }

  if (activity.volume !== undefined || activity.volume !== null) {
    newFormikData.volume = String(activity?.volume)
  }

  if (activity.unitVolume) {
    newFormikData.unitVolume = unitTypesVolume.find(
      (element) =>
        element.value === activity?.unitVolume?._id ?? activity?.unitVolume
    )
  }

  if (activity.signers) {
    newFormikData.signers = activity.signers
  }

  if (activity.files) {
    newFormikData.evidences = activity.files
  }

  if (activity.storages) {
    newFormikData.storages = activity.storages
  }

  if (activity.supplies) {
    newFormikData.supplies = activity.supplies.map((supply) => {
      if (!supply.unitName) {
        const unitName = unitTypesSupplies.find(
          (unit) => unit?.key?.toLowerCase() === supply?.unit?.toLowerCase()
        )
        supply.unitName = unitName?.keyLabel
      }
      return supply
    })

    if (tag === activityTypesConstants.ACT_APPLICATION.key) {
      const totalEiqAllSupplies = calculateEiq(
        activity.supplies,
        activity.surface
      )
      const range = getEIQRange(totalEiqAllSupplies)
      newFormikData.eiqSupplies = { totalEiqAllSupplies, range }
    }
  }

  if (activity.dateAchievement) {
    newFormikData.dateAchievement = {
      value: moment(activity.dateAchievement).toDate(),
      label: formatDateMoment(
        activity.dateAchievement,
        'DD/MMM/YYYY',
        locale,
        true
      ),
    }
  }
  if (activity.farm) {
    newFormikData.farm = {
      tag: activity?.farm?.name,
      farmId: activity?.farm?._id,
    }
  }

  if (activity.seedDryContent) {
    newFormikData.seedDryContent = String(activity.seedDryContent)
  }
  if (activity.dataForCFT) {
    newFormikData.dataForCFT = activity.dataForCFT
  }
  if (activity.farms) {
    newFormikData.farms = activity.farms.map((farm) => ({
      _id: farm._id,
      tag: farm.name,
      farm: farm._id,
    }))
  }
  if (activity.irrigationPowerSource) {
    newFormikData.irrigationPowerSource = irrigationPowerSources.find(
      (element) => element.value === activity.irrigationPowerSource
    )
  }
  if (activity.irrigationWaterSource) {
    newFormikData.irrigationWaterSource = irrigationWaterSources.find(
      (element) => element.value === activity.irrigationWaterSource
    )
  }
  if (activity.pumpingDepth) {
    newFormikData.pumpingDepth = String(activity.pumpingDepth)
  }
  if (activity.waterConsumption) {
    newFormikData.waterConsumption = String(activity.waterConsumption)
  }
  if (activity.horizontalWateringDepth) {
    newFormikData.horizontalWateringDepth = String(
      activity.horizontalWateringDepth
    )
  }
  if (tag === activityTypesConstants.ACT_IRRIGATION.key) {
    newFormikData.noFiles = true
  }

  if (activity.weatherType) {
    newFormikData.weatherType = {
      value: activity.weatherType._id,
      label: activity.weatherType.keyLabel,
    }
  }
  if (activity.temperature) {
    newFormikData.temperature = String(activity?.temperature)
  }
  if (activity.soilType) {
    newFormikData.soilType = {
      value: activity.soilType._id,
      label: activity.soilType.keyLabel,
    }
  }
  if (activity.landType) {
    newFormikData.landType = {
      value: activity.landType._id,
      label: activity.landType.keyLabel,
    }
  }
  if (activity.humidityType) {
    newFormikData.humidityType = {
      value: activity.humidityType._id,
      label: activity.humidityType.keyLabel,
    }
  }
  if (tag === activityTypesConstants.ACT_FLOOD.key) {
    const paramsActivityFlood = {
      timeFloodUnderCrop: activity.timeFloodUnderCrop,
      unitTime: activity.unitTime,
      paddyWaterPreseason: activity.paddyWaterPreseason,
      paddyWaterCultivation: activity.paddyWaterCultivation,
    }
    newFormikData.paramsActivityFlood = paramsActivityFlood
  }

  return {
    ...formikInitialValues,
    ...newFormikData,
  }
}
