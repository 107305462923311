import { FC, ReactElement, useContext } from 'react'

import { LanguageContext } from '@contextState/language'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { IValidateModal } from './interfaces'

export const ValidateModal: FC<IValidateModal> = ({
  isModalVisible,
  closeModal,
}): ReactElement => {
  const { t }: any = useContext(LanguageContext)

  return (
    <ModalConfirm
      visible={isModalVisible}
      onConfirm={closeModal}
      onClose={closeModal}
      title={t('VIEWS.ACTIVITIES.MODAL_VALIDATE.TEXT_1')}
      contentText={t('VIEWS.ACTIVITIES.MODAL_VALIDATE.TEXT_2')}
      confirmText={t('VIEWS.ACTIVITIES.MODAL_VALIDATE.TEXT_3')}
    />
  )
}
