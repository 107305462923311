import React, { useContext } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { blackHighEmphasis, blueGrey700, primary500 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { useAboutActivities } from '../hooks/useAboutActivities'

export const EmptyState = () => {
  const { t } = useContext(LanguageContext)
  const { openBackdropAboutActivities, ComponentAboutActivities } =
    useAboutActivities({ t })

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.header}>{t('VIEWS.ACTIVITIES.NO_ACTIVITIES')}</Text>
        <Text style={styles.message}>
          {t('VIEWS.ACTIVITIES.ADD_ACTIVITIES')}
        </Text>
        <TouchableOpacity onPress={openBackdropAboutActivities}>
          <Text style={styles.about}>
            {t('VIEWS.ACTIVITIES.ABOUT_ACTIVITIES')}
          </Text>
        </TouchableOpacity>
      </View>
      <ComponentAboutActivities />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  header: {
    paddingHorizontal: 26,
    alignSelf: 'center',
    fontSize: 20,
    color: blackHighEmphasis,
    textAlign: 'center',
    lineHeight: 23,
    fontWeight: 'bold',
  },
  message: {
    paddingHorizontal: 26,
    marginTop: 20,
    alignSelf: 'center',
    fontSize: 18,
    color: blueGrey700,
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: 23,
  },
  about: {
    paddingHorizontal: 26,
    marginTop: 20,
    alignSelf: 'center',
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: 23,
    color: primary500,
  },
})
