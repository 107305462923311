import ENTER_PIN from '../../components/EnterPin/translate/en'
import CONFIRM_PIN from '../../components/ConfirmPin/translate/en'
import WIZARD_PIN from '../../components/WizardPin/translate/en'

export default {
  COMPONENTS: {
    ENTER_PIN,
    CONFIRM_PIN,
    WIZARD_PIN,
  },
  NEXT: 'Continue',
}
