import { draftCropTypes as type } from '../types'

const initialState = {
  valuesForm: [],
  companyId: null,
  collaborators: [],
  valuesCompany: {},
  isNewCompany: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_DRAFT_CROP: {
      const newValuesForm = state.valuesForm
      const index = newValuesForm.findIndex(
        (value) => value.idCompany === action.payload.idCompany
      )
      if (index === -1) {
        newValuesForm.push(action.payload)
      } else {
        newValuesForm[index] = action.payload
      }
      return {
        ...state,
        valuesForm: newValuesForm,
      }
    }
    case type.SET_DRAFT_COMPANY_ID: {
      return {
        ...state,
        companyId: action.payload,
      }
    }

    case type.SET_DRAFT_COLLABORATORS: {
      return {
        ...state,
        collaborators: [...action.payload, ...state.collaborators],
      }
    }
    case type.SET_DRAFT_VALUES_COMPANY: {
      return {
        ...state,
        valuesCompany: { ...action.payload },
        isNewCompany: true,
      }
    }

    case type.CLEAR_DRAFT_CROP: {
      const valuesForm = state.valuesForm
      const newValuesForm = valuesForm.filter(
        (value) => value.idCompany !== action.payload
      )
      return {
        ...initialState,
        valuesForm: newValuesForm,
      }
    }

    default:
      return state
  }
}

export default reducer
