export default {
  TEXT_1: 'Confirm new crop',
  TEXT_2:
    "From the company that's in charge of the production, whether it's a physical or legal entity.",
  TEXT_3: 'Required field',
  TEXT_4: 'Area must be bigger than 0',
  TEXT_5: 'Field',
  TEXT_6: 'ha',
  TEXT_7: 'Confirm new crop',
  TEXT_8: 'Congratulations!',
  TEXT_9: 'You have already assigned the fields to the crop',
  TEXT_10: 'Find more about your crop:',
  TEXT_11: 'Crop story',
  TEXT_12: 'Go back to crops',
  TEXT_13: 'ac',
  FIELD_1: {
    NAME: 'Crop type',
  },
  FIELD_2: {
    NAME: 'Estimated planting date',
  },
  FIELD_3: {
    NAME: 'Estimated harvest date',
  },
  FIELD_4: {
    NAME: 'Estimated crop yield',
  },
  FIELD_5: {
    NAME: 'Tax Id',
  },
  FIELD_6: {
    NAME: 'Crop name',
  },
  FIELD_7: {
    NAME: 'Planting area',
  },
  FIELD_8: {
    NAME: 'Yield unit',
  },
  FIELD_9: {
    NAME: 'Country',
  },
  TEXT_14: 'Congratulations!',
  TEXT_15: 'You successfully created the crop.',
  OUTLIER_TITLE: 'The value is outside the average range.',
  OUTLIER_DESCRIPTION: 'How to use the standard deviation?',
}
