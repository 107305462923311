import { ChemicalComposition } from '../hooks'

interface valuesForm {
  chemicalCompositions: ChemicalComposition
  optionsNitrogen: string
  optionsPhosphorus: string
  optionsPotassium: string
  optionsCalcium: string
  optionsSulfur: string
}

export const generateSupplyNameV2 = (values: valuesForm) => {
  let supplyName = ''
  const {
    chemicalCompositions,
    optionsNitrogen,
    optionsPhosphorus,
    optionsPotassium,
    optionsCalcium,
    optionsSulfur,
  } = values
  const compositionKeyByIngredient = {
    N_A: 'N-NH3',
    N_N: 'N-NO4',
    N_U: 'N-Urea',
  }

  const namePhosphorus =
    chemicalCompositions[optionsPhosphorus] > 0
      ? `${optionsPhosphorus}${Number(chemicalCompositions[optionsPhosphorus])}`
      : ''

  const namePotassium =
    chemicalCompositions[optionsPotassium] > 0
      ? `${optionsPotassium}${Number(chemicalCompositions[optionsPotassium])}`
      : ''

  const nameCalcium =
    chemicalCompositions[optionsCalcium] > 0
      ? `${optionsCalcium}${Number(chemicalCompositions[optionsCalcium])}`
      : ''
  const nameSulfur =
    chemicalCompositions[optionsSulfur] > 0
      ? `${optionsSulfur}${Number(chemicalCompositions[optionsSulfur])}`
      : ''

  const nameNitrogen =
    optionsNitrogen === 'composition'
      ? Object.entries(chemicalCompositions.N).reduce((acc, [key, value]) => {
          const compositionKey =
            compositionKeyByIngredient[
              key as keyof typeof compositionKeyByIngredient
            ]
          const composition = Number(value)
          return compositionKey && !isNaN(composition) && composition > 0
            ? acc + compositionKey + value
            : acc
        }, '')
      : Number(chemicalCompositions.N.N) > 0
      ? `N${Number(chemicalCompositions.N.N)}`
      : ''

  supplyName = `${nameNitrogen}${namePhosphorus}${namePotassium}${nameCalcium}${nameSulfur}`
  return supplyName
}
