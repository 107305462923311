export default {
  TEXT_1: 'Add input',
  TEXT_2: 'Add evidence',
  TEXT_3: 'Generated EIQ',
  TEXT_4: 'Validate',
  TEXT_5: 'This post is being edited by another contributor (%{userFullname}).',
  TEXT_6: 'SAVE DRAFT',
  TEXT_7: 'by %{user} in %{date} %{hour} hs.',
  TEXT_8: 'Draft: version %{version}',
  MODAL_CONFIRM: {
    TEXT_1: 'Exit without saving?',
    TEXT_2: 'Changes will not be saved',
    TEXT_3: 'LEAVE',
    TEXT_4: 'STAY',
  },
  MODAL_DELETE: {
    TEXT_1: 'Delete Draft?',
    TEXT_2: 'Uploaded evidences will be permanently deleted.',
    TEXT_3: 'KEEP',
    TEXT_4: 'DELETE',
  },
  MODAL_VALIDATE: {
    TEXT_1: 'Validate this version of the draft?',
    TEXT_2: 'There are other versions of this same record',
    TEXT_3: 'CANCEL',
    TEXT_4: 'VALIDATE',
  },
}
