export const INIT_DATA = []

export const ITEM_PER_PAGE_CROP_TYPES = 5

export const ITEM_PER_PAGE_ACTIVITY_TYPES = 3

export const MINIMUM_CHARACTERS = 3

export const INIT_CURRENT_PAGE = 1

export const TIMEOUT_ON_KEY_UP = 300
