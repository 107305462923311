export default {
  PLURAL_DAYS: 'dias',
  SINGULAR_DAYS: 'dia',
  HARVESTED_AT: 'Colhido há %{daysQuantity} %{daysWord} atrás',
  MONITORED_AT: 'Monitorou há %{daysQuantity} %{daysWord} atrás',
  EXECUTED_AT: 'Realizado em campo há %{daysQuantity} %{daysWord} atrás',
  MESSAGE_1: 'Você tem uma ',
  MESSAGE_2: 'validação pendente ',
  MESSAGE_3: 'na ',
  MESSAGE_4: 'atividade %{activityType} ',
  MESSAGE_5: 'de %{surface} %{areaUnit}',
  SINGULAR_FIELDS: 'lote',
  PLURAL_FIELDS: 'lotes',
  VERIFY_VALUE_MESSAGE: 'Confira os valores inseridos',
}
