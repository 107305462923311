export default {
  TEXT_1: 'Composition',
  TEXT_2: 'EIQ',
  TEXT_3: 'Toxicological classes',
  TEXT_4: 'Area %{areaUnit}',
  TEXT_5: 'Measurement unit',
  TEXT_6: 'Quantity of',
  TEXT_7: 'Total',
  MODAL_DELETE: {
    TEXT_1: 'Delete input?',
    TEXT_2: 'This input will be removed from the activity.',
    TEXT_3: 'KEEP',
    TEXT_4: 'DELETE',
  },
}
