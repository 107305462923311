import React, { useContext } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import CollaboratorCardDetails from '../../collaborators/CollaboratorCardDetails'
import { ROL } from '@constants/roles'
import ButtonCustom from '../../common/ButtonCustom'

const Unlink = ({
  onClose,
  notfound = false,
  modal = false,
  currentKey,
  findCropCollaborator,
  isFetchingCrop,
  setDisabled,
  getTitleMenu,
  goToUnlinkCollaborators,
}) => {
  const { t } = useContext(LanguageContext)
  const pendingValidation = findCropCollaborator[currentKey].pendingValidations
    .length
    ? true
    : false

  return (
    <View
      style={notfound ? styles.containerModalNotFound : styles.containerModal}
    >
      <View
        style={[
          styles.containerHeaderModalGeneral,
          modal ? styles.containerHeaderIsModal : styles.containerHeaderModal,
        ]}
      >
        <TouchableOpacity onPress={onClose}>
          <CommonIcon name={'X'} size={20} style={styles.buttonClose} />
        </TouchableOpacity>
        <Text style={styles.titleModal}>
          {t('VIEWS').CROP_COLLABORATOR.MODAL_ACTION_SHEET.TEXT_4}
        </Text>
      </View>
      <View style={modal ? styles.contentIsModal : styles.contentModal}>
        {currentKey != null && (
          <CollaboratorCardDetails
            notfound={notfound}
            collaborator={findCropCollaborator[currentKey]}
            loading={isFetchingCrop}
            disabled={setDisabled(findCropCollaborator[currentKey])}
            companyName={findCropCollaborator[currentKey].company.name}
            collaboratorType={findCropCollaborator[currentKey].rol}
            title={getTitleMenu(findCropCollaborator[currentKey])}
            subtitle={
              ROL[findCropCollaborator[currentKey].rol](t) || ROL['DEFAULT'](t)
            }
            status={!!findCropCollaborator[currentKey].firstName}
            pendingValidation={
              findCropCollaborator[currentKey].pendingValidations.length
            }
            delegating
          />
        )}
        {!notfound && !pendingValidation && (
          <View>
            <CommonIcon
              name={'SIGN'}
              size={40}
              color='#66BB6A'
              style={styles.iconNoValidation}
            />
            <Text style={styles.textNoValidation}>
              {t('VIEWS').CROP_COLLABORATOR.MODAL_ACTION_SHEET.TEXT_5}
            </Text>
          </View>
        )}
        {pendingValidation && (
          <View style={styles.contentTextValidation}>
            <Text style={styles.textValidation}>
              {t('VIEWS').CROP_COLLABORATOR.MODAL_UNLINK.TEXT_1}
            </Text>
          </View>
        )}
        {!notfound && (
          <View style={styles.containerButton}>
            <ButtonCustom
              contentStyle={styles.buttonUnlinkStyle}
              onPress={goToUnlinkCollaborators}
            >
              {pendingValidation
                ? t('VIEWS').CROP_COLLABORATOR.MODAL_UNLINK.TEXT_3
                : t('VIEWS').CROP_COLLABORATOR.TEXT_19}
            </ButtonCustom>
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  containerModal: {
    backgroundColor: 'white',
  },
  containerModalNotFound: {
    backgroundColor: '#FBE9E7',
  },
  containerHeaderModalGeneral: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 16,
  },
  containerHeaderModal: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  containerHeaderIsModal: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  buttonClose: {
    alignSelf: 'flex-start',
  },
  iconNoValidation: {
    alignSelf: 'center',
    padding: 8,
  },
  textNoValidation: {
    textAlign: 'center',
    alignSelf: 'center',
    color: '#66BB6A',
    fontSize: 24,
  },
  contentTextValidation: {
    marginTop: 10,
  },
  textValidation: {
    textAlign: 'justify',
    color: '#333',
    fontSize: 16,
    fontWeight: '500',
  },
  titleModal: {
    color: '#333333',
    fontWeight: 'bold',
    marginLeft: 20,
    fontSize: 18,
  },
  contentModal: {
    paddingHorizontal: 16,
  },
  contentIsModal: {
    paddingHorizontal: 0,
  },
  containerButton: {
    paddingVertical: 15,
    marginTop: 15,
  },
  buttonUnlinkStyle: {
    backgroundColor: '#FF7043',
  },
})

Unlink.propTypes = {
  onClose: PropTypes.func.isRequired,
  notfound: PropTypes.bool,
  modal: PropTypes.bool,
  currentKey: PropTypes.number,
  findCropCollaborator: PropTypes.array.isRequired,
  isFetchingCrop: PropTypes.bool,
  setDisabled: PropTypes.func.isRequired,
  getTitleMenu: PropTypes.func.isRequired,
  goToUnlinkCollaborators: PropTypes.func.isRequired,
}

export default Unlink
