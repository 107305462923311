import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  url: '',
  onMessage: () => {},
}

export const webViewSlice = createSlice({
  name: 'webView',
  initialState,
  reducers: {
    /**
     * @param {object} state
     * @property {string} state.url
     * @property {string} action.payload.redirectUri
     * @property {func} state.onMessage
     * @property {func} state.onNavigationStateChange
     * @param {object} action
     * @property {object} action.payload
     * @property {string} action.payload.url
     * @property {string} action.payload.redirectUri
     * @property {func} action.payload.onMessage
     * @property {func} state.payload.onNavigationStateChange
     */
    setWebViewValues: (state, action: PayloadAction<any>) => {
      state = {
        ...action.payload,
      }

      return state
    },
    /**
     * @param {object} state
     * @property {string} state.url
     * @property {func} state.onMessage
     *
     * @returns {object} Initial state
     */
    resetWebViewValues: (state) => {
      state = initialState

      return state
    },
  },
})

export const { setWebViewValues, resetWebViewValues } = webViewSlice.actions

export const { reducer: webViewReducer } = webViewSlice
