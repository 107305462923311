import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { TextInput, Avatar } from 'react-native-paper'

function Input({
  field,
  form: { errors, touched, handleChange, setFieldTouched },
  helpText,
  inputProps,
  icon,
  multiline,
  disabled,
}) {
  const isInvalid = errors[field.name] && touched[field.name]
  const fieldData = {
    name: field?.name,
    value: field?.value,
  }
  return (
    <View styles={styles.content}>
      <TextInput
        style={[styles.input, !helpText && !isInvalid ? styles.spacing : {}]}
        mode='outlined'
        multiline={multiline || false}
        {...inputProps}
        autoCapitalize='none'
        {...fieldData}
        onChangeText={handleChange(field.name)}
        onBlur={() => setFieldTouched(field.name)}
        error={isInvalid}
        disabled={disabled}
      />
      {helpText && !isInvalid && (
        <Text style={styles.helpText}>{helpText}</Text>
      )}
      {isInvalid && <Text style={styles.errorText}>{errors[field.name]}</Text>}
      {icon && (
        <Avatar.Icon size={40} icon={icon} style={styles.icon} color='grey' />
      )}
    </View>
  )
}

Input.defaultProps = {
  helpText: null,
}

const styles = StyleSheet.create({
  content: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    right: 8,
    top: 14,
    backgroundColor: 'transparent',
  },
  helpText: {
    marginTop: 4,
    marginBottom: 16,
    marginLeft: 16,
    fontSize: 12,
    color: 'rgba(0,0,0,0.6)',
  },
  errorText: {
    marginTop: 4,
    marginBottom: 8,
    fontSize: 12,
    color: 'rgb(176, 0, 32)',
  },
  input: {
    backgroundColor: 'white',
  },
  spacing: {
    marginBottom: 16,
  },
})

export default Input
