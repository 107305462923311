export default {
  WIZARD_1: {
    WELCOME: 'Bienvenido a ucrop.it',
    PARAGRAPH:
      'La plataforma de agricultura colaborativa con la que sustentabilidad es + rentabilidad.',
  },
  WIZARD_2: {
    TRACE_HISTORY_CROPS: 'Trazá la historia de tus cultivos',
    PARAGRAPH:
      'Accedé y gestioná indicadores ambientales.\nConectá con empresas que valoran y premian las buenas prácticas.',
  },
  WIZARD_3: {
    DIGITAL_BACKUP: 'Respaldo digital',
    ITEMS:
      '+ Firmas digitales\n+ Verificación de registros a campo\n+ Trazabilidad de actividades\n+ Historia detallada de cultivos\n+ Documentación criptoconfidencial',
  },
}
