export default {
  TEXT_1: 'ASSOCIADO',
  TEXT_2: 'Campos',
  TEXT_3: 'ha',
  TEXT_4:
    'La empresa tiene cupo de hectáreas. Solo se aplicarán %{hectareLimit} has. de las %{surfaceTotal} has. seleccionadas.',
  TEXT_5:
    'Actualmente esta empresa ya tiene %{lotsApplied} lotes - %{surfaceApplied} has. asociados a esta licencia.',
  TEXT_6: 'Ya se aplicaron estos lotes a esta empresa.',
  TEXT_7: 'superficie neta',
  TEXT_8: 'Selecionar tudo',
  TEXT_9:
    'A empresa não possui mais cotas de hectares. Aqueles selecionados anteriormente serão aplicados.',
  MODAL_CONFIRM_COMPANIES: {
    TEXT_1: 'Confirmação da empresa',
    TEXT_2:
      'Depois de selecionar a empresa associada à licença, você não poderá desmarcá-la.',
    TEXT_3: 'CANCELAR',
    TEXT_4: 'ASSOCIADO',
  },
}
