import React from 'react'
import {
  Animated,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native'
import { HeaderSelectionItemFieldProps } from './HeaderSelectionItemField.props'
import { SIZES } from '@common/components/icons/interfaces'
import { CommonIcon } from '@common/components/icons'
import { blackHighEmphasis, gray12, primary500 } from '@styles/palette'

export const HeaderSelectionItem = ({
  arrowAngle,
  onPress,
  otherParams,
}: HeaderSelectionItemFieldProps) => {
  const { tag, selected, disabled, onClickElement, index } = otherParams
  return (
    <View style={[styles.contentHeader, disabled ? styles.disabled : {}]}>
      <View>
        <CommonIcon
          name={selected ? 'CHECKBOX-SELECTED' : 'CHECKBOX-UNSELECTED'}
          size={SIZES.MEDIUM}
          color={selected && !disabled ? primary500 : blackHighEmphasis}
          onPress={() => {
            if (onClickElement && typeof index === 'number' && !disabled) {
              return onClickElement(index)
            }
          }}
        />
      </View>
      <TouchableOpacity style={styles.contentTag} onPress={onPress}>
        <Text style={styles.tag}>{tag}</Text>
        {arrowAngle && (
          <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
            <CommonIcon name={'CHEVRON-DOWN'} size={SIZES.MEDIUM} />
          </Animated.View>
        )}
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  contentTag: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 32,
    borderBottomWidth: 1,
    borderBottomColor: gray12,
    paddingVertical: 16,
  },
  tag: {
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 24,
  },
  disabled: {
    opacity: 0.5,
  },
})
