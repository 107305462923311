export default {
  PARAGRAPH_BY_DELETING_ACCOUNT:
    'Ao excluir sua conta, removeremos todos os seus dados pessoais e de uso de nossos sistemas.',
  PARAGRAPH_ACTION_IRREVERSIBLE:
    'Esta ação é irreversível e, uma vez apagada, não poderemos restaurá-la.',
  DELETE_ACCOUNT: 'Excluir conta',
  CANCEL: 'CANCELAR',
  MODAL_CONFIRM_DELETE_ACCOUNT: {
    PARAGRAPH_1:
      'Esta ação é irreversível e, uma vez apagada, não poderemos restaurá-la.\n\nVocê confirma para excluir sua conta?',
    DELETE: 'EXCLUIR',
  },
  MODAL_SUCCESS_DELETED_ACCOUNT: {
    DELETED_ACCOUNT: 'Conta excluída',
    PARAGRAPH_HAVE_DELETED_ACCOUNT:
      'Excluímos sua conta e seus dados de nosso sistema.',
    CLOSE: 'FECHAR',
  },
}
