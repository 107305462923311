const orderSeed = {
  eiq: {
    searchOrder: 'eiq',
    nameSearch: (t) => t('COMPONENTS').SUPPLIES.ORDER_BY.TEXT_2,
  },
  company: {
    searchOrder: 'company',
    nameSearch: (t) => t('COMPONENTS').SUPPLIES.ORDER_BY.TEXT_3,
  },
  name: {
    searchOrder: 'name',
    nameSearch: (t) => t('COMPONENTS').SUPPLIES.ORDER_BY.TEXT_4,
  },
  defaultMode: {
    searchOrder: '',
    nameSearch: (t) => t('COMPONENTS').SUPPLIES.ORDER_BY.TEXT_5,
  },
}

export default orderSeed
