import { StyleSheet, View } from 'react-native'
import React from 'react'
import { FooterStyles } from './interfaces'

export const Footer = () => {
  return <View style={styles.footer} />
}

const styles = StyleSheet.create<FooterStyles>({
  footer: {
    height: 60,
  },
})
