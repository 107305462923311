import * as yup from 'yup'

export const getFormikValidationSchema = (t) =>
  yup.object().shape({
    code: yup
      .string()
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .min(6, t('ERRORS.FORM_ERRORS.MIN_CHARACTERS', { quantity: 6 })),
    email: yup.string(),
  })
