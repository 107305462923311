import * as yup from 'yup'

export const getFormikValidationSchema = (t) =>
  yup.object().shape({
    typePerson: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    address: yup
      .string()
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    specificAddress: yup
      .string()
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
    files: yup.array().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
  })
