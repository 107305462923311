import RouterNames from '@constants/routerNames'

export const navigateCompanyListOrAdd = (
  companySelected: object,
  navigation: any,
  callback: () => void
) => {
  callback()

  const params = !companySelected
    ? {
        initialRouteName: RouterNames.COMPANY_ADD,
      }
    : {}

  navigation.reset({
    index: 0,
    routes: [
      {
        name: RouterNames.COMPANY,
        params,
      },
    ],
  })
}
