import { draftCropTypes as type } from '../types'

export const draftCropActions = {
  setDraftCrop: (payload) => ({
    type: type.SET_DRAFT_CROP,
    payload,
  }),
  setCompanyId: (payload) => ({
    type: type.SET_DRAFT_COMPANY_ID,
    payload,
  }),
  setDraftCollaborators: (payload) => ({
    type: type.SET_DRAFT_COLLABORATORS,
    payload,
  }),
  setDraftValuesCompany: (payload) => ({
    type: type.SET_DRAFT_VALUES_COMPANY,
    payload,
  }),
  clearDraftCrop: (payload) => ({
    type: type.CLEAR_DRAFT_CROP,
    payload,
  }),
}
