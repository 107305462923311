import { collaboratorCompanyTypes as type } from '../types'

const initialState = {
  collaborators: [],
  company: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_COLLABORATORS_COMPANY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case type.CLEAR_COLLABORATOR_COMPANY: {
      return {
        ...state,
        collaborators: [],
      }
    }
    default:
      return state
  }
}

export default reducer
