import { useCallback, useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'
import Screen from './screen'
import useNetwork from '@utils/network'
import useOfflineCrops from '@offline/queries/crops'
import { useAuth } from '@common/hooks'

const OptionalPlanned = ({ navigation, route }) => {
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const [crop, setCrop] = useState({})
  const { doRequest } = useNetwork()
  const { findOneCropById } = useOfflineCrops()
  const { user } = useAuth()
  const [isViewReady, setIsViewReady] = useState(false)

  const {
    id: cropId,
    tag: tagActivity,
    mode: modeActivity,
    areaUnit: areaUnitCrop,
    cropTypeName,
    surface,
    activityTypeName,
  } = useRef(route.params).current

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    fetchInitData()
    return () => {}
  }, [])

  /**
   * FETCH INIT DATA
   */
  const fetchInitData = useCallback(async () => {
    setIsViewReady(false)
    await fetchCrop()
    setIsViewReady(true)
  }, [])

  /**
   * FETCH CROP
   */
  const fetchCrop = useCallback(async () => {
    let crop
    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        version: 'v2',
        url: `crops/${cropId}`,
        params: {
          companyId: user.config.companySelected?._id,
        },
        displayAlert: false,
      })
      crop = response.data
    } else {
      const offlineData = await findOneCropById(cropId)
      crop = offlineData
    }
    setCrop(crop)
    return crop
  }, [isConnected])

  const goToRealization = () => {
    navigation.navigate('ActivityCreate', {
      cropId: cropId,
      tag: tagActivity,
      canSign: true,
      isDirectAchievement: true,
      isNewActivity: true,
      canPlanning: true,
      id: cropId,
      areaUnit: areaUnitCrop,
      cropTypeName,
      surface,
      activityTypeName: activityTypeName,
    })
  }

  const goToPlanning = () => {
    navigation.navigate('CommonActivities', {
      id: cropId,
      mode: modeActivity,
      tag: tagActivity,
      areaUnit: areaUnitCrop,
      cropTypeName,
      surface,
      activityTypeName: activityTypeName,
    })
  }

  return (
    <Screen
      crop={crop}
      isViewReady={isViewReady}
      goToPlanning={goToPlanning}
      goToRealization={goToRealization}
    />
  )
}

OptionalPlanned.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default OptionalPlanned
