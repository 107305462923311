import { Platform } from 'react-native'

import { urlToBlob, blobToFile } from '@utils/files'

export const parseEvidencesToFiles = async (evidences) => {
  const files = []

  for (const evidence of evidences) {
    if (!evidence.persisted) {
      files.push(evidence.file)

      continue
    }

    let file

    if (Platform.OS === 'web') {
      const blobFile = await urlToBlob({
        url: evidence.file,
      })

      file = blobToFile({
        blobFile,
        fileName: evidence.originalName ?? evidence.name,
        fileType: evidence.type,
      })
    } else {
      file = {
        type: evidence.type,
        name: evidence.originalName ?? evidence.name,
        uri: evidence.file,
      }
    }

    files.push(file)
  }

  return files
}
