export default {
  TEXT_1: 'lotes selecionados',
  TEXT_2: 'Campo obrigatório',
  TEXT_3: 'Selecionar Lotes',
  TEXT_4: 'Superficie %{areaUnit}',
  TEXT_5: 'Desde',
  TEXT_6: 'Até',
  TEXT_7: 'Unidade de rendimento',
  TEXT_8: 'Estimação de rendimento',
  TEXT_9: 'ATÉ não pode ser uma data anterior a DESDE',
  TEXT_10: 'Tipo de',
  TEXT_11: 'Considerações',
  TEXT_12:
    'Adicione as considerações necessárias a serem incluídas na ordem de trabalho.',
  TEXT_13: 'Você pode inserir até %{considerationsLength} caracteres.',
}
