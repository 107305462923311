export default {
  TEXT_1: 'Lotes Seleccionados',
  TEXT_2: 'Superficie planificada',
  TEXT_3: 'Colaboradores',
  TEXT_4: 'Realización',
  TEXT_5: 'de lo planificado',
  TEXT_6: 'HECHO',
  TEXT_7: 'AGREGAR REALIZACIÓN',
  TEXT_8: 'Superficie realizada',
  TEXT_9: 'Validaciones',
  TEXT_10: 'Validado',
  TEXT_11: 'Validar',
  TEXT_12: 'Descargar reporte PDF',
  TEXT_13: 'Borrador',
  TEXT_14: 'Versión',
  TEXT_15: 'En Revisión',
  TEXT_16: '%{establishments} Campo - %{lots} Lotes',
  TEXT_17: 'Tipo de fertilización',
  TEXT_18: 'Insumos',
  TEXT_19: 'Desde',
  TEXT_20: 'Hasta',
}
