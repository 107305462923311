import { useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import { setWebViewValues } from '@store/slices'
import { useAuth } from '@common/hooks'

export const useStatementForm = () => {
  const { user } = useAuth()

  const navigation = useNavigation()
  const dispatch = useDispatch()

  const navigateToStatementForm = ({ url }) => {
    const lang = user?.config?.languageSelected

    const dataToSend = {
      url: `${url[lang]}${user.email}`,
    }

    dispatch(setWebViewValues(dataToSend))

    navigation.navigate('WebView')
  }

  return { navigateToStatementForm }
}

export default useStatementForm
