import moment from 'moment'
import Constants from 'expo-constants'

import { formatSurface } from '@modules/common/utils'

const apiUrl = Constants.expoConfig.extra.EXPO_HOST

export const getFieldDetailsDTO = ({
  t,
  farmUuid,
  uuid,
  name,
  surface,
  areaUnit,
  farmName,
  secondGeometryLevel,
  thirdGeometryLevel,
  cropTypeName,
  cropDateCrop,
  cropDateHarvest,
  imageUrl,
  canRemoveField,
  cantRemoveFieldReason,
}) => {
  const fieldUuid = uuid
  const fieldNameLabel = name
  const surfaceLabel = formatSurface(surface, areaUnit)
  const farmNameLabel = farmName
  const locationLabel = `${thirdGeometryLevel}, ${secondGeometryLevel}`
  const cropTypeNameLabel = cropTypeName
  const campaignLabel = `${
    t('CALENDAR').MONTHS_SHORT[moment(cropDateCrop).format('M')]
  }/${moment(cropDateCrop).format('YYYY')} - ${
    t('CALENDAR').MONTHS_SHORT[moment(cropDateHarvest).format('M')]
  }/${moment(cropDateHarvest).format('YYYY')}`
  const fieldImage =
    imageUrl.indexOf('/uploads/map-static') === -1
      ? imageUrl
      : `${apiUrl}${imageUrl}`

  const fieldDetailsDTO = {
    farmUuid,
    fieldUuid,
    fieldNameLabel,
    surfaceLabel,
    farmNameLabel,
    locationLabel,
    cropTypeNameLabel,
    campaignLabel,
    fieldImage,
    canRemoveField,
    cantRemoveFieldReason,
  }

  return fieldDetailsDTO
}
