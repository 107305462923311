import React, { useContext, useEffect } from 'react'
import { View, Text, StyleSheet, Platform, BackHandler } from 'react-native'
import { useFormik } from 'formik'
import { FormAuth } from './components'
import { LanguageContext } from '@contextState/language'
import { white, blackMediumEmphasis, primary500 } from '@styles/palette'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { getFormikInitialValues, getFormikValidationSchema } from './utils'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { logLogin } from '@services/analytics'
import useNetwork from '@utils/network.js'
import { useSelector } from 'react-redux'
import { WizardIntro } from '../v1/components/WizardIntro'
import ButtonRipple from '@modules/common/components/buttons/ButtonRipple'

const SignIn = ({ navigation, route }) => {
  const { t } = useContext(LanguageContext)
  const { doRequest } = useNetwork()
  const hideWizardIntro = useSelector((state) => state.auth.hideWizardIntro)
  const { params } = route
  const { notRegister } = params ?? {}

  useEffect(() => {
    const backAction = () => {
      return true
    }

    let backHandler

    if (Platform.OS !== 'web') {
      backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction
      )
    }

    return () => backHandler?.remove()
  }, [])

  const formik = useFormik({
    initialValues: getFormikInitialValues(),
    validationSchema: getFormikValidationSchema(t),
    enableReinitialize: false,
    onSubmit: (values, { setSubmitting }) => {
      onSignIn(values, setSubmitting)
    },
  })

  const onSignIn = async (values, setSubmitting) => {
    setSubmitting(true)
    await AsyncStorage.setItem('REGISTER_EMAIL', values.email)
    try {
      await doRequest({
        needAuthorizationHeaders: false,
        method: 'POST',
        url: 'auth',
        data: values,
        /* displayAlert: false */
      })

      logLogin({ os: Platform.OS, email: values.email })
      setSubmitting(false)

      navigation.navigate('VerifyCode')
    } catch (error) {
      if (error?.error === 'ERR_USER_NOT_FOUND') {
        setSubmitting(false)
        navigation.navigate('SignUp', { email: values.email })
      }
    }
  }

  if (!hideWizardIntro) {
    return <WizardIntro />
  }

  const { dirty, isValid, isSubmitting, handleSubmit, values } = formik

  const navigateToSignUp = () => {
    const { email } = values
    navigation.navigate('SignUp', { email })
  }

  return (
    <View style={styles.content}>
      <Text style={styles.textTittle}>
        {t('VIEWS.SIGN_IN_V2.SIGN_IN_ACCOUNT')}
      </Text>
      <Text style={styles.textInfo}>{t('VIEWS.SIGN_IN_V2.TEXT_EMAIL')}</Text>
      <FormAuth formik={formik} t={t} />
      <View style={styles.contentBottom}>
        <ButtonDefault
          typeButton={
            dirty && isValid && !isSubmitting ? 'primary' : 'disabled'
          }
          text={t('VIEWS.SIGN_IN_V2.CONTINUE')}
          marginTop={50}
          marginBottom={50}
          onPress={handleSubmit}
          disabled={!(dirty && isValid)}
          testID='next_button'
        />
        {!notRegister && (
          <View>
            <Text style={styles.subText}>
              {t('VIEWS.SIGN_IN_V2.TEXT_REGISTER_1')}{' '}
              <ButtonRipple onPress={navigateToSignUp} testID='signup_button'>
                <Text style={styles.link}>
                  {t('VIEWS.SIGN_IN_V2.TEXT_REGISTER_2')}
                </Text>
              </ButtonRipple>
            </Text>
          </View>
        )}
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 16,
    paddingTop: 104,
    paddingBottom: 44,
    backgroundColor: white,
    flex: 1,
  },
  textTittle: {
    fontWeight: '700',
    fontSize: 24,
    marginBottom: 16,
  },
  textInfo: {
    marginBottom: 28,
  },
  contentBottom: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  subText: {
    color: blackMediumEmphasis,
    fontWeight: '400',
    textAlign: 'center',
  },
  link: {
    color: primary500,
    fontWeight: '700',
  },
})
export default SignIn
