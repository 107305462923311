export default {
  TEXT_1: 'Seleccionar Lotes Realizados',
  TEXT_2: 'Lote(s) seleccionado(s)',
  TEXT_3: 'ha',
  TEXT_4: 'Todos los establecimientos',
  TEXT_5: 'Sup. Realizada',
  TEXT_6: 'CONFIRMAR LOTES',
  TEXT_7: 'Seleccionar Lotes',
  TEXT_8: 'Sup. a verificar',
  TEXT_9: 'Sup. verificada',
  TEXT_10: 'Lote ya aplicado a este tipo de verificación',
  TEXT_11:
    'Ya no se encuentran disponibles %{lotsVerifiedQuantity} de los %{lotsQuantity} lotes de la selección sin conexión.',
  FIELD_1: {
    NAME: 'Buscar por establecimiento...',
  },
}
