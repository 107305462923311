export default {
  TEXT_1: 'Semeadura',
  TEXT_2: 'Colheita',
  TEXT_3: 'Labrança',
  TEXT_4: 'Apl',
  TEXT_5: 'Fertilização',
  TEXT_6: 'Monitoramento',
  TEXT_7: 'Descarregar relatório pdf',
  TEXT_8: 'Uso legal da terra',
  TEXT_9: 'ha',
  TEXT_10: 'Cultivos sustentáveis',
  TEXT_11: 'Lotes carregados',
  TEXT_12: 'Dados insuficientes',
  TEXT_13: 'Volume Total estimado',
  TEXT_14: 'tn',
  TEXT_15: 'Atividades',
  TEXT_16: 'Acordos validados',
}
