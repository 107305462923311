import { makeRequest } from '@common/services'
import Constants from 'expo-constants'

const MS_SUPPLY_URL = Constants?.expoConfig?.extra?.MS_SUPPLY_URL ?? ''

export const createGenericSupplyV2 = async (data: any) => {
  return await makeRequest({
    version: 'v2',
    method: 'POST',
    url: `supplies/generic`,
    data: data,
    apiUrl: MS_SUPPLY_URL,
  })
}
