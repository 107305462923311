import React, { useContext, useEffect, useState } from 'react'
import {
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
} from 'react-native'
import Menu from '@material-ui/core/Menu'
import { CommonIcon } from '../icons'
import { SIZES } from '../icons/interfaces'
import { ItemMenu } from './components/ItemMenu'
import { LanguageContext } from '@contextState/language'
import {
  CROP_MENU_OPTIONS,
  canSeeCropMenuDeleteCrop,
  canSeeCropMenuFarmAndField,
} from '@common/utils'
import { Platform } from 'react-native'
import { black, gray300 } from '@common/styles'
import { useAuth } from '@common/hooks'

export const CropMenuWeb = ({
  disabled,
  status,
  isCropOffline,
  canWrite,
  onPress,
  showOptionDeleteCrop = true,
  showOptionEnableOrDisableOffline = true,
  hasLots = true,
  userType,
  userCanDeleteCrop = false,
  isDeleteCropAvailable = false,
}: any) => {
  const { t } = useContext(LanguageContext)
  const {
    isCompanyVerifiers,
    companyIsSynchronized,
    selectedProfile,
    isCompanyUcropit,
  } = useAuth()
  const [anchorEl, setAnchorEl] = useState<any | null>(null)

  const handleClick = (event: GestureResponderEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOutsideClick = (event: { target: any }) => {
    if (anchorEl && !anchorEl.contains(event.target)) {
      setAnchorEl(null)
    }
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('mousedown', handleOutsideClick)
      }
    }
  }, [anchorEl])

  const handleCropMenuOptions = async (option: string) => {
    if (onPress) onPress(option)

    handleClose()
  }

  const handleDeleteDraft = () => {
    handleCropMenuOptions(CROP_MENU_OPTIONS.DELETE_DRAFT)
    handleClose()
  }

  const handleCropStory = () => {
    handleCropMenuOptions(CROP_MENU_OPTIONS.CROP_STORY)
    handleClose()
  }

  const handleCollaborators = () => {
    handleCropMenuOptions(CROP_MENU_OPTIONS.COLLABORATORS)
    handleClose()
  }

  const handleLicenses = () => {
    handleCropMenuOptions(CROP_MENU_OPTIONS.LICENSES)
    handleClose()
  }

  const handleIntegrationsWithThirdParties = () => {
    handleCropMenuOptions(CROP_MENU_OPTIONS.INTEGRATIONS_WITH_THIRD_PARTIES)
    handleClose()
  }

  const handleEnableOrDisableOffline = () => {
    handleCropMenuOptions(
      isCropOffline
        ? CROP_MENU_OPTIONS.DISABLE_OFFLINE
        : CROP_MENU_OPTIONS.ENABLE_OFFLINE
    )
    handleClose()
  }

  const handleFarmAndFieldManagement = () => {
    handleCropMenuOptions(CROP_MENU_OPTIONS.FARM_AND_FIELD_MANAGEMENT)
    handleClose()
  }

  const handleDeleteCrop = () => {
    handleCropMenuOptions(CROP_MENU_OPTIONS.DELETE_CROP)
    handleClose()
  }

  return (
    <>
      <TouchableOpacity onPress={!disabled ? handleClick : undefined}>
        <CommonIcon
          name={'DOTS-VERTICAL'}
          size={SIZES.MEDIUM}
          style={styles.dotsIcon}
          disabled={disabled}
          color={disabled ? gray300 : black}
        />
      </TouchableOpacity>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status === 'DRAFT' && (
          <ItemMenu
            onPress={handleDeleteDraft}
            title={t('COMMON.COMPONENTS.CROP_MENU.DELETE_DRAFT')}
          />
        )}
        {hasLots && (
          <ItemMenu
            onPress={handleCropStory}
            title={t('COMMON.COMPONENTS.CROP_MENU.CROP_STORY')}
          />
        )}
        {hasLots && (
          <ItemMenu
            onPress={handleCollaborators}
            title={t('COMMON.COMPONENTS.CROP_MENU.COLLABORATORS')}
          />
        )}
        {hasLots && !isCompanyVerifiers() && (
          <ItemMenu
            onPress={handleLicenses}
            title={t('COMMON.COMPONENTS.CROP_MENU.LICENSES')}
          />
        )}
        {hasLots && companyIsSynchronized && !isCompanyVerifiers() && (
          <ItemMenu
            onPress={handleIntegrationsWithThirdParties}
            title={t(
              'COMMON.COMPONENTS.CROP_MENU.INTEGRATIONS_WITH_THIRD_PARTIES'
            )}
          />
        )}
        {hasLots &&
          Platform.OS !== 'web' &&
          showOptionEnableOrDisableOffline && (
            <ItemMenu
              onPress={handleEnableOrDisableOffline}
              title={
                isCropOffline
                  ? t('COMMON.COMPONENTS.CROP_MENU.DISABLE_OFFLINE')
                  : t('COMMON.COMPONENTS.CROP_MENU.ENABLE_OFFLINE')
              }
            />
          )}
        {canSeeCropMenuFarmAndField({ userType }) && (
          <ItemMenu
            onPress={handleFarmAndFieldManagement}
            title={t('COMMON.COMPONENTS.CROP_MENU.FARM_AND_FIELD_MANAGEMENT')}
          />
        )}
        {canSeeCropMenuDeleteCrop(
          selectedProfile.isAdmin,
          userType,
          isCompanyUcropit
        ) &&
          canWrite &&
          !isCompanyVerifiers() &&
          showOptionDeleteCrop &&
          userCanDeleteCrop && (
            <ItemMenu
              onPress={handleDeleteCrop}
              disabled={!isDeleteCropAvailable}
              title={t('COMMON.COMPONENTS.CROP_MENU.DELETE_CROP')}
            />
          )}
      </Menu>
    </>
  )
}

const styles = StyleSheet.create({
  dotsIcon: {
    margin: 8,
  },
})
