import { useContext } from 'react'
import { useNavigation } from '@react-navigation/native'

import { CommonContext } from '@contextState/common'
import { ActivityIcon } from '@modules/common/components'
import activityTypesConstants from '@constants/activityTypes'
import { black } from '@styles/palette'
import { IUseFab } from './interfaces'
import { blackHighEmphasis, white } from '@common/styles'
import { useSelector } from 'react-redux'
import activityTypes from '@constants/activityTypes'
import { GlobalStateInterface } from '@store/interfaces'

const fabItemProps = {
  style: {
    borderRadius: 50,
  },
  containerStyle: {
    marginVertical: -20,
  },
  labelStyle: {
    borderRadius: 8,
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: white,
    fontSize: 12,
    color: blackHighEmphasis,
    marginRight: -10,
  },
}

const orderActivities = [
  activityTypes.ACT_HARVEST.key,
  activityTypes.ACT_MONITORING.key,
  activityTypes.ACT_FERTILIZATION.key,
  activityTypes.ACT_APPLICATION.key,
  activityTypes.ACT_SOWING.key,
  activityTypes.ACT_TILLAGE.key,
  activityTypes.ACT_FLOOD.key,
  activityTypes.ACT_IRRIGATION.key,
  activityTypes.ACT_AGREEMENTS.key,
]

export const useFab = ({
  cropId,
  areaUnit,
  cropType,
  surface,
}: IUseFab): {
  fabActions: Array<any>
} => {
  const { activityTypes } = useSelector(
    (state: GlobalStateInterface) => state.activityTypesReducer
  )
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )

  const navigation = useNavigation()
  const cropTypeName = cropType?.codeLabel

  const excludeFabAction = (activityType: any) => {
    if (activityTypesConstants.ACT_VERIFICATION.key === activityType.tag) {
      return false
    }

    if (
      activityTypesConstants.ACT_AGREEMENTS.key === activityType.tag &&
      !isConnected
    ) {
      return false
    }

    if (activityType.cropTypesAllowed?.length > 0) {
      const cropTypeAllowed = activityType.cropTypesAllowed.find(
        (cropTypeAllowed: any) => cropTypeAllowed.key === cropType?.key
      )

      if (!cropTypeAllowed) {
        return false
      }
    }

    return true
  }

  const fabActions = orderActivities
    .map((activityTag: string) => {
      const activityType = activityTypes.find(
        (activityTypeFind: any) => activityTypeFind.tag === activityTag
      )

      if (!activityType) {
        return
      }

      if (excludeFabAction(activityType)) {
        return {
          ...fabItemProps,
          icon: (props: any) => (
            <ActivityIcon {...props} name={activityType.tag} color={black} />
          ),
          label: activityType.label,
          onPress: (): void => {
            const screen = activityType.canPlanning
              ? 'CommonOptionalPlanned'
              : 'ActivityCreate'

            const params = {
              id: cropId,
              cropId,
              tag: activityType.tag,
              areaUnit,
              cropTypeName,
              surface,
              activityTypeName: activityType.label,
            }

            return navigation.navigate(screen, params)
          },
        }
      }
    })
    .filter((fabAction: any) => fabAction)
  return { fabActions }
}
