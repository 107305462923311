import { ICropType } from '../interfaces'
const keys = {
  SOY_KEY: 'soy',
  CORN_KEY: 'corn',
  WHEAT_KEY: 'wheat',
  SUNFLOWER_KEY: 'sunflower',
  BARLEY_KEY: 'Barley',
}

export const orderCropTypes = (cropTypes: ICropType[]) => {
  const orderBy = [
    keys.SOY_KEY,
    keys.CORN_KEY,
    keys.WHEAT_KEY,
    keys.SUNFLOWER_KEY,
    keys.BARLEY_KEY,
  ]
  const orderedArray = [...cropTypes].sort((a, b) => {
    const indexA = orderBy.indexOf(a.key)
    const indexB = orderBy.indexOf(b.key)

    // If both labels are present in the orderBy array, sort based on their positions in the orderBy array
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }
    // If only one label is present in the orderBy array, it should appear first
    else if (indexA !== -1) {
      return -1
    } else if (indexB !== -1) {
      return 1
    }
    // If neither label is present in the orderBy array, sort based on default order
    else {
      return 0
    }
  })

  return orderedArray
}
