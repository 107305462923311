import React, { useContext } from 'react'
import { View, Text, StyleSheet, Pressable } from 'react-native'
import { lemon300, lemon700, lemon900, yellow200 } from '@styles/palette'

import { LanguageContext } from '@contextState/language'
import { CommonIcon, ActivityIcon } from '@modules/common/components'
import { EActivityTypes } from '../../../common/enum/activityTypes/index'
import { SIZES } from '@common/components/icons/interfaces'

interface SnackBarAddGenericSupplyProps {
  visible: string
  onPress: () => {}
}

export const SnackbarAddGenericSupply: React.FC<
  SnackBarAddGenericSupplyProps
> = ({ visible, onPress }) => {
  if (!visible) {
    return null
  }

  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.snackbarAddGenericSupplyContainer}>
      <View style={styles.leftContainer}>
        <View style={styles.leftIconContainer}>
          <ActivityIcon
            name={EActivityTypes.ACT_FERTILIZATION}
            size={SIZES.MEDIUM}
            color={lemon900}
            style={styles.textTopContainerIcon}
          />
        </View>

        <View style={styles.textsContainer}>
          <Text style={styles.textTopContainerText}>
            {t('VIEWS.SUPPLIES.ADD_NEW_GENERIC_FERTILIZER')}
          </Text>

          <View style={styles.textBottomContainer}>
            <Text style={styles.textBottomContainerText}>
              {t('VIEWS.SUPPLIES.ADD_NEW_GENERIC_FERTILIZER_EASILY')}
            </Text>

            <CommonIcon
              size={SIZES.SMALL}
              name={'PLUS-CIRCLE'}
              style={styles.textBottomContainerIcon}
            />
          </View>
        </View>
      </View>

      <Pressable style={styles.rightIconContainer} onPress={onPress}>
        <CommonIcon size={SIZES.MEDIUM} name={'X'} style={styles.rightIcon} />
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  snackbarAddGenericSupplyContainer: {
    flexDirection: 'row',
    backgroundColor: yellow200,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 18,
    paddingVertical: 10,
    margin: 16,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: lemon300,
  },
  leftContainer: {
    flexDirection: 'row',
  },
  leftIconContainer: {
    marginRight: 6,
  },
  textsContainer: {
    flexDirection: 'column',
  },
  textTopContainerIcon: {
    fontWeight: '600',
  },
  textTopContainerText: {
    fontSize: 14,
    fontWeight: '600',
    color: lemon900,
  },
  textBottomContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textBottomContainerText: {
    fontSize: 10,
    fontWeight: '500',
    color: lemon700,
  },
  textBottomContainerIcon: {
    color: lemon700,
    marginLeft: 2,
  },
  rightIconContainer: {
    marginLeft: 'auto',
  },
  rightIcon: {
    color: lemon900,
  },
})
