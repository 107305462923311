export const agreementFormValues = ({
  cropId,
  activityId,
  company,
  draftId,
  tag,
  activityType,
  seedDryContent = undefined,
}) => ({
  draftId: draftId,
  cropId: cropId,
  activityId: activityId,
  company: company,
  tag: tag,
  name: activityType?.label,
  type: activityType?.value,
  status: 'DONE',
  lots: {
    value: [],
    label: '',
  },
  surface: '',
  seedDryContent: seedDryContent,
  dataForCFT: undefined,
  establishmentFormId: undefined,
  emissionCO2: undefined,
  summaryEmissionCO2: undefined,
  typeAgreement: {
    value: '',
    label: '',
  },
  dateLimitValidation: {
    value: '',
    label: '',
  },
  signers: [],
  evidences: [],
  files: [],
  farms: [],
  temperature: '',
  weatherType: {
    value: '',
    label: '',
  },
  soilType: {
    value: '',
    label: '',
  },
  landType: {
    value: '',
    label: '',
  },
  humidityType: {
    value: '',
    label: '',
  },
  formResponse: [],
})
