export const goToNavigate = (
  route: string,
  navigation: any,
  callback: () => void
) => {
  if (callback) {
    callback()
  }

  navigation.reset({
    index: 0,
    routes: [{ name: route }],
  })
}
