export default {
  TEXT_1: 'Select Fields',
  TEXT_2: 'field(s) selected',
  TEXT_3: 'ha',
  TEXT_4: 'All farms',
  TEXT_5: 'Executed Area',
  TEXT_6: 'CONFIRM FIELDS',
  TEXT_7: 'Select Fields',
  TEXT_8: 'Verified Area',
  TEXT_9: 'Verified Area',
  TEXT_10: 'Field already applied to this type of verification',
  TEXT_11:
    '%{lotsVerifiedQuantity} of the %{lotsQuantity} lots in the offline selection are no longer available.',
  FIELD_1: {
    NAME: 'Search by farm...',
  },
}
