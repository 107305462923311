import React, { useEffect, useState } from 'react'

import { ActivityIndicator } from '@modules/common/components'
import { useCompanyInfo } from '@modules/common/hooks'
import { NotificationList as List } from './components'
import { useFetchData } from './hooks'
import { primary500 } from '@styles/palette'
import { canActivityPlanning } from './utils'
import useNetwork from '@utils/network'

const NotificationList = ({ navigation }) => {
  const [isViewReady, setIsViewReady] = useState(false)

  const { doRequest } = useNetwork()

  const { companyId } = useCompanyInfo()

  const {
    isLoading: isFetchDataLoading,
    notifications,
    nextPage,
    hasMoreData,
  } = useFetchData({ companyId })

  useEffect(() => {
    if (isFetchDataLoading) {
      return
    }

    setIsViewReady(true)
  }, [isFetchDataLoading])

  const onEndReached = async () => {
    await nextPage()
  }

  const onPressNotification = async ({
    isNotificationRead,
    notificationId,
    cropId,
    activityType,
    activityId,
    achievementId,
  }) => {
    if (!isNotificationRead) {
      const requestData = {
        method: 'PUT',
        url: `pendingTasks/${notificationId}`,
        data: {
          isRead: true,
        },
        displayAlert: false,
      }

      try {
        doRequest(requestData)
      } catch (error) {
        console.error(error)
      }
    }

    /**
     * IS ACHIEVEMENT WITH PLANNING
     */
    if (achievementId) {
      return navigation.navigate('CommonActivitiesDetail', {
        id: cropId,
        achievement: achievementId,
        activity: activityId,
        mode: 'sowing',
        canWrite: true,
        tag: activityType,
      })
    }

    /**
     * IS DIRECT ACHIEVEMENT OR ACTIVITY WITHOUT PLANNING
     */
    return navigation.navigate('ActivityUpdate', {
      cropId,
      activityId,
      tag: activityType,
      isDirectAchievement: canActivityPlanning({ activityType }),
      canSign: true,
      isSigned: false,
      isRealized: true,
      notDeepLink: true,
    })
  }

  if (!isViewReady) {
    return <ActivityIndicator size='large' color={primary500} />
  }

  return (
    <List
      isLoading={isFetchDataLoading}
      notifications={notifications}
      onPress={onPressNotification}
      onEndReached={onEndReached}
      hasMoreData={hasMoreData}
    />
  )
}

export default NotificationList
