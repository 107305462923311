import { useState } from 'react'

const useModal = (defaultVisible = false) => {
  const [isModalVisible, setModalIsVisible] = useState(defaultVisible)

  const toggleModal = (state = false) => {
    setModalIsVisible(state !== null && !isModalVisible)
  }

  const closeModal = () => {
    setModalIsVisible(false)
  }

  return {
    isModalVisible,
    setModalIsVisible,
    toggleModal,
    closeModal,
  }
}

export default useModal
