import React from 'react'
import { View } from 'react-native'
import InputText from '@components/inputs/InputText'

export const CodeForm = ({ formik, t }) => {
  const { values, touched, errors, setFieldValue, setFieldTouched } = formik

  return (
    <View>
      <InputText
        id='code'
        value={values.code}
        label={t('VIEWS.COGNITO_CODE.COMPONENTS.CODE_FORM.LOGIN_CODE')}
        placeholder={t('VIEWS.COGNITO_CODE.COMPONENTS.CODE_FORM.LOGIN_CODE')}
        touched={touched.code}
        error={errors.code}
        onChangeText={(value) => {
          setFieldValue('code', value)
        }}
        onBlur={() => {
          setFieldTouched('code')
        }}
        keyboardType='numeric'
      />
    </View>
  )
}
