export default {
  VALIDATED: 'VALIDADO',
  TEXT_1: 'Superficie realizada: %{surface} %{areaUnit}',
  TEXT_2: 'Tipo de %{activityType}: %{subTypeActivity}',
  TEXT_3: 'EIQ realizado',
  TEXT_4: 'INSUMOS',
  TEXT_5: 'VER DETALLE',
  TEXT_6: 'Borrador',
  TEXT_7: 'Borrador: Versión',
}
