export default {
  TEXT_1: 'Asignar lotes al cultivo',
  TEXT_2: 'ha',
  TEXT_3: 'Todos los campos',
  TEXT_4: 'Lotes no disponibles para este cultivo:',
  TEXT_5:
    'La fecha de siembra de este cultivo es anterior a la fecha de consecha de otro cultivo.',
  TEXT_6: 'SUBIR LOTES SELECCIONADOS',
  FIELD_1: {
    NAME: 'Buscar por campo...',
  },
  TEXT_7: 'Sup. Realizada',
  TEXT_8: 'Confirmar Lotes',
  TEXT_9: 'SIGUIENTE',
  TEXT_10: 'ac',
  TEXT_11: 'Para asignar un lote al cultivo, deberás crear un campo.',
  ADD_FIELDS_TO_CROP: 'Asignar lotes al cultivo',
  ALL_FARMS: 'Todos los campos',
  CANCEL: 'CANCELAR',
}
