import { useContext } from 'react'
import { formatDateLabel, getUnitsLongitude } from '@common/utils'
import { LanguageContext } from '@contextState/language'
import { useAuth } from '@common/hooks'
import { IItem } from '../components'

export const getDataRenderResumeIrrigation = (activityDTO: any, t: any) => {
  const {
    cropName,
    companyName,
    fieldsQuantity,
    surface,
    unitArea,
    irrigationPowerSourceName,
    irrigationWaterSourceName,
    pumpingDepth,
    waterConsumption,
    horizontalWateringDepth,
    dateAchievement,
  } = activityDTO

  const { config } = useAuth()

  const { i18n } = useContext(LanguageContext)
  const unitMeasureSystem = config.companySelected?.unitMeasureSystem
  // @ts-ignore
  const unitsLongitude = getUnitsLongitude(unitMeasureSystem, i18n?.locale)
  const itemsIrrigation: IItem[] = [
    {
      title: t('VIEWS.ACTIVITY_CREATE_CONFIRM.IRRIGATION.SOURCE_OF_WATER'),
      textValue: irrigationWaterSourceName,
    },
    {
      title: t('VIEWS.ACTIVITY_CREATE_CONFIRM.IRRIGATION.PUMPING_DEPTH'),
      textValue: `${pumpingDepth} ${unitsLongitude.UNIT_1}`,
    },
    {
      title: t('VIEWS.ACTIVITY_CREATE_CONFIRM.IRRIGATION.WATER_CONSUMPTION'),
      textValue: `${waterConsumption} ${unitsLongitude.UNIT_2}`,
    },
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.IRRIGATION.HORIZONTAL_IRRIGATION_DISTANCE'
      ),
      textValue: `${horizontalWateringDepth} ${unitsLongitude.UNIT_1}`,
    },
    {
      title: t('VIEWS.ACTIVITY_CREATE_CONFIRM.IRRIGATION.SOURCE_OF_ENERGY'),
      textValue: irrigationPowerSourceName,
    },
  ]

  const itemsCrop: IItem[] = [
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.GENERAL_INFO_CARD.CROP_NAME_TITLE'
      ),
      textValue: cropName,
    },
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.GENERAL_INFO_CARD.COMPANY_NAME_TITLE'
      ),
      textValue: companyName,
    },
  ]

  const itemsActivity: IItem[] = [
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.FIELDS_QUANTITY_TITLE'
      ),
      textValue: fieldsQuantity,
    },
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.SURFACE_TITLE'
      ),
      textValue: `${surface} ${unitArea}`,
    },
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.REALIZED_DATE_TITLE'
      ),
      textValue: formatDateLabel({ t, date: dateAchievement }),
    },
  ]

  return { itemsIrrigation, itemsCrop, itemsActivity }
}
