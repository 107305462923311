import activityTypes from '@constants/activityTypes'
import {
  parseDataToValidateV2,
  validateHarvest,
  validateMonitoring,
  validateAgreement,
  validateVerification,
  validateAchievementUnplanned,
  parseDataToValidateAchievementUnplanned,
  parseDataToValidateIsDirectAchievement,
  validateIrrigation,
  parseToValidateIrrigation,
  validateFlood,
  parseToValidateFlood,
} from './index'

export const validateByTypeActivity = async (params) => {
  const { values, tag, isDirectAchievement, userId, alreadyParsed } = params

  if (
    isDirectAchievement &&
    tag !== activityTypes.ACT_IRRIGATION.key &&
    tag !== activityTypes.ACT_FLOOD.key
  ) {
    return await validateAchievementUnplanned({
      ...params,
      dataToSend: !alreadyParsed
        ? parseDataToValidateAchievementUnplanned({ ...values, tag }, userId)
        : values,
    })
  }

  switch (tag) {
    case activityTypes.ACT_HARVEST.key:
      return await validateHarvest({
        ...params,
        dataToSend: parseDataToValidateIsDirectAchievement(values, userId, tag),
      })

    case activityTypes.ACT_MONITORING.key:
      return await validateMonitoring({
        ...params,
        dataToSend: parseDataToValidateIsDirectAchievement(values, userId, tag),
      })

    case activityTypes.ACT_AGREEMENTS.key:
      return await validateAgreement({
        ...params,
        dataToSend: parseDataToValidateIsDirectAchievement(values, userId, tag),
      })

    case activityTypes.ACT_VERIFICATION.key:
      return await validateVerification({
        ...params,
        dataToSend: parseDataToValidateV2(values),
      })

    case activityTypes.ACT_IRRIGATION.key:
      return await validateIrrigation({
        ...params,
        dataToSend: parseToValidateIrrigation({ ...values, tag }),
      })
    case activityTypes.ACT_FLOOD.key:
      return await validateFlood({
        ...params,
        dataToSend: parseToValidateFlood({ ...values, tag }),
      })
  }
}
