export default {
  TEXT_1: 'Detalle de lote',
  TEXT_2: 'Sup. digital %{surface} %{areaUnit}',
  TEXT_3: '%{applicationQuantity} Aplicaciones',
  TEXT_4: 'EIQ %{eiq}',
  TEXT_5: 'Sustentabilidad del paisaje',
  TEXT_6: '%{value} %{areaUnit} sin solapamiento',
  TEXT_7: '%{value} %{areaUnit} se solapan',
  TEXT_8: 'Apto',
  TEXT_9: 'ha',
  TEXT_10: 'Detalle',
  TEXT_11: 'Ordenamiento de bosques nativos',
  TEXT_12: '%{value}% de solapamiento',
  TEXT_13: 'CATEGORIA %{value}',
  TEXT_14: 'Áreas valiosas de pastizal',
  TEXT_15: 'Áreas de conservación de aves',
  TEXT_16: 'Áreas de desmonte',
  TEXT_17: 'Humedales y turberas',
  TEXT_18: 'Áreas protegidas',
  TEXT_19: 'Áreas de alta biodiversidad',
  TEXT_20: 'Reservas naturales',
  TEXT_21:
    '*Los porcentajes totales tienen en cuenta el solapamiento entre capas.',
}
