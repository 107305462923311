import { useState, useCallback, useContext, useEffect } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import useNetwork from '@utils/network'
import { CommonContext } from '@contextState/common'

import { getNotificationsDTO } from '../utils'

const INIT_PAGE = 1
const LIMIT = 15
const INTI_PAGINATION = {
  page: INIT_PAGE,
  limit: LIMIT,
  totalPages: null,
}

export const useFetchData = ({ companyId }) => {
  const {
    notificationFilters,
    getNotificationFilterValues,
    resetNotificationFilters,
  } = useContext(CommonContext)

  const [paginationData, setPaginationData] = useState(INTI_PAGINATION)
  const [notifications, setNotifications] = useState([])
  const [hasMoreData, setHasMoreData] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const { doRequest } = useNetwork()

  useEffect(() => {
    return () => {
      notificationFilters.hasAnyFilterActive && resetNotificationFilters()
    }
  }, [notificationFilters.hasAnyFilterActive])

  useFocusEffect(
    useCallback(() => {
      fetchData({})
    }, [notificationFilters.values])
  )

  const fetchData = async ({ page = INIT_PAGE, limit = LIMIT }) => {
    setIsLoading(true)

    const filters = getNotificationFilterValues()

    const requestData = {
      method: 'GET',
      url: 'pendingTasks',
      params: {
        ...filters,
        companyId: companyId ? companyId : undefined,
        page,
        limit,
      },
      displayAlert: false,
    }

    let response

    try {
      const { data } = await doRequest(requestData)

      response = data
    } catch (error) {
      setIsLoading(false)

      return
    }

    const notificationsDTO = getNotificationsDTO({
      currentNotifications: page === INIT_PAGE ? [] : notifications,
      newNotifications: response.data,
    })

    setNotifications(notificationsDTO)

    if (!paginationData.totalPages) {
      const newPaginationData = {
        ...paginationData,
        totalPages: response.meta?.totalPages,
      }

      setPaginationData(newPaginationData)
    }

    setIsLoading(false)
  }

  const nextPage = async () => {
    if (isLoading) {
      return
    }

    if (paginationData.page >= paginationData.totalPages) {
      setHasMoreData(false)

      return
    }

    const newPaginationData = {
      ...paginationData,
      page: paginationData.page + 1,
    }

    setPaginationData(newPaginationData)

    await fetchData(newPaginationData)
  }

  return {
    isLoading,
    notifications,
    nextPage,
    hasMoreData,
  }
}
