import { View, StyleSheet } from 'react-native'
import React, { useContext } from 'react'
import { useCommonItemsSelect } from '@common/hooks'
import { white } from '@styles/palette'
import { ActivityIndicator } from '@common/components'
import { FlatList } from 'react-native'
import { ItemList } from './components'
import ButtonDefault from '@common/components/buttons/ButtonDefault'

export const SelectItemsToParameters = () => {
  const {
    items,
    isLoading,
    itemsSelected,
    onSelectItem,
    isMultiple,
    onSubmit,
    fieldNameRender,
    isViewReadOnly,
    textButton,
    fieldIsSelected,
  } = useCommonItemsSelect()
  return (
    <View style={styles.content}>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <FlatList
          data={items ?? []}
          renderItem={({ item }: any) => {
            const itemSelected = Boolean(
              itemsSelected.find(
                (itemSelected: any) =>
                  item[fieldIsSelected] === itemSelected[fieldIsSelected]
              )
            )
            return (
              <ItemList
                item={item}
                onSelectItem={onSelectItem}
                itemSelected={itemSelected}
                isMultiple={isMultiple}
                fieldNameRender={fieldNameRender}
                isViewReadOnly={isViewReadOnly}
              />
            )
          }}
          keyExtractor={(item: any) =>
            `$farm-selected-${item[fieldIsSelected]}`
          }
        />
      )}

      {!isViewReadOnly && (
        <View style={styles.contentButton}>
          <ButtonDefault
            typeButton={itemsSelected.length ? 'primary' : 'disabled'}
            disabled={!itemsSelected.length}
            text={textButton}
            onPress={onSubmit}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: white,
  },
  contentButton: {
    paddingHorizontal: 24,
    paddingVertical: 45,
  },
})
