export default {
  TEXT_1: 'Selected field',
  TEXT_2: 'Required field',
  TEXT_3: 'Select fields',
  TEXT_4: 'Area %{areaUnit}',
  TEXT_5: 'From',
  TEXT_6: 'To',
  TEXT_7: 'Yield unit',
  TEXT_8: 'Yield estimation',
  TEXT_9: 'UNTIL cannot be a date prior to FROM',
  TEXT_10: 'Type of',
  TEXT_11: 'Considerations',
  TEXT_12: 'Add the necessary considerations to be included in the work order.',
  TEXT_13: 'You can enter up to %{considerationsLength} characters.',
}
