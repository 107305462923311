export default {
  TEXT_1: 'Selecione os lotes realizados',
  TEXT_2: 'Lote(s) selecionado(s)',
  TEXT_3: 'hec.',
  TEXT_4: 'Todos os estabelecimentos',
  TEXT_5: 'Área Realizada',
  TEXT_6: 'CONFIRMAR LOTES',
  TEXT_7: 'Selecione os lotes',
  TEXT_8: 'Área verificada',
  TEXT_9: 'Sup. verificada',
  TEXT_10: 'Lote ya aplicado a este tipo de verificación',
  TEXT_11:
    '%{lotsVerifiedQuantity} dos %{lotsQuantity} lotes na seleção offline não estão mais disponíveis.',
  FIELD_1: {
    NAME: 'Pesquisa por estabelecimento...',
  },
}
