import getDatabase from '@offline/queries/getDataBase'
import {
  tableNamesIndividuals,
  tableNamesTypes,
} from '@offline/data_base/tableNames'
import { CREATE_TABLE_DEFAULT } from '@offline/data_base/sqlStatements'
import useOfflineSupplies from '@offline/queries/supplies'
import offlineCropQueries from '@offline/queries/crops'
import useOfflineDrafts from '@offline/queries/drafts'
import useOfflineEvidenceDrafts from '@offline/queries/evidenceDrafts'
import useOfflineUser from '@offline/queries/user'
import useOfflineQueue from '@offline/queries/queue'
import useOfflineSubTypeActivities from '@offline/queries/subTypeActivities'

export const useTableCreationInitializationForOffline = () => {
  const { initOfflineSupply } = useOfflineSupplies()
  const { initOfflineCrop } = offlineCropQueries()
  const { initOfflineDraft } = useOfflineDrafts()
  const { initOfflineEvidenceDrafts } = useOfflineEvidenceDrafts()
  const { initOfflineUser } = useOfflineUser()
  const { initOfflineQueue } = useOfflineQueue()
  const { initOfflineSubTypeActivities } = useOfflineSubTypeActivities()

  const initializeOfflineTables = async () => {
    const db = getDatabase('db.offlinedata')

    const promise = new Promise((resolve, reject) => {
      if (!db) {
        return resolve()
      }

      db.transaction(
        async (tx) => {
          for (const tableName of tableNamesTypes) {
            const sqlStatement = CREATE_TABLE_DEFAULT.replace(
              'tableName',
              `${tableName}_types`
            )

            tx.executeSql(sqlStatement)
          }

          for (const tableName of tableNamesIndividuals) {
            const sqlStatement = CREATE_TABLE_DEFAULT.replace(
              'tableName',
              `individuals_${tableName}`
            )

            tx.executeSql(sqlStatement)
          }

          await Promise.all([
            initOfflineCrop(),
            initOfflineUser(),
            initOfflineSupply(),
            initOfflineDraft(),
            initOfflineEvidenceDrafts(),
            initOfflineQueue(),
            initOfflineSubTypeActivities(),
          ])

          return resolve()
        },
        (_, err) => reject(err)
      )
    })

    return promise
  }

  return {
    initializeOfflineTables,
  }
}
