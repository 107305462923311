import React from 'react'
import { StyleSheet } from 'react-native'
import { Input } from 'react-native-elements'

import { CommonIcon } from '@modules/common/components'
import { grayInput } from '@styles/palette'

const InputSearch = ({
  onChange,
  onChangeText,
  value,
  onPressRight,
  onFocus,
  onBlur,
  inputRef,
  onSubmitEditing,
}) => {
  return (
    <Input
      inputContainerStyle={styles.inputContainerStyle}
      leftIcon={<CommonIcon name='SEARCH' size={25} color={grayInput} />}
      rightIcon={
        <CommonIcon
          name={'X'}
          size={25}
          color={grayInput}
          onPress={onPressRight}
        />
      }
      onChange={onChange}
      onChangeText={onChangeText}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={inputRef}
      onSubmitEditing={onSubmitEditing}
      returnKeyType='search'
    />
  )
}

const styles = StyleSheet.create({
  inputContainerStyle: {
    backgroundColor: '#fff',
    borderWidth: 0,
    borderBottomWidth: 0,
    paddingHorizontal: 10,
    borderRadius: 4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,
    elevation: 11,
  },
})

export default InputSearch
