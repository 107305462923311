import { PropTypes } from 'prop-types'
import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from 'react-native'

import { CommonIcon } from '@modules/common/components'
import { white, blackHighEmphasis } from '@styles/palette'

const { height } = Dimensions.get('window')

const ActionSheetAndModal = ({ modal = false, onClose, title, children }) => {
  return (
    <View style={styles.containerModal}>
      <View
        style={[
          styles.containerHeaderModalGeneral,
          modal ? styles.containerHeaderIsModal : styles.containerHeaderModal,
        ]}
      >
        <TouchableOpacity onPress={onClose}>
          <CommonIcon name={'X'} size={15} style={styles.buttonClose} />
        </TouchableOpacity>

        {Boolean(title) && <Text style={styles.titleModal}>{title}</Text>}
      </View>

      <ScrollView
        style={[
          modal ? styles.contentIsModal : styles.contentModal,
          styles.generalStyleContent,
        ]}
      >
        {children}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  containerModal: {
    backgroundColor: white,
  },
  containerHeaderModalGeneral: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 16,
  },
  containerHeaderIsModal: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  containerHeaderModal: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  buttonClose: {
    alignSelf: 'flex-start',
  },
  titleModal: {
    color: blackHighEmphasis,
    fontWeight: 'bold',
    marginLeft: 20,
    fontSize: 16,
  },
  contentIsModal: {
    paddingHorizontal: 0,
  },
  contentModal: {
    paddingHorizontal: 16,
  },
  generalStyleContent: {
    maxHeight: height - 100,
  },
})

ActionSheetAndModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  modal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default ActionSheetAndModal
