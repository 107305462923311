import { createRef, useState } from 'react'
import { Platform } from 'react-native'

const useActionSheetManagement = () => {
  const actionSheetRef = createRef()
  const [isOpenModal, setOpenModal] = useState(false)

  const openActionSheet = () => {
    Platform.OS !== 'web' ? actionSheetRef.current?.show() : setOpenModal(true)
  }
  const closeActionSheet = () => {
    Platform.OS !== 'web' ? actionSheetRef.current?.hide() : setOpenModal(false)
  }

  return {
    isOpenModal,
    actionSheetRef,
    openActionSheet,
    closeActionSheet,
  }
}

export default useActionSheetManagement
