import { getObjectDepth, truncateNumber } from '@common/utils'
import {
  MapperChemicalCompositionsParams,
  MapperChemicalCompositionsReturn,
  mapperChemicalCompositions,
} from './mapperChemicalCompositions'

interface NutrientData {
  fountain?: string
  N?: number
  N_A?: number
  N_N?: number
  N_U?: number
  P?: number
  P2O5?: number
  K?: number
  K2O?: number
  CA?: number
  CaCO3?: number
  S?: number
  SO4?: number
}

enum NutrientKey {
  N = 'N',
  N_A = 'N_A',
  N_N = 'N_N',
  N_U = 'N_U',
  P = 'P',
  P2O5 = 'P2O5',
  K = 'K',
  K2O = 'K2O',
  CA = 'CA',
  CaCO3 = 'CaCO3',
  S = 'S',
  SO4 = 'SO4',
}

const mappingLabels: { [key: string]: string } = {
  [NutrientKey.N_A]: 'N-NH3',
  [NutrientKey.N_N]: 'N-NO4',
  [NutrientKey.N_U]: 'N-Urea',
  [NutrientKey.P2O5]: 'P2O5',
  [NutrientKey.K2O]: 'K2O',
  [NutrientKey.CaCO3]: 'CaCO3',
  [NutrientKey.SO4]: 'SO4',
}

export function mapData(data: {
  [key: string]: NutrientData | MapperChemicalCompositionsParams
}): MapperChemicalCompositionsReturn[] | null {
  const output: MapperChemicalCompositionsReturn[] = []

  if (getObjectDepth(data) === 1) {
    return mapperChemicalCompositions(data as any)
  }

  for (const key in data) {
    const element: any = data[key]

    if (key in NutrientKey) {
      for (const subKey in element) {
        if (subKey in NutrientKey) {
          const outputKey =
            subKey in mappingLabels ? mappingLabels[subKey] : subKey

          if (element[subKey] !== undefined) {
            output.push({
              name: `% ${outputKey}`,
              value: truncateNumber(element[subKey] as number, 2),
            })
          }
        }
      }
    }
  }

  return output
}
