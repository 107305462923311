import DONWLOAD_REPORT from '../../components/DonwloadReport/translate/es'
import REQUESTED_MODAL from '../../components/RequestedReportModal/translate/es'
export default {
  COMPONENTS: {
    DONWLOAD_REPORT,
    REQUESTED_MODAL,
  },
  LOCATION: 'Ubicación',
  LOTS: 'Lotes',
  BUTTON_ADD_LOTS_TO_FARM: 'AGREGAR LOTES AL CAMPO',
  BUTTON_EDIT_LOTS_TO_FARM: 'AGREGAR LOTES',
}
