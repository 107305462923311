export default {
  TEXT_1: 'Adicionar evidência',
  TEXT_2: 'Campo obrigatório',
  TEXT_3: 'Conceito',
  TEXT_4: 'Data de realização',
  TEXT_5: 'Foto dentro do lote',
  TEXT_6: 'Selecionar arquivo',
  TEXT_7: 'CANCELAR',
  TEXT_8: 'ADICIONAR',
}
