export const PENDING_TASK = 'PENDING_TASK'

/**
 * Enum for notification types.
 * @readonly
 * @enum {string}
 */
export const ENotificationType = {
  PENDING_TASK: PENDING_TASK,
}
