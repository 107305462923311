export default {
  TEXT_1: 'El CUIT ya existe registrado, por favor ingresa otro',
  TEXT_2: 'Campo Requerido',
  TEXT_3: 'Ingresa un numero',
  TEXT_4: 'CUIT Invalido',
  TEXT_5: 'El CUIT inválido',
  FIELD_1: {
    LABEL: 'CUIT',
    HELPTEXT: 'De la empresa física o jurídica a cargo de la producción.',
  },
  FIELD_2: {
    LABEL: 'Tax ID',
    HELPTEXT: 'De la empresa física o jurídica a cargo de la producción.',
  },
  TEXT_6: 'Agregar documentación',
  FIELD_3: {
    LABEL: 'Nombre',
    PLACEHOLDER: 'Nombre',
    VALIDATE: 'Campo requerido',
  },
  FIELD_4: {
    LABEL: 'Concepto',
    PLACEHOLDER: 'Concepto',
    VALIDATE: 'Campo requerido',
  },
  FIELD_5: {
    LABEL: 'Fecha de realización',
    PLACEHOLDER: 'Fecha de realización',
  },
  TEXT_7: 'SELECCIONAR DOCUMENTO',
  TEXT_8: 'CANCELAR',
  TEXT_9: 'AGREGAR',
  FIELD_6: {
    LABEL: 'País',
  },
  TEXT_10: 'CUIT:',
  FIELD_7: {
    LABEL: 'Razon Social',
    PLACEHOLDER: 'Razon Social',
    VALIDATE: 'Campo requerido',
  },
  FIELD_8: {
    LABEL: 'Personería',
    OPTION_1: 'Fisica',
    OPTION_2: 'Jurídica',
    VALIDATE: 'Campo requerido',
  },
  FIELD_9: {
    LABEL: 'Dirección',
    PLACEHOLDER: 'Dirección',
    VALIDATE: 'Campo requerido',
  },
  FIELD_10: {
    LABEL: 'Piso / Depto / Oficina',
    PLACEHOLDER: 'Piso / Depto / Oficina',
  },
  TEXT_11: 'AGREGAR DOCUMENTACIÓN',
  TEXT_12: 'Guardar',
}
