import { PropTypes } from 'prop-types'
import React from 'react'
import { Text, Dimensions, StyleSheet, View } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import { blackHighEmphasis, black } from '@styles/palette'
import { LottieAnimation } from '@common/components/animations/LottieAnimation/index'
import { useResponsive } from '@modules/common/hooks'
const width = Dimensions.get('window').width
// Todo: Este numero se usa para poder centrar en pantalla completa, si el tamaño es 960 el swiper no funciona tiene que ser el tamaño completo de la pantalla
const PADDING_LEFT = 344
const LOTTIE_ANIMATION_SIZE = 272

const ItemSwiper = ({
  title,
  description,
  animation,
  onPress,
  animationRef,
}) => {
  const { isScreenDesktop } = useResponsive()
  const styleResponsive = isScreenDesktop
    ? { paddingLeft: PADDING_LEFT }
    : { alignItems: 'center' }

  return (
    <View>
      <TouchableRipple onPress={onPress} style={styles.flex}>
        <View style={[styles.child, styleResponsive]}>
          <View style={styles.content}>
            <View style={styles.contentAnimation}>
              <LottieAnimation
                animation={animation}
                width={LOTTIE_ANIMATION_SIZE}
                height={LOTTIE_ANIMATION_SIZE}
                animationRef={animationRef}
              />
            </View>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
          </View>
        </View>
      </TouchableRipple>
    </View>
  )
}
const styles = StyleSheet.create({
  flex: { flex: 1 },
  child: {
    flex: 1,
    justifyContent: 'center',
    width,
  },
  content: { maxWidth: 300, alignItems: 'flex-start' },
  title: {
    fontSize: 24,
    fontWeight: '800',
    lineHeight: 24,
    color: blackHighEmphasis,
    marginBottom: 20,
    marginTop: 64,
  },
  description: {
    color: black,
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
  },
  contentAnimation: {
    width: LOTTIE_ANIMATION_SIZE,
    height: LOTTIE_ANIMATION_SIZE,
  },
})

ItemSwiper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  style: PropTypes.object,
  onPress: PropTypes.func,
}

export default ItemSwiper
