export default {
  ADD_DOCUMENTATION: 'Add documentation',
  FIELD_NAME: {
    LABEL: 'Name',
    PLACEHOLDER: 'Name',
    VALIDATE: 'Required field',
  },
  FIELD_CONCEPT: {
    LABEL: 'Concept',
    PLACEHOLDER: 'Concept',
    VALIDATE: 'Required field',
  },
  FIELD_DATE: {
    LABEL: 'Date of realization',
    PLACEHOLDER: 'Date of realization',
  },
  SELECT_DOCUMENT: 'SELECT DOCUMENT',
  CANCEL: 'CANCEL',
  ADD: 'ADD',
}
