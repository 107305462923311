import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/en-au'
import 'moment/locale/pt'
import 'moment/locale/es'

/**
 * Add One Year Date.
 *
 * @param {*} date
 */
const addDateOneYear = (date) => {
  return new Date(date.getFullYear() + 1, date.getMonth(), date.getDate())
}

const formatDateMoment = (
  date,
  format = 'DD/MM/YYYY',
  locale = 'en',
  capitalize = false
) => {
  if (date) {
    moment.locale(locale)
    if (capitalize) {
      return dateCapitalize(moment(date).format(format).replace('.', ''))
    }
    return moment(date).format(format)
  }
  return ''
}

/** Use only for date format with month name
 * example: 01/abr/2020
 * when date include time, is required use of a separator ' - '
 * example: 01/abr/2020 - 10:00 PM
 * this function return date with month in capitalize
 * return example: 01/Abr/2020
 */
const dateCapitalize = (date) => {
  let monthName = ''
  let separator
  let ampm = false
  const dateArray = date.split('')

  for (const [i, element] of dateArray.entries()) {
    if (isNaN(Number(element))) {
      if (!element.match(/[a-z]/i)) {
        separator = !separator ? element : separator
        continue
      }
      if (
        ((element?.toLowerCase() === 'p' || element?.toLowerCase() === 'a') &&
          dateArray[i + 1]?.toLowerCase() === 'm') ||
        (element.toLowerCase() === 'm' &&
          (dateArray[i - 1]?.toLowerCase() === 'a' ||
            dateArray[i - 1]?.toLowerCase() === 'p'))
      ) {
        ampm = true
        continue
      }
      monthName = monthName + element
    }
  }
  if (ampm) {
    return `${date
      .split('-')[0]
      .split(separator)
      .map((element) =>
        element === monthName ? _.startCase(monthName) : element
      )
      .join(separator)}-${date.split('-')[1]}`
  }
  return date
    .split(separator)
    .map((element) =>
      element === monthName ? _.startCase(monthName) : element
    )
    .join(separator)
}

const addDateFifteenDays = (date) => {
  return new Date(new Date(date).getTime() + 15 * 24 * 60 * 60 * 1000)
}

const validateDateIsOlder = (dateIn, dateOut) => {
  const dateStart = new Date(dateIn)
  const dateEnd = new Date(dateOut)
  if (dateEnd.getTime() > dateStart.getTime()) {
    return true
  }
  return false
}

const diffInMinutes = (date1, date2) => {
  var diff = (date2.getTime() - date1.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

export {
  addDateOneYear,
  formatDateMoment,
  addDateFifteenDays,
  validateDateIsOlder,
  diffInMinutes,
}
