import React from 'react'
import { Divider, List } from 'react-native-paper'
import { StyleSheet, Image } from 'react-native'
import Outlier from '@modules/common/components/Outlier'
import { outlierIcons } from '@constants/outlierData'
import { CommonIcon } from '@modules/common/components'

function CommonList({
  data,
  onDelete,
  disabled,
  icon,
  iconRender,
  img,
  onPress,
  index,
  canShowOutlier,
  t,
  openBackdropStandardDeviation,
  isValueOutsideMean,
  outlierType,
}) {
  async function handlePress(data) {
    onPress(data)
  }
  const renderIconOrImg = (props) => {
    return img ? (
      <Image style={styles.image} source={img} />
    ) : (
      <CommonIcon {...props} name={icon} size={35} style={styles.icon} />
    )
  }

  return (
    <>
      <List.Item
        onPress={onPress ? () => handlePress(data) : null}
        title={data.name}
        description={data.description}
        titleNumberOfLines={1}
        descriptionNumberOfLines={5}
        left={!iconRender ? renderIconOrImg : iconRender}
        right={(props) =>
          onDelete ? (
            <CommonIcon
              {...props}
              name={'X'}
              style={styles.icon}
              onPress={() => onDelete(index)}
              disabled={disabled}
            />
          ) : null
        }
      />
      {canShowOutlier && isValueOutsideMean && (
        <Outlier
          title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
          icon={outlierIcons.InfoFill}
          description={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_DESCRIPTION}
          type={outlierType}
          style={styles.outlier}
          onPress={openBackdropStandardDeviation}
        />
      )}
      <Divider />
    </>
  )
}

const styles = StyleSheet.create({
  icon: {
    marginTop: 5,
  },
  image: {
    width: 50,
    height: 50,
    marginTop: 8,
  },
  outlier: {
    marginLeft: 65,
    marginBottom: 10,
  },
})

export default CommonList
