export default {
  TEXT_1: 'GUARDAR BORRADOR',
  TEXT_2: 'VALIDAR',
  TEXT_3: 'FIRMAR',
  TEXT_4: 'FIRMADO',
  TEXT_5: 'Borrador: versión %{version}',
  TEXT_6: 'por %{user} el %{date} a las %{hour} hs.',
  TEXT_7: 'Se guardó la actividad en borrador',
  TEXT_8:
    'Esta entrada está siendo editada por otro colaborador (%{userFullname}).',
  TEXT_9: 'EN VERIFICACIÓN',
  TEXT_10: 'Rechazar',
  CROP_DESCRIPTION: {
    TEXT_1: '%{surface} has',
  },
  HARVEST_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Seleccionar lotes',
      LABEL: 'Lotes seleccionados',
      LABEL_ALT: 'Lote seleccionado',
    },
    FIELD_2: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    FIELD_3: {
      PLACEHOLDER: 'Fecha de cosecha',
      LABEL: 'Fecha de cosecha',
    },
    FIELD_4: {
      PLACEHOLDER: 'Unidad de rinde',
      LABEL: 'Unidad de rinde',
    },
    FIELD_5: {
      PLACEHOLDER: 'Volumen',
      LABEL: 'Volumen',
    },
    FIELD_6: {
      PLACEHOLDER: 'Rinde',
      LABEL: 'Rinde',
    },
    FIELD_VOLUME_UNIT: {
      PLACEHOLDER: 'Unidad de volumen',
      LABEL: 'Unidad de volumen',
    },
  },
  MONITORING_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Seleccionar lotes',
      LABEL: 'Lotes seleccionados',
      LABEL_ALT: 'Lote seleccionado',
    },
    FIELD_2: {
      PLACEHOLDER: 'Fecha de monitoreo',
      LABEL: 'Fecha de monitoreo',
    },
    FIELD_3: {
      PLACEHOLDER: 'Unidad de rinde',
      LABEL: 'Unidad de rinde',
    },
    FIELD_4: {
      PLACEHOLDER: 'Estimación de rinde',
      LABEL: 'Estimación de rinde',
    },
    FIELD_5: {
      PLACEHOLDER: 'Observación',
      LABEL: 'Observación',
    },
    FIELD_6: {
      PLACEHOLDER: 'Estimación de cosecha',
      LABEL: 'Estimación de cosecha',
    },
  },
  AGREEMENT_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Tipo de declaración',
      LABEL: 'Tipo de declaración',
    },
    FIELD_2: {
      PLACEHOLDER: 'Seleccionar lotes',
      LABEL: 'Lotes seleccionados',
      LABEL_ALT: 'Lote seleccionado',
    },
    FIELD_3: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Porcentaje semilla seca',
      LABEL: 'Porcentaje semilla seca',
    },
    FARM_FIELD: {
      SINGULAR: '%{num} Campo seleccionado',
      PLURAL: '%{num} Campos seleccionados',
    },
    SURFACE_FIELD: {
      LIMIT: 'La superficie no puede superar las %{surfaceMax} %{areaUnit}.',
    },
  },
  STATEMENT_CFT_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Seleccionar campo',
      LABEL: 'Seleccionar campo',
    },
    FIELD_2: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    EMISSION_TITLE: 'Cálculo GEI',
    EMISSION_TEXT_BEFORE_SEND:
      'Completando sólo unos datos, generaremos un informe calculando los gases de efecto invernadero (GEI) con la metodología CFT y lo agregaremos como evidencia al cultivo.',
    EMISSION_BUTTON: 'Generar evidencia',
    EMISSION_TEXT_AFTER_SEND:
      'Al validar la declaración, generaremos el informe y lo agregaremos como evidencia al cultivo.',
  },
  STATEMENT_FORM: {
    SELECT_FIELDS: 'Seleccionar lotes',
    DECLARATIVE_REPORT: 'Informe declarativo',
    COMPLETE_THE_FORM:
      'Completa el formulario para generar el informe y agregarlo como evidencia a tu cultivo.',
    COMPLETE_FORM: 'Completar formulario',
    FORM_TYPE_DECLARATION_REPORT: 'Informe Declaración tipo fomulario',
    BY_VALIDATING_THE_DECLARATION:
      'Al validar la declaración, generaremos el informe y lo agregaremos como evidencia a tu cultivo',
  },
  IRRIGATION_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Método de riego',
      LABEL: 'Método de riego',
    },
    FIELD_2: {
      PLACEHOLDER: 'Seleccionar lotes',
      LABEL: 'Lotes seleccionados',
      LABEL_ALT: 'Lote seleccionado',
    },
    FIELD_3: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Fecha de realización',
      LABEL: 'Fecha de realización',
    },
    FIELD_5: {
      PLACEHOLDER: 'Fuente de agua',
      LABEL: 'Fuente de agua',
    },
    FIELD_6: {
      PLACEHOLDER: 'Profundidad de bombeo %{unit}',
      LABEL: 'Profundidad de bombeo %{unit}',
    },
    FIELD_7: {
      PLACEHOLDER: 'Consumo de agua %{unit}',
      LABEL: 'Consumo de agua %{unit}',
    },
    FIELD_8: {
      PLACEHOLDER: 'Distancia de riego horizontal %{unit}',
      LABEL: 'Distancia de riego horizontal %{unit}',
    },
    FIELD_9: {
      PLACEHOLDER: 'Fuente de energía',
      LABEL: 'Fuente de energía',
    },
  },
  STATEMENT_SDP_REPORT_NEED_SURFACE_FARM: {
    TITLE: 'Superficie del campo',
    DESCRIPTION:
      'Para generar la declaración, necesitamos que subas un archivo KMZ con el perímetro del campo para calcular la superficie con precisión.',
    REDIRECT_EDIT_FARM_TEXT: 'Subir archivo KMZ',
  },
  VERIFICATION_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Tipo de verificación',
      LABEL: 'Tipo de verificación',
    },
    FIELD_2: {
      PLACEHOLDER: 'Seleccionar lotes',
      LABEL: 'Lotes seleccionados',
      LABEL_ALT: 'Lote seleccionado',
    },
    FIELD_3: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Observación',
      LABEL: 'Observación',
    },
  },
  DESTINATION: {
    TEXT_1: 'AGREGAR DESTINO',
    MODAL_1: {
      TEXT_1: 'AGREGAR DESTINO',
    },
  },
  EVIDENCE: {
    TEXT_1: 'AGREGAR EVIDENCIA',
    MODAL_1: {
      TEXT_1: 'AGREGAR EVIDENCIA',
    },
  },
  SUPPLY: {
    TEXT_1: 'AGREGAR INSUMO',
  },
  SUPPLIES_LIST: {
    TEXT_1: 'EIQ %{eiqQuantity}',
  },
  DESTINATION_LIST: {
    TEXT_1: 'Almacenamiento',
    TEXT_2: '%{tonQuantity} Toneladas',
  },
  COLLABORATORS: {
    TEXT_1: 'Colaboradores',
    TEXT_2: 'Agregar colaboradores',
    TEXT_3: '%{collaboratorsQuantity} colaboradores seleccionados',
  },
  MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES: {
    TEXT_1: '¿Salir sin guardar?',
    TEXT_2: 'No se conservarán los cambios.',
    TEXT_3: 'SALIR',
    TEXT_4: 'PERMANECER',
  },
  MODAL_DELETE: {
    TEXT_1: '¿Eliminar Borrador?',
    TEXT_2: 'Las evidencias subidas se eliminarán definitivamente.',
    TEXT_3: 'CONSERVAR',
    TEXT_4: 'ELIMINAR',
  },
  MODAL_VALIDATE: {
    TEXT_1: '¿Validar esta versión del borrador?',
    TEXT_2: 'Existen otras versiones de este mismo registro',
    TEXT_3: 'CANCELAR',
    TEXT_4: 'VALIDAR',
  },
  MODAL_CONFIRM_ACTIVITY_VERIFICATION: {
    TEXT_1: 'Tipo de verificación',
    TEXT_2:
      'Al modificar el tipo de verificación se perderan todos los datos de la Actividad',
    TEXT_3: 'MODIFICAR',
    TEXT_4: 'CANCELAR',
  },
  MODAL_VALIDATE_VERIFICATION: {
    TEXT_1: 'Validar verificación',
    TEXT_2:
      'Si consideras aceptada la verificación, carga aquí el documento que lo respalda.',
    TEXT_3: 'AGREGAR VERIFICACIÓN',
    TEXT_4: 'Verificación %{verificationType}',
    TEXT_5: 'Estado: %{verificationStatus}',
    TEXT_6: 'Seleccione el estado final de la verificación:',
    TEXT_7: 'NO CONFORME',
    TEXT_8: 'VERIFICADO',
  },
  MODAL_CONFIRM_VALIDATE_VERIFICATION: {
    TEXT_1: 'Confirmar Verificación',
    TEXT_2:
      'Una vez validada la verificación no podrá modificar los datos cargados.',
    TEXT_3: 'CANCELAR',
    TEXT_4: 'VALIDAR',
  },
  MODAL_CONFIRM_DECLINE_SIGNATURE: {
    TEXT_1: 'Estás a punto de rechazar una firma',
    TEXT_2:
      'Ten en cuenta que esta acción implica que la actividad quedará suspendida.',
    TEXT_3: 'Ingresar una observación',
    TEXT_4: 'Puedes ingresar hasta 140 caracteres.',
    TEXT_5: 'Confirmar rechazo',
  },
  MODAL_LOADING: {
    COMPLETING_THE_APPLICATION: 'Completando la solicitud',
    PLEASE_WAIT_A_FEW_MOMENTS: 'Aguarda unos instantes.',
    PLEASE_WAIT_A_FEW_MORE_MOMENTS: 'Aguarda unos instantes más.',
  },
  AGREEMENT_FORM_DISCLAIMER_EVIDENCE: {
    SUSTAINABILITY_2BSVS: {
      TITLE: 'Informe de Sustentabilidad del paisaje - 2BSvs',
      DESCRIPTION:
        'Una vez validada la actividad, generaremos el informe en unos minutos.',
    },
    SUSTAINABILITY_EPA: {
      TITLE: 'Informe de Sustentabilidad del paisaje - EPA',
      DESCRIPTION:
        'Una vez validada la actividad, generaremos el informe en unos minutos.',
    },
    SUSTAINABILITY_MOA: {
      TITLE: 'Informe de Sustentabilidad del paisaje - MOA',
      DESCRIPTION:
        'Una vez validada la actividad, generaremos el informe en unos minutos.',
    },
    SUSTAINABILITY_CAN: {
      TITLE: 'Informe de Sustentabilidad del paisaje - Generico',
      DESCRIPTION:
        'Una vez validada la actividad, generaremos el informe en unos minutos.',
    },
  },
  WATER_CALCULATION_FORM: {
    WEATHER: 'Clima',
    TEMPERATURE: 'Temperatura °%{unitTemperature}',
    LAND_TYPE: 'Tipo de suelo',
    SOIL_CHARACTERISTIC: 'Característica de suelo',
    SOIL_MOISTURE: 'Humedad del suelo',
    MEASURED_AT_PLANTING: 'Medida al momento de la siembra',
  },
  FLOOD_FORM: {
    TIME_FLOOD_UNDER_CROP: 'Tiempo de inundación bajo cultivo',
    WATER_MANAGEMENT: 'Gestión del agua',
    BEFORE_CROP: 'Antes del cultivo',
    DURING_CROP: 'Durante el cultivo',
    DEFAULT_VALUES: 'Usar valores por defecto',
    UNIT_TIME: 'Unidad de tiempo',
  },
}
