export default {
  TEXT_1: 'Tax ID has already been registered, Please enter another',
  TEXT_2: 'Required field',
  TEXT_3: 'Enter a number',
  TEXT_4: 'Tax ID Unvalid',
  TEXT_5: 'The Tax ID Unvalid',
  FIELD_1: {
    LABEL: 'Tax ID',
    HELPTEXT: 'From the physical or legal company in charge of production.',
  },
  FIELD_2: {
    LABEL: 'Tax ID',
    HELPTEXT: 'From the physical or legal company in charge of production.',
  },
  TEXT_6: 'Add documentation',
  FIELD_3: {
    LABEL: 'Name',
    PLACEHOLDER: 'Name',
    VALIDATE: 'Required field',
  },
  FIELD_4: {
    LABEL: 'Concept',
    PLACEHOLDER: 'Concept',
    VALIDATE: 'Required field',
  },
  FIELD_5: {
    LABEL: 'Date of execution',
    PLACEHOLDER: 'Date of execution',
  },
  TEXT_7: 'Select file',
  TEXT_8: 'Cancel',
  TEXT_9: 'Add',
  FIELD_6: {
    LABEL: 'Country',
  },
  TEXT_10: 'Tax ID:',
  FIELD_7: {
    LABEL: 'Company name',
    PLACEHOLDER: 'Company name',
    VALIDATE: 'Required field',
  },
  FIELD_8: {
    OPTION_1: 'Physical',
    OPTION_2: 'Legal',
    VALIDATE: 'Required field',
  },
  FIELD_9: {
    LABEL: 'Address',
    PLACEHOLDER: 'Address',
    VALIDATE: 'Required field',
  },
  FIELD_10: {
    LABEL: 'Floor / Apartment / Office',
    PLACEHOLDER: 'Floor / Depto / Office',
  },
  TEXT_11: 'Add documentation',
  TEXT_12: 'Saving',
  TEXT_13: 'Legal Identity',
}
