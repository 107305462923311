import CALENDAR from './calendar'
import CONSTANTS from './constants'
import ERRORS from './errors'
import VIEWS from './views'
import COMPONENTS from './components'
import NAVIGATION from './navigation'
import UTILS from './utils'
import CONTEXT_STATE from './contextState'
import ACTIVITIES from '@modules/activities/translate/pt'
import COMMON from '@modules/common/translate/pt'

export default {
  CALENDAR,
  CONSTANTS,
  ERRORS,
  VIEWS,
  COMPONENTS,
  NAVIGATION,
  UTILS,
  CONTEXT_STATE,
  ACTIVITIES,
  COMMON,
}
