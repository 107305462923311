import React, { useContext, useState } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Text, ActivityIndicator } from 'react-native-paper'
import { PropTypes } from 'prop-types'
import { EstablishmentContext } from '@contextState/establishment'

import EstablishmentsList from '@components/lots/v1/EstablishmentsList'
import { LanguageContext } from '@contextState/language'
import { buttonDisabled, blackDisabled, primary500 } from '@styles/palette'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { useSelector } from 'react-redux'
import { selectDraftCrop } from '@store/selectors/draftCrops'
import { LotsEmpty, CompanyDetails } from '@modules/common/components'
import ButtonCustom from '@components/common/ButtonCustom'
import { useAuth, useOfflineCrops } from '@modules/common/hooks'
import { green500 } from '@common/styles/palette'
import RouterNames from '@constants/routerNames'

const LotsAdd = ({ navigation, route }) => {
  const cropId = route?.params?.cropId
  const lotsAdd = route?.params?.lotsAdd
  const onSubmit = route?.params?.onSubmit
  const cropName = route?.params?.cropName

  const { config } = useAuth()

  const { identifier, name } = config.companySelected
  const valuesForm = useSelector(selectDraftCrop(identifier))
  const {
    establishments,
    displayEstablishmentToggle,
    isLoading,
    allEstablishmentSelected,
    selectEstablishmentToggle,
    selectAllEstablishmentsToggle,
    selectLotToggle,
  } = useContext(EstablishmentContext)

  const { t } = useContext(LanguageContext)

  const { updateFarmsCropOffline } = useOfflineCrops()

  const [isSubmitting, setIsSubmitting] = useState(false)

  /**
   * GO TO CROP CONFIRM
   */
  const goToCropConfirm = async () => {
    if (!validateEstablishments()) {
      return
    }

    if (lotsAdd) {
      setIsSubmitting(true)

      const isSuccess = await onSubmit(buildReusableLots())

      setIsSubmitting(false)

      if (!isSuccess) {
        return
      }

      updateFarmsCropOffline({ cropId }).then(({ error }) => {
        if (error) {
          console.error(error)
        }
      })

      return navigation.goBack()
    }

    return navigation.navigate(RouterNames.CROP_CREATE_CONFIRM, {
      identifier: route?.params?.identifier,
    })
  }

  /*
  BUILD ESTABLISHMENTS SELECTED
  */
  const buildReusableLots = () => {
    const establishmentsSelected = []
    establishments.map((element) => {
      if (element.disabled) return

      const newLots = element.lots.filter(
        (subElement) => !subElement.disabled && subElement.selected
      )

      if (!newLots.length) return

      establishmentsSelected.push({
        ...element,
        lots: newLots,
      })
    })

    const reusableLots = establishmentsSelected
      .filter((element) => !element.file)
      .map((element) => {
        return {
          tag: element.tag,
          farmId: element?.farmId,
          lotIds: element.lots.map((subElement) => subElement._id),
        }
      })

    return reusableLots
  }

  /**
   * VALIDATE IF ANY ESTABLISHMENT IS VALID
   */
  const validateEstablishments = () => {
    if (!establishments) {
      return false
    }
    return establishments?.filter((element) => {
      return element.lots.some((item) => item.selected)
    })?.length
  }

  const gotoListCrops = () => {
    navigation.navigate('CropList')
  }

  const onCancel = () => {
    if (lotsAdd) return navigation.goBack()

    navigation.reset({
      index: 0,
      routes: [{ name: 'CropList' }],
    })
  }

  return (
    <SafeAreaProvider style={styles.safeAreaProvider}>
      <CompanyDetails
        companyName={name}
        companyIdentifier={identifier}
        style={styles.CompanyDetails}
      />

      {!establishments.length && !isLoading ? (
        <>
          <LotsEmpty
            title={t('VIEWS').LOTS_ADD.TEXT_11}
            onPressButton={gotoListCrops}
          />
        </>
      ) : (
        <>
          <ScrollView style={styles.container}>
            <Text
              style={styles.cropName}
              ellipsizeMode='tail'
              numberOfLines={1}
            >
              {lotsAdd ? cropName : valuesForm?.name}
            </Text>

            <EstablishmentsList
              establishmentsContext={establishments}
              displayEstablishmentToggle={displayEstablishmentToggle}
              allEstablishmentSelected={allEstablishmentSelected}
              selectEstablishmentToggle={selectEstablishmentToggle}
              selectAllEstablishmentsToggle={selectAllEstablishmentsToggle}
              selectLotToggle={selectLotToggle}
              navigation={navigation}
            />

            {isLoading && (
              <ActivityIndicator
                size='large'
                color={green500}
                style={styles.loader}
              />
            )}
          </ScrollView>

          <View style={styles.containerButton}>
            <ButtonPrimary
              onPress={() => goToCropConfirm()}
              text={t('VIEWS').LOTS_ADD.TEXT_9}
              disabled={!validateEstablishments()}
              loading={isSubmitting}
              backgroundColor={
                validateEstablishments() ? primary500 : buttonDisabled
              }
              marginTop={10}
              marginBottom={6}
              colorText={validateEstablishments() ? '#FFF' : blackDisabled}
            />

            <ButtonCustom
              isLoading={false}
              onPress={onCancel}
              styles={styles.cancelButton}
              labelStyle={styles.textCancelButton}
            >
              {t('VIEWS').LOTS_ADD.CANCEL}
            </ButtonCustom>
          </View>
        </>
      )}
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  safeAreaProvider: {
    backgroundColor: '#FFFFFF',
  },
  containerButton: {
    paddingHorizontal: 20,
  },
  container: {
    flex: 1,
  },
  cropName: {
    fontSize: 16,
    fontWeight: '500',
    marginLeft: 32,
    paddingRight: 20,
  },
  loader: {
    marginTop: 100,
  },
  button: {
    marginTop: 30,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: '#4CAF50',
    borderRadius: 10,
  },
  buttonDisabled: {
    marginTop: 30,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: buttonDisabled,
    borderRadius: 10,
  },
  CompanyDetails: {
    marginLeft: 32,
  },
  cancelButton: {
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 1,
    shadowColor: primary500,
    borderWidth: 1,
    borderColor: primary500,
  },
  textCancelButton: {
    color: primary500,
  },
})

LotsAdd.propTypes = {
  navigation: PropTypes.object,
}

export default LotsAdd
