export default {
  TEXT_1: 'lotes seleccionados',
  TEXT_2: 'Campo requerido',
  TEXT_3: 'Seleccionar Lotes',
  TEXT_4: 'Fecha de observación',
  TEXT_5: 'Unidad de rinde',
  TEXT_6: 'Estimación de rinde',
  TEXT_7: 'Observación',
  TEXT_8: 'Estimacion de cosecha',
}
