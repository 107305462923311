export default {
  TEXT_1: 'EIQ',
  TEXT_2: 'Required field',
  TEXT_3: 'Invalid number',
  TEXT_4: 'Negative number',
  TEXT_5: 'The area must be greater than 0',
  FIELD_1: {
    LABEL: 'input',
  },
  FIELD_2: {
    LABEL: 'Measurement Unit',
  },
  FIELD_3: {
    LABEL: 'Total',
    KEY: 'Accept',
  },
  FIELD_4: {
    LABEL: 'Area ha',
  },
  FIELD_5: {
    LABEL: 'Amount of',
  },
  TEXT_6: 'ha',
  TEXT_7: 'Cancel',
  TEXT_8: 'Select',
  TEXT_9: 'Find the required input',
  TEXT_10: 'inputs',
  COMPOSITION: 'Composition',
  ENTER_THE_AMOUNT_APPLIED: 'Enter the amount applied in %{unit}.',
}
