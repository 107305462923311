import React from 'react'
import { Text, View, StyleSheet } from 'react-native'

interface HeaderActivityCreateConfirmProps {
  title: string
  subtitle?: string
  styles?: object
}

export const HeaderActivityCreateConfirm: React.FC<
  HeaderActivityCreateConfirmProps
> = ({ title, subtitle, styles: contentStyles }) => {
  return (
    <View style={contentStyles}>
      <Text style={styles.title}>{title}</Text>

      {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: '700',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '700',
    marginTop: 5,
  },
})
