/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import useNetwork from '@utils/network'
import { ResponsePaginationInterface } from '@common/interfaces'

interface IParams {
  url: string
  method: string
  version?: string
  params?: any
}

const useFetchPagination = ({
  url,
  method,
  version = 'v1',
  params = {},
}: IParams) => {
  const LIMIT_PAGE = 20
  const [response, setResponse] = useState<ResponsePaginationInterface>({
    data: [],
    metaInfo: {},
    meta: {},
  })
  const [error, setError] = useState<null | unknown>(null)
  const [isLoading, setIsLoading] = useState(true)
  const { doRequest } = useNetwork()
  const [firstPaginate, setFirstPaginate] = useState(true)
  const [page, setPage] = useState(1)
  const [refresh, setRefresh] = useState(true)

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const res = await doRequest({
        method,
        url,
        version,
        params: { limit: LIMIT_PAGE, page, ...params },
        displayAlert: false,
      })

      const dataResult = res.data || res
      const { results, metaInfo, meta } = dataResult

      setResponse({
        ...dataResult,
        data: page === 1 ? results : [...response.data, ...results],
        metaInfo,
        meta,
      })

      setFirstPaginate(false)
      setIsLoading(false)
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [page, refresh])

  const nextPage = () => {
    if (!response.metaInfo.totalPages) return
    if (page < response.metaInfo.totalPages) {
      setPage(page + 1)
    }
  }

  const refreshPage = () => {
    setPage(1)
    setRefresh((prevState) => !prevState)
  }

  return {
    response,
    error,
    isLoading,
    setError,
    setResponse,
    firstPaginate,
    nextPage,
    refreshPage,
    page,
  }
}

export default useFetchPagination
