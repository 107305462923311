import { gray300, red100, red700 } from '@common/styles'
import { LanguageContext } from '@contextState/language'
import { Alert } from '@modules/crops/screens/CropList/v1/components'
import { GenericSupplyHeader } from '@modules/supplies/components'
import { useCreateGenericSupply } from '@modules/supplies/hooks'
import { generateSupplyNameV2 } from '@modules/supplies/utils'
import { hasChemicalCompositions } from '@modules/supplies/utils/hasChemicalComponent'
import {
  lemon300,
  lemon900,
  primary500,
  white,
  carbon50,
} from '@styles/palette'
import { useContext } from 'react'
import { Platform, ScrollView, StyleSheet, View } from 'react-native'
import { ResponsiveLayout } from '@common/components'
import { WITH_TABLE } from '@common/constants/width'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { GenericSupplyFormV2 } from '../../../components/GenericSupplyForm/v2/index'

const isWeb = Platform.OS === 'web'

const CreateGenericSupplyV2 = () => {
  const { t } = useContext(LanguageContext)
  const {
    formik,
    options,
    setValueWithEquivalence,
    setValueForm,
    handleSubmit,
    validatePercent,
  } = useCreateGenericSupply()
  const { values, errors } = formik

  return (
    <ResponsiveLayout maxWidth={WITH_TABLE} backgroundColor={carbon50}>
      <ScrollView
        style={styles.scrollView}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
      >
        <GenericSupplyHeader
          supplyName={generateSupplyNameV2(values as any)}
          iconBoxColor={lemon300}
          iconColor={lemon900}
        />

        {errors.chemicalCompositions &&
          typeof errors?.chemicalCompositions === 'string' &&
          hasChemicalCompositions(values) && (
            <Alert
              message={errors.chemicalCompositions}
              containerStyle={styles.contentAlert}
              textStyle={styles.textAlert}
            />
          )}

        <GenericSupplyFormV2
          formik={formik}
          options={options}
          setValueWithEquivalence={setValueWithEquivalence}
          setValueForm={setValueForm}
          validatePercent={validatePercent}
        />
        <View style={styles.containerButton}>
          <ButtonDefault
            text={t('VIEWS.SUPPLIES.CREATE_AND_SELECTED_FERTILIZER')}
            width={234}
            height={38}
            fontSize={14}
            fontWeight='600'
            disabled={!formik.isValid || !hasChemicalCompositions(values)}
            typeButton={
              !formik.isValid || !hasChemicalCompositions(values)
                ? 'disabled'
                : 'primary'
            }
            onPress={() => handleSubmit(values)}
          />
        </View>
      </ScrollView>
    </ResponsiveLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: carbon50,
    fontFamily: 'Inter',
  },
  containerForm: {
    flex: 1,
    '@media (max-width: 1199px)': {
      marginLeft: 200,
      marginRight: 200,
    },
    '@media (max-width: 960px)': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  scrollView: {
    marginTop: 8,
    marginHorizontal: 16,
  },
  button: {
    backgroundColor: primary500,
    marginVertical: 5,
    fontWeight: '700',
  },
  textButton: {
    color: white,
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 20,
  },
  buttonDisabled: {
    backgroundColor: gray300,
    marginVertical: 5,
  },
  contentAlert: {
    backgroundColor: red100,
    paddingVertical: 12,
    paddingHorizontal: 8,
    marginHorizontal: 0,
    marginBottom: 32,
  },
  textAlert: {
    color: red700,
  },
  containerButton: {
    marginTop: 32,
    marginBottom: 64,
  },
})

export default CreateGenericSupplyV2
