import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  irrigationPowerSources: [],
  irrigationWaterSources: [],
}
export const irrigationData = createSlice({
  name: 'irrigationData',
  initialState,
  reducers: {
    /**
     * @param {object} state
     * @property {string} state.url
     * @property {func} state.onMessage
     *
     * @param {object} action
     * @property {object} action.payload
     * @property {string} action.payload.url
     * @property {func} action.payload.onMessage
     */
    setIrrigationPowerSources: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        irrigationPowerSources: action.payload,
      }
      return state
    },
    setIrrigationWaterSources: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        irrigationWaterSources: action.payload,
      }
      return state
    },
    resetIrrigationData: (state) => {
      state = initialState
      return state
    },
  },
})

export const {
  setIrrigationPowerSources,
  setIrrigationWaterSources,
  resetIrrigationData,
} = irrigationData.actions

export const { reducer: irrigationDataReducer } = irrigationData
