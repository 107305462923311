export default {
  WORK_ORDER: 'Work Order',
  SHARE: {
    TITLE: 'Ucropit',
    I_SEND_THE_FOLLOWING_WORK_ORDE: 'I send the following work order:',
    LABOR: 'Labor: %{labor}',
    CROP: 'Crop: %{crop}',
    TOTAL_PLANNED_AREA: 'Total planned area: %{total_planned_area}',
    ESTIMATED_START: 'Estimated start: %{estimated_start}',
    FOR_MORE_DETAILS: 'For more details, go to %{url}',
  },
}
