import { RoleEnum } from '@common/enum'
import { useAuth } from '@common/hooks'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { LanguageContext } from '@contextState/language'
import useActionSheetCustom from '@hooks/useActionSheetCustom'
import { white } from '@styles/palette'
import { PropTypes } from 'prop-types'
import React, { useMemo, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import RouterNames from 'src/constants/routerNames'
import { HeaderTypeLicenses, ItemLicense } from './components/index'
import LicensesEmpty from './components/LicensesEmpty'
import useLicenseList from './hooks/useLicenseList'

const LicenseList = ({ navigation, route: { params } }) => {
  const { t } = React.useContext(LanguageContext)
  const [itemSelected, setItemSelected] = useState(null)
  const [displayBackDrop, setDisplayBackDrop] = useState(false)
  const { config } = useAuth()
  const { roleSelected } = config

  const {
    licenses,
    cropTypes,
    nextPage,
    refreshPage,
    isLoading,
    changeCropType,
    clearFiltersCropTypes,
    numberColumns,
    licensesFiltered,
  } = useLicenseList()

  const isTraderAdmin = useMemo(
    () => (roleSelected?.value === RoleEnum.TRADER_ADMIN ? true : false),
    [roleSelected]
  )

  const { ActionSheetComponent, openActionSheet, closeActionSheet } =
    useActionSheetCustom({
      t,
      title: '',
    })
  const haveData =
    Boolean(!isTraderAdmin ? licenses?.length : licensesFiltered?.length) &&
    Boolean(cropTypes)
  const selectItem = (item) => {
    setItemSelected(item._id)
    changeCropType(item)
    if (displayBackDrop) {
      closeActionSheet()
      setDisplayBackDrop(false)
    }
  }

  const afterClosing = () => {
    setDisplayBackDrop(false)
  }

  const clearFilter = () => {
    setItemSelected(null)
    clearFiltersCropTypes()
  }

  const openAll = () => {
    setDisplayBackDrop(true)
    openActionSheet()
  }

  /**
   * GO TO LICENSE DETAIL SCREEN
   *
   * @param {object} license
   * @param {string} license._id
   *
   * @returns
   */
  const goToLicenseDetails = (license) => {
    if (!params.cropId) {
      navigation.navigate(RouterNames.LICENSES_DETAIL_READ_ONLY, {
        licenseId: license._id,
      })

      return
    }

    navigation.navigate(RouterNames.LICENSES_DETAIL, {
      licenseId: license._id,
      cropId: params.cropId,
    })
  }

  return (
    <View style={styles.container}>
      {!haveData && isLoading ? (
        <LoaderCenterContainer />
      ) : (
        <FlatList
          data={!isTraderAdmin ? licenses : licensesFiltered}
          numColumns={2}
          ItemSeparatorComponent={() => <View style={styles.stylesSeparator} />}
          columnWrapperStyle={styles.columnWrapperStyle}
          renderItem={({ item }) => (
            <ItemLicense
              license={item}
              t={t}
              onPress={() => goToLicenseDetails(item)}
            />
          )}
          ListHeaderComponent={
            cropTypes
              ? () => (
                  <HeaderTypeLicenses
                    cropTypes={cropTypes || []}
                    displayBackDrop={displayBackDrop}
                    ActionSheetComponent={ActionSheetComponent}
                    selectItem={selectItem}
                    afterClosing={afterClosing}
                    t={t}
                    itemSelected={itemSelected}
                    clearFilter={clearFilter}
                    openAll={openAll}
                    numberColumns={numberColumns}
                  />
                )
              : null
          }
          refreshing={isLoading}
          onEndReachedThreshold={0.1}
          onEndReached={nextPage}
          onRefresh={refreshPage}
          ListEmptyComponent={<LicensesEmpty />}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: white,
    flex: 1,
  },
  stylesSeparator: {
    height: 16,
  },
  columnWrapperStyle: {
    justifyContent: 'space-between',
  },
})

LicenseList.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default LicenseList
