import React, { useContext } from 'react'
import {
  StyleSheet,
  View,
  Text,
  Modal as ModalMobile,
  Platform,
  Pressable,
} from 'react-native'
import ModalWeb from 'modal-react-native-web'
import moment from 'moment'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import InputFileUpload from '@components/inputs/InputFileUpload'
import { blackHighEmphasis, primary500 } from '@styles/palette'
import { CommonIcon, ActivityIcon } from '@modules/common/components'

const Modal = Platform.OS === 'web' ? ModalWeb : ModalMobile

export const ModalValidateVerification = ({
  isModalVisible,
  closeModal,
  isAddVerificationFileButtonDisabled,
  onSelectVerificationFile,
  verificationType,
  verificationStatus,
  verificationFileName,
  verificationFileUploadedBy,
  verificationFileUploadedAt,
  onPressRemoveVerificationFile,
  onPressVerificationInvalidButton,
  onPressVerificationValidButton,
}) => {
  const { t } = useContext(LanguageContext)

  const allowedFormats = [
    '.jpg',
    '.jpeg',
    '.png',
    '.heic',
    '.pdf',
    '.doc',
    '.docx',
    '.txt',
    '.zip',
  ]

  const Header = ({ closeModal }) => {
    return (
      <View style={styles.header}>
        <CommonIcon
          name={'X'}
          color={blackHighEmphasis}
          style={styles.headerIcon}
          onPress={closeModal}
        />

        <Text style={styles.headerText}>
          {t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_1')}
        </Text>
      </View>
    )
  }

  const FileDescription = () => {
    return (
      <Text style={styles.fileDescription}>
        {t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_2')}
      </Text>
    )
  }

  const AddVerificationFile = ({
    isAddVerificationFileButtonDisabled,
    onSelectVerificationFile,
  }) => {
    return (
      <InputFileUpload
        accept={allowedFormats}
        onSelectFile={onSelectVerificationFile}
        disabled={isAddVerificationFileButtonDisabled}
        isDocumentOnly
      >
        <View style={styles.addVerificationFileButton}>
          <Text
            style={[
              styles.textAddVerificationFileButton,
              isAddVerificationFileButtonDisabled
                ? styles.textAddVerificationFileButtonDisabled
                : {},
            ]}
          >
            {t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_3')}
          </Text>
        </View>
      </InputFileUpload>
    )
  }

  const VerificationFile = ({
    verificationType,
    verificationStatus,
    verificationFileName,
    verificationFileUploadedBy,
    verificationFileUploadedAt,
    onPressRemove,
  }) => {
    if (!isAddVerificationFileButtonDisabled) {
      return false
    }

    return (
      <View style={styles.verificationFileContainer}>
        <View style={styles.verificationFileIconContainer}>
          <ActivityIcon name={'ACT_VERIFICATION'} size={35} />
        </View>

        <View style={styles.verificationFileDescriptionContainer}>
          <Text style={styles.verificationTypeText}>
            {t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_4', {
              verificationType: verificationType.label,
            })}
          </Text>

          <Text style={styles.verificationStatusText}>
            {t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_5', {
              verificationStatus,
            })}
          </Text>

          <Text style={styles.verificationFileNameText}>
            {verificationFileName}
          </Text>

          <Text style={styles.verificationFileUploadedByText}>
            {`${verificationFileUploadedBy?.firstName} ${verificationFileUploadedBy?.lastName}`}
          </Text>

          <Text style={styles.verificationFileUploadedAtText}>
            {moment(verificationFileUploadedAt).format('DD/MM/YY')}
          </Text>
        </View>

        <View style={styles.removeIconContainer}>
          <CommonIcon
            name={'X'}
            color={blackHighEmphasis}
            onPress={onPressRemove}
          />
        </View>
      </View>
    )
  }

  const ValidationDescription = () => {
    return (
      <Text style={styles.validationDescription}>
        {t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_6')}
      </Text>
    )
  }

  return (
    <Modal
      animationType='slide'
      transparent={true}
      visible={isModalVisible}
      onBackButtonPress={closeModal}
      useNativeDriver={true}
      ariaHideApp={false}
    >
      <View style={styles.modalContainer}>
        <Pressable style={styles.backScreen} onPress={closeModal}></Pressable>

        <View style={styles.container}>
          <Header closeModal={closeModal} />

          <FileDescription />

          <AddVerificationFile
            isAddVerificationFileButtonDisabled={
              isAddVerificationFileButtonDisabled
            }
            onSelectVerificationFile={onSelectVerificationFile}
          />

          <VerificationFile
            verificationType={verificationType}
            verificationStatus={verificationStatus}
            verificationFileName={verificationFileName}
            verificationFileUploadedBy={verificationFileUploadedBy}
            verificationFileUploadedAt={verificationFileUploadedAt}
            onPressRemove={onPressRemoveVerificationFile}
          />

          <ValidationDescription />

          <ButtonCustom
            disabled={isAddVerificationFileButtonDisabled}
            onPress={onPressVerificationInvalidButton}
            styles={[
              styles.verificationInvalidButton,
              isAddVerificationFileButtonDisabled
                ? styles.verificationInvalidButtonDisabled
                : {},
            ]}
            labelStyle={[
              styles.textVerificationInvalidButton,
              isAddVerificationFileButtonDisabled
                ? styles.textVerificationInvalidButtonDisabled
                : {},
            ]}
          >
            {t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_7')}
          </ButtonCustom>

          <ButtonCustom
            disabled={!isAddVerificationFileButtonDisabled}
            onPress={onPressVerificationValidButton}
            styles={[
              styles.verificationValidButton,
              !isAddVerificationFileButtonDisabled
                ? styles.verificationValidButtonDisabled
                : {},
            ]}
            labelStyle={[
              styles.textVerificationValidButton,
              !isAddVerificationFileButtonDisabled
                ? styles.textVerificationValidButtonDisabled
                : {},
            ]}
          >
            {t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE_VERIFICATION.TEXT_8')}
          </ButtonCustom>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .4)',
    zIndex: 1,
  },
  backScreen: {
    height: '100%',
    width: '100%',
  },
  container: {
    backgroundColor: 'white',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingHorizontal: 22,
    paddingVertical: 10,
    position: 'absolute',
    width: '100%',
    bottom: 0,
    zIndex: 3,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  headerIcon: {
    marginLeft: -10,
  },
  headerText: {
    fontSize: 16,
    fontWeight: '500',
    marginLeft: 37,
  },
  fileDescription: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 20,
  },
  addVerificationFileButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
  },
  textAddVerificationFileButton: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'green',
  },
  textAddVerificationFileButtonDisabled: {
    color: 'rgba(32, 32, 32, 0.12)',
  },
  validationDescription: {
    fontSize: 16,
    fontWeight: '500',
    marginTop: 20,
    marginBottom: 20,
  },
  verificationInvalidButton: {
    backgroundColor: 'white',
    borderColor: '#4CAF50',
    borderWidth: 1,
    marginTop: 5,
  },
  verificationInvalidButtonDisabled: {
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  textVerificationInvalidButton: {
    color: '#4CAF50',
  },
  textVerificationInvalidButtonDisabled: {
    color: 'rgba(0, 0, 0, 0.12)',
  },
  verificationValidButton: {
    backgroundColor: primary500,
    marginVertical: 5,
  },
  verificationValidButtonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textVerificationValidButton: {
    color: 'white',
  },
  textVerificationValidButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
  verificationFileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    backgroundColor: '#F8F8F8',
    borderRadius: 4,
    marginVertical: 10,
  },
  verificationFileIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
  },
  verificationFileDescriptionContainer: {
    flex: 1,
  },
  verificationTypeText: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  verificationStatus: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  verificationFileName: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  verificationFileUploadedByText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  verificationFileUploadedAtText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  removeIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginRight: 8,
  },
})

ModalValidateVerification.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isAddVerificationFileButtonDisabled: PropTypes.bool.isRequired,
  onSelectVerificationFile: PropTypes.func.isRequired,
  verificationType: PropTypes.object,
  verificationStatus: PropTypes.string,
  verificationFileName: PropTypes.string,
  verificationFileUploadedBy: PropTypes.object,
  verificationFileUploadedAt: PropTypes.object,
  onPressRemoveVerificationFile: PropTypes.func.isRequired,
  onPressVerificationInvalidButton: PropTypes.func.isRequired,
  onPressVerificationValidButton: PropTypes.func.isRequired,
}
