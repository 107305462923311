export default {
  TEXT_1: 'The file is not in KMZ format',
  TEXT_2: 'Area',
  TEXT_3: 'ha',
  TEXT_4: 'No field loaded yet.',
  TEXT_5: 'You can add fields by uploading',
  TEXT_6: '.KMZ files',
  TEXT_7: 'KMZ',
  TEXT_8: "Farm's name",
  TEXT_9: "Enter the farm's name",
  TEXT_10: 'Upload Fields (KMZ)',
  TEXT_11: 'Save',
}
