import React, { useContext, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { InputSelectDefault } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import {
  getActiveAgreementTypes,
  getAgreementTypeToDeepLink,
  getFormikInitialValues,
} from '../utils'
import { EAgreementTypes, ESubtypeAgreements } from '@common/enum'
import useAgreementsTypes from '../hooks/useAgreementsTypes'
import { StatementCftForm } from './statementCftForm'
import { Statement2bsvsForm } from './statement2bsvsForm'
import { StatementDefaultForm } from './statementDefaultForm'
import { LandScapeSustainabilityReportForm } from './landScapeSustainabilityReportForm'
import { useCftForm, useStatementForm } from '../hooks'
import { useRoute } from '@react-navigation/native'
import { StatementWaterCalculation } from './statementWaterCalculation'
import { StatementForm } from './statementForm'
import { useDispatch } from 'react-redux'
import { resetDataSelection } from '@store/slices'

export const AgreementForm = ({
  formik,
  readOnly = false,
  onPressLotsInput,
  crop: { areaUnit, cropType, _id: cropId },
  formikInitialValues,
  dontShowGenerateCft,
  setDontShowGenerateCft,
  goToEditFarm,
  alphaCodeCompany,
}) => {
  const dispatch = useDispatch()
  const route = useRoute()
  const { t } = useContext(LanguageContext)
  const { agreementTypesData } = useAgreementsTypes({
    cropType,
    alphaCodeCompany,
    cropId,
  })
  const { setFieldValue, values, setFieldTouched, touched, errors, setValues } =
    formik
  const { navigateToCftForm } = useCftForm({
    values,
    setFieldValue,
    cropTypeId: cropType?._id,
  })
  const { navigateToStatementForm } = useStatementForm()

  const onChangeTypeAgreementValue = (value) => {
    dispatch(resetDataSelection())

    const newFormikInitialValues = getFormikInitialValues(formikInitialValues)

    setValues({ ...newFormikInitialValues, typeAgreement: value })
  }

  useEffect(() => {
    getAgreementTypeToDeepLink(
      agreementTypesData,
      route?.params?.declarationType,
      onChangeTypeAgreementValue
    )
  }, [agreementTypesData])

  const renderStatementForm = () => {
    const currentAgreementTypeKey = values?.typeAgreement?.key

    if (values?.typeAgreement?.subType === ESubtypeAgreements.FORM) {
      return (
        <StatementForm
          onPressLotsInput={onPressLotsInput}
          formik={formik}
          areaUnit={areaUnit}
          navigateToForm={navigateToStatementForm}
          readOnly={readOnly}
        />
      )
    }

    switch (currentAgreementTypeKey) {
      case EAgreementTypes['2BSVS_CALCULATION']:
        return (
          <Statement2bsvsForm
            onPressLotsInput={onPressLotsInput}
            formik={formik}
            areaUnit={areaUnit}
            readOnly={readOnly}
          />
        )

      case EAgreementTypes.EVIDENCE_CFT:
        return (
          <StatementCftForm
            onPressLotsInput={onPressLotsInput}
            formik={formik}
            areaUnit={areaUnit}
            navigateToCftForm={navigateToCftForm}
            readOnly={readOnly}
            dontShowGenerateCft={dontShowGenerateCft}
            setDontShowGenerateCft={setDontShowGenerateCft}
          />
        )
      case EAgreementTypes['SUSTAINABILITY_2BSVS']:
      case EAgreementTypes['SUSTAINABILITY_EPA']:
      case EAgreementTypes['SUSTAINABILITY_MOA']:
      case EAgreementTypes['SUSTAINABILITY_CAN']:
        return (
          <LandScapeSustainabilityReportForm
            onPressLotsInput={onPressLotsInput}
            formik={formik}
            areaUnit={areaUnit}
            goToEditFarm={goToEditFarm}
          />
        )

      case EAgreementTypes.WATER_CALCULATION:
        return (
          <StatementWaterCalculation
            onPressLotsInput={onPressLotsInput}
            formik={formik}
            areaUnit={areaUnit}
            navigateToCftForm={navigateToCftForm}
            readOnly={readOnly}
          />
        )

      default:
        return (
          <StatementDefaultForm
            onPressLotsInput={onPressLotsInput}
            formik={formik}
            areaUnit={areaUnit}
          />
        )
    }
  }

  const activeAgreementTypes = getActiveAgreementTypes(agreementTypesData)

  return (
    <View>
      <InputSelectDefault
        id='typeAgreement'
        containerStyle={styles.containerInput}
        onChangeText={onChangeTypeAgreementValue}
        onBlur={() => setTimeout(() => setFieldTouched('typeAgreement'))}
        value={activeAgreementTypes.length ? values.typeAgreement : null}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_1.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_1.LABEL')}`}
        options={activeAgreementTypes}
        disabled={Boolean(readOnly)}
        touched={touched.typeAgreement}
        error={errors.typeAgreement?.value}
      />

      {renderStatementForm()}
    </View>
  )
}

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
})

AgreementForm.propTypes = {
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onPressLotsInput: PropTypes.func.isRequired,
}
