import activityTypes from '@constants/activityTypes'
import {
  harvestFormValues,
  monitoringFormValues,
  agreementFormValues,
  verificationFormValues,
  irrigationFormValues,
  achievementUnPlannedFormValues,
  floodFormInitialValues,
} from './index'

export const getFormikInitialValues = (params) => {
  if (!params) {
    return {}
  }

  const { tag, isDirectAchievement } = params

  switch (tag) {
    case activityTypes.ACT_HARVEST.key:
      return harvestFormValues(params)

    case activityTypes.ACT_MONITORING.key:
      return monitoringFormValues(params)

    case activityTypes.ACT_AGREEMENTS.key:
      return agreementFormValues(params)

    case activityTypes.ACT_VERIFICATION.key:
      return verificationFormValues(params)

    case activityTypes.ACT_IRRIGATION.key:
      return irrigationFormValues(params)

    case activityTypes.ACT_FLOOD.key:
      return floodFormInitialValues(params)
  }

  if (isDirectAchievement) {
    return achievementUnPlannedFormValues(params)
  }
}
