export const getAgreementTypeToDeepLink = async (
  agreementTypes: Array<any>,
  declarationType: string,
  callback?: (agreementType: any) => void
) => {
  if (!agreementTypes.length || !Boolean(declarationType)) return

  const agreementType = agreementTypes.find(
    (agreementType) =>
      String(agreementType.key).toLowerCase() ===
      String(declarationType).toLowerCase()
  )

  if (!agreementType) return

  if (!callback) {
    return agreementType
  }

  callback(agreementType)
}
