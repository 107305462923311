import { MODAL_ERRORS } from '@utils/constants'
import activityTypesConstants from '@constants/activityTypes'

export const searchErrorInAcVerification = (crop, drafts) => {
  const countDraftsExist = drafts.filter(
    (element) => element.tag === activityTypesConstants.ACT_VERIFICATION.key
  ).length
  if (
    crop?.verificationTypes?.length &&
    crop?.verificationTypes?.length <= countDraftsExist
  ) {
    return MODAL_ERRORS.EXIST_VERIFICATION_DRAFT
  } else if (
    !crop?.verificationTypes.find((element) => !element.isAllLotsVerified)
  ) {
    return MODAL_ERRORS.NO_LOTS_AVAILABLE
  } else {
    return false
  }
}
