import { useContext, useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'

import useNetwork from '@utils/network'
import { useAuth, useCompanyInfo } from '@modules/common/hooks'

import {
  INIT_DATA,
  ITEM_PER_PAGE_CROP_TYPES,
  ITEM_PER_PAGE_ACTIVITY_TYPES,
  MINIMUM_CHARACTERS,
  INIT_CURRENT_PAGE,
  TIMEOUT_ON_KEY_UP,
  getFormikValidationSchema,
  paginateArray,
} from '../utils'
import { useNavigation } from '@react-navigation/native'
import { mostUsedFiltersNotifications } from '@services/analytics'

export const useNotificationFilters = () => {
  const { goBack } = useNavigation()

  const { t } = useContext(LanguageContext)
  const { doRequest } = useNetwork()
  const { companyId } = useCompanyInfo()

  const { user } = useAuth()

  const [currentPageCropTypes, setCurrentPageCropTypes] =
    useState(INIT_CURRENT_PAGE)
  const [currentPageActivityTypes, setCurrentPageActivityTypes] =
    useState(INIT_CURRENT_PAGE)

  const { notificationFilters, setNotificationFilters } =
    useContext(CommonContext)

  const validateFunction = (values) => {
    const errors = {}

    const { farmListSelected, cropName, cropTypes, activityTypes } = values

    const cropTypeSelected = Boolean(
      cropTypes.find((cropType) => cropType.selected)
    )
    const activityTypeSelected = Boolean(
      activityTypes.find((activityType) => activityType.selected)
    )

    if (
      !farmListSelected.length &&
      !cropName.length &&
      !cropTypeSelected &&
      !activityTypeSelected
    ) {
      errors.filter = true
    }

    return errors
  }

  const formik = useFormik({
    initialValues: notificationFilters.values,
    validationSchema: getFormikValidationSchema(t),
    validate: validateFunction,
    enableReinitialize: true,
  })

  const { values, setFieldValue, isValid, validateForm, resetForm, dirty } =
    formik

  useEffect(() => {
    setNotificationFilters((notificationFilters) => ({
      ...notificationFilters,
      callbackResetNotificationFilter: () => {
        resetForm()
        validateForm()
      },
    }))
    validateForm()
    handleChangeForm()
  }, [dirty])

  const handleChangeForm = () => {
    setNotificationFilters((notifications) => ({
      ...notifications,
      isDirty: dirty,
    }))
  }

  const fetchFarms = async (value) => {
    try {
      const requestData = {
        method: 'GET',
        url: 'pendingTasks/farms',
        params: {
          companyId: companyId,
          name: value,
        },
        displayAlert: false,
      }
      const response = await doRequest(requestData)

      return response.data
    } catch (err) {
      console.error(err)
    }
  }

  const fetchCrops = async (value) => {
    try {
      const requestData = {
        method: 'GET',
        url: 'pendingTasks/crops',
        params: {
          companyId: companyId,
          name: value,
        },
        displayAlert: false,
      }
      const response = await doRequest(requestData)
      return response.data
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * find farms by name
   */
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const farmName = values.farmName.trim()
      if (farmName.length >= MINIMUM_CHARACTERS) {
        const { farmListSelected } = values
        const result = await fetchFarms(farmName)

        setFieldValue(
          'farmList',
          result.map((item) => ({
            ...item,
            selected: !!farmListSelected.find(
              (element) => item._id === element._id
            ),
          }))
        )
      } else {
        setFieldValue('farmList', INIT_DATA)
      }
    }, TIMEOUT_ON_KEY_UP)

    return () => clearTimeout(delayDebounceFn)
  }, [values.farmName])

  /**
   * update farmName value
   *
   * @param {string} value
   */
  const onChangeTextFarm = async (value) => {
    setFieldValue('farmName', value?.trimStart())
  }

  /**
   * Farm Selected
   *
   * @param {object} item
   */
  const onSelectedFarm = (item) => {
    updateFarmList(item)
    updateFarmListSelected(item)
  }

  const updateFarmList = (item) => {
    const { farmList } = values
    const index = farmList.findIndex((data) => data._id === item._id)

    if (index < 0) {
      return
    }

    const element = farmList[index]
    element.selected = !element?.selected
    const newData = [...farmList]
    newData[index] = element

    setFieldValue('farmList', newData)
  }

  const updateFarmListSelected = (item) => {
    const { farmListSelected } = values
    const index = farmListSelected.findIndex((data) => data._id === item._id)
    let newData = []

    if (index < 0) {
      newData = [...farmListSelected, item]
    } else {
      newData = [...farmListSelected]
      newData.splice(index, 1)
    }

    setFieldValue('farmListSelected', newData)
  }

  /**
   * find crops by name
   */
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const cropName = values.cropName.trim()

      if (cropName.length >= MINIMUM_CHARACTERS && !values.cropSelected) {
        const result = await fetchCrops(cropName)
        setFieldValue('cropList', result)
      } else {
        setFieldValue('cropList', INIT_DATA)
      }
    }, TIMEOUT_ON_KEY_UP)

    return () => clearTimeout(delayDebounceFn)
  }, [values.cropName])

  /**
   * update cropName value
   *
   * @param {string} value
   */
  const onChangeTextCrop = async (value) => {
    setFieldValue('cropName', value?.trimStart())
    setFieldValue('cropSelected', null)
  }

  /**
   * Crop Selected
   *
   * @param {object} item
   */
  const onSelectedCrop = (item) => {
    setFieldValue('cropName', item.name?.trimStart())
    setFieldValue('cropSelected', item)
    setFieldValue('cropList', INIT_DATA)
  }

  /**
   * Events button apply
   */
  const onSubmit = () => {
    setNotificationFilters((notificationFilters) => ({
      ...notificationFilters,
      values: { ...values },
      hasAnyFilterActive: true,
    }))

    mostUsedFiltersNotifications({
      activityTypes: values.activityTypes
        .filter((activityType) => activityType.selected === true)
        .map((activityType) => {
          if (activityType.selected) {
            return activityType.key
          }
        }),
      cropTypes: values.cropTypes
        .filter((cropType) => cropType.selected === true)
        .map((cropType) => {
          if (cropType.selected) {
            return cropType.key
          }
        }),
      byFarmFilter: values.farmListSelected.length > 0,
      byCropFilter: values.cropSelected !== null,
      date: new Date(),
      userId: user._id,
      email: user.email,
    })

    goBack()
  }

  const loadMoreCropTypes = () => setCurrentPageCropTypes((page) => page + 1)
  const showloadMoreCropTypes =
    values.cropTypes.length / ITEM_PER_PAGE_CROP_TYPES >= currentPageCropTypes

  const loadMoreActivityTypes = () =>
    setCurrentPageActivityTypes((page) => page + 1)
  const showloadMoreActivityTypes =
    values.activityTypes.length / ITEM_PER_PAGE_ACTIVITY_TYPES >=
    currentPageActivityTypes

  const showEmptyStateFarmList =
    Boolean(values.farmName?.length >= MINIMUM_CHARACTERS) &&
    Boolean(!values.farmList?.length)

  const showEmptyStateCropList =
    Boolean(values.cropName?.length >= MINIMUM_CHARACTERS) &&
    Boolean(!values.cropList?.length) &&
    !values.cropSelected

  return {
    ITEM_PER_PAGE_CROP_TYPES,
    ITEM_PER_PAGE_ACTIVITY_TYPES,
    MINIMUM_CHARACTERS,
    INIT_CURRENT_PAGE,
    TIMEOUT_ON_KEY_UP,
    t,
    formik,
    values,
    onChangeTextFarm,
    onSelectedFarm,
    showEmptyStateFarmList,
    showEmptyStateCropList,
    onChangeTextCrop,
    onSelectedCrop,
    paginateArray,
    currentPageCropTypes,
    showloadMoreCropTypes,
    loadMoreCropTypes,
    currentPageActivityTypes,
    showloadMoreActivityTypes,
    loadMoreActivityTypes,
    onSubmit,
    isValid,
  }
}
