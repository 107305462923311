export default {
  TEXT_1: 'Assign fields to the crop',
  TEXT_2: 'ha',
  TEXT_3: 'All farms',
  TEXT_4: 'Fields not available for this crop:',
  TEXT_5:
    'The planting date of this crop is prior to the harvest date of another crop.',
  TEXT_6: 'Upload selected fields',
  FIELD_1: {
    NAME: 'Search by farm…',
  },
  TEXT_7: 'Executed Area',
  TEXT_8: 'Confirm Fields',
  TEXT_9: 'NEXT',
  TEXT_10: 'ac',
  TEXT_11: 'To assign a field to the crop, you should have a farm created',
  ADD_FIELDS_TO_CROP: 'Assign fields to crop',
  ALL_FARMS: 'All farms',
  CANCEL: 'CANCEL',
}
