import { collaboratorTypes as type } from '../types'

const initialState = {
  collaborators: [],
  createCollaboratorConfig: {
    defaultCountry: null,
    defaultIdentifier: null,
    defaultEmail: null,
    defaultUserType: null,
    userTypesAllowed: [],
    isCountryDisabled: false,
    isIdentifierDisabled: false,
    isEmailDisabled: false,
    isUserTypeDisabled: false,
    callback: null,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_COLLABORATORS: {
      return {
        ...state,
        collaborators: action.payload,
      }
    }

    case type.CLEAR_COLLABORATORS: {
      return {
        ...state,
        collaborators: initialState.collaborators,
      }
    }

    case type.SET_CREATE_COLLABORATOR_CONFIG: {
      return {
        ...state,
        createCollaboratorConfig: {
          ...state.createCollaboratorConfig,
          ...action.payload,
        },
      }
    }

    case type.CLEAR_CREATE_COLLABORATOR_CONFIG: {
      return {
        ...state,
        createCollaboratorConfig: initialState.createCollaboratorConfig,
      }
    }

    default:
      return state
  }
}

export default reducer
