const userTypes = {
  PRODUCER_ADVISER: {
    key: 'PRODUCER_ADVISER',
    name: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER,
  },
  PRODUCER_ADVISER_ENCOURAGED: {
    key: 'PRODUCER_ADVISER_ENCOURAGED',
    name: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER_ENCOURAGED,
  },
  PRODUCER: {
    key: 'PRODUCER',
    name: (t) => t('CONSTANTS').USER_TYPES.PRODUCER,
  },
  PROVIDER: {
    key: 'PROVIDER',
    name: (t) => t('CONSTANTS').USER_TYPES.PROVIDER,
  },
  MARKETER: {
    key: 'MARKETER',
    name: (t) => t('CONSTANTS').USER_TYPES.MARKETER,
  },
  CONTACT_REPRESENTATIVE: {
    key: 'CONTACT_REPRESENTATIVE',
    name: (t) => t('CONSTANTS').USER_TYPES.CONTACT_REPRESENTATIVE,
  },
  CAM: {
    key: 'CAM',
    name: (t) => t('CONSTANTS').USER_TYPES.CAM,
  },
  KAM: {
    key: 'KAM',
    name: (t) => t('CONSTANTS').USER_TYPES.KAM,
  },
  VERIFIER: {
    key: 'VERIFIER',
    name: (t) => t('CONSTANTS').USER_TYPES.VERIFIER,
  },
}

export default userTypes
