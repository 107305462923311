export const cropColumns = [
  {
    name: 'id',
    type: 'integer',
  },
  {
    name: 'data',
    type: 'text',
  },
  {
    name: '_id',
    type: 'text',
  },
  {
    name: 'downloaded',
    type: 'integer',
  },
  {
    name: 'created_at',
    type: 'timestamp',
  },
  {
    name: 'updated_at',
    type: 'timestamp',
  },
  {
    name: 'crop_identifier',
    type: 'timestamp',
  },
  {
    name: 'member_identifier',
    type: 'timestamp',
  },
]
