import * as yup from 'yup'
import activityTypesConstants from '@constants/activityTypes'

export const achievementUnPlannedValidationSchema = (t, tag) =>
  yup.object().shape({
    subTypeActivity: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    lots: yup
      .object()
      .shape({
        value: yup
          .array()
          .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
          .of(
            yup.object().shape({
              _id: yup.string().required(),
              surface: yup.number().required(),
              tag: yup.string(),
              wktFormat: yup.string(),
              area: yup.array(),
            })
          )
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    surface: yup.number().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
    dateAchievement: yup
      .object()
      .shape({
        value: yup
          .date()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    evidences: yup
      .array()
      .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    supplies:
      tag !== activityTypesConstants.ACT_TILLAGE.key
        ? yup
            .array()
            .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
            .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
        : yup.array(),
    signers: yup
      .array()
      .min(2, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 2 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
  })
