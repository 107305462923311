import React, { useContext } from 'react'
import { Text, View, StyleSheet } from 'react-native'

import { carbon600, carbon800 } from '@styles/palette'

import { MapperChemicalCompositionsReturn } from '../../utils'

import { ChemicalCompositionItem } from '../ChemicalCompositionItem'
import { LanguageContext } from '@contextState/language'
import { MIN_COMPOSITION_VALUE } from '@modules/supplies/utils'

interface ChemicalCompositionsProps {
  chemicalCompositions: MapperChemicalCompositionsReturn[] | null
  show: boolean | undefined
  containerStyle: object
}

export const ChemicalCompositions: React.FC<ChemicalCompositionsProps> = ({
  chemicalCompositions,
  show = true,
  containerStyle = {},
}) => {
  const { t } = useContext(LanguageContext)

  if (!chemicalCompositions || !show) {
    return null
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <Text style={styles.title}>{t('VIEWS.SUPPLY_SELECTOR.COMPOSITION')}</Text>

      {chemicalCompositions.map((composition) => {
        if (composition.value >= MIN_COMPOSITION_VALUE) {
          return (
            <ChemicalCompositionItem
              key={composition.name}
              composition={composition.name}
              value={composition.value}
            />
          )
        }
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  title: {
    fontSize: 18,
    lineHeight: 24,
    fontFamily: 'Inter',
    fontWeight: '600',
    color: carbon800,
  },
})
