import React, { useContext } from 'react'
import { View, ScrollView, Text, StyleSheet } from 'react-native'
import { white } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { FormSignUp } from './components'
import ButtonDefault from '@modules/common/components/buttons/ButtonDefault'
import useSignUp from './hooks/useSignUp'
import { useFetchTerms } from '@modules/common/hooks'
import { ModalTerms } from '@modules/common/components/modals/ModalTerms'
import { Layout } from '@common/components'

const SignUp = ({ route }) => {
  const { t, i18n } = useContext(LanguageContext)
  const {
    formik,
    isModalVisible,
    acceptTerms,
    onEndEditingEmail,
    errorEmail,
    closeModalReject,
    closeModalTerms,
    disabledEmail,
  } = useSignUp({
    route,
    t,
    locale: i18n.locale,
  })
  const { terms } = useFetchTerms({ locale: i18n.locale })
  const { dirty, isValid, isSubmitting, handleSubmit } = formik

  return (
    <Layout style={styles.content}>
      <View>
        <Text style={styles.title}>{t('VIEWS.SIGN_UP.WELCOME')}</Text>
        <Text style={styles.info}>{t('VIEWS.SIGN_UP.INFO_WELCOME')}</Text>
        <FormSignUp
          formik={formik}
          t={t}
          disabledEmail={disabledEmail}
          onEndEditingEmail={onEndEditingEmail}
          errorEmail={errorEmail}
        />
      </View>
      <View>
        <ButtonDefault
          typeButton={
            dirty && isValid && !isSubmitting && !errorEmail
              ? 'primary'
              : 'disabled'
          }
          text={t('VIEWS.SIGN_UP.CONTINUE')}
          testID='next_button'
          onPress={handleSubmit}
          disabled={!(dirty && isValid) || isSubmitting || errorEmail}
        />
      </View>
      {isModalVisible && (
        <ModalTerms
          isModalVisible={isModalVisible}
          acceptTerms={acceptTerms}
          t={t}
          terms={terms}
          closeModalReject={closeModalReject}
          closeModalTerms={closeModalTerms}
        />
      )}
    </Layout>
  )
}

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    //justifyContent: 'space-between',
    backgroundColor: white,
    paddingHorizontal: 16,
    flexDirection: 'column',
    paddingBottom: 32,
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    marginTop: 31,
    marginBottom: 16,
  },
  info: {
    marginBottom: 23,
  },
})

export default SignUp
