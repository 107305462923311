export default {
  TEXT_1: 'ASOCIAR',
  TEXT_2: 'Lotes',
  TEXT_3: 'ha',
  TEXT_4:
    'La empresa tiene cupo de superficie. Solo se aplicarán %{hectareLimit} %{areaUnit}. de las %{surfaceTotal} %{areaUnit}',
  TEXT_5:
    'Esta empresa ya tiene %{lotsApplied} lotes - %{surfaceApplied} %{areaUnit} aplicadas.',
  TEXT_6: 'Ya se aplicaron estos lotes a esta empresa.',
  TEXT_7: 'superficie neta',
  TEXT_8: 'Seleccionar Todos',
  TEXT_9:
    'La empresa no tiene más cupo de superficie. Se aplicarán los lotes seleccionados con anterioridad.',
  MODAL_CONFIRM_COMPANIES: {
    TEXT_1: 'Confirmación de empresa',
    TEXT_2:
      'Una vez seleccionada la empresa asociada al ecosistema, no podrá deseleccionarla.',
    TEXT_3: 'CANCELAR',
    TEXT_4: 'ASOCIAR',
  },
}
