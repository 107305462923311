export default {
  TEXT_1: 'Subir lotes de KMZ',
  TEXT_2: 'SUBIR OTRO MAPA',
  TEXT_3: 'SUBIR LOTES SELECCIONADOS',
  TEXT_4: 'Todos los lotes',
  TEXT_5: 'El archivo no tiene formato KMZ',
  FIELD_1: {
    NAME: 'Nombre del Establecimiento',
  },
}
