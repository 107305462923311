import React, { useContext, useState } from 'react'
import { PropTypes } from 'prop-types'
import integrations from '@constants/integrations'
import { CommonContext } from '@contextState/common'
import useNetwork from '@utils/network'
import ButtonCustom from '@components/common/ButtonCustom'
import { View, Text, StyleSheet, Image } from 'react-native'
import { Card } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'
import { useAuth } from '@common/hooks'

function CompanyUnlinkServiceIntegrationScreen({ route, navigation }) {
  const { servicesIntegration } = useContext(CommonContext)
  const { selectedProfile } = useAuth()
  const { doRequest, loading } = useNetwork()
  const [service, setService] = useState('')
  const [integration, setIntegration] = useState(null)
  const [id, setId] = useState('')
  const { t } = useContext(LanguageContext)

  React.useEffect(() => {
    const integration = servicesIntegration.find(
      (integration) => integration.value === route.params.service
    )

    setIntegration(integration)
    setId(route.params.id)
    setService(route.params.service)
  }, [])

  async function unlinkService(service, id) {
    await doRequest({
      method: 'PUT',
      url: `exporters/company/${id}/services/${service}/${selectedProfile.company?.identifier}`,
    })

    navigation.navigate('CompanyIntegrationAppList', {
      id: id,
    })
  }

  function imageSelection(service) {
    let image
    switch (service) {
      case 'auravant':
        image = integrations.auravant
        break

      default:
        image = integrations.auravant
    }
    return image
  }

  return (
    <View style={styles.container}>
      <Card style={styles.containerCard}>
        <View style={styles.imageContainer}>
          <Image style={styles.stylesImage} source={imageSelection(service)} />
        </View>
        <Text style={styles.title}>{service}</Text>
        <Text style={styles.textItem}>{integration?.description}</Text>
        <ButtonCustom
          disabled={loading}
          isLoading={loading}
          onPress={() => unlinkService(service, id)}
        >
          {t('VIEWS').COMPANY_UNLINK_SERVICE_INTEGRATION.TEXT_1}
        </ButtonCustom>
      </Card>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  containerCard: {
    marginTop: 50,
    borderRadius: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 20,
    alignItems: 'center',
  },
  imageContainer: {
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
  stylesImage: {
    width: 100,
    height: 100,
  },
  button: {
    alignItems: 'center',
    fontSize: 22,
    marginRight: 4,
  },
  buttonGreen: {
    alignItems: 'center',
    fontSize: 15,
    padding: 10,
    color: '#4CAF50',
  },
  title: {
    padding: 12,
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: '600',
    fontSize: 28,
  },
  textItem: {
    textAlign: 'center',
    padding: 20,
    fontSize: 18,
  },
})

CompanyUnlinkServiceIntegrationScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CompanyUnlinkServiceIntegrationScreen
