import React, { useCallback, useContext, useEffect } from 'react'
import { BackHandler, StyleSheet, View, Platform } from 'react-native'
import { useFocusEffect } from '@react-navigation/core'
import { useDispatch } from 'react-redux'

import { CommonIcon } from '@modules/common/components'
import useNetwork from '@utils/network'
import { ChildrenModalSuccess } from '@common/components/modals/ChildrenModalSuccess'
import { LanguageContext } from '@contextState/language'
import { MAGIC_TIME_REDIRECT } from '@constants/magic'
import {
  greenOpacity50,
  primary500,
  primary500Disabled,
  white,
} from '@styles/palette'
import { farmActions } from '@store/actions'
import { useAuth } from '@common/hooks'

const SuccessAddCompany = ({ route, navigation }) => {
  const dispatch = useDispatch()
  const { companyId } = route.params
  const { doRequest } = useNetwork()
  const { config, fetchUser } = useAuth()
  const { t } = useContext(LanguageContext)
  let finish = false

  useEffect(() => {
    navigation.addListener('focus', () => {
      dispatch(farmActions.setFullScreen(true))
    })
    navigation.addListener('beforeRemove', () => {
      dispatch(farmActions.setFullScreen(false))
    })
  }, [navigation])

  useFocusEffect(
    useCallback(() => {
      setTimeout(() => {
        if (!finish) {
          onChangeCompany()
        }
      }, MAGIC_TIME_REDIRECT)
    }, [])
  )

  useEffect(() => {
    const backAction = () => {
      return true
    }

    let backHandler

    if (Platform.OS !== 'web') {
      backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction
      )
    }

    return () => backHandler?.remove()
  }, [])

  const onChangeCompany = async () => {
    finish = true
    const data = {
      companySelected: companyId,
      companyAdmin: null,
    }

    await doRequest({
      method: 'PUT',
      url: `configurations/${config._id}`,
      data,
    })
    await fetchUser()
    navigation.popToTop()
  }

  return (
    <View style={styles.container}>
      <View style={[styles.buttonContainer]}>
        <View style={styles.button}>
          <CommonIcon name={'CHECK'} size={45} color={white} />
        </View>
      </View>

      <ChildrenModalSuccess
        onPress={onChangeCompany}
        textSuccess={t('VIEWS.ADD_COMPANY_SCREEN.TEXT_SUCCESS')}
        textButton={t('VIEWS.ADD_COMPANY_SCREEN.TEXT_BUTTON')}
        testID='success_button'
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: primary500Disabled,
    justifyContent: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: 171,
    height: 171,
    borderRadius: 100,
    backgroundColor: greenOpacity50,
    marginTop: 128,
    marginBottom: 0,
  },
  button: {
    width: 131,
    height: 131,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 100,
    backgroundColor: primary500,
    marginVertical: 30,
  },
})

export default SuccessAddCompany
