import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { useFocusEffect } from '@react-navigation/native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import userTypes from '@constants/userTypes'
import { collaboratorsActions } from '@store/actions'
import {
  getFormikInitialValues,
  getFormikValidationSchema,
  parseDataToFormik,
  parseDataToSubmit,
} from './utils'
import Screen from './screen'

const CollaboratorCreate = ({ route }) => {
  const { t } = useContext(LanguageContext)
  const { countries } = useContext(CommonContext)

  const createCollaboratorConfig = useSelector(
    (state) => state.collaborators.createCollaboratorConfig
  )

  const [isViewReady, setIsViewReady] = useState(false)
  const [countryOptions, setCountryOptions] = useState([])
  const [userTypeOptions, setUserTypeOptions] = useState([])
  const [isSubmitButtonAvailable, setIsSubmitButtonAvailable] = useState(false)
  const [formikInitialValues, setFormikInitialValues] = useState(
    getFormikInitialValues({
      ...route.params,
    })
  )

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: getFormikValidationSchema(t, countryOptions),
    enableReinitialize: false,
  })

  const {
    values,
    resetForm,
    isValid,
    validateForm,
    setSubmitting,
    isSubmitting,
  } = formik

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    fetchInitData()

    return () => {
      collaboratorsActions.clearCreateCollaboratorConfig()
    }
  }, [])

  /**
   * ENABLE/DISABLE VALIDATE BUTTON IF FORM IS VALID
   */
  useEffect(() => {
    if (isValid) {
      setIsSubmitButtonAvailable(true)
    } else {
      setIsSubmitButtonAvailable(false)
    }
  }, [isValid])

  /**
   * VALIDATE FORM WHEN USER ENTER ON VIEW
   */
  useFocusEffect(
    useCallback(() => {
      validateForm()

      return () => true
    }, [])
  )

  /**
   * FETCH INIT DATA
   */
  const fetchInitData = useCallback(async () => {
    setIsViewReady(false)

    setCountryOptions(
      countries.map((element) => ({
        value: element._id,
        label: element.name,
      }))
    )

    const userTypeKeys = []

    for (const key in userTypes) {
      userTypeKeys.push({
        value: userTypes[key].key,
        label: userTypes[key].name(t),
      })
    }

    setUserTypeOptions(
      userTypeKeys.filter((element) =>
        createCollaboratorConfig.userTypesAllowed.length
          ? createCollaboratorConfig.userTypesAllowed.includes(element.value)
          : true
      )
    )

    const newFormikInitialValues = parseDataToFormik({
      formikInitialValues,
      createCollaboratorConfig,
    })

    setFormikInitialValues(newFormikInitialValues)

    resetForm({
      values: newFormikInitialValues,
    })

    setTimeout(validateForm, 50)

    setIsViewReady(true)
  }, [])

  /**
   * ON PRESS ADD COLLABORATOR
   */
  const onPressSubmitButton = async () => {
    setSubmitting(true)
    const dataToReturn = parseDataToSubmit(values)

    createCollaboratorConfig.callback(dataToReturn, setSubmitting)
  }

  return (
    <Screen
      isViewReady={isViewReady}
      formik={formik}
      createCollaboratorConfig={createCollaboratorConfig}
      countryOptions={countryOptions}
      userTypeOptions={userTypeOptions}
      isSubmitButtonAvailable={isSubmitButtonAvailable}
      onPressSubmitButton={onPressSubmitButton}
      isSubmitButtonLoading={isSubmitting}
    />
  )
}

CollaboratorCreate.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default CollaboratorCreate
