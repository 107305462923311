import EMPTY_STATE_INPUT from '../../components/EmptyStateInput/translate/pt'
export default {
  NAVIGATION: {
    TITLE: 'Filtro',
  },
  CLEAR: 'Limpar',
  FIELD_NAME: 'Nome do campo',
  CROP_NAME: 'Nome da cultivo',
  TYPE_OF_CROP: 'Tipo de cultivo',
  ACTIVITY: 'Actividade',
  SEE_MORE: 'Ver mais',
  APPLY: 'Aplicar',
  COMPONENTS: {
    EMPTY_STATE_INPUT,
  },
}
