export default {
  TEXT_1: 'APLICAR LICENÇA',
  TEXT_2:
    'Por favor, selecione os lotes que serão aplicados à licença do ecossistema.',
  TEXT_3:
    '%{hasSelected} hectáres selecionadas em uma faixa %{hasMinAvailable} a %{hasAvailable} hectáres',
  TEXT_4: 'Has.',
  TEXT_5: 'Todos os estabelecimentos',
  TEXT_6: 'Lotes já aplicados para esta licença:',
  TEXT_7: 'Esses lotes já foram solicitados para esta licença.',
  TEXT_8: 'lote(s)',
  TEXT_9: 'Aplicar a %{lotsQuantity}lote(s)',
  TEXT_10: 'Parabéns!',
  TEXT_11: 'Sua licença foi aplicada aos',
  TEXT_12: 'lotes selecionados',
  TEXT_13: 'ASSOCIAR OUTRA LICENÇA',
  TEXT_14: 'Voltar a cultivos',
  TEXT_15: 'Desculpe',
  TEXT_16:
    'O número total de hectares disponíveis para esta licença se esgotou, e o lote já foi solicitado por outro produtor.',
  TEXT_17: '%{overlappingPercentage}% SOBREPOSIÇãO',
  TEXT_18:
    'O lote não disponível para esta licença excede a sobreposição permitida',
  FIELD_1: {
    NAME: 'Pesquisa por estabelecimento...',
  },
  TEXT_19: 'SELECIONE A EMPRESA',
  TEXT_20:
    'Lo sentimos: se actualizó la superficie disponible.\n %{hasSelected} %{areaUnit} seleccionadas sobre %{hasAvailable} disponibles',
  MODAL_UNASSOCIATE: {
    TITLE: 'Lote desassociado',
    INFO: 'Este lote não será mais associado à licença selecionada e a área usada será liberada.',
    CONFIRM: 'Unassociate',
    CANCEL: 'Cancelar',
  },
  CAN_APPLY_LICENSE:
    '%{hasSelected} %{areaUnit} selecionada dentro do intervalo da licença do ecossistema de %{hasMinAvailable} a %{hasAvailable} %{areaUnit}.',
  INVALID_APPLY_MINIMUM_ALLOWED:
    '%{hasSelected} %{areaUnit} selecionada.\n A área mínima associada à licença do ecossistema é de %{hasMinAvailable} %{areaUnit}.',
  INVALID_APPLY_MAXIMUM_ALLOWED:
    '%{hasSelected} %{areaUnit} selecionadas.\n O requisito máximo de área associado à licença do ecossistema é de %{hasAvailable} %{areaUnit}.',
}
