import React from 'react'
import { VirtualizedList } from 'react-native'
import { PropTypes } from 'prop-types'
import ObservationItem from './ObservationItem'
import { getItem } from '@utils/common'

const ObservationList = ({
  observations,
  deleteObservation,
  copyObservation,
  showMenu,
}) => {
  return (
    <VirtualizedList
      data={observations}
      renderItem={({ item, index }) => (
        <ObservationItem
          item={item}
          index={index}
          deleteObservation={deleteObservation}
          copyObservation={copyObservation}
          showMenu={showMenu}
        />
      )}
      getItem={getItem}
      getItemCount={() => observations.length}
      keyExtractor={(_, index) => `observation-${index}`}
    />
  )
}

ObservationList.propTypes = {
  observations: PropTypes.array.isRequired,
  deleteObservation: PropTypes.func.isRequired,
  copyObservation: PropTypes.func.isRequired,
}

export default ObservationList
