import { useCallback, useContext, useState } from 'react'
import {
  StyleSheet,
  View,
  KeyboardAvoidingView,
  Image,
  ScrollView,
  Platform,
} from 'react-native'
import { Text } from 'react-native-paper'
import { Field, Formik } from 'formik'
import { round } from 'lodash'
import { useSelector } from 'react-redux'
import { calculateDosage } from '@ucropit/eiq-calculator'
import { LanguageContext } from '@contextState/language'
import { SUPPLY } from '@constants/iconGuide'
import { SelectorContext } from '@contextState/selectors'
import ButtonCustom from '@components/common/ButtonCustom'
import { primary500, carbon800, carbon300, carbon500 } from '@styles/palette'
import { CommonContext } from '@contextState/common'
import { CropContext } from '@contextState/crop'
import Outlier from '@modules/common/components/Outlier'
import {
  outlierType,
  outlierIcons,
  statusOutlier,
} from '@constants/outlierData'
import useNetwork from '@utils/network'
import {
  outlierOptionsRequest,
  urlsOutlierParser,
  validateCompanyCountryHasOutlierOff,
} from '@common/utils'

import { useFocusEffect } from '@react-navigation/native'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { useAuth, useStandardDeviation } from '@common/hooks'
import { unitMeasureSystem as UnitMeasureSystem } from '@utils/constants'
import { truncateSurface } from '@common/utils/truncateSurface'
import { ChemicalCompositions } from './components/ChemicalCompositions'
import { mapData } from './utils'
import { keysSupplies } from '@constants/index'

import { activityIcons } from '@modules/cropStory/screens/CropStoryLotDetail/v1/utils'
import { SIZES } from '@common/components/icons/interfaces'
import { IconBox } from '@modules/supplies/components'
import { white, yellow300, yellow900 } from '@common/styles'
import { ResponsiveLayout } from '@common/components'
import InputText from '@components/inputs/InputText'
import { WITH_TABLE } from '@common/constants/width'

const isWeb = Platform.OS === 'web'

function SupplyLoaderScreen({ route, navigation }) {
  const { doRequest } = useNetwork()
  const {
    item,
    surface,
    suppliesSelected,
    isActivityImport = false,
  } = route.params

  const supplySelected = suppliesSelected?.find(
    (supply) => item._id === supply._id
  )
  const { supplySelector, setSupplySelector } = useContext(SelectorContext)
  const { unitTypesSupplies } = useContext(CommonContext)
  const { t, i18n } = useContext(LanguageContext)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { currentCrop } = useContext(CropContext)
  const { config } = useAuth()
  const [outlier, setOutlier] = useState()

  const {
    companySelected: {
      country: { alpha3Code },
    },
  } = config

  const { ComponentStandarDeviation, openBackdropStandardDeviation } =
    useStandardDeviation({ t })

  const [initialValues, setInitialValues] = useState({
    _id: item._id,
    isGeneric: item.isGeneric,
    chemicalCompositions: item.chemicalCompositions,
    chemicalCompositionsRequest: item?.chemicalCompositionsRequest,
    supplyType: item.typeId || item.typeSupply || item.supplyType,
    unitTypeSupply: item.unitTypeSupply,
    name: item.name,
    unit: item.unit,
    quantity: item?.total / surface || 0,
    total: item?.total
      ? item.total.toString()
      : supplySelected?.total
      ? supplySelected?.total.toString()
      : '',
    eiqTotal: '',
    surface: surface ? surface.toString() : '0',
    areaUnit: currentCrop?.areaUnit
      ? currentCrop?.areaUnit
      : (config?.companySelected?.unitMeasureSystem ===
        UnitMeasureSystem.IMPERIAL
          ? 'ac'
          : 'ha') || '',
  })

  const {
    showDeleteModal,
    setShowDeleteModal,
    setShowDeleteSupplyIcon,
    setShowDeleteIcon,
  } = useContext(CommonContext)

  useFocusEffect(
    useCallback(() => {
      // eslint-disable-next-line no-prototype-builtins
      if (route.params.hasOwnProperty('readOnly')) {
        setShowDeleteSupplyIcon(!route.params.readOnly)
      } else {
        setShowDeleteSupplyIcon(Boolean(item?.total))
      }

      setShowDeleteIcon(false)

      if (item) {
        fetchOutlierRequest(item)
      }

      return () => {
        setShowDeleteSupplyIcon(false)

        return true
      }
    }, [item])
  )

  const unitName = unitTypesSupplies.find(
    (unit) => unit?.key?.toLowerCase() === item?.unit?.toLowerCase()
  )

  function validateAmount(value) {
    const number = value.replace(',', '.')
    let error
    if (!value) {
      error = t('VIEWS').SUPPLY_SELECTOR.TEXT_2
    } else if (!Number(number)) {
      error = t('VIEWS').SUPPLY_SELECTOR.TEXT_3
    } else if (Number(number) < 0) {
      error = t('VIEWS').SUPPLY_SELECTOR.TEXT_4
    }
    return error
  }

  async function onChangeTotal(value) {
    if (!value) {
      setInitialValues({
        ...initialValues,
        total: value,
        quantity: '0',
      })

      setOutlier()

      return t('VIEWS').SUPPLY_SELECTOR.TEXT_2
    }

    if (!validateAmount(value)) {
      const total = value.replace(',', '.')

      const newSupply = {
        ...initialValues,
        total,
        quantity: calculateDosage(
          Number(total),
          Number(initialValues.surface),
          6
        ).toString(),
      }

      setInitialValues(newSupply)
      await fetchOutlierRequest(newSupply)
    }

    return validateAmount(value)
  }

  const getOutlierRequest = async (url) => {
    if (!url) {
      return
    }

    let data

    try {
      const request = await doRequest({
        method: 'GET',
        url: url,
        displayAlert: false,
      })

      data = request.data
    } catch (error) {
      console.error(error)

      return
    }

    return data
  }

  const fetchOutlierRequest = async (newSupply) => {
    const { typeSupply, typeId } = item
    const tags = typeSupply?.tags || typeId?.tags
    if (!tags) {
      return
    }
    const optionOutlier = outlierOptionsRequest(tags)
    const checkCountryOutlier = validateCompanyCountryHasOutlierOff(alpha3Code)

    if (!optionOutlier || checkCountryOutlier) {
      return
    }

    const { url } = urlsOutlierParser(newSupply, optionOutlier, surface)

    const response = await getOutlierRequest(url)

    if (!response) {
      return
    }

    if (tags.includes('SEEDS')) {
      setOutlier(statusOutlier[response.status])
    } else if (tags.includes('FERTILIZERS')) {
      setOutlier(statusOutlier[response.status])
    } else if (tags.includes('PHYTOTHERAPICS')) {
      let outlier

      response.forEach((element) => {
        if (!outlier && statusOutlier[element.status]) {
          outlier = statusOutlier[element.status]

          return
        }

        if (statusOutlier[element.status] === outlierType.DANGER) {
          outlier = statusOutlier[element.status]

          return
        }

        if (
          statusOutlier[element.status] === outlierType.WARNING &&
          outlier !== outlierType.DANGER
        ) {
          outlier = statusOutlier[element.status]

          return
        }
      })

      setOutlier(outlier)
    }
  }

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      if (navigation.canGoBack()) {
        navigation.goBack()
      }
    }
  }

  function handleSelect(values) {
    values.quantity = Number(values.quantity.replace(',', '.'))
    values.total = Number(values.total.replace(',', '.'))

    const supply = {
      ...values,
      code: item.code,
      typeId: item.typeId,
      icon: item.typeId.icon,
      supplyId: item._id,
      eiqTotal: item.eiqTotal,
      company: item.company,
      classToxic: item.classToxic,
      composition: item.composition ?? item.compositon,
      activeIngredients: item.activeIngredients?.length
        ? item.activeIngredients
        : [],
      brand: item?.brand,
      unitTypeSupply: item.unitTypeSupply,
      unitName: unitName ? unitName?.keyLabel : item?.unitTypeSupply?.key || '',
    }

    if (isActivityImport) {
      window.parent.postMessage(supply, '*')
      return
    }

    if (route.params.callBack) {
      route.params.callBack(supply)
      return
    }

    let result = []

    if (supplySelector.find(({ _id }) => _id === supply._id)) {
      result = supplySelector.map((element) => {
        return element._id !== supply._id ? element : { ...element, ...supply }
      })
    } else {
      result = [...supplySelector, supply]
    }

    setSupplySelector(result)

    if (route.params.valuesParams && route.params.routeRedirect) {
      const valuesForm = route.params.valuesParams.valuesForm
      valuesForm.supplies = result
      navigation.navigate(route.params.routeRedirect, {
        ...route.params.valuesParams,
        valuesForm,
      })
    } else {
      goBack()
    }
  }

  const closeModalDelete = () => {
    setShowDeleteModal(false)
  }

  const handleDeleteSupply = (key) => {
    const result = supplySelector.filter((el, index) => key !== index)
    setSupplySelector(result)
    closeModalDelete()
    if (route.params.callBack) {
      route.params.callbackDelete(key)
      return
    }
    if (route.params.valuesParams && route.params.routeRedirect) {
      const valuesForm = route.params.valuesParams.valuesForm
      valuesForm.supplies = result
      navigation.navigate(route.params.routeRedirect, {
        ...route.params.valuesParams,
        valuesForm,
      })
    } else {
      navigation.goBack()
    }
  }

  if (item === undefined) {
    return null
  }

  const codeSupply =
    item?.typeId?.code ?? item?.supplyType?.code ?? item?.typeSupply.code

  return (
    <ResponsiveLayout maxWidth={WITH_TABLE}>
      <KeyboardAvoidingView
        keyboardVerticalOffset={20}
        behavior='padding'
        style={styles.container}
      >
        <ScrollView showsVerticalScrollIndicator={Platform.OS === 'web'}>
          <>
            {!item.isGeneric ? (
              <Image
                style={styles.image}
                source={SUPPLY[codeSupply] || SUPPLY['default']}
              />
            ) : (
              <IconBox
                iconName={activityIcons.ACT_FERTILIZATION}
                size={SIZES.MEDIUM}
                color={yellow900}
                bgColor={yellow300}
              />
            )}

            <Text style={styles.supplyName}>{item.brand ?? item.name}</Text>

            {item.brand && (
              <Text style={[styles.supplyProperty, styles.marginTopBrand]}>
                {item.name}
              </Text>
            )}

            <Text style={styles.supplyProperty}>
              {item.typeId?.codeLabel ?? item.typeId.name}
            </Text>

            <Text style={styles.supplyProperty}>{item.company}</Text>
          </>

          {item?.activeIngredients.length ? (
            <>
              <View style={styles.containerSegment}>
                <View style={styles.containerData}>
                  <Text style={[styles.disabledText, styles.disabledTitles]}>
                    {t('VIEWS').SUPPLY_SELECTOR_LOADER.TEXT_1}
                  </Text>
                  <Text style={styles.activeIngredientPlacement}>
                    {isConnected && (
                      <View>
                        <Text style={[styles.disabledText, styles.supplyData]}>
                          {item.activeIngredientsLabel}
                        </Text>
                      </View>
                    )}
                    {!isConnected &&
                      item.activeIngredients.map((ingredient, key2) => (
                        <View key={key2}>
                          <Text
                            key={key2}
                            style={[styles.disabledText, styles.supplyData]}
                          >
                            {ingredient.activeIngredient.name[i18n.locale]}
                          </Text>
                          {key2 !== item.activeIngredients.length - 1 && (
                            <Text style={styles.disabledText} key={key2}>
                              ,{' '}
                            </Text>
                          )}
                        </View>
                      ))}
                  </Text>
                </View>
                <View style={styles.containerData}>
                  <Text style={[styles.disabledText, styles.disabledTitles]}>
                    {t('VIEWS').SUPPLY_SELECTOR_LOADER.TEXT_2}
                  </Text>
                  <Text style={[styles.disabledText, styles.supplyData]}>
                    {round(Number(item.eiqTotal), 2)}
                  </Text>
                </View>

                <View style={styles.containerData}>
                  <Text style={[styles.disabledText, styles.disabledTitles]}>
                    {t('VIEWS').SUPPLY_SELECTOR_LOADER.TEXT_3}
                  </Text>
                  {!!item.classToxic && (
                    <Text style={[styles.disabledText, styles.supplyData]}>
                      {`${item.classToxic}`}
                    </Text>
                  )}
                </View>

                <View style={styles.separator} />
              </View>
            </>
          ) : null}

          <ChemicalCompositions
            chemicalCompositions={mapData(
              item?.chemicalCompositionsRequest ?? item?.chemicalCompositions
            )}
            show={item?.typeId?.tags?.includes(keysSupplies.fertilizer)}
          />

          <View style={styles.containerSegment}>
            <View style={[styles.containerData, styles.itemText]}>
              <Text style={styles.titleInfo}>
                {t('ACTIVITIES.SUPPLIES_ACTIVITY.UNIT_OF_MEASURE')}
              </Text>
              <Text style={styles.titleValue}>
                {unitName?.keyLabel || item?.unitTypeSupply?.key || ''}
              </Text>
            </View>

            <View style={[styles.containerData, styles.itemText]}>
              <Text style={styles.titleInfo}>
                {t('VIEWS.SUPPLY_SELECTOR_LOADER.TEXT_4', {
                  areaUnit: initialValues.areaUnit,
                })}
              </Text>
              <Text style={styles.titleValue}>
                {truncateSurface(Number(initialValues.surface))}
              </Text>
            </View>

            <View style={[styles.containerData, styles.itemText]}>
              <Text style={styles.titleInfo}>
                {t('ACTIVITIES.SUPPLIES_ACTIVITY.AMOUNT', {
                  unit: unitName?.keyLabel || item?.unitTypeSupply?.key || '',
                  areaUnit: initialValues.areaUnit,
                })}
              </Text>
              <Text style={styles.titleValue}>
                {`${round(Number(initialValues.quantity), 6)}`}
              </Text>
            </View>
          </View>
        </ScrollView>

        <View style={styles.bottomComponents}>
          <Text style={styles.textInfo}>
            {t('ACTIVITIES.SUPPLIES_ACTIVITY.TOTAL_AMOUNT', {
              unit: unitName?.keyLabel || item?.unitTypeSupply?.key || '',
            })}
          </Text>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSelect}
          >
            {({
              handleSubmit,
              values,
              touched,
              setFieldTouched,
              setFieldValue,
              errors,
            }) => (
              <View style={styles.modalContainer}>
                <Field
                  name='total'
                  component={InputText}
                  inputProps={{
                    keyboardType: 'decimal-pad',
                    returnKeyType: 'done',
                    returnKeyLabel: t('VIEWS').SUPPLY_SELECTOR.FIELD_3.KEY,
                  }}
                  validate={onChangeTotal}
                  label={t('ACTIVITIES.SUPPLIES_ACTIVITY.TOTAL_AMOUNT_APPLIED')}
                  placeholder={t(
                    'ACTIVITIES.SUPPLIES_ACTIVITY.TOTAL_AMOUNT_APPLIED'
                  )}
                  value={values?.total}
                  onChangeText={(value) =>
                    setFieldValue('total', value?.trimStart())
                  }
                  onPress={() => {
                    setFieldTouched('total')
                  }}
                  touched={touched?.total}
                  error={errors?.total}
                  containerStyle={styles.input}
                  inputContainerStyle={[styles.inputContainerStyle]}
                  animate='medium'
                  placeholderStyle={styles.placeholderStyle}
                  placeholderTextColor={carbon500}
                />

                {outlier && (
                  <View style={styles.outlierContainer}>
                    <Outlier
                      icon={outlierIcons.InfoFill}
                      title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
                      type={outlier}
                      onPress={openBackdropStandardDeviation}
                    />
                  </View>
                )}

                <ButtonCustom
                  onPress={handleSubmit}
                  height={38}
                  styles={[
                    styles.button,
                    !values.total ? styles.buttonDisabled : {},
                  ]}
                  labelStyle={[
                    styles.textButton,
                    !values.total ? styles.textButtonDisabled : {},
                  ]}
                >
                  {t('ACTIVITIES.SUPPLIES_ACTIVITY.APPLY')}
                </ButtonCustom>
              </View>
            )}
          </Formik>
        </View>

        <ComponentStandarDeviation />

        <ModalConfirm
          visible={showDeleteModal}
          onClose={closeModalDelete}
          onConfirm={() => handleDeleteSupply(route.params.key)}
          title={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_1}
          contentText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_2}
          cancelText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_3}
          confirmText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_4}
          cancelButtonStyle={styles.deleteModalCancelButtonStyle}
          cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
          confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
        />
      </KeyboardAvoidingView>
    </ResponsiveLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 0,
    backgroundColor: white,
    flexDirection: 'column',
  },
  containerSegment: {
    paddingVertical: 13,
  },
  disabledText: {
    color: '#333333B2',
  },
  supplyName: {
    fontSize: 24,
    fontStyle: 'normal',
    letterSpacing: 0.15,
    textAlign: 'left',
    marginTop: 14,
    fontFamily: 'Inter',
    fontWeight: 'Inter_700_bold',
    lineHeight: 32,
  },
  supplyProperty: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.25,
    textAlign: 'left',
    fontFamily: 'Inter',
    color: carbon800,
    lineHeight: 24,
  },
  marginTopBrand: {
    marginTop: 8,
  },
  image: {
    width: 40,
    height: 40,
    marginTop: 8,
  },
  supplyInfoTexts: {
    flex: 1,
    paddingHorizontal: 16,
  },
  disabledTitles: {
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0.4,
    textAlign: 'left',
    flexWrap: 'wrap',
    fontFamily: 'Inter',
    fontWeight: 'Inter_600_semibold',
    color: carbon800,
  },
  itemText: {
    marginVertical: 4,
  },
  containerData: {
    paddingBottom: 4,
  },
  supplyData: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.15,
    textAlign: 'left',
    color: carbon800,
    fontFamily: 'Inter',
  },
  supplyInfo: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  modalContainer: {
    maxWidth: isWeb ? 312 : null,
  },
  activeIngredientPlacement: {
    flexDirection: 'row',
  },
  separator: {
    borderBottomColor: '#0000001F',
    borderBottomWidth: 1,
    marginHorizontal: 32,
    paddingTop: 8,
  },
  bottomComponents: {
    marginTop: 'auto',
  },
  input: {
    height: 56,
    marginBottom: 25,
  },
  inputContainerStyle: {
    borderRadius: 10,
    borderColor: carbon300,
    height: 56,
  },
  placeholderStyle: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
    color: carbon500,
  },
  button: {
    backgroundColor: primary500,
    marginTop: 8,
    marginBottom: 10,
    maxWidth: isWeb ? 224 : null,
  },
  buttonTextStyle: {
    color: white,
  },
  buttonDisabled: {
    backgroundColor: carbon300,
  },
  textButtonDisabled: {
    color: white,
  },
  textButton: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'Inter_600_semibold',
    color: white,
  },
  outlierContainer: {
    marginTop: 15,
    marginHorizontal: 0,
    marginBottom: 40,
  },
  textInfo: {
    fontSize: 16,
    fontWeight: 'Inter_500_medium',
    lineHeight: 24,
    marginBottom: 16,
    fontFamily: 'Inter',
    color: carbon800,
  },
  titleInfo: {
    fontSize: 18,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'Inter_600_semibold',
    color: carbon800,
    lineHeight: 28,
  },
  titleValue: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    color: carbon800,
    lineHeight: 24,
  },
})

export default SupplyLoaderScreen
