export default {
  TEXT_1: 'Planned',
  TEXT_2: 'From',
  TEXT_3: 'To',
  TEXT_4: '%{establishments} Farms %{lots} lots',
  TEXT_5: 'Planned area: %{surface} %{areaUnit}',
  TEXT_6: 'Type of %{activityType} : %{subTypeActivity}',
  TEXT_7: 'Projected EIQ %{eiq}',
  TEXT_8: 'INPUT',
  TEXT_9: 'INPUTS',
}
