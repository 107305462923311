export const tableNamesIndividuals = ['crops', 'activities']
export const tableNamesTypes = [
  'croptypes',
  'roletypes',
  'unitypes',
  'unitypesvolume',
  'agreements',
  'verificationTypes',
  'activities',
  'concepts',
  'storagetypes',
  'countries',
  'subactivity',
]
