import React from 'react'
import { Image, Text, Platform, StyleSheet } from 'react-native'
import { countryToFlag, getUrlCountry } from '../../../utils'

export const FlagCountry = ({ country }) => {
  return Platform.OS === 'web' ? (
    <Image
      source={getUrlCountry(country?.alpha2Code).toLocaleLowerCase()}
      style={styles.imgFlag}
    />
  ) : (
    <Text style={styles.textFlagCountry}>
      {countryToFlag(country?.alpha2Code || 'ar')}
    </Text>
  )
}

const styles = StyleSheet.create({
  imgFlag: {
    width: 30,
    height: 20,
  },
  textFlagCountry: {
    fontSize: 18,
  },
})
