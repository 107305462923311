import React from 'react'
import { PropTypes } from 'prop-types'
import { View, Text, StyleSheet, Pressable, Platform } from 'react-native'
import {
  brown400,
  cherry200,
  cherry600,
  lemon500,
  yellow200,
  cherry300,
  lemon300,
} from '@styles/palette'
import { useAuth } from '@common/hooks'

const isWeb = Platform.OS === 'web'

const DANGER = 'DANGER'
export const OutlierBox = ({ title, type, style, onPress }) => {
  const { config } = useAuth()

  const {
    companySelected: {
      country: { alpha3Code },
    },
  } = config

  return (
    alpha3Code !== 'USA' && (
      <Pressable onPress={onPress ? onPress : null}>
        <View
          style={[
            styles.container,
            type === DANGER ? styles.danger : styles.warning,
            style,
          ]}
        >
          <Text
            style={[
              styles.text,
              type === DANGER ? styles.textDanger : styles.textWarning,
            ]}
          >
            {title}
          </Text>
        </View>
      </Pressable>
    )
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: cherry200,
    marginHorizontal: 10,
    marginTop: 5,
    paddingLeft: 10,
    borderWidth: 1,
    borderRadius: 4,
    height: 20,
    width: isWeb ? 300 : undefined,
  },
  danger: {
    backgroundColor: cherry200,
    borderColor: cherry300,
  },

  containerText: {
    marginLeft: 20,
    marginRight: 16,
  },
  text: {
    fontWeight: '400',
    fontSize: 10,
    marginTop: 3,
    marginBottom: 5,
  },
  textDanger: {
    color: cherry600,
  },
  warning: { backgroundColor: yellow200, borderColor: lemon300 },
  iconWarning: { color: lemon500 },
  textWarning: { color: brown400 },
})

OutlierBox.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
}
