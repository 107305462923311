import {
  AgreementTypeLazyLoadEvidence,
  AgreementSubTypeForStatementService,
} from '@common/utils'

const canRedirectDeclarationService = (agreementTypeKey) => {
  const pattern = new RegExp('^SUSTAINABILITY')
  return (
    pattern.test(agreementTypeKey) ||
    AgreementTypeLazyLoadEvidence[agreementTypeKey]
  )
}

const canRedirectStatementServices = (agreementSubType) => {
  return AgreementSubTypeForStatementService[agreementSubType]
}

export const validateAgreement = async ({
  activityToDelete,
  isRealized,
  doRequest,
  dataToSend,
  values,
  pinToken,
  agreementTypeKey,
}) => {
  const isRedirectDeclarationService =
    canRedirectDeclarationService(agreementTypeKey)
  const isRedirectStatementService = canRedirectStatementServices(
    values?.typeAgreement?.subType
  )
  const formData = new FormData()
  if (dataToSend.dataForCFT) {
    formData.append('dataForCFT', JSON.stringify(dataToSend.dataForCFT))
    delete dataToSend.dataForCFT
  }
  formData.append('data', JSON.stringify(dataToSend))
  values.files.forEach((element) => formData.append('files', element))

  let newActivityId
  let responseActivity

  if (!values.activityId && !isRealized) {
    if (activityToDelete) {
      await doRequest({
        method: 'DELETE',
        url: `activities/${activityToDelete}`,
        displayAlert: false,
      })
    }

    if (isRedirectDeclarationService) {
      responseActivity = await doRequest({
        headers: {
          'Content-Type': 'multipart/form-data',
          'validate-token-pin': `Bearer ${pinToken}`,
        },
        method: 'POST',
        url: 'declarations',
        data: formData,
        version: 'v1',
        displayAlert: false,
      })
    } else if (isRedirectStatementService) {
      responseActivity = await doRequest({
        headers: {
          'Content-Type': 'multipart/form-data',
          'validate-token-pin': `Bearer ${pinToken}`,
        },
        method: 'POST',
        url: 'statements/api/v1/statements',
        data: formData,
        displayAlert: false,
        onlyUrl: true,
      })
    } else {
      responseActivity = await doRequest({
        headers: {
          'Content-Type': 'multipart/form-data',
          'validate-token-pin': `Bearer ${pinToken}`,
        },
        method: 'POST',
        url: 'activities',
        data: formData,
        version: 'v3',
        displayAlert: false,
      })
    }

    newActivityId = responseActivity?.data?._id
  }

  await doRequest({
    headers: {
      'validate-token-pin': `Bearer ${pinToken}`,
    },
    method: 'PUT',
    url: `activities/${newActivityId ?? values.activityId}/crops/${
      values.cropId || values.crop
    }/signed`,
    data: {
      draftId: values.draftId,
      dateSigned: new Date(),
    },
    displayAlert: false,
  })

  return true
}
