export const parseDataToSubmit = (values) => {
  const dataToSend = {
    country: values.country.value,
    identifier: values.identifier,
    email: values.email,
    userType: values.userType.value,
  }

  return dataToSend
}
