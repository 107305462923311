import React from 'react'
import { FlatList, StyleSheet, View, Platform } from 'react-native'
import ItemSupply from './ItemSupply'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import SuppliesEmpty from '@components/supplies/SuppliesEmpty'
import { ActivityIndicator } from '@modules/common/components'
import { gray25, gray200 } from '@modules/common/styles'

export const ListSupplies = ({
  data,
  search,
  isLoading,
  onEndReached,
  firstPaginate,
  onPress,
  type,
  isConnected,
  searchValue,
  onRefresh,
}) => {
  return !firstPaginate ? (
    <FlatList
      showsVerticalScrollIndicator={Platform.OS === 'web'}
      contentContainerStyle={{ flexGrow: 1, backgroundColor: gray25 }}
      data={data}
      renderItem={({ item }) => (
        <ItemSupply
          search={search}
          item={item}
          onPress={() => onPress(item)}
          type={type}
        />
      )}
      keyExtractor={(item, index) => index.toString()}
      onRefresh={onRefresh}
      refreshing={isLoading}
      onEndReachedThreshold={2}
      onEndReached={!isLoading ? onEndReached : null}
      ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
      ListEmptyComponent={
        <SuppliesEmpty
          type={type}
          searchValue={searchValue}
          isConnected={isConnected}
        />
      }
      ListFooterComponent={
        isLoading ? (
          <ActivityIndicator containerStyle={styles.loaderContainerStyle} />
        ) : null
      }
    />
  ) : (
    <LoaderCenterContainer />
  )
}

const styles = StyleSheet.create({
  loaderContainerStyle: {
    marginVertical: 30,
  },
  itemSeparator: {
    backgroundColor: gray200,
    height: 0.5,
  },
})
