export default {
  REQUIRED: 'The field is required',
  INVALID_FORMAT: 'The field format is wrong',
  MIN_QUANTITY: 'Select at least %{quantity}',
  MAX_QUANTITY: 'Maximum selection of %{quantity}',
  IS_NUMBER: 'The field must be numeric',
  IS_POSITIVE: 'The field must greater than 0',
  MIN_CHARACTERS: 'Please enter at least %{quantity} characters',
  MAX_CHARACTERS: 'Enter maximum %{quantity} characters',
  LEN_CHARACTERS: 'Must be exactly %{quantity} characters',
  MUST_MATCH: 'Emails must match',
  EMAIL_EXIST: 'This email exist',
  THIS_VALUE_IS:
    'It is not possible to add this TAX ID to this collaborator type, please enter a new one.',
  NUMBER_INVALID: 'The number entered is not valid.',
  INVALID_CHARACTERS: 'Invalid characters',
  NOT_SPECIAL_CHARACTERS: 'Tax ID with invalid characters',
  LESS_THAN: 'The field must be less than',
}
