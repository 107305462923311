const activityTypes: any = {
  ACT_TILLAGE: {
    key: 'ACT_TILLAGE',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_TILLAGE,
    canPlanning: true,
    icon: 'shovel',
  },
  ACT_APPLICATION: {
    key: 'ACT_APPLICATION',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_APPLICATION,
    canPlanning: true,
    icon: 'test-tube',
  },
  ACT_FERTILIZATION: {
    key: 'ACT_FERTILIZATION',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_FERTILIZATION,
    canPlanning: true,
    icon: 'barley',
  },
  ACT_SOWING: {
    key: 'ACT_SOWING',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_SOWING,
    canPlanning: true,
    icon: 'seed',
  },
  ACT_HARVEST: {
    key: 'ACT_HARVEST',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_HARVEST,
    canPlanning: false,
    icon: 'tractor-variant',
  },
  ACT_AGREEMENTS: {
    key: 'ACT_AGREEMENTS',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_AGREEMENTS,
    canPlanning: false,
    icon: 'shovel',
  },
  ACT_MONITORING: {
    key: 'ACT_MONITORING',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_MONITORING,
    canPlanning: false,
    icon: 'eye',
  },
  ACT_VERIFICATION: {
    key: 'ACT_VERIFICATION',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_VERIFICATION,
    canPlanning: false,
  },
  ACT_IRRIGATION: {
    key: 'ACT_IRRIGATION',
    name: (t: any) => t('CONSTANTS').ACTIVITY_TYPES.ACT_IRRIGATION,
    canPlanning: false,
  },
  ACT_FLOOD: {
    key: 'ACT_FLOOD',
    name: '',
    canPlanning: false,
  },
}

export default activityTypes
