import { useContext } from 'react'
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from 'react-native'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { CropDescription } from '@common/components'
import {
  primary500,
  blackHighEmphasis,
  blackMediumEmphasis,
  backgroundDefault,
  white,
  greenLight,
  black,
} from '@styles/palette'
import TagNew from '@common/components/tagNew'
import { LanguageContext } from '@contextState/language'

const Screen = ({ crop, isViewReady, goToPlanning, goToRealization }) => {
  const { isConnected } = useSelector((state) => state.connectionReducer)

  const { t } = useContext(LanguageContext)

  if (!isViewReady) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator animating size='large' color={primary500} />
      </View>
    )
  }

  return (
    <View style={styles.flex}>
      <View style={styles.container}>
        <View>
          <CropDescription crop={crop} />
          <TouchableWithoutFeedback
            onPress={goToPlanning}
            disabled={!isConnected}
          >
            <View style={[styles.containerItem, styles.bgWhite]}>
              <View style={styles.containerLeft}>
                <CommonIcon
                  name={'CALENDAR-PLANIFY'}
                  size={32}
                  color={blackHighEmphasis}
                  style={styles.icon}
                />
                <View style={styles.containerText}>
                  <Text style={[styles.title, styles.black]}>
                    {t('ACTIVITIES.OPTIONAL_PLANNED.TEXT_1')}
                  </Text>
                  <Text style={[styles.textDescription, styles.blackMedium]}>
                    {t('ACTIVITIES.OPTIONAL_PLANNED.TEXT_2')}
                  </Text>
                </View>
              </View>
              <View style={styles.containerRight}>
                <CommonIcon name='CHEVRON-RIGHT' size={20} color={black} />
              </View>
            </View>
          </TouchableWithoutFeedback>
          <TouchableWithoutFeedback onPress={goToRealization}>
            <View style={[styles.containerItem, styles.bgGreen]}>
              <View style={styles.containerLeft}>
                <CommonIcon
                  name={'CALENDAR-REALIZED'}
                  size={32}
                  color={white}
                  style={styles.icon}
                />
                <View style={styles.containerText}>
                  <Text style={[styles.title, styles.white]}>
                    {t('ACTIVITIES.OPTIONAL_PLANNED.TEXT_3')}
                  </Text>
                  <Text style={[styles.textDescription, styles.white]}>
                    {t('ACTIVITIES.OPTIONAL_PLANNED.TEXT_4')}
                  </Text>
                </View>
              </View>
              <View style={styles.containerRight}>
                <CommonIcon name='CHEVRON-RIGHT' size={20} color={white} />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </View>

        <TagNew description={t('ACTIVITIES.OPTIONAL_PLANNED.TEXT_5')} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  loader: {
    marginTop: 100,
  },
  container: {
    backgroundColor: backgroundDefault,
    paddingBottom: 16,
    paddingHorizontal: 16,
    flex: 1,
    justifyContent: 'space-between',
  },
  containerItem: {
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    elevation: 1,
    marginBottom: 16,
    minHeight: 75,
  },
  bgWhite: {
    backgroundColor: white,
  },
  bgGreen: {
    backgroundColor: primary500,
  },
  containerLeft: { flexDirection: 'row', flex: 0.9 },
  icon: { paddingTop: 3 },
  containerText: { marginLeft: 16, flex: 1 },
  title: {
    fontSize: 16,
    fontWeight: '700',
    paddingBottom: 3,
  },
  black: { color: blackHighEmphasis },
  blackMedium: { color: blackMediumEmphasis },
  white: { color: white },
  textDescription: {
    fontSize: 14,
    fontWeight: '400',
  },
  containerRight: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: 0.2,
  },
  containerIconGreen: {
    backgroundColor: greenLight,
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
})

Screen.propTypes = {
  crop: PropTypes.object.isRequired,
  isViewReady: PropTypes.bool.isRequired,
  goToPlanning: PropTypes.func.isRequired,
  goToRealization: PropTypes.func.isRequired,
}

export default Screen
