export function getObjectDepth(obj: any, set = new Set()) {
  if (set.has(obj)) {
    return 0
  }

  set.add(obj)
  let depth = 0

  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      typeof obj[key] === 'object'
    ) {
      depth = Math.max(depth, getObjectDepth(obj[key], set))
    }
  }

  set.delete(obj)

  return depth + 1
}
