import { FC, ReactElement, useContext } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { LanguageContext } from '@contextState/language'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'

import FarmsMap from '@modules/farms/screens/FarmsMap/v2'
import FarmMapToUrlWebView from '@modules/farms/screens/FarmMapToUrlWebView/v1'

const Stack = createStackNavigator()

export const FarmStack: FC<any> = (): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const stacks = [
    {
      name: RouterNames.FARM_MAP,
      component: FarmsMap,
      initialParams: { newFarm: false },
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_50'),
        header: (props: any) => {
          if (Platform.OS === 'web') {
            return null
          }

          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_50')}
              drawer
            />
          )
        },
      },
    },
    {
      name: RouterNames.FARM_MAP_TO_URL_WEB_VIEW,
      component: FarmMapToUrlWebView,
      initialParams: { newFarm: false },
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_50'),
        header: (props: any) => {
          if (Platform.OS === 'web') {
            return null
          }

          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_50')}
              drawer
            />
          )
        },
      },
    },
  ]

  return (
    <Stack.Navigator initialRouteName={RouterNames.FARM_MAP}>
      {stacks.map((stack: any, key) => (
        <Stack.Screen {...stack} key={`FARM_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
