import { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { white } from '@styles/palette'
import { ActivityIndicator } from '@common/components'
import { FlatList } from 'react-native'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { AccordionItemSelection } from './components'
import { useCommonItemsMultipleLevels } from '@common/hooks/useCommonItemsMultipleLevels'

export const SelectItemsWithMultiLevels = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const {
    items,
    isLoading,
    onSubmit,
    isViewReadOnly,
    textButton,
    HeaderRender,
    numItemsSelected,
  } = useCommonItemsMultipleLevels()

  const onPress = () => {
    if (isSubmitted) {
      return
    }

    setIsSubmitted(true)

    onSubmit()
  }

  return (
    <View style={styles.content}>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <FlatList
          data={items ?? []}
          ListHeaderComponent={HeaderRender}
          renderItem={({ item, index }) => {
            return <AccordionItemSelection item={item} index={index} />
          }}
          keyExtractor={(item) => `item-${item._id}`}
        />
      )}

      {!isViewReadOnly && (
        <View style={styles.contentButton}>
          <ButtonDefault
            typeButton={numItemsSelected > 0 ? 'primary' : 'disabled'}
            disabled={numItemsSelected <= 0}
            text={textButton}
            onPress={onPress}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: white,
  },
  contentButton: {
    paddingHorizontal: 24,
    paddingVertical: 45,
  },
})
