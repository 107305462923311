export default {
  TEXT_1: 'Required field',
  TEXT_2: 'The code should have six digits',
  TEXT_3: 'A code has been sent to your email',
  FIELD_1: {
    HELP_TEXT: 'Enter the code you received in your email',
    LABEL: 'Access code*',
    PLACEHOLDER: 'xxxxxxx',
  },
  TEXT_4: 'Sign in',
  TEXT_5: '¿Problems signing in?',
  TEXT_6: 'Re-enter',
}
