import LICENSES from './licenses'
import LOTS from './lots'
import COMMON from './common'
import MODAL from './modals'
import ACTIVITIES from './activities'
import BINNACLE from './binnacle'
import COLLABORATORS_BUTTON from './collaborators/CollaboratorsButton'
import COMPANIES from './companies'
import CROPS from './crops'
import INPUTS from './inputs'
import INTEGRATION from './integration'
import DIALOG from './dialog'
import SUPPLIES from './supplies'
import SUPPLIES_EMPTY from './supplies/SuppliesEmpty'
import LANDSCAPE_SUSTAINABILITY from './landscapeSustainability'
import TEXT from './text'
import COUNTRIES from './countries'
import COMPONENTS_COMPANY from '@modules/companies/components/translate/pt'
import APP_INTEGRATOR from '@modules/AppIntegrator/translate/pt'

export default {
  LICENSES,
  LOTS,
  COMMON,
  MODAL,
  ACTIVITIES,
  BINNACLE,
  COLLABORATORS_BUTTON,
  COMPANIES,
  CROPS,
  INPUTS,
  INTEGRATION,
  DIALOG,
  SUPPLIES,
  SUPPLIES_EMPTY,
  LANDSCAPE_SUSTAINABILITY,
  TEXT,
  COUNTRIES,
  COMPONENTS_COMPANY,
  APP_INTEGRATOR,
}
