import { useState, useMemo } from 'react'
import { orderCropTypes } from '@modules/crops/utils'
import { deepSearchCropTypeInArr } from '../utils/deepSearchCropTypes'
import { ICropType } from '../interfaces'

export const useSearchCropTypes = (cropTypes: ICropType[]) => {
  const orderedCropTypes = useMemo(() => orderCropTypes(cropTypes), [cropTypes])
  const [searchTxt, setSearchTxt] = useState('')

  const cropTypesArr = useMemo(() => {
    return searchTxt?.length >= 3
      ? deepSearchCropTypeInArr(searchTxt, orderedCropTypes)
      : orderedCropTypes
  }, [orderedCropTypes, searchTxt])

  return {
    cropTypesArr,
    searchTxt,
    setSearchTxt,
  }
}
