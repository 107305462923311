export enum EEvidenceConcepts {
  '0001' = '0001',
  '0002' = '0002',
  '0003' = '0003',
  '0004' = '0004',
  '0005' = '0005',
  '0006' = '0006',
  '0007' = '0007',
  '0008' = '0008',
  '0009' = '0009',
  '0010' = '0010',
  '0011' = '0011',
  '0012' = '0012',
  '0013' = '0013',
  '0034' = '0034',
  '0040' = '0040',
}
