export default {
  TEXT_1: 'Campo Requerido',
  TEXT_2: 'No es un email valido',
  TEXT_3: 'No te encontramos en el sistema',
  TEXT_4: 'CREA TU CUENTA',
  FIELD_1: {
    HELP_TEXT:
      'Enviaremos un código para ingresar a la aplicación a ésta dirección',
    LABEL: 'Email*',
    PLACEHOLDER: 'Ingresa tu Email',
  },
  FIELD_2: {
    LABEL: 'Nombre*',
    PLACEHOLDER: 'Ingresa tu nombre',
  },
  FIELD_3: {
    LABEL: 'Apellido*',
    PLACEHOLDER: 'Ingresa tu apellido',
  },
  FIELD_4: {
    LABEL: 'Teléfono Celular*',
    PLACEHOLDER: 'Ingresa tu teléfono',
  },
  TEXT_5: 'Acepto las políticas de uso',
  TEXT_6: 'Obtener código de acceso',
  TEXT_7: 'He leído y acepto los',
  TEXT_8: 'T&C / política de privacidad',
  PRIVACY_POLICY_URL: 'politica-de-privacidad/',
}
