export default {
  ACTIVITY_TYPE_TITLE: 'Type of %{activityType}',
  FIELDS_QUANTITY_TITLE: 'Fields selected',
  SURFACE_TITLE: 'Area',
  PLANNED_DATE_TITLE: 'Planned %{activityType} date',
  SINCE: 'From %{date}',
  UNTIL: 'To %{date}',
  REALIZED_DATE_TITLE: 'Execution date',
  HARVEST_DATE_TITLE: 'Harvest date',
  MONITORING_DATE_TITLE: 'Scouting date',
  AGREEMENT_TYPE_TITLE: 'Declaration type',
}
