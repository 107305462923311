import { farmTypes as type } from '../types'

const initialState = {
  fullScreen: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.FULL_SCREEN: {
      return {
        ...state,
        fullScreen: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
