import HeaderAccordionPlanning from '../components/HeaderAccordionPlanning/translate/en'
import CardAchievement from '../components/CardAchievement/translate/en'
import HeaderInfoAchievements from '../components/HeaderInfoAchievements/translate/en'
import WORK_ORDER from '../components/WorkOrder/translate/en'

export default {
  TEXT_1: 'FINISH ACTIVITY',
  TEXT_2: 'BACK TO ACTIVITIES',
  COMMON: {
    TEXT_1: 'Farm',
    TEXT_2: 'Farms',
    TEXT_3: 'Field',
    TEXT_4: 'Fields',
    TEXT_5: 'Inputs',
    TEXT_6: 'Input',
  },
  COMPONENTS: {
    HEADER_ACCORDION_PLANNING: HeaderAccordionPlanning,
    CARD_ACHIEVEMENT: CardAchievement,
    HEADER_INFO_ACHIEVEMENT: HeaderInfoAchievements,
    WORK_ORDER,
  },
  CONFIRM_FINISHED: {
    TEXT_1: 'Finish %{activity}',
    TEXT_2: 'Area not executed can be done on a new Activity',
    TEXT_3: 'FINISH',
    TEXT_4: 'CANCEL',
  },
}
