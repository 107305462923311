export default {
  TEXT_1: 'Field detail',
  TEXT_2: 'Sup. digital %{surface} %{areaUnit}',
  TEXT_3: '%{applicationQuantity} applications',
  TEXT_4: 'EIQ %{eiq}',
  TEXT_5: 'Landscape sustainability',
  TEXT_6: '%{value} %{areaUnit} without overlap',
  TEXT_7: '%{value} %{areaUnit} overlap',
  TEXT_8: 'Apto',
  TEXT_9: 'ha',
  TEXT_10: 'Detalle',
  TEXT_11: 'Native forest management',
  TEXT_12: '%{value}% overlap',
  TEXT_13: 'CATEGORY %{value}',
  TEXT_14: 'Valuable grassland areas',
  TEXT_15: 'Bird conservation areas',
  TEXT_16: 'Clearing areas',
  TEXT_17: 'Wetlands and peatlands',
  TEXT_18: 'Protected areas',
  TEXT_19: 'High biodiversity areas',
  TEXT_20: 'Nature reserves',
  TEXT_21: '*Total percentages take into account layer overlap',
}
