export default {
  TEXT_1: 'Confirmar nuevo cultivo',
  TEXT_2: 'De la empresa física o jurídica a cargo de la producción',
  TEXT_3: 'Campo requerido',
  TEXT_4: 'Superficie debe ser mayor a 0',
  TEXT_5: 'lotes',
  TEXT_6: 'ha',
  TEXT_7: 'CONFIRMAR NUEVO CULTIVO',
  TEXT_8: '¡Felicitaciones!',
  TEXT_9: 'Ya asignaste los lotes al cultivo',
  TEXT_10: 'Vea más detalle de su cultivo:',
  TEXT_11: 'Historia del cultivo',
  TEXT_12: 'Volver a cultivos',
  TEXT_13: 'ac',
  FIELD_1: {
    NAME: 'Tipo de cultivo',
  },
  FIELD_2: {
    NAME: 'Siembra estimada',
  },
  FIELD_3: {
    NAME: 'Cosecha estimada',
  },
  FIELD_4: {
    NAME: 'Rinde estimado',
  },
  FIELD_5: {
    NAME: 'CUIT',
  },
  FIELD_6: {
    NAME: 'Nombre del cultivo',
  },
  FIELD_7: {
    NAME: 'Superficie Neta',
  },
  FIELD_8: {
    NAME: 'Unidad de rinde',
  },
  FIELD_9: {
    NAME: 'País',
  },
  TEXT_14: '¡Felicitaciones!',
  TEXT_15: 'Creaste el cultivo con éxito.',
  OUTLIER_TITLE: 'El valor se encuentra fuera de la media.',
  OUTLIER_DESCRIPTION: '¿Cómo utilizar la desviación estándar?',
}
