import * as yup from 'yup'

export const verificationValidationSchema = (t) =>
  yup.object().shape({
    verificationType: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    lots: yup
      .object()
      .shape({
        value: yup
          .array()
          .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
          .of(
            yup.object().shape({
              _id: yup.string().required(),
              surface: yup.number().required(),
              tag: yup.string(),
              wktFormat: yup.string(),
              area: yup.string(),
            })
          )
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    surface: yup.number().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
    observation: yup.string().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
    observations: yup.array(),
    evidences: yup.array(),
    signers: yup
      .array()
      .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    verificationFile: yup.object().shape({
      name: yup.string().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
      originalName: yup
        .string()
        .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
      updatedBy: yup.object().shape({
        _id: yup.string().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
        firstName: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
        lastName: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
      }),
      updatedAt: yup.date().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
    }),
  })
