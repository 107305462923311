/**
 * Replace ",", "\."
 *
 * @param {*} number
 */

function convertNumber(number) {
  return number.replace(',', '.')
}

/**
 * Replace ".", ","
 *
 * @param {*} number
 */
function convertNumbertoString(number) {
  return number.toString().replace('.', ',')
}

const roundNumber = (number, numberDecimal = 2) =>
  Math.round(number * Math.pow(10, numberDecimal)) / Math.pow(10, numberDecimal)

const numberInteger = (number) => number.replace(/(?!-)[^0-9]/g, '')
const numberDecimal = (number) =>
  number.replace(',', '.').replace(/(?!-)[^0-9.]/g, '')

const keyboardTypeParseNumberDecimal = (text, keyboardType) => {
  switch ((text, keyboardType)) {
    case 'decimal-pad':
    case 'numbers-and-punctuation':
      return numberDecimal(String(text))
    case 'numeric':
      return numberInteger(String(text))
    default:
      return text
  }
}
const isNumber = (number) => /^[0-9$]*$/g.test(number)
export {
  isNumber,
  convertNumber,
  convertNumbertoString,
  roundNumber,
  keyboardTypeParseNumberDecimal,
  numberInteger,
  numberDecimal,
}
