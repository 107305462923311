import { round } from 'lodash'

const SUPPLY_TYPE_SEED = 'SEEDS'
const SUPPLY_TYPE_FERTILIZER = 'FERTILIZERS'
const SUPPLY_TYPE_PHYTOTHERAPIC = 'PHYTOTHERAPICS'

export const parseSupplies = ({ supplies }) => {
  const suppliesParsed = supplies.map(
    ({
      _id,
      typeId,
      typeSupply,
      brand,
      name,
      company,
      quantity: supplyQuantity,
      total: supplyTotal,
      unitTypeSupply,
      eiqTotal: supplyEiqTotal,
      isGeneric,
    }) => {
      const { tags: supplyTypeTags = [], code } = typeSupply || typeId
      const supplyTypeName = typeSupply?.codeLabel || typeSupply?.name
      const unitType = unitTypeSupply?.keyLabel
      const unitTypeKey = unitTypeSupply?.key
      const quantity = round(supplyQuantity, 3)
      const total = Number(supplyTotal)
      const eiqTotal = round(supplyEiqTotal, 2)

      return {
        _id,
        supplyTypeTags,
        supplyTypeName,
        code,
        name: brand || name,
        company,
        quantity,
        total,
        unitType,
        unitTypeKey,
        eiqTotal,
        isGeneric,
      }
    }
  )

  const suppliesSeeds = parseSuppliesSeeds({ supplies: suppliesParsed })

  const suppliesFertilizers = parseSuppliesFertilizers({
    supplies: suppliesParsed,
  })

  const suppliesPhytotherapics = parseSuppliesPhytotherapics({
    supplies: suppliesParsed,
  })

  return {
    suppliesSeeds,
    suppliesFertilizers,
    suppliesPhytotherapics,
  }
}

const parseSuppliesSeeds = ({ supplies }) => {
  const suppliesFiltered = supplies.filter(({ supplyTypeTags }) =>
    supplyTypeTags.includes(SUPPLY_TYPE_SEED)
  )

  const supplyTypeName = suppliesFiltered.length
    ? suppliesFiltered[0].supplyTypeName
    : undefined

  const totalDensity = suppliesFiltered.reduce(
    (accumulator, { total }) => accumulator + total,
    0
  )

  const unitType = suppliesFiltered.length
    ? suppliesFiltered[0].unitType
    : undefined

  const suppliesSeeds = {
    outlier: '', //WARNING || DANGER
    supplyTypeName,
    totalDensity,
    unitType,
    supplies: suppliesFiltered,
  }

  return suppliesSeeds
}

const parseSuppliesFertilizers = ({ supplies }) => {
  const suppliesFiltered = supplies.filter(({ supplyTypeTags }) =>
    supplyTypeTags.includes(SUPPLY_TYPE_FERTILIZER)
  )

  const supplyTypeName = suppliesFiltered.length
    ? suppliesFiltered[0].supplyTypeName
    : undefined

  const suppliesFertilizers = {
    outlier: '', //WARNING || DANGER
    supplyTypeName,
    supplies: suppliesFiltered,
  }

  return suppliesFertilizers
}

const parseSuppliesPhytotherapics = ({ supplies }) => {
  const suppliesFiltered = supplies.filter(({ supplyTypeTags }) =>
    supplyTypeTags.includes(SUPPLY_TYPE_PHYTOTHERAPIC)
  )

  const supplyTypeName = suppliesFiltered.length
    ? suppliesFiltered[0].supplyTypeName
    : undefined

  const suppliesPhytotherapics = {
    outlier: '', //WARNING || DANGER
    supplyTypeName,
    supplies: suppliesFiltered,
  }

  return suppliesPhytotherapics
}
