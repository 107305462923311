import supplies from './supplies'

const CROPICON = {
  corn: supplies.corn,
  sunflower: supplies.sunflower,
  wheat: supplies.wheat,
  Barley: supplies.barley,
  soy: supplies.soy,
  Colza: supplies.canola,
  cotton: supplies.cotton,
  Popcorn: supplies.popcorn,
  Oatmeal: supplies.oatmeal,
  Rye: supplies.rye,
  'Rye grass': supplies.ryegrass,
  Vetch: supplies.vetch,
  default: supplies.otherseed,
  Carinata: supplies.carinata,
  Potato: supplies.Potato,
  Rice: supplies.Rice,
  Peanut: supplies.Peanut,
  'adzuki-bean': supplies.adzukibean,
  sesame: supplies.sesame,
  'mung-bean': supplies.mungbean,
  bean: supplies.bean,
  whitecorn: supplies.whitecorn,
  hemp: supplies.hemp,
  chickpea: supplies.chickpea,
  lentil: supplies.lentil,
}

const SUPPLY = {
  SeMa: supplies.corn,
  FE: supplies.fertilizer,
  FU: supplies.fungicide,
  Otros: supplies.other,
  SeGi: supplies.sunflower,
  SeTr: supplies.wheat,
  HE: supplies.herbicide,
  SeCe: supplies.barley,
  SeSo: supplies.soy,
  SeCo: supplies.canola,
  SeCa: supplies.carinata,
  SeAl: supplies.cotton,
  SePi: supplies.popcorn,
  IN: supplies.edt,
  SeAv: supplies.oatmeal,
  SeCen: supplies.rye,
  SeRa: supplies.ryegrass,
  SeAr: supplies.vetch,
  default: supplies.otherseed,
  SeCam: supplies.camelina,
  SeVi: supplies.vicia,
  SeArr: supplies.Rice,
  SePap: supplies.Potato,
  SeMan: supplies.Peanut,
  SemPoAd: supplies.adzukibean,
  SeSes: supplies.sesame,
  SemPoMu: supplies.mungbean,
  SemPo: supplies.bean,
  SeVid: supplies.vine,
  SemNog: supplies.walnut,
  SemCeb: supplies.onion,
  SemSan: supplies.watermelon,
  SeMaB: supplies.whitecorn,
  SeCh: supplies.chia,
  SeCan: supplies.hemp,
  SeGa: supplies.chickpea,
  SeLe: supplies.lentil,
}

export { CROPICON, SUPPLY }
