import React, { useContext } from 'react'
import { View, Text, Image, StyleSheet } from 'react-native'
import { offlineEmptyImage } from '@constants/images'
import { LanguageContext } from '@contextState/language'
import { blackHighEmphasis } from '@styles/palette'

export const OfflineEmpty = () => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <Image source={offlineEmptyImage} style={styles.emptyImage} />

      <Text style={styles.title}>
        {t('COMMON.OFFLINE_EMPTY.NO_INTERNET_CONNECTION')}
      </Text>
      <Text style={styles.subTitle}>
        {t('COMMON.OFFLINE_EMPTY.AVAILABLE_CROPS_ON_OFFLINE_MODE')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  emptyImage: {
    width: 280,
    height: 220,
    marginTop: 55,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginBottom: 10,
  },

  title: {
    paddingHorizontal: 26,
    marginTop: 40,
    marginBottom: 10,
    alignSelf: 'center',
    fontSize: 20,
    color: blackHighEmphasis,
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: 23,
  },
  subTitle: {
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 24,
    color: blackHighEmphasis,
    textAlign: 'center',
    paddingHorizontal: 26,
  },
})
