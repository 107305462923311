import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  isAuth: boolean
  user: any
  config: any
  selectedProfile: {
    isAdmin: boolean
  }
  companyAllow: boolean
  companyIsSynchronized: boolean
  isCompanyUcropit: boolean
  isVerifierUcropit: boolean
  isCompanyProducer: boolean
  isPendingAuthorization: boolean
  pin: null | any
  hideWizardIntro: boolean
  welcomeCompanyList: Array<any>
}

const initialState: AuthState = {
  isAuth: false,
  user: null,
  config: null,
  selectedProfile: {
    isAdmin: true,
  },
  companyAllow: true,
  companyIsSynchronized: false,
  isCompanyUcropit: false,
  isVerifierUcropit: false,
  isCompanyProducer: false,
  isPendingAuthorization: false,
  pin: null,
  hideWizardIntro: false,
  welcomeCompanyList: [],
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthValues(state: AuthState, action: PayloadAction<any>) {
      state = {
        ...state,
        ...action.payload,
      }

      return state
    },
    resetAuthValues(state: AuthState) {
      state = initialState

      return state
    },
    setPin(state: AuthState, action: PayloadAction<{ pin: any }>) {
      state.pin = {
        ...action.payload?.pin,
      }
    },
    removePin(state: AuthState) {
      state.pin = null
    },
    setHideWizardIntro(state: AuthState, action: PayloadAction<any>) {
      state.hideWizardIntro = action.payload
    },
    setEmailWelcomeCompanyList(state: AuthState, action: PayloadAction<any>) {
      const index = state.welcomeCompanyList.findIndex(
        (item) => item.email === action.payload.email
      )

      if (index !== -1) {
        state.welcomeCompanyList[index] = action.payload
      } else {
        state.welcomeCompanyList.push(action.payload)
      }
    },
    removeEmailOfWelcomeCompanyList(
      state: AuthState,
      action: PayloadAction<string>
    ) {
      state.welcomeCompanyList = state.welcomeCompanyList.filter(
        (item) => item.id !== action.payload
      )
    },
    setIsPendingAuthorization(
      state: AuthState,
      action: PayloadAction<boolean>
    ) {
      state.isPendingAuthorization = action.payload
    },
    setUser(state: AuthState, action: PayloadAction<any>) {
      state.user = action.payload
    },
    setConfig(state: AuthState, action: PayloadAction<any>) {
      state.config = action.payload
    },
    setSelectedProfile(state: AuthState, action: PayloadAction<any>) {
      state.selectedProfile = action.payload
    },
    setIsCompanyUcropit(state: AuthState, action: PayloadAction<boolean>) {
      state.isCompanyUcropit = action.payload
    },
    setIsVerifierUcropit(state: AuthState, action: PayloadAction<boolean>) {
      state.isVerifierUcropit = action.payload
    },
    setIsCompanyProducer(state: AuthState, action: PayloadAction<boolean>) {
      state.isCompanyProducer = action.payload
    },
    setCompanyAllow(state: AuthState, action: PayloadAction<boolean>) {
      state.companyAllow = action.payload
    },
    setCompanyIsSynchronized(state: AuthState, action: PayloadAction<boolean>) {
      state.companyIsSynchronized = action.payload
    },
    setIsAuth(state: AuthState, action: PayloadAction<boolean>) {
      state.isAuth = action.payload
    },
  },
})

export const {
  setAuthValues,
  resetAuthValues,
  setPin,
  removePin,
  setHideWizardIntro,
  setEmailWelcomeCompanyList,
  removeEmailOfWelcomeCompanyList,
  setIsPendingAuthorization,
  setUser,
  setConfig,
  setSelectedProfile,
  setIsCompanyUcropit,
  setIsVerifierUcropit,
  setIsCompanyProducer,
  setCompanyAllow,
  setCompanyIsSynchronized,
  setIsAuth,
} = authSlice.actions

export const { reducer: authReducer } = authSlice
