import { View, Text, StyleSheet } from 'react-native'
import React, { useContext } from 'react'
import { primary50 } from '@styles/palette'
import { useSelector } from 'react-redux'
import {
  GlobalStateInterface,
  SelectionPageReducerInterface,
} from '@store/interfaces'
import { formatSurface } from '@common/utils'
import { LanguageContext } from '@contextState/language'

export const HeaderFieldSelection = () => {
  const {
    numItemsSelected,
    sumFieldSelected,
    items,
  }: SelectionPageReducerInterface = useSelector(
    (state: GlobalStateInterface) => state.selectionPageReducer
  )
  const { t } = useContext(LanguageContext)
  const title =
    numItemsSelected !== 1
      ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
      : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')
  return (
    <View style={styles.content}>
      <Text style={styles.text}>
        {numItemsSelected} {title}
      </Text>
      <Text style={styles.text}>
        {formatSurface(sumFieldSelected, items[0].areaUnit)}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: primary50,
    marginTop: 8,
    marginBottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
  },
})
