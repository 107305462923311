import { fileBinary as type } from '../types'

const initialState = []

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_FILE_BINARY: {
      return action.payload
    }

    case type.CLEAR_FILE_BINARY: {
      return []
    }

    default:
      return state
  }
}

export default reducer
