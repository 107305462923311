import { groupBy, round } from 'lodash'
import moment from 'moment'

export const getNotificationsDTO = ({
  currentNotifications,
  newNotifications,
}) => {
  newNotifications.map((element) => {
    element.date = moment(element.createdAt).startOf('day').toDate()

    return element
  })

  if (!newNotifications.length) {
    return currentNotifications
  }

  const newNotificationsGrouped = groupBy(newNotifications, 'date')

  const dates = Object.keys(newNotificationsGrouped)

  const newNotificationsDTO = []

  for (const date of dates) {
    const notifications = newNotificationsGrouped[date].map((notification) => {
      let achievement

      if (notification.achievement) {
        achievement = {
          _id: notification.achievement._id,
          surface: round(notification.achievement.surface, 2),
        }
      }
      const outlierSowingLevel = notification.outlierSowingLevel
      const outlierHarvestLevel = notification.outlierHarvestLevel
      const outlierFertilizerLevel = notification.outlierFertilizerLevel
      const outlierActiveIngredientLevel =
        notification.outlierActiveIngredientLevel

      return {
        _id: notification._id,
        type: notification.type,
        isRead: notification.isRead,
        realizedAt: notification.realizedAt,
        areaUnit: notification.areaUnit,
        activity: {
          _id: notification.activity._id,
          surface: round(notification.activity.surface, 2),
          type: notification.activity.type,
        },
        achievement,
        crop: {
          _id: notification.crop._id,
          name: notification.crop.name,
        },
        farms: notification.farms.map((farm) => ({
          _id: farm._id,
          name: farm.name,
          surface: round(farm.surface, 2),
          areaUnit: farm.areaUnit,
          fields: farm.fields.map((field) => ({
            _id: field._id,
            surface: round(field.surface, 2),
            areaUnit: field.areaUnit,
          })),
        })),
        createdAt: notification.createdAt,
        updatedAt: notification.updatedAt,
        outlierSowingLevel,
        outlierHarvestLevel,
        outlierFertilizerLevel,
        outlierActiveIngredientLevel,
      }
    })

    newNotificationsDTO.push({
      date,
      data: notifications,
    })
  }

  let notificationsDTO = []

  if (currentNotifications.length) {
    const lastDateCurrentNotification =
      currentNotifications[currentNotifications.length - 1].date
    const firstDateNewNotification = newNotificationsDTO[0].date

    if (lastDateCurrentNotification === firstDateNewNotification) {
      currentNotifications[currentNotifications.length - 1] = {
        ...currentNotifications[currentNotifications.length - 1],
        data: [
          ...currentNotifications[currentNotifications.length - 1].data,
          ...newNotificationsDTO[0].data,
        ],
      }

      newNotificationsDTO.pop()
    }

    notificationsDTO = [...currentNotifications, ...newNotificationsDTO]
  } else {
    notificationsDTO = newNotificationsDTO
  }

  return notificationsDTO
}
