import _ from 'lodash'
import { statusSubLicenses } from '@utils/constants'

export const isAppliedLots = (lotsSelected, lotsApplied) => {
  const diffLots = _.difference(lotsSelected, lotsApplied)
  if (diffLots.length === 0 && lotsApplied.length > 0) {
    return statusSubLicenses.DISABLED
  } else if (diffLots.length > 0 && lotsApplied.length > 0) {
    return statusSubLicenses.IS_APPLIED
  }
  return ''
}
