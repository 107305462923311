import React, { useContext, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useFormik } from 'formik'
import { LanguageContext } from '@contextState/language'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { white, blackMediumEmphasis, primary500, black } from '@styles/palette'
import { errorsCognito } from '@utils/constants'
import { CodeForm } from './components/CodeForm'
import { getFormikInitialValues } from './utils/getFormikInitialValues'
import { getFormikValidationSchema } from './utils/getFormikValidationShema'
import { useAuth } from '@common/hooks'

const CognitoCode = ({ route }) => {
  const { t } = useContext(LanguageContext)
  const { user } = useAuth()
  const [formikInitialValues] = useState(getFormikInitialValues())
  const { params } = route
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: getFormikValidationSchema(t),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      submitForm(values, setSubmitting)
    },
  })

  const { handleSubmit, dirty, isValid, isSubmitting, setErrors } = formik

  const submitForm = async (valuesForm, setSubmitting) => {
    const { callback } = params
    if (callback) {
      try {
        await callback(valuesForm, formik)
        return true
      } catch (error) {
        if (error.code === errorsCognito.USER_LAMBDA_VALIDATION_EXCEPTION) {
          setErrors({ code: t('VIEWS.COGNITO_CODE.CODE_INCORRECT') })
        } else {
          setErrors({ code: error?.message })
        }
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <View style={styles.content}>
      <Text style={styles.textTittle}>
        {t('VIEWS.COGNITO_CODE.SECRET_LOGIN_CODE')}
      </Text>
      <Text style={styles.textInfo}>
        {t('VIEWS.COGNITO_CODE.PARAGRAPH_ENTER_YOU_SECRET_CODE')}
        <Text style={styles.textInfoBold}>{user.email}</Text>
      </Text>
      <CodeForm formik={formik} t={t} />
      <Text style={styles.subText}>
        {t('VIEWS.COGNITO_CODE.IF_HAVENT_RECEIVED_EMAIL')}
      </Text>
      <View style={styles.contentBottom}>
        <ButtonDefault
          typeButton={
            dirty && isValid && !isSubmitting ? 'primary' : 'disabled'
          }
          text={t('VIEWS.COGNITO_CODE.LOGIN')}
          onPress={handleSubmit}
          disabled={!(dirty && isValid)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 16,
    paddingTop: 30,
    paddingBottom: 44,
    backgroundColor: white,
    flex: 1,
  },
  textTittle: {
    fontWeight: '700',
    fontSize: 24,
    marginBottom: 16,
    lineHeight: 28,
    color: black,
  },
  textInfo: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    marginBottom: 28,
  },
  textInfoBold: {
    fontWeight: '700',
  },
  contentBottom: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  subText: {
    color: blackMediumEmphasis,
    fontWeight: '400',
    marginTop: 18,
    fontSize: 12,
    lineHeight: 18,
    letterSpacing: 0.25,
  },
  link: {
    color: primary500,
  },
})
export default CognitoCode
