import React from 'react'
import { PropTypes } from 'prop-types'
import { ActivityIcon } from '@modules/common/components'
import { black } from '@modules/common/styles'

const IconActivity = ({ name, color, size, direction }) => {
  return (
    <ActivityIcon
      name={name.toUpperCase()}
      color={color || black}
      size={size}
      direction={direction}
    />
  )
}

IconActivity.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  direction: PropTypes.string,
}

export default IconActivity
