import React from 'react'
import { PropTypes } from 'prop-types'

import {
  GeneralInfoCard,
  ActivityCard,
  YieldCard,
  ObservationCard,
  EvidenceCard,
  CollaboratorCard,
} from '..'

export const Monitoring = ({
  cropName,
  cropType,
  companyName,
  fieldsQuantity,
  surface,
  unitArea,
  dateHarvest,
  dateMonitoring,
  unitTypeName,
  unitTypeKey,
  pay,
  observation,
  evidences,
  collaboratorsQuantity,
}) => {
  return (
    <>
      <GeneralInfoCard cropName={cropName} companyName={companyName} />

      <ActivityCard
        fieldsQuantity={fieldsQuantity}
        surface={surface}
        unitArea={unitArea}
        dateHarvest={dateHarvest}
        dateMonitoring={dateMonitoring}
      />

      <YieldCard
        unitTypeName={unitTypeName}
        unitTypeKey={unitTypeKey}
        pay={pay}
        cropType={cropType}
      />

      <ObservationCard observation={observation} />

      <EvidenceCard evidences={evidences} />

      <CollaboratorCard collaboratorsQuantity={collaboratorsQuantity} />
    </>
  )
}

Monitoring.propTypes = {
  cropName: PropTypes.string.isRequired,
  cropType: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  fieldsQuantity: PropTypes.number.isRequired,
  surface: PropTypes.number.isRequired,
  unitArea: PropTypes.string.isRequired,
  dateHarvest: PropTypes.string.isRequired,
  unitTypeName: PropTypes.string.isRequired,
  unitTypeKey: PropTypes.string.isRequired,
  pay: PropTypes.number.isRequired,
  observation: PropTypes.string.isRequired,
  evidences: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      uploadedBy: PropTypes.string.isRequired,
      uploadedAt: PropTypes.string.isRequired,
      filePath: PropTypes.string,
    })
  ),
  collaboratorsQuantity: PropTypes.number.isRequired,
  isValueOutsideMeanHarvest: PropTypes.bool,
  canShowOutlierHarvest: PropTypes.bool,
  changeOutLierHarvest: PropTypes.func,
  statusOutlier: PropTypes.string,
}
