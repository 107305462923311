import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
} from 'react-native'
import { PropTypes } from 'prop-types'
import {
  grayBackground,
  blackHighEmphasis,
  blackMediumEmphasis,
} from '@styles/palette'
import licenseStates from '@constants/licenseStates'

export const ItemLicense = ({ license, t, onPress }) => {
  const backgroundLicense = licenseStates[license?.state]?.background
    ? { backgroundColor: licenseStates[license.state].background }
    : {}

  return (
    <TouchableOpacity onPress={onPress} style={styles.containerItem}>
      <ImageBackground
        source={{ uri: license.image }}
        style={styles.licenseImage}
      >
        {licenseStates[license.state] && (
          <View style={[styles.contentInfoLicense, backgroundLicense]}>
            <Text style={licenseStates[license.state].styleText}>
              {licenseStates[license.state]?.name(t)}
            </Text>

            {licenseStates[license.state].icon}
          </View>
        )}
      </ImageBackground>

      <View style={styles.contentDescription}>
        <Text style={styles.licenseName} numberOfLines={2}>
          {license.name}
        </Text>

        <Text style={styles.licenseDescription} numberOfLines={2}>
          {license.previewDescription}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  containerItem: {
    flex: 0.485,
  },
  licenseImage: {
    height: 168,
    resizeMode: 'contain',
  },
  contentDescription: {
    padding: 16,
    backgroundColor: grayBackground,
  },
  licenseName: {
    fontSize: 18,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  licenseDescription: {
    fontSize: 14,
    fontWeight: '400',
    color: blackMediumEmphasis,
    marginTop: 8,
  },
  contentInfoLicense: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
  },
})

ItemLicense.propTypes = {
  license: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
}
