import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { View, Text, StyleSheet } from 'react-native'

import { grayDA, blackMediumEmphasis } from '@styles/palette'

import { LanguageContext } from '@contextState/language'

export const EmptyStateInput = ({ name }) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.contentData}>
      <View style={styles.item}>
        <Text style={styles.itemText} numberOfLines={1} ellipsizeMode='tail'>
          {t(
            'VIEWS.NOTIFICATION_FILTER.COMPONENTS.EMPTY_STATE_INPUT.NO_RESULTS',
            {
              name: name,
            }
          )}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentData: {
    borderWidth: 1,
    borderColor: blackMediumEmphasis,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 18,
    marginHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: grayDA,
  },
  itemText: {
    flex: 1,
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    color: blackMediumEmphasis,
    fontStyle: 'italic',
  },
})

EmptyStateInput.prototype = {
  name: PropTypes.string.isRequired,
}
