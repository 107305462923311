import React, { useState, useEffect, useContext } from 'react'
import { Text, View, StyleSheet, Platform, Dimensions } from 'react-native'
import { Field, Formik } from 'formik'
import { Button, List, Title } from 'react-native-paper'
import CustomDatePicker from './CustomDatePicker'
import { CommonContext } from '@contextState/common'
import Select from './Select'
import InputFileUpload from '../inputs/InputFileUpload'
import { LanguageContext } from '@contextState/language'
import { ALLOW_IMAGES_FORMAT } from '@constants/common'
import ACTIVITY_TYPES from '@constants/activityTypes'
import ModalCustom from './ModalCustom'
import { CommonIcon } from '@modules/common/components'
import { EEvidenceConcepts } from '@common/enum'
import ButtonCustom from './ButtonCustom'

const { width } = Dimensions.get('window')
const WIDTH_MODAL_EVIDENCE = 600
const WITH_MODAL_RESPONSIVE =
  width <= WIDTH_MODAL_EVIDENCE ? width - 50 : WIDTH_MODAL_EVIDENCE

function ModalEvidence({
  visible,
  handleClose,
  onSelect,
  setFieldValue,
  allowedFormats,
  tag,
  itemSelected,
  filterEvidences,
}) {
  const { t } = useContext(LanguageContext)
  const { evidenceConcept } = useContext(CommonContext)

  const DEFAULT_ALLOW_FORMAT = `.doc,.docx,.pdf,.txt,.zip,${ALLOW_IMAGES_FORMAT}`

  const [isSettingValue, setIsSettingValue] = useState(false)

  const getAccepts = (allowedFormats, values) => {
    return allowedFormats
      ? allowedFormats.join(',')
      : values.description !== t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_5
      ? DEFAULT_ALLOW_FORMAT
      : ALLOW_IMAGES_FORMAT
  }

  useEffect(() => {
    if (!visible) {
      return
    }

    setIsSettingValue(false)
  }, [visible])

  function handleFileSubmit(values) {
    if (isSettingValue) {
      return
    }

    setIsSettingValue(true)
    const evidence = evidenceConcept.find(
      (evidence) => evidence.value === values.description
    )
    values.evidenceConcept = evidence?._id
    handleClose()
    onSelect({
      values,
      file: values.file,
      location: values.location || {},
      setFieldValue,
    })
  }

  const getOptions = () => {
    if (tag === ACTIVITY_TYPES.ACT_IRRIGATION.key) {
      return evidenceConcept.filter(
        (evidenceType) => evidenceType.code === EEvidenceConcepts['0002']
      )
    }
    if (tag !== ACTIVITY_TYPES.ACT_AGREEMENTS.key) {
      const listWithoutTag = evidenceConcept.filter(
        (item) => !item.tagActivityType
      )
      const listWithTag = evidenceConcept.filter(
        (item) => item.tagActivityType === tag
      )
      return [...listWithoutTag, ...listWithTag]
    }
    let evidences = evidenceConcept.filter((evidence) =>
      itemSelected.evidenceTypesAllowed.find(({ _id }) => _id === evidence._id)
    )
    if (filterEvidences) {
      evidences = filterEvidences(evidences)
    }

    return evidences
  }

  const renderForm = () => (
    <Formik
      initialValues={{ description: '', date: new Date() }}
      onSubmit={handleFileSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <>
            <Title style={styles.modalTitle}>
              {t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_1}
            </Title>
            <Field
              name='description'
              component={Select}
              label={t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_3}
              options={getOptions()}
              validate={(value) =>
                !value && t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_2
              }
              extraFunction={(valueDescription, list) => {
                setFieldValue('file', null)
                setFieldValue('location', null)
                const evidenceTypeSelected = list.find(
                  (item) => item.label === valueDescription
                )
                setFieldValue('evidenceTypeSelected', evidenceTypeSelected)
              }}
            />
            <Field
              component={CustomDatePicker}
              name='date'
              placeholder={t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_4}
              label={t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_4}
              disablePast={false}
            />

            {values.evidenceTypeSelected ? (
              <InputFileUpload
                showLocation={values.evidenceTypeSelected.code === '0004'}
                nameInputError='description'
                showCameraRollOption
                accept={getAccepts(allowedFormats, values)}
                setFieldValue={setFieldValue}
                isPictureOnlyPicture={
                  values.evidenceTypeSelected.code === '0004'
                }
                cancelOnPress={handleClose}
              >
                <View style={styles.fileButton}>
                  <Text style={styles.textButton}>
                    {t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_6}
                  </Text>
                </View>
              </InputFileUpload>
            ) : null}

            {values.file && (
              <List.Item
                title={values.file.name}
                left={() => <CommonIcon name='FILE-UPLOAD' size={20} />}
              />
            )}
            <View style={styles.actions}>
              <Button onPress={handleClose}>
                {t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_7}
              </Button>

              <ButtonCustom
                disabled={!values.file}
                onPress={handleSubmit}
                mode='contained'
                style={!values.file ? styles.buttonDisabled : styles.button}
                labelStyle={
                  !values.file ? styles.textButtonDisabled : styles.textButton2
                }
              >
                {t('COMPONENTS').COMMON.MODAL_EVIDENCE.TEXT_8}
              </ButtonCustom>
            </View>
          </>
        )
      }}
    </Formik>
  )

  return (
    <ModalCustom
      visible={visible}
      hideModal={handleClose}
      style={Platform.OS === 'web' ? styles.modalEvidence : undefined}
    >
      {renderForm()}
    </ModalCustom>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    marginBottom: 8,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
  },
  container: {
    padding: 16,
    flex: 1,
  },
  headerText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  textButton: {
    color: 'green',
    textAlign: 'center',
    padding: 4,
  },
  fileButton: {
    borderRadius: 2,
    padding: 4,
    fontWeight: '400',
  },
  button: {
    backgroundColor: '#4CAF50',
  },
  buttonDisabled: {
    backgroundColor: '#81C784',
  },
  textButtonDisabled: {
    color: 'rgba(255, 255, 255, .5)',
  },
  textButton2: {
    color: 'white',
  },
  modalEvidence: {
    width: WITH_MODAL_RESPONSIVE,
    marginLeft: (width - WITH_MODAL_RESPONSIVE) / 2,
    backgroundColor: 'white',
  },
})

export default ModalEvidence
