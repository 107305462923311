import { FC, useContext, ReactElement } from 'react'
import { StyleSheet } from 'react-native'

import { LanguageContext } from '@contextState/language'
import InputText from '@components/inputs/InputText'
import { CommonIcon } from '@modules/common/components'
import { truncateSurface } from '@common/utils/truncateSurface'
import { SIZES } from '@common/components/icons/interfaces'
import { parentTypes } from '@common/constants'

export const StatementDefaultForm: FC<any> = ({
  onPressLotsInput,
  formik,
  areaUnit,
}: any): ReactElement => {
  const { t } = useContext(LanguageContext)

  const { values, setFieldTouched, touched, errors } = formik

  const labelLots =
    values?.typeAgreement?.parentType === parentTypes.CROP_FARM
      ? t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')
      : t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_2.PLACEHOLDER')

  return (
    <>
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={SIZES.MEDIUM}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={labelLots}
        label={labelLots}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />
    </>
  )
}

StatementDefaultForm.displayName = 'StatementDefaultForm'

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
})
