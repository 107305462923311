import React from 'react'
import { View, StyleSheet, Image, ScrollView } from 'react-native'
import { Card, Title, Paragraph, Text } from 'react-native-paper'
import { PropTypes } from 'prop-types'
import { blackHighEmphasis, whiteLight } from '@styles/palette'

export const Details = ({ license }) => {
  return (
    <ScrollView style={styles.detailsView}>
      <Title style={styles.titleLicense}>{license.name}</Title>

      <Paragraph style={styles.descriptionLicense}>
        {license.previewDescription}
      </Paragraph>

      {license.clauses.map((clause, key) => (
        <View key={key} style={styles.detailedView}>
          <Image style={styles.image} source={{ uri: clause.image }} />
          <View style={styles.container}>
            <Text style={styles.textTitle}>{clause.title}</Text>

            <Paragraph style={styles.paragraph}>{clause.description}</Paragraph>
          </View>
        </View>
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  detailsView: {
    backgroundColor: whiteLight,
  },
  container: {
    flex: 1,
  },
  titleLicense: {
    fontSize: 20,
    marginTop: 10,
    marginLeft: 15,
  },
  descriptionLicense: {
    fontSize: 14,
    color: blackHighEmphasis,
    fontWeight: '400',
    marginLeft: 15,
  },
  textTitle: {
    fontSize: 20,
  },
  paragraph: {
    fontSize: 14,
    color: blackHighEmphasis,
  },
  detailedView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'whiteLight',
    padding: 16,
  },
  cardContent: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
  },
  image: {
    marginTop: 5,
    marginRight: 15,
    height: 120,
    width: 120,
  },
})

Details.propTypes = {
  license: PropTypes.object,
}
