import React, { useCallback, useContext, useState } from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { PropTypes } from 'prop-types'
import { Subheading, Divider } from 'react-native-paper'
import useNetwork from '@utils/network'
import { CommonContext } from '@contextState/common'
import IntegrationsList from '@components/integrations/IntegrationsList'
import { LanguageContext } from '@contextState/language'
import networkAdapter from '../modules/common/utils/adapters/networkAdapter'
import { useAuth } from '@common/hooks'

function CompanyIntegrationAppListScreen({ route, navigation }) {
  const { doRequest: origianlDoRequest, loading } = useNetwork()
  const { doRequest } = networkAdapter(origianlDoRequest)
  const { selectedProfile } = useAuth()
  const [integrations, setIntegration] = useState([])
  const { servicesIntegration } = useContext(CommonContext)
  const { t } = useContext(LanguageContext)

  const fetchData = useCallback(async (id) => {
    try {
      const response = await doRequest({
        method: 'GET',
        url: `companies/integrations/${id}`,
        // useMock: true,
        // identifier: 'companies/integrations/show',
      })

      setIntegration(response.data)
    } catch (error) {
      console.error(error)
    }
  })

  function filterService(services, listIntegrations) {
    if (!listIntegrations.length > 0) {
      return services
    }

    return services.filter((item) =>
      listIntegrations.find((el) => el.service !== item.value)
    )
  }

  function onPressNavigate(listConnected, service) {
    let screenNavigate = ''
    if (!listConnected) {
      screenNavigate = 'CompanyIntegrationAgreement'
    } else {
      screenNavigate = 'CompanyServiceIntegrationAccountSelector'
    }
    return navigation.navigate(screenNavigate, {
      id: route.params.id,
      service: service,
    })
  }

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      await fetchData(selectedProfile.company._id)
    })

    return unsubscribe
  }, [navigation])

  if (loading) {
    return (
      <View style={styles.emptyContainer}>
        <ActivityIndicator />
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <View>
        <Subheading style={styles.title}>
          {t('VIEWS').COMPANY_INTEGRATION_APP_LIST.TEXT_1}
        </Subheading>
        <Divider />
        <React.Fragment>
          <IntegrationsList
            list={integrations}
            isIntegrated={true}
            onPress={onPressNavigate}
          />
          <Divider />
        </React.Fragment>
        <Subheading style={styles.title}>
          {t('VIEWS').COMPANY_INTEGRATION_APP_LIST.TEXT_2}
        </Subheading>
        <Divider />
        <React.Fragment>
          <IntegrationsList
            list={filterService(servicesIntegration, integrations)}
            isIntegrated={false}
            onPress={onPressNavigate}
          />
          <Divider />
        </React.Fragment>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    marginBottom: 25,
    justifyContent: 'space-between',
  },
  title: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'flex-start',
    fontWeight: 'bold',
  },
})

CompanyIntegrationAppListScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CompanyIntegrationAppListScreen
