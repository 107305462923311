export default {
  VALIDATED: 'VALIDATED',
  TEXT_1: 'Executed Area: %{surface} %{areaUnit}',
  TEXT_2: 'Type of %{activityType}: %{subTypeActivity}',
  TEXT_3: 'Generated EIQ',
  TEXT_4: 'INPUTS',
  TEXT_5: 'SEE DETAIL',
  TEXT_6: 'Draft',
  TEXT_7: 'Draft: Version',
}
