export default {
  PRODUCER_ADVISER: 'Consultant',
  PRODUCER_ADVISER_ENCOURAGED: 'Consultant promoter',
  PRODUCER: 'Producer',
  PROVIDER: 'Supplier',
  MARKETER: 'Merchant',
  CONTACT_REPRESENTATIVE: 'Commercial owner',
  CAM: 'KAM',
  KAM: 'KAM',
  VERIFIER: 'Verificador',
}
