import COMPANY_DETAILS from '../../components/CompanyDetails/translate/pt'
import LOTS_LIST from '../../components/LotsList/translate/pt'
import KMZ_ADD_BUTTON from '../../components/KmzAddButton/translate/pt'
import CREATE_FARM_BUTTON from '../../components/CreateFarmButton/translate/pt'
import MODAL_SUCCESS from '../../components/ModalSuccess/translate/pt'

export default {
  TEXT_1:
    'O arquivo que você está tentando carregar não é um tipo de formato KMZ ou foi danificado.',
  TEXT_2:
    'Não foi possível criar o campo. Por favor, tente novamente. Se o erro persistir, entre em contato com o suporte.',
  TEXT_3: 'Voltar',
  TEXT_4: 'Erro de lote',
  COMPONENTS: {
    COMPANY_DETAILS,
    LOTS_LIST,
    KMZ_ADD_BUTTON,
    CREATE_FARM_BUTTON,
    MODAL_SUCCESS,
  },
}
