export default {
  PLURAL_DAYS: 'días',
  SINGULAR_DAYS: 'día',
  HARVESTED_AT: 'Cosechada hace %{daysQuantity} %{daysWord}',
  MONITORED_AT: 'Monitoreada hace %{daysQuantity} %{daysWord}',
  EXECUTED_AT: 'Realizada a campo hace %{daysQuantity} %{daysWord}',
  MESSAGE_1: 'Tienes una ',
  MESSAGE_2: 'validación pendiente ',
  MESSAGE_3: 'en la ',
  MESSAGE_4: 'actividad %{activityType} ',
  MESSAGE_5: 'de %{surface} %{areaUnit}',
  SINGULAR_FIELDS: 'lote',
  PLURAL_FIELDS: 'lotes',
  VERIFY_VALUE_MESSAGE: 'Revisa los valores ingresados',
}
