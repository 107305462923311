export default {
  COUNTRY: 'País',
  COMPANY_NAME: 'Razão social',
  COMPANY_TYPE: 'Personalidade Jurídica',
  ADDRESS: 'Endereço',
  SPECIFIC_ADDRESS: 'Andar/Apartamento/ Escritório',
  ADVSER_RECOMENDATION:
    'Você deve adicionar como colaboradores um *produtor* *administrador* e um *consultor* *responsável* para poder finalizar a criação da empresa.',
  SAVE_COMPANY: 'Criar empresa',
}
