export default {
  TEXT_1: 'O arquivo não tem formato KMZ',
  TEXT_2: 'Superficie',
  TEXT_3: 'ha',
  TEXT_4: 'Nenhum lote carregado ainda.',
  TEXT_5: 'Os lotes são carregados por upload',
  TEXT_6: 'arquivos .KMZ',
  TEXT_7: 'kmz',
  TEXT_8: 'Nome do estabelecimento',
  TEXT_9: 'Insire nome do estabelecimento',
  TEXT_10: 'Subir kmz de lotes',
  TEXT_11: 'Salvar',
}
