import React, { useContext } from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { SUPPLY } from '@constants/iconGuide'
import { carbon400, carbon600, blackHighEmphasis } from '@styles/palette'
import { EActivityTypes } from '@common/enum'
import { SIZES } from '@common/components/icons/interfaces'
import { IconBox } from '@modules/supplies/components'

export const Supply = ({
  unitArea,
  code,
  name,
  company,
  quantity,
  total,
  unitType,
  eiqTotal,
  isGeneric,
}) => {
  const { t } = useContext(LanguageContext)

  const supplyImagePath = SUPPLY[code] || SUPPLY['default']

  const supplyDescription = `${quantity} ${unitType}/${unitArea} - ${total} ${unitType}`

  const SupplyIcon = () => {
    if (!isGeneric) {
      return <Image style={styles.supplyImage} source={supplyImagePath} />
    }

    return (
      <IconBox
        iconName={EActivityTypes.ACT_FERTILIZATION}
        size={SIZES.MEDIUM}
        color={carbon600}
        style={styles.supplyIcon}
      />
    )
  }

  return (
    <View style={styles.supplyContainer}>
      <SupplyIcon />

      <View style={styles.textContainer}>
        <Text style={styles.textBold}>{name}</Text>

        <Text style={styles.text}>{company}</Text>

        <Text style={styles.text}>{supplyDescription}</Text>

        {Boolean(eiqTotal) && (
          <Text style={styles.text}>
            {t('VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.SUPPLY.EIQ_LABEL', {
              eiq: eiqTotal,
            })}
          </Text>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  supplyContainer: {
    flexDirection: 'row',
    marginTop: 24,
  },
  supplyImage: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
    borderRadius: 4,
    marginRight: 15,
    marginTop: 3.5,
  },
  supplyIcon: {
    width: 50,
    height: 50,
    borderRadius: 4,
    marginRight: 15,
    marginTop: 3.5,
  },
  textContainer: {
    flex: 1,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
  },
})

Supply.propTypes = {
  unitArea: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  unitType: PropTypes.string.isRequired,
  eiqTotal: PropTypes.number,
}
