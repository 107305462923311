import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { Card, Text } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { GET_ROLES } from '@constants/roles'
import { MENU_COLLABORATOR_NEW } from '@constants/menuCollaborator'

function CollaboratorCardDetails({
  title,
  subtitle,
  companyName,
  pendingValidation,
  collaboratorType,
  disabled,
  status,
  tag,
  notfound = false,
  delegating = false,
  style = styles.card,
  cardSuccess = false,
  ...props
}) {
  const { t } = useContext(LanguageContext)

  const getStyleItem = () =>
    GET_ROLES[collaboratorType](t) ? styles.menuItemTitle : styles.menuItem

  const getTitleIcon = () =>
    pendingValidation
      ? `${pendingValidation} ${t('VIEWS').CROP_COLLABORATOR.TEXT_16}`
      : `${pendingValidation} ${t('VIEWS').CROP_COLLABORATOR.TEXT_17}`

  const getStyleCard = () => (!status ? styles.opacity05 : styles.opacity1)

  return (
    <View>
      <Card disabled={disabled} style={style}>
        {tag && (
          <Text style={styles.roleText}>{MENU_COLLABORATOR_NEW[tag](t)}</Text>
        )}
        {!status && (
          <Text style={styles.roleTextDisabled}>
            {t('VIEWS').CROP_COLLABORATOR.TEXT_15}
          </Text>
        )}
        <Card.Title
          title={title}
          style={styles.titleFrame}
          rightStyle={getStyleItem()}
          titleStyle={[getStyleCard(), props.titleStyle]}
          subtitleStyle={styles.subTitle}
          subtitleNumberOfLines={3}
          subtitle={`${subtitle} - ${companyName}`}
        />
      </Card>

      {!cardSuccess && status && !!pendingValidation && (
        <Text style={styles.pendingSignatures}>
          <CommonIcon
            name='SIGN'
            size={20}
            color='#FF7043'
            style={styles.iconSize}
          />

          {getTitleIcon()}
        </Text>
      )}

      {!delegating && (
        <Text style={styles.text}>
          {notfound
            ? `${t('VIEWS').CROP_COLLABORATOR.MODAL_ACTION_SHEET.TEXT_2}`
            : `${t('VIEWS.CROP_COLLABORATOR.MODAL_ACTION_SHEET.TEXT_3', {
                rol: subtitle,
              })}`}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  cardStyles: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  card: {
    marginBottom: 8,
    padding: 12,
    backgroundColor: '#F8F8F8',
  },
  roleText: {
    backgroundColor: '#edf7ee',
    color: '#388E3C',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 16,
    letterSpacing: 1.5,
    textAlign: 'left',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  roleTextDisabled: {
    backgroundColor: '#FBE9E7',
    color: 'rgba(51, 51, 51, 0.7)',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 16,
    letterSpacing: 1.5,
    textAlign: 'left',
    alignSelf: 'flex-start',
  },
  title: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 0.15,
    textAlign: 'left',
  },
  menuItemTitle: {
    marginTop: -60,
    marginRight: -10,
  },
  menuItem: {
    marginTop: -30,
    marginRight: -10,
  },
  titleFrame: {
    paddingLeft: 0,
    minHeight: 20,
  },
  subtitle: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.25,
    textAlign: 'left',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  pendingSignatures: {
    paddingTop: 5,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    textAlign: 'left',
    color: '#FF7043',
  },
  getLoading: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 2,
  },
  text: {
    paddingVertical: 10,
  },
  opacity05: {
    opacity: 0.5,
    fontSize: 16,
  },
  opacity1: {
    opacity: 1,
    fontSize: 16,
  },
  iconSize: {
    marginRight: 16,
  },
  subTilte: {
    fontSize: 14,
  },
})
CollaboratorCardDetails.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  pendingValidation: PropTypes.number,
  collaboratorType: PropTypes.string,
  disabled: PropTypes.bool,
  status: PropTypes.bool,
  notfound: PropTypes.bool,
  delegating: PropTypes.bool,
  style: PropTypes.object,
  tag: PropTypes.string,
  cardSuccess: PropTypes.bool,
}

export default CollaboratorCardDetails
