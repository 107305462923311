import { FC, useContext, ReactElement, useEffect } from 'react'
import { StyleSheet, View, Text } from 'react-native'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import InputText from '@components/inputs/InputText'
import { CommonIcon } from '@modules/common/components'
import { truncateSurface } from '@common/utils/truncateSurface'
import { SIZES } from '@common/components/icons/interfaces'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { EEvidenceConcepts } from '@common/enum'
import {
  black,
  green100,
  green200,
  green300,
  green500,
  green700,
  gray300,
  gray500,
} from '@common/styles'
import { findEvidenceCft } from '../utils'

export const StatementCftForm: FC<any> = ({
  onPressLotsInput,
  formik,
  areaUnit,
  navigateToCftForm,
  readOnly,
  dontShowGenerateCft,
  setDontShowGenerateCft,
}: any): ReactElement => {
  const { t } = useContext(LanguageContext)
  const { evidenceConcept: evidenceConcepts, evidenceCft }: any =
    useContext(CommonContext)

  const { values, setFieldValue, setFieldTouched, touched, errors } = formik

  const evidenceConceptName = evidenceConcepts.find(
    ({ code }: any) => code === EEvidenceConcepts['0013']
  ).label

  const RenderCftContainer = () => {
    if (readOnly || dontShowGenerateCft) {
      return null
    }

    return !values.dataForCFT ? (
      <View style={styles.generateEmissionBeforeSendContainer}>
        <Text style={styles.generateEmissionBeforeSendTitle}>
          {t('VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.EMISSION_TITLE')}
        </Text>

        <Text style={styles.generateEmissionBeforeSendText}>
          {t(
            'VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.EMISSION_TEXT_BEFORE_SEND'
          )}
        </Text>

        <ButtonPrimary
          text={t('VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.EMISSION_BUTTON')}
          onPress={navigateToCftForm}
          backgroundColor={'transparent'}
          disabled={Boolean(!values.lots?.value?.length)}
          colorText={values.lots?.value?.length ? green500 : green300}
          marginTop={8}
        />
      </View>
    ) : (
      <View style={styles.generateEmissionAfterSendContainer}>
        <View style={styles.generateEmissionAfterSendIconContainer}>
          <CommonIcon name='CLOCK' size={SIZES.MEDIUM} />
        </View>

        <View style={styles.generateEmissionAfterSendTextContainer}>
          <Text style={styles.generateEmissionAfterSendTitle}>
            {evidenceConceptName}
          </Text>

          <Text style={styles.generateEmissionAfterSendText}>
            {t(
              'VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.EMISSION_TEXT_AFTER_SEND'
            )}
          </Text>
        </View>
      </View>
    )
  }

  useEffect(() => {
    const cftEvidence = findEvidenceCft(values.evidences, evidenceCft)
    if (!cftEvidence) {
      setDontShowGenerateCft(false)
    } else {
      setDontShowGenerateCft(true)
    }
  }, [values.evidences])

  return (
    <>
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={SIZES.MEDIUM}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.FIELD_1.PLACEHOLDER'
        )}
        label={t('VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.FIELD_1.LABEL')}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.FIELD_2.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.STATEMENT_CFT_FORM.FIELD_2.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <RenderCftContainer />
    </>
  )
}

StatementCftForm.displayName = 'StatementCftForm'

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
  generateEmissionBeforeSendContainer: {
    borderColor: green200,
    borderWidth: 1,
    backgroundColor: green100,
    borderRadius: 8,
    padding: 24,
    marginVertical: 8,
  },
  generateEmissionBeforeSendTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: green700,
  },
  generateEmissionBeforeSendText: {
    fontSize: 14,
    fontWeight: '400',
    color: green700,
    marginTop: 8,
  },
  generateEmissionAfterSendContainer: {
    flexDirection: 'row',
    paddingTop: 24,
    paddingHorizontal: 12,
    marginVertical: 8,
  },
  generateEmissionAfterSendIconContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 55,
  },
  generateEmissionAfterSendTextContainer: {
    borderBottomColor: gray300,
    borderBottomWidth: 1,
    paddingBottom: 24,
    flex: 1,
  },
  generateEmissionAfterSendTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: black,
  },
  generateEmissionAfterSendText: {
    fontSize: 14,
    fontWeight: '400',
    color: gray500,
    marginTop: 8,
  },
})
