export default {
  TEXT_1: 'AGREGAR INSUMO',
  TEXT_2: 'AGREGAR EVIDENCIA',
  TEXT_3: 'EIQ realizado',
  TEXT_4: 'VALIDAR',
  TEXT_5:
    'Esta entrada está siendo editada por otro colaborador (%{userFullname}).',
  TEXT_6: 'GUARDAR BORRADOR',
  TEXT_7: 'por %{user} el %{date} a las %{hour} hs.',
  TEXT_8: 'Borrador: versión %{version}',
  MODAL_CONFIRM: {
    TEXT_1: '¿Salir sin guardar?',
    TEXT_2: 'No se conservarán los cambios.',
    TEXT_3: 'SALIR',
    TEXT_4: 'PERMANECER',
  },
  MODAL_DELETE: {
    TEXT_1: '¿Eliminar Borrador?',
    TEXT_2: 'Las evidencias subidas se eliminarán definitivamente.',
    TEXT_3: 'CONSERVAR',
    TEXT_4: 'ELIMINAR',
  },
  MODAL_VALIDATE: {
    TEXT_1: '¿Validar esta versión del borrador?',
    TEXT_2: 'Existen otras versiones de este mismo registro',
    TEXT_3: 'CANCELAR',
    TEXT_4: 'VALIDAR',
  },
}
