import { View, StyleSheet } from 'react-native'
import React from 'react'
import { gray200, gray800 } from '@common/styles'
import { carbon800, white } from '@styles/palette'

import { InputChemicalText } from '../InputChemicalText'
import AccordionItem from '@components/common/v2/AccordionItem'
import { InputChemicalMultiCompound } from '../InputChemicalMultiCompound/InputChemicalMultiCompound.component'
import { InputNitrogenProps } from './InputNitrogen.props'

export const InputSelectNitrogen = ({
  title,
  fieldName,
  firstText,
  secondText,
  firstOption,
  secondOption,
  optionValue,
  firstTextValue,
  setFieldValue,
  onChangeFirstTextValue,
  onChangeChemicalComposition,
  secondTextValue,
  values,
}: InputNitrogenProps) => {
  const [open, setOpen] = React.useState(true)
  const styleAccordion = open ? styles.accordionOpen : styles.accordionClosed
  return (
    <View style={styles.container}>
      <AccordionItem
        title={title}
        titleStyle={styles.titleStyle}
        titleContainerStyle={{ ...styles.contentAccordion, ...styleAccordion }}
        extraPressCustom={() => {
          setOpen(!open)
        }}
        extraBodyStyle={styles.bodyAccordion}
        openByDefault={true}
        colorArrow={gray800}
      >
        <InputChemicalText
          text={firstText}
          option={firstOption}
          optionValue={optionValue}
          onPress={() => {
            setFieldValue(fieldName, firstOption)
            setFieldValue('chemicalCompositions', {
              ...values,
              N: {
                ...values.N,
                fountain: 'conventional',
                N_A: '0.00',
                N_N: '0.00',
                N_U: '0.00',
              },
            })
          }}
          widthBorder={true}
          textValue={firstTextValue}
          onChangeText={onChangeFirstTextValue}
        />

        <InputChemicalMultiCompound
          widthBorder
          text={secondText}
          option={secondOption}
          optionValue={optionValue}
          onPress={() => {
            setFieldValue(fieldName, secondOption)
          }}
          textValue={secondTextValue}
          onChangeChemicalComposition={onChangeChemicalComposition}
        />
      </AccordionItem>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 6,
  },
  contentAccordion: {
    borderWidth: 1,
    borderColor: gray200,
    backgroundColor: white,
    padding: 16,
  },
  accordionClosed: {
    borderRadius: 8,
  },
  accordionOpen: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  bodyAccordion: {
    backgroundColor: white,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: gray200,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 0,
    paddingLeft: 4,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 24,
    color: carbon800,
    fontFamily: 'Inter',
  },
})
