export const getFormikInitialValues = () => ({
  country: {
    value: '',
    label: '',
  },
  identifier: '',
  email: '',
  userType: {
    value: '',
    label: '',
  },
})
