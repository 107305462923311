import FORM_EDIT_LOT_NAME from '../../components/FormEditLotName/translate/en'

export default {
  TEXT_1: 'Save changes',
  TEXT_2: 'Please enter at least one letter or number',
  TEXT_3: 'You can only enter up to %{quantity} letters and/or numbers',
  COMPONENTS: {
    FORM_EDIT_LOT_NAME,
  },
}
