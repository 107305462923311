export const verificationFormValues = ({
  cropId,
  activityId,
  company,
  draftId,
  tag,
  signers,
}) => ({
  draftId: draftId,
  cropId: cropId,
  activityId: activityId,
  company: company,
  tag: tag,
  verificationType: {
    value: '',
    label: '',
  },
  lots: {
    value: [],
    label: '',
  },
  surface: '',
  observation: '',
  observations: [],
  signers,
  evidences: [],
  verificationFile: undefined,
  verificationStatus: '',
})
