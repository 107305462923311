import { validateLocationInLot } from './validateLocationInLot'

export const recalculateIsInLot = (values, jsonWkt) => {
  const auxEvidences = values.evidences
  auxEvidences.forEach((element, index) => {
    const elementLocation = element?.meta?.location
    if (elementLocation && Object.keys(elementLocation).length) {
      const isInLot = validateLocationInLot({
        location: elementLocation,
        lots: values.lots.value,
        jsonWkt,
      })
      auxEvidences[index].settings.isInLot = isInLot ? true : false
    }
  })
  return auxEvidences
}
