import RouterNames from '@constants/routerNames'

export const handleEdit = (navigation: any) => {
  navigation.reset({
    index: 0,
    routes: [
      {
        name: RouterNames.PROFILE,
      },
    ],
  })
}
