export default {
  TEXT_1: 'Atribuir lotes à colheita',
  TEXT_2: 'Has.',
  TEXT_3: 'Todos os estabelecimentos',
  TEXT_4: 'Lotes não disponíveis para esta cultura:',
  TEXT_5:
    'A data de semeadura desta safra é anterior à data de colheita de outra safra.',
  TEXT_6: 'CARREGAR LOTES SELECIONADOS',
  FIELD_1: {
    NAME: 'Pesquisar por propriedade…',
  },
  TEXT_7: 'Sup. Realizada',
  TEXT_8: 'CONFIRMAR LOTES',
  TEXT_9: 'SEGUINTE',
  TEXT_10: 'ac',
  TEXT_11: 'Para atribuir um lote ao cultivo você deverá criar um campo',
  ADD_FIELDS_TO_CROP: 'Atribuir lotes à colheita',
  ALL_FARMS: 'Todas as propriedades',
  CANCEL: 'CANCELAR',
}
