import COMPANY_DETAILS from '../../components/CompanyDetails/translate/en'
import LOTS_LIST from '../../components/LotsList/translate/en'
import KMZ_ADD_BUTTON from '../../components/KmzAddButton/translate/en'
import CREATE_FARM_BUTTON from '../../components/CreateFarmButton/translate/en'
import MODAL_SUCCESS from '../../components/ModalSuccess/translate/en'

export default {
  TEXT_1:
    'The file you are trying to upload is not a KMZ format type or has been damaged.',
  TEXT_2:
    'Could not create the farm. Please try again. If the error persists, contact support.',
  TEXT_3: 'Back',
  TEXT_4: 'Field Error',
  COMPONENTS: {
    COMPANY_DETAILS,
    LOTS_LIST,
    KMZ_ADD_BUTTON,
    CREATE_FARM_BUTTON,
    MODAL_SUCCESS,
  },
}
