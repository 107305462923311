import React from 'react'
import { Keyboard, TouchableWithoutFeedback } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

function Layout({ children }) {
  function handleDismiss() {
    Keyboard.dismiss()
  }

  return (
    <KeyboardAwareScrollView
      extraHeight={50}
      enableOnAndroid
      style={{ flex: 1 }}
      behavior='padding'
    >
      <TouchableWithoutFeedback style={{ flex: 1 }} onPress={handleDismiss}>
        <>{children}</>
      </TouchableWithoutFeedback>
    </KeyboardAwareScrollView>
  )
}

export default Layout
