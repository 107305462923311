import FORM_SIGN_UP from '../../components/FormSignUp/translate/pt'
export default {
  WELCOME: 'Bem-vindo ao ucrop.it',
  INFO_WELCOME:
    'Preencha seus dados, nós lhe enviaremos um código de acesso ao seu e-mail para finalizar a criação de sua conta.',
  CONTINUE: 'Continuar',
  COMPONENTS: {
    FORM_SIGN_UP,
  },
}
