import React, { useState, useEffect, useContext } from 'react'
import { StyleSheet, TextInput, View, Image } from 'react-native'
import { Text, List } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'
import { PropTypes } from 'prop-types'
import { uniqueId } from 'lodash'
import Fuse from 'fuse.js'
import { formatSurface } from '@common/utils'

import { CommonIcon } from '@modules/common/components'
import { black } from '@styles/palette'

const EstablishmentsList = ({
  establishmentsContext,
  displayEstablishmentToggle,
  allEstablishmentSelected,
  selectEstablishmentToggle,
  selectAllEstablishmentsToggle,
  selectLotToggle,
}) => {
  const { t } = useContext(LanguageContext)
  /*
  ESTABLISHMENTS
  */
  const [establishments, setEstablishments] = useState(establishmentsContext)

  /*
  SEARCH FIELD
  */
  const [searchField, setSearchField] = useState({
    id: uniqueId('field_'),
    value: '',
    placeholder: t('VIEWS').LOTS_ADD.FIELD_1.NAME,
    onChangeValue: (value) => {
      setSearchField({
        ...searchField,
        value: value,
      })
    },
  })

  /*
  LISTEN CHANGES OF SEARCH FIELD FOR FILTER ESTABLISHMENTS
  */
  useEffect(() => {
    if (!establishmentsContext.length) {
      return
    }

    if (!searchField.value) {
      return setEstablishments(establishmentsContext)
    }

    const options = {
      includeScore: false,
      keys: ['tag'],
    }

    const fuse = new Fuse(establishmentsContext, options)

    const result = fuse.search(searchField.value).map((element) => element.item)

    setEstablishments(result)
  }, [establishmentsContext, searchField])

  return (
    <>
      <View style={styles.containerSearchField}>
        <TextInput
          style={styles.searchField}
          placeholder={searchField.placeholder}
          placeholderTextColor='rgba(0, 0, 0, .6)'
          value={searchField.value}
          onChangeText={(text) => searchField.onChangeValue(text)}
          autoCorrect={false}
        />

        <CommonIcon name='SEARCH' size={24} style={styles.searchIcon} />
      </View>

      <View style={styles.accordionsHeader}>
        <CommonIcon
          name={
            allEstablishmentSelected
              ? 'CHECKBOX-SELECTED'
              : 'CHECKBOX-UNSELECTED'
          }
          size={24}
          color={allEstablishmentSelected ? '#4CAF50' : '#333333'}
          style={styles.accordionsHeaderIcon}
          onPress={() => {
            selectAllEstablishmentsToggle()
          }}
        />

        <View style={styles.accordionsHeaderTextContainer}>
          <Text style={styles.accordionsHeaderText}>
            {t('VIEWS').LOTS_ADD.ALL_FARMS}
          </Text>
        </View>
      </View>

      <View style={styles.containerContent}>
        {establishments.map((establishment, key) => (
          <View key={key}>
            <View
              style={
                !establishment.disabled
                  ? styles.containerAccordion
                  : styles.containerDisabledAccordion
              }
            >
              <CommonIcon
                name={
                  establishment.selected
                    ? 'CHECKBOX-SELECTED'
                    : 'CHECKBOX-UNSELECTED'
                }
                size={24}
                color={establishment.selected ? '#4CAF50' : black}
                style={styles.accordionIcon}
                onPress={() => {
                  selectEstablishmentToggle(establishment)
                }}
              />

              <View style={styles.subContainerAccordion}>
                <List.Accordion
                  title={establishment.tag}
                  style={styles.accordion}
                  titleStyle={
                    !establishment.display
                      ? styles.accordionTitle
                      : styles.accordionTitleSelected
                  }
                  expanded={establishment.display}
                  onPress={() => displayEstablishmentToggle(establishment)}
                ></List.Accordion>
              </View>
            </View>

            {establishment.display && (
              <View style={styles.containerLots}>
                {establishment.lots
                  .filter((lot) => !lot.disabled)
                  .map((lot, lotKey) => (
                    <View key={lotKey} style={styles.containerLot}>
                      <View style={styles.subContainerLot}>
                        <CommonIcon
                          name={
                            lot.selected
                              ? 'CHECKBOX-SELECTED'
                              : 'CHECKBOX-UNSELECTED'
                          }
                          size={24}
                          color={lot.selected ? '#4CAF50' : black}
                          style={styles.lotIcon}
                          onPress={() => {
                            selectLotToggle(establishment, lot)
                          }}
                        />

                        <View style={styles.lotTexts}>
                          <Text style={styles.lotName}>{`${lot.name}`}</Text>

                          <Text style={styles.lotSurface}>
                            {formatSurface(
                              lot.surface,
                              establishment?.areaUnit ?? ''
                            )}
                          </Text>

                          <Text style={styles.lotLocation}>
                            {`${lot.cityName ? lot.cityName : ''} ${
                              lot.provinceName ? lot.provinceName : ''
                            }`}
                          </Text>
                        </View>

                        <View style={styles.lotImageContainer}>
                          <Image
                            source={{
                              uri: lot.imageUrl,
                            }}
                            style={styles.lotImage}
                          />
                        </View>
                      </View>
                    </View>
                  ))}

                {establishment.lots.filter((lot) => lot.disabled)[0] && (
                  <>
                    <View style={styles.disabledLotsHeader}>
                      <Text style={styles.disabledLotsHeaderBoldText}>
                        {t('VIEWS').LOTS_ADD.TEXT_4}
                      </Text>

                      <Text style={styles.disabledLotsHeaderText}>
                        {t('VIEWS').LOTS_ADD.TEXT_5}
                      </Text>
                    </View>

                    {establishment.lots
                      .filter((lot) => lot.disabled)
                      .map((lot, lotKey) => (
                        <View key={lotKey} style={styles.containerLot}>
                          <View style={styles.subContainerDisabledLot}>
                            <CommonIcon
                              name={
                                lot.selected
                                  ? 'CHECKBOX-SELECTED'
                                  : 'CHECKBOX-UNSELECTED'
                              }
                              size={24}
                              color={lot.selected ? '#4CAF50' : black}
                              style={styles.lotIcon}
                              onPress={() => {
                                selectLotToggle(establishment, lot)
                              }}
                            />

                            <View style={styles.lotTexts}>
                              <Text style={styles.lotName}>{lot.name}</Text>

                              <Text style={styles.lotSurface}>
                                {`${(
                                  Math.round(lot.surface * 100) / 100
                                ).toFixed(2)} ${establishment?.areaUnit ?? ''}`}
                              </Text>

                              <Text style={styles.lotLocation}>
                                {`${lot.cityName ? lot.cityName : ''} ${
                                  lot.provinceName ? lot.provinceName : ''
                                }`}
                              </Text>
                            </View>

                            <View style={styles.lotImageContainer}>
                              <Image
                                source={{
                                  uri: lot.imageUrl,
                                }}
                                style={styles.lotImage}
                              />
                            </View>
                          </View>
                        </View>
                      ))}
                  </>
                )}
              </View>
            )}
          </View>
        ))}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  containerContent: {
    marginBottom: 50,
  },
  containerSearchField: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  searchField: {
    flex: 1,
    height: 48,
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 1)',
    borderRadius: 2,
    paddingTop: 2,
    paddingRight: 16,
    paddingBottom: 2,
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: '500',
  },
  searchIcon: {
    marginLeft: 10,
    marginRight: 10,
  },
  accordionsHeader: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
  },
  accordionsHeaderIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  accordionsHeaderTextContainer: {
    flex: 1,
    paddingTop: 18,
    paddingBottom: 18,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  accordionsHeaderText: {
    fontSize: 16,
    fontWeight: '500',
  },
  containerAccordion: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
  },
  containerDisabledAccordion: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
    opacity: 0.5,
  },
  accordionIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  subContainerAccordion: {
    flex: 1,
  },
  accordion: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 16,
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  accordionTitle: {
    color: '#000000',
    fontWeight: '500',
    marginLeft: -8,
  },
  accordionTitleSelected: {
    color: '#4CAF50',
    fontWeight: '500',
    marginLeft: -8,
  },
  containerLot: {
    backgroundColor: '#F8F8F8',
    paddingLeft: 5,
    paddingRight: 5,
  },
  subContainerLot: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  lotIcon: {
    marginLeft: 30,
    marginRight: 30,
  },
  lotTexts: {
    flex: 1,
  },
  lotName: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 5,
  },
  lotSurface: {
    fontSize: 14,
    color: '#333333',
    marginBottom: 5,
  },
  lotLocation: {
    fontSize: 14,
    color: '#333333',
  },
  lotImageContainer: {
    marginLeft: 'auto',
    marginRight: 20,
  },
  lotImage: {
    height: 60,
    width: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  disabledLotsHeader: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#F8F8F8',
  },
  disabledLotsHeaderBoldText: {
    fontSize: 16,
    fontWeight: '500',
  },
  disabledLotsHeaderText: {
    fontSize: 16,
    fontStyle: 'italic',
    marginTop: 5,
  },
  subContainerDisabledLot: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
    opacity: 0.5,
  },
})

EstablishmentsList.propTypes = {
  establishmentsContext: PropTypes.array.isRequired,
  displayEstablishmentToggle: PropTypes.func.isRequired,
  allEstablishmentSelected: PropTypes.bool.isRequired,
  selectEstablishmentToggle: PropTypes.func.isRequired,
  selectAllEstablishmentsToggle: PropTypes.func.isRequired,
  selectLotToggle: PropTypes.func.isRequired,
}

export default EstablishmentsList
