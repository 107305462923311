import { takeLatest, put } from 'redux-saga/effects'
import { getDraftsVerificationByCropIdAndIdentifier } from '@services/firebase'
import { verificationTypes as type } from '../types'

function* callLoadVerificationTypes({ payload }) {
  try {
    const { id, identifier, verificationTypes, verificationTypesCrop } = payload
    let draftsByCrop = yield getDraftsVerificationByCropIdAndIdentifier(
      id,
      identifier
    )

    draftsByCrop = draftsByCrop.map((draft) => {
      const verificationType = verificationTypes.find(
        (verificationType) => verificationType.value === draft.verificationType
      )
      return {
        ...draft,
        verificationType: {
          _id: verificationType?.value,
          codeLabel: verificationType?.label,
        },
      }
    })
    yield put({
      type: type.SET_VERIFICATION_TYPES,
      payload: {
        verificationTypesData: verificationTypesCrop,
        draftsByCrop,
      },
    })
  } catch (error) {
    console.error('error verificationTypes', error)
  }
}

export function* loadVerificationTypes() {
  yield takeLatest(type.LOAD_VERIFICATION_TYPES, callLoadVerificationTypes)
}
