import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { PropTypes } from 'prop-types'

import { primary50, gray7 } from '@styles/palette'

export const CantRemoveReasonToast = ({ showToast, reason }) => {
  return (
    showToast && (
      <View style={styles.container}>
        <Text style={styles.reasonLabel}>{reason}</Text>
      </View>
    )
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 12,
    backgroundColor: primary50,
    borderRadius: 5,
  },
  reasonLabel: {
    fontSize: 14,
    fontWeight: '400',
    color: gray7,
  },
})

CantRemoveReasonToast.propTypes = {
  showToast: PropTypes.bool.isRequired,
  reason: PropTypes.string,
}
