import React, { useContext, useState, useEffect } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { PropTypes } from 'prop-types'
import { Menu, Card } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'
import orderSeed from './constants/order'
import { CommonIcon } from '@modules/common/components'
import { SnackbarAddGenericSupply } from '@modules/supplies/components'
import { MenuDefault } from '@common/components/menu/MenuDefault'
import { ItemMenuDefault } from '@common/components/menu/ItemMenuDefault'

const SupplyOrderByWeb = ({
  setSearchCode,
  selectedTab,
  genericSupplySnackBarShown,
  setGenericSupplySnackBarShown,
}) => {
  const { t } = useContext(LanguageContext)
  const [visibleMenu, setVisibleMenu] = useState(false)
  const hasEIQ = selectedTab === 'fitoterapicos' || selectedTab === 'insumos'
  const [searchMode, setSearchMode] = useState(
    orderSeed.defaultMode.nameSearch(t)
  )
  const openMenu = () => setVisibleMenu(true)
  const closeMenu = () => setVisibleMenu(false)
  const [visibleSnackbar, setVisibleSnackbar] = useState(false)

  useEffect(() => {
    if (!genericSupplySnackBarShown && selectedTab === 'fertilizantes') {
      setVisibleSnackbar(true)
      setGenericSupplySnackBarShown(true)
    }
    if (selectedTab !== 'fertilizantes') {
      setVisibleSnackbar(false)
    }
  }, [selectedTab, genericSupplySnackBarShown])

  const handlePress = (mode) => {
    setSearchMode(orderSeed[mode]?.nameSearch(t))
    setSearchCode(mode)
    closeMenu()
  }

  return (
    <>
      <View style={styles.menu}>
        <SnackbarAddGenericSupply
          visible={visibleSnackbar}
          onPress={() => setVisibleSnackbar(false)}
        />
        <Card onPress={openMenu}>
          <Card.Title
            subtitleStyle={styles.cardText}
            subtitle={
              <Text>
                {t('COMPONENTS').SUPPLIES.ORDER_BY.TEXT_1}
                <Text style={styles.searchMode}>{searchMode}</Text>
              </Text>
            }
            right={() => (
              <MenuDefault>
                {hasEIQ && (
                  <ItemMenuDefault
                    onPress={() => {
                      handlePress(orderSeed.eiq.searchOrder)
                    }}
                    title={orderSeed.eiq.nameSearch(t)}
                  />
                )}
                <ItemMenuDefault
                  onPress={() => {
                    handlePress(orderSeed.company.searchOrder)
                  }}
                  title={orderSeed.company.nameSearch(t)}
                />
                <ItemMenuDefault
                  onPress={() => {
                    handlePress(orderSeed.name.searchOrder)
                  }}
                  title={orderSeed.name.nameSearch(t)}
                />
              </MenuDefault>
            )}
          />
        </Card>
      </View>
    </>
  )
}
const styles = StyleSheet.create({
  menu: {
    paddingTop: 8,
  },
  cardText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#3A3B3C',
  },
  searchMode: {
    fontWeight: 'bold',
    color: 'black',
  },
})

SupplyOrderByWeb.propTypes = {
  setSearchCode: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
}
export default SupplyOrderByWeb
