import { View, StyleSheet, Text } from 'react-native'
import { RadioButton } from 'react-native-paper'
import { gray200, gray50, gray800 } from '@common/styles'
import InputText from '@components/inputs/InputText'
import { InputChemicalMultiCompoundProps } from './InputChemicalMulticompound.props'
import { InputSelectDefault } from '../../../common/components/inputs/InputSelectDefault/index'
import { carbon500, carbon800, carbon50 } from '@styles/palette'
import { useContext } from 'react'
import { LanguageContext } from '@contextState/language'

export const InputChemicalMultiCompound = ({
  option,
  optionValue,
  text,
  onPress,
  textValue,
  onChangeChemicalComposition,
}: InputChemicalMultiCompoundProps) => {
  const styleDisabled = option !== optionValue ? styles.disabled : {}
  const { t } = useContext(LanguageContext)
  const fountainOptions = [
    {
      label: t('VIEWS.SUPPLIES.CONVENTIONAL'),
      value: 'conventional',
      key: 'conventional',
      disabled: false,
    },
    {
      label: t('VIEWS.SUPPLIES.GREEN'),
      value: 'green',
      key: 'green',
      disabled: false,
    },
  ]
  return (
    <View style={[{ flex: 1, flexDirection: 'column' }, styles.withBorder]}>
      <View style={styles.contentRadioInput}>
        <RadioButton.Android
          value={option}
          color={carbon800}
          status={option === optionValue ? 'checked' : 'unchecked'}
          onPress={(value: any) => {
            onPress(value)
          }}
        />
        <View style={[styles.descriptionContainer]}>
          <Text style={styles.descriptionText}>{text}</Text>
        </View>
      </View>

      {option === optionValue && (
        <View>
          <View style={[styles.compositionItemContainer, styles.withBorder]}>
            <InputSelectDefault
              id='fountain'
              containerStyle={{ borderRadius: 4 }}
              onChangeText={(value: any) => {
                onChangeChemicalComposition('fountain', value.key)
              }}
              value={fountainOptions.find(
                (option) => option.value === textValue.N.fountain
              )}
              label={t('VIEWS.SUPPLIES.FOUNTAIN')}
              labelStyle={styles.selectLabel}
              options={fountainOptions}
              disabled={Boolean(false)}
              touched
              error={''}
              animatedLabel={false}
              dropdownIconStyle={{ fontSize: 20, color: gray800 }}
              selectButtonStyle={{ backgroundColor: carbon50, height: 40 }}
              buttonTextStyle={styles.selectButtonText}
            />
          </View>
          <View
            style={[
              styles.compositionItemContainer,
              styles.withBorder,
              styles.spaceBetween,
            ]}
          >
            <View>
              <Text style={styles.itemLabel}>{`% ${t(
                'VIEWS.SUPPLIES.N_AMMONIACAL'
              )}`}</Text>
              <Text style={styles.itemSecondaryText}>{'N-NH3'}</Text>
            </View>

            <InputText
              placeholder='0.00'
              inputContainerStyle={{
                ...styles.inputContainerStyle,
                ...styleDisabled,
              }}
              inputStyle={styles.inputStyle}
              disabled={option !== optionValue}
              editable={option === optionValue}
              keyboardType='decimal-pad'
              value={textValue.N.N_A}
              onChangeText={(value: string) =>
                onChangeChemicalComposition('N_A', value)
              }
            />
          </View>

          <View
            style={[
              styles.compositionItemContainer,
              styles.withBorder,
              styles.spaceBetween,
            ]}
          >
            <View>
              <Text style={styles.itemLabel}>{`% ${t(
                'VIEWS.SUPPLIES.N_NITRIC'
              )}`}</Text>
              <Text style={styles.itemSecondaryText}>{'N-NO4'}</Text>
            </View>
            <InputText
              placeholder='0.00'
              inputContainerStyle={{
                ...styles.inputContainerStyle,
                ...styleDisabled,
              }}
              inputStyle={styles.inputStyle}
              containerStyle={styles.inputContainerStyle}
              disabled={option !== optionValue}
              editable={option === optionValue}
              keyboardType='decimal-pad'
              value={textValue.N.N_N}
              onChangeText={(value: string) =>
                onChangeChemicalComposition('N_N', value)
              }
            />
          </View>

          <View style={[styles.compositionItemContainer, styles.spaceBetween]}>
            <View>
              <Text style={styles.itemLabel}>{`% ${t(
                'VIEWS.SUPPLIES.N_UREAIC'
              )}`}</Text>
              <Text style={styles.itemSecondaryText}>{'N-Urea'}</Text>
            </View>
            <InputText
              placeholder='0.00'
              inputContainerStyle={{
                ...styles.inputContainerStyle,
                ...styleDisabled,
              }}
              inputStyle={styles.inputStyle}
              containerStyle={styles.inputContainerStyle}
              disabled={option !== optionValue}
              editable={option === optionValue}
              keyboardType='decimal-pad'
              value={textValue.N.N_U}
              onChangeText={(value: string) =>
                onChangeChemicalComposition('N_U', value)
              }
            />
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  contentRadioInput: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  descriptionContainer: {
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    paddingRight: 16,
    marginLeft: 8,
    paddingVertical: 8,
  },
  descriptionText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '600',
    color: carbon800,
    fontFamily: 'Inter',
  },
  itemLabel: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '600',
    fontStyle: 'normal',
    color: carbon800,
    fontFamily: 'Inter',
  },
  compositionItemContainer: {
    paddingRight: 17,
    marginLeft: 41,
    paddingVertical: 8,
  },
  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemSecondaryText: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
    color: carbon500,
    fontFamily: 'Inter',
  },
  withBorder: {
    borderBottomWidth: 1,
    borderBottomColor: gray200,
  },
  inputContainerStyle: {
    width: 76,
    height: 40,
    backgroundColor: gray50,
    borderRadius: 4,
  },
  inputStyle: {
    paddingHorizontal: 8,
    textAlign: 'right',
    width: 76,
    height: 40,
    fontWeight: '500',
  },
  disabled: {
    borderColor: gray50,
  },
  selectLabel: {
    paddingLeft: 0,
    fontSize: 16,
    fontWeight: '600',
    color: carbon800,
    fontFamily: 'Inter',
  },
  selectButtonText: {
    color: carbon800,
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
  },
})
