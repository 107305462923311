import * as yup from 'yup'
import { cuitValidator } from '@utils/validation'
import {
  notSpecialCharacters,
  onlyNumbers,
} from '@common/utils/regularsExpressions'

export const getFormikValidationSchema = (t, countries) =>
  yup.object().shape({
    country: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    identifier: yup
      .string()
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .when('country', {
        is: (value) =>
          value?.value ===
          countries.find((element) => element.alpha3Code === 'ARG')?._id,
        then: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .test('CuitValid', t('UTILS.VALIDATION.TEXT_1'), (val) =>
            cuitValidator.test(val)
          )
          .matches(onlyNumbers, t('ERRORS.FORM_ERRORS.IS_NUMBER')),
        otherwise: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .matches(notSpecialCharacters, {
            message: t('ERRORS.FORM_ERRORS.NOT_SPECIAL_CHARACTERS'),
            excludeEmptyString: true,
          }),
      }),
    name: yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    address: yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    typePerson: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    users: yup
      .array()
      .min(2, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 2 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
  })
