import CODE_FORM from '../../components/CodeForm/translate/en'
export default {
  SECRET_LOGIN_CODE: 'Secret login code',
  PARAGRAPH_ENTER_YOU_SECRET_CODE:
    'Enter your secret 6-digit login code that we have sent you to ',
  IF_HAVENT_RECEIVED_EMAIL: `If you haven't received the email, check your spam box.`,
  LOGIN: 'Login',
  CODE_INCORRECT: 'The entered code is incorrect.',
  COMPONENTS: {
    CODE_FORM,
  },
}
