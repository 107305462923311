import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, FlatList } from 'react-native'
import { CompanyDetails } from '@common/components/CompanyDetails'
import CardCollaborator from './components/CardCollaborator'
import { white } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { useSelector } from 'react-redux'
import useNetwork from '@utils/network'
import { useAuth } from '@common/hooks'

const CollaboratorsListNewCompany = () => {
  const { isCompanyProducer } = useAuth()
  const { t } = useContext(LanguageContext)
  const { doRequest } = useNetwork()
  const collaboratorsCompany = useSelector(
    (state) => state.collaboratorsCompany
  )
  const [roleTypes, setRoleTypes] = useState([])
  const { collaborators, company } = collaboratorsCompany
  const { companyIdentifier, companyName } = company

  useEffect(() => {
    fetchCommon()
  }, [])

  const fetchCommon = async () => {
    try {
      const response = await doRequest({
        method: 'get',
        url: 'commons/roles',
        params: {
          companyType: 'ALL',
        },
      })
      setRoleTypes(response.data)
    } catch (error) {
      console.error('error roles', error)
    }
  }

  const isFlag = (role) => {
    if (role?.isAdmin) {
      return t('VIEWS.COLLABORATORS_LIST_NEW_COMPANY.TEXT_ADMIN')
    }
    if (role?.isResponsible) {
      return t('VIEWS.COLLABORATORS_LIST_NEW_COMPANY.TEXT_RESPONSIBLE')
    }
    return ''
  }
  return (
    <View style={styles.container}>
      <CompanyDetails
        companyName={companyName}
        companyIdentifier={companyIdentifier}
      />
      <View style={[styles.marginBottom, styles.containerCollaborators]}>
        <FlatList
          data={collaborators}
          renderItem={({ item }) => {
            const roleValue = item?.role?.value ?? item?.role
            const role = roleTypes.find((role) => role?.value === roleValue)
            const roleName = `${role?.keyLabel} ${isFlag(item?.config ?? item)}`
            return (
              <CardCollaborator
                collaborator={item}
                t={t}
                isCompanyProducer={isCompanyProducer}
                companyName={companyName}
                roleName={roleName}
              />
            )
          }}
          keyExtractor={(item) => item?._id || item?.email}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    flex: 1,
  },
  containerCollaborators: {
    paddingHorizontal: 10,
    flex: 1,
  },
  marginBottom: {
    marginBottom: 20,
  },
})

export default CollaboratorsListNewCompany
