export default {
  TEXT_1: 'License Details',
  TEXT_2: 'I read the license and agree to participate in this ecosystem.',
  TEXT_3: 'Sign',
  TEXT_4: 'Information visibility',
  TEXT_5:
    'When you sign this license, the information of this crop will be shared with the issuing company.',
  TEXT_6: 'Reject',
  TEXT_7: 'Accept',
  TEXT_8: '¡License signed!',
  TEXT_9: 'The license number is #%{numberLicense}',
  TEXT_10: 'Choose the fields you want to associate with this ecosystem',
  TEXT_11: 'Define fields for this license',
  TEXT_12: 'Go back to Crops',
}
