/**
 *
 * @param {string} farmName
 * @returns {object}
 */
export const getFormikInitialValues = (email = '') => {
  return {
    email,
  }
}
