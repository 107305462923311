import { EActiveAgreementTypes, EquivalentSeeds } from '@common/utils'

import useNetwork from '@utils/network'
import { useEffect, useState } from 'react'
import { getActiveAgreementTypes } from '../utils'
import { useNavigation, useRoute } from '@react-navigation/native'
import { MODAL_ERRORS } from '@utils/constants'

const useAgreementsTypes = ({ cropType, alphaCodeCompany, cropId }) => {
  const [agreementTypesData, setAgreementTypesData] = useState([])
  const [agreementTypes, setAgreementTypes] = useState([])
  const { doRequest } = useNetwork()
  const { navigate } = useNavigation()
  const { params } = useRoute()

  useEffect(() => {
    fetchAgreementsTypes()
  }, [])

  useEffect(() => {
    fetchAgreements()
  }, [agreementTypesData])

  const fetchAgreementsTypes = async () => {
    const result = await doRequest({
      method: 'GET',
      url: 'statements/api/v1/type-statements',
      params: {
        cropId,
      },
      displayAlert: false,
      onlyUrl: true,
    })

    const newResponse = result.data.map((el) => ({
      ...el,
      label: el.keyLabel,
      value: el._id,
      key: el.key,
      parentType: el.parentTypes?.length ? el.parentTypes[0].key : null,
      evidenceTypesAllowed:
        el.evidenceTypesAllowed?.map(({ evidenceConcepts, required }) => ({
          _id: evidenceConcepts?._id || null,
          code: evidenceConcepts?.code || null,
          required,
        })) || [],
      multipleSelection: el.multipleSelection,
      cropTypes: el?.cropTypes ?? [],
    }))

    setAgreementTypesData(newResponse)
  }

  const filterByCropType = (agreementsResult, cropTypeKey) => {
    return agreementsResult.filter((agreementType) => {
      const cropTypes = agreementType?.cropTypes ?? []
      if (!cropTypes.length) {
        return true
      }
      return cropTypes
        .map((cropType) => cropType.toUpperCase())
        .includes(cropTypeKey)
    })
  }

  const filterByCountry = (agreementsResult, alphaCode) => {
    return agreementsResult.filter((agreementType) => {
      const countries = agreementType?.countries ?? []
      if (!countries.length) {
        return true
      }
      return countries
        .map((country) => country.toUpperCase())
        .includes(alphaCode)
    })
  }

  const fetchAgreements = async () => {
    const cropTypeKey = String(cropType.key).toUpperCase()
    const cropTypeLabel = EquivalentSeeds[cropTypeKey] ?? cropTypeKey

    let agreementsResult = Object.assign({}, agreementTypesData)

    const constants2BSVS = await doRequest({
      method: 'GET',
      url: 'emissions/constants/2bsvs',
      displayAlert: false,
    })

    if (constants2BSVS?.data) {
      const isCrop2BSVS = constants2BSVS?.data.some(
        (constant2BSVS) =>
          String(constant2BSVS.key).toUpperCase() ===
          String(cropTypeLabel).toUpperCase()
      )

      if (
        !isCrop2BSVS &&
        String(params?.declarationType).toUpperCase() ===
          String(EActiveAgreementTypes.CALCULATION_2BSVS).toUpperCase()
      ) {
        return navigate('ModalErrorDeepLinks', {
          error: MODAL_ERRORS.NOT_AVAILABLE,
        })
      }

      agreementsResult = getActiveAgreementTypes(
        agreementTypesData,
        !isCrop2BSVS && EActiveAgreementTypes.CALCULATION_2BSVS
      )
    }

    setAgreementTypes(
      filterByCountry(
        filterByCropType(agreementsResult, cropTypeKey),
        alphaCodeCompany
      )
    )
  }

  return { agreementTypes, agreementTypesData }
}

export default useAgreementsTypes
