import * as yup from 'yup'

export const declineValidationSchema = (t) =>
  yup.object().shape({
    observation: yup
      .string()
      .strict(false)
      .trim()
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .min(40, t('ERRORS.FORM_ERRORS.MIN_CHARACTERS', { quantity: 40 }))
      .max(140, t('ERRORS.FORM_ERRORS.MIN_CHARACTERS', { quantity: 140 })),
  })
