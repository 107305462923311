import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { TabBar } from 'react-native-tab-view'
import { FormikProvider } from 'formik'
import { TabView } from 'react-native-tab-view'
import { PropTypes } from 'prop-types'
import { blackMediumEmphasis, primary500 } from '@styles/palette'
import SupplyOrderBy from '@components/supplies/SupplyOrderBy'
import SupplyOrderByWeb from '@components/supplies/SupplyOrderByWeb'
import InputSearchSupply from './components/InputSearchSupply'
import { FabButton } from '@common/components'
import { keysSupplies } from '@constants/index'

const Screen = ({
  formik,
  handler,
  values,
  setFieldValue,
  handleSubmit,
  onTabPress,
  index,
  routes,
  renderScene,
  setIndex,
  layout,
  isModalHistoryVisible,
  searchHistory,
  onSelectHistoryElement,
  onRemoveHistoryElement,
  onSearchInputFocus,
  onSearchInputBlur,
  searchInputRef,
  setSearchCode,
  selectedTab,
  onSearchInputHandleKeypress,
  onPressRight,
  isConnected,
  goToCreateGenericSupply,
  genericSupplySnackBarShown,
  setGenericSupplySnackBarShown,
}) => {
  return (
    <View style={styles.container}>
      <FormikProvider value={formik}>
        <InputSearchSupply
          handler={handler}
          values={values}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          onSearchInputFocus={onSearchInputFocus}
          onSearchInputBlur={onSearchInputBlur}
          searchInputRef={searchInputRef}
          visible={isModalHistoryVisible}
          data={searchHistory}
          onSelectElement={onSelectHistoryElement}
          onRemoveElement={onRemoveHistoryElement}
          onSearchInputHandleKeypress={onSearchInputHandleKeypress}
          onPressRight={onPressRight}
        />
      </FormikProvider>

      <TabView
        style={styles.tabContainer}
        renderTabBar={(props) => {
          return (
            <>
              <TabBar
                {...props}
                scrollEnabled
                onTabPress={onTabPress}
                activeColor={primary500}
                indicatorStyle={styles.indicatorStyle}
                style={styles.tabBar}
                inactiveColor={blackMediumEmphasis}
                labelStyle={styles.labelStyleTabBar}
              />
              {Platform.OS == 'web' ? (
                <SupplyOrderByWeb
                  setSearchCode={setSearchCode}
                  selectedTab={selectedTab}
                  setGenericSupplySnackBarShown={setGenericSupplySnackBarShown}
                  genericSupplySnackBarShown={genericSupplySnackBarShown}
                />
              ) : (
                <SupplyOrderBy
                  setSearchCode={setSearchCode}
                  selectedTab={selectedTab}
                  setGenericSupplySnackBarShown={setGenericSupplySnackBarShown}
                  genericSupplySnackBarShown={genericSupplySnackBarShown}
                />
              )}
            </>
          )
        }}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width: layout.width }}
      />

      <FabButton
        icon='plus'
        onPress={goToCreateGenericSupply}
        visible={isConnected && keysSupplies.fertilizer === routes[index].key}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20,
    paddingHorizontal: 10,
  },
  tabContainer: {
    marginTop: 55,
  },
  indicatorStyle: {
    backgroundColor: primary500,
  },
  tabBar: {
    backgroundColor: '#fff',
  },
  labelStyleTabBar: {
    fontWeight: 'bold',
  },
})

Screen.propTypes = {
  handler: PropTypes.func.isRequired,
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onTabPress: PropTypes.func.isRequired,
  index: PropTypes.number,
  routes: PropTypes.array.isRequired,
  renderScene: PropTypes.func.isRequired,
  setIndex: PropTypes.func.isRequired,
  layout: PropTypes.object.isRequired,
  isModalHistoryVisible: PropTypes.bool.isRequired,
  searchHistory: PropTypes.array.isRequired,
  onSelectHistoryElement: PropTypes.func.isRequired,
  onRemoveHistoryElement: PropTypes.func.isRequired,
  onSearchInputFocus: PropTypes.func.isRequired,
  onSearchInputBlur: PropTypes.func.isRequired,
  setSearchCode: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
}

export default Screen
