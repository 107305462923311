import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { secondary100 } from '@styles/palette'
import { formatDateMoment } from '@utils/date'
import { LanguageContext } from '@contextState/language'
import { capitalizeFirstLetter } from '@utils/strings'

export const SnackbarRejectedActivity = ({ draft, i18n }) => {
  const { t } = React.useContext(LanguageContext)
  if (!draft?.isRejected) {
    return null
  }

  const renderTextDate = () =>
    `${formatDateMoment(
      draft.rejectedAt,
      'DD/',
      i18n.locale
    )} ${capitalizeFirstLetter(
      formatDateMoment(draft.rejectedAt, 'MMM/', i18n.locale).replace('.', '')
    )} ${formatDateMoment(draft.rejectedAt, 'YY HH:mm', i18n.locale)}`

  return (
    <View style={styles.contentSnack}>
      <Text style={styles.textInfo}>{renderTextDate()}</Text>
      <Text style={styles.textInfo}>
        {`${t('COMMON.SNACKBAR.SNACKBAR_REJECTED_ACTIVITY.TEXT_1')} ${
          draft.rejectedByUsername ?? draft.createdByUsername
        }`}
      </Text>
      <Text style={styles.textBold}>
        {t('COMMON.SNACKBAR.SNACKBAR_REJECTED_ACTIVITY.TEXT_2')}
      </Text>
      <Text style={styles.textInfo}>{`"${draft.rejectObservation}"`}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  contentSnack: {
    backgroundColor: secondary100,
    padding: 16,
    borderRadius: 4,
  },
  textInfo: {
    fontSize: 12,
    opacity: 0.6,
  },
  textBold: {
    fontWeight: 'bold',
    opacity: 0.6,
  },
})
