export default {
  TEXT_1: 'You dont have authorization to export the report',
  TEXT_2: 'The KMZ file has an invalid format for a field',
  TEXT_3: 'Evidence files have extension that are not allowed',
  TEXT_4: 'Third party service is integrated into the company',
  TEXT_5: 'The company already has an integration service',
  TEXT_6: 'The company is not integrated',
  TEXT_7: 'The field has already been exported',
  TEXT_8: 'Error trying to export the information',
  TEXT_9: 'Invalid credentials',
  TEXT_10: 'The KMZ file has an invalid format for a field.',
  TEXT_11:
    'There are fields with the same name, please modify them to continue',
  TEXT_12: 'Some required fields are empty',
  TEXT_13: 'One of the dates of the crop is invalid',
  TEXT_14: 'The set of files sent are invalid',
  TEXT_15: 'Field not available',
  TEXT_16: 'The set of files sent are invalid',
  TEXT_17: 'The information sent is invalid',
  TEXT_18: 'No puede desvincular colaborador',
}
