import { Platform } from 'react-native'
import { MenuWeb } from '../MenuWeb'
import { MenuMobile } from '../MenuMobile'
import { MenuDefaultProps } from './MenuDefault.props'

export const MenuDefault = ({
  children,
  disabled,
  anchor,
}: MenuDefaultProps) => {
  return Platform.OS === 'web' ? (
    <MenuWeb disabled={disabled} anchor={anchor}>
      {children}
    </MenuWeb>
  ) : (
    <MenuMobile disabled={disabled} anchor={anchor}>
      {children}
    </MenuMobile>
  )
}
