export default {
  TEXT_1: 'Add Collaborator',
  TEXT_2: 'ADD COLLABORATOR',
  FIELD_1: {
    PLACEHOLDER: 'Country',
    LABEL: 'Country',
  },
  FIELD_2: {
    PLACEHOLDER: 'Tax Id',
    LABEL: 'Tax Id',
  },
  FIELD_3: {
    PLACEHOLDER: 'Email',
    LABEL: 'Email',
  },
  FIELD_4: {
    PLACEHOLDER: 'Collaborator type',
    LABEL: 'Collaborator type',
  },
}
