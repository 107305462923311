import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { secondary500 } from '@styles/palette'

export const DisassociateFieldModal = ({ visible, onClose, onConfirm }) => {
  const { t } = useContext(LanguageContext)

  return (
    <ModalConfirm
      visible={visible}
      onClose={onClose}
      onCancel={onConfirm}
      onConfirm={onClose}
      title={t(
        'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.DISASSOCIATE_FIELD_MODAL.TITLE'
      )}
      contentText={t(
        'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.DISASSOCIATE_FIELD_MODAL.CONTENT_TEXT'
      )}
      cancelText={t(
        'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.DISASSOCIATE_FIELD_MODAL.CANCEL_TEXT'
      )}
      confirmText={t(
        'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.DISASSOCIATE_FIELD_MODAL.CONFIRM_TEXT'
      )}
      cancelButtonStyle={styles.deleteModalCancelButtonStyle}
      cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
      confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
    />
  )
}

const styles = StyleSheet.create({
  deleteModalCancelButtonStyle: {
    borderColor: secondary500,
  },
  deleteModalCancelButtonTextStyle: {
    color: secondary500,
  },
  deleteModalConfirmButtonStyle: {
    backgroundColor: secondary500,
  },
})

DisassociateFieldModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}
