import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'
import { carbon400, blackHighEmphasis } from '@styles/palette'
import { CommonIcon } from '@modules/common/components'

export const DisclaimerLazyLoadEvidence = ({ title, description }) => {
  return (
    <View style={styles.contentContainer}>
      <CommonIcon name='CLOCK' size={45} style={styles.evidenceIcon} />

      <View style={styles.textContainer}>
        <Text style={styles.textBold}>{title}</Text>
        <Text style={styles.text}>{description}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    marginTop: 15,
  },
  evidenceContainer: {
    flexDirection: 'column',
    marginTop: 10,
  },
  evidenceIcon: {
    marginRight: 20,
    marginTop: 5,
  },
  textContainer: {
    flex: 1,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 4,
  },
  text: {
    width: '84%',
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
  },
})

DisclaimerLazyLoadEvidence.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
}
