export default {
  TEXT_1: 'Aplicación',
  TEXT_2: 'ha',
  TEXT_3: 'Planificada',
  TEXT_4: 'Desde',
  TEXT_5: 'Hasta',
  TEXT_6: 'Lotes seleccionados',
  TEXT_7: 'Superficie planificada',
  TEXT_8: 'EIQ planificado',
  TEXT_9: 'insumos',
  TEXT_10: 'Realización',
  TEXT_11: 'de lo planificado',
  TEXT_12: 'HECHO',
  TEXT_13: 'AGREGAR REALIZACIÓN',
  TEXT_14: 'Validaciones',
  TEXT_15: 'EIQ generado',
  TEXT_16: 'Borrador',
  TEXT_17: 'versión',
  TEXT_18: 'Superficie realizada',
}
