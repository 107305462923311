export default {
  TEXT_1: 'O colaborador já existe na cultura',
  TEXT_2: 'colaborador Adicionado com sucesso',
  TEXT_3: 'Campo obrigatório',
  TEXT_4: 'Insire o número',
  TEXT_5: 'CNPJ inválido',
  TEXT_6: 'A CNPJ inválido',
  FIELD_1: {
    LABEL: 'CNPJ',
    HELPTEXT: 'Da empresa física ou jurídica a cargo da produção.',
  },
  FIELD_2: {
    LABEL: 'CNPJ',
    HELPTEXT: 'Da empresa física ou jurídica a cargo da produção.',
  },
  FIELD_3: {
    LABEL: 'País',
  },
  FIELD_4: {
    LABEL: 'Email',
  },
  FIELD_5: {
    LABEL: 'Tipo de colaborador',
    HELPTEXT:
      'O produtor poderá visualizar, editar e validar todas as atividades',
  },
  TEXT_7: 'Salvar',
  TEXT_8: 'Formato de email inválido. ejemplo@mail.com',
}
