import { Field, Formik } from 'formik'
import React, { useState, useContext, useReducer } from 'react'
import { PropTypes } from 'prop-types'
import {
  StyleSheet,
  View,
  ScrollView,
  Dimensions,
  Platform,
} from 'react-native'
import { Button, Title } from 'react-native-paper'
import Input from './Input'
import Select from './Select'
import ModalCustom from './ModalCustom'
import { CommonContext } from '@contextState/common'
import { requiredNumber } from '@utils/validation'
import { LanguageContext } from '@contextState/language'
import { unitTypesForModalStorage, unitMeasureSystem } from '@utils/constants'
import { useAuth } from '@common/hooks'
import { useSelector } from 'react-redux'

const { width } = Dimensions.get('window')
const WIDTH_MODAL_EVIDENCE = 600
const WITH_MODAL_RESPONSIVE =
  width <= WIDTH_MODAL_EVIDENCE ? width - 50 : WIDTH_MODAL_EVIDENCE

function ModalStorage({ visible, handleClose, onSelect, company }) {
  const { config } = useAuth()

  const { unitTypes } = useSelector((state) => state.unitTypesReducer)
  const { storageTypes } = useSelector((state) => state.storageTypesReducer)
  const { t } = useContext(LanguageContext)
  const unitType = unitTypes
    .filter(
      (unitType) =>
        unitType.unitMeasureSystem ===
        config?.companySelected?.unitMeasureSystem
    )
    .find((unitType) =>
      unitTypesForModalStorage.includes(unitType.key.toLowerCase())
    )
  const [initialValues] = useState({
    unitType: unitType?.value,
    tonsHarvest: '',
    storageType: '',
  })

  function handleCloseModal() {
    handleClose()
  }

  function handleSelect(values) {
    const uniType = unitTypes.filter((type) => type.value === values.unitType)
    values.icon = 'archive'
    values.label = uniType.length > 0 ? uniType[0].label : ''
    ;(values.tonsHarvest = Number(values.tonsHarvest.replace(',', '.'))),
      onSelect({
        values,
      })
    handleClose()
  }

  return (
    <>
      <ModalCustom
        visible={visible}
        hideModal={handleCloseModal}
        style={Platform.OS === 'web' ? styles.modalEvidence : undefined}
      >
        <Title style={styles.modalTitle}>
          {t('COMPONENTS').COMMON.MODAL_STORAGE.TEXT_1}
        </Title>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSelect}
        >
          {({ handleSubmit }) => (
            <View style={styles.modalContainer}>
              <ScrollView keyboardShouldPersistTaps={false}>
                <Field
                  name='storageType'
                  component={Select}
                  label={t('COMPONENTS.COMMON.MODAL_STORAGE.TEXT_1')}
                  options={storageTypes}
                  validate={(value) =>
                    !value && t('COMPONENTS.COMMON.MODAL_STORAGE.TEXT_2')
                  }
                />
              </ScrollView>

              <Field
                name='tonsHarvest'
                component={Input}
                inputProps={{
                  label:
                    company?.unitMeasureSystem === unitMeasureSystem.IMPERIAL
                      ? t('COMPONENTS.COMMON.MODAL_STORAGE.TEXT_5')
                      : t('COMPONENTS.COMMON.MODAL_STORAGE.TEXT_3'),
                  placeholder:
                    company?.unitMeasureSystem === unitMeasureSystem.IMPERIAL
                      ? t('COMPONENTS.COMMON.MODAL_STORAGE.TEXT_5')
                      : t('COMPONENTS.COMMON.MODAL_STORAGE.TEXT_3'),
                  keyboardType: 'decimal-pad',
                  returnKeyType: 'done',
                }}
                validate={(value) => requiredNumber(value, t)}
              />
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 8,
                }}
              >
                <Button
                  onPress={handleSubmit}
                  mode='contained'
                  labelStyle={{ color: 'white' }}
                >
                  {t('COMPONENTS').COMMON.MODAL_STORAGE.TEXT_4}
                </Button>
              </View>
            </View>
          )}
        </Formik>
      </ModalCustom>
    </>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    flex: 1,
  },
  indicatorContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    padding: 16,
    paddingBottom: 0,
  },
  modalContainer: {
    padding: 4,
  },
  modalEvidence: {
    width: WITH_MODAL_RESPONSIVE,
    marginLeft: (width - WITH_MODAL_RESPONSIVE) / 2,
    backgroundColor: 'white',
  },
})

ModalStorage.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  onSelect: PropTypes.func,
}

export default ModalStorage
