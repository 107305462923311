import { View, Text, StyleSheet } from 'react-native'
import React, { useContext } from 'react'
import { primary500 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'

export const HeaderAppIntegrator = () => {
  const { t } = useContext(LanguageContext)

  const handleAbout = () => {
    window.open('https://ucrop.it/es/apps-integrations/', '_blank')
  }
  return (
    <View style={styles.content}>
      <View style={{ paddingBottom: 16 }}>
        <Text style={styles.title}>
          {t('COMPONENTS.APP_INTEGRATOR.COMPONENTS.HEADER.TITLE')}
        </Text>
      </View>
      <View style={{ paddingBottom: 12 }}>
        <Text style={styles.description}>
          {t('COMPONENTS.APP_INTEGRATOR.COMPONENTS.HEADER.DESCRIPTION')}{' '}
        </Text>
      </View>
      <Text style={styles.about} onPress={handleAbout}>
        {t('COMPONENTS.APP_INTEGRATOR.COMPONENTS.HEADER.ABOUT')}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 8,
    paddingBottom: 30,
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 32,
  },
  description: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 23,
  },
  about: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 24,
    color: primary500,
  },
})
