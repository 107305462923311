import { Platform } from 'react-native'

let SQLite
let db

const getDatabase = (name) => {
  try {
    if (Platform.OS === 'web') {
      return null
    }

    if (!db) {
      SQLite = require('expo-sqlite/legacy')

      db = SQLite.openDatabase(name)
    }

    return db
  } catch (error) {
    return null
  }
}

export default getDatabase
