export default {
  WIZARD_1: {
    WELCOME: 'Bem-vindo ao ucrop.it',
    PARAGRAPH:
      'A plataforma agrícola colaborativa com a qual a sustentabilidade é + rentabilidade.',
  },
  WIZARD_2: {
    TRACE_HISTORY_CROPS: 'Trace a história de suas colheitas',
    PARAGRAPH:
      'Acessar e gerenciar indicadores ambientais.\nConectar-se com empresas que valorizam e recompensam as boas práticas.',
  },
  WIZARD_3: {
    DIGITAL_BACKUP: 'Backup digital',
    ITEMS:
      '+ Assinaturas digitais\n+ Verificação de campo dos registros\n+ Rastreabilidade das atividades\n+ Histórico detalhado da safra\n+ Documentação cripto-confidencial',
  },
}
