import { MenuItem } from '@material-ui/core'
import { blackDisabled } from '@styles/palette'
import React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { ItemMenuProps } from './ItemMenu.props'

export const ItemMenu = ({
  onPress,
  disabled,
  title,
  titleStyle = {},
}: ItemMenuProps) => {
  const style = disabled ? styles.textDisabled : {}
  return (
    <TouchableOpacity
      onPress={!disabled ? onPress : () => {}}
      style={styles.containerMenu}
    >
      <Text style={{ ...style, ...titleStyle }}>{title}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  textDisabled: {
    color: blackDisabled,
  },
  containerMenu: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
})
