import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { PropTypes } from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { useFocusEffect } from '@react-navigation/native'

import { LanguageContext } from '@contextState/language'
import { LicenseContext } from '@contextState/license'

import useNetwork from '@utils/network'
import { useAuth, useCompanyInfo } from '@modules/common/hooks'

import { AnimatedScreen } from '@modules/common/components/AnimatedScreen'
import ButtonCustom from '@components/common/ButtonCustom'
import { Details } from './components/Details'

import { userPermissions, validateRoleInUserConfigService } from '@utils'

import { grey500, primary500, white } from '@styles/palette'
import { CommonContext } from '@contextState/common'
import { MODAL_ERRORS } from '@utils/constants'
import { green500 } from '@common/styles'
import { useSelector } from 'react-redux'
import RouterNames from 'src/constants/routerNames'

const LicenseDetail = ({ navigation, route }) => {
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { config } = useAuth()
  const { errorDeepLink } = useContext(CommonContext)
  const { licenseSelected, selectLicense } = useContext(LicenseContext)
  const { t } = useContext(LanguageContext)

  const { doRequest } = useNetwork()
  const { companyId } = useCompanyInfo()

  const [isLoading, setIsloading] = useState(true)

  const { roleSelected, isAdmin: isAdminConfig } = config

  const hasPermissionProducerAdmin = useRef(
    validateRoleInUserConfigService(
      userPermissions.SIGN_LICENSE,
      roleSelected,
      isAdminConfig
    )
  ).current

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    fetchLicenseDetails()
  }, [])

  useFocusEffect(
    useCallback(() => {
      if (errorDeepLink) {
        navigation.replace('ModalErrorDeepLinks', {
          error: errorDeepLink,
        })
      }
    }, [])
  )

  /**
   * FETCH LICENSE DETAILS
   */
  const fetchLicenseDetails = async () => {
    setIsloading(() => true)

    try {
      if (isConnected) {
        const response = await doRequest({
          method: 'GET',
          url: `licenses/${route.params.licenseId}`,
          params: {
            companyId,
          },
          version: 'v2',
          displayAlert: false,
        })

        selectLicense(response.data)
      } else {
        selectLicense(null)
      }
    } catch (err) {
      console.error(err)
      navigation.replace('ModalErrorDeepLinks', {
        error: MODAL_ERRORS.ERROR_UNEXPECTED,
      })
    } finally {
      setIsloading(() => false)
    }
  }

  /**
   * GO TO SIGN LICENSE
   */
  const goToLicenseSign = () => {
    navigation.navigate(RouterNames.LICENSES_SIGN, route.params)
  }

  if (isLoading) {
    return (
      <ActivityIndicator animating color={green500} style={styles.loader} />
    )
  }

  return (
    <View style={styles.container}>
      <AnimatedScreen imageBackground={licenseSelected?.image}>
        <Details license={licenseSelected} />
      </AnimatedScreen>

      {!licenseSelected?.isSigned && hasPermissionProducerAdmin && (
        <ButtonCustom
          onPress={goToLicenseSign}
          styles={styles.button}
          uppercase={false}
        >
          {t('COMPONENTS').LICENSES.LICENSE_BUTTON.TEXT_1}
        </ButtonCustom>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white,
  },
  loader: {
    marginTop: 100,
  },
  textButtonDisabled: {
    color: grey500,
  },
  button: {
    backgroundColor: primary500,
    marginHorizontal: 16,
    marginVertical: 5,
  },
})

LicenseDetail.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default LicenseDetail
