import React, { useEffect, useState } from 'react'

import DropDown from './Dropdown/DropDown'
import { View, StyleSheet, Text, Keyboard } from 'react-native'
import { CommonIcon } from '@modules/common/components'

function Select({
  label,
  placeholder,
  helpText,
  options,
  style,
  field: { value, name },
  form: { errors, touched, setFieldValue },
  disabled,
  extraFunction,
  withOutIcon = false,
}) {
  const [showDropDown, setShowDropDown] = useState(false)
  const isInvalid = errors[name] && touched[name]
  const list = React.useMemo(() => options, [options])

  function handleChange(value) {
    setFieldValue(name, value)
    if (extraFunction) {
      extraFunction(value, list)
    }
  }

  function handleDisabled() {
    if (disabled) {
      setShowDropDown(false)
    } else {
      setShowDropDown(true)
    }
    Keyboard.dismiss()
  }

  useEffect(() => {
    if (value === null || value === undefined) {
      setFieldValue(name, 0)
    }
  }, [value, disabled])

  return (
    <View style={[styles.spacing, style]}>
      <DropDown
        theme={{
          colors: {
            background: 'white',
          },
        }}
        activeColor='black'
        error={isInvalid}
        label={label}
        placeholder={placeholder}
        mode='outlined'
        value={value}
        setValue={handleChange}
        list={list}
        visible={showDropDown}
        showDropDown={handleDisabled}
        onDismiss={() => setShowDropDown(false)}
        inputProps={{
          error: isInvalid,
          right: !withOutIcon ? (
            <CommonIcon name='CHEVRON-DOWN' size={20} />
          ) : null,
          disabled: disabled,
        }}
      />
      {helpText && !isInvalid && (
        <Text style={styles.helpText}>{helpText}</Text>
      )}
      {isInvalid && <Text style={styles.errorText}>{errors[name]}</Text>}
    </View>
  )
}

Select.defaultProps = {
  options: [],
}

const styles = StyleSheet.create({
  select: {
    backgroundColor: 'white',
  },
  spacing: {
    marginBottom: 16,
  },
  helpText: {
    marginTop: 4,
    marginBottom: 16,
    fontSize: 12,
    color: 'rgba(0,0,0,0.6)',
  },
  errorText: {
    marginTop: 4,
    marginBottom: 8,
    fontSize: 12,
    color: 'rgb(176, 0, 32)',
  },
})

export default Select
