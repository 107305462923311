export const INIT_NOTIFICATION_FILTER = {
  values: {
    farmName: '',
    farmList: [],
    farmListSelected: [],
    cropName: '',
    cropList: [],
    cropSelected: null,
    cropTypes: [],
    activityTypes: [],
  },
  isDirty: false,
  callbackResetNotificationFilter: undefined,
  hasAnyFilterActive: false,
}
