import { ECountriesAlphaCode } from '../enum/outlierCountry/index'
export const validateCompanyCountryHasOutlierOff = (
  alpha3Code: string
): boolean => {
  const countriesOutlierOff = [
    ECountriesAlphaCode.CAN,
    ECountriesAlphaCode.USA,
    ECountriesAlphaCode.BOL,
    ECountriesAlphaCode.AUS,
    ECountriesAlphaCode.BRA,
    ECountriesAlphaCode.ECU,
    ECountriesAlphaCode.MEX,
  ]

  if (alpha3Code) {
    return countriesOutlierOff.some((item) => item === alpha3Code)
  }

  return false
}
