export default {
  TEXT_1: 'Siem',
  TEXT_2: 'Cos',
  TEXT_3: 'Labra',
  TEXT_4: 'Apl',
  TEXT_5: 'Fert',
  TEXT_6: 'Monit',
  TEXT_7: 'Descargar Reporte',
  TEXT_8: 'Uso legal de la Tierra',
  TEXT_9: 'ha',
  TEXT_10: 'Cultivos Sustentables',
  TEXT_11: 'Lotes Cargados',
  TEXT_12: 'Datos Insuficientes',
  TEXT_13: 'Volumen Total Esperado',
  TEXT_14: 'tn',
  TEXT_15: 'Actividades',
  TEXT_16: 'Acuerdos Validados',
}
