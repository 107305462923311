import React, { useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import {
  primary500,
  blackHighEmphasis,
  white,
  redV2,
  black,
  green1,
  gray4,
} from '@styles/palette'

import { RadioButton } from 'react-native-paper'
import { CommonIcon } from '@modules/common/components'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { COLLABORATORS_REQUEST_STATUS } from '@utils/constants'

const ContentActionSheet = ({
  style,
  t,
  T,
  handleClose,
  collaboratorSelected,
  fetchUpdateCollaboratorRequest,
  userTypeOptions,
}) => {
  const [rolOption, setrolOption] = useState(null)

  const hasRole = Boolean(collaboratorSelected?.role)
  const isDisabled = () => rolOption === null

  const handleFetchUpdate = (action) => {
    const payload = {
      status: action,
      role: hasRole ? collaboratorSelected?.role : rolOption,
      isAdmin: hasRole ? collaboratorSelected?.isAdmin : rolOption?.isAdmin,
    }
    fetchUpdateCollaboratorRequest(collaboratorSelected, payload)
  }

  return (
    <>
      <View style={style}>
        <TouchableOpacity onPress={handleClose}>
          <CommonIcon name={'X'} size={20} />
        </TouchableOpacity>
        <Text style={styles.actionSheetHeaderText}>
          {t(
            'VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.BACKDROP_COLLABORATOR.CONFIG'
          )}
        </Text>
      </View>
      <View style={styles.actionSheetBody}>
        {hasRole ? (
          <Text style={styles.parragraph}>
            <T
              id='VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.BACKDROP_COLLABORATOR.PARRAGRAPH'
              values={{
                name:
                  collaboratorSelected?.fullName || collaboratorSelected?.email,
                role: collaboratorSelected?.role?.keyLabel,
              }}
              components={[
                {
                  component: Text,
                  props: {
                    style: styles.parragraphBold,
                  },
                },
              ]}
            />
          </Text>
        ) : (
          <View>
            <Text style={styles.parragraph}>
              <T
                id='VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.BACKDROP_COLLABORATOR.PARRAGRAPH_WITHOUT_ROLE'
                values={{
                  name:
                    collaboratorSelected?.fullName ||
                    collaboratorSelected?.email,
                }}
                components={[
                  {
                    component: Text,
                    props: {
                      style: styles.parragraphBold,
                    },
                  },
                ]}
              />
            </Text>

            <RadioButton.Group
              onValueChange={(option) => setrolOption(option)}
              value={rolOption}
            >
              {userTypeOptions?.map((userType) => (
                <View style={styles.radioRow} key={userType?.keyLabel}>
                  <RadioButton value={userType} color={green1} />
                  <Text style={styles.radioText}>{userType.keyLabel}</Text>
                </View>
              ))}
            </RadioButton.Group>
          </View>
        )}
        <View
          style={[
            styles.containerButtons,
            !collaboratorSelected?.role && styles.marginWithoutRole,
          ]}
        >
          <ButtonDefault
            onPress={() =>
              handleFetchUpdate(COLLABORATORS_REQUEST_STATUS.REJECTED)
            }
            typeButton={'inline_red'}
            text={t(
              'VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.BACKDROP_COLLABORATOR.REJECT'
            )}
            flex={1}
            height={40}
            borderRadius={6}
            customTextStyle={styles.rejectText}
            marginRight={8}
          />
          <ButtonDefault
            typeButton={!hasRole && isDisabled() ? 'disabled' : 'primary'}
            text={t(
              'VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.BACKDROP_COLLABORATOR.AUTHORIZE'
            )}
            flex={1}
            height={40}
            borderRadius={6}
            customTextStyle={styles.autorizeText}
            disabled={!hasRole && isDisabled()}
            marginLeft={8}
            onPress={() =>
              handleFetchUpdate(COLLABORATORS_REQUEST_STATUS.ACCEPTED)
            }
          />
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  buttonClose: {
    alignSelf: 'flex-start',
    paddingStart: 5,
  },
  actionSheetHeaderText: {
    fontWeight: '500',
    fontSize: 16,
    color: black,
    marginLeft: 37,
  },
  actionSheetBody: {
    paddingHorizontal: 32,
  },
  parragraph: {
    fontSize: 14,

    lineHeight: 20,
    color: black,
    fontWeight: '400',
    marginVertical: 25,
  },
  parragraphBold: {
    fontWeight: '700',
  },
  containerButtons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  marginWithoutRole: {
    marginTop: 53,
  },
  backDropItem: {
    flex: 1,
    paddingVertical: 10,
    alignItems: 'center',
    borderRadius: 6,
    height: 40,
  },
  buttonReject: {
    borderColor: redV2,
    borderWidth: 1,
    marginRight: 8,
  },
  disabled: {
    backgroundColor: gray4,
  },
  rejectText: {
    color: redV2,
    fontSize: 14,
    fontWeight: '400',
  },
  buttonAutorize: {
    backgroundColor: primary500,
    marginLeft: 8,
  },
  autorizeText: {
    color: white,
    fontSize: 14,
    fontWeight: '500',
  },
  radioRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioText: {
    color: blackHighEmphasis,
    fontSize: 14,
    fontWeight: '400',
  },
})
export default ContentActionSheet
