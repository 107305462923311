import DONWLOAD_REPORT from '../../components/DonwloadReport/translate/en'
import REQUESTED_MODAL from '../../components/RequestedReportModal/translate/en'

export default {
  COMPONENTS: {
    DONWLOAD_REPORT,
    REQUESTED_MODAL,
  },
  LOCATION: 'Location',
  LOTS: 'Fields',
  BUTTON_ADD_LOTS_TO_FARM: 'ADD FIELDS TO THE FARM',
  BUTTON_EDIT_LOTS_TO_FARM: 'ADD FIELDS',
}
