import CryptoJS from 'crypto-js'
import Constants from 'expo-constants'

const secretKey: string =
  Constants.expoConfig?.extra?.ENCRYPTION_SECRET_KEY || ''
/**
 * @description data to excrypt, type object, string
 * @param {*} data
 * @returns
 */
export function encrypt(data: string): string {
  const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString()
  return encodeURIComponent(encrypted)
}

/**
 * @description function to descrypting data
 * @param {*} encryptedText type token encrypt
 * @returns
 */
// Función para desencriptar datos
export function decrypt(encryptedData: string): string {
  try {
    var bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
    var decryptedText = bytes.toString(CryptoJS.enc.Utf8)
    var decryptedObject = JSON.parse(decryptedText)
    return decryptedObject
  } catch (error) {
    return 'error' // Return null or handle the error appropriately
  }
}
