import { formatDateMoment } from '@utils/date'

/**
 * @typedef {Object} WorkOrderData
 * @property {number} labor
 * @property {number} crop
 * @property {number} total_planned_area
 * @property {number} estimated_start
 * @property {number} url
 * @property {number} longUrl
 *
 */

/**
 *
 * @param {Function} t
 * @param {Object} activity
 * @param {string} activity.name
 * @param {Object} activity.crop
 * @param {Object} activity.crop.cropType
 * @param {string} activity.crop.cropType.keyLabel
 * @param {number} activity.surface
 * @param {string} activity.areaUnit
 * @param {string} activity.dateStart
 * @param {string} activity.workOrderNumber
 * @param {string} activity.workOrderShortUrl
 * @param {string} activity.workOrderLongUrl
 * @param {string} locale
 *
 * @return {WorkOrderData}
 */
export const mapWorkOrder = (activity, locale) => {
  if (!activity) {
    return undefined
  }

  const estimated_start = formatDateMoment(
    activity?.dateStart,
    'DD/MMM/YYYY',
    locale,
    true
  )

  const workOrder = {
    labor: activity?.name,
    crop:
      activity?.crop.cropType?.keyLabel ?? activity?.crop.cropType?.codeLabel,
    total_planned_area: `${activity?.surface} ${activity?.areaUnit}`,
    estimated_start,
    url: activity?.workOrderShortUrl,
    longUrl: activity?.workOrderLongUrl,
  }

  return workOrder
}
