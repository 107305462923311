import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import Constants from 'expo-constants'
import AsyncStorage from '@react-native-async-storage/async-storage'

import useNetwork from '@utils/network'
import useOfflineUser from '../../../offline/queries/user'
import companyTypes from '../../../constants/companyTypes'
import { TYPE_UCROPIT, TYPE_VERIFIER, TYPE_PRODUCER } from '@utils/constants'
import { COLLABORATORS_REQUEST_STATUS } from '@utils/constants'
import {
  removeEmailOfWelcomeCompanyList,
  setCompanyAllow,
  setCompanyIsSynchronized,
  setConfig,
  setIsCompanyProducer,
  setIsCompanyUcropit,
  setIsPendingAuthorization,
  setIsVerifierUcropit,
  setSelectedProfile,
  setUser,
} from '@store/slices'
import { RootState } from '@store/index'
import { makeRequest } from '@common/services'

const MS_AUTH_URL = Constants.expoConfig?.extra?.MS_AUTH_URL

export const useAuth = () => {
  const { doRequest } = useNetwork()
  const dispatch = useDispatch()

  const { isConnected } = useSelector(
    (state: RootState) => state.connectionReducer
  )

  const {
    user,
    config,
    isAuth,
    selectedProfile,
    isVerifierUcropit,
    companyAllow,
    isCompanyProducer,
    companyIsSynchronized,
    isCompanyUcropit,
    isPendingAuthorization,
  } = useSelector((state: RootState) => state.auth)

  const { syncUser, selectUser } = useOfflineUser()

  function handleUser(data: any, config = true, cb?: Function) {
    dispatch(setUser(data))

    if (config) {
      dispatch(
        setIsPendingAuthorization(
          data.collaboratorRequest.find(
            (request: any) =>
              request.company === data?.config.companySelected?._id &&
              request.user === data?._id
          )?.status === COLLABORATORS_REQUEST_STATUS.PENDING
        )
      )

      dispatch(setConfig({ ...data.config }))
    }
    if (data.config.companySelected) {
      dispatch(
        setSelectedProfile(
          data.companies.find(
            (company: any) =>
              company.company?._id === data?.config?.companySelected?._id ||
              company.company?._id === data?.config?.companyAdmin?._id
          ) || { isAdmin: false }
        )
      )
      const types = data.config.companySelected.types
      const typesAdmin = data.config?.companyAdmin?.types
      const isUcropit =
        Boolean(data.config?.companyAdmin) ||
        Boolean(typesAdmin?.find((type: any) => type?.name === TYPE_UCROPIT))

      dispatch(setIsCompanyUcropit(isUcropit))

      dispatch(
        setIsVerifierUcropit(
          Boolean(typesAdmin?.find((type: any) => type?.name === TYPE_VERIFIER))
        )
      )

      dispatch(
        setIsCompanyProducer(
          Boolean(types.find((type: any) => type?.name === TYPE_PRODUCER))
        )
      )

      if (data.collaboratorRequest && data.collaboratorRequest.length > 0) {
        const found = data.collaboratorRequest.find(
          (el: any) => el.company === data.config.companySelected._id
        )

        if (found !== undefined && found.status !== 'accepted') {
          dispatch(setCompanyAllow(false))
        } else {
          dispatch(setCompanyAllow(true))
        }
      } else {
        dispatch(setCompanyAllow(true))
      }

      dispatch(removeEmailOfWelcomeCompanyList(data?.email))
    }

    if (cb) cb()
  }

  async function fetchUser() {
    try {
      let user

      if (isConnected) {
        const response = await makeRequest({
          method: 'GET',
          url: 'auth/me',
          prefix: 'api',
          apiUrl: MS_AUTH_URL,
        })

        user = response.data

        if (Platform.OS !== 'web') {
          syncUser(user)
        }

        handleUser(user)
      } else {
        user = await selectUser()

        handleUser(user)
      }

      if (user?.config?.languageSelected) {
        await AsyncStorage.setItem('language', user?.config?.languageSelected)
      }

      return user
    } catch (err) {
      throw err
    }
  }

  async function fetchCompany(identifier: string) {
    try {
      if (!isConnected) {
        return
      }

      const response = await doRequest({
        method: 'GET',
        url: `companies?identifier=${identifier}`,
        displayAlert: false,
      })

      const [company] = response.data

      return company
    } catch (err) {
      console.warn(err)
    }
  }

  async function addCompanyConfigUser(
    id: string | number,
    companyId: string,
    identifier: string
  ) {
    try {
      const response = await doRequest({
        method: 'PUT',
        url: `configurations/${id}/companies?identifier=${identifier}`,
        data: { companySelected: companyId },
      })
      return response.data
    } catch (err) {
      console.warn(err)
    }
  }

  async function canSynchronized(id: string) {
    const company = await fetchCompany(id)
    let companyIsSynchronized = false

    if (
      company &&
      company.servicesIntegrations &&
      company.servicesIntegrations.length > 0
    ) {
      companyIsSynchronized = true
    }

    dispatch(setCompanyIsSynchronized(companyIsSynchronized))
  }

  const isCompanyVerifiers = () => {
    const types = config?.companySelected?.types ?? []

    return Boolean(
      types.find((type: any) => type?.name === companyTypes.VERIFIERS.key)
    )
  }

  const countryAuth = () => {
    return config?.companySelected?.country
  }

  return {
    isAuth,
    user,
    config,
    selectedProfile,
    isCompanyVerifiers,
    companyIsSynchronized,
    isCompanyProducer,
    isVerifierUcropit,
    isCompanyUcropit,
    isPendingAuthorization,
    companyAllow,
    handleUser,
    fetchUser,
    fetchCompany,
    addCompanyConfigUser,
    canSynchronized,
    countryAuth,
  }
}
