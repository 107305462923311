import React, { useContext } from 'react'
import { View, Text, Image, StyleSheet, Platform, Linking } from 'react-native'
import { PropTypes } from 'prop-types'
import { Button } from 'react-native-paper'
import SuppliesEmptyImage from '@images/supplies_empty.png'
import ConnectionStatusSnackbar from '../common/v1/ConnectionStatusSnackbar'
import { LanguageContext } from '@contextState/language'
import Links from '@constants/links'

const SuppliesEmpty = ({ type, searchValue, isConnected }) => {
  const { t } = useContext(LanguageContext)

  const openLinkNewSupply = () => {
    Platform.OS !== 'web'
      ? Linking.openURL(Links.suggestSupplyFormLink)
      : window.open(Links.suggestSupplyFormLink)
  }
  return (
    <View style={styles.container}>
      <View style={styles.subcontainer}>
        <Image source={SuppliesEmptyImage} style={styles.imagesEmpty} />
        <Text style={styles.textTitle}>
          {t('COMPONENTS.SUPPLIES_EMPTY.TEXT_1', {
            type,
            searchValue,
          })}
        </Text>
        {type === 'insumos' ? (
          <Text style={styles.textTitle}>
            {t('COMPONENTS').SUPPLIES_EMPTY.TEXT_2}
          </Text>
        ) : (
          <Text style={styles.textTitle}>
            {t('COMPONENTS').SUPPLIES_EMPTY.TEXT_3}
          </Text>
        )}
      </View>

      <View
        style={[
          styles.subcontainerBottom,
          isConnected
            ? styles.subcontainerBottomConnected
            : styles.subcontainerBottomNotConnected,
        ]}
      >
        {isConnected ? (
          <View>
            <Text style={styles.subtitle}>
              {t('COMPONENTS').SUPPLIES_EMPTY.TEXT_4}
            </Text>
            <Button onPress={openLinkNewSupply}>
              {t('COMPONENTS').SUPPLIES_EMPTY.TEXT_5}
            </Button>
          </View>
        ) : (
          <ConnectionStatusSnackbar />
        )}
      </View>
    </View>
  )
}

SuppliesEmpty.propTypes = {
  type: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    justifyContent: 'space-around',
    marginTop: 16,
  },
  subcontainer: {
    justifyContent: 'space-around',
    flex: 0.8,
  },
  imagesEmpty: {
    width: 150,
    height: 150,
    alignSelf: 'center',
  },
  textTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 21,
    textAlign: 'center',
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
  subcontainerBottom: {
    flex: 0.2,
  },
  subcontainerBottomConnected: {
    justifyContent: 'center',
  },
  subcontainerBottomNotConnected: {
    justifyContent: 'flex-end',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 21,
    textAlign: 'center',
  },
})

export default SuppliesEmpty
