import { ReactElement } from 'react'
import { createIconSetFromIcoMoon } from 'react-native-vector-icons'

import cropSelection from '@assets/icons/crop/selection.json'
import activitySelection from '@assets/icons/activity/selection.json'
import suppliesSelection from '@assets/icons/supplies/selection.json'
import sustainabilitySelection from '@assets/icons/sustainability/selection.json'
import commonSelection from '@assets/icons/common/selection.json'
import { IIcon } from './interfaces'

const CropIconIcoMoon = createIconSetFromIcoMoon(cropSelection, 'crop')
const ActivityIconIcoMoon = createIconSetFromIcoMoon(
  activitySelection,
  'activity'
)
const SuppliesIconIcoMoon = createIconSetFromIcoMoon(
  suppliesSelection,
  'supplies'
)
const SustainabilityIconIcoMoon = createIconSetFromIcoMoon(
  sustainabilitySelection,
  'sustainability'
)
const CommonIconIcoMoon = createIconSetFromIcoMoon(commonSelection, 'common')

const CropIcon = ({
  name,
  size,
  color,
  style = {},
  onPress,
  disabled = false,
  ...otherProps
}: IIcon): ReactElement => {
  return (
    <CropIconIcoMoon
      name={name}
      size={size}
      style={[{ color }, style]}
      onPress={onPress}
      disabled={disabled}
      {...otherProps}
    />
  )
}

const ActivityIcon = ({
  name,
  size,
  color,
  style = {},
  onPress,
  disabled = false,
  ...otherProps
}: IIcon): ReactElement => {
  return (
    <ActivityIconIcoMoon
      name={name}
      size={size}
      style={[{ color }, style]}
      onPress={onPress}
      disabled={disabled}
      {...otherProps}
    />
  )
}

const CommonIcon = ({
  name,
  size,
  color,
  style = {},
  onPress,
  disabled = false,
  ...otherProps
}: IIcon): ReactElement => {
  return (
    <CommonIconIcoMoon
      name={name}
      size={size}
      style={[{ color }, style]}
      onPress={onPress}
      disabled={disabled}
      {...otherProps}
    />
  )
}

const SuppliesIcon = ({
  name,
  size,
  color,
  style = {},
  onPress,
  disabled = false,
  ...otherProps
}: IIcon): ReactElement => {
  return (
    <SuppliesIconIcoMoon
      name={name}
      size={size}
      style={[{ color }, style]}
      onPress={onPress}
      disabled={disabled}
      {...otherProps}
    />
  )
}

const SustainabilityIcon = ({
  name,
  size,
  color,
  style = {},
  onPress,
  disabled = false,
  ...otherProps
}: IIcon): ReactElement => {
  return (
    <SustainabilityIconIcoMoon
      name={name}
      size={size}
      style={[{ color }, style]}
      onPress={onPress}
      disabled={disabled}
      {...otherProps}
    />
  )
}

export { CropIcon, ActivityIcon, SuppliesIcon, SustainabilityIcon, CommonIcon }
