export const LAST_UPLOADED = 'last_uploaded'
export const LARGER_SURFFACE = 'surface'
export const PENDING_ACTIVITIES = 'pendingActivities'
export const ENABLED_OFFLINE = 'enabled_offline'
export const A_Z = 'asc'

/**
 * Enum for tri-state values.
 * @readonly
 * @enum {string}
 */
export const ORDER_MENU_ENUM = {
  LAST_UPLOADED,
  LARGER_SURFFACE,
  PENDING_ACTIVITIES,
  ENABLED_OFFLINE,
  A_Z,
}
