export default {
  TITLE: 'Saiba mais sobre as atividades',
  DRAFT: {
    TITLE: 'Rascunho',
    DESCRIPTION:
      'Estas são as atividades que não foram carregadas ou foram modificadas por dois ou mais colaboradores e precisam confirmar uma versão.',
  },
  PLANNED: {
    TITLE: 'Planejado',
    DESCRIPTION:
      'Estas são as atividades que foram planejadas e que estão pendentes de serem realizadas.',
  },
  PENDING_VALIDATION: {
    TITLE: 'Pendente de validação',
    DESCRIPTION:
      'Estas são as atividades que foram realizadas, mas que têm validações pendentes.',
  },
  COMPLETED: {
    TITLE: 'Concluído',
    DESCRIPTION: 'Estas são as atividades que foram realizadas e validadas.',
  },
}
