export const getFarmsDTO = (data) => {
  const farmsDTO = data.map(({ farm }) => {
    const farmDTO = {
      _id: farm._id,
      uuid: farm.uuid,
      name: farm.name,
      fields: farm.lots.map((field) => ({
        _id: field._id,
        uuid: field.uuid,
        farm: field.farm,
        image: field.image.normal,
        name: field.name,
        surface: field.surface,
        areaUnit: field.areaUnit,
        thirdGeometryLevel: field.cityName,
        secondGeometryLevel: field.provinceName,
      })),
    }

    return farmDTO
  })

  return farmsDTO
}
