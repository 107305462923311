import { FC, ReactElement, useContext } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { LanguageContext } from '@contextState/language'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'

import LicenseList from '@modules/licenses/screens/LicenseList/v1'
import LicenseSign from '@modules/licenses/screens/LicenseSign/v2'
import LicenseLotsAddScreen from '../../../screens/v1/LicenseLotsAdd'
import SummaryLicenseLots from 'src/screens/v1/SummaryLicenseLots'
import SubLicenseSelectCompanies from 'src/screens/v1/subLicenseSelectCompanies'
import SummaryLandscapeSustainability from 'src/screens/v1/SummaryLandscapeSustainability'
import LicenseDetails from 'src/screens/v1/LicenseDetails'
import LicenseDetail from '@modules/licenses/screens/LicenseDetail/v1'

const Stack = createStackNavigator()

export const LicenseStack: FC<any> = ({
  route: { params },
}): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const initialParams = {
    ...params,
    initialRouteName: undefined,
  }

  const stacks = [
    {
      name: RouterNames.LICENSES_LIST,
      component: LicenseList,
      initialParams,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.LICENCES'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.LICENCES')}
            drawer={Platform.OS !== 'web'}
          />
        ),
      },
    },
    {
      name: RouterNames.LICENSES_DETAIL,
      component: LicenseDetails,
      initialParams,
      options: {
        title: t('VIEWS.LICENSE_DETAILS.TEXT_1'),
        header: (props: any) => (
          <Header {...props} title={t('VIEWS.LICENSE_DETAILS.TEXT_1')} goBack />
        ),
      },
    },
    {
      name: RouterNames.LICENSES_DETAIL_READ_ONLY,
      component: LicenseDetail,
      initialParams,
      options: {
        title: t('VIEWS.LICENSE_DETAILS.TEXT_1'),
        header: (props: any) => (
          <Header {...props} title={t('VIEWS.LICENSE_DETAILS.TEXT_1')} goBack />
        ),
      },
    },
    {
      name: RouterNames.LICENSES_LOTS_ADD,
      component: LicenseLotsAddScreen,
      initialParams,
      options: {
        title: t('VIEWS.LICENSE_LOTS_ADD.TEXT_1'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.LICENSE_LOTS_ADD.TEXT_1')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.LICENSES_SIGN,
      component: LicenseSign,
      initialParams,
      options: {
        title: t('VIEWS.LICENSE_SIGN.TEXT_1'),
        header: (props: any) => (
          <Header {...props} title={t('VIEWS.LICENSE_SIGN.TEXT_1')} goBack />
        ),
      },
    },
    {
      name: RouterNames.LICENSES_SUMMARY_LICENSE_LOTS,
      component: SummaryLicenseLots,
      initialParams,
      options: {
        title: t('VIEWS.SUMMARY_LICENSE_LOTS.TEXT_1'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.SUMMARY_LICENSE_LOTS.TEXT_1')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.LICENSES_SUB_LICENSE_SELECT_COMPANIES,
      component: SubLicenseSelectCompanies,
      initialParams,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_49'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_49')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.LICENSES_SUMMARY_LANDSCAPE_SUSTAINABILITY,
      component: SummaryLandscapeSustainability,
      initialParams,
      options: {
        title: t('VIEWS.LICENSE_LOTS_ADD.TEXT_1'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.LICENSE_LOTS_ADD.TEXT_1')}
            goBack
          />
        ),
      },
    },
  ]

  const initialRouteName = params.initialRouteName || RouterNames.LICENSES_LIST

  return (
    <Stack.Navigator initialRouteName={initialRouteName}>
      {stacks.map((stack: any, key) => (
        <Stack.Screen {...stack} key={`LICENSE_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
