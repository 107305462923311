import PropTypes from 'prop-types'
import { StyleSheet, View } from 'react-native'
import React from 'react'
import { ItemFilter } from '../ItemFilter'

export const ListFilter = ({
  data,
  items,
  setItems,
  title,
  disabledItems = false,
}) => {
  const onAction = (item, isExist) => {
    setItems((state) =>
      isExist
        ? state.filter((status) => status !== item.key)
        : [...state, item.key]
    )
  }

  const selectedAll = (items, data, setItems) => {
    setItems(
      items.length === data.length ? [] : data.map((status) => status.key)
    )
  }

  return (
    <View style={styles.containerSection}>
      <ItemFilter
        title={title}
        bigTitle
        checked={items.length === data.length}
        onPress={() => selectedAll(items, data, setItems)}
        disabled={disabledItems}
      />
      {data.map((item, key) => {
        const isExist = items.some((key) => item.key === key)
        return (
          <ItemFilter
            key={key}
            title={item.title}
            checked={isExist}
            onPress={() => onAction(item, isExist)}
            borderBottom
            disabled={disabledItems}
          />
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  containerSection: {
    paddingTop: 30,
  },
})

ListFilter.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  disabledItems: PropTypes.bool,
}
