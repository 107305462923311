export default {
  TEXT_1: 'LICENÇA',
  TEXT_2: 'Eu li e cumpro esta seção',
  TEXT_3: 'assinar',
  TEXT_4: 'Visibilidade dos dados',
  TEXT_5:
    'A assinatura desta licença implica o compartilhamento dos dados dos cultivos a que se aplique com a empresa emissora.',
  TEXT_6: 'Rejeitar',
  TEXT_7: 'Aceito',
  TEXT_8: '¡Licença assinada!',
  TEXT_9: 'O número da licença é #%{numberLicense}',
  TEXT_10: 'Escolha os lotes nos quais esta licença se aplicará',
  TEXT_11: 'APLICAR LICENÇA A LOTES',
  TEXT_12: 'Voltar a cultivos',
}
