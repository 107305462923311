import { useEffect, useState } from 'react'

/**
 * @typedef {Object} UseModalParams
 * @property {object} [animated] animation Lottie json
 * @property {string} title modal title
 * @property {string} subtitle first subtitle to display
 * @property {string} subtitleSecondary second subtitle to display
 * @property {number} updateTime seconds
 * @property {boolean} [isvisible]
 */

/**
 * @typedef  {Object} UseModalReturn
 * @property {boolean} isModalVisible
 * @property {Function} setModalIsVisible
 * @property {Function} toggleModal
 * @property {Function} openModal
 * @property {Function} closeModal
 * @property {object} animated
 * @property {string} title
 * @property {string} subtitle
 */

/**
 *
 * @param {UseModalParams} params
 *
 * @returns {UseModalReturn}
 */
export const useModalAnimated = ({
  animated,
  title,
  subtitle,
  subtitleSecondary,
  updateTime = 10,
  isVisible = false,
}) => {
  const [isModalVisible, setModalIsVisible] = useState(isVisible)
  const [subtitleState, setSubtitleState] = useState(subtitle)

  useEffect(() => {
    let timeout

    if (isModalVisible) {
      timeout = setTimeout(() => {
        Boolean(subtitleSecondary) && setSubtitleState(subtitleSecondary)
      }, updateTime * 1000)
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [isModalVisible])

  const toggleModal = (state = null) => {
    setModalIsVisible(state !== null ? state : !isModalVisible)
  }

  const openModal = () => {
    setModalIsVisible(true)
  }

  const closeModal = () => {
    setModalIsVisible(false)
  }

  return {
    isModalVisible,
    setModalIsVisible,
    toggleModal,
    openModal,
    closeModal,
    animated,
    title,
    subtitle: subtitleState,
  }
}
