import FARM_SEARCH from '../../components/SearchFarm/translate/pt'
import FARM_CARD from '../../components/FarmCard/translate/pt'

export default {
  NOT_FOUND: 'Nenhuma equivalência encontrada',
  COMPONENTS: {
    FARM_SEARCH,
    FARM_CARD,
  },
}
