export default {
  ACT_TILLAGE: 'Tillage',
  ACT_APPLICATION: 'Crop Protection',
  ACT_FERTILIZATION: 'Fertilization',
  ACT_SOWING: 'Planting',
  ACT_HARVEST: 'Harvest',
  ACT_AGREEMENTS: 'Declaration',
  ACT_MONITORING: 'Monitoring',
  ACT_VERIFICATION: 'Verification',
  ACT_IRRIGATION: 'Irrigation',
}
