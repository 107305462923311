import { activitiesTypes as type } from '../types'

const initialState = {
  activityStatus: [],
  activityType: [],
  hasActivities: false,
  canSeeVerification: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_FILTERS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case type.REMOVE_FILTERS: {
      return {
        ...initialState,
      }
    }
    case type.SET_IS_ACTIVITIES: {
      return {
        ...state,
        hasActivities: action.payload,
      }
    }
    case type.SET_CAN_SEE_VERIFICATION: {
      return {
        ...state,
        canSeeVerification: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
