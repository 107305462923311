export default {
  TEXT_1: 'Planning details',
  TEXT_2: 'ha',
  TEXT_3: 'EIQ',
  TEXT_4: 'Projected EIQ',
  TEXT_5: 'Field',
  TEXT_6: 'ha',
  TEXT_7: 'From',
  TEXT_8: 'Until',
}
