import { FC, ReactElement, useContext } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'

import { LanguageContext } from '@contextState/language'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'
import NotificationList from '@modules/notifications/screens/NotificationList/v1'
import NotificationFilters from '@modules/notifications/screens/Filters/v1'
import CommonActivitiesDetail from 'src/screens/CommonActivitiesDetail'
import ActivityCreate from '@modules/activities/screens/activityCreate/v1'
import { CommonContext } from '@contextState/common'
import { useSelector } from 'react-redux'
import { GlobalStateInterface } from '@store/interfaces'

const Stack = createStackNavigator()

export const NotificationStack: FC<any> = (): ReactElement | null => {
  const { t } = useContext(LanguageContext)
  const { activityTypes } = useSelector(
    (state: GlobalStateInterface) => state.activityTypesReducer
  )

  const getCurrentActivityType: any = (route: any) =>
    activityTypes.find((activity: any) => activity.tag === route.params.tag)

  const stacks = [
    {
      name: RouterNames.NOTIFICATION_LIST,
      component: NotificationList,
      options: {
        title: t('VIEWS.NOTIFICATIONS.NAVIGATION.TITLE'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.NOTIFICATIONS.NAVIGATION.TITLE')}
            filtersScreen='NotificationFilters'
            drawer={Platform.OS !== 'web'}
          />
        ),
      },
    },
    {
      name: RouterNames.NOTIFICATION_FILTERS,
      component: NotificationFilters,
      options: {
        title: t('VIEWS.NOTIFICATION_FILTER.NAVIGATION.TITLE'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.NOTIFICATION_FILTER.NAVIGATION.TITLE')}
            close
            showResetNotificationFilters
          />
        ),
      },
    },
    {
      name: 'CommonActivitiesDetail',
      component: CommonActivitiesDetail,
      options: {
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_41')}
            goBack
          />
        ),
      },
    },
    {
      name: 'ActivityUpdate',
      component: ActivityCreate,
      options: ({ route }: any) => ({
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_47', {
          activityType: getCurrentActivityType(route).label,
        }),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_47', {
              activityType: getCurrentActivityType(route).label,
            })}
            goBack
          />
        ),
      }),
    },
  ]

  return (
    <Stack.Navigator initialRouteName={RouterNames.NOTIFICATION_LIST}>
      {stacks.map((stack, key) => (
        <Stack.Screen {...stack} key={`NOTIFICATION_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
