import { useContext } from 'react'
import { round } from 'lodash'

import { CommonContext } from '@contextState/common'
import { IUseWrapper } from '../../interfaces'
import { useSelector } from 'react-redux'
import { GlobalStateInterface } from '@store/interfaces'

export const useWrapper = ({
  cropTypeKey,
  cropSurface,
  areaUnit,
  cropName,
  companyName,
}: IUseWrapper) => {
  const { cropTypes } = useSelector(
    (state: GlobalStateInterface) => state.cropTypesReducer
  )

  const iconName = cropTypeKey.toUpperCase()

  const cropTypeLabel: string =
    cropTypes.find(({ key }: { key: string }) => key === cropTypeKey)?.label ??
    ''

  const cropSurfaceLabel = `${round(cropSurface, 4)} ${areaUnit}`

  const cropNameLabel = cropName

  const companyNameLabel = companyName

  const dataToReturn = {
    iconName,
    cropTypeLabel,
    cropSurfaceLabel,
    cropNameLabel,
    companyNameLabel,
  }

  return dataToReturn
}
