import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { StyleSheet, Platform, ScrollView } from 'react-native'
import { CommonActions, useFocusEffect } from '@react-navigation/native'

import { LanguageContext } from '@contextState/language'
import useModal from '@hooks/useModal'
import { useModalAnimated } from '@modules/common/hooks/useModalAnimated'
import useValidatePin from '@hooks/useValidatePin'
import useValidateAchievement from '@hooks/useValidateAchievement'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { VerifyPin, ModalLoading } from '@modules/common/components'
import {
  HeaderActivityCreateConfirm,
  Planification,
  Realization,
  Harvest,
  Monitoring,
  Agreement,
  ValidateButton,
  ModifyButton,
  Irrigation,
  FloodResume,
} from './components'
import { useGetActivityDTO, useFetchOutliers } from './hooks'
import activityTypes from '@constants/activityTypes'
import {
  parseEvidencesToFiles,
  validateByTypeActivity,
} from '@modules/activities/screens/activityCreate/v1/utils'
import useNetwork from '@utils/network'
import { urlToBlob, blobToFile } from '@utils/files'
import { carbon50 } from '@styles/palette'
import { capitalizeFirstLetter } from '@utils/strings'
import RouterNames from '@constants/routerNames'
import { useDispatch, useSelector } from 'react-redux'
import { resetActivityTemporalData } from '@store/slices'
import { useAuth } from '@common/hooks'
import SnackbarCustom from '@components/common/SnackbarCustom'
import useOfflineDrafts from '@offline/queries/drafts'

const ActivityCreateConfirm = ({ route: { params }, navigation }) => {
  const dispatch = useDispatch()
  const { isPlanification, activityTypeTag } = useRef(params).current

  const [isValidateButtonAvailable, setIsValidateButtonAvailable] =
    useState(true)
  const [sowingOutlier, setSowingOutlier] = useState()
  const [fertilizerOutlier] = useState()
  const [phytotherapicOutlier, setPhytotherapicOutlier] = useState()
  const [visibleSnackbar, setVisibleSnackbar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState()

  const { t } = useContext(LanguageContext)
  const {
    user: { _id: userId },
    config,
  } = useAuth()

  const activityTemporalData = useSelector(
    (state) => state.activityTemporalData
  )

  const {
    data: {
      draftId,
      displayDraftVersionModal,
      dataToValidate,
      activityToDelete,
      isAchievement,
      isDirectAchievement,
      currentActivity,
      isDeepLink,
      routeRedirect,
    },
  } = activityTemporalData

  const { activityDTO } = useGetActivityDTO({ activityTypeTag })

  const { fetchSowingOutlier, fetchPhytotherapicOutlier } = useFetchOutliers()

  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()

  const { validateAchievement } = useValidateAchievement()

  const { doRequest } = useNetwork()

  const {
    findDraftByIdOffline,
    findDraftsByDraftGroupIdOffline,
    deleteDraftByIdOffline,
  } = useOfflineDrafts()

  useFocusEffect(
    useCallback(() => {
      fetchData()
    }, [])
  )

  useEffect(() => {
    return () => {
      dispatch(resetActivityTemporalData())
    }
  }, [])

  const {
    isModalVisible: isModalValidateDraftVersionVisible,
    toggleModal: displayToggleModalValidateDraftVersion,
    closeModal: closeModalValidateDraftVersion,
  } = useModal()
  const {
    isModalVisible: isModalVerifyPinVisible,
    toggleModal: toggleModalVerifyPin,
    closeModal: closeModalVerifyPin,
  } = useModal()
  const {
    isModalVisible: isModalLoadingVisible,
    title: titleModalLoading,
    subtitle: subtitleModalLoading,
    setModalIsVisible: setModalLoadingIsVisible,
  } = useModalAnimated({
    title: t('VIEWS.ACTIVITY_CREATE.MODAL_LOADING.COMPLETING_THE_APPLICATION'),
    subtitle: t(
      'VIEWS.ACTIVITY_CREATE.MODAL_LOADING.PLEASE_WAIT_A_FEW_MOMENTS'
    ),
    subtitleSecondary: t(
      'VIEWS.ACTIVITY_CREATE.MODAL_LOADING.PLEASE_WAIT_A_FEW_MORE_MOMENTS'
    ),
  })

  const fetchData = useCallback(async () => {
    if (activityDTO.suppliesSeeds.supplies?.length) {
      const sowingParamsGroupedByCode = {}

      activityDTO.suppliesSeeds.supplies.map(({ code, unitTypeKey, total }) => {
        if (sowingParamsGroupedByCode[code]) {
          sowingParamsGroupedByCode[code].totals += total

          return
        }

        sowingParamsGroupedByCode[code] = {
          code,
          unit: unitTypeKey,
          totals: total,
          surface: activityDTO.surface,
        }

        return
      })

      const sowingParams = Object.values(sowingParamsGroupedByCode)

      const sowingOutlier = await fetchSowingOutlier({ data: sowingParams })

      setSowingOutlier(sowingOutlier)
    }

    if (activityDTO.suppliesPhytotherapics.supplies?.length) {
      const supplies = activityDTO.suppliesPhytotherapics.supplies.map(
        ({ _id, unitTypeKey, total }) => ({
          id: _id,
          unit: unitTypeKey,
          total,
        })
      )

      const phytotherapicParams = {
        surface: activityDTO.surface,
        supplies,
      }

      const phytotherapicOutlier = await fetchPhytotherapicOutlier({
        params: phytotherapicParams,
      })

      setPhytotherapicOutlier(phytotherapicOutlier)
    }
  }, [])

  const onPressValidateButton = useCallback(() => {
    setIsValidateButtonAvailable(false)

    if (displayDraftVersionModal) {
      return displayToggleModalValidateDraftVersion()
    }

    validatePin()
  }, [])

  const onPressModifyButton = useCallback(() => {
    navigation.goBack()
  }, [])

  const onPressCancelValidateVersion = useCallback(() => {
    closeModalValidateDraftVersion()

    setIsValidateButtonAvailable(true)
  }, [])

  const onPressConfirmValidateDraftVersion = useCallback(() => {
    validatePin()
  }, [])

  const validatePin = useCallback(async () => {
    if (
      (typeof config === 'object' && config?.hasPin) ||
      (typeof config === 'boolean' && config)
    ) {
      if (verifyExpirationPin()) {
        setTimeout(() => toggleModalVerifyPin(true), 400)
      } else {
        const { validate, tokenPin } = await geTokenAuthPin()

        if (!validate) {
          setTimeout(() => toggleModalVerifyPin(true), 400)

          return
        }

        onSuccessVerifyPin(tokenPin)
      }
    } else {
      navigation.navigate('CreatePin', {
        callbackPin,
      })
    }
  }, [])

  const callbackPin = useCallback(async () => {
    const { tokenPin } = await geTokenAuthPin()

    onSuccessVerifyPin(tokenPin)
  }, [])

  const onSuccessVerifyPin = useCallback(async (pinToken) => {
    closeModalVerifyPin()

    onValidate(pinToken)
  }, [])

  const onPressCancelModalVerifyPin = useCallback(() => {
    closeModalVerifyPin()

    setIsValidateButtonAvailable(true)
  }, [])

  const onValidate = useCallback(async (pinToken) => {
    try {
      setModalLoadingIsVisible(true)

      if (isPlanification) {
        const formData = new FormData()
        formData.append(
          'data',
          JSON.stringify({
            ...dataToValidate,
            status: 'PLANNED',
            noFiles: true,
          })
        )

        if (!currentActivity) {
          await doRequest({
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            method: 'POST',
            url: 'activities',
            data: formData,
            version: 'v3',
          })
        } else {
          await doRequest({
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            method: 'PUT',
            url: `activities/${currentActivity}`,
            data: formData,
          })
        }
      } else if (isAchievement) {
        const formData = new FormData()

        for (const evidence of dataToValidate.evidences) {
          if (!evidence.persisted) {
            formData.append('files', evidence.file)

            continue
          }

          let file

          if (Platform.OS === 'web') {
            const blobFile = await urlToBlob({
              url: evidence.file,
            })

            file = blobToFile({
              blobFile,
              fileName: evidence.name,
              fileType: evidence.type,
            })
          } else {
            file = {
              type: evidence.type,
              name: evidence.name,
              uri: evidence.file,
            }
          }

          formData.append('files', file)
        }

        formData.append(
          'data',
          JSON.stringify({
            ...dataToValidate,
            fromActivityPlanned: true,
            version: undefined,
            createdByUserId: undefined,
            createdByUsername: undefined,
            updatedByUserId: undefined,
            updatedByUsername: undefined,
            createdAt: undefined,
            draftGroupId: undefined,
            hasMoreVersion: undefined,
            lotsData: undefined,
            company: undefined,
            isRejected: undefined,
            rejectedAt: undefined,
            rejectObservation: undefined,
          })
        )

        await validateAchievement({
          data: formData,
          crop: dataToValidate.crop,
          activity: dataToValidate.activity,
          draftId,
        })
      } else {
        const { agreementTypeKey } = activityDTO
        const files = await parseEvidencesToFiles(dataToValidate.evidences)

        const newValues = {
          ...dataToValidate,
          files,
        }

        await validateByTypeActivity({
          alreadyParsed: true,
          doRequest,
          tag: activityTypeTag,
          activityToDelete,
          isRealized: false,
          values: newValues,
          pinToken,
          isDirectAchievement,
          userId,
          agreementTypeKey,
        })
      }

      if (!isPlanification && Platform.OS !== 'web') {
        const [errorFindDraft, responseDraft] = await findDraftByIdOffline(
          draftId
        )

        if (!errorFindDraft && responseDraft) {
          const [errorFindDrafts, responseDrafts] =
            await findDraftsByDraftGroupIdOffline(responseDraft.draftGroupId)

          if (!errorFindDrafts) {
            for (const draft of responseDrafts) {
              deleteDraftByIdOffline(draft._id)
            }
          }
        }
      }

      if (isDeepLink) {
        if (dataToValidate?.cropId) {
          navigation.navigate(RouterNames.ACTIVITY_LIST, {
            cropId: dataToValidate?.cropId,
          })
        } else {
          navigation.navigate(RouterNames.CROP_LIST)
        }
      } else {
        const resetAction = CommonActions.reset({
          index: 0,
          routes: [routeRedirect],
        })
        navigation.dispatch(resetAction)
      }
    } catch (error) {
      console.error(error)
      setSnackBarMessage(String(error?.message))
      setVisibleSnackbar(true)
    } finally {
      setTimeout(() => setModalLoadingIsVisible(false), 400)
      setIsValidateButtonAvailable(true)
    }
  }, [])

  const RenderCardsByActivity = () => {
    if (activityTypes[activityTypeTag].canPlanning) {
      return isPlanification ? (
        <Planification
          cropName={activityDTO.cropName}
          companyName={activityDTO.companyName}
          fieldsQuantity={activityDTO.fieldsQuantity}
          surface={activityDTO.surface}
          unitArea={activityDTO.unitArea}
          activityTypeName={activityDTO.activityTypeName}
          subActivityTypeName={activityDTO.subActivityTypeName}
          dateStart={activityDTO.dateStart}
          dateEnd={activityDTO.dateEnd}
          sowingOutlier={sowingOutlier}
          suppliesSeeds={activityDTO.suppliesSeeds}
          fertilizerOutlier={fertilizerOutlier}
          suppliesFertilizers={activityDTO.suppliesFertilizers}
          phytotherapicOutlier={phytotherapicOutlier}
          suppliesPhytotherapics={activityDTO.suppliesPhytotherapics}
          collaboratorsQuantity={activityDTO.collaboratorsQuantity}
        />
      ) : (
        <Realization
          cropName={activityDTO.cropName}
          companyName={activityDTO.companyName}
          fieldsQuantity={activityDTO.fieldsQuantity}
          surface={activityDTO.surface}
          unitArea={activityDTO.unitArea}
          activityTypeName={activityDTO.activityTypeName}
          subActivityTypeName={activityDTO.subActivityTypeName}
          dateAchievement={activityDTO.dateAchievement}
          sowingOutlier={sowingOutlier}
          suppliesSeeds={activityDTO.suppliesSeeds}
          fertilizerOutlier={fertilizerOutlier}
          suppliesFertilizers={activityDTO.suppliesFertilizers}
          phytotherapicOutlier={phytotherapicOutlier}
          suppliesPhytotherapics={activityDTO.suppliesPhytotherapics}
          evidences={activityDTO.evidences}
          collaboratorsQuantity={activityDTO.collaboratorsQuantity}
        />
      )
    }

    switch (activityTypeTag) {
      case activityTypes.ACT_HARVEST.key:
        return (
          <Harvest
            cropName={activityDTO.cropName}
            cropType={activityDTO.cropType}
            companyName={activityDTO.companyName}
            fieldsQuantity={activityDTO.fieldsQuantity}
            surface={activityDTO.surface}
            unitArea={activityDTO.unitArea}
            dateHarvest={activityDTO.dateHarvest}
            unitTypeName={activityDTO.unitTypeName}
            unitTypeKey={activityDTO.unitTypeKey}
            pay={activityDTO.pay}
            storages={activityDTO.storages}
            evidences={activityDTO.evidences}
            collaboratorsQuantity={activityDTO.collaboratorsQuantity}
            volume={activityDTO.volume}
            unitVolume={activityDTO.unitVolume}
          />
        )

      case activityTypes.ACT_MONITORING.key:
        return (
          <Monitoring
            cropName={activityDTO.cropName}
            cropType={activityDTO.cropType}
            companyName={activityDTO.companyName}
            fieldsQuantity={activityDTO.fieldsQuantity}
            surface={activityDTO.surface}
            unitArea={activityDTO.unitArea}
            dateHarvest={activityDTO.dateEstimatedHarvest}
            dateMonitoring={activityDTO.dateObservation}
            unitTypeName={activityDTO.unitTypeName}
            unitTypeKey={activityDTO.unitTypeKey}
            pay={activityDTO.pay}
            observation={activityDTO.observation}
            evidences={activityDTO.evidences}
            collaboratorsQuantity={activityDTO.collaboratorsQuantity}
          />
        )

      case activityTypes.ACT_AGREEMENTS.key:
        return <Agreement {...activityDTO} />

      case activityTypes.ACT_IRRIGATION.key:
        return <Irrigation {...activityDTO} />
      case activityTypes.ACT_FLOOD.key:
        return <FloodResume {...activityDTO} />
    }
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
      >
        <HeaderActivityCreateConfirm
          title={capitalizeFirstLetter(activityDTO.activityTypeName)}
          subtitle={
            activityDTO.agreementTypeName ?? activityDTO.subActivityTypeName
          }
          styles={styles.headerStyles}
        />

        <RenderCardsByActivity />

        <ValidateButton
          isPlanification={isPlanification}
          isValidateButtonAvailable={isValidateButtonAvailable}
          onPressValidateButton={onPressValidateButton}
        />

        <ModifyButton onPressModifyButton={onPressModifyButton} />
      </ScrollView>

      <ModalConfirm
        visible={isModalValidateDraftVersionVisible}
        onClose={onPressCancelValidateVersion}
        onConfirm={onPressConfirmValidateDraftVersion}
        title={t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE.TEXT_1')}
        contentText={t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE.TEXT_2')}
        cancelText={t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE.TEXT_3')}
        confirmText={t('VIEWS.ACTIVITY_CREATE.MODAL_VALIDATE.TEXT_4')}
        cancelButtonStyle={styles.validateDraftVersionModalCancelButtonStyle}
        cancelButtonTextStyle={
          styles.validateDraftVersionModalCancelButtonTextStyle
        }
        confirmButtonStyle={styles.validateDraftVersionModalConfirmButtonStyle}
      />

      <VerifyPin
        isModalVisible={isModalVerifyPinVisible}
        toggleModal={onPressCancelModalVerifyPin}
        onSuccess={onSuccessVerifyPin}
      />

      <ModalLoading
        visible={isModalLoadingVisible}
        title={titleModalLoading}
        subtitle={subtitleModalLoading}
        animationStyle={styles.animationStyle}
      />

      <SnackbarCustom
        visible={visibleSnackbar}
        onToggle={() => {
          setSnackBarMessage('')
          setVisibleSnackbar(false)
        }}
      >
        {snackBarMessage}
      </SnackbarCustom>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingBottom: 10,
    backgroundColor: carbon50,
  },
  validateDraftVersionModalCancelButtonStyle: {
    borderColor: '#FF7043',
  },
  validateDraftVersionModalCancelButtonTextStyle: {
    color: '#FF7043',
  },
  validateDraftVersionModalConfirmButtonStyle: {
    backgroundColor: '#FF7043',
  },
  animationStyle: {
    width: 300,
    height: 300,
  },
  headerStyles: {
    marginTop: 16,
  },
})

export default ActivityCreateConfirm
