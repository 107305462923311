import {
  getEIQRange as getEIQRangePackage,
  calculateEIQWithList,
  getUnits,
} from '@ucropit/eiq-calculator'

/**
 * GET EIQ RANGE.
 *
 * @param {*} eiq
 */
export const getEIQRange = (eiq) => {
  return getEIQRangePackage(eiq)
}

/**
 * CALCULATE EIQ.
 *
 * @param {*} supplies
 * @returns Number
 */
export const calculateEiq = (supplies, surface) => {
  const units = getUnits()
  const suppliesList = supplies.map(
    ({ total, supply, eiqTotal, unit, unitTypeSupply }) => {
      const eiq = getEiqOfSupply({ supply, eiqTotal }) || 0
      unit = unitTypeSupply?.key ?? unit ?? ''
      if (!units.includes(unit.toLowerCase())) {
        return {
          eiq: 0,
          total: 0,
          unit: 'kg',
        }
      }
      return {
        total,
        eiq,
        unit,
      }
    }
  )

  if (surface === 0) {
    return 0
  }

  return suppliesList?.length
    ? calculateEIQWithList(surface, suppliesList || [], 2)
    : 0
}

/**
 * GET EIQ FROM SUPPLY
 *
 * @param {*} supply
 * @returns
 */
export const getEiqOfSupply = ({ supply, eiqTotal }) => {
  const regExp = /^[+-]?\d+(\.\d+)?$/

  return regExp.test(supply?.eiqTotal)
    ? supply.eiqTotal
    : regExp.test(eiqTotal)
    ? eiqTotal
    : undefined
}

export const getAchievementEiq = ({ supplies, lotsWithSurface }) => {
  const surfaceRealized = lotsWithSurface.reduce(
    (subAccumulator, { surfaceRealized, surface }) =>
      subAccumulator + (surfaceRealized || surface),
    0
  )
  return calculateEiq(supplies, surfaceRealized)
}
