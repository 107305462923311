import { lots as type } from '../types'

export const lotsActions = {
  setLotsUnsynchronized: (payload) => ({
    type: type.SET_LOTS_UNSYNCHRONIZED,
    payload,
  }),
  clearLotsUnsynchronizedByDraftId: (payload) => ({
    type: type.CLEAR_LOTS_UNSYNCHRONIZED,
    payload,
  }),
  clearLotsUnsynchronized: () => ({
    type: type.CLEAR_LOTS_UNSYNCHRONIZED,
  }),
}
