/**
 * Enum for notification types.
 * @readonly
 * @enum {string}
 */
export const EActiveAgreementTypes = {
  EXPLO: 'EXPLO',
  SEED_USE: 'SEED_USE',
  EVIDENCE_CFT: 'EVIDENCE_CFT',
  SUSTAINABILITY_2BSVS: 'SUSTAINABILITY_2BSVS',
  SUSTAINABILITY_EPA: 'SUSTAINABILITY_EPA',
  SUSTAINABILITY_MOA: 'SUSTAINABILITY_MOA',
  SUSTAINABILITY_CAN: 'SUSTAINABILITY_CAN',
  SELFDEC: 'SELFDEC',
  SELFDEV: 'SELFDEV',
  SELFDEM: 'SELFDEM',
  SELFDEA: 'SELFDEA',
  INPUTSIN: 'INPUTSIN',
  SUSTAINABILITY: 'SUSTAINABILITY',
  RESPONSIBLE_FERT: 'RESPONSIBLE_FERT',
  SEED_PROD: 'SEED_PROD',
  ADD_INFO: 'ADD_INFO',
  CALCULATION_2BSVS: '2BSVS_CALCULATION',
  WATER_CALCULATION: 'WATER_CALCULATION',
}

export const EquivalentSeeds = {
  COLZA: 'RAPESEED',
  SOY: 'SOYBEANS',
}

export const TypeAgreementNeedSurfaceFarm = {
  SUSTAINABILITY_2BSVS: false,
  SUSTAINABILITY_EPA: false,
  SUSTAINABILITY_MOA: false,
  SUSTAINABILITY_CAN: false,
}

export const AgreementTypeLazyLoadEvidence = {
  SUSTAINABILITY_2BSVS: true,
  SUSTAINABILITY_EPA: true,
  SUSTAINABILITY_MOA: true,
  SUSTAINABILITY_CAN: true,
}

export const AgreementSubTypeForStatementService = {
  FORM: true,
}
