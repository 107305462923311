import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { MenuDefault } from '../MenuDefault'
import { ItemMenuDefault } from '../ItemMenuDefault'

export const UnassociatedLicenseMenu = ({ onPress }) => {
  const { t } = useContext(LanguageContext)

  const handleCropMenuOptions = async () => {
    if (onPress) {
      onPress()
    }
  }
  return (
    <MenuDefault>
      <ItemMenuDefault
        onPress={handleCropMenuOptions}
        title={t(
          'COMMON.COMPONENTS.UNASSOCIATE_LICENSE_MENU.UNASSOCIATE_LICENSE'
        )}
      />
    </MenuDefault>
  )
}

UnassociatedLicenseMenu.propTypes = {
  onPress: PropTypes.func.isRequired,
}
