export default {
  FIELD_1: {
    LABEL: 'E-Mail',
    PLACEHOLDER: 'Ingrese su email',
  },
  FIELD_2: {
    LABEL: 'Nombre',
    PLACEHOLDER: 'Ingrese su nombre',
  },
  FIELD_3: {
    LABEL: 'Apellido',
    PLACEHOLDER: 'Ingrese su apellido',
  },
  FIELD_4: {
    LABEL: 'Teléfono',
    PLACEHOLDER: 'Ingrese su teléfono',
  },
  FIELD_5: {
    LABEL: 'PIN',
    PLACEHOLDER: 'Ingrese su pin',
    HELPTEXT: 'Dejalo en blanco para mantener tu código',
    TITLE: 'PIN de Seguridad',
    SUBTITLE:
      'Ayudará a verificar tu identidad en las actividades que realices.',
  },
  TEXT_1: 'Actualizar',
  TEXT_2: 'Perfil guardado con éxito',
}
