export default {
  TEXT_1: 'lotes selecionados',
  TEXT_2: 'Campo obrigatório',
  TEXT_3: 'Selecionar Lotes',
  TEXT_4: 'Data de observação',
  TEXT_5: 'Unidade de rendimento',
  TEXT_6: 'Estimação de rendimento',
  TEXT_7: 'Observação',
  TEXT_8: 'Estimação de coleita',
}
