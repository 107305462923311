import React from 'react'
import { ItemMenu } from '@common/components/CropMenuWeb/components/ItemMenu'
import { Platform } from 'react-native'
import { Menu } from 'react-native-paper'
import { ItemMenuDefaultProps } from './ItemMenuDefault.props'

export const ItemMenuDefault = ({
  children,
  onPress,
  disabled,
  title,
  titleStyle,
  itemStyle,
}: ItemMenuDefaultProps) => {
  const ComponentMenuDefault = Platform.OS === 'web' ? ItemMenu : Menu.Item
  return (
    //@ts-ignore
    <ComponentMenuDefault
      onPress={onPress}
      disabled={disabled}
      title={title}
      titleStyle={titleStyle}
      style={itemStyle}
    >
      {children}
    </ComponentMenuDefault>
  )
}
