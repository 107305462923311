import { FC, ReactElement, useContext } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'

import { LanguageContext } from '@contextState/language'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'
import SetupScreen from 'src/screens/SetupScreen'
import OfflineQueueScreen from 'src/screens/OfflineQueueScreen'

const Stack = createStackNavigator()

export const SetupStack: FC<any> = (): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const stacks = [
    {
      name: RouterNames.SETUP,
      component: SetupScreen,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_2'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_2')}
            drawer={Platform.OS !== 'web'}
          />
        ),
      },
    },
    {
      name: RouterNames.OFFLINE_QUEUE,
      component: OfflineQueueScreen,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_3'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_3')}
            goBack
          />
        ),
      },
    },
  ]

  return (
    <Stack.Navigator initialRouteName={RouterNames.SETUP}>
      {stacks.map((stack, key) => (
        <Stack.Screen {...stack} key={`SETTINGS_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
