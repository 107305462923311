import React, { useContext } from 'react'
import { StyleSheet, TouchableOpacity, Text } from 'react-native'
import { TextInput } from 'react-native-paper'
import { DatePickerModal } from 'react-native-paper-dates'
import moment from 'moment'
import { LanguageContext } from '@contextState/language'

function MobileWebPicker({
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
  placeholder,
  label,
  helpText,
  readOnly,
  ...other
}) {
  const { t } = useContext(LanguageContext)
  const [visible, setVisible] = React.useState(false)
  const onDismiss = React.useCallback(() => {
    setVisible(false)
    setFieldTouched(field.name)
  }, [setVisible])

  const isInvalid = errors[field.name] && touched[field.name]
  return (
    <>
      <TouchableOpacity
        disabled={readOnly}
        onPress={() => setVisible(true)}
        style={{ flex: 1 }}
      >
        <TextInput
          pointerEvents='none'
          style={[styles.input, !helpText && !isInvalid ? styles.spacing : {}]}
          mode='outlined'
          autoCapitalize='none'
          placeholder={placeholder}
          label={label}
          {...field}
          editable={false}
          value={field.value && moment(field.value).format('DD/MM/YYYY')}
          disabled={readOnly}
          error={isInvalid}
          {...other}
        />
        {isInvalid && (
          <Text style={styles.errorText}>{errors[field.name]}</Text>
        )}
      </TouchableOpacity>
      <DatePickerModal
        mode='single'
        visible={visible}
        date={field.value ? new Date(field.value) : new Date()}
        onDismiss={onDismiss}
        onConfirm={(date) => {
          setFieldValue(field.name, date.date, false)
          setVisible(false)
        }}
        saveLabel={t('COMPONENTS').COMMON.MOBILE_WEB_PICKER.TEXT_1}
      />
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    right: 8,
    top: 14,
    backgroundColor: 'transparent',
  },
  helpText: {
    marginTop: 4,
    marginBottom: 16,
    fontSize: 12,
    color: 'rgba(0,0,0,0.6)',
  },
  errorText: {
    marginTop: 4,
    marginBottom: 8,
    fontSize: 12,
    color: 'rgb(176, 0, 32)',
  },
  input: {
    backgroundColor: 'white',
  },
  spacing: {
    marginBottom: 16,
  },
})

export default MobileWebPicker
