import React, { useState, useContext, useEffect, useCallback } from 'react'
import {
  View,
  Text,
  StyleSheet,
  Image,
  TextInput,
  TouchableHighlight,
  Platform,
} from 'react-native'
import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import CachedImage from '@components/common/CachedImage'
import { formatSurface, truncateSurface } from '@common/utils'
import { alertText, primary500 } from '@styles/palette'
import { SIZES } from '@common/components/icons/interfaces'
import { blackHighEmphasis } from '@common/styles'
import { EditFieldSurfaceProps } from './EditFieldSurface.props'

export const EditFieldSurface = ({
  id,
  name,
  cityName,
  provinceName,
  imageUrl,
  surface,
  surfacePlanned,
  surfaceRealized,
  onPressAdd,
  onPressSubtract,
  editSurfaceLot,
  readOnly = false,
  hideSurfacePlanned,
  areaUnit = '',
  indexFarm,
  indexField,
  error,
}: EditFieldSurfaceProps) => {
  const { t } = useContext(LanguageContext)

  const [editSurfaces, onChangeEditSurface] = useState(
    surfaceRealized.toString()
  )

  useEffect(() => {
    editSurfaceInput(id, truncateSurface(Number(editSurfaces)).toString())
  }, [editSurfaces])

  useEffect(() => {
    editSurface(surfaceRealized)
  }, [surfaceRealized])

  const editSurface = (surfaceRealized: number) => {
    onChangeEditSurface(surfaceRealized.toString())
  }

  const editSurfaceInput = useCallback(
    (id: string, editSurfaces: string) => {
      const surfaceInput = truncateSurface(Number(editSurfaces))
      if (indexFarm !== undefined && indexField !== undefined) {
        editSurfaceLot(id, surfaceInput, indexFarm, indexField)
      }
    },
    [editSurfaces]
  )

  const limitDecimal = (value: string) => {
    value = value.replace(',', '.')
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      onChangeEditSurface(value)
    }
  }

  return (
    <>
      <View key={id}>
        <View style={styles.locationContainer}>
          <CommonIcon name={'CHECK'} size={SIZES.MEDIUM} color={primary500} />

          <View style={styles.locationTextContainer}>
            <Text style={styles.descriptionTitle}>{name}</Text>
            {(Boolean(cityName) || Boolean(provinceName)) && (
              <Text style={styles.descriptionSubTitle}>{`${cityName}${
                cityName && provinceName ? ', ' : ''
              }${provinceName}`}</Text>
            )}
          </View>

          <View style={styles.imageContainer}>
            {Platform.OS === 'web' ? (
              <Image
                source={{
                  uri: imageUrl,
                }}
                style={styles.image}
              />
            ) : imageUrl ? (
              //@ts-ignore
              <CachedImage
                source={{
                  uri: imageUrl,
                }}
                style={styles.image}
              />
            ) : (
              false
            )}
          </View>
        </View>

        <View style={styles.contentDescription}>
          <View style={styles.contentDescriptionTitle}>
            <Text style={styles.descriptionTitleSurface}>
              {t('VIEWS.ACHIEVEMENT_LOTS_SURFACE.TEXT_2')}
            </Text>
            <Text style={styles.descriptionTitleSurface}>
              {formatSurface(surface, areaUnit)}
            </Text>
          </View>

          {!hideSurfacePlanned && surfacePlanned && (
            <View style={styles.contentDescriptionTitle}>
              <Text style={styles.descriptionTitleSurface}>
                {t('VIEWS.ACHIEVEMENT_LOTS_SURFACE.TEXT_3')}
              </Text>

              <Text style={styles.descriptionTitleSurface}>
                {formatSurface(surfacePlanned, areaUnit)}
              </Text>
            </View>
          )}
        </View>

        <View style={styles.contentSurfaceRealize}>
          <View>
            <Text style={styles.surfaceRealizeTitle}>
              {t('VIEWS.ACHIEVEMENT_LOTS_SURFACE.TEXT_4')}
            </Text>
            <Text style={styles.surfaceRealizeTitle}>
              {t('VIEWS.ACHIEVEMENT_LOTS_SURFACE.TEXT_5')}
            </Text>
          </View>
          <View style={styles.contentDescriptionImage}>
            {!readOnly && (
              <TouchableHighlight
                onPress={() =>
                  onPressSubtract(Number(editSurfaces), indexFarm, indexField)
                }
              >
                <View style={styles.button}>
                  <Text>
                    <CommonIcon
                      name={'MINUS'}
                      size={SIZES.MEDIUM}
                      color={blackHighEmphasis}
                    />
                  </Text>
                </View>
              </TouchableHighlight>
            )}

            <TextInput
              style={[styles.input, error ? styles.inputError : {}]}
              onChangeText={(value) => limitDecimal(value)}
              value={editSurfaces.toString()}
              keyboardType='numeric'
              editable={!readOnly}
            />

            {!readOnly && (
              <TouchableHighlight
                onPress={() =>
                  onPressAdd(Number(editSurfaces), indexFarm, indexField)
                }
              >
                <View style={styles.button}>
                  <Text>
                    <CommonIcon
                      name={'PLUS'}
                      size={SIZES.MEDIUM}
                      color={blackHighEmphasis}
                    />
                  </Text>
                </View>
              </TouchableHighlight>
            )}
          </View>
        </View>
        {error && (
          <View style={styles.contentErrorSurface}>
            <Text style={styles.textErrorSurface}>
              {t('VIEWS.ACHIEVEMENT_LOTS_SURFACE.ERROR_SURFACE')}
            </Text>
          </View>
        )}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  locationContainer: {
    flexDirection: 'row',
    marginVertical: 10,
    width: '100%',
  },
  locationTextContainer: {
    flex: 1,
  },
  imageContainer: {
    height: 56,
    width: 100,
    marginLeft: 15,
  },
  image: {
    height: 56,
    width: 100,
  },
  container: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  contentTitle: {
    paddingVertical: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
  },
  contentDescription: {
    marginTop: 10,
    marginLeft: 20,
  },
  contentDescriptionTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  descriptionContainer: {
    flex: 1,
    marginBottom: 15,
  },
  descriptionTitle: {
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 10,
  },
  descriptionSubTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333333',
  },
  contentDescriptionImage: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  contentDescriptionSubTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  descriptionTitleSurface: {
    fontSize: 16,
    fontWeight: '400',
    color: '#333333',
    marginBottom: 10,
  },
  contentSurfaceRealize: {
    flexDirection: 'row',
    marginLeft: 20,
    marginBottom: 10,
  },
  surfaceRealizeTitle: {
    fontSize: 16,
    fontWeight: '400',
  },
  input: {
    width: 105,
    padding: 8,
    backgroundColor: '#EEEEEE',
    fontSize: 16,
    fontWeight: '700',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    padding: 10,
    marginRight: 4,
    marginLeft: 4,
  },
  icon: {
    marginRight: 16,
  },
  contentErrorSurface: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: 3,
    marginBottom: 10,
  },
  textErrorSurface: {
    width: 200,
    fontSize: 10,
    fontWeight: '400',
    color: alertText,
  },
  inputError: {
    color: alertText,
  },
})
