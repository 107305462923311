import React from 'react'
import { View, Platform } from 'react-native'
import LottieView from 'lottie-react-native'
import LoadingAnimation from '@assets/animations/lottie/loading'

let Lottie
if (Platform.OS === 'web') {
  Lottie = require('react-lottie').default
}

export const LottieAnimation = ({ animation, width, height, animationRef }) => {
  const styleMobile = { width, height }
  const lottieWeb = () => {
    return (
      <Lottie
        ref={animationRef}
        options={{
          loop: false,
          autoplay: false,
          animationData: animation ? animation : LoadingAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={width}
        width={height}
      />
    )
  }

  const lottieMobile = () => {
    return (
      <LottieView
        style={styleMobile}
        source={animation ? animation : LoadingAnimation}
        autoPlay={false}
        loop={false}
        ref={animationRef}
      />
    )
  }
  return <View>{Platform.OS === 'web' ? lottieWeb() : lottieMobile()}</View>
}
