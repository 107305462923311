import EMPTY_STATE_INPUT from '../../components/EmptyStateInput/translate/en'

export default {
  NAVIGATION: {
    TITLE: 'Filter',
  },
  CLEAR: 'Clear',
  FIELD_NAME: 'Field Name',
  CROP_NAME: 'Crop name',
  TYPE_OF_CROP: 'Type of crop',
  ACTIVITY: 'Activity',
  SEE_MORE: 'See more',
  APPLY: 'Apply',
  COMPONENTS: {
    EMPTY_STATE_INPUT,
  },
}
