import { uniq } from 'lodash'
import useNetwork from '@utils/network'
import getDatabase from './getDataBase'

const SELECT_CROP_BY_ID_QUERY = 'SELECT * FROM individuals_crops WHERE _id = ?;'
const UPDATE_CROP_QUERY = 'UPDATE individuals_crops SET data = ? WHERE _id = ?;'

function useOfflineCommon() {
  const db = getDatabase('db.offlinedata')
  const { doRequest } = useNetwork()

  const createTable = async (tableName) => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction(
        (tx) => {
          tx.executeSql(
            `CREATE TABLE IF NOT EXISTS ${tableName} (id integer primary key not null, _id text, data text)`
          )
          resolve()
        },
        (_, err) => console.warn(`ERROR: CREATE TABLE ${tableName}`, err)
      )
    })

    return promise
  }

  function initOfflineCommon() {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve) => {
      await createTable('individuals_crops')
      await createTable('individuals_activities')
      resolve()
    })

    return promise
  }

  async function storeActivities(crop) {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve) => {
      if (!db) {
        return resolve()
      }
      const pending = crop.pending.map((el) => el._id)
      const toMake = crop.toMake.map((el) => el._id)
      const done = crop.done.map((el) => el._id)
      const finished = crop.finished.map((el) => el._id)
      const ids = uniq([...pending, ...toMake, ...done, ...finished])

      const response = await doRequest({
        method: 'GET',
        url: `activities?ids=${JSON.stringify(ids)}`,
        displayAlert: false,
      })

      const activities = response.data

      if (activities.length > 0) {
        await createTable('individuals_activities')
        activities.forEach((el) => {
          db.transaction((tx) => {
            tx.executeSql(
              'INSERT INTO individuals_activities (_id, data) values (?, ?)',
              [el._id, JSON.stringify(el)]
            )
          })
        })
      }
      resolve()
    })

    return promise
  }

  async function storeIndividual(name, id, data) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          `INSERT INTO individuals_${name} (_id, data) values (?,?)`,
          [id, JSON.stringify(data)],
          () => resolve(),
          (_, err) => console.warn(`ERR storing individuals: ${err}`)
        )
      })
    })

    return promise
  }

  async function storeAtIndividuals(name, id, params = {}) {
    const version = name === 'crops' ? 'v2' : 'v1'
    const response = await doRequest({
      method: 'GET',
      url: `${name}/${id}`,
      version,
      params,
      displayAlert: false,
    })

    const dataToInsert = response.data

    dataToInsert.establishments = dataToInsert.establishments.map((farm) => ({
      ...farm,
      lots: farm.lots.map((field) => ({
        ...field,
        area: null,
        geometryData: null,
        wkt: null,
      })),
    }))

    dataToInsert.toMake = dataToInsert.toMake.map((activity) => ({
      ...activity,
      establishments: activity.establishments.map((farm) => ({
        ...farm,
        lots: farm.lots.map((field) => ({
          ...field,
          area: null,
          geometryData: null,
          wkt: null,
        })),
      })),
    }))

    dataToInsert.done = dataToInsert.done.map((activity) => ({
      ...activity,
      establishments: activity.establishments.map((farm) => ({
        ...farm,
        lots: farm.lots.map((field) => ({
          ...field,
          area: null,
          geometryData: null,
          wkt: null,
        })),
      })),
    }))

    dataToInsert.finished = dataToInsert.finished.map((activity) => ({
      ...activity,
      establishments: activity.establishments.map((farm) => ({
        ...farm,
        lots: farm.lots.map((field) => ({
          ...field,
          area: null,
          geometryData: null,
          wkt: null,
        })),
      })),
    }))

    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve) => {
      if (!db) {
        return resolve()
      }
      await createTable(`individuals_${name}`)
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT id, _id FROM individuals_${name} where _id = '${id}'`,
          [],
          (_, { rows }) => {
            if (rows.length === 0) {
              tx.executeSql(
                `INSERT INTO individuals_${name} (_id, data) values (?,?)`,
                [id, JSON.stringify(dataToInsert)],
                async () => {
                  if (name === 'crops') {
                    await storeActivities(response.data)
                  }
                  resolve()
                },
                (_, err) => console.warn(`ERR STORE ${name} individual: ${err}`)
              )
            } else {
              resolve()
            }
          }
        )
      })
    })

    return promise
  }

  function storeAchievement(data, activity) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve({})
      }
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM individuals_activities WHERE _id = '${activity}';`,
          [],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            if (results.length > 0) {
              const activityData = JSON.parse(results[0].data)
              activityData.achievements = activityData.achievements
                ? [...activityData.achievements, data]
                : [data]

              db.transaction((tx) => {
                tx.executeSql(
                  'UPDATE individuals_activities SET data = ? where _id = ?;',
                  [JSON.stringify(activityData), activity],
                  () => resolve(),
                  (_, err) => console.warn(err)
                )
              })
            } else {
              resolve({})
            }
          },
          (_, err) => console.warn(`ERR store achievements individual: ${err}`)
        )
      })
    })

    return promise
  }

  function showIndividuals(name, id) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve({})
      }
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM individuals_${name} WHERE _id = ?`,
          [id],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            if (results.length > 0) {
              resolve(JSON.parse(results[0]?.data))
            } else {
              resolve({})
            }
          },
          (_, err) => console.warn(`ERR SHOW ${name} individual: ${err}`)
        )
      })
    })

    return promise
  }

  const findOneIndividual = async (name) => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve(null)
      }

      db.transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM individuals_${name} LIMIT 1;`,
          [],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            if (results.length > 0) {
              resolve(JSON.parse(results[0]?.data))
            } else {
              resolve(null)
            }
          },
          (_, err) => console.warn(`ERR SHOW ${name} individual: ${err}`)
        )
      })
    })

    return promise
  }

  function deleteIndividuals(name, id) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          `DELETE FROM individuals_${name} WHERE _id = '${id}'`,
          [],
          () => resolve(),
          (_, err) => console.warn(`ERR DELETE ${name} individual: ${err}`)
        )
      })
    })

    return promise
  }

  function deleteIndividualCrop(crop) {
    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise(async (resolve) => {
      if (!db) {
        return resolve()
      }
      let count = 0
      const pending = crop.pending
        .filter((element) => element)
        .map((el) => el._id)
      const toMake = crop.toMake
        .filter((element) => element)
        .map((el) => el._id)
      const done = crop.done.filter((element) => element).map((el) => el._id)
      const finished = crop.finished
        .filter((element) => element)
        .map((el) => el._id)
      const ids = uniq([...pending, ...toMake, ...done, ...finished])
      for (const id of ids) {
        countIndividuals('activities', id).then((count) => {
          if (count > 0) {
            deleteIndividuals('activities', id)
          }
        })
      }
      count = await countIndividuals('crops', crop._id)
      if (count > 0) {
        await deleteIndividuals('crops', crop._id)
      }
      resolve()
    })

    return promise
  }

  function deleteAllIndividuals(name) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          `DELETE FROM individuals_${name}`,
          [],
          () => resolve(),
          (_, err) => console.warn(`ERR DELETE ${name} individual: ${err}`)
        )
      })
    })

    return promise
  }

  function countIndividuals(name, id) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve(0)
      }
      db.transaction((tx) => {
        tx.executeSql(
          `SELECT COUNT(id) AS count FROM individuals_${name} WHERE _id = '${id}'`,
          [],
          (_, { rows }) => {
            const results = rows._array ?? Array.from(rows)
            resolve(results[0].count)
          },
          (_, err) => console.warn(`ERR COUNT ${name} individual: ${err}`)
        )
      })
    })

    return promise
  }

  const updateFarmsInCrop = async ({ cropId, farms }) => {
    const { error: errorFindCrop, crop } = await findCropById({ cropId })

    if (errorFindCrop) {
      return {
        error: errorFindCrop,
      }
    }

    const dataToUpdate = parseDataToUpdateFarmsInCrop({ crop, farms })

    const { errorUpdateCrop } = await updateCrop(dataToUpdate)

    if (errorUpdateCrop?.length) {
      return {
        error: errorUpdateCrop,
      }
    }

    return {}
  }

  const findCropById = async ({ cropId }) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return null
        }

        db.transaction((tx) => {
          tx.executeSql(
            SELECT_CROP_BY_ID_QUERY,
            [cropId],
            (tx, { rows }) => {
              const results = rows._array ?? Array.from(rows)

              resolve({
                crop: results.length
                  ? results.map((element) => JSON.parse(element.data))[0]
                  : null,
              })
            },
            (_, error) => {
              console.warn(`ERROR Find Crop By Id`)
              console.warn(error)

              reject({
                error,
              })
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.warn(`ERROR Find Crop By Id`)
      console.warn(error)

      return {
        error,
      }
    }
  }

  const parseDataToUpdateFarmsInCrop = ({ crop, farms }) => {
    const newDraft = {
      ...crop,
      establishments: farms,
    }

    const additionalData = JSON.stringify(newDraft)

    const dataToUpdate = [additionalData, crop._id]

    return dataToUpdate
  }

  const updateCrop = async (dataToUpdate) => {
    try {
      const promise = new Promise((resolve, reject) => {
        if (!db) {
          return resolve([])
        }

        db.transaction((tx) => {
          tx.executeSql(
            UPDATE_CROP_QUERY,
            dataToUpdate,
            () => resolve([]),
            (_, error) => {
              console.error(`ERROR Update Crop`)
              console.error(error)

              reject([error])
            }
          )
        })
      })

      return promise
    } catch (error) {
      console.error(`ERROR Update Crop`)
      console.error(error)

      return [error]
    }
  }

  return {
    initOfflineCommon,
    storeAtIndividuals,
    deleteIndividuals,
    showIndividuals,
    deleteAllIndividuals,
    storeIndividual,
    storeAchievement,
    countIndividuals,
    deleteIndividualCrop,
    findOneIndividual,
    updateFarmsInCrop,
  }
}

export default useOfflineCommon
