import { FC, useContext, ReactElement, useEffect } from 'react'
import { StyleSheet } from 'react-native'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import InputText from '@components/inputs/InputText'
import { CommonIcon } from '@modules/common/components'
import { truncateSurface } from '@common/utils/truncateSurface'
import { SIZES } from '@common/components/icons/interfaces'
import { EEvidenceConcepts } from '@common/enum'

export const Statement2bsvsForm: FC<any> = ({
  onPressLotsInput,
  formik,
  areaUnit,
  readOnly,
}: any): ReactElement => {
  const { t } = useContext(LanguageContext)
  const { evidenceConcept: evidenceConcepts }: any = useContext(CommonContext)

  const { setFieldValue, values, setFieldTouched, touched, errors, resetForm } =
    formik

  useEffect(() => {
    const evidence2bsvs = values.evidences.find(
      ({ evidenceConcept: evidenceConceptId }: any) =>
        evidenceConceptId ===
        evidenceConcepts.find(
          ({ code }: any) => code === EEvidenceConcepts['0034']
        )._id
    )

    if (!evidence2bsvs) {
      setFieldValue('summaryEmissionCO2', undefined)
    }
  }, [evidenceConcepts, values.evidences])

  return (
    <>
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={SIZES.MEDIUM}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')}
        label={t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <InputText
        id='seedDryContent'
        containerStyle={styles.containerInput}
        value={values.seedDryContent}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_4.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_4.LABEL', {
          areaUnit,
        })}`}
        onBlur={() => setFieldTouched('seedDryContent')}
        touched={touched.seedDryContent}
        error={errors.seedDryContent}
        setFieldValue={setFieldValue}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
      />
    </>
  )
}

Statement2bsvsForm.displayName = 'Statement2bsvsForm'

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
})
