import React, { useState, useContext } from 'react'
import { PropTypes } from 'prop-types'
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from 'react-native'
import { Divider } from 'react-native-paper'

import { LanguageContext } from '@contextState/language'
import { LicenseContext } from '@contextState/license'

import useNetwork from '@utils/network'
import { useCompanyInfo } from '@common/hooks/useCompanyInfo'
import useModal from '@hooks/useModal'
import useValidatePin from '@hooks/useValidatePin'

import {
  SuccessPinModal,
  ModalError,
  ChildrenModalSuccessLicence,
} from './components'
import { VerifyPin } from '@modules/common/components'

import { blackHighEmphasis, primary500Disabled, white } from '@styles/palette'
import ButtonCustom from '@components/common/ButtonCustom'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import InputCheckbox from '@components/inputs/InputCheckbox'
import { useAuth } from '@common/hooks'
import RouterNames from 'src/constants/routerNames'

const LicenseSign = ({ navigation }) => {
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const {
    isModalVisible: isModalSuccess,
    toggleModal: toggleModalSuccess,
    closeModal: closeModalSuccess,
  } = useModal()
  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const { doRequest } = useNetwork()
  const {
    licenseSelected: { name, termsAndConditions, _id: licenseId },
  } = useContext(LicenseContext)
  const { t } = useContext(LanguageContext)

  const { user } = useAuth()
  const { config } = user
  const { companyId } = useCompanyInfo()

  const [checked, setChecked] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [visible, setVisible] = useState(false)
  const [visibleError, setVisibleError] = useState(false)
  const [numberLicense, setNumberLicense] = useState('')

  /**
   * HANDLE FIRM BUTTON
   * WITH VERIFICATE EXPIRATION PIN
   */
  const signPress = async ({ updatedPin = false }) => {
    if (config.hasPin || updatedPin) {
      if (verifyExpirationPin()) {
        toggleModal(true)
      } else {
        const { validate, tokenPin } = await geTokenAuthPin()
        if (!validate) {
          toggleModal(true)
          return
        }
        onSignLicense(tokenPin)
      }
    } else {
      navigation.navigate('CreatePin', {
        callbackPin,
      })
    }
  }

  const callbackPin = async () => {
    const { tokenPin } = await geTokenAuthPin()
    onSignLicense(tokenPin)
  }

  /**
   * HIDE DIALOG
   */
  const hideDialog = () => {
    setVisible(false)
  }

  /**
   * HANDLE CHECK
   */
  const checkPress = () => {
    if (!checked) {
      setVisible(true)
    }
    denyTerm()
  }

  /**
   * DENY TERM DIALOG
   */
  const denyTerm = () => {
    setDisabled(true)
    setChecked(false)
  }

  /**
   * ACCEPT TERM DIALOG
   */
  const acceptTerm = () => {
    setDisabled(false)
    setChecked(true)
  }

  /**
   * SIGN LICENSE
   *
   * @param {*} tokenPin
   */
  const onSignLicense = async (tokenPin) => {
    setVisibleError(false)

    try {
      const headers = {
        'Validate-Token-Pin': `Bearer ${tokenPin}`,
      }

      const dataLicence = {
        companyId,
      }

      const response = await doRequest({
        method: 'POST',
        url: `licenses/${licenseId}/sign`,
        version: 'v2',
        data: dataLicence,
        headers,
        displayAlert: false,
      })

      //Close Modal PIN
      closeModal()
      if (response) {
        setNumberLicense(String(response.data.timestamp))
        toggleModalSuccess()
      } else {
        setVisibleError(true)
      }
    } catch (error) {
      closeModal()
      setVisibleError(true)
    }
  }

  /**
   * GO TO HOME
   */
  const goToHome = () => {
    closeModal()
    closeModalSuccess()
    setVisibleError(false)

    navigation.navigate(RouterNames.LICENSES_LIST)
  }

  const goToLicenseList = () => {
    closeModal()
    closeModalSuccess()
    setVisibleError(false)

    navigation.navigate(RouterNames.LICENSES_LIST)
  }

  return (
    <>
      <Divider />
      <View style={styles.container}>
        <Text style={styles.titleLicense}>{name}</Text>

        <Divider />

        <ScrollView style={styles.licenceView}>
          <View style={styles.term}>
            <Text style={styles.termText}>{termsAndConditions}</Text>
          </View>
        </ScrollView>

        <View style={styles.checked}>
          <InputCheckbox checked={checked} onPress={checkPress} />

          <Text style={styles.conditions}>
            {t('VIEWS').LICENSE_SIGN.TEXT_2}
          </Text>
        </View>

        <ButtonCustom
          onPress={signPress}
          styles={disabled ? styles.buttonDisabled : styles.button}
          disabled={disabled}
          uppercase={false}
        >
          {t('VIEWS').LICENSE_SIGN.TEXT_3}
        </ButtonCustom>
      </View>

      <ModalConfirm
        visible={visible}
        onConfirm={acceptTerm}
        onClose={hideDialog}
        onCancel={denyTerm}
        title={t('VIEWS.LICENSE_SIGN.TEXT_4')}
        contentText={t('VIEWS.LICENSE_SIGN.TEXT_5')}
        cancelText={t('VIEWS.LICENSE_SIGN.TEXT_6')}
        confirmText={t('VIEWS.LICENSE_SIGN.TEXT_7')}
      />

      <VerifyPin
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onSuccess={onSignLicense}
      />

      <SuccessPinModal
        isModalVisible={isModalSuccess}
        toggleModal={toggleModalSuccess}
        title={t('VIEWS.LICENSE_SIGN_V2.SIGNED_LICENSE')}
      >
        <ChildrenModalSuccessLicence
          onPressFirstButton={() => goToLicenseList()}
          numberLicense={numberLicense}
        />
      </SuccessPinModal>

      {visibleError && (
        <ModalError
          visible={visibleError}
          title={t('COMPONENTS').COMMON.MODAL_ERROR.TEXT_1}
          description={t('COMPONENTS').COMMON.MODAL_ERROR.TEXT_2}
        >
          <TouchableOpacity onPress={() => goToHome()}>
            <Text style={styles.goToCrop}>
              {t('VIEWS').LICENSE_SIGN.BACK_TO_LICENSES}
            </Text>
          </TouchableOpacity>
        </ModalError>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    flex: 1,
    paddingHorizontal: 20,
  },
  licenceView: {
    backgroundColor: white,
  },
  titleLicense: {
    fontSize: 16,
    paddingVertical: 16,
    fontWeight: '500',
  },
  checked: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
  },
  term: {
    marginHorizontal: '2.5%',
    marginTop: 10,
    marginBottom: 10,
  },
  termText: {
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'justify',
    lineHeight: 20,
  },
  button: {
    margin: 16,
    borderRadius: 8,
    marginBottom: 20,
  },
  buttonDisabled: {
    margin: 16,
    borderRadius: 8,
    marginBottom: 20,
    backgroundColor: primary500Disabled,
  },
  buttonText: {
    color: white,
    fontWeight: '700',
  },
  innerButtonStyle: {
    padding: 10,
  },
  alertTitle: {
    fontSize: 18,
    color: blackHighEmphasis,
    fontWeight: '500',
  },
  alertParagraph: {
    fontSize: 18,
    color: blackHighEmphasis,
    fontWeight: '500',
  },
  conditions: {
    fontWeight: '500',
    textDecorationLine: 'underline',
    marginLeft: 5,
  },
  goToCrop: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.75,
    fontWeight: '700',
    fontStyle: 'normal',
    color: white,
  },
})

LicenseSign.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default LicenseSign
