import { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'

import { CommonContext } from '@contextState/common'
import {
  initializeFirebase,
  firestoreDisableNetwork,
  firestoreEnableNetwork,
  signInAnonymously,
} from '@services/firebase'
import { useAuth, useSyncDrafts } from '@common/hooks'

export const useFirebase = () => {
  const { isConnected } = useSelector((state: any) => state.connectionReducer)
  const { isAuth, user, config } = useAuth()
  const {
    isFetchingCommonSuccess,
    isDraftsSynchronizing,
    displayToggleModalNotification,
  }: any = useContext(CommonContext)

  const [firestoreNetworkEnabled, setFirestoreNetworkEnabled] = useState(false)

  const { syncDrafts } = useSyncDrafts()

  const getFirestoreNetwork = async () => {
    if (!isConnected) {
      // await firestoreDisableNetwork()

      // setFirestoreNetworkEnabled(false)

      return
    }

    await firestoreEnableNetwork()

    setFirestoreNetworkEnabled(true)
  }

  const initSyncDrafts = async () => {
    await syncDrafts(user, config, displayToggleModalNotification)
  }

  useEffect(() => {
    initializeFirebase()
  }, [])

  useEffect(() => {
    getFirestoreNetwork()
  }, [isConnected])

  useEffect(() => {
    if (!isAuth || !user) {
      return
    }

    signInAnonymously(user)
  }, [isAuth, user])

  useEffect(() => {
    if (
      isDraftsSynchronizing ||
      !isConnected ||
      !isFetchingCommonSuccess ||
      !firestoreNetworkEnabled
    ) {
      return
    }

    initSyncDrafts()
  }, [
    isDraftsSynchronizing,
    isConnected,
    isFetchingCommonSuccess,
    firestoreNetworkEnabled,
  ])
}
