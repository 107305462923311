export default {
  TEXT_1: 'Agregar Colaborador',
  TEXT_2: 'AGREGAR COLABORADOR',
  FIELD_1: {
    PLACEHOLDER: 'País',
    LABEL: 'País',
  },
  FIELD_2: {
    PLACEHOLDER: 'CUIT',
    LABEL: 'CUIT',
  },
  FIELD_3: {
    PLACEHOLDER: 'Email',
    LABEL: 'Email',
  },
  FIELD_4: {
    PLACEHOLDER: 'Tipo de colaborador',
    LABEL: 'Tipo de colaborador',
  },
}
