import EMPTY_STATE_INPUT from '../../components/EmptyStateInput/translate/es'

export default {
  NAVIGATION: {
    TITLE: 'Filtrar',
  },
  CLEAR: 'Limpiar',
  FIELD_NAME: 'Nombre del campo',
  CROP_NAME: 'Nombre del cultivo',
  TYPE_OF_CROP: 'Tipo de cultivo',
  ACTIVITY: 'Actividad',
  SEE_MORE: 'Ver más',
  APPLY: 'Aplicar',
  COMPONENTS: {
    EMPTY_STATE_INPUT,
  },
}
