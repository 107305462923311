import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Animated,
} from 'react-native'
import { PropTypes } from 'prop-types'

import { formatDateMoment } from '@utils/date'
import { CommonIcon } from '@modules/common/components'
import {
  gray12,
  blackHighEmphasis,
  backgroundDefault,
  grayInput,
} from '@styles/palette'
import { DateActivity } from '../'
import activityTypes from '@constants/activityTypes'
import EiqCard from '@components/common/v1/EiqCard'
import { getEIQRange, calculateEiq } from '@utils/eiq'
import { roundNumber } from '@utils'
import { truncateSurface } from '@common/utils/truncateSurface'

export const HeaderAccordionPlanning = ({
  t,
  activity,
  locale,
  onPress,
  arrowAngle,
  isOpened,
  subTypeActivities,
  tag,
  isConnected,
}) => {
  const {
    dateStart,
    dateEnd,
    surface,
    areaUnit,
    subTypeActivity,
    supplies,
    lots,
    establishments,
    activityType,
  } = activity
  const styleClosed = !isOpened ? styles.stylesClosed : {}
  const valueSubtype = subTypeActivities.find(
    (subType) => subType?.value === subTypeActivity?._id
  )
  const activityTypeLabel =
    activityType?.codeLabel ?? activityTypes[tag].name(t)

  return (
    <View>
      <View style={styles.contentIcon}>
        <CommonIcon
          name={'CALENDAR-PLANIFY'}
          size={32}
          color={blackHighEmphasis}
        />
        <Text style={styles.textIcon}>
          {t(
            'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_ACCORDION_PLANNING.TEXT_1'
          )}
        </Text>
      </View>
      <View style={[styles.contentGray, styleClosed]}>
        <View style={styles.contentFlexRow}>
          <DateActivity
            text={t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_ACCORDION_PLANNING.TEXT_2'
            )}
            date={formatDateMoment(dateStart, 'DD/MMM/YYYY', locale, true)}
          />
          <DateActivity
            text={t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_ACCORDION_PLANNING.TEXT_3'
            )}
            date={formatDateMoment(dateEnd, 'DD/MMM/YYYY', locale, true)}
          />
        </View>
        <View style={[styles.contentFlexRow, styles.borderBottom]}>
          <Text>
            {`${establishments?.length} ${t(
              establishments?.length > 1
                ? 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_2'
                : 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_1'
            )}`}
            {' - '}
            {`${lots.length} ${t(
              lots.length > 1
                ? 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_4'
                : 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_3'
            )}`}
          </Text>
          <TouchableOpacity onPress={onPress}>
            <View>
              <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
                <CommonIcon name='CHEVRON-DOWN' size={25} color={grayInput} />
              </Animated.View>
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.contentAdditionalInfo}>
          <Text>
            {t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_ACCORDION_PLANNING.TEXT_5',
              { surface: truncateSurface(surface), areaUnit: areaUnit }
            )}
          </Text>
          {valueSubtype && (
            <Text>
              {t(
                'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_ACCORDION_PLANNING.TEXT_6',
                {
                  activityType: activityTypeLabel,
                  subTypeActivity: valueSubtype?.label,
                }
              )}
            </Text>
          )}
        </View>
        {tag === activityTypes.ACT_APPLICATION.key && isConnected && (
          <View style={styles.cardEiq}>
            <EiqCard
              title={`${t(
                'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.HEADER_ACCORDION_PLANNING.TEXT_7',
                { eiq: calculateEiq(supplies, surface) }
              )}`}
              subTitle={`${supplies.length} ${t(
                supplies.length > 1
                  ? 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_5'
                  : 'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMMON.TEXT_6'
              )}`}
              eiqRange={getEIQRange(calculateEiq(supplies, surface))}
            />
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentIcon: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textIcon: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
    marginLeft: 20,
  },
  contentGray: {
    backgroundColor: backgroundDefault,
    marginHorizontal: 7.5,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  contentFlexRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
  borderBottom: {
    borderBottomColor: gray12,
    borderBottomWidth: 1,
    marginHorizontal: 10,
    paddingHorizontal: 0,
    paddingBottom: 15,
  },
  contentAdditionalInfo: {
    padding: 10,
  },
  stylesClosed: {
    borderRadius: 4,
    marginBottom: 10,
  },
  cardEiq: {
    padding: 10,
  },
})

HeaderAccordionPlanning.propTypes = {
  t: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  arrowAngle: PropTypes.object,
  isOpened: PropTypes.bool,
  subTypeActivities: PropTypes.array,
  tag: PropTypes.string.isRequired,
  isConnected: PropTypes.bool.isRequired,
}
