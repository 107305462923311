import { useEffect, useState } from 'react'
import Constants from 'expo-constants'
import { Linking, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigation } from '@react-navigation/native'

import { useCompanyInfo } from './useCompanyInfo'
import { makeRequest } from '@common/services'

export enum MESSAGE_KEY {
  GO_TO_FIELD_STORY = 'GO_TO_FIELD_STORY',
  NUE_LINK_TO = 'NUE_LINK_TO',
  OPEN_BLANK_LINK = 'OPEN_BLANK_LINK',
}

interface QueryParams {
  cropId: string
  lotId?: string
}

const URL_FIELD_STORY = Constants?.expoConfig?.extra?.MS_FIELD_STORY_URL

export const useStoriesWebView = (path: string, query: QueryParams) => {
  const navigation = useNavigation()
  const { identifier: taxID } = useCompanyInfo()

  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [url, setUrl] = useState<string | null>(null)
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const token = await AsyncStorage.getItem('USER_TOKEN')
      setAccessToken(token)
    })()
  }, [])

  useEffect(() => {
    makeWebViewUrl()
    return () => {}
  }, [accessToken])

  const makeWebViewUrl = async () => {
    if (!accessToken) return null

    const params = {
      ...query,
      taxID,
      accessToken,
      isApp: 'true',
      date: `${new Date().getTime()}`,
    }

    let url: string

    if (path === 'crop-story') {
      const result = await makeRequest({
        url: `generate-link/${params.cropId}`,
        method: 'POST',
        data: { isExternal: false },
        apiUrl: URL_FIELD_STORY,
      })

      url = `${result.data.url}&accessToken=${accessToken}`
    } else {
      const result = await makeRequest({
        url: `generate-link/${params.cropId}/field/${params.lotId}`,
        method: 'POST',
        apiUrl: URL_FIELD_STORY,
      })

      url = `${result.data.url}&accessToken=${accessToken}`
    }

    setUrl(url)

    setIsReady(true)
  }

  const handleIFrameMessages = (event: any) => {
    const data = Platform.OS === 'web' ? event.data : event.nativeEvent.data
    if (typeof data !== 'string') {
      return
    }

    let dataWebView

    try {
      dataWebView = JSON.parse(data)
    } catch (error) {
      return
    }

    if (!dataWebView) {
      return
    }

    switch (dataWebView.key) {
      case MESSAGE_KEY.GO_TO_FIELD_STORY:
        // @ts-ignore
        navigation?.push('FieldStory', {
          id: query.cropId,
          fieldStory: true,
          lotId: dataWebView?.data?.queryParams?.lotId,
        })
        break
      case MESSAGE_KEY.NUE_LINK_TO:
        if (dataWebView?.data?.url) {
          Linking.openURL(dataWebView.data.url)
        }
        break
      case MESSAGE_KEY.OPEN_BLANK_LINK:
        if (dataWebView?.data?.url) {
          Linking.openURL(dataWebView.data.url)
        }
      default:
        break
    }

    return dataWebView
  }

  return {
    accessToken,
    url,
    isReady,
    handleIFrameMessages,
  }
}
