import React, { useEffect, useState } from 'react'
import {
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
} from 'react-native'
import Menu from '@material-ui/core/Menu'

import { Platform } from 'react-native'
import { CommonIcon } from '@common/components/icons'
import { SIZES } from '@common/components/icons/interfaces'
import { MenuWebProps } from './MenuWeb.props'

export const MenuWeb = ({ disabled, children, anchor }: MenuWebProps) => {
  const [anchorEl, setAnchorEl] = useState<any | null>(null)

  const handleClick = (event: GestureResponderEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOutsideClick = (event: { target: any }) => {
    const phoneCountryInputId = event.target.getAttribute('data-testid')
    if (
      anchorEl &&
      !anchorEl.contains(event.target) &&
      phoneCountryInputId !== 'phone_country'
    ) {
      setAnchorEl(null)
    }
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('mousedown', handleOutsideClick)
      }
    }
  }, [anchorEl])

  return (
    <>
      <TouchableOpacity onPress={handleClick}>
        {anchor ?? (
          <CommonIcon
            name={'DOTS-VERTICAL'}
            size={SIZES.MEDIUM}
            style={styles.dotsIcon}
            disabled={disabled}
          />
        )}
      </TouchableOpacity>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  )
}

const styles = StyleSheet.create({
  dotsIcon: {
    margin: 8,
  },
})
