import { Platform } from 'react-native'
import React, { useEffect } from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'

import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { farmActions } from '@store/actions'
import { useDispatch } from 'react-redux'
import { WebViewRender } from '@modules/webView/components'
import { useMakeUrl } from '@modules/webView/hooks'
import RouterNames from '@constants/routerNames'

const FarmMapToUrlWebView = ({ navigation }) => {
  const { isReady, urlWebView, isFullScreen, redirectUrl, callback } =
    useMakeUrl('createFarm')
  const dispatch = useDispatch()

  useEffect(() => {
    navigation.addListener('focus', () => {
      dispatch(farmActions.setFullScreen(isFullScreen))
    })
    navigation.addListener('beforeRemove', () => {
      dispatch(farmActions.setFullScreen(!isFullScreen))
    })
  }, [navigation, isReady])

  const goBack = () => {
    if (callback) {
      callback()
      return navigation.goBack()
    }

    navigation.navigate(redirectUrl ?? RouterNames.FARMS)
  }

  const validateStateChange = (url) => {
    if (url?.indexOf('/close') !== -1) {
      goBack()
    }
  }
  const handleWebViewNavigationStateChange = (newNavState) =>
    validateStateChange(newNavState?.url)

  const handleIFrameMessages = (event) => {
    const data = Platform.OS === 'web' ? event.data : event.nativeEvent.data
    if (data === 'close-iframe') {
      goBack()
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      {!urlWebView ? (
        <LoaderCenterContainer />
      ) : (
        <WebViewRender
          url={urlWebView}
          onNavigationStateChange={handleWebViewNavigationStateChange}
          onMessage={handleIFrameMessages}
        />
      )}
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
export default FarmMapToUrlWebView
