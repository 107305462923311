import React from 'react'
import { StyleSheet, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { white, black, gray400, gray200 } from '@modules/common/styles/palette'

export const Card = ({ style = {}, children }) => {
  return <View style={[styles.container, style]}>{children}</View>
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    marginTop: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: gray200,
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
  textTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 19,
    paddingHorizontal: 17,
    borderBottomWidth: 1,
    borderBottomColor: gray200,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: gray400,
  },
})

Card.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
