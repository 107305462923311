import { SafeAreaView, StyleSheet } from 'react-native'
import { useEffect, useState } from 'react'
import { WebViewRender } from '@modules/webView/components'
import { makeRequest } from '@common/services'
import { getUrlQuickSight } from '@services/amplify'
import { QuickSightTraderRouteProp } from '@modules/traders/interfaces'

import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import Constants from 'expo-constants'

const MS_AUTH_URL = Constants.expoConfig?.extra?.MS_AUTH_URL

const QuickSightTrader = ({ route }: { route: QuickSightTraderRouteProp }) => {
  const { params } = route
  const { dashboardId, dashboardType } = params

  const [quickSightUrl, setQuickSightUrl] = useState<string>('')

  useEffect(() => {
    setQuickSightUrl('')
    fetchInit()
  }, [dashboardId])

  const fetchInit = async () => {
    try {
      const url = await getUrlQuickSight({ dashboardId, dashboardType })

      const response = await makeRequest({
        method: 'GET',
        url,
        version: 'v2',
        prefix: 'api',
        apiUrl: MS_AUTH_URL,
      })

      const quickSightUrl = response.data?.quickSightUrl
        ? response.data?.quickSightUrl
        : response.data

      setQuickSightUrl(quickSightUrl)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      {!quickSightUrl ? (
        <LoaderCenterContainer />
      ) : (
        <WebViewRender url={quickSightUrl} />
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default QuickSightTrader
