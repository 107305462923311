export default {
  TEXT_1: 'Campo obrigatório',
  TEXT_2: 'Email inválido',
  TEXT_3: 'Não te encontramos no sistema',
  TEXT_4: 'CRIE A SUA CONTA',
  FIELD_1: {
    HELP_TEXT: 'O código de ingresso será-lhe enviado a esta conta',
    LABEL: 'Email',
    PLACEHOLDER: 'Digite seu email',
  },
  FIELD_2: {
    LABEL: 'Nome*',
    PLACEHOLDER: 'Insire seu nome',
  },
  FIELD_3: {
    LABEL: 'Sobrenome*',
    PLACEHOLDER: 'Insire seu sobrenome',
  },
  FIELD_4: {
    LABEL: 'Telefone celular*',
    PLACEHOLDER: 'Insire seu telefone',
  },
  TEXT_5: 'Eu aceito as políticas de uso',
  TEXT_6: 'Obter código de acesso',
  TEXT_7: 'He leído y acepto los',
  TEXT_8: 'T&C / política de privacidade',
  PRIVACY_POLICY_URL: 'pt/politica-de-privacidade/',
}
