import AsyncStorage from '@react-native-async-storage/async-storage'
import { persistReducer, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers'
import rootSaga from './sagas/index'
import { configureStore } from '@reduxjs/toolkit'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['draftCrop', 'lots', 'auth', 'companies', 'activities'],
  blacklist: ['collaborators', 'selectionPageReducer'],
}

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: [...middleware],
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga)

const persistor = persistStore(store)

export { store, persistor }

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
