import { useState, useContext } from 'react'
import Axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { errorMap } from '@utils/constants'
import { LanguageContext } from '@contextState/language'
import Constants from 'expo-constants'

const EXPO_API_HOST = Constants?.expoConfig?.extra?.EXPO_API_HOST || ''
export const apiUrl = EXPO_API_HOST.replace('/v1', '')

const useNetwork = function () {
  const { t, i18n } = useContext(LanguageContext)

  const [loading, setLoading] = useState(false)

  /**
   *
   * @param {object} params
   * @returns {import('axios').AxiosPromise}
   */
  const doRequest = function ({
    method,
    url,
    data,
    params,
    headers = {
      'Content-Type': 'application/json',
    },
    version = 'v1',
    displayAlert = true,
    needAuthorizationHeaders = true,
    onlyUrl = false,
  }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolver, reject) => {
      try {
        setLoading(true)

        headers.Accept = 'application/json'

        if (needAuthorizationHeaders) {
          const token = await AsyncStorage.getItem('USER_TOKEN')

          if (token) {
            headers.Authorization = `Bearer ${token ?? ''}`
          }
        }

        const locale = await AsyncStorage.getItem('language')

        if (locale) {
          headers['Accept-Language'] = locale
        }

        const options = {
          method,
          url: onlyUrl ? `${apiUrl}/${url}` : `${apiUrl}/${version}/${url}`,
          data,
          params,
          headers,
        }

        if (method.toLowerCase() === 'get') {
          delete options.data
        }

        if (!options.params) {
          delete options.params
        }

        const response = await Axios(options)

        setLoading(false)

        return resolver(response)
      } catch (err) {
        const { status = 500, data = {} } = err?.response || {}
        const { code, message = 'Bad Request' } = data
        let alertMessage, responseError

        if (code) {
          alertMessage =
            errorMap
              .find((error) => error.data === err.response.data.code)
              ?.message(t) ?? message
          responseError = { status, ...data }
        }

        if (status === 400 && !responseError) {
          alertMessage =
            errorMap
              .find((error) => error.data === err?.response?.data)
              ?.message(t) ?? message
          responseError = { status, ...data }
        }

        if (status !== 401 && status !== 404 && !responseError) {
          if (t) {
            alertMessage = t('UTILS').NETWORK.TEXT_1
          }
        }

        if (displayAlert && alertMessage) {
          alert(`${alertMessage}`)
        }

        if (!responseError) {
          const error = data?.error || err.toString()
          responseError = { status, error }
        }
        console.warn(
          `ERROR AT ${apiUrl}/${version}/${url}`,
          err?.response || err
        )

        setLoading(false)

        return reject(responseError)
      }
    })
  }

  return { doRequest, loading }
}

export default useNetwork
