import getDatabase from './getDataBase'
export const CREATE_TABLE_QUERY_USER =
  'CREATE TABLE IF NOT EXISTS user (id integer primary key not null, data text, _id text);'

function useOfflineUser() {
  const db = getDatabase('db.offlinedata')

  const initOfflineUser = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }

      db.transaction((tx) => {
        tx.executeSql(
          CREATE_TABLE_QUERY_USER,
          [],
          () => {
            resolve()
          },
          (_, err) => console.warn(err)
        )
      })
    })

    return promise
  }

  function syncUser(data) {
    if (!db) {
      console.warn('Database is not supported')
      return null
    }
    db.transaction((tx) => {
      tx.executeSql(
        'SELECT id, _id from user where _id = ?',
        [data._id],
        (_, { rows }) => {
          if (rows.length === 0) {
            tx.executeSql(
              'INSERT INTO user (_id, data) values (?, ?)',
              [data._id, JSON.stringify(data)],
              null,
              (_, err) => console.warn(err)
            )
          } else {
            tx.executeSql(
              'UPDATE user SET data = ? where _id = ?',
              [JSON.stringify(data), data._id],
              () => {},
              (_, err) => console.warn(`USER OFFLINE UPDATE ERROR ${err}`)
            )
          }
        }
      )
    })
  }

  function selectUser() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve(null)
      }
      db.transaction((tx) => {
        tx.executeSql('SELECT * from user', [], (_, { rows }) => {
          const results = rows._array ?? Array.from(rows)
          if (results.length) {
            resolve(JSON.parse(results[0].data))
          }
        })
      })
    })

    return promise
  }

  function deleteUser() {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }

      db.transaction((tx) => {
        tx.executeSql('DELETE from user', [], () => {
          resolve()
        })
      })
    })

    return promise
  }

  return { initOfflineUser, syncUser, selectUser, deleteUser }
}

export default useOfflineUser
