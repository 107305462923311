export default {
  DELETE_DRAFT: 'Deletar rascunho',
  CROP_STORY: 'História do cultivo',
  COLLABORATORS: 'Colaboradores',
  LICENSES: 'Licenças',
  INTEGRATIONS_WITH_THIRD_PARTIES: 'Itegração com terceiros',
  ENABLE_OFFLINE: 'Ativar offline',
  DISABLE_OFFLINE: 'Desativar offline',
  FARM_AND_FIELD_MANAGEMENT: 'Gerenciamento de campo e lote',
  DELETE_CROP: 'Deletar cultivo',
}
