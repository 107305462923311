import 'intl'
import 'intl/locale-data/jsonp/en'

import { useState, useEffect } from 'react'
import { Platform } from 'react-native'
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import * as SplashScreen from 'expo-splash-screen'
import * as Updates from 'expo-updates'
import { useFonts } from 'expo-font'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import Navigator from './src/navigator'
import { ContextProvider } from '@contextState/state'
import { store, persistor } from './src/store'

import { useConnection } from '@common/hooks'
import UcropitTheme from '@common/styles'

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    ...UcropitTheme.colors,
    // primary: '#4CAF50',
    // accent: '#FF5722',
  },
  fonts: {
    ...DefaultTheme.fonts,
    thin: {
      fontFamily: 'Inter',
    },
    light: {
      fontFamily: 'Inter',
    },
    regular: {
      fontFamily: 'Inter',
    },
    medium: {
      fontFamily: 'Inter',
    },
  },
}

// eslint-disable-next-line no-undef
const IS_DEV_MODE_ENABLED = __DEV__

SplashScreen.preventAutoHideAsync()

function Master() {
  const [isAppReady, setIsAppReady] = useState(false)
  const [isFontsLoaded] = useFonts({
    crop: require('@assets/icons/crop/font.ttf'),
    activity: require('@assets/icons/activity/font.ttf'),
    supplies: require('@assets/icons/supplies/font.ttf'),
    sustainability: require('@assets/icons/sustainability/font.ttf'),
    common: require('@assets/icons/common/font.ttf'),
    Inter_100_thin: require('@assets/fonts/inter/Inter-Thin.ttf'),
    Inter_200_extra_light: require('@assets/fonts/inter/Inter-ExtraLight.ttf'),
    Inter_300_light: require('@assets/fonts/inter/Inter-Light.ttf'),
    Inter: require('@assets/fonts/inter/Inter-Regular.ttf'),
    Inter_500_medium: require('@assets/fonts/inter/Inter-Medium.ttf'),
    Inter_600_semibold: require('@assets/fonts/inter/Inter-SemiBold.ttf'),
    Inter_700_bold: require('@assets/fonts/inter/Inter-Bold.ttf'),
    Inter_800_extrabold: require('@assets/fonts/inter/Inter-ExtraBold.ttf'),
    Inter_900_black: require('@assets/fonts/inter/Inter-Black.ttf'),
  })
  const { isConnected, isConnectionReady } = useSelector(
    (state) => state.connectionReducer
  )

  useEffect(() => {
    fetchUpdate()
  }, [])

  useEffect(() => {
    if (!isAppReady || !isConnectionReady || !isFontsLoaded) {
      return
    }

    SplashScreen.hideAsync()
  }, [isAppReady, isConnectionReady, isFontsLoaded])

  const fetchUpdate = async () => {
    if (!IS_DEV_MODE_ENABLED && isConnected && Platform.OS !== 'web') {
      let update

      try {
        update = await Updates.checkForUpdateAsync()
      } catch (error) {
        console.error(error)
      }

      if (update?.isAvailable) {
        try {
          await Updates.fetchUpdateAsync()
        } catch (error) {
          console.error(error)
        }

        return await Updates.reloadAsync()
      }
    }

    setIsAppReady(true)
  }

  if (!isAppReady || !isFontsLoaded) {
    return false
  }

  return <Navigator />
}

const InitialHooks = ({ children }) => {
  useConnection()

  return children
}

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <InitialHooks>
          <ContextProvider>
            <ActionSheetProvider>
              <PaperProvider theme={theme}>
                <Master />
              </PaperProvider>
            </ActionSheetProvider>
          </ContextProvider>
        </InitialHooks>
      </PersistGate>
    </Provider>
  )
}
