import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { Card } from '@modules/common/components'
import { outlierType } from '@constants/outlierData'
import Outlier from '@modules/common/components/Outlier'
import { Supply } from '..'
import {
  carbon400,
  blackHighEmphasis,
  cherry50,
  cherry200,
  cherry300,
  lemon100,
  lemon300,
  lemon400,
} from '@styles/palette'

export const SupplyPhytotherapicCard = ({
  outlier,
  unitArea,
  supplyTypeName,
  supplies = [],
}) => {
  if (!supplies.length) {
    return false
  }

  const { t } = useContext(LanguageContext)

  const cardStyle =
    outlier === outlierType.DANGER
      ? styles.cardDanger
      : outlier === outlierType.WARNING
      ? styles.cardWarning
      : {}

  return (
    <Card style={cardStyle}>
      <View style={styles.infoContainer}>
        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.SUPPLY_PHYTOTHERAPIC_CARD.SUPPLY_NAME_TITLE'
          )}
        </Text>

        <Text style={styles.textBold}>{supplyTypeName}</Text>

        {outlier && (
          <Outlier
            title={t('VIEWS.CROP_CREATE_CONFIRM.OUTLIER_TITLE')}
            type={outlier}
          />
        )}

        {supplies.map(
          (
            { code, name, company, quantity, total, unitType, eiqTotal },
            key
          ) => (
            <Supply
              key={key}
              unitArea={unitArea}
              code={code}
              name={name}
              company={company}
              quantity={quantity}
              total={total}
              unitType={unitType}
              eiqTotal={eiqTotal}
            />
          )
        )}
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  cardDanger: {
    backgroundColor: cherry50,
    borderColor: cherry200,
    shadowColor: cherry300,
  },
  cardWarning: {
    backgroundColor: lemon100,
    borderColor: lemon300,
    shadowColor: lemon400,
  },
  infoContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
    marginBottom: 2,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 18,
  },
})

SupplyPhytotherapicCard.propTypes = {
  outlier: PropTypes.string,
  unitArea: PropTypes.string.isRequired,
  supplyTypeName: PropTypes.string,
  supplies: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      unitType: PropTypes.string.isRequired,
      eiqTotal: PropTypes.number,
    })
  ),
}
