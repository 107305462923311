export default {
  TEXT_1: 'Close',
  UNAUTHORIZED: {
    TEXT_1: 'No permissions',
    TEXT_2:
      'To access this information you must request permissions from the administrator.',
  },
  NOT_AVAILABLE: {
    TEXT_1: 'Check the link',
    TEXT_2: 'The information you are trying to access is no longer available.',
  },
  ERROR_UNEXPECTED: {
    TEXT_1: 'We were unable to access the information',
    TEXT_2: 'Try again later.',
  },
  EXIST_VERIFICATION_DRAFT: {
    TEXT_1: 'Verification Activity',
    TEXT_2:
      'There is one or more verification activities in draft. A new one cannot be created until at least one is completed.',
  },
  NO_LOTS_AVAILABLE: {
    TEXT_1: 'Verification Activity',
    TEXT_2:
      'There are currently no fields available to generate a new verification.',
  },
}
