export default {
  FIELD_1: {
    LABEL: 'E-Mail',
    PLACEHOLDER: 'Enter your email',
  },
  FIELD_2: {
    LABEL: 'First name',
    PLACEHOLDER: 'Enter your first name',
  },
  FIELD_3: {
    LABEL: 'Last name',
    PLACEHOLDER: 'Enter your last name',
  },
  FIELD_4: {
    LABEL: 'Mobile phone',
    PLACEHOLDER: 'Enter your mobile phone',
  },
  FIELD_5: {
    LABEL: 'PIN Code',
    PLACEHOLDER: 'Enter your PIN',
    HELPTEXT: 'Leave it blank to keep your existing code',
    TITLE: 'Security PIN Code',
    SUBTITLE:
      'It will be used to verify your identity in the activities you validate.',
  },
  TEXT_1: 'Update',
  TEXT_2: 'Profile saved successfully',
}
