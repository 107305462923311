export default {
  TEXT_1: 'Detalle Realización',
  TEXT_2: 'ha',
  TEXT_3: 'EIQ',
  TEXT_4: 'Validado',
  TEXT_5: 'Validar',
  TEXT_6: 'Lotes',
  TEXT_7: 'EIQ realizado',
  TEXT_8: 'ha',
}
