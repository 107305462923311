import { capitalize } from 'lodash'
import { enumUnitMeasureSystem } from '@common/utils'

export interface UnitTypesSupplies {
  _id: string
  createdAt: string
  equivalence: any[]
  key: string
  name: SupplyName
  typeMeasure: string
  unitMeasureSystem: enumUnitMeasureSystem
  updatedAt: string
}

export interface SupplyName {
  en: string
  es: string
  pt: string
}

const validUnits = ['lt', 'kg', 'lb', 'fl-oz']

interface MapperUnitTypesSuppliesReturns {
  id: number
  label: string
  key: string
  disabled: boolean
}

export const mapperUnitTypesSupplies = (
  unitTypesSupplies: UnitTypesSupplies[],
  unitMeasureSystem: enumUnitMeasureSystem,
  lang: string
): MapperUnitTypesSuppliesReturns[] => {
  return unitTypesSupplies
    .filter(
      (unitTypesSupply) =>
        unitTypesSupply.unitMeasureSystem === unitMeasureSystem &&
        validUnits.includes(unitTypesSupply.key)
    )
    .map((unitTypesSupply, index) => ({
      id: index,
      key: unitTypesSupply.key,
      label: `${capitalize(unitTypesSupply.key)} ${capitalize(
        unitTypesSupply.name[lang as keyof SupplyName]
      )}`,
      disabled: false,
    }))
}
