import FARM_LIST from '../../components/FarmList/translate/en'
import NO_LOTS_AVAILABLE from '../../components/NoLotsAvailable/translate/en'
import EMPTY_STATE from '../../components/EmptyState/translate/en'
import EMPTY_ITEM_FARM from '../../components/EmptyItemFarm/translate/en'

export default {
  COMPONENTS: {
    FARM_LIST,
    NO_LOTS_AVAILABLE,
    EMPTY_STATE,
    EMPTY_ITEM_FARM,
  },
}
