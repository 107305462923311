export default {
  ADD_DOCUMENTATION: 'Agregar documentación',
  FIELD_NAME: {
    LABEL: 'Nombre',
    PLACEHOLDER: 'Nombre',
    VALIDATE: 'Campo requerido',
  },
  FIELD_CONCEPT: {
    LABEL: 'Concepto',
    PLACEHOLDER: 'Concepto',
    VALIDATE: 'Campo requerido',
  },
  FIELD_DATE: {
    LABEL: 'Fecha de realización',
    PLACEHOLDER: 'Fecha de realización',
  },
  SELECT_DOCUMENT: 'SELECCIONAR DOCUMENTO',
  CANCEL: 'CANCELAR',
  ADD: 'AGREGAR',
}
