const COLLABORATOR_TYPES = {
  UCROPIT_ADVISER: {
    key: 'ucropit_adviser',
    name: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_13,
  },
  CROP_RESPONSIBLE: {
    key: 'crop_responsible',
    name: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_12,
  },
  CROP_MARKETER: {
    key: 'crop_marketer',
    name: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_14,
  },
  VERIFIER_RESPONSIBLE: {
    key: 'verifier_responsible',
    name: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_34,
  },
  DELEGATE: {
    key: 'delegate',
    name: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_18,
  },
  UNLINK: {
    key: 'unlink',
    name: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_19,
  },
}

export { COLLABORATOR_TYPES }
