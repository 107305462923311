import { ORDER_MENU_ENUM } from '@modules/common/utils'

/**
 * OrderMenuOption type definition
 * @typedef {Object} OrderMenuOption
 * @property {string} title
 * @property {ORDER_MENU_ENUM} key
 * @property {boolean} activeOffline
 */

/**
 * @param {any} t
 *
 * @return {Array.<OrderMenuOption>}
 */
export const getOrderMenuOptions = (t) => [
  {
    title: t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_2,
    key: ORDER_MENU_ENUM.LAST_UPLOADED,
    activeOffline: false,
  },
  {
    title: t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_3,
    key: ORDER_MENU_ENUM.LARGER_SURFFACE,
    activeOffline: false,
  },
  {
    title: t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_4,
    key: ORDER_MENU_ENUM.PENDING_ACTIVITIES,
    activeOffline: false,
  },
  {
    title: t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_5,
    key: ORDER_MENU_ENUM.ENABLED_OFFLINE,
    activeOffline: true,
  },
  {
    title: t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_6,
    key: ORDER_MENU_ENUM.A_Z,
    activeOffline: false,
  },
]

export const orderMenuOptions = [
  {
    title: (t) => t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_2,
    key: ORDER_MENU_ENUM.LAST_UPLOADED,
    activeOffline: false,
  },
  {
    title: (t) => t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_3,
    key: ORDER_MENU_ENUM.LARGER_SURFFACE,
    activeOffline: false,
  },
  {
    title: (t) => t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_5,
    key: ORDER_MENU_ENUM.ENABLED_OFFLINE,
    activeOffline: true,
  },
  {
    title: (t) => t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_6,
    key: ORDER_MENU_ENUM.A_Z,
    activeOffline: false,
  },
]

/**
 *
 * @param {Array<OrderMenuOption>} orderMenuOptions
 * @param {ORDER_MENU_ENUM} key
 *
 * @return
 */
export const findOrderMenuOptionByKey = (orderMenuOptions, key) => {
  return orderMenuOptions.find((element) => element.key === key)
}
