import React from 'react'
import { StyleSheet, View, Text, Image } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { onPressedColor, blackHighEmphasis } from '@styles/palette'
import { formatSurface } from '@modules/common/utils'

export const FieldItem = ({ onPressFieldDetails, field }) => {
  const getFieldLocation = ({ thirdGeometryLevel, secondGeometryLevel }) => {
    if (thirdGeometryLevel && secondGeometryLevel) {
      return `${thirdGeometryLevel}, ${secondGeometryLevel}`
    }

    if (thirdGeometryLevel) {
      return `${thirdGeometryLevel}`
    }

    if (secondGeometryLevel) {
      return `${secondGeometryLevel}`
    }

    return ''
  }

  return (
    <TouchableRipple
      onPress={() => onPressFieldDetails(field)}
      rippleColor={onPressedColor}
      underlayColor={onPressedColor}
    >
      <View style={styles.fieldContainer}>
        <Image
          source={{
            uri: field.image,
          }}
          style={styles.fieldImage}
        />

        <View style={styles.fieldRightSide}>
          <View style={styles.fieldTexts}>
            <Text style={styles.fieldName}>{field.name}</Text>

            <Text style={styles.fieldSurface}>
              {formatSurface(field.surface, field.areaUnit)}
            </Text>

            {Boolean(field.thirdGeometryLevel || field.secondGeometryLevel) && (
              <Text style={styles.fieldLocation}>
                {getFieldLocation(field)}
              </Text>
            )}
          </View>

          <View style={styles.iconContainer}>
            <CommonIcon
              name='CHEVRON-RIGHT'
              size={25}
              color={blackHighEmphasis}
            />
          </View>
        </View>
      </View>
    </TouchableRipple>
  )
}

const styles = StyleSheet.create({
  fieldContainer: {
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldImage: {
    height: 60,
    width: 100,
    marginRight: 12.5,
  },
  fieldRightSide: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 7.5,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .12)',
  },
  fieldTexts: {
    flex: 1,
  },
  fieldName: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 1,
  },
  fieldSurface: {
    fontSize: 14,
    color: '#333333',
    marginBottom: 1,
  },
  fieldLocation: {
    fontSize: 14,
    color: '#333333',
  },
  iconContainer: {
    marginRight: 10,
  },
})

FieldItem.propTypes = {
  onPressFieldDetails: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
}
