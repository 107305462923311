import HEADER from '../../components/Header/translate/en'
import SUB_HEADER from '../../components/SubHeader/translate/en'
import FORM from '../../components/Form/translate/en'
import SIGN_IN_BUTTON from '../../components/SignInButton/translate/en'
import WIZZARD_INTRO from '../../components/WizardIntro/translate/en'

export default {
  COMPONENTS: {
    HEADER,
    SUB_HEADER,
    FORM,
    SIGN_IN_BUTTON,
    WIZZARD_INTRO,
  },
}
