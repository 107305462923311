import React, { useContext } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'

import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import InputText from '@components/inputs/InputText'
import InputDate from '@components/inputs/InputDate'
import { truncateSurface } from '@common/utils/truncateSurface'
import { SIZES } from '@common/components/icons/interfaces'
import { primary500 } from '@styles/palette'
import { useFloodForm } from '../../../../hooks'
import { LanguageContext } from '@contextState/language'

export const FloodForm = ({
  formik,
  readOnly = false,
  crop: { areaUnit },
  onPressLotsInput,
}: any) => {
  const { setFieldValue, values, setFieldTouched, touched, errors } = formik
  const { t } = useContext(LanguageContext)
  const {
    paddyWaterCultivations,
    paddyWaterPreseasons,
    unitTimes,
    setDefaultValues,
    unitTimeRef,
  } = useFloodForm(formik)
  return (
    <View>
      <InputText
        id='timeFloodUnderCrop'
        containerStyle={styles.containerInput}
        onChangeText={(value: string) => {
          if (!Number.isInteger(Number(value))) {
            return
          }
          setFieldValue('timeFloodUnderCrop', value)
        }}
        value={values.timeFloodUnderCrop}
        label={t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.TIME_FLOOD_UNDER_CROP')}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.FLOOD_FORM.TIME_FLOOD_UNDER_CROP'
        )}
        keyboardType='numeric'
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        onBlur={() => setFieldTouched('timeFloodUnderCrop')}
        touched={touched.timeFloodUnderCrop}
        error={errors.timeFloodUnderCrop}
      />
      <InputSelectDefault
        id='unitTime'
        containerStyle={styles.containerUnitTime}
        onChangeText={(value) => {
          setFieldValue('unitTime', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('unitTime'))}
        value={values.unitTime}
        label={t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.UNIT_TIME')}
        placeholder={t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.UNIT_TIME')}
        options={unitTimes}
        touched={touched.unitTime}
        error={errors?.unitTime?.value}
        disabled={Boolean(readOnly)}
        selectRef={unitTimeRef}
      />

      <TouchableOpacity onPress={setDefaultValues}>
        <Text style={styles.textDefaultValue}>
          {t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.DEFAULT_VALUES')}
        </Text>
      </TouchableOpacity>

      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={SIZES.MEDIUM}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_2.LABEL')}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_2.PLACEHOLDER'
        )}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}
        editable={false}
        disabled
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <InputDate
        id='dateAchievement'
        containerStyle={styles.containerInput}
        onChangeText={(value: any) => {
          setFieldValue('dateAchievement', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('dateAchievement'))}
        value={values.dateAchievement}
        placeholder={t('COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_4')}
        label={t('COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_4')}
        disablePast={false}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        touched={touched.dateAchievement}
        error={errors.dateAchievement?.value}
      />

      <Text style={styles.textWaterManagement}>
        {t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.WATER_MANAGEMENT')}
      </Text>

      <InputSelectDefault
        id='paddyWaterPreseason'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('paddyWaterPreseason', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('paddyWaterPreseason'))}
        value={values.paddyWaterPreseason}
        label={t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.BEFORE_CROP')}
        placeholder={t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.BEFORE_CROP')}
        options={paddyWaterPreseasons}
        touched={touched.paddyWaterPreseason}
        error={errors?.paddyWaterPreseason?.value}
        disabled={Boolean(readOnly)}
      />

      <InputSelectDefault
        id='paddyWaterCultivation'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('paddyWaterCultivation', value)
        }}
        onBlur={() =>
          setTimeout(() => setFieldTouched('paddyWaterCultivation'))
        }
        value={values.paddyWaterCultivation}
        label={t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.DURING_CROP')}
        placeholder={t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.DURING_CROP')}
        options={paddyWaterCultivations}
        touched={touched.paddyWaterCultivation}
        error={errors?.paddyWaterCultivation?.value}
        disabled={Boolean(readOnly)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
  textDefaultValue: {
    color: primary500,
    fontWeight: '600',
    marginBottom: 20,
  },
  containerUnitTime: {
    marginBottom: 8,
  },
  textWaterManagement: {
    marginTop: 5,
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '500',
  },
})
