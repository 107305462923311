import GENERAL_INFO_CARD from '../../components/GeneralInfoCard/translate/es'
import ACTIVITY_CARD from '../../components/ActivityCard/translate/es'
import YIELD_CARD from '../../components/YieldCard/translate/es'
import STORAGE_CARD from '../../components/StorageCard/translate/es'
import OBSERVATION_CARD from '../../components/ObservationCard/translate/es'
import EVIDENCE_CARD from '../../components/EvidenceCard/translate/es'
import COLLABORATOR_CARD from '../../components/CollaboratorCard/translate/es'
import SUPPLY_SEED_CARD from '../../components/SupplySeedCard/translate/es'
import SUPPLY_FERTILIZER_CARD from '../../components/SupplyFertilizerCard/translate/es'
import SUPPLY_PHYTOTHERAPIC_CARD from '../../components/SupplyPhytotherapicCard/translate/es'
import SUPPLY from '../../components/Supply/translate/es'
import VALIDATE_BUTTON from '../../components/ValidateButton/translate/es'
import MODIFY_BUTTON from '../../components/ModifyButton/translate/es'

export default {
  NAVIGATION: {
    TITLE: 'Resumen',
  },
  COMPONENTS: {
    GENERAL_INFO_CARD,
    ACTIVITY_CARD,
    YIELD_CARD,
    STORAGE_CARD,
    OBSERVATION_CARD,
    EVIDENCE_CARD,
    COLLABORATOR_CARD,
    SUPPLY_SEED_CARD,
    SUPPLY_FERTILIZER_CARD,
    SUPPLY_PHYTOTHERAPIC_CARD,
    SUPPLY,
    VALIDATE_BUTTON,
    MODIFY_BUTTON,
  },
  IRRIGATION: {
    SOURCE_OF_WATER: 'Fuente de agua',
    PUMPING_DEPTH: 'Profundidad de bombeo',
    WATER_CONSUMPTION: 'Consumo de agua',
    HORIZONTAL_IRRIGATION_DISTANCE: 'Distancia horizontal de riego',
    SOURCE_OF_ENERGY: 'Fuente de energía',
  },
}
