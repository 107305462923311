export default {
  REQUIRED: 'El campo es requerido',
  INVALID_FORMAT: 'El formato del campo es incorrecto',
  MIN_QUANTITY: 'Seleccione al menos %{quantity}',
  MAX_QUANTITY: 'Selección máxima de %{quantity}',
  IS_NUMBER: 'El campo debe ser númerico',
  IS_POSITIVE: 'El campo debe ser mayor que 0',
  MIN_CHARACTERS: 'Ingrese al menos %{quantity} caracteres',
  MAX_CHARACTERS: 'Ingrese máximo %{quantity} caracteres',
  LEN_CHARACTERS: 'Debe tener exactamente %{quantity} caracteres',
  MUST_MATCH: 'Los emails deben coincidir',
  EMAIL_EXIST: 'Este email existe',
  THIS_VALUE_IS:
    'No es posible agregar este CUIT a este tipo de colaborador, por favor ingresa uno nuevo.',
  NUMBER_INVALID: 'El número ingresado no es válido.',
  INVALID_CHARACTERS: 'Caracteres inválidos ingresados',
  NOT_SPECIAL_CHARACTERS: 'El tax ID ingresado tiene carácteres inválidos',
  LESS_THAN: 'El campo debe ser menor que',
}
