import { View, Text, SafeAreaView, StyleSheet } from 'react-native'
import React from 'react'
import { useFarmsMain } from '@modules/farms/hooks'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { WebViewRender } from '@modules/webView/components'

const FarmsMap = () => {
  const {
    handleWebViewNavigationStateChange,
    handleIFrameMessages,
    urlWebView,
  } = useFarmsMain()
  return (
    <SafeAreaView style={styles.container}>
      {!urlWebView ? (
        <LoaderCenterContainer />
      ) : (
        <WebViewRender
          url={urlWebView}
          onNavigationStateChange={handleWebViewNavigationStateChange}
          onMessage={handleIFrameMessages}
        />
      )}
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default FarmsMap
