export default {
  DELETE_DRAFT: 'Delete draft',
  CROP_STORY: 'Crop story',
  COLLABORATORS: 'Collaborators',
  LICENSES: 'Ecosystems',
  INTEGRATIONS_WITH_THIRD_PARTIES: 'Integrations with third parties',
  ENABLE_OFFLINE: 'Enable offline',
  DISABLE_OFFLINE: 'Disable offline',
  FARM_AND_FIELD_MANAGEMENT: 'Farm and field management',
  DELETE_CROP: 'Delete crop',
}
