import { keysSupplies } from '@constants'

export const lastSeeds = (supplies) =>
  supplies
    .filter((supply) =>
      supply.typeId.tags.find((tag) => tag === keysSupplies.seeds)
    )
    .pop()

export const lastFertilizer = (supplies) =>
  supplies
    .filter((supply) =>
      supply.typeId.tags.find((tag) => tag === keysSupplies.fertilizer)
    )
    .pop()

export const lastPhytotherapics = (supplies) =>
  supplies
    .filter((supply) =>
      supply.typeId.tags.find((tag) => tag === keysSupplies.phytotherapics)
    )
    .pop()
