import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { Card } from '@modules/common/components'
import { carbon400, blackHighEmphasis } from '@styles/palette'

export const GeneralInfoCard = ({ cropName, companyName }) => {
  const { t } = useContext(LanguageContext)

  return (
    <Card>
      <View style={styles.infoContainer}>
        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.GENERAL_INFO_CARD.CROP_NAME_TITLE'
          )}
        </Text>

        <Text style={styles.textBold}>{cropName}</Text>

        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.GENERAL_INFO_CARD.COMPANY_NAME_TITLE'
          )}
        </Text>

        <Text style={styles.textBold}>{companyName}</Text>
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  infoContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
    marginBottom: 2,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 18,
  },
})

GeneralInfoCard.propTypes = {
  cropName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
}
