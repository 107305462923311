const activitiesStatus = {
  PENDING: {
    key: 'PENDING',
  },
  TO_MAKE: {
    key: 'TO_MAKE',
  },
  DONE: {
    key: 'DONE',
  },
  FINISHED: {
    key: 'FINISHED',
  },
}

export default activitiesStatus
