import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { activitiesActions } from '@store/actions/activities'
import { LanguageContext } from '@contextState/language'
import activityTypesConstants from '@constants/activityTypes'
import { ABOUT_ACTIVITIES } from '@utils/constants'
import ButtonDefault from '@common/components/buttons/ButtonDefault'

import { ListFilter } from './components'
import { gray50 } from '@common/styles/palette'
import { ACTIVITY_CONDITIONS } from '@common/utils'
import { mostUsedFiltersActivities } from '@services/analytics'

const Filter = ({ navigation }) => {
  const dispatch = useDispatch()

  const { t } = useContext(LanguageContext)
  const { activityTypes } = useSelector((state) => state.activityTypesReducer)

  const {
    activityStatus: activityStatusPersist,
    activityType: activityTypePersist,
    canSeeVerification,
  } = useSelector((state) => state.activities)

  const [activityStatus, setActivityStatus] = useState(activityStatusPersist)
  const [activityType, setActivityType] = useState(activityTypePersist)

  const DISABLED_ACTIVITY_TYPE =
    activityStatus.some((status) => status === ACTIVITY_CONDITIONS.DRAFT.key) &&
    !activityStatus.filter((status) => status !== ACTIVITY_CONDITIONS.DRAFT.key)
      .length

  useEffect(() => {
    if (DISABLED_ACTIVITY_TYPE) {
      setActivityType([])
    }
  }, [activityStatus])

  const setFilters = () => {
    dispatch(activitiesActions.setFilters({ activityStatus, activityType }))

    mostUsedFiltersActivities({
      activityStatus,
      activityType,
    })

    navigation.goBack()
  }

  return (
    <View style={styles.container}>
      <ScrollView style={styles.container}>
        <ListFilter
          data={ABOUT_ACTIVITIES.map((activity) => {
            return { key: activity.KEY, title: activity.TITLE(t) }
          })}
          items={activityStatus}
          setItems={setActivityStatus}
          title={t('ACTIVITIES.FILTER_ACTIVITY.ACTIVITY_STATUS')}
        />

        <ListFilter
          data={_.orderBy(
            activityTypes
              .filter((activity) =>
                !canSeeVerification
                  ? activity.tag !== activityTypesConstants.ACT_VERIFICATION.key
                  : true
              )
              .map((activity) => {
                return { key: activity.tag, title: activity.label }
              }),
            'title',
            'asc'
          )}
          items={activityType}
          setItems={setActivityType}
          title={t('ACTIVITIES.FILTER_ACTIVITY.TYPE_ACTIVITY')}
          disabledItems={DISABLED_ACTIVITY_TYPE}
        />
      </ScrollView>
      <View style={styles.contentBottom}>
        <ButtonDefault
          typeButton={
            activityStatus.length || activityType.length
              ? 'primary'
              : 'disabled'
          }
          text={t('ACTIVITIES.FILTER_ACTIVITY.APPLY')}
          onPress={setFilters}
          disabled={!(activityStatus.length || activityType.length)}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: gray50,
    flex: 1,
  },
  contentBottom: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginVertical: 32,
    marginHorizontal: 16,
  },
})

Filter.propTypes = {
  navigation: PropTypes.object.isRequired,
}

export default Filter
