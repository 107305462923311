import { useFormik } from 'formik'
import { createGenericSupplyValidation } from '../utils/createGenericSupplyValidation'
import { useContext, useEffect, useMemo, useState } from 'react'
import { LanguageContext } from '@contextState/language'
import { StackActions, useNavigation, useRoute } from '@react-navigation/native'
import useNetwork from '@utils/network'
import { mapperUnitTypesSupplies } from '../utils'
import { useAuth, useCompanyInfo } from '@common/hooks'
import { CommonContext } from '@contextState/common'
import RouterNames from '@constants/routerNames'
import { createGenericSupplyV2 } from '../services'

export interface ChemicalComposition {
  [key: string]: any
  N: {
    fountain: string
    N: string
    N_A: string
    N_N: string
    N_U: string
  }
  P: string
  K: string
  CA: string
  S: string
}

export interface CreateGenericSupplyInitialValues {
  countryId: string
  chemicalCompositions: ChemicalComposition
  unit: number
  optionsNitrogen?: string
}

const formikInitialValues = {
  countryId: '',
  chemicalCompositions: {
    N: {
      fountain: 'conventional',
      N: '',
      N_A: '',
      N_N: '',
      N_U: '',
    },
    P: '',
    K: '',
    CA: '',
    S: '',
    P2O5: '',
    K2O: '',
    CaCO3: '',
    SO4: '',
  },
  unit: 1,
  optionsNitrogen: undefined,
}

const unitDefaultKeys = ['kg', 'fl-oz']

export const useCreateGenericSupply = () => {
  const {
    config: { companySelected },
  } = useAuth()

  const { t, i18n } = useContext<any>(LanguageContext)
  const { unitTypesSupplies } = useContext<any>(CommonContext)
  const { country } = useCompanyInfo()
  const navigation = useNavigation()
  const route = useRoute()
  const { params }: any = route
  const { doRequest } = useNetwork()
  const [supplyExternal, setSupplyExternal] = useState<any>(null)

  const options = useMemo(() => {
    return mapperUnitTypesSupplies(
      unitTypesSupplies,
      country.unitMeasureSystem,
      i18n?.locale
    )
  }, [unitTypesSupplies, country.unitMeasureSystem, i18n?.locale])

  useEffect(() => {
    if (supplyExternal) {
      window.parent.postMessage(supplyExternal, '*')
    }
  }, [supplyExternal])

  useEffect(() => {
    if (options.length) {
      const defaultUnit = options.find((option) =>
        unitDefaultKeys.includes(option.key)
      )
      formik.setFieldValue(`unit`, defaultUnit?.id ?? options[0].id)
    }
  }, [options])

  const {
    country: { _id: countryId },
  } = companySelected
  const formik = useFormik<CreateGenericSupplyInitialValues>({
    initialValues: {
      ...formikInitialValues,
      countryId,
      unit: options.findIndex(({ key }) => unitDefaultKeys.includes(key)),
    },
    validationSchema: createGenericSupplyValidation(t),
    enableReinitialize: false,
    isInitialValid: false,
    onSubmit: (values) => {
      return handleSubmit(values)
    },
  })

  const getSupplyById = async (supplyId: string) => {
    return doRequest({
      method: 'get',
      url: `supplies/${supplyId}`,
      displayAlert: false,
    })
  }

  const handleSubmit = async (values: any) => {
    let nitrogenComposition: any = {
      N:
        Number(values.chemicalCompositions.N.N) > 0
          ? Number(values.chemicalCompositions.N.N)
          : undefined,
    }

    if (values.optionsNitrogen === 'composition') {
      nitrogenComposition = {
        fountain: values.chemicalCompositions.N.fountain,
        N_A: Number(values.chemicalCompositions.N.N_A),
        N_N: Number(values.chemicalCompositions.N.N_N),
        N_U: Number(values.chemicalCompositions.N.N_U),
      }
    }

    const data = {
      unit:
        options[values.unit].key ??
        unitDefaultKeys[values.unit] ??
        unitDefaultKeys[0],
      countryId: values.countryId,
      chemicalCompositions: {
        N: nitrogenComposition,
        P: values.optionsPhosphorus && {
          [values.optionsPhosphorus]: Number(
            values.chemicalCompositions[values.optionsPhosphorus]
          ),
        },
        K: values.optionsPotassium && {
          [values.optionsPotassium]: Number(
            values.chemicalCompositions[values.optionsPotassium]
          ),
        },
        CA: values.optionsCalcium && {
          [values.optionsCalcium]: Number(
            values.chemicalCompositions[values.optionsCalcium]
          ),
        },
        S: values.optionsSulfur && {
          [values.optionsSulfur]: Number(
            values.chemicalCompositions[values.optionsSulfur]
          ),
        },
      },
    }

    const response: any = await createGenericSupplyV2(data)

    const supply = await getSupplyById(response?.data?._id)

    if ((params as { isExternal: boolean }).isExternal) {
      setSupplyExternal(supply?.data[0])
    }

    navigation.dispatch(
      StackActions.replace(RouterNames.SUPPLY_LOADER, {
        ...params,
        item: {
          ...supply?.data[0],
        },
      })
    )

    return values
  }

  const validateNumber = (value: string) => {
    return (
      /^[0-9]+(\.[0-9]{0,2})?$/.test(value) ||
      /^[0-9]+\.$/.test(value) ||
      value === ''
    )
  }
  const setValueForm = (name: string, value: string) => {
    if (validatePercent(value)) {
      formik.setFieldValue(`chemicalCompositions.${name}`, value)
    }
  }

  const validatePercent = (value: string) =>
    validateNumber(value) && Number(value) <= 100

  const setValueWithEquivalence = ({
    value,
    equivalence,
    firstNameValue,
    secondValueName,
  }: {
    value: string
    equivalence: number
    firstNameValue: string
    secondValueName: string
  }) => {
    if (validateNumber(value)) {
      formik.setFieldValue(`chemicalCompositions.${secondValueName}`, value)
      formik.setFieldValue(
        `chemicalCompositions.${firstNameValue}`,
        (Number(value) * equivalence).toFixed(2)
      )
    }
  }

  return {
    formik,
    options,
    setValueWithEquivalence,
    setValueForm,
    handleSubmit,
    validatePercent,
  }
}
