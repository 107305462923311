export const agreementsAndEvidence = {
  EXPLO: [
    '0002',
    '0014',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  SEED_USE: [
    '0007',
    '0008',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  EVIDENCE_CFT: [
    '0013',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  '2BSVS_REPORT': [
    '0002',
    '0015',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  SELFDEC: [
    '0002',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  SELFDEV: [
    '0002',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  SELFDEM: [
    '0002',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  SELFDEA: [
    '0002',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  INPUTSIN: [
    '0002',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  SEED_PROD: [
    '0016',
    '0017',
    '0018',
    '0019',
    '0020',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  RESPONSIBLE_FERT: [
    '0021',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  SUSTAINABILITY: [
    '0022',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  ADD_INFO: [
    '0023',
    '0024',
    '0025',
    '0026',
    '0027',
    '0028',
    '0029',
    '0030',
    '0031',
  ],
  '2BSVS_CALCULATION': ['0002'],
  SDP_REPORTS: ['0035', '0036', '0037', '0039'],
}
