export default {
  TEXT_1: 'Não está autorizado a exportar o relatório',
  TEXT_2: 'O arquivo KMZ tem um formato invãlido para um lote',
  TEXT_3: 'Arquivos de evidências tem extensões não permitidas',
  TEXT_4: 'Serviço de terceiro já está integrado na empresa',
  TEXT_5: 'A companhia já possui um serviço de integração',
  TEXT_6: 'A companhia não se encontra integrada',
  TEXT_7: 'O lote já foi exportado',
  TEXT_8: 'Erro ao tentar exportar os dados',
  TEXT_9: 'As credenciais são inválidas',
  TEXT_10: 'O arquivo KMZ tem um formato invãlido para um lote.',
  TEXT_11: 'Tem lotes com o mesmo nome, deve modificá-los para continuar',
  TEXT_12: 'Alguns campos obrigatórios estão vazios',
  TEXT_13: 'Alguma data do cultivo é inválida',
  TEXT_14: 'O conjunto de arquivos enviado é inválido',
  TEXT_15: 'Lotes não disponíveis',
  TEXT_16: 'O conjunto de lotes enviados são inválido',
  TEXT_17: 'Os dados enviados são inválidos',
  TEXT_18: 'No puede desvincular colaborador',
}
