import { EAgreementTypes, ESubtypeAgreements } from '@common/enum'

export const parseDataToValidateIsDirectAchievement = (values, userId, tag) => {
  const dataToSend = {
    draftId: values.draftId,
    cropId: values?.cropId ?? values.crop,
    name: values.name,
    type: values.type,
    status: values.status,
    lots: values.lots?.value?.map((element) => element._id) ?? values.lots,
    lotsWithSurface:
      values.lots?.value?.map((element) => ({
        lot: element._id,
        surfacePlanned: element.surface,
        tag: element.tag,
      })) ?? values.lotsWithSurface,
    surface: Number(values.surface),
    dateHarvest: values.dateHarvest?.value ?? values.dateHarvest,
    dateObservation: values.dateObservation?.value ?? values.dateObservation,
    dateEstimatedHarvest:
      values.dateEstimatedHarvest?.value ?? values.dateEstimatedHarvest,
    unitType: values.unitType?.value ?? values.unitType,
    typeAgreement: values.typeAgreement?.value ?? values.typeAgreement,
    verificationType: values.verificationType?.value ?? values.verificationType,
    pay: values.pay,
    volume: values?.volume,
    unitVolume: values?.unitVolume,
    observation: values.observation,
    observations: values.observations,
    storages: values.storages,
    evidences: values.evidences.map((element) => ({
      ...element,
      user: element.user?._id,
    })),
    signers: values.signers.map((element) => {
      if (element.userId !== userId) {
        return {
          ...element,
          signed: false,
          dateToSign: new Date(),
        }
      }
      return element
    }),
    verificationFile: values.verificationFile
      ? {
          name: values.verificationFile.originalName,
          uploadedBy: values.verificationFile.uploadedBy._id,
          uploadedAt: values.verificationFile.uploadedAt,
        }
      : undefined,
    isDirectAchievement: true,
    tag,
    farms: values.farms,
    seedDryContent: values?.seedDryContent
      ? Number(values?.seedDryContent)
      : undefined,
    dataForCFT: values?.dataForCFT,
    establishmentFormId: values?.establishmentFormId,
    emissionCO2: values?.emissionCO2,
    summaryEmissionCO2: values?.summaryEmissionCO2,
    weatherTypeId: values.weatherType?.value || undefined,
    temperature: values?.temperature ? Number(values.temperature) : undefined,
    soilTypeId: values?.soilType?.value || undefined,
    landTypeId: values?.landType?.value || undefined,
    humidityTypeId: values?.humidityType?.value || undefined,
    formResponse: values.formResponse,
  }
  if (
    (!values.evidences.length &&
      (values?.typeAgreement?.key === EAgreementTypes['2BSVS_CALCULATION'] ||
        values?.typeAgreement?.key === EAgreementTypes.EVIDENCE_CFT)) ||
    values?.dataForCFT
  ) {
    dataToSend.noFiles = true
  }

  return dataToSend
}
