import React, { useEffect, useRef } from 'react'
import { View } from 'react-native'
import InputText from '@components/inputs/InputText'

export const TaxIdForm = ({ formik, t, testID = 'tax_id' }) => {
  const ref = useRef(null)
  const { values, touched, errors, setFieldValue, setFieldTouched } = formik

  useEffect(() => {
    ref?.current?.focus()
  }, [])

  return (
    <View>
      <InputText
        inputRef={ref}
        id='taxid'
        value={values.taxid}
        label={t('VIEWS.VALIDATE_TAXID.COMPONENTS.TAXID_FORM.TAXID')}
        placeholder={t('VIEWS.VALIDATE_TAXID.COMPONENTS.TAXID_FORM.TAXID')}
        touched={touched.taxid}
        error={errors.taxid}
        onChangeText={(value) => {
          setFieldValue('taxid', value)
        }}
        testID={testID}
        onBlur={() => {
          setFieldTouched('taxid')
        }}
        autofocus
      />
    </View>
  )
}
