export const getFilesDeleted = (draftEvidences, currentEvidences) => {
  const filesDeleted = []

  draftEvidences.forEach((draftEvidence) => {
    const draftEvidenceName = draftEvidence.fileNamePath || draftEvidence.name
    const fileExist = currentEvidences.find((evidence) => {
      const evidenceName = evidence.fileNamePath || evidence.name
      return draftEvidenceName === evidenceName
    })

    if (!fileExist) {
      filesDeleted.push(draftEvidenceName)
    }
  })

  return filesDeleted
}
