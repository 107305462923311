import React from 'react'
import { View, StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'

const CardDefault = ({ children, extraStyle = {} }) => {
  return <View style={[styles.card, extraStyle]}>{children}</View>
}
const styles = StyleSheet.create({
  card: {
    borderRadius: 6,
    backgroundColor: '#fff',
    marginVertical: 10,
    marginHorizontal: 1,
    elevation: 3,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0.5 * 3 },
    shadowOpacity: 0.3,
    shadowRadius: 0.8 * 3,
  },
})

CardDefault.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  extraStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
}

export default CardDefault
