import { View, Image, StyleSheet, Text } from 'react-native'
import React, { useContext } from 'react'

import RouterNames from '@constants/routerNames'
import { cropsEmpty } from '@constants/images'
import { LanguageContext } from '@contextState/language'

import ButtonDefault from '@common/components/buttons/ButtonDefault'

const Home = ({ navigation }) => {
  const { t } = useContext(LanguageContext)
  const goCompanyAdd = () => {
    navigation.navigate(RouterNames.COMPANY_ADD, {
      isAdviser: false,
    })
  }

  return (
    <View>
      <View>
        <Image source={cropsEmpty} style={styles.emptyImage} />
      </View>
      <Text style={styles.welcome}>{t('VIEWS.HOME_SCREEN.WELCOME')}</Text>
      <View style={styles.button}>
        <ButtonDefault
          typeButton={'primary'}
          text={t('VIEWS.HOME_SCREEN.ADD_COMPANY')}
          onPress={goCompanyAdd}
          borderRadius={8}
          height={48}
          customTextStyle={styles.textButton}
        />
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  emptyImage: {
    width: 146,
    height: 200,
    marginTop: 130,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  welcome: {
    marginTop: 18,
    marginHorizontal: 26,
    fontSize: 20,
    lineHeight: 23,
    fontWeight: '500',
    textAlign: 'center',
  },
  button: {
    marginHorizontal: 24,
    marginTop: 47,
  },
  textButton: {
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: 0.75,
    lineHeight: 16,
  },
})
export default Home
