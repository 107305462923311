import COLLABORATOR_REQUEST_LIST from './collaborator-request-list'
import FORM_ADD from './form-add'
import FORM_BOARDING from './form-boarding'
import FORM_UPDATE from './form-update'

export default {
  COLLABORATOR_REQUEST_LIST,
  FORM_ADD,
  FORM_BOARDING,
  FORM_UPDATE,
}
