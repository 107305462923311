import { FC, ReactElement } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'
import QR from '@modules/traders/screens/qr'

const Stack = createStackNavigator()

export const QRStack: FC<any> = (): ReactElement | null => {
  const stacks = [
    {
      name: RouterNames.QR,
      component: QR,
      options: {
        header: (props: any) => {
          if (Platform.OS === 'web') {
            return null
          }

          return <Header {...props} title={' '} drawer />
        },
      },
    },
  ]

  return (
    <Stack.Navigator initialRouteName={RouterNames.QR}>
      {stacks.map((stack, key) => (
        <Stack.Screen {...stack} key={`QR_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
