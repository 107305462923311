import React, { useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { Card } from '@modules/common/components'
import { carbon400, carbon500, blackHighEmphasis } from '@styles/palette'

const ICON_STORAGE = 'STORAGE'

export const StorageCard = ({ storages = [] }) => {
  const { t } = useContext(LanguageContext)

  const RenderStorage = ({ tons, storageTypeName }) => {
    return (
      <View style={styles.storageContainer}>
        <CommonIcon name={ICON_STORAGE} size={45} style={styles.storageIcon} />

        <View>
          <Text style={styles.textBold}>{`${tons} tn`}</Text>

          <Text style={styles.text}>{storageTypeName}</Text>
        </View>
      </View>
    )
  }

  return (
    <Card>
      <View style={styles.infoContainer}>
        <Text style={styles.textTitle}>
          {t(
            'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.STORAGE_CARD.STORAGE_TITLE'
          )}
        </Text>

        {storages.map(({ tons, storageTypeName }, key) => (
          <RenderStorage
            key={key}
            tons={tons}
            storageTypeName={storageTypeName}
          />
        ))}
      </View>
    </Card>
  )
}

const styles = StyleSheet.create({
  infoContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  storageContainer: {
    flexDirection: 'row',
    marginTop: 24,
  },
  storageIcon: {
    marginRight: 20,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
    marginBottom: 2,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon500,
    marginBottom: 2,
  },
})

StorageCard.propTypes = {
  storages: PropTypes.arrayOf(
    PropTypes.shape({
      tons: PropTypes.number.isRequired,
      storageTypeName: PropTypes.string.isRequired,
    })
  ),
}
