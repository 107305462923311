import { useEffect, useState } from 'react'
import { getUrlDrawFields } from '@modules/webView/utils'

export const usePolygonsTrader = () => {
  const [urlWebView, setUrlWebView] = useState('')

  useEffect(() => {
    fetchWebViewUrl()
  }, [])

  const fetchWebViewUrl = async () => {
    const url = await getUrlDrawFields.getUrlTradersBulkUpload()
    setUrlWebView(url)
  }

  return {
    urlWebView,
  }
}
