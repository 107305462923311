export default {
  TEXT_1: 'Perfil',
  TEXT_2: 'Ajustes',
  TEXT_3: 'sincronización',
  TEXT_4: 'Cola de sincronización',
  TEXT_5: 'Cultivos',
  TEXT_6: 'Filtros',
  TEXT_7: 'Detalle Cultivo',
  TEXT_8: 'Detalle Sincronización',
  TEXT_9: 'Alta de Empresa',
  TEXT_10: 'Colaboradores',
  TEXT_11: 'Agregar Colaborador',
  TEXT_12: 'Sincronizar Cultivo',
  TEXT_13: 'Integración',
  TEXT_14: 'Integraciones',
  TEXT_15: 'Opciones Integración',
  TEXT_16: 'Cola de integracion',
  TEXT_17: 'Credenciales de Integracion',
  TEXT_18: 'Mi Empresa',
  TEXT_19: 'Actividades',
  TEXT_20: 'Agregar acuerdo',
  TEXT_21: 'Editar acuerdo',
  TEXT_22: 'Agregar Observación',
  TEXT_23: 'Editar Observación',
  TEXT_24: 'Agregar Cosecha',
  TEXT_25: 'Editar Cosecha',
  TEXT_26: 'Planificar Siembra',
  TEXT_27: 'Planificar Labranza',
  TEXT_28: 'Planificar Fertilización',
  TEXT_29: 'Planificar Aplicación',
  TEXT_30: 'Planificar Cosecha',
  TEXT_31: 'Actividad Siembra',
  TEXT_32: 'Actividad Labranza',
  TEXT_33: 'Actividad Fertilización',
  TEXT_34: 'Actividad Aplicación',
  TEXT_35: 'Actividad Cosecha',
  TEXT_36: 'Realizar Siembra',
  TEXT_37: 'Realizar Labranza',
  TEXT_38: 'Realizar Fertilización',
  TEXT_39: 'Realizar Aplicación',
  TEXT_40: 'Realizar Cosecha',
  TEXT_41: 'Detalle realización',
  TEXT_42: 'Confirmar Superficie Realizada',
  TEXT_43: 'Seleccionar insumos',
  TEXT_44: 'Detalle insumo',
  TEXT_45: 'Detalle de lote',
  TEXT_46: 'Agregar %{activityType}',
  TEXT_47: 'Editar %{activityType}',
  TEXT_48: 'Detalle %{activityType}',
  TEXT_49: 'Empresa Asociada al Ecosistema',
  TEXT_50: 'Campos',
  TEXT_51: 'Agregar Lotes',
  TEXT_52: 'Nuevo Campo',
  TEXT_53: 'Campo',
  TEXT_54: 'Lotes',
  TEXT_55: 'Lote',
  TEXT_56: 'Actividad Siembra',
  TEXT_57: 'Actividad Labranza',
  TEXT_58: 'Actividad Fertilización',
  TEXT_59: 'Actividad Aplicación',
  TEXT_60: 'Editar nombre',
  TEXT_61: 'Realizar %{activityType}',
  TEXT_62: 'Detalle del lote',
  TEXT_63: 'Campos y lotes del cultivo',
  TEXT_64: 'Lote',
  TEXT_65: 'Empresas asesoradas',
  TEXT_66: 'Empresas',
  COLLABORATORS: 'Colaboradores',
  MANAGE_COMPANY: 'Administrar Nueva Empresa',
  EDIT_COMPANY: 'Editar empresa',
  ADVISE_COMPANY: 'Asesorar Nueva Empresa',
  ADD_COLLABORATORS: 'Agregar Colaborador',
  SETTINGS: 'Configuración',
  ADD_PRODUCER_ADMIN: 'Agregar Productor - Administrador',
  ADD_ADVISER_RESPONSIBLE: 'Agregar Asesor - Responsable',
  DELETE_ACCOUNT: 'Eliminar cuenta',
  LANGUAGES: 'Idioma',
  LICENCES: 'Programas',
  FILTERS: 'Filtrar por:',
  SELECT_FARM: 'Seleccionar campo',
  FIELD_STORY: 'Historia del lote',
  ACTIVITY: 'Actividad',
  PLAN: 'Planificar',
}
