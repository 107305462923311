export default {
  CREATE_NEW_FERTILIZER: 'Crear nuevo fertilizante',
  GENERIC: 'Genérico',
  COMPOSITION: 'Composición',
  ENTER_THE_PERCENTAGE_OF_THE_COMPOSITION:
    'Ingresa el porcentaje de la composición y la unidad de medida que se utilizará en su aplicación.',
  THE_COMPOSITION_CANNOT_EXCEED_HUNDRED_PERCENT:
    'La composición no puede superar el 100%',
  ENTER_THE_AMOUNT_APPLIED_IN_KG_OF_FERTILIZER:
    'Ingresa la cantidad aplicada en {{kg}} de fertilizante.',
  NUMBER_OF_KG: '{{Cantidad of kg}}',
  N_NITROGEN_PERCENT: 'N Nitrógeno %',
  P_PHOSPHORUS_PERCENT: 'P Fósforo %',
  K_POTASSIUM_PERCENT: 'K Potasio %',
  CA_CALCIUM_PERCENT: 'Ca Calcio %',
  S_SULFUR_PERCENT: 'S Sulfuro %',
  UNIT_OF_MEASURE: 'Unidad de medida',
  ADD_NEW_GENERIC_FERTILIZER: 'Agrega tus fertilizantes genéricos',
  ADD_NEW_GENERIC_FERTILIZER_EASILY:
    'Agregalos fácilmente al listado desde el botón',
  PHOSPHORUS: 'Fósforo',
  P2O5: 'P₂O₅',
  NITROGEN: 'Nitrógeno',
  BY_COMPOSITION: 'Por composición',
  FOUNTAIN: 'Fuente',
  CONVENTIONAL: 'Convencional',
  GREEN: 'Ecológico',
  N_AMMONIACAL: 'N Amoniacal',
  N_NITRIC: 'N Nítrico',
  N_UREAIC: 'N Ureico',
  POTASSIUM: 'Potasio',
  K2O5: 'K₂O₅',
  CALCIUM: 'Calcio',
  CACO3: 'CaCO₃',
  SULFUR: 'Sulfuro',
  SO4: 'SO₄',
  CREATE_AND_SELECTED_FERTILIZER: 'Crear y seleccionar nuevo fertilizante',
  CHEMICAL_COMPOSITION: 'Composición química',
  ENTER_CHEMICAL_COMPOSITION:
    'Introduzca la composición química del fertilizante.',
}
