import React from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { FormikProvider } from 'formik'
import { ActivityIndicator } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { Layout } from '@modules/common/components'
import { primary500 } from '@styles/palette'
import { Form, SubmitButton } from './components'

const Screen = ({
  isViewReady,
  formik,
  createCollaboratorConfig,
  countryOptions,
  userTypeOptions,
  isSubmitButtonAvailable,
  onPressSubmitButton,
  isSubmitButtonLoading,
}) => {
  if (!isViewReady) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator animating size='large' color={primary500} />
      </View>
    )
  }

  return (
    <Layout>
      <ScrollView contentContainerStyle={styles.container}>
        <FormikProvider value={formik}>
          <Form
            formik={formik}
            createCollaboratorConfig={createCollaboratorConfig}
            countryOptions={countryOptions}
            userTypeOptions={userTypeOptions}
          />
        </FormikProvider>
      </ScrollView>

      <SubmitButton
        isSubmitButtonAvailable={isSubmitButtonAvailable}
        onPressSubmitButton={onPressSubmitButton}
        isSubmitButtonLoading={isSubmitButtonLoading}
      />
    </Layout>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  container: {
    flex: 1,
    backgroundColor: '#F2F2F2',
    paddingHorizontal: 16,
  },
})

Screen.propTypes = {
  isViewReady: PropTypes.bool.isRequired,
  formik: PropTypes.object,
  createCollaboratorConfig: PropTypes.object.isRequired,
  countryOptions: PropTypes.array.isRequired,
  userTypeOptions: PropTypes.array.isRequired,
  isSubmitButtonAvailable: PropTypes.bool.isRequired,
  isSubmitButtonLoading: PropTypes.bool.isRequired,
  onPressSubmitButton: PropTypes.func.isRequired,
}

export default Screen
