export default {
  TEXT_1: 'Cultivos',
  TEXT_2: 'Editar perfil',
  TEXT_3: 'Empresa',
  TEXT_4: 'Integrações',
  TEXT_5: 'Licenças',
  TEXT_6: 'Finalizar a sessão',
  TEXT_7: 'Versão',
  TEXT_8: 'Registro de empresa',
  TEXT_9: 'Data',
  TEXT_10: 'Atividades pendentes',
  TEXT_11: 'Listado',
  TEXT_12: 'Dashboard',
  TEXT_13: 'Ver detalle de cultivo',
  TEXT_14: 'Deletar cultivo?',
  TEXT_15: 'Colaboradores',
  TEXT_16: 'História do cultivo',
  TEXT_17: 'Está a ponto de deletar a cultura ¿Tem certeza?',
  TEXT_18: 'Deletar',
  TEXT_19: 'Não deletar',
  TEXT_20: 'Sem cultura carregada',
  TEXT_21: 'Pendente de autorização',
  TEXT_22: 'Tem autorização pendente para validação dentro da empresa',
  TEXT_23: 'Deletar rascunho',
  TEXT_24: 'Colaboradores',
  TEXT_25: 'Itegração com terceiros',
  TEXT_26: 'Ativar offline',
  TEXT_27: 'Desativar offline',
  TEXT_28: 'Deletar cultivo',
  TEXT_29: 'Asociar empresa',
  TEXT_30: 'Atividades pendentes',
  TEXT_31: 'Colaborar',
  TEXT_32:
    'A sincronização offline de recorte está ativada para %{country}.\nPara trabalhar offline com esta cultura, desative o modo offline nas demais.',
  TEXT_33:
    'Crie sua primeira cultura carregando lotes e comece a documentar suas atividades. ',
  TEXT_34: 'ACEITAR',
  TEXT_35: 'Último carregado',
  TEXT_36: 'Superfície maior',
  TEXT_37: 'Atividades pendentes',
  TEXT_38: 'Ativado offline',
  TEXT_39: 'A-Z',
  TEXT_40: 'Ordenados por',
  TEXT_41:
    'Lembre-se de adicionar o lote ao campo para que ele possa ser atribuído a um novo cultivo.',
  TEXT_42:
    ' Crie o seu primer cultivo e comece a registrar as suas atividades.',
}
