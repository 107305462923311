import { getUnitAreaByUnitMeasureSystem } from '@modules/common/utils'
import { useAuth } from './useAuth'

export const useCompanyInfo = () => {
  const { config, companyAllow } = useAuth()

  const companyId = config.companySelected?._id
  const _id = config.companySelected?._id
  const identifier = config.companySelected?.identifier
  const companyName = config.companySelected?.name
  const country = config.companySelected?.country
  const typePerson = config.companySelected?.typePerson
  const address = config.companySelected?.address
  const addressFloor = config.companySelected?.addressFloor
  const collaborators = config.companySelected?.collaborators
  const unitMeasureSystem = config.companySelected?.unitMeasureSystem
  const unitArea = getUnitAreaByUnitMeasureSystem(unitMeasureSystem)

  return {
    companyId,
    _id,
    identifier,
    companyName,
    companyAllow,
    country,
    typePerson,
    address,
    addressFloor,
    collaborators,
    unitMeasureSystem,
    unitArea,
  }
}
