export default {
  ADD_DOCUMENTATION: 'Adicionar documentação',
  FIELD_NAME: {
    LABEL: 'Nome',
    PLACEHOLDER: 'Nome',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_CONCEPT: {
    LABEL: 'Conceito',
    PLACEHOLDER: 'Conceito',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_DATE: {
    LABEL: 'Data de realização',
    PLACEHOLDER: 'Data de realização',
  },
  SELECT_DOCUMENT: 'SELECIONAR DOCUMENTO',
  CANCEL: 'CANCELAR',
  ADD: 'AGREGAR',
}
