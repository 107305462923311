import CUSTOM_DRAWER from './custom-drawer'
import INDEX_PRINCIPAL from './index-principal'
import STACK_NAVIGATOR from './stack-navigator'
import TAB_NAVIGATOR from './tab-navigator'
import CUSTOM_TAB_BAR from './custom-tab-bar'

export default {
  CUSTOM_DRAWER,
  INDEX_PRINCIPAL,
  STACK_NAVIGATOR,
  TAB_NAVIGATOR,
  CUSTOM_TAB_BAR,
}
