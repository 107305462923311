export default {
  TEXT_1: 'O CNPJ já existe na plataforma, por favor insire outro',
  TEXT_2: 'Campo obrigatório',
  TEXT_3: 'Insire o número',
  TEXT_4: 'CNPJ Invalido',
  TEXT_5: 'A CNPJ inválido',
  FIELD_1: {
    LABEL: 'CUIT',
    HELPTEXT: 'Da empresa física ou jurídica a cargo da produção.',
  },
  FIELD_2: {
    LABEL: 'CNPJ',
    HELPTEXT: 'Da empresa física ou jurídica a cargo da produção.',
  },
  TEXT_6: 'Adicionar documentação',
  FIELD_3: {
    LABEL: 'Nome',
    PLACEHOLDER: 'Nome',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_4: {
    LABEL: 'Conceito',
    PLACEHOLDER: 'Conceito',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_5: {
    LABEL: 'Data de realização',
    PLACEHOLDER: 'Data de realização',
  },
  TEXT_7: 'selecionar documento',
  TEXT_8: 'CANCELAR',
  TEXT_9: 'AGREGAR',
  FIELD_6: {
    LABEL: 'País',
  },
  TEXT_10: 'CNPJ:',
  FIELD_7: {
    LABEL: 'Razão social',
    PLACEHOLDER: 'Razão social',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_8: {
    LABEL: 'Personalidade Jurídica',
    OPTION_1: 'Física',
    OPTION_2: 'Jurídica',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_9: {
    LABEL: 'Endereço',
    PLACEHOLDER: 'Endereço',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_10: {
    LABEL: 'Andar / Apartamento / Escritório',
    PLACEHOLDER: 'Andar / Apartamento / Escritório',
  },
  TEXT_11: 'Adicionar documentação',
  TEXT_12: 'Guardar',
}
