import React from 'react'
import { WebView } from 'react-native-webview'
import PropTypes from 'prop-types'
import { keyFilterUrl } from '../../constants'

export const WebViewRenderIntegrator = ({ url, onMessage, redirectUri }) => {
  const webViewRef = React.createRef()

  const handleOnNavigationStateChange = (navState) => {
    keyFilterUrl.INTEGRATOR

    const { url: currentUrl } = navState
    if (
      currentUrl !== '' &&
      currentUrl.includes(keyFilterUrl.INTEGRATOR) &&
      !currentUrl.includes(keyFilterUrl.JOHN_DEERE)
    ) {
      if (currentUrl !== redirectUri) {
        webViewRef.current.injectJavaScript(
          `window.location.href = '${redirectUri}';`
        )
      }
    }
  }

  const runFirst = `
        window.isNativeApp = true;
        true; // note: this is required, or you'll sometimes get silent failures
      `

  return (
    <WebView
      ref={webViewRef}
      originWhitelist={['*']}
      source={{ uri: url }}
      scrollEnabled={true}
      incognito={true}
      injectedJavaScriptBeforeContentLoaded={runFirst}
      onNavigationStateChange={handleOnNavigationStateChange}
      onMessage={onMessage}
    />
  )
}

WebViewRenderIntegrator.propTypes = {
  url: PropTypes.string.isRequired,
  onNavigationStateChange: PropTypes.func,
  onMessage: PropTypes.func,
  position: PropTypes.string,
}
