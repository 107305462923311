import FIELDS_ADD_BUTTON from '../../components/FieldsAddButton/translate/en'
import GO_BACK_BUTTON from '../../components/GoBackButton/translate/en'
import EMPTY_STATE from '../../components/EmptyState/translate/en'

export default {
  COMPONENTS: {
    FIELDS_ADD_BUTTON,
    GO_BACK_BUTTON,
    EMPTY_STATE,
  },
}
