import React, { useContext } from 'react'
import { View, StyleSheet, Text, Image } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { lotDefault } from '@constants/images'
import { CommonIcon } from '@modules/common/components'
import { blackHighEmphasis } from '@styles/palette'

export const FieldDetails = ({
  fieldNameLabel,
  surfaceLabel,
  farmNameLabel,
  locationLabel,
  cropTypeNameLabel,
  campaignLabel,
  fieldImage,
  goToEditLotName,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <View style={styles.fieldNameContainer}>
        <Text style={styles.fieldNameLabel} numberOfLines={1}>
          {fieldNameLabel}
        </Text>

        <CommonIcon
          name={'EDIT'}
          size={20}
          color={blackHighEmphasis}
          style={styles.fieldNameIcon}
          onPress={goToEditLotName}
        />
      </View>

      <View style={styles.surfaceLabelContainer}>
        <Text style={styles.surfaceLabelTitle}>
          {t(
            'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.FIELD_DETAILS.SURFACE_LABEL_TITLE'
          )}
        </Text>

        <Text style={styles.surfaceLabel}>{surfaceLabel}</Text>
      </View>

      <View style={styles.farmNameLabelContainer}>
        <Text style={styles.farmNameLabelTitle}>
          {t(
            'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.FIELD_DETAILS.FARM_NAME_LABEL_TITLE'
          )}
        </Text>

        <Text style={styles.farmNameLabel}>{farmNameLabel}</Text>
      </View>

      <View style={styles.locationLabelContainer}>
        <Text style={styles.locationLabelTitle}>
          {t(
            'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.FIELD_DETAILS.LOCATION_LABEL_TITLE'
          )}
        </Text>

        <Text style={styles.locationLabel}>{locationLabel}</Text>
      </View>

      <View style={styles.cropTypeNameLabelContainer}>
        <Text style={styles.cropTypeNameLabelTitle}>
          {t(
            'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.FIELD_DETAILS.CROP_TYPE_LABEL_TITLE'
          )}
        </Text>

        <Text style={styles.cropTypeNameLabel}>{cropTypeNameLabel}</Text>
      </View>

      <View style={styles.campaignLabelContainer}>
        <Text style={styles.campaignLabelTitle}>
          {t(
            'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.FIELD_DETAILS.CAMPAIGN_LABEL_TITLE'
          )}
        </Text>

        <Text style={styles.campaignLabel}>{campaignLabel}</Text>
      </View>

      <View style={styles.imageContainer}>
        <Text style={styles.imageTitle}>
          {t(
            'VIEWS.CROP_FARM_FIELD_DETAILS.COMPONENTS.FIELD_DETAILS.IMAGE_TITLE'
          )}
        </Text>

        <Image
          source={{ uri: fieldImage || lotDefault }}
          style={styles.image}
          resizeMode={'contain'}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
  },
  fieldNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 5,
    paddingVertical: 6,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .12)',
  },
  fieldNameLabel: {
    flex: 1,
    fontWeight: '500',
    fontSize: 24,
    color: blackHighEmphasis,
  },
  fieldNameIcon: {
    marginLeft: 10,
  },
  surfaceLabelContainer: {
    marginTop: 16,
    marginBottom: 3,
    paddingVertical: 6,
  },
  surfaceLabelTitle: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  surfaceLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  farmNameLabelContainer: {
    marginTop: 16,
    marginBottom: 3,
    paddingVertical: 6,
  },
  farmNameLabelTitle: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  farmNameLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  locationLabelContainer: {
    marginTop: 16,
    marginBottom: 3,
    paddingVertical: 6,
  },
  locationLabelTitle: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  locationLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  cropTypeNameLabelContainer: {
    marginTop: 16,
    marginBottom: 3,
    paddingVertical: 6,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .12)',
  },
  cropTypeNameLabelTitle: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  cropTypeNameLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  campaignLabelContainer: {
    marginTop: 16,
    marginBottom: 3,
    paddingVertical: 6,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .12)',
  },
  campaignLabelTitle: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  campaignLabel: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  imageContainer: {
    marginTop: 30,
    marginBottom: 5,
  },
  imageTitle: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  image: {
    flex: 1,
    height: 350,
    marginTop: 10,
    marginBottom: 20,
  },
})

FieldDetails.propTypes = {
  fieldNameLabel: PropTypes.string,
  surfaceLabel: PropTypes.string,
  farmNameLabel: PropTypes.string,
  locationLabel: PropTypes.string,
  cropTypeNameLabel: PropTypes.string,
  campaignLabel: PropTypes.string,
  fieldImage: PropTypes.string,
  goToEditLotName: PropTypes.func.isRequired,
}
