import CROP_TYPES from './crop-types'
import ACTIVITY_TYPES from './activity-types'
import LICENSE_STATES from './license-states'
import EIQ_STATUS from './eiq-status'
import USER_TYPES from './user-types'
import LICENCES from './licences'
import COMPANY_TYPES from './company-types'
import VERIFICATION_STATUS from './verification-status'
import VERIFICATION_TYPES from './verification-types'

export default {
  CROP_TYPES,
  LICENSE_STATES,
  ACTIVITY_TYPES,
  EIQ_STATUS,
  USER_TYPES,
  LICENCES,
  COMPANY_TYPES,
  VERIFICATION_STATUS,
  VERIFICATION_TYPES,
}
