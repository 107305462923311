import { View, Text, StyleSheet } from 'react-native'
import React, { useContext } from 'react'
import { FormikProvider } from 'formik'
import { LanguageContext } from '@contextState/language'
import { InputSelectDefault } from '@common/components'
import { InputSelectChemicalComposition } from '../../InputSelectChemicalComposition'
import { gray800 } from '@common/styles'
import { InputSelectNitrogen } from '../../InputNitrogen'
import { carbon800 } from '@styles/palette'

const equivalences = {
  P: 0.436,
  K: 0.83,
  CA: 0.4,
  S: 0.333,
}

interface GenericSupplyFormProps {
  formik: any
  options: any
  setValueWithEquivalence: ({
    value,
    equivalence,
    firstNameValue,
    secondValueName,
  }: {
    value: string
    equivalence: number
    firstNameValue: string
    secondValueName: string
  }) => void
  setValueForm: (name: string, value: string) => void
  validatePercent: (value: string) => boolean
}

export const GenericSupplyFormV2: React.FC<GenericSupplyFormProps> = ({
  formik,
  options,
  setValueWithEquivalence,
  setValueForm,
  validatePercent,
}) => {
  const { t } = useContext(LanguageContext)
  const { values, setFieldValue } = formik

  return (
    <View>
      <FormikProvider value={formik}>
        <InputSelectDefault
          id='unit'
          containerStyle={{ borderRadius: 4 }}
          onChangeText={(value: any) => {
            formik.setFieldValue('unit', value.id)
          }}
          onBlur={() => setTimeout(() => formik.setFieldTouched('unit'))}
          value={options[formik.values.unit]}
          label={t('VIEWS.SUPPLIES.UNIT_OF_MEASURE')}
          options={options}
          disabled={Boolean(false)}
          touched={formik.touched.unit}
          error={formik.errors.unit}
          animatedLabel={false}
          dropdownIconStyle={{ fontSize: 20, color: gray800 }}
          labelStyle={styles.selectLabel}
          buttonTextStyle={styles.selectButtonText}
          selectButtonStyle={{ height: 56 }}
        />

        <View style={styles.compositionDescriptionContainer}>
          <Text style={styles.compositionDescriptionTitle}>
            {t('VIEWS.SUPPLIES.CHEMICAL_COMPOSITION')}
          </Text>
          <Text style={styles.compositionDescriptionSubTitle}>
            {t('VIEWS.SUPPLIES.ENTER_CHEMICAL_COMPOSITION')}
          </Text>
        </View>

        <InputSelectNitrogen
          title={`N ${t('VIEWS.SUPPLIES.NITROGEN')}`}
          fieldName='optionsNitrogen'
          firstText={`% ${t('VIEWS.SUPPLIES.NITROGEN')}`}
          secondText={t('VIEWS.SUPPLIES.BY_COMPOSITION')}
          firstOption='total'
          secondOption='composition'
          optionValue={values.optionsNitrogen}
          setFieldValue={setFieldValue}
          firstTextValue={values.chemicalCompositions.N.N}
          secondTextValue={values.chemicalCompositions}
          values={values.chemicalCompositions}
          onChangeFirstTextValue={(value: string) => {
            if (validatePercent(value)) {
              setFieldValue('chemicalCompositions', {
                ...values.chemicalCompositions,
                N: {
                  ...values.chemicalCompositions.N,
                  N: value,
                },
              })
            }
          }}
          onChangeChemicalComposition={(composition: string, value: string) => {
            if (composition === 'fountain') {
              setFieldValue('chemicalCompositions', {
                ...values.chemicalCompositions,
                N: {
                  ...values.chemicalCompositions.N,
                  [composition]: value,
                },
              })
            } else if (validatePercent(value)) {
              setFieldValue('chemicalCompositions', {
                ...values.chemicalCompositions,
                N: {
                  ...values.chemicalCompositions.N,
                  [composition]: value,
                  N: (
                    Number(values?.chemicalCompositions?.N?.N_A ?? 0) +
                    Number(values?.chemicalCompositions?.N?.N_N ?? 0) +
                    Number(values?.chemicalCompositions?.N?.N_U ?? 0) +
                    Number(value ?? 0) -
                    Number(values?.chemicalCompositions?.N[composition] ?? 0)
                  ).toFixed(2),
                },
              })
            }
          }}
        />

        <InputSelectChemicalComposition
          title={`P ${t('VIEWS.SUPPLIES.PHOSPHORUS')}`}
          fieldName='optionsPhosphorus'
          firstText={`% ${t('VIEWS.SUPPLIES.PHOSPHORUS')}`}
          secondText={`% ${t('VIEWS.SUPPLIES.P2O5')}`}
          firstOption='P'
          secondOption='P2O5'
          optionValue={values.optionsPhosphorus}
          setFieldValue={setFieldValue}
          firstTextValue={values.chemicalCompositions.P}
          secondTextValue={values.chemicalCompositions.P2O5}
          onChangeFirstTextValue={(value: string) => {
            setValueForm('P', value)
            if (values.chemicalCompositions.P2O5) {
              setFieldValue('chemicalCompositions.P2O5', '')
            }
          }}
          onChangeSecondTextValue={(value: string) => {
            setValueWithEquivalence({
              value,
              equivalence: equivalences.P,
              firstNameValue: 'P',
              secondValueName: 'P2O5',
            })
          }}
        />
        <InputSelectChemicalComposition
          title={`K ${t('VIEWS.SUPPLIES.POTASSIUM')}`}
          fieldName='optionsPotassium'
          firstText={`% ${t('VIEWS.SUPPLIES.POTASSIUM')}`}
          secondText={`% ${t('VIEWS.SUPPLIES.K2O5')}`}
          firstOption='K'
          secondOption='K2O'
          optionValue={values.optionsPotassium}
          setFieldValue={setFieldValue}
          firstTextValue={values.chemicalCompositions.K}
          secondTextValue={values.chemicalCompositions.K2O}
          onChangeFirstTextValue={(value: string) => {
            setValueForm('K', value)
            if (values.chemicalCompositions.K2O) {
              setFieldValue('chemicalCompositions.K2O', '')
            }
          }}
          onChangeSecondTextValue={(value: string) => {
            setValueWithEquivalence({
              value,
              equivalence: equivalences.K,
              firstNameValue: 'K',
              secondValueName: 'K2O',
            })
          }}
        />

        <InputSelectChemicalComposition
          title={`Ca ${t('VIEWS.SUPPLIES.CALCIUM')}`}
          fieldName='optionsCalcium'
          firstText={`% ${t('VIEWS.SUPPLIES.CALCIUM')}`}
          secondText={`% ${t('VIEWS.SUPPLIES.CACO3')}`}
          firstOption='CA'
          secondOption='CaCO3'
          optionValue={values.optionsCalcium}
          setFieldValue={setFieldValue}
          firstTextValue={values.chemicalCompositions.CA}
          secondTextValue={values.chemicalCompositions.CaCO3}
          onChangeFirstTextValue={(value: string) => {
            setValueForm('CA', value)
            if (values.chemicalCompositions.CaCO3) {
              setFieldValue('chemicalCompositions.CaCO3', '')
            }
          }}
          onChangeSecondTextValue={(value: string) => {
            setValueWithEquivalence({
              value,
              equivalence: equivalences.CA,
              firstNameValue: 'CA',
              secondValueName: 'CaCO3',
            })
          }}
        />

        <InputSelectChemicalComposition
          title={`S ${t('VIEWS.SUPPLIES.SULFUR')}`}
          fieldName='optionsSulfur'
          firstText={`% ${t('VIEWS.SUPPLIES.SULFUR')}`}
          secondText={`% ${t('VIEWS.SUPPLIES.SO4')}`}
          firstOption='S'
          secondOption='SO4'
          optionValue={values.optionsSulfur}
          setFieldValue={setFieldValue}
          firstTextValue={values.chemicalCompositions.S}
          secondTextValue={values.chemicalCompositions.SO4}
          onChangeFirstTextValue={(value: string) => {
            setValueForm('S', value)
            if (values.chemicalCompositions.SO4) {
              setFieldValue('chemicalCompositions.SO4', '')
            }
          }}
          onChangeSecondTextValue={(value: string) => {
            setValueWithEquivalence({
              value,
              equivalence: equivalences.S,
              firstNameValue: 'S',
              secondValueName: 'SO4',
            })
          }}
        />
      </FormikProvider>
    </View>
  )
}

const styles = StyleSheet.create({
  compositionDescriptionContainer: { marginBottom: 20, paddingLeft: 10 },
  compositionDescriptionTitle: {
    fontSize: 18,
    lineHeight: 28,
    fontWeight: '600',
    color: carbon800,
    fontFamily: 'Inter',
  },
  compositionDescriptionSubTitle: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
    color: carbon800,
    fontFamily: 'Inter',
  },
  selectLabel: {
    color: carbon800,
    fontFamily: 'Inter',
  },
  selectButtonText: {
    color: carbon800,
    fontFamily: 'Inter',
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 24,
  },
})
