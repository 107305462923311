import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'

import { primary500 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import { useSelector } from 'react-redux'

export const ValidateButton = ({
  isValidateButtonAvailable,
  isValidateButtonLoading,
  onPressValidateButton,
  isValidateButtonVisible = true,
  isModalVerifyPinVisible,
}) => {
  if (!isValidateButtonVisible) {
    return false
  }

  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { t } = useContext(LanguageContext)

  return (
    <ButtonCustom
      disabled={!isConnected || !isValidateButtonAvailable}
      isLoading={isValidateButtonLoading || isModalVerifyPinVisible}
      onPress={onPressValidateButton}
      styles={[
        styles.button,
        !isConnected || !isValidateButtonAvailable ? styles.buttonDisabled : {},
      ]}
      labelStyle={[
        styles.textButton,
        !isConnected || !isValidateButtonAvailable
          ? styles.textButtonDisabled
          : {},
      ]}
    >
      {t('VIEWS.ACTIVITY_CREATE.TEXT_2')}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: primary500,
    marginVertical: 5,
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: 'white',
  },
  textButtonDisabled: {
    color: 'rgba(51, 51, 51, 0.38)',
  },
})

ValidateButton.propTypes = {
  isValidateButtonAvailable: PropTypes.bool.isRequired,
  isValidateButtonLoading: PropTypes.bool.isRequired,
  onPressValidateButton: PropTypes.func.isRequired,
  isValidateButtonVisible: PropTypes.bool,
  isRealized: PropTypes.bool,
  canSign: PropTypes.bool,
  isSigned: PropTypes.bool,
}
