import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  storageTypes: [],
}

export const storageTypesSlice = createSlice({
  name: 'storageTypes',
  initialState,
  reducers: {
    /**
     * @param {object} state
     * @property {string} state.url
     * @property {func} state.onMessage
     *
     * @param {object} action
     * @property {object} action.payload
     * @property {string} action.payload.url
     * @property {func} action.payload.onMessage
     */
    setStorageTypes: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        storageTypes: action.payload,
      }
      return state
    },
    resetStorageTypes: (state) => {
      state = initialState
      return state
    },
  },
})

export const { setStorageTypes, resetStorageTypes } = storageTypesSlice.actions

export const { reducer: storageTypesReducer } = storageTypesSlice
