import { useContext } from 'react'
import { round, groupBy } from 'lodash'
import { useSelector } from 'react-redux'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { ACTIVITY_CONDITIONS } from '@modules/common/utils'
import { useAuth, useCompanyInfo } from '@common/hooks'
import activityTypesConstants from '@constants/activityTypes'
import { GlobalStateInterface } from '@store/interfaces'

export const useActivityWrapper = ({ subTypeActivities, offlineCrop }: any) => {
  const { t }: any = useContext(LanguageContext)
  const { user } = useAuth()
  const { activityTypes } = useSelector(
    (state: GlobalStateInterface) => state.activityTypesReducer
  )
  const { typeAgreements } = useSelector(
    (state: any) => state.typeAgreementsReducer
  )
  const { unitTypes } = useSelector(
    (state: GlobalStateInterface) => state.unitTypesReducer
  )
  const { isConnected } = useSelector((state: any) => state.connectionReducer)

  const { unitArea } = useCompanyInfo()

  const wrapData = ({ currentData, newData }: any) => {
    const data = newData.map(
      ({
        id: draftId,
        _id: activityId,
        condition: activityCondition = ACTIVITY_CONDITIONS.DRAFT.key,
        type,
        lots = [],
        establishments = [],
        percent,
        signers = [],
        surface,
        subTypeActivity: activitySubTypeActivity,
        eiqPlanned: activityEiqPlanned,
        eiqRealized: activityEiqRealized,
        pay: activityPay,
        unitType: activityUnitType,
        isDirectAchievement,
        achievements = [],
        verificationStatus,
        tag: activityTag,
        waterConsumption,
        typeAgreement,
      }: any) => {
        const activityFarms = parseFarms({ establishments, lots })
        const activityTypeId = type?._id || type?.value || type
        const subTypeActivityId =
          activitySubTypeActivity?._id || activitySubTypeActivity

        const activityType = activityTypes.find(
          ({ value }: any) => value === activityTypeId
        )
        const tag = activityType?.tag || activityTag

        const subTypeActivity = subTypeActivities.find(
          ({ value }: any) => value === subTypeActivityId
        )

        const unitType = unitTypes.find(
          ({ value }: any) =>
            value === activityUnitType || value === activityUnitType?._id
        )

        const activityName = activityType?.label

        const canPlanning = activityType?.canPlanning

        const eiqActivity =
          activityEiqRealized !== undefined
            ? activityEiqRealized
            : activityEiqPlanned !== undefined
            ? activityEiqPlanned
            : 0

        const condition = draftId
          ? ACTIVITY_CONDITIONS.DRAFT.key
          : activityCondition
        const status = ACTIVITY_CONDITIONS[condition].name(t)
        const activityTypeKey = tag
        const activityTypeName = activityName
        const farmsQuantity = activityFarms.length
        const fieldsQuantity = activityFarms.reduce(
          (accumulator: number, { lots: fields }: any) =>
            accumulator + fields.length,
          0
        )
        const farms = activityFarms.map(
          ({ farm: { name, surface }, lots: fields }: any) => {
            const farmName = name
            const farmSurface =
              surface !== undefined
                ? round(surface, 4)
                : round(
                    fields.reduce(
                      (accumulator: number, { surface }: any) =>
                        accumulator + surface,
                      0
                    ),
                    4
                  )
            const farmFields = fields.map(({ name, surface }: any) => ({
              name,
              surface: round(surface, 4),
              areaUnit: unitArea,
            }))

            const farmToReturn = {
              name: farmName,
              surface: farmSurface,
              areaUnit: unitArea,
              fields: farmFields,
            }

            return farmToReturn
          }
        )
        const percentageRealized = parsePercentageRealized({
          condition,
          canPlanning,
          isDirectAchievement,
          achievements,
          percent,
        })
        const pendingSignsQuantity =
          ACTIVITY_CONDITIONS.DONE.key === condition
            ? !canPlanning || isDirectAchievement
              ? signers.filter(({ signed }: any) => !signed).length
              : achievements.reduce(
                  (accumulator: number, { signers }: any) =>
                    accumulator +
                    signers.filter(({ signed }: any) => !signed).length,
                  0
                )
            : undefined
        const activitySurface =
          activityTypesConstants.ACT_HARVEST.key !== tag &&
          activityTypesConstants.ACT_MONITORING.key !== tag
            ? round(surface, 4)
            : undefined
        const subActivityTypeName = canPlanning
          ? subTypeActivity?.label
          : undefined
        const eiq =
          activityTypesConstants.ACT_APPLICATION.key === tag
            ? round(eiqActivity, 2)
            : undefined
        const pay =
          activityTypesConstants.ACT_HARVEST.key === tag
            ? round(activityPay, 2)
            : undefined
        const payUnitName = unitType?.label
        const estimatedPay =
          activityTypesConstants.ACT_MONITORING.key === tag
            ? round(activityPay, 2)
            : undefined
        const estimatedPayUnitName = unitType?.label
        const currentUserIsActivitySigner =
          condition === ACTIVITY_CONDITIONS.DRAFT.key
            ? true
            : signers.some(({ userId }: any) => userId === user._id)
        const activitySignedByCurrentUser = signers.find(
          ({ signed, userId }: any) => signed && userId === user._id
        )
        let typeAgreementName = null
        if (typeAgreement) {
          typeAgreementName =
            typeAgreement?.codeLabel ??
            typeAgreements?.find(({ value }: any) => value === typeAgreement)
              ?.label
        }
        const dataToReturn = {
          canPlanning,
          isDirectAchievement,
          activitySignedByCurrentUser,
          currentUserIsActivitySigner,
          draftId,
          activityId,
          condition,
          status,
          activityTypeKey,
          activityTypeName,
          farmsQuantity,
          fieldsQuantity,
          farms,
          percentageRealized,
          pendingSignsQuantity,
          verificationStatus,
          activitySurface,
          areaUnit: unitArea,
          subActivityTypeName,
          eiq,
          pay,
          payUnitName,
          estimatedPay,
          estimatedPayUnitName,
          subTypeActivity,
          waterConsumption,
          typeAgreementName,
        }

        return dataToReturn
      }
    )

    return {
      data: currentData.concat(data),
    }
  }

  const parsePercentageRealized = ({
    condition,
    canPlanning,
    isDirectAchievement,
    achievements,
    percent,
  }: any): number | any => {
    if (ACTIVITY_CONDITIONS.PLANNED.key === condition) {
      return percent
    }

    if (ACTIVITY_CONDITIONS.DONE.key === condition) {
      if (!canPlanning || isDirectAchievement) {
        return percent
      }

      const achievementsPercent: number = achievements.reduce(
        (accumulator: number, { percent }: any) => accumulator + percent,
        0
      )

      return achievementsPercent
    }

    return undefined
  }

  const parseFarms = ({ establishments, lots }: any) => {
    if (isConnected || (establishments.length && !offlineCrop)) {
      return establishments
    }

    const activityFarms = groupBy(lots, 'farm')

    const farms = offlineCrop.establishments
      .filter(({ farm: { _id } }: any) => activityFarms[_id])
      .map((element: any) => ({
        ...element,
        lots: element.lots.filter(({ _id }: any) =>
          lots.find((lot: any) => lot._id === _id)
        ),
      }))

    return farms
  }

  return {
    wrapData,
  }
}
