import { useEffect } from 'react'
import { Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import NetInfo from '@react-native-community/netinfo'
import { debounce } from 'lodash'

import { setConnectionValues } from '@store/slices'

export const useConnection = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (Platform.OS === 'web') {
      return
    }

    const unsubscribe = NetInfo.addEventListener(
      debounce(async (state) => {
        let isConnected = true
        let lowConnection = false

        if (
          state.type === 'cellular' &&
          state.details.cellularGeneration !== '4g' &&
          state.details.cellularGeneration !== '3g'
        ) {
          lowConnection = true
        }

        if (!state.isInternetReachable) {
          isConnected = false
        }

        dispatch(
          setConnectionValues({
            isConnectionReady: true,
            isConnected,
            lowConnection,
          })
        )
      }, 400)
    )

    return () => unsubscribe()
  }, [])
}
