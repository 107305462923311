import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Localization from 'expo-localization'
import I18n from 'i18n-js'
import es from './es'
import en from './en'
import pt from './pt'

const checkLanguageStorage = async () => {
  const lang = await AsyncStorage.getItem('language')

  I18n.locale = lang
  if (lang) I18n.locale = lang
  else {
    const value = Localization.locale || 'en'
    await AsyncStorage.setItem('language', value)
    I18n.locale = value
  }
}

checkLanguageStorage()

I18n.fallbacks = true
I18n.translations = {
  es,
  en,
  pt,
}

export default I18n
