import React, { useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { LanguageContext } from '@contextState/language'

const ConnectionStatusSnackbar = () => {
  const { t } = useContext(LanguageContext)
  return (
    <View style={styles.containerOffline}>
      <Text style={styles.textOffline}>
        {t('COMPONENTS').COMMON.CONNECTION_STATUS_SNACKBAR.TEXT_1}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  containerOffline: {
    backgroundColor: '#FF0C3E',
    padding: 16,
  },
  textOffline: {
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: 'white',
  },
})

export default ConnectionStatusSnackbar
