export default {
  COUNTRY: 'País',
  COMPANY_NAME: 'Razón social',
  COMPANY_TYPE: 'Personería',
  ADDRESS: 'Dirección',
  SPECIFIC_ADDRESS: 'Piso/Depto/Oficina',
  ADVSER_RECOMENDATION:
    'Deberás agregar como colaboradores un *productor* *administrador* y un *asesor* *responsable* para poder finalizar la creación de la empresa.',
  SAVE_COMPANY: 'Crear empresa',
}
