import React, { useContext } from 'react'
import { Text, View, StyleSheet } from 'react-native'

import { LanguageContext } from '@contextState/language'
import { EvidenceCard, CollaboratorCard, CardInfoParameters } from '..'
import { getDataRenderResumeIrrigation } from '../../utils'

export const Irrigation = ({
  evidences,
  collaboratorsQuantity,
  subActivityTypeName,
  activityTypeName,
  cropName,
  companyName,
  fieldsQuantity,
  surface,
  unitArea,
  irrigationPowerSourceName,
  irrigationWaterSourceName,
  pumpingDepth,
  waterConsumption,
  horizontalWateringDepth,
  dateAchievement,
}: any) => {
  const { t } = useContext(LanguageContext)

  const { itemsIrrigation, itemsCrop, itemsActivity } =
    getDataRenderResumeIrrigation(
      {
        cropName,
        companyName,
        fieldsQuantity,
        surface,
        unitArea,
        irrigationPowerSourceName,
        irrigationWaterSourceName,
        pumpingDepth,
        waterConsumption,
        horizontalWateringDepth,
        dateAchievement,
      },
      t
    )

  return (
    <>
      <CardInfoParameters items={itemsCrop} />
      <CardInfoParameters items={itemsActivity} />
      <CardInfoParameters items={itemsIrrigation} />
      <EvidenceCard evidences={evidences} />
      <CollaboratorCard collaboratorsQuantity={collaboratorsQuantity} />
    </>
  )
}

const styles = StyleSheet.create({
  contentActivity: { marginLeft: 7 },
  textTypeActivity: {
    fontSize: 16,
    fontWeight: '500',
    textTransform: 'capitalize',
  },
  textSubTypeActivity: {
    fontSize: 16,
  },
})
