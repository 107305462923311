export default {
  TEXT_1: 'Composición',
  TEXT_2: 'EIQ',
  TEXT_3: 'Clases toxicológicas',
  TEXT_4: 'Superficie %{areaUnit}',
  TEXT_5: 'Unidad de medida',
  TEXT_6: 'Cantidad de',
  TEXT_7: 'Total',
  MODAL_DELETE: {
    TEXT_1: '¿Eliminar insumo?',
    TEXT_2: 'Se quitará este insumo de la actividad.',
    TEXT_3: 'CONSERVAR',
    TEXT_4: 'ELIMINAR',
  },
}
