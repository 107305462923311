/**
 * @returns {object}
 */
export const getFormikInitialValues = ({
  identifier = '',
  country = { label: '', value: '' },
}) => {
  return {
    country,
    identifier,
    email: '',
    confirmEmail: '',
    userType: {
      label: '',
      value: '',
    },
  }
}
