export default {
  TEXT_1: 'Select fields to apply',
  TEXT_2: 'Please, select fields that will apply to the ecosystem license.',
  TEXT_3:
    '%{hasSelected} %{areaUnit} Selected. Make sure you select between %{hasMinAvailable} and %{hasAvailable} %{areaUnit}',
  TEXT_4: 'ha',
  TEXT_5: 'All farms',
  TEXT_6: 'field already assigned to this ecosystem:',
  TEXT_7: 'These field has already been applied to this ecosystem.',
  TEXT_8: 'Field(s)',
  TEXT_9: 'Apply to %{lotsQuantity}field(s)',
  TEXT_10: 'Congratulations!',
  TEXT_11: 'The ecosystem license was applied to',
  TEXT_12: 'the selected fields',
  TEXT_13: 'Associate another ecosystem',
  TEXT_14: 'Go back to Crops',
  TEXT_15: 'We apologize',
  TEXT_16:
    'The total number of hectares\n available for this ecosystem \nhas been exhausted, and the field has already been applied by another producer.',
  TEXT_17: '%{overlappingPercentage}% OVERLAPPING',
  TEXT_18:
    'Field not available for this ecosystem; exceeds the predefined area.',
  FIELD_1: {
    NAME: 'Search by farm...',
  },
  TEXT_19: 'SELECT COMPANY',
  TEXT_20:
    'You have exceeded the available area.\n %{hasSelected} %{areaUnit} selected over %{hasAvailable} available',
  MODAL_UNASSOCIATE: {
    TITLE: 'Remove field',
    INFO: 'This field will no longer be associated with the selected ecosystem.',
    CONFIRM: 'Remove',
    CANCEL: 'Cancel',
  },
  CAN_APPLY_LICENSE:
    '%{hasSelected} %{areaUnit} selected over the ecosystem license range of %{hasMinAvailable} to %{hasAvailable} %{areaUnit}.',
  INVALID_APPLY_MINIMUM_ALLOWED:
    '%{hasSelected} %{areaUnit} selected.\n Minimum area requirement associated with the ecosystem license is %{hasMinAvailable} %{areaUnit}.',
  INVALID_APPLY_MAXIMUM_ALLOWED:
    '%{hasSelected} %{areaUnit} selected.\n Maximum area requirement associated with the ecosystem license is %{hasAvailable} %{areaUnit}.',
}
