/**
 *
 * @param {number | string} surface  The number to round.
 * @param {number} decimalPlace - Number of decimal places
 *
 * @return {number}
 */
export const truncateNumber = (numFloat, toFixed) => {
  if (isNaN(numFloat) || !numFloat.toString().length) return numFloat

  const regexp = new RegExp('^-?\\d+(?:.\\d{0,' + (toFixed || -1) + '})?')

  return parseFloat(numFloat.toString().match(regexp)[0])
}
