export default {
  TEXT_1: 'Cultivos',
  TEXT_2: 'Editar perfil',
  TEXT_3: 'Empresa',
  TEXT_4: 'Integraciones',
  TEXT_5: 'Ecosistemas',
  TEXT_6: 'Cerrar sesión',
  TEXT_7: 'Versión',
  TEXT_8: 'Alta de Empresa',
  TEXT_9: 'Fecha',
  TEXT_10: 'Actividades Pendientes',
  TEXT_11: 'Listado',
  TEXT_12: 'Dashboard',
  TEXT_13: 'Ver detalle de cultivo',
  TEXT_14: '¿Eliminar cultivo?',
  TEXT_15: 'Colaboradores',
  TEXT_16: 'Historia del cultivo',
  TEXT_17: 'Estas por eliminar este cultivo ¿Estás seguro?',
  TEXT_18: 'Eliminar',
  TEXT_19: 'No Eliminar',
  TEXT_20: 'Sin cultivos cargados',
  TEXT_21: 'Pendiente de autorización',
  TEXT_22: 'Aún no te an autorizado para firmar validaciones en esta empresa.',
  TEXT_23: 'Eliminar Borrador',
  TEXT_24: 'Colaboradores',
  TEXT_25: 'Integración con terceros',
  TEXT_26: 'Habilitar offline',
  TEXT_27: 'Deshabilitar offline',
  TEXT_28: 'Eliminar cultivo',
  TEXT_29: 'Asociar empresa',
  TEXT_30: 'actividades pendientes',
  TEXT_31: 'Colaborar',
  TEXT_32:
    'La sincronización offline de cultivos está habilitada para %{country}.\nPara trabajar offline con este cultivo, deshabilitar offline en el resto.',
  TEXT_33:
    'Creá tu primer cultivo para comenzar a registrar tus actividades, medir EIQ y tener acceso a los tableros de control.',
  TEXT_34: 'ACEPTAR',
  TEXT_35: 'Último cargado',
  TEXT_36: 'Mayor superficie',
  TEXT_37: 'Actividades pendientes',
  TEXT_38: 'Habilitados offline',
  TEXT_39: 'A-Z',
  TEXT_40: 'Ordenados por',
  TEXT_41:
    'Recuerda agregar el lote a un campo para que pueda ser asignado a un nuevo cultivo.',
  TEXT_42: 'Crea tu primer cultivo y comienza a registrar tus actividades.',
}
