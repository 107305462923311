import React from 'react'
import { View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { PropTypes } from 'prop-types'
import { HeaderAppIntegrator } from '@modules/AppIntegrator/components/Header'
import { PluginList } from '@modules/AppIntegrator/components/PluginList'
import { MODAL_ERRORS } from '@utils/constants'
import {
  userPermissions,
  validateRoleInUserConfigService,
} from '@utils/permissions'
import { useAuth } from '@common/hooks'

function CompanyServiceIntegrationsSelectorScreen({ route, navigation }) {
  const { config } = useAuth()

  const { roleSelected } = config

  if (
    !validateRoleInUserConfigService(
      userPermissions.CREATE_PLUGIN,
      roleSelected
    )
  ) {
    navigation.navigate('ModalErrorDeepLinks', {
      error: MODAL_ERRORS.UNAUTHORIZED,
    })
  }

  const isS2 = route.params?.isS2 || false
  return (
    <View style={styles.container} dataSet={{ media: ids.container }}>
      <HeaderAppIntegrator />
      <PluginList isS2={isS2} route={route} navigation={navigation} />
    </View>
  )
}

const { styles, ids } = StyleSheet.create({
  container: {
    flex: 1,
    paddingRight: 24,
    paddingHorizontal: 24,
    paddingTop: 20,
    marginLeft: 180,
    marginRight: 260,
    '@media (max-width: 1199px)': {
      marginLeft: 180,
      marginRight: 200,
    },
    '@media (max-width: 960px)': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
})

CompanyServiceIntegrationsSelectorScreen.cropTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CompanyServiceIntegrationsSelectorScreen
