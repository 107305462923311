export default {
  TEXT_1: 'Application details',
  TEXT_2: 'ha',
  TEXT_3: 'EIQ',
  TEXT_4: 'Validated',
  TEXT_5: 'validate',
  TEXT_6: 'Field',
  TEXT_7: 'Generated EIQ',
  TEXT_8: 'ha',
}
