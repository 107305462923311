import { getObjectId } from '@utils'

export const buildEstablishmentDraft = (lotsInDraft, establishments) => {
  const result = establishments
    .map((establishment) => {
      const lots = establishment?.lots?.filter((lot) =>
        lotsInDraft.find((lotInDraft) => getObjectId(lotInDraft) === lot._id)
      )

      const surface =
        establishment.surface ||
        lots.reduce((accumulator, { surface }) => accumulator + surface, 0)

      if (lots.length) {
        return {
          ...establishment,
          lots,
          surface,
        }
      }
    })
    .filter((establishment) => establishment)
  return result
}
