export default {
  TEXT_1: 'Composition',
  TEXT_2: 'EIQ',
  TEXT_3: 'Toxicological Classes',
  TEXT_4: 'Area %{areaUnit}',
  TEXT_5: 'Measurement unit',
  TEXT_6: 'Rate of %{unit}/%{areaUnit}',
  TEXT_7: 'ha',
  TEXT_8: 'Total',
  TEXT_9: 'Select',
  TEXT_10: 'Class',
  TEXT_11: 'Limited',
}
