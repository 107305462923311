export default {
  TEXT_1: 'No camera permissions',
  TEXT_2: 'Please go to settings and active manually',
  TEXT_3: 'Cancel',
  TEXT_4: 'Allow',
  TEXT_5:
    'The file you are trying to upload is not a KMZ format type or has been damaged.',
  TEXT_6: 'The allowed extensions would be .doc,.docx,.pdf,.png,.jpg,.zip',
  TEXT_7: 'File extension is not allowed',
  TEXT_8: 'ACCEPT',
  TEXT_9: 'UCROP.IT Need location permission',
  TEXT_10: 'Documents',
  TEXT_11: 'Camera',
  TEXT_12: 'Dashboard',
  TEXT_13: 'Add evidence',
  TEXT_14: 'Looking for georeference...',
  TEXT_15: 'Please dont leave this screen',
}
