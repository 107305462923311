import React, { useContext, useState } from 'react'
import { CommonContext } from '@contextState/common'
import ButtonCustom from '@components/common/ButtonCustom'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { List } from 'react-native-paper'
import { LanguageContext } from '@contextState/language'

function CompanyIntegrationLinkScreen({ route, navigation }) {
  const { servicesIntegration } = useContext(CommonContext)
  const [integration, setIntegration] = useState(null)
  const [service, setService] = useState('')
  const [id, setId] = useState('')
  const { t } = useContext(LanguageContext)

  React.useEffect(() => {
    const integration = servicesIntegration.find(
      (integration) => integration.label === route.params.service
    )

    setIntegration(integration)
    setId(route.params.id)
    setService(route.params.service)
  }, [])

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{service}</Text>
      <ScrollView style={styles.textItem}>
        {integration?.conditions.map((item, key) => (
          <List.Item
            key={key}
            descriptionStyle={styles.description}
            description={`${key + 1}-${item}`}
          />
        ))}
      </ScrollView>
      <ButtonCustom
        onPress={() => {
          navigation.navigate('CompanyServiceIntegrationAdd', {
            id: id,
            service: service,
          })
        }}
      >
        {t('VIEWS').COMPANY_INTEGRATION_LINK.TEXT_1}
      </ButtonCustom>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  button: {
    alignItems: 'center',
    fontSize: 22,
    marginRight: 4,
  },
  title: {
    padding: 20,
    textAlign: 'left',
    textTransform: 'capitalize',
    fontWeight: '600',
    fontSize: 22,
  },
  description: {
    fontSize: 12,
    textAlign: 'justify',
    color: 'black',
  },
  textItem: {
    textAlign: 'left',
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
  },
})

export default CompanyIntegrationLinkScreen
