import { FC, ReactElement, useContext } from 'react'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'

import { LanguageContext } from '@contextState/language'
import { HeaderOnlyBack, ResponsiveLayout } from '@common/components'
import Header from '@components/common/Header'
import WelcomeCompany from '@modules/companies/screens/WelcomeCompany/v1'
import ValidateTaxId from '@modules/companies/screens/ValidateTaxId/v1'
import AddCompanyScreen from '@modules/companies/screens/AddCompanyScreen/v1'
import SuccessAddCompany from '@modules/companies/screens/SuccessAddCompany'
import CollaboratorsListNewCompany from '@modules/collaborators/screens/CollaboratorsListNewCompany/v1'

const RootStack = createStackNavigator()
const Stack = createStackNavigator()

export const CompanyOnboardingStack: FC<any> = (): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const fullScreen = useSelector((state: any) => state.farm.fullScreen)

  const stacks = [
    {
      name: 'WelcomeCompany',
      component: WelcomeCompany,
      options: {
        title: '',
        header: () => null,
      },
    },
    {
      name: 'ValidateTaxId',
      component: ValidateTaxId,
      options: {
        title: '',
        header: (props: any) => (
          <HeaderOnlyBack {...props} title={''} testID='back_button' />
        ),
      },
    },
    {
      name: 'AddCompanyScreen',
      component: AddCompanyScreen,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_9'),
        header: (props: any) => {
          const { isAdviser } =
            props?.route?.params ?? props.scene?.route?.params

          const title = isAdviser
            ? t('NAVIGATION.STACK_NAVIGATOR.ADVISE_COMPANY')
            : t('NAVIGATION.STACK_NAVIGATOR.MANAGE_COMPANY')

          return <Header {...props} title={title} goBack testID='back_button' />
        },
      },
    },
    {
      name: 'CollaboratorsListNewCompany',
      component: CollaboratorsListNewCompany,
      options: {
        header: (props: any) => {
          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.COLLABORATORS')}
              goBack
              testID='back_button'
            />
          )
        },
      },
    },
    {
      name: 'SuccessAddCompany',
      component: SuccessAddCompany,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_9'),
        header: () => null,
      },
    },
  ]

  const Stacks = () => {
    return (
      <Stack.Navigator initialRouteName={'WelcomeCompany'}>
        {stacks.map((stack, key) => (
          <Stack.Screen {...stack} key={`ONBOARDING_STACK_${key}`} />
        ))}
      </Stack.Navigator>
    )
  }

  return (
    <ResponsiveLayout fullScreen={fullScreen}>
      <RootStack.Navigator>
        <RootStack.Screen
          name='OnboardingCompany'
          options={{ headerShown: false }}
          component={Stacks}
        />
      </RootStack.Navigator>
    </ResponsiveLayout>
  )
}
