import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { WebViewRenderIntegrator } from '@modules/webView/components'
import { resetWebViewValues } from '@store/slices'

const WebViewIntegrator = () => {
  const dispatch = useDispatch()

  const { url, onMessage, redirectUri } = useSelector((state) => {
    return state.webViewReducer
  })

  useEffect(() => {
    return () => {
      dispatch(resetWebViewValues())
    }
  }, [])

  if (!url) {
    return null
  }

  return (
    <WebViewRenderIntegrator
      url={url}
      onMessage={onMessage}
      redirectUri={redirectUri}
    />
  )
}

export default WebViewIntegrator
