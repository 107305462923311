import { useEffect, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import {
  GlobalStateInterface,
  SelectionPageReducerInterface,
} from '@store/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { resetDataSelection } from '@store/slices'

interface RouteParams {
  textButton?: string
  isViewReadOnly?: boolean | string
}

export const useCommonItemsMultipleLevels = () => {
  const dispatch = useDispatch()
  const { params } = useRoute()
  const {
    items,
    loading,
    callback,
    fieldNameRender,
    itemsSelected: itemsSelectedRedux,
    fieldIsSelected,
    HeaderRender,
    numItemsSelected,
    sumFieldSelected,
  }: SelectionPageReducerInterface = useSelector(
    (state: GlobalStateInterface) => state.selectionPageReducer
  )
  const { textButton, isViewReadOnly } = params as RouteParams

  const onSubmit = () => {
    if (callback) {
      callback(itemsSelectedRedux, sumFieldSelected)
    }
  }

  return {
    items,
    isLoading: loading,
    onSubmit,
    fieldNameRender,
    textButton,
    isViewReadOnly,
    fieldIsSelected,
    HeaderRender,
    numItemsSelected,
    itemsSelectedRedux,
  }
}
