import React, { useRef, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  LayoutAnimation,
  StyleSheet,
} from 'react-native'
import { Animated } from 'react-native'
import { CommonIcon } from '@common/components'
import { grayInput, white } from '@styles/palette'
import { Easing } from 'react-native'

const AccordionMobile = ({
  title = '',
  children,
  ContentHeader,
  titleContainerStyle = {},
  titleStyle = {},
  openByDefault = false,
  extraBodyStyle = {},
  otherParams = {},
  contentTop = false,
  extraPressCustom,
  colorArrow = grayInput,
}) => {
  const [expanded, setExpanded] = useState(openByDefault)

  const toggleExpand = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    if (expanded) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true,
      }).start()
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true,
      }).start()
    }
    setExpanded(!expanded)
    if (extraPressCustom) extraPressCustom()
  }

  const animatedController = useRef(
    new Animated.Value(Number(openByDefault))
  ).current

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  })

  return (
    <View>
      {ContentHeader ? (
        <ContentHeader
          arrowAngle={arrowAngle}
          onPress={() => toggleExpand()}
          isOpened={expanded}
          otherParams={otherParams}
        />
      ) : (
        <TouchableOpacity onPress={() => toggleExpand()}>
          <View style={[styles.titleContainer, titleContainerStyle]}>
            <Text style={titleStyle}>{title}</Text>
            <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
              <CommonIcon name='CHEVRON-DOWN' size={20} color={colorArrow} />
            </Animated.View>
          </View>
        </TouchableOpacity>
      )}
      {contentTop}
      <View style={styles.parentHr} />
      {expanded && (
        <View style={[styles.child, extraBodyStyle]}>{children}</View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 56,
    paddingLeft: 25,
    paddingRight: 18,
    alignItems: 'center',
    backgroundColor: white,
  },
  parentHr: {
    height: 1,
    color: 'white',
    width: '100%',
  },
  child: {
    backgroundColor: white,
    padding: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
})

export default AccordionMobile
