import * as yup from 'yup'
import { lettersAndCharactersSpecials, onlyNumbers } from '@common/utils'

export const getFormikValidationSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    firstName: yup
      .string()
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .matches(
        lettersAndCharactersSpecials,
        t('ERRORS.FORM_ERRORS.INVALID_CHARACTERS')
      ),
    lastName: yup
      .string()
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .matches(
        lettersAndCharactersSpecials,
        t('ERRORS.FORM_ERRORS.INVALID_CHARACTERS')
      ),
    phoneNumber: yup
      .string()
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .matches(onlyNumbers, t('ERRORS.FORM_ERRORS.IS_NUMBER')),
    valueCodeArea: yup
      .object()
      .shape({
        phoneCode: yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
  })
