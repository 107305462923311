import Constants from 'expo-constants'
import queryString from 'query-string'
import AsyncStorage from '@react-native-async-storage/async-storage'

const URL_EXPORTER_CIRCUIT =
  Constants.expoConfig?.extra?.EXPORTER_CIRCUIT_URL ?? ''
const EXPORTER_CIRCUIT_TOKEN =
  Constants.expoConfig?.extra?.EXPORTER_CIRCUIT_TOKEN ?? ''

const parseParams = async (params = {}) => {
  const token = await AsyncStorage.getItem('USER_TOKEN')
  const queryParams = {
    accessToken: token,
    ...params,
  }
  return queryString.stringify(queryParams)
}

const getUrlMainExporterCircuit = async () => {
  const params = await parseParams()
  const url = `${URL_EXPORTER_CIRCUIT}?${params}&tokenFromApp=${EXPORTER_CIRCUIT_TOKEN}`

  return encodeURI(url)
}

export const getUrlExporterCircuit = {
  getUrlMainExporterCircuit,
}
