import React, { useContext, useState, useEffect } from 'react'
import { LanguageContext } from '@contextState/language'
import { outlierType } from '@constants/outlierData'
import { outlierLevels } from '@utils/constants'
import { OutlierBox } from '../components'
import useNetwork from '@utils/network'
import {
  outlierOptionsRequest,
  urlsOutlierParserBox,
  getMaxOutlierLevel,
  validateCompanyCountryHasOutlierOff,
} from '@common/utils'

import { useAuth } from './useAuth'

export const useFetchOutliers = ({ item, surface, onPress }) => {
  const { config } = useAuth()
  const { doRequest } = useNetwork()
  const { t } = useContext(LanguageContext)
  const [outliers, setOutliers] = useState([])
  const [maxLevelOutlier, setMaxLevelOutlier] = useState({})

  const {
    companySelected: {
      country: { alpha3Code },
    },
  } = config

  const { typeId } = item

  const optionOutlier = outlierOptionsRequest(typeId?.tags)

  const { url, urlActiveIngredient } = urlsOutlierParserBox(
    item,
    optionOutlier,
    surface
  )

  const fetchOutliers = async (urlParams) => {
    if (!urlParams || validateCompanyCountryHasOutlierOff(alpha3Code)) {
      return
    }

    let data

    try {
      const request = await doRequest({
        method: 'GET',
        url: urlParams,
        displayAlert: false,
      })

      data = request.data
    } catch (error) {
      console.error(error)

      return
    }

    const newData = [data]

    setOutliers(newData)
  }

  useEffect(() => {
    fetchOutliers(url)
  }, [url])

  useEffect(() => {
    if (!urlActiveIngredient) {
      return
    }
    getOutlierRequest(urlActiveIngredient)
    // eslint-disable-next-line
  }, [urlActiveIngredient])

  useEffect(() => {
    setMaxLevelOutlier(getMaxOutlierLevel(outliers))
  }, [outliers])

  const getOutlierRequest = async (url) => {
    if (!url || validateCompanyCountryHasOutlierOff(alpha3Code)) {
      return
    }
    try {
      const request = await doRequest({
        method: 'GET',
        url: url,
        displayAlert: false,
      })
      const { data } = request
      if (typeof data === 'object') {
        const newData = [...outliers, data]
        setOutliers(newData)
      }

      if (data.length) {
        const newData = [...outliers, ...data]
        setOutliers(newData)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const ComponentOutlierBox = () => {
    return (
      maxLevelOutlier?.level > outlierLevels.ONE && (
        <OutlierBox
          title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
          onPress={onPress}
          type={
            maxLevelOutlier?.level > outlierLevels.TWO
              ? outlierType.DANGER
              : outlierType.WARNING
          }
        ></OutlierBox>
      )
    )
  }
  return {
    ComponentOutlierBox,
  }
}
