import React from 'react'
import { StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'

import Accordion from '@components/common/v2/AccordionItem'
import { AccordionHeader, FieldList } from '../'

export const FarmItem = ({ farm, onPressFieldDetails, index }) => {
  return (
    <Accordion
      otherParams={{
        farm,
      }}
      ContentHeader={AccordionHeader}
      extraBodyStyle={styles.extraBodyAccordion}
      openByDefault
    >
      <FieldList
        fields={farm.fields}
        onPressFieldDetails={onPressFieldDetails}
        index={index}
      />
    </Accordion>
  )
}

const styles = StyleSheet.create({
  extraBodyAccordion: {
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
  },
})

FarmItem.propTypes = {
  farm: PropTypes.object.isRequired,
  onPressFieldDetails: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}
