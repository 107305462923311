import { useEffect, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import { getUrlDrawFields } from '../utils/getUrlDrawFields'
import RouterNames from '@constants/routerNames'

export const useMakeUrl = (resourceParameter) => {
  const [urlWebView, setUrlWebView] = useState(null)

  const route = useRoute()

  const { type, url, resource, callback } = route.params

  useEffect(() => {
    getUrl()
  }, [])

  const typesRender = {
    farm: {
      renderUrl: getUrlDrawFields.getUrlDetailFarm,
    },
    field: {
      renderUrl: getUrlDrawFields.getUrlDetailField,
    },
    mainFarm: {
      renderUrl: getUrlDrawFields.getUrlMainFarm,
      redirectUrl: RouterNames.FARMS,
    },
    createFarm: {
      renderUrl: getUrlDrawFields.getUrlCreateFarm,
      redirectUrl: RouterNames.FARMS,
      isFullScreen: true,
    },
    draftFarm: {
      renderUrl: getUrlDrawFields.getUrlDraftFarm,
      redirectUrl: RouterNames.FARMS,
      isFullScreen: true,
    },
    editFarm: {
      renderUrl: getUrlDrawFields.getUrlEditFarm,
      redirectUrl: RouterNames.FARMS,
      isFullScreen: true,
    },
    make_url: {
      resultUrl: url,
    },
  }

  const getUrl = async () => {
    if (!route.params) {
      return
    }

    const selected = resource ?? resourceParameter

    const tySelected = typesRender[type] || typesRender[selected]

    const resultUrl = tySelected.renderUrl
      ? await tySelected.renderUrl(route.params)
      : tySelected.resultUrl

    setUrlWebView(resultUrl)
  }

  return { urlWebView, callback }
}
