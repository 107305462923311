import * as Yup from 'yup'

const MAX_CHARACTERS = 50

export const farmEditLotNameValidationSchema = (t) =>
  Yup.object().shape({
    lotName: Yup.string()
      .trim()
      .min(1, t('VIEWS.FARM_LOT_EDIT_NAME.TEXT_2'))
      .max(
        50,
        t('VIEWS.FARM_LOT_EDIT_NAME.TEXT_3', {
          quantity: MAX_CHARACTERS,
        })
      )
      .required(t('VIEWS.FARM_LOT_EDIT_NAME.TEXT_2')),
  })
