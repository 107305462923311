export default {
  ACT_TILLAGE: 'Labranza',
  ACT_APPLICATION: 'Aplicación',
  ACT_FERTILIZATION: 'Fertilización',
  ACT_SOWING: 'Siembra',
  ACT_HARVEST: 'Cosecha',
  ACT_AGREEMENTS: 'Declaración',
  ACT_MONITORING: 'Monitoreo',
  ACT_VERIFICATION: 'Verificación',
  ACT_IRRIGATION: 'Riego',
}
