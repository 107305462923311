import React from 'react'
import { StyleSheet } from 'react-native'
import { Button } from 'react-native-paper'

function ButtonCustom({
  children,
  isLoading = false,
  disabled = false,
  height = 50,
  ...props
}) {
  return (
    <Button
      {...props}
      disabled={disabled || isLoading}
      style={[
        {
          height: height,
        },
        styles.button,
        props.styles,
      ]}
      contentStyle={[
        {
          height: height,
        },
        styles.button,
        props.contentStyle,
      ]}
      labelStyle={[styles.label, props.labelStyle]}
      loading={isLoading}
      uppercase={false}
    >
      {!isLoading && children}
    </Button>
  )
}

ButtonCustom.defaultProps = {
  mode: 'contained',
}

const styles = StyleSheet.create({
  button: { borderRadius: 4 },
  label: { color: 'white' },
})

export default ButtonCustom
