export const parseDataToFormik = ({
  formikInitialValues,
  createCollaboratorConfig: {
    defaultCountry,
    defaultIdentifier,
    defaultEmail,
    defaultUserType,
  },
}) => {
  const newFormikData = {}

  if (defaultCountry) {
    newFormikData.country = defaultCountry
  }

  if (defaultIdentifier) {
    newFormikData.identifier = defaultIdentifier
  }

  if (defaultEmail) {
    newFormikData.email = defaultEmail
  }

  if (defaultUserType) {
    newFormikData.userType = defaultUserType
  }

  return {
    ...formikInitialValues,
    ...newFormikData,
  }
}
