export default {
  TEXT_1: 'Licencia',
  TEXT_2: 'He leído y cumplo con este apartado',
  TEXT_3: 'Firmar',
  TEXT_4: 'Visibilidad de los datos',
  TEXT_5:
    'La firma de esta licencia implica compartir los datos de los cultivos a los que se aplique con la empresa emisora.',
  TEXT_6: 'RECHAZAR',
  TEXT_7: 'ACEPTAR',
}
