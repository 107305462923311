export default {
  TEXT_1: 'The collaborator already exists for this crop',
  TEXT_2: 'Collaborator added successfully',
  TEXT_3: 'Required field',
  TEXT_4: 'Enter a number',
  TEXT_5: 'Invalid Tax Id',
  TEXT_6: 'The Invalid Tax Id',
  FIELD_1: {
    LABEL: 'Tax Id',
    HELPTEXT: 'From the physical or legal company in charge of production.',
  },
  FIELD_2: {
    LABEL: 'Tax Id',
    HELPTEXT: 'From the physical or legal company in charge of production.',
  },
  FIELD_3: {
    LABEL: 'Country',
  },
  FIELD_4: {
    LABEL: 'Email',
  },
  FIELD_5: {
    LABEL: 'Collaborator Type',
    HELPTEXT:
      'The farmer will be able to see, edit and validate all the activities',
  },
  TEXT_7: 'Save',
  TEXT_8: 'Invalid email format. ejemplo@mail.com',
}
