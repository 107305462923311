import { FC, ReactElement, useContext } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'

import { LanguageContext } from '@contextState/language'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'
import ProfileScreen from 'src/screens/ProfileScreen'

const Stack = createStackNavigator()

export const ProfileStack: FC<any> = (): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const stacks = [
    {
      name: RouterNames.PROFILE,
      component: ProfileScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_1'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_1')}
            drawer={Platform.OS !== 'web'}
          />
        ),
      },
    },
  ]

  return (
    <Stack.Navigator initialRouteName={RouterNames.PROFILE}>
      {stacks.map((stack, key) => (
        <Stack.Screen {...stack} key={`PROFILE_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
