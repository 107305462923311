export default {
  TEXT_1: 'Adicionar Contribuidor',
  TEXT_2: 'ADICIONAR CONTRIBUIDOR',
  FIELD_1: {
    PLACEHOLDER: 'País',
    LABEL: 'País',
  },
  FIELD_2: {
    PLACEHOLDER: 'CUIT',
    LABEL: 'CUIT',
  },
  FIELD_3: {
    PLACEHOLDER: 'Correio eletrônico',
    LABEL: 'Correio eletrônico',
  },
  FIELD_4: {
    PLACEHOLDER: 'Tipo de contribuidor',
    LABEL: 'Tipo de contribuidor',
  },
}
