export default {
  PARAGRAPH_BY_DELETING_ACCOUNT:
    'By deleting your account, we will remove all your personal and usage data from our systems.',
  PARAGRAPH_ACTION_IRREVERSIBLE:
    'This action is irreversible and, once deleted, we will not be able to restore it.',
  DELETE_ACCOUNT: 'Delete account',
  CANCEL: 'CANCEL',
  MODAL_CONFIRM_DELETE_ACCOUNT: {
    PARAGRAPH_1:
      'This action is irreversible and, once deleted, we will not be able to restore it.\n\nDo you confirm to delete your account?',
    DELETE: 'DELETE',
  },
  MODAL_SUCCESS_DELETED_ACCOUNT: {
    DELETED_ACCOUNT: 'Deleted account',
    PARAGRAPH_HAVE_DELETED_ACCOUNT:
      'We have deleted your account and data from our system.',
    CLOSE: 'CLOSE',
  },
}
