import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Text, Pressable, Animated } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import useFadeAnimation from '../../../hooks/useFadeAnimation'

const Accordion = ({
  expanded,
  onPress: onPressAccordionCustom,
  style = {},
  title,
  titleStyle = {},
  subtitle,
  subtitleStyle = {},
  children,
}) => {
  const {
    animatedValue: childrenOpacity,
    fadeIn,
    fadeOut,
  } = useFadeAnimation({
    durationIn: 300,
    durationOut: 150,
  })

  const [isDisplayed, setIsDisplayed] = useState(expanded)

  useEffect(() => {
    isDisplayed ? fadeIn() : fadeOut()
  }, [isDisplayed])

  useEffect(() => {
    if (expanded === undefined) {
      return
    }

    setIsDisplayed(expanded)
  }, [expanded])

  const onPressAccordion = (value) => {
    if (onPressAccordionCustom) {
      onPressAccordionCustom()

      return
    }

    setIsDisplayed(value)
  }

  return (
    <View style={styles.container}>
      <Pressable
        style={[styles.header, style]}
        onPress={() => onPressAccordion(!isDisplayed)}
      >
        <View style={styles.headerTexts}>
          <Text style={[styles.headerTitle, titleStyle]}>{title}</Text>

          {subtitle && (
            <Text style={[styles.headerSubTitle, subtitleStyle]}>
              {subtitle}
            </Text>
          )}
        </View>

        <View style={styles.arrowIconContainer}>
          {!isDisplayed ? (
            <CommonIcon name='CHEVRON-DOWN' size={16} color='black' />
          ) : (
            <CommonIcon name='CHEVRON-UP' size={16} color='black' />
          )}
        </View>
      </Pressable>

      {isDisplayed && (
        <Animated.View style={[{ opacity: childrenOpacity }]}>
          {children}
        </Animated.View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTexts: {
    flexDirection: 'column',
    flex: 1,
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: '500',
    color: '#333333',
  },
  headerSubTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  arrowIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginLeft: 14,
  },
})

Accordion.propTypes = {
  expanded: PropTypes.bool,
  onPress: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  subtitle: PropTypes.string,
  subtitleStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Accordion
