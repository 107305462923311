export default {
  TEXT_1: 'The crop has no collaborators',
  TEXT_2: 'Farmer',
  TEXT_3: 'FARMER',
  TEXT_4: 'PENDING AUTHORIZATION',
  TEXT_5: 'Remove label',
  TEXT_6: 'Unlink Collaborator',
  TEXT_7: 'Unlink Collaborator?',
  TEXT_8: 'You are about to unlink a collaborator from the crop, are you sure?',
  TEXT_9: 'CANCELAR',
  TEXT_10: 'UNLINK',
  TEXT_11: 'No collaborators',
  TEXT_12: 'Crop Owner',
  TEXT_13: 'ucrop.it assistant (KAM)',
  TEXT_14: 'Crop salesperson',
  TEXT_15: 'Pending authorization',
  TEXT_16: 'Pending validation',
  TEXT_17: 'Pending validation',
  TEXT_18: 'Delegate validations',
  TEXT_19: 'Unlink Collaborator',
  TEXT_20: 'Delegate',
  TEXT_21: 'me',
  TEXT_22: 'OK',
  TEXT_23: 'All pending validations will be delegated to this collaborator',
  TEXT_24: 'Validations were successfully delegated!',
  TEXT_25:
    'Could not delegate validations correctly. Please check the connection and try again.',
  MODAL_ACTION_SHEET: {
    TEXT_1: 'Select replacement',
    TEXT_2:
      'There are no eligible collaborators to receive pending validations. Please add a new collaborator.',
    TEXT_3:
      'All pending validations will be delegated to another enabled collaborator with the same role (%{rol}) in this crop.',
    TEXT_4: 'Unlink from Crop',
    TEXT_5: '¡This collaborator has no pending validations!',
    TEXT_6: 'Appoint a new ',
    TEXT_7: ' before unlinking this collaborator',
    TEXT_8:
      'All pending validations must be delegated to another collaborator in this crop.',
  },
  TEXT_26: 'OK',
  MODAL_RESPONSIBLE: {
    TEXT_1: 'New Mamager',
    TEXT_2: "We'll replace",
    TEXT_3: 'current',
    TEXT_4: 'Cancel',
    TEXT_5: 'OK',
  },
  TEXT_27: 'confirm delegation',
  TEXT_28: 'Confirm Replacement',
  TEXT_29: '[You]',
  MODAL_CONFIRM: {
    TEXT_1: 'Confirm Delegate and Unlink',
    TEXT_2: 'All pending validations will be delegated to this collaborator',
    TEXT_3: 'DELEGATE AND UNLINK COLLABORATOR',
  },
  MODAL_UNLINK: {
    TEXT_1:
      'All pending validations must be delegated to another collaborator in this crop.',
    TEXT_2:
      'There are no eligible collaborators to receive pending validations. Please add a new collaborator.',
    TEXT_3: 'delegate pendieng validations',
  },
  TEXT_30: 'No pending validations',
  TEXT_31: 'Error when unlinking collaborator.',
  TEXT_32: 'Collaborator was unlinked successfully.',
  TEXT_33: 'Collaborator was replaced successfully.',
  MODAL_UNLINK_VERIFIER: {
    TEXT_1: 'Are you sure you want to remove',
    TEXT_2: ' Verifier ',
    TEXT_3:
      'from the crop? By doing so you may not be fulfilling certain regulations',
  },
  TEXT_34: 'Verifier Admin',
}
