import DONWLOAD_REPORT from '../../components/DonwloadReport/translate/pt'
import REQUESTED_MODAL from '../../components/RequestedReportModal/translate/pt'
export default {
  COMPONENTS: {
    DONWLOAD_REPORT,
    REQUESTED_MODAL,
  },
  LOCATION: 'Localização',
  LOTS: 'Lotes',
  BUTTON_ADD_LOTS_TO_FARM: 'ADICIONAR LOTES AO CAMPO',
  BUTTON_EDIT_LOTS_TO_FARM: 'ADICIONAR LOTES',
}
