import { StyleSheet, Text, View } from 'react-native'
import { carbon800 } from '@styles/palette'

export const ChemicalCompositionItem = ({
  composition,
  value,
}: {
  composition: string
  value: number
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{composition}</Text>

      <Text style={styles.text}>{value}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 180,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 16,
    color: carbon800,
    fontWeight: '400',
    lineHeight: 20,
    fontFamily: 'Inter',
  },
})
