const COMPANY_TYPE_PERSON = {
  PHYSICAL_PERSON: {
    key: 'PHYSICAL_PERSON',
    name: (t) => t('VIEWS.COMPANY_DETAIL.TEXT_1'),
  },
  LEGAL_PERSON: {
    key: 'LEGAL_PERSON',
    name: (t) => t('VIEWS.COMPANY_DETAIL.TEXT_2'),
  },
}

export { COMPANY_TYPE_PERSON }
