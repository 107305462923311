import * as yup from 'yup'

import { notSpecialCharacters } from '@common/utils/regularsExpressions'

export const getFormikValidationSchema = (t) =>
  yup.object().shape({
    taxid: yup
      .string()
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .typeError(t('ERRORS.FORM_ERRORS.NUMBER_INVALID'))
      .matches(
        notSpecialCharacters,
        t('ERRORS.FORM_ERRORS.NOT_SPECIAL_CHARACTERS')
      ),
  })
