import HEADER from '../../components/Header/translate/es'
import APP_INTEGRATOR_DETAILS from '../../screens/AppIntegratorDetails/translate/es'

export default {
  COMPONENTS: {
    HEADER,
    APP_INTEGRATOR_DETAILS,
  },
  CONNECTED_APPLICATIONS: 'Aplicaciones conectadas',
  OTHER_APPLICATIONS: 'Otras aplicaciones',
  SYNCHRONIZATION_DESCRIPTION:
    'El tiempo de procesamiento puede variar en función a la cantidad de datos de su cuenta.',
  LAST_UPDATE: 'Última actualización',
  CONNECTED_TITLE: 'Conectado',
  OBTAINING_TITLE: 'Obteniendo información',
  INFORMATION_OBTAINED_TITLE: 'Información obtenida',
  VALIDATING: 'Validación pendiente',
  PENDING_VALIDATION: 'Validación pendiente',
  PENDING_VALIDATION_DESCRIPTION:
    'Para concluir el proceso de sincronización, deberás verificar y validar los datos obtenidos.',
}
