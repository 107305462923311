// @ts-ignore
import * as Yup from 'yup'

const MAX_COMPOSITION = 100
export const MIN_COMPOSITION_VALUE = 0.01

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/

const yupNumberTwoDecimalPlace = (t: any) =>
  Yup.number(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
    // .positive()
    .test('is-decimal', t('ERRORS.FORM_ERRORS.INVALID_FORMAT'), (val: any) => {
      if (val != undefined) {
        return patternTwoDigisAfterComma.test(val)
      }
      return true
    })
    // .min(0.01)
    .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list: any) {
  // @ts-ignore
  return this.test({
    name: 'atLeastOneOf',
    message: '$',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value: any) =>
      value == null || list.some((f: any) => value[f] != null),
  })
})

export const createGenericSupplyValidation = (t: any) =>
  Yup.object().shape({
    countryId: Yup.string().min(1).max(50).required('Required'),
    chemicalCompositions: Yup.object()
      .shape({
        N: Yup.object().shape({
          fountain: Yup.string().when('optionsNitrogen', {
            is: 'composition',
            then: Yup.string(),
          }),
          N: yupNumberTwoDecimalPlace(t),
          N_A: Yup.string().when('optionsNitrogen', {
            is: 'composition',
            then: yupNumberTwoDecimalPlace(t),
          }),
          N_N: Yup.string().when('optionsNitrogen', {
            is: 'composition',
            then: yupNumberTwoDecimalPlace(t),
          }),
          N_U: Yup.string().when('optionsNitrogen', {
            is: 'composition',
            then: yupNumberTwoDecimalPlace(t),
          }),
        }),
        P: yupNumberTwoDecimalPlace(t),
        K: yupNumberTwoDecimalPlace(t),
        CA: yupNumberTwoDecimalPlace(t),
        S: yupNumberTwoDecimalPlace(t),
      })
      .atLeastOneOf(['N', 'P', 'K', 'CA', 'S'])
      .test(
        'less_or_equal_100',
        t('VIEWS.SUPPLIES.THE_COMPOSITION_CANNOT_EXCEED_HUNDRED_PERCENT'),
        (chemicalCompositions: object) => {
          const total = Object.entries(chemicalCompositions)
            .filter(([key]) => ['N', 'P', 'K', 'CA', 'S'].includes(key))
            .reduce((a: number, [key, value]: [string, any]) => {
              const evValue = key === 'N' ? value?.N : value
              return evValue ? evValue + a : a
            }, 0)
          return total <= MAX_COMPOSITION
        }
      ),

    unit: Yup.number().transform((value: any) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    ),
  })
