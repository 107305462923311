export const parseToValidateIrrigation = (values: any) => {
  const dataToSend = {
    ...values,
    pumpingDepth: Number(values.pumpingDepth),
    waterConsumption: Number(values.waterConsumption),
    horizontalWateringDepth: Number(values.horizontalWateringDepth),
    evidences: values.evidences?.length ? values.evidences : undefined,
    noFiles: true,
  }

  return dataToSend
}
