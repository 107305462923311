import { View, Text, StyleSheet } from 'react-native'
import React from 'react'

const ChipsComponent = ({
  text,
  icon,
  backgroundColor = 'black',
  textColor = 'white',
  fontWeight = 'bold',
  widthChips = 150,
  fontSize = 10,
  heightChips = 16,
  extraStyle = {},
  borderRadius = 5,
  textTransform = 'none',
}) => {
  const styles = StyleSheet.create({
    contentChip: {
      flexDirection: 'row',
      backgroundColor,
      minWidth: widthChips,
      borderRadius,
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: heightChips,
      ...extraStyle,
      paddingHorizontal: 2,
    },
    textChip: {
      color: textColor,
      fontWeight,
      letterSpacing: 1.5,
      fontSize,
      textTransform,
    },
  })
  return (
    <View style={styles.contentChip}>
      {icon}
      <Text style={styles.textChip}>{text}</Text>
    </View>
  )
}

export default ChipsComponent
