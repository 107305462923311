import React, { useEffect, useContext, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { FormikProvider, useFormik } from 'formik'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { grayBackground } from '@styles/palette'
import { getFormikValidationSchema, getFormikInitialValues } from './utils'
import FormCollaborator from './components/FormCollaborator'
import useNetwork from '@utils/network'
import { CommonActions } from '@react-navigation/native'
import RouterNames from '@constants/routerNames'
import { useSelector } from 'react-redux'
import { useAuth } from '@common/hooks'

const AddCollaborator = ({ navigation, route }) => {
  const { params } = route
  const { doRequest } = useNetwork()
  const { t } = useContext(LanguageContext)
  const { user, config } = useAuth()
  const { countries, allCountryData } = useContext(CommonContext)
  const { isConnected } = useSelector((state) => state.connectionReducer)

  const { companySelected } = config

  const [countriesData, setCountriesData] = useState([])
  const [userTypeOptions, setUserTypeOptions] = useState([])
  const {
    isAdviser,
    callbackAddCollaborator,
    firstUser,
    identifierCompany,
    country,
    nextButton,
    isNewCompany = false,
  } = params

  const [formikInitialValues] = useState(
    getFormikInitialValues({ identifier: identifierCompany, country })
  )

  useEffect(() => {
    if (!isConnected) {
      navigation.popToTop()
    }
  }, [isConnected])
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: getFormikValidationSchema(
      t,
      countries,
      isAdviser,
      firstUser?.email,
      identifierCompany
    ),
    onSubmit: (values) => {
      if (callbackAddCollaborator) {
        callbackAddCollaborator({ valuesUser: values, params, setSubmitting })
        return
      }
      addCollaboratorCompany(values)
    },
  })

  const { setSubmitting } = formik

  useEffect(() => {
    if (!countries.length) {
      return
    }
    setCountriesData(
      countries.map((element) => {
        return {
          label: element.name,
          value: element._id,
        }
      })
    )
  }, [countries])

  useEffect(() => {
    if (!isAdviser) {
      const { roleSelected, isAdmin, isResponsible } = user?.config || {}
      const options =
        isAdmin || isResponsible
          ? roleSelected?.assignableCompany?.withFlagAdmin
          : roleSelected?.assignableCompany?.withoutFlagAdmin
      const result = options?.map((option) => ({
        value: option.name,
        label: option.keyLabel,
        isAdmin: option.isAdmin,
        isResponsible: option.isResponsible,
      }))
      setUserTypeOptions(result)
    }
  }, [user?.config])

  const addCollaboratorCompany = async (valuesFormik) => {
    try {
      const { companySelected } = user?.config || {}
      const data = {
        email: valuesFormik.email,
        identifier: valuesFormik.identifier,
        country: valuesFormik.country.value,
        role: valuesFormik.userType.value,
        isAdmin: valuesFormik.userType.isAdmin ?? false,
        isResponsible: valuesFormik.userType.isResponsible ?? false,
      }

      await doRequest({
        method: 'post',
        url: `companies/${companySelected._id}/collaborators`,
        version: 'v2',
        data,
      })
      navigation.dispatch((state) => {
        const nextRouter = state.routes.find(
          (item) => item.name === RouterNames.COMPANY_COLLABORATOR_LIST
        )
        return {
          ...CommonActions.setParams({ isNewCollaborator: true }),
          source: nextRouter.key,
        }
      })
      navigation.goBack()
    } catch (error) {
      setSubmitting(false)
    }
  }

  return (
    <View style={styles.container}>
      <FormikProvider value={formik}>
        <FormCollaborator
          formik={formik}
          countriesData={countriesData}
          allCountryData={allCountryData}
          userTypeOptions={userTypeOptions}
          isAdviser={isAdviser}
          isNewCompany={isNewCompany}
          nextButton={nextButton}
          companySelected={companySelected}
        />
      </FormikProvider>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: grayBackground,
    flex: 1,
  },
})

export default AddCollaborator
