import React, { useEffect, useState, useContext } from 'react'
import { Text, Modal as ModalMobile, StyleSheet, View } from 'react-native'
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field'
import { LanguageContext } from '@contextState/language'
import {
  white,
  black,
  carbon600,
  carbon300,
  carbon50,
  cherry400,
  carbon400,
} from '@styles/palette'

import { CommonIcon } from '@modules/common/components'
import { isNumber } from '@utils'
import useValidatePin from '@hooks/useValidatePin'
import ButtonDefault from '../../buttons/ButtonDefault'
import ButtonRipple from '../../buttons/ButtonRipple'
import { ResponsiveLayout } from '../../layouts/ResponsiveLayout'

const CELL_COUNT = 4
const POINTER = '•'

export const VerifyPin = ({
  isModalVisible,
  toggleModal,
  onSuccess,
  externalLoading,
}) => {
  const Modal = ModalMobile
  const { t } = useContext(LanguageContext)
  const { loading, setVerifyPin } = useValidatePin()
  const [error, setError] = useState(false)
  const [enableMask, setEnableMask] = useState(true)
  const [value, setValue] = useState('')
  const refCode = useBlurOnFulfill({ value, cellCount: CELL_COUNT })
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  })

  const handleSumbit = async () => {
    try {
      const { validate, tokenPin } = await setVerifyPin(value)
      if (!validate) {
        setValue('')
        setError(true)
      } else {
        setError(false)
        if (onSuccess) {
          onSuccess(tokenPin)
        }
      }
    } catch (error) {
      setValue('')
      setError(true)
    }
  }

  const toggleMask = () => setEnableMask((f) => !f)
  const renderCell = ({ index, symbol, isFocused }) => {
    let textChild = null

    if (symbol) {
      textChild = enableMask ? POINTER : symbol
    } else if (isFocused) {
      textChild = <Cursor />
    }

    return (
      <Text
        key={index}
        style={[
          styles.cell,
          isFocused && styles.focusCell,
          error && styles.errorCell,
        ]}
        onLayout={getCellOnLayoutHandler(index)}
      >
        {textChild}
      </Text>
    )
  }

  const onChangeText = (text) => {
    if (isNumber(text)) {
      setValue(text)
    }
  }

  useEffect(() => {
    refCode?.current?.focus()
  }, [])

  const isDisabled = () => value.length < 4

  return (
    <Modal
      animationType='slide'
      transparent={false}
      visible={isModalVisible}
      ariaHideApp={false}
    >
      <ResponsiveLayout>
        <View style={styles.root}>
          <View style={styles.main}>
            <View style={styles.pinContainer}>
              <ButtonRipple onPress={toggleModal}>
                <CommonIcon
                  name={'X'}
                  size={24}
                  color={carbon600}
                  style={styles.iconClose}
                />
              </ButtonRipple>
              <Text style={styles.title}>{t('VIEWS.VERIFY_PIN.TITLE')}</Text>
              <Text style={styles.description}>
                {t('VIEWS.VERIFY_PIN.TEXT_PIN_1')}
              </Text>
              <View style={styles.fieldRow}>
                <CodeField
                  ref={refCode}
                  {...props}
                  value={value}
                  onChangeText={onChangeText}
                  cellCount={CELL_COUNT}
                  rootStyle={styles.codeFieldRoot}
                  keyboardType='number-pad'
                  textContentType='oneTimeCode'
                  renderCell={renderCell}
                  autoFocus={true}
                />

                <View style={styles.toggleContainer}>
                  <Text style={styles.toggle} onPress={toggleMask}>
                    {enableMask ? (
                      <CommonIcon name={'EYE-OFF'} size={32} color={black} />
                    ) : (
                      <CommonIcon name={'EYE'} size={32} color={black} />
                    )}
                  </Text>
                </View>
              </View>
              <Text style={styles.textError}>
                {error ? t('VIEWS.VERIFY_PIN.PIN_ERROR') : ''}
              </Text>
              <Text style={[styles.secondDescription]}>
                {t('VIEWS.VERIFY_PIN.TEXT_PIN_2')}
              </Text>
            </View>
            <View style={styles.buttonContainer}>
              <ButtonDefault
                text={t('VIEWS.VERIFY_PIN.CONTINUE')}
                typeButton={
                  isDisabled() || loading || externalLoading
                    ? 'disabled'
                    : 'primary'
                }
                disabled={isDisabled() || loading || externalLoading}
                onPress={handleSumbit}
              />
            </View>
          </View>
        </View>
      </ResponsiveLayout>
    </Modal>
  )
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: white,
    flex: 1,
  },
  main: {
    backgroundColor: white,
    flex: 1,
    justifyContent: 'space-between',
  },
  pinContainer: {
    paddingHorizontal: 24,
    paddingBottom: 32,
  },
  iconClose: {
    marginTop: 44,
  },
  title: {
    marginTop: 40,
    fontSize: 24,
    fontWeight: '700',

    lineHeight: 28,
  },
  description: {
    fontSize: 14,
    color: carbon600,
    marginTop: 16,

    lineHeight: 20,
  },
  fieldRow: {
    marginTop: 36,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cell: {
    width: 56,
    height: 56,
    lineHeight: 56,
    fontSize: 32,
    fontWeight: '500',
    borderWidth: 1,
    borderColor: carbon300,
    borderRadius: 12,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginEnd: 8,
    backgroundColor: carbon50,
  },
  toggleContainer: {
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    marginStart: 22.5,
  },
  toggle: {
    lineHeight: 55,
    fontSize: 24,
    textAlign: 'center',
  },
  focusCell: {
    borderColor: carbon600,
  },
  buttonContainer: {
    paddingHorizontal: 16,
    paddingBottom: 32,
  },
  textButton: {
    lineHeight: 16,
    letterSpacing: 0.75,
  },
  secondDescription: {
    marginTop: 30,
    fontSize: 14,
    color: carbon400,

    lineHeight: 20,
  },
  textError: {
    marginTop: 8,
    color: cherry400,
    fontWeight: '400',
  },
  errorCell: {
    borderColor: cherry400,
  },
})
