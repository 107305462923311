import DESCRIPTION from '../../components/Description/translate/es'
import ERROR_MESSAGE from '../../components/DeleteModal/translate/es'

export default {
  TEXT_1: 'Cultivos',
  TEXT_2: 'Campaña',
  TEXT_3: 'Mapa digital',
  TEXT_4: 'Eliminar lote',
  TEXT_5: 'Sin cultivo',
  TEXT_6: 'Sin campaña',
  TO_DATE: 'a',
  COMPONENTS: {
    DESCRIPTION,
    ERROR_MESSAGE,
  },
}
