import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import { buttonDisabled, carbon300, primary500 } from '@styles/palette'
import { CommonIcon } from '@common/components'
import { ItemListInterface } from './ItemList.interface'

export const ItemList = ({
  item,
  onSelectItem,
  itemSelected,
  isMultiple,
  fieldNameRender,
  isViewReadOnly
}: ItemListInterface) => {
  const checkedSingle = itemSelected ? 'CHECK-CIRCLE-FILLED' : 'CIRCLE'
  const checkedMultiple = itemSelected
    ? 'CHECKBOX-SELECTED'
    : 'CHECKBOX-UNSELECTED'
  return (
    <View style={styles.contentItem}>
      <TouchableOpacity
        style={styles.contentIcon}
        onPress={() => onSelectItem(item)}
      >
        <CommonIcon
          name={isMultiple ? checkedMultiple : checkedSingle}
          size={24}
          color={isViewReadOnly ? buttonDisabled : primary500}
        />
      </TouchableOpacity>
      <View style={styles.contentText}>
        <Text style={styles.textFarm}>{item[fieldNameRender]}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 63,
  },
  contentIcon: {
    marginLeft: 18,
    marginRight: 34,
  },
  contentText: {
    flex: 1,
    flexDirection: 'row',
    height: 63,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: carbon300,
  },
  textFarm: {
    fontSize: 16,
    fontWeight: '500',
  },
  lotIcon: {
    width: 50,
    height: 50,
    backgroundColor: 'red',
  },
})
