import LOTS_LIST from '../../components/LotsList/translate/es'
import KMZ_ADD_BUTTON from '../../components/KmzAddButton/translate/es'
import CREATE_FARM_BUTTON from '../../components/CreateFarmButton/translate/es'
import MODAL_SUCCESS from '../../components/ModalSuccess/translate/es'

export default {
  TEXT_1:
    'El archivo que intentas subir no es un tipo de formato KMZ o se ha dañado.',
  TEXT_2:
    'No se pudo generar el campo. Por favor, volver a intentar. Si el error persiste, contacta con soporte.',
  TEXT_3: 'Volver',
  TEXT_4: 'Error en el lote',
  COMPONENTS: {
    LOTS_LIST,
    KMZ_ADD_BUTTON,
    CREATE_FARM_BUTTON,
    MODAL_SUCCESS,
  },
}
