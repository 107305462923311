import React from 'react'
import { View, StyleSheet } from 'react-native'
import InputPhone from '@modules/common/components/inputs/InputPhone'
import InputText from '@components/inputs/InputText'

export const FormSignUp = ({
  formik,
  t,
  onEndEditingEmail,
  errorEmail,
  disabledEmail,
}) => {
  const { setFieldValue, setFieldTouched, values, touched, errors } = formik
  return (
    <View>
      <InputText
        id='email'
        testID='email'
        onChangeText={(value) => setFieldValue('email', value)}
        value={values.email}
        placeholder={t('VIEWS.SIGN_UP.COMPONENTS.FORM_SIGN_UP.EMAIL')}
        label={t('VIEWS.SIGN_UP.COMPONENTS.FORM_SIGN_UP.EMAIL')}
        onBlur={() => {
          setFieldTouched('email')
          onEndEditingEmail()
        }}
        editable={!disabledEmail}
        disabled={disabledEmail}
        touched={touched.email}
        error={errors.email || errorEmail}
        containerStyle={styles.input}
      />
      <InputText
        id='firstName'
        testID='first_name'
        onChangeText={(value) => setFieldValue('firstName', value)}
        value={values.firstName}
        placeholder={t('VIEWS.SIGN_UP.COMPONENTS.FORM_SIGN_UP.FIRST_NAME')}
        label={t('VIEWS.SIGN_UP.COMPONENTS.FORM_SIGN_UP.FIRST_NAME')}
        onBlur={() => setFieldTouched('firstName')}
        touched={touched.firstName}
        error={errors.firstName}
        containerStyle={styles.input}
      />
      <InputText
        id='lastName'
        testID='last_name'
        onChangeText={(value) => setFieldValue('lastName', value)}
        value={values.lastName}
        placeholder={t('VIEWS.SIGN_UP.COMPONENTS.FORM_SIGN_UP.LAST_NAME')}
        label={t('VIEWS.SIGN_UP.COMPONENTS.FORM_SIGN_UP.LAST_NAME')}
        onBlur={() => setFieldTouched('lastName')}
        touched={touched.lastName}
        error={errors.lastName}
        containerStyle={styles.input}
      />
      <InputPhone
        id='valuePhone'
        onChangeCodeArea={(value) => {
          setFieldValue('valueCodeArea', value)
        }}
        onBlur={() => {
          setFieldTouched('valueCodeArea')
          setFieldTouched('phoneNumber')
        }}
        valuePhone={values.phoneNumber}
        label={t('VIEWS.SIGN_UP.COMPONENTS.FORM_SIGN_UP.PHONE')}
        placeholder={t('VIEWS.SIGN_UP.COMPONENTS.FORM_SIGN_UP.PHONE')}
        touched={touched.phoneNumber}
        error={errors?.valueCodeArea?.value || errors.phoneNumber}
        valueCodeArea={values.valueCodeArea}
        containerStyle={styles.input}
        onChangePhoneNumber={(value) => {
          setFieldValue('phoneNumber', value)
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    marginBottom: 12,
  },
})
