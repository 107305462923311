import EMPTY_STATE_CROP_STORY from '../../components/EmptyStateCropStory/translate/en'
import ALERT from '../../components/Alert/translate/en'
import ORDER_MENU from '../../components/OrderMenu/translate/en'
import AUTHORIZATION_CARD from '../../components/AuthorizationCard/translate/en'

export default {
  COMPONENTS: {
    EMPTY_STATE_CROP_STORY,
    ALERT,
    ORDER_MENU,
    AUTHORIZATION_CARD,
  },
}
