export const parseToValidateFlood = (values: any) => {
  const dataToSend = {
    ...values,
    isDirectAchievement: true,
    timeFloodUnderCrop: Number(values.timeFloodUnderCrop),
    unitTime: values.unitTime?.value,
    paddyWaterCultivation: values.paddyWaterCultivation?.value,
    paddyWaterPreseason: values.paddyWaterPreseason?.value,
    evidences: values.evidences?.length ? values.evidences : [],
    subTypeActivity: undefined,
  }

  return dataToSend
}
