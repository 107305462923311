export default {
  WORK_ORDER: 'Ordem de trabalho',
  SHARE: {
    TITLE: 'Ucropit',
    I_SEND_THE_FOLLOWING_WORK_ORDE: 'Eu envio a seguinte ordem de trabalho:',
    LABOR: 'Trabalho: %{labor}',
    CROP: 'Cultivo: %{crop}',
    TOTAL_PLANNED_AREA: 'Área total planejada: %{total_planned_area}',
    ESTIMATED_START: 'Início estimado: %{estimated_start}',
    FOR_MORE_DETAILS: 'Para mais detalhes, vá para %{url}',
  },
}
