export default {
  TEXT_1: 'LICENÇA',
  TEXT_2: 'Eu li e cumpro esta seção',
  TEXT_3: 'Assinar',
  TEXT_4: 'Visibilidade dos dados',
  TEXT_5:
    'A assinatura desta licença implica o compartilhamento dos dados dos cultivos a que se aplique com a empresa emissora.',
  TEXT_6: 'Rejeitar',
  TEXT_7: 'Aceito',
}
