import activityTypes from '@constants/activityTypes'

const activityIncludes: string[] = [
  activityTypes.ACT_HARVEST.key,
  activityTypes.ACT_MONITORING.key,
  activityTypes.ACT_AGREEMENTS.key,
]
export const isDirectAchievementValidate = (tag: string) => {
  return activityIncludes.includes(tag)
}
