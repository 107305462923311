export default {
  COUNTRY: {
    PLACEHOLDER: 'País',
    LABEL: 'País',
  },
  EMAIL: {
    PLACEHOLDER: 'Email',
    LABEL: 'Email',
  },
  CONFIRM_EMAIL: {
    PLACEHOLDER: 'Confirmar email',
    LABEL: 'Confirmar email',
  },
  COLLABORATOR_TYPE: {
    PLACEHOLDER: 'Tipo de colaborador',
    LABEL: 'Tipo de colaborador',
  },
  SAVE: 'Guardar',
  NEXT: 'Siguiente',
}
