import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import activityTypes from '@constants/activityTypes'

export const SaveDraftButton = ({
  tag,
  isSaveDraftButtonAvailable,
  isSaveDraftButtonLoading,
  onPressSaveDraftButton,
  isSaveDraftButtonVisible = true,
}) => {
  if (!isSaveDraftButtonVisible) {
    return false
  }

  const { t } = useContext(LanguageContext)

  return (
    <ButtonCustom
      disabled={!isSaveDraftButtonAvailable}
      isLoading={isSaveDraftButtonLoading}
      onPress={onPressSaveDraftButton}
      styles={[
        styles.button,
        !isSaveDraftButtonAvailable ? styles.buttonDisabled : {},
      ]}
      labelStyle={[
        styles.textButton,
        !isSaveDraftButtonAvailable ? styles.textButtonDisabled : {},
      ]}
    >
      {tag !== activityTypes.ACT_VERIFICATION.key
        ? t('VIEWS').ACTIVITY_CREATE.TEXT_1
        : t('VIEWS').ACTIVITY_CREATE.TEXT_9}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#f2f2f2',
    borderColor: '#4CAF50',
    borderWidth: 1,
    marginTop: 5,
  },
  buttonDisabled: {
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  textButton: {
    color: '#4CAF50',
  },
  textButtonDisabled: {
    color: 'rgba(0, 0, 0, 0.12)',
  },
})

SaveDraftButton.propTypes = {
  isSaveDraftButtonAvailable: PropTypes.bool.isRequired,
  isSaveDraftButtonLoading: PropTypes.bool.isRequired,
  onPressSaveDraftButton: PropTypes.func.isRequired,
  isSaveDraftButtonVisible: PropTypes.bool,
}
