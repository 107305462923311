export default {
  PRODUCER_ADVISER: 'Asesor',
  PRODUCER_ADVISER_ENCOURAGED: 'Asesor Promotor',
  PRODUCER: 'Productor',
  PROVIDER: 'Proveedor',
  MARKETER: 'Comercializador',
  CONTACT_REPRESENTATIVE: 'Responsable Comercial',
  CAM: 'KAM',
  KAM: 'KAM',
  VERIFIER: 'Verificador',
}
