export default {
  TEXT_1: '¿Deseja sair de UCROP.IT?',
  TEXT_2: 'Esta ação deletará a fila de sincronização',
  TEXT_3: 'Sem empresa atribuída',
  TEXT_4: 'Cadastro de empresa',
  TEXT_5: 'EDITAR PERFIL',
  TEXT_6: 'Empresa',
  TEXT_7: 'Integrações',
  TEXT_8: 'Definições',
  TEXT_9: 'Finalizar a sessão',
  TEXT_10: 'Versão %{version} Build %{build}',
  TEXT_11: 'Perfil',
  TEXT_12: 'Idioma',
  TEXT_13: 'Dados da empresa',
  TEXT_14: 'Sincronize offline',
  TEXT_15: 'Conectar aplicativo',
  TEXT_16: 'Consultoria',
  SETTINGS: 'Contexto',
  PRODUCTION_BOARD: 'Quadro de produção',
  ADD_COMPANY: 'Adicionar empresa',
  YOUR_COMPANY: 'SEU COMPANHIA',
  ADVISED_COMPANY: 'EMPRESA ACONSELHADA',
  INTEGRATIONS: 'Integrações',
  TAX_ID: 'TAX ID',
  TRADERS: 'Comerciantes',
  QR: 'Rastreabilidade',
  QR_TRADER_ADMIN: 'Produto rastreado',
  POLYGONS: 'Polígonos',
  INFORMATION: 'Informação',
  BULK_UPLOAD: 'Uso da terra',
  PRODUCTIVE_UNITS: 'Unidades produtivas',
  CROP_STORY: 'Histórico da cultura',
  PROGRAM_TRADER_ADMIN: 'Programas',
  EXPORT_ACTIVITIES: 'Atividades de exportação',
  SYNCHRONIZE_ACTIVITIES: 'Sincronizar atividades',
}
