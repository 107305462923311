import React, { useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import { primary500, white, black, carbon400, carbon600 } from '@styles/palette'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { LanguageContext } from '@contextState/language'

const WizardPin = ({ handleStep }) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <View />
      <View style={styles.content}>
        <CommonIcon name={'KEY'} size={48} color={black} />
        <Text style={styles.title}>
          {t(
            'VIEWS.CREATE_PIN_SCREEN.COMPONENTS.WIZARD_PIN.CREATE_SECURITY_PIN'
          )}
        </Text>
        <Text style={styles.paragraph}>
          {t('VIEWS.CREATE_PIN_SCREEN.COMPONENTS.WIZARD_PIN.PARAGRAPH_1')}
        </Text>
        <Text style={styles.paragraph2}>
          {t('VIEWS.CREATE_PIN_SCREEN.COMPONENTS.WIZARD_PIN.PARAGRAPH_2')}
        </Text>
      </View>

      <ButtonPrimary
        text={t(
          'VIEWS.CREATE_PIN_SCREEN.COMPONENTS.WIZARD_PIN.CREATE_SECURITY_PIN_BUTTON'
        )}
        backgroundColor={primary500}
        colorText={white}
        onPress={handleStep}
        fontSize={14}
        customTextStyle={styles.textButton}
        fontWeight={'700'}
        marginBottom={48}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  content: {
    marginTop: 32,
  },
  title: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 44,
    marginTop: 46,
  },
  paragraph: {
    marginTop: 24,
    color: carbon600,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 26,
  },
  paragraph2: {
    marginTop: 24,
    color: carbon400,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 26,
  },
  textButton: {
    lineHeight: 16,
    letterSpacing: 0.75,
  },
})

export default WizardPin
