import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { InputSelectDefault } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import InputText from '@components/inputs/InputText'

export const Form = ({
  formik: { setFieldValue, values, setFieldTouched, touched, errors },
  createCollaboratorConfig: {
    isCountryDisabled,
    isIdentifierDisabled,
    isEmailDisabled,
    isUserTypeDisabled,
  },
  countryOptions,
  userTypeOptions,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <InputSelectDefault
        id='country'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('country', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('country'))}
        value={values.country}
        placeholder={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_1.PLACEHOLDER')}`}
        label={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_1.LABEL')}`}
        options={countryOptions}
        disabled={isCountryDisabled}
        touched={touched.country}
        error={errors.country?.value}
      />

      <InputText
        id='identifier'
        containerStyle={styles.containerInput}
        onChangeText={(value) => setFieldValue('identifier', value)}
        value={values.identifier}
        placeholder={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_2.PLACEHOLDER')}`}
        label={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_2.LABEL')}`}
        editable={!isIdentifierDisabled}
        disabled={isIdentifierDisabled}
        onBlur={() => setFieldTouched('identifier')}
        touched={touched.identifier}
        error={errors.identifier}
      />

      <InputText
        id='email'
        containerStyle={styles.containerInput}
        onChangeText={(value) => setFieldValue('email', value)}
        value={values.email}
        placeholder={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_3.PLACEHOLDER')}`}
        label={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_3.LABEL')}`}
        editable={!isEmailDisabled}
        disabled={isEmailDisabled}
        onBlur={() => setFieldTouched('email')}
        touched={touched.email}
        error={errors.email}
      />

      <InputSelectDefault
        id='userType'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('userType', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('userType'))}
        value={values.userType}
        placeholder={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_4.PLACEHOLDER')}`}
        label={`${t('VIEWS.COLLABORATOR_CREATE.FIELD_4.LABEL')}`}
        options={userTypeOptions}
        disabled={isUserTypeDisabled}
        touched={touched.userType}
        error={errors.userType?.value}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 24,
  },
  containerInput: {
    marginVertical: 1,
  },
})

Form.propTypes = {
  formik: PropTypes.object.isRequired,
  createCollaboratorConfig: PropTypes.object.isRequired,
  countryOptions: PropTypes.array.isRequired,
  userTypeOptions: PropTypes.array.isRequired,
}
