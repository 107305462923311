import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Platform, Linking } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'

import { collaboratorsActions, draftCropActions } from '@store/actions'
import userTypes from '@constants/userTypes'
import links from '@constants/links'
import useNetwork from '@utils/network'
import Screen from './screen'
import { selectDraftCrop } from '@store/selectors/draftCrops'
import { useAuth } from '@common/hooks'

const CropSelectResponsible = ({ navigation, route }) => {
  const { config } = useAuth()
  const draftCrop = useSelector((state) => state.draftCrop)
  const { companyId } = draftCrop
  const valuesForm = useSelector(
    selectDraftCrop(config.companySelected?.identifier)
  )

  const { isConnected } = useSelector((state) => state.connectionReducer)

  const [isViewReady, setIsViewReady] = useState(false)
  const [collaborators, setCollaborators] = useState([])
  const [isSubmitButtonAvailable, setIsSubmitButtonAvailable] = useState(false)

  const dispatch = useDispatch()
  const { doRequest } = useNetwork()

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    fetchInitData()

    return () => true
  }, [])

  /**
   * ENABLE/DISABLE VALIDATE BUTTON IF FORM IS VALID
   */
  useEffect(() => {
    if (collaborators.find((element) => element.selected)) {
      setIsSubmitButtonAvailable(true)
    } else {
      setIsSubmitButtonAvailable(false)
    }
  }, [collaborators])

  /**
   * FETCH INIT DATA
   */
  const fetchInitData = useCallback(async () => {
    setIsViewReady(false)

    await Promise.all([fetchCollaborators()])

    setIsViewReady(true)
  }, [])

  /**
   * FETCH COLLABORATORS OF COMPANY
   */
  const fetchCollaborators = useCallback(async () => {
    let collaborators

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `companies/${companyId}/collaborators`,
      })

      collaborators = response.data
    }

    setCollaborators(collaborators)

    return collaborators
  }, [isConnected])

  /**
   * ON PRESS SHOW COLLABORATOR CONTACT
   */
  const onPressShowCollaboratorContact = async ({
    _id: collaboratorSelectedId,
  }) => {
    setCollaborators(
      collaborators.map((element) => ({
        ...element,
        displayContact:
          element._id === collaboratorSelectedId
            ? !element.displayContact
            : false,
      }))
    )
  }

  /**
   * ON PRESS SELECT RESPONSIBLE
   */
  const onPressSelectResponsible = async ({ _id: collaboratorSelectedId }) => {
    setCollaborators(
      collaborators.map((element) => ({
        ...element,
        selected:
          element._id === collaboratorSelectedId ? !element.selected : false,
      }))
    )
  }

  /**
   * ON PRESS CALL CONTACT
   */
  const onPressCallContact = ({ phone }) => {
    const url = `tel:${phone}`

    Linking.canOpenURL(url).then(() => {
      return Linking.openURL(url).catch(() => null)
    })
  }

  /**
   * ON PRESS SEND WHATSAPP MESSAGE
   */
  const onPressSendWhatsappMessage = ({ phone }) => {
    if (Platform.OS !== 'web') {
      Linking.openURL(`${links.whatsappLink}${phone}&phone=${phone}`)
    } else {
      window.open(links.whatsappWebLink + phone)
    }
  }

  /**
   * ON PRESS SUBMIT DATA
   */
  const onPressSubmitButton = async () => {
    const collaboratorSelected = collaborators.find(
      (element) => element.selected
    )

    // ADD DATA TO REDUCER STATE

    dispatch(
      collaboratorsActions.setCreateCollaboratorConfig({
        isCountryDisabled: true,
        isIdentifierDisabled: true,
        isEmailDisabled: true,
        userTypesAllowed: [
          userTypes.PRODUCER.key,
          userTypes.PRODUCER_ADVISER.key,
        ],
        defaultCountry: valuesForm.country,
        defaultIdentifier: valuesForm.identifier,
        defaultEmail: collaboratorSelected.email,
        callback: (data) => {
          const collaborators = [
            {
              identifier: data.identifier,
              type: data.userType,
              email: data.email,
              owner: true,
            },
          ]
          dispatch(draftCropActions.setDraftCollaborators(collaborators))
          navigation.navigate('LotsAdd', {
            identifier: route.params.identifier,
          })
        },
      })
    )

    navigation.navigate('CollaboratorCreate')
  }

  return (
    <Screen
      isViewReady={isViewReady}
      collaborators={collaborators}
      onPressShowCollaboratorContact={onPressShowCollaboratorContact}
      onPressSelectResponsible={onPressSelectResponsible}
      onPressCallContact={onPressCallContact}
      onPressSendWhatsappMessage={onPressSendWhatsappMessage}
      isSubmitButtonAvailable={isSubmitButtonAvailable}
      onPressSubmitButton={onPressSubmitButton}
    />
  )
}

CropSelectResponsible.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default CropSelectResponsible
