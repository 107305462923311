import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'
import moment from 'moment'

import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import Layout from '@components/common/Layout'
import InputDate from '@components/inputs/InputDate'
import InputText from '@components/inputs/InputText'
import Outlier from '@modules/common/components/Outlier'
import { outlierIcons } from '@constants/outlierData'
import { useSearchCropTypes } from '@modules/crops/hooks/useSearchCroptypes'
import { EPayUnitType } from '@modules/common/enum'
import { useAuth } from '@common/hooks'
import { useSelector } from 'react-redux'

export const CropForm = ({
  currentUserIsAdminOfCompany,
  values,
  setFieldValue,
  isCompanyUcropit,
  touched,
  errors,
  setFieldTouched,
  valuesForm = {},
  formIsValid = false,
  canShowOutlier = false,
  isValueOutsideMean = false,
  statusOutlier = 'WARNING',
  onChangePayHarvest,
  onChangeUnitType,
  openBackdropStandardDeviation,
}) => {
  const { t } = useContext(LanguageContext)
  const { config } = useAuth()
  const { stages, countries } = useContext(CommonContext)
  const { unitTypes } = useSelector((state) => state.unitTypesReducer)
  const { cropTypes } = useSelector((state) => state.cropTypesReducer)
  const [unitTypeList, setUnitTypeList] = useState([])
  const [country, setCountry] = useState(null)
  const { cropTypesArr, setSearchTxt } = useSearchCropTypes(cropTypes)

  useEffect(() => {
    let country
    if (isCompanyUcropit && values?.country?.value) {
      country = countries.find(
        (country) => country._id === values?.country?.value
      )
    } else if (!isCompanyUcropit) {
      country = config?.companySelected
    }
    setCountry(country)
  }, [values.country.value])

  useEffect(() => {
    if (country?.unitMeasureSystem) {
      const filterUnitTypes = unitTypes.filter(
        (unitType) =>
          unitType.unitMeasureSystem === country?.unitMeasureSystem &&
          Object.values(EPayUnitType).includes(unitType.key)
      )

      setUnitTypeList(filterUnitTypes)

      if (
        country?.unitMeasureSystem &&
        valuesForm?.unitType?.unitMeasureSystem !== country?.unitMeasureSystem
      ) {
        setFieldValue('unitType', { value: '', label: '' })
      }
    }
  }, [country?.unitMeasureSystem])

  const getOptions = () => {
    return stages.map((el) => ({
      label: t(`VIEWS.CROP_CREATE.STAGE_FIELD.${el.label}`),
      value: el.value,
    }))
  }

  return (
    <Layout>
      <InputSelectDefault
        id='cropType'
        containerStyle={styles.containerInput}
        onBlur={() => {
          setTimeout(() => setFieldTouched('cropType'))
          setSearchTxt('')
        }}
        onChangeText={(value) => {
          setFieldValue('cropType', value)
        }}
        value={values.cropType}
        label={t('VIEWS').CROP_CREATE.FIELD_1.NAME}
        placeholder={t('VIEWS').CROP_CREATE.FIELD_1.NAME}
        options={cropTypesArr}
        touched={touched.cropType}
        error={errors?.cropType?.value}
        search
        onChangeSearchInputText={(txtSearch) => {
          setSearchTxt(txtSearch)
        }}
        placeholderSearch={t('VIEWS.CROP_CREATE.SEARCH_CROP_TYPE')}
      />
      <InputSelectDefault
        id='stage'
        containerStyle={styles.containerInput}
        onBlur={() => setTimeout(() => setFieldTouched('stage'))}
        onChangeText={(value) => {
          setFieldValue('stage', value)
        }}
        value={values.stage}
        label={t('VIEWS').CROP_CREATE.STAGE_FIELD.NAME}
        placeholder={t('VIEWS').CROP_CREATE.STAGE_FIELD.NAME}
        options={getOptions()}
      />

      <View style={styles.inRow}>
        <View style={styles.styleDateRow}>
          <InputDate
            id='dateCrop'
            onChangeText={(value) => {
              setTimeout(() => setFieldValue('dateCrop', value))

              if (
                values.dateHarvest.value &&
                moment(values.dateHarvest.value).isSameOrBefore(value.value)
              ) {
                setTimeout(() =>
                  setFieldValue('dateHarvest', {
                    value: '',
                    label: '',
                  })
                )
              }
            }}
            value={values.dateCrop}
            label={t('VIEWS').CROP_CREATE.FIELD_2.NAME}
            placeholder={t('VIEWS').CROP_CREATE.FIELD_2.NAME}
            disablePast={false}
            touched={touched.dateCrop}
            error={errors?.dateCrop?.value}
            onBlur={() => setTimeout(() => setFieldTouched('dateCrop'))}
          />
        </View>

        <View style={[styles.styleDateRow, styles.rightSpace]}>
          <InputDate
            id='dateHarvest'
            onChangeText={(value) => {
              setTimeout(() => setFieldValue('dateHarvest', value))
            }}
            value={values.dateHarvest}
            label={t('VIEWS').CROP_CREATE.FIELD_3.NAME}
            placeholder={t('VIEWS').CROP_CREATE.FIELD_3.NAME}
            disablePast={false}
            minDate={
              values.dateCrop.value
                ? moment(values.dateCrop.value).add(1, 'd').toDate()
                : undefined
            }
            touched={touched.dateHarvest}
            error={errors?.dateHarvest?.value}
            onBlur={() => setTimeout(() => setFieldTouched('dateHarvest'))}
          />
        </View>
      </View>

      <View style={styles.inRow}>
        <View style={styles.styleDateRow}>
          <InputSelectDefault
            id='unitType'
            rightIcon={<CommonIcon name={'CHEVRON-DOWN'} size={20} />}
            onChangeText={(value) => {
              setFieldValue('unitType', value)
              onChangeUnitType(value)
            }}
            onBlur={() => setTimeout(() => setFieldTouched('unitType'))}
            value={values.unitType}
            label={t('VIEWS').CROP_CREATE.FIELD_4.NAME}
            placeholder={t('VIEWS').CROP_CREATE.FIELD_4.NAME}
            options={unitTypeList}
            touched={touched.unitType}
            error={errors?.unitType?.value}
            disabled={!values?.country?.value}
          />
        </View>

        <View style={[styles.styleDateRow, styles.rightSpace]}>
          <InputText
            id='pay'
            value={values.pay}
            label={t('VIEWS').CROP_CREATE.FIELD_5.NAME}
            placeholder={t('VIEWS').CROP_CREATE.FIELD_5.NAME}
            touched={touched.pay}
            error={errors.pay}
            keyboardType='decimal-pad'
            onChangeText={(value) => {
              setFieldValue('pay', value)
              onChangePayHarvest(value)
            }}
            onBlur={() => setFieldTouched('pay')}
          />
        </View>
      </View>
      {canShowOutlier && isValueOutsideMean && (
        <Outlier
          title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
          icon={outlierIcons.InfoFill}
          description={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_DESCRIPTION}
          type={statusOutlier}
          style={styles.outlier}
          onPress={openBackdropStandardDeviation}
        />
      )}

      <InputText
        id='name'
        value={values.name}
        label={t('VIEWS').CROP_CREATE.FIELD_7.NAME}
        placeholder={t('VIEWS').CROP_CREATE.FIELD_7.NAME}
        touched={touched.name}
        error={errors.name}
        disabled
        editable={false}
        helpText={
          formIsValid && currentUserIsAdminOfCompany
            ? t('VIEWS.CROP_CREATE.TEXT_9')
            : undefined
        }
      />
    </Layout>
  )
}

const styles = StyleSheet.create({
  inRow: {
    flexDirection: 'row',
  },
  leftSpace: {
    marginRight: 4,
    flex: 1,
    width: '45%',
  },
  rightSpace: {
    marginLeft: 4,
    flex: 1,
  },
  styleDateRow: {
    flexBasis: '49%',
  },
  containerInput: {
    marginTop: 7,
  },
  outlier: { marginTop: -15, marginBottom: 15 },
})

CropForm.propTypes = {
  currentUserIsAdminOfCompany: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isCompanyUcropit: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  valuesForm: PropTypes.object,
  formIsValid: PropTypes.bool,
  canShowOutlier: PropTypes.bool,
  isValueOutsideMean: PropTypes.bool,
  statusOutlier: PropTypes.string,
  onChangePayHarvest: PropTypes.func.isRequired,
  onChangeUnitType: PropTypes.func.isRequired,
}
