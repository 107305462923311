import HEADER from '../../components/Header/translate/en'
import APP_INTEGRATOR_DETAILS from '../../screens/AppIntegratorDetails/translate/en'

export default {
  COMPONENTS: {
    HEADER,
    APP_INTEGRATOR_DETAILS,
  },
  CONNECTED_APPLICATIONS: 'Connected applications',
  OTHER_APPLICATIONS: 'Other applications',
  SYNCHRONIZATION_DESCRIPTION:
    'Processing time may vary depending on the amount of data in your account.',
  LAST_UPDATE: 'Last update',
  CONNECTED_TITLE: 'Connected',
  OBTAINING_TITLE: 'Obtaining information',
  INFORMATION_OBTAINED_TITLE: 'Information obtained',
  VALIDATING: 'Validation pending',
  PENDING_VALIDATION: 'Validation pending',
  PENDING_VALIDATION_DESCRIPTION:
    'To conclude the synchronization process, you must verify and validate the data obtained.',
}
