export default {
  REQUIRED: 'O campo é obrigatório',
  INVALID_FORMAT: 'O formato do campo está errado',
  MIN_QUANTITY: 'Selecione pelo menos %{quantity}',
  MAX_QUANTITY: 'Seleção máxima de %{quantity}',
  IS_NUMBER: 'O campo deve ser numérico',
  IS_POSITIVE: 'O campo deve ser maior que 0',
  MIN_CHARACTERS: 'Ingrese al menos %{quantity} caracteres',
  MAX_CHARACTERS: 'Ingrese máximo %{quantity} caracteres',
  LEN_CHARACTERS: 'Deve ter exatamente %{quantity} caracteres',
  MUST_MATCH: 'Os emails e devem corresponder',
  EMAIL_EXIST: 'Este email existe',
  THIS_VALUE_IS:
    'Não é possível acrescentar este TAX ID a este tipo de colaborador, por favor, insira um novo.',
  NUMBER_INVALID: 'O número inserido não é válido.',
  INVALID_CHARACTERS: 'Caracteres inválidos inseridos',
  NOT_SPECIAL_CHARACTERS: 'Fiscal ID inserido tem caracteres inválidos',
  LESS_THAN: 'O campo deve ser inferior que',
}
