import { FC, ReactElement, useContext } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { LanguageContext } from '@contextState/language'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'
import CropList from '@modules/crops/screens/CropList/v1'
import CropStoryDetail from '@modules/cropStory/screens/CropStoryDetail/v2'
import CropCreate from '@modules/crops/screens/CropCreate/v1'
import CropSelectResponsible from 'src/screens/v1/cropSelectResponsible'
import CollaboratorCreate from 'src/screens/v1/collaboratorCreate'
import LotsAddScreen from '../../../screens/v1/LotsAdd'
import CropCreateConfirm from '@modules/crops/screens/CropCreateConfirm/v1'
import CropCollaboratorsListScreen from '../../../screens/v1/CropCollaboratorsListScreen'
import CropCollaboratorsAddScreen from '../../../screens/CropCollaboratorsAddScreen'
import CropFarmFieldDetails from '@modules/crops/screens/CropFarmFieldDetails/v1'
import FiltersScreen from '../../../screens/FiltersScreen'
import { getActivityStackScreens } from '../activity'
import CropFarmList from '@modules/crops/screens/CropFarmList/v1'
import CollaboratorsDelegateSignaturesList from 'src/screens/v1/CollaboratorsDelegateSignaturesList'
import UnlinkCollaborator from 'src/screens/v1/UnlinkCollaborator'
import FarmLotEditName from '@modules/farms/screens/FarmLotEditName/v1'
import AddCompanyScreen from '@modules/companies/screens/AddCompanyScreen/v1'
import CollaboratorsListNewCompany from '@modules/collaborators/screens/CollaboratorsListNewCompany/v1'

const Stack = createStackNavigator()

export const CropStack: FC<any> = (props): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const cropStacks = [
    {
      name: RouterNames.CROP_LIST,
      component: CropList,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_5'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_5')}
            drawer={Platform.OS !== 'web'}
            showFilters
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_DETAIL,
      component: CropStoryDetail,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_7'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_7')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_FIELD_STORY,
      component: CropStoryDetail,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.FIELD_STORY'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.FIELD_STORY')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_CREATE,
      component: CropCreate,
      options: {
        title: t('VIEWS.CROP_CREATE.TEXT_1'),
        header: (props: any) => (
          <Header {...props} title={t('VIEWS.CROP_CREATE.TEXT_1')} goBack />
        ),
      },
    },
    {
      name: RouterNames.CROP_SELECT_RESPONSIBLE,
      component: CropSelectResponsible,
      options: {
        title: t('VIEWS.CROP_SELECT_RESPONSIBLE.TEXT_1'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.CROP_SELECT_RESPONSIBLE.TEXT_1')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_COLLABORATOR_CREATE,
      component: CollaboratorCreate,
      options: {
        title: t('VIEWS.COLLABORATOR_CREATE.TEXT_1'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.COLLABORATOR_CREATE.TEXT_1')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_LOTS_ADD,
      component: LotsAddScreen,
      options: {
        title: t('VIEWS.LOTS_ADD.ADD_FIELDS_TO_CROP'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.LOTS_ADD.ADD_FIELDS_TO_CROP')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_CREATE_CONFIRM,
      component: CropCreateConfirm,
      options: {
        title: t('VIEWS.CROP_CREATE_CONFIRM.TEXT_1'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.CROP_CREATE_CONFIRM.TEXT_1')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_COLLABORATOR_LIST,
      component: CropCollaboratorsListScreen,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_10'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_10')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_COLLABORATOR_ADD,
      component: CropCollaboratorsAddScreen,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_11'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_11')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_COLLABORATOR_DELEGATE_SIGNATURES,
      component: CollaboratorsDelegateSignaturesList,
      options: {
        title: t('VIEWS.CROP_COLLABORATOR.TEXT_18'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.CROP_COLLABORATOR.TEXT_18')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_COLLABORATOR_UNLINK,
      component: UnlinkCollaborator,
      options: {
        title: t('VIEWS.CROP_COLLABORATOR.TEXT_19'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.CROP_COLLABORATOR.TEXT_19')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_FARM_LIST,
      component: CropFarmList,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_63'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_63')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_FARM_FIELD_DETAIL,
      component: CropFarmFieldDetails,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_64'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_64')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_FARM_FIELD_EDIT,
      component: FarmLotEditName,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_60'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_60')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.CROP_FILTER,
      component: FiltersScreen,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_6'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_6')}
            close
            showResetFilters
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_ADD,
      component: AddCompanyScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_9'),
        header: (props: any) => {
          const { isAdviser } =
            props?.route?.params ?? props.scene?.route?.params

          const title = isAdviser
            ? t('NAVIGATION.STACK_NAVIGATOR.ADVISE_COMPANY')
            : t('NAVIGATION.STACK_NAVIGATOR.MANAGE_COMPANY')

          return <Header {...props} title={title} goBack />
        },
      },
    },
  ]

  const activityStackScreens = getActivityStackScreens()

  const stacks = [...cropStacks, ...activityStackScreens]

  const initialRouteName =
    props.route.params.initialRouteName || RouterNames.CROP_LIST

  return (
    <Stack.Navigator initialRouteName={initialRouteName}>
      {stacks.map((stack: any, key) => (
        <Stack.Screen {...stack} key={`CROPS_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
