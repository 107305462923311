export default {
  TEXT_1: 'Semeadura',
  TEXT_2: 'Colheita',
  TEXT_3: 'Labrança',
  TEXT_4: 'Fertilização',
  TEXT_5: 'Aplicações',
  TEXT_6: 'Monitoramento',
  TEXT_7: 'Declaração',
  TEXT_8: 'Rendimento esperado',
  TEXT_9: 'Data est. de colheita',
  TEXT_10: 'Realização',
  TEXT_11: 'Validações',
  TEXT_12: 'Superfície',
  TEXT_13: 'ha',
  TEXT_14: 'Sup',
  TEXT_15: 'Insumos',
  TEXT_16: 'Colaboradores',
  TEXT_17: 'Em Rascunho',
  TEXT_18: 'Planejado para realizar',
  TEXT_19: 'EIQ Planejado',
  TEXT_20: 'Realizações com validação pendente',
  TEXT_21: 'Observação',
  TEXT_22: 'Validação Pendente',
  TEXT_23: 'Finalizadas',
  TEXT_24: 'EIQ',
  TEXT_25: 'Atividades',
  TEXT_26: 'Rascunho',
  TEXT_27: 'Version',
  TEXT_28: '%{lotsQuantity} lotes - %{surface} has',
  TEXT_29: '%{lotsQuantity} lote - %{surface} has',
  TEXT_30: 'Rendimentos esperados: %{pay} %{unitType}',
  TEXT_31: 'Rendimento: %{pay} %{unitType}',
  TEXT_32: 'Data Est. Colheita: %{dateEstimatedHarvest}',
  TEXT_33: 'Destino: %{storage}',
  TEXT_34: 'A licença %{licenseName} ainda não tem pacotes associados a ela!',
  TEXT_35:
    'Existem várias licenças ainda sem lotes associados! Solicitamos os seus rendimentos para o funcionamento dos mesmos.',
  TEXT_36:
    'A licença %{licenseName} ainda não tem pacotes associados e empresas selecionadas!',
  TEXT_37:
    'Existem várias licenças ainda sem lotes e empresas associadas! Solicitamos os seus rendimentos para o funcionamento dos mesmos.',
  TEXT_38: 'ACESSE A LICENÇA',
  TEXT_39: 'IR PARA LICENÇAS',
  MODAL_VALIDATE: {
    TEXT_1: 'Actividad verificador',
    TEXT_2:
      'Hay una o más actividades de verificación en borrador. No se puede crear una nueva hasta no finalizar al menos una.',
    TEXT_3: 'OK',
  },
  MODAL_NO_LOGER_LOTS: {
    TEXT_1: 'Actividad verificador',
    TEXT_2: 'No hay lotes disponibles para generar una nueva verificación.',
    TEXT_3: 'OK',
  },
  TEXT_40: 'Não compatível',
  TEXT_41: 'Verificado',
  TEXT_42: 'Sob verificação',
  TEXT_43: 'Verificação',
  TEXT_44: 'Tipo de %{activityType}: %{subTypeActivity}',
  TEXT_45: 'EIQ realizado',
  TEXT_46: 'PENDIENTE',
  TEXT_47: '%{value} PENDIENTES',
  TEXT_48: '%{establishmentsQuantity} Fazendas',
  TEXT_49: '%{establishmentsQuantity} Fazenda',
  TEXT_50: '%{lotsQuantity} parcelas',
  TEXT_51: '%{lotsQuantity} parcela',
  TEXT_52: 'FAZENDA',
  TEXT_53: 'PARCELAS',
  TEXT_54: 'COMEÇAR',
  TEXT_55: 'VER DETALHE',
  TEXT_56: 'EDITAR',
  TEXT_57: 'Data e hora do rascunho:',
  TEXT_58: 'COMPLETAR',
  TEXT_59: 'REALIZAÇÃO',
  TEXT_60: 'Rascunhos',
  TEXT_61: 'EM REVISÃO',
  TEXT_62: 'a',
  NO_ACTIVITIES: 'Você ainda não tem atividades',
  ADD_ACTIVITIES:
    'Adicionar uma nova atividade para registrar as tarefas a serem realizadas ou concluídas no campo.',
  ABOUT_ACTIVITIES: 'Saiba mais sobre as atividades',
  IRRIGATION: 'Irrigação',
}
