import React, { useContext } from 'react'
import { Linking, StyleSheet, Text, View, Platform } from 'react-native'
import { Card } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { formattedNumber } from '@utils/phone'
import { LanguageContext } from '@contextState/language'
import { phoneNumberValidator } from '@utils/validation'
import Links from '@constants/links'
import { CommonIcon } from '@modules/common/components'

function CardContactActions({
  item,
  country,
  stylesContact,
  phone: userPhone,
}) {
  const { t } = useContext(LanguageContext)
  const phone =
    userPhone || item?.user?.phone ? item?.user?.phone : item?.userId?.phone

  function msgWhatsApp() {
    if (phoneNumberValidator(phone)) {
      alert(t('COMPONENTS').COMMON.CARD_CONTACT_ACTIONS.TEXT_3)
    } else {
      if (Platform.OS !== 'web') {
        Linking.openURL(
          Links.whatsappLink +
            phone +
            '&phone=' +
            formattedNumber(phone, country)
        )
      } else {
        window.open(
          Links.whatsappWebLink +
            formattedNumber(phone, country).replace(' ', '')
        )
      }
    }
  }

  function callTheContact() {
    if (phoneNumberValidator(phone)) {
      alert(t('COMPONENTS').COMMON.CARD_CONTACT_ACTIONS.TEXT_3)
    } else {
      const url = `tel:${formattedNumber(phone, country)}`

      Linking.canOpenURL(url).then(() => {
        return Linking.openURL(url).catch(() => null)
      })
    }
  }

  if (!item) return null

  return (
    <>
      <Card.Actions style={stylesContact ?? styles.actions}>
        <View style={styles.actionContainer}>
          <CommonIcon
            name='PHONE'
            size={20}
            color='#606060'
            onPress={callTheContact}
            style={styles.icon}
          />

          <Text style={styles.textButton}>
            {t('COMPONENTS.COMMON.CARD_CONTACT_ACTIONS.TEXT_1')}
          </Text>
        </View>

        <View style={styles.actionContainer}>
          <CommonIcon
            name='WHATSAPP'
            size={20}
            color='#606060'
            onPress={msgWhatsApp}
            style={styles.icon}
          />

          <Text style={styles.textButton}>
            {t('COMPONENTS.COMMON.CARD_CONTACT_ACTIONS.TEXT_2')}
          </Text>
        </View>
      </Card.Actions>
    </>
  )
}

const styles = StyleSheet.create({
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginLeft: 8,
  },
  textButton: {
    fontSize: 12,
    textTransform: 'capitalize',
    lineHeight: 16,
    alignItems: 'center',
    letterSpacing: 0.4,
    marginLeft: 8,
  },
  actions: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

CardContactActions.propTypes = {
  item: PropTypes.object,
  props: PropTypes.object,
  country: PropTypes.string,
}

export default CardContactActions
