import { useContext, useMemo, useRef } from 'react'
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Image,
  Platform,
} from 'react-native'
import { PropTypes } from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { SUPPLY } from '@constants/iconGuide'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { Layout, ResponsiveLayout } from '@modules/common/components'
import { useStandardDeviation, useFetchOutliers } from '@common/hooks'
import { ChemicalCompositions } from '@modules/activities/screens/SupplyLoader/v1/components'
import {
  mapData,
  mapperChemicalCompositions,
} from '@modules/activities/screens/SupplyLoader/v1/utils'
import { keysSupplies } from '@constants/index'
import { EActivityTypes } from '@common/enum'
import { SIZES } from '@common/components/icons/interfaces'
import { IconBox } from '@modules/supplies/components'
import { carbon600, carbon800, white } from '@styles/palette'
import { yellow300, yellow900 } from '@common/styles'
import { WITH_TABLE } from '@common/constants/width'

const Screen = ({
  supply,
  route,
  handleDeleteSupply,
  showDeleteModal,
  closeModalDelete,
  getSurface,
  getEiq,
  getComposition,
  getClassToxic,
  getQuantity,
  unitSurface,
  code,
}) => {
  const { t } = useContext(LanguageContext)
  const { ComponentStandarDeviation, openBackdropStandardDeviation } =
    useStandardDeviation({ t })
  const { surface, surfaceDetail, item, id } = useRef(route.params).current
  const itemToSend = item ? item : id

  const { ComponentOutlierBox } = useFetchOutliers({
    item: itemToSend,
    surface: surface
      ? surface
      : surfaceDetail
      ? surfaceDetail
      : itemToSend?.surfaceDetail,
    openBackdropStandardDeviation,
  })
  const getTitle = () => {
    if (supply.brand) {
      return supply.brand
    }

    if (typeof supply.supply === 'object') {
      if (supply?.supply?.brand) {
        return supply.supply.brand
      }
    }

    return supply?.name
  }

  const getUnitTypeSupply = (supply) => supply?.unitName ?? supply?.unit ?? ''

  const eiqData = useMemo(() => {
    return getEiq(supply)
  }, [supply])

  const classToxic = useMemo(() => {
    if (supply) return getClassToxic(supply)
    else return ''
  }, [supply])

  const SupplyIcon = () => {
    if (!supply?.supply?.isGeneric) {
      return (
        <Image
          style={styles.image}
          source={SUPPLY[code] || SUPPLY['default']}
        />
      )
    }

    return (
      <IconBox
        iconName={EActivityTypes.ACT_FERTILIZATION}
        size={SIZES.MEDIUM}
        color={yellow900}
        bgColor={yellow300}
      />
    )
  }

  return (
    <ResponsiveLayout maxWidth={WITH_TABLE}>
      <ScrollView
        showsVerticalScrollIndicator={Platform.OS === 'web'}
        style={styles.container}
      >
        {supply && (
          <>
            <>
              <SupplyIcon />

              <Text style={styles.title}>{getTitle()}</Text>

              {supply.brand && (
                <Text style={[styles.subTitleSupply, styles.marginTopBrand]}>
                  {supply.name}
                </Text>
              )}

              <Text style={styles.subTitleSupply}>
                {supply?.typeId?.codeLabel ?? supply?.typeId?.name}
              </Text>

              <Text style={styles.subTitleSupply}>
                {supply.supply?.company}
              </Text>
            </>

            <ComponentOutlierBox />

            {!supply?.typeId?.tags.includes(keysSupplies.fertilizer) ? (
              <View style={styles.dataWrapper}>
                <Text style={styles.subTitle}>
                  {t('VIEWS').SUPPLY_DETAIL.TEXT_1}
                </Text>
                <Text style={styles.dataValue}>{getComposition(supply)}</Text>
              </View>
            ) : (
              <ChemicalCompositions
                containerStyle={styles.chemicalCompositions}
                chemicalCompositions={mapData(
                  supply?.supply?.chemicalCompositionsRequest ??
                    supply?.supply?.chemicalCompositions
                )}
                show={supply?.typeId?.tags.includes(keysSupplies.fertilizer)}
              />
            )}

            {Boolean(eiqData) && (
              <View style={styles.dataWrapper}>
                <Text style={styles.subTitle}>
                  {t('VIEWS').SUPPLY_DETAIL.TEXT_2}
                </Text>
                <Text style={styles.dataValue}>{eiqData}</Text>
              </View>
            )}

            {Boolean(classToxic) && (
              <View style={styles.dataWrapper}>
                <Text style={styles.subTitle}>
                  {t('VIEWS').SUPPLY_DETAIL.TEXT_3}
                </Text>

                <Text style={styles.dataValue}>{classToxic}</Text>
              </View>
            )}

            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('ACTIVITIES.SUPPLIES_ACTIVITY.UNIT_OF_MEASURE')}
              </Text>

              <Text style={styles.dataValue}>{getUnitTypeSupply(supply)}</Text>
            </View>

            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS.SUPPLY_DETAIL.TEXT_4', {
                  areaUnit: unitSurface,
                })}
              </Text>

              <Text style={styles.dataValue}>{getSurface(supply)}</Text>
            </View>

            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {t('VIEWS').SUPPLY_DETAIL.TEXT_6} {getUnitTypeSupply(supply)}/
                {unitSurface}
              </Text>
              <Text style={styles.dataValue}>{getQuantity(supply)}</Text>
            </View>
            <View style={styles.dataWrapper}>
              <Text style={styles.subTitle}>
                {`${t('VIEWS').SUPPLY_DETAIL.TEXT_7} ${getUnitTypeSupply(
                  supply
                )}`}
              </Text>
              <Text style={styles.dataValue}>{supply?.total}</Text>
            </View>
          </>
        )}
      </ScrollView>

      <ComponentStandarDeviation />

      <ModalConfirm
        visible={showDeleteModal}
        onClose={closeModalDelete}
        onConfirm={() => handleDeleteSupply(route.params.key)}
        title={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_1}
        contentText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_2}
        cancelText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_3}
        confirmText={t('VIEWS').SUPPLY_DETAIL.MODAL_DELETE.TEXT_4}
        cancelButtonStyle={styles.deleteModalCancelButtonStyle}
        cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
        confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
      />
    </ResponsiveLayout>
  )
}

const styles = StyleSheet.create({
  layoutContainer: {
    backgroundColor: 'white',
  },
  container: {
    paddingHorizontal: 16,
    backgroundColor: white,
  },
  title: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: 0.15,
    textAlign: 'left',
    marginTop: 12,
    fontFamily: 'Inter_700_bold',
    lineHeight: 32,
  },
  subTitleSupply: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.25,
    textAlign: 'left',
    fontFamily: 'Inter',
    color: carbon800,
    lineHeight: 24,
  },
  marginTopBrand: {
    marginTop: 8,
  },
  subTitle: {
    fontFamily: 'Inter_600_semibold',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '600',
    color: carbon800,
    lineHeight: 28,
  },
  dataValue: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    color: carbon800,
    lineHeight: 24,
  },
  image: {
    height: 40,
    width: 40,
  },

  textDescription: {
    marginLeft: 16,
    width: 300,
  },
  dataWrapper: {
    marginTop: 16,
  },
  deleteModalCancelButtonStyle: {
    borderColor: '#FF7043',
  },
  deleteModalCancelButtonTextStyle: {
    color: '#FF7043',
  },
  deleteModalConfirmButtonStyle: {
    backgroundColor: '#FF7043',
  },
  outlierContainer: {
    marginHorizontal: 33,
    marginBottom: 45,
  },
  chemicalCompositions: {
    marginLeft: 0,
  },
})
Screen.propTypes = {
  route: PropTypes.object.isRequired,
}

Screen.propTypes = {
  supply: PropTypes.object,
  route: PropTypes.object.isRequired,
  handleDeleteSupply: PropTypes.func.isRequired,
  showDeleteModal: PropTypes.bool.isRequired,
  closeModalDelete: PropTypes.func.isRequired,
  getSurface: PropTypes.func.isRequired,
  getEiq: PropTypes.func.isRequired,
  getComposition: PropTypes.func.isRequired,
  getClassToxic: PropTypes.func.isRequired,
}

export default Screen
