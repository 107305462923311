import React from 'react'
import { PropTypes } from 'prop-types'

import activityTypes from '@constants/activityTypes'
import {
  HarvestForm,
  MonitoringForm,
  AgreementForm,
  VerificationForm,
  IrrigationForm,
  AchievementUnplannedForm,
  FloodForm,
} from '../../components'
import { outlierWarningValues } from '@constants'

export const getFormByActivityType = ({
  tag,
  formik,
  isViewReadOnly = false,
  onPressLotsInput,
  openConfirmationChangeField,
  verificationType,
  crop,
  isDirectAchievement = false,
  activityType,
  isConnected,
  openBackdropStandardDeviation,
  outlierHarvestLevel,
  canShowOutlier,
  changeOutlierUnitType,
  changePayAndRequestOutlier,
  isValueOutsideMeanHarvest,
  canShowOutlierHarvest,
  statusOutlier,
  isNewActivity,
  formikInitialValues,
  dontShowGenerateCft,
  goToEditFarm,
  setDontShowGenerateCft,
  alphaCodeCompany,
}) => {
  const valueOutsideMean = outlierWarningValues.includes(outlierHarvestLevel)

  if (tag === activityTypes.ACT_HARVEST.key) {
    return (
      <HarvestForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        crop={crop}
        openBackdropStandardDeviation={openBackdropStandardDeviation}
        canShowOutlier={canShowOutlier}
        valueOutsideMean={valueOutsideMean}
        isNewActivity={isNewActivity}
        changeOutlierUnitType={changeOutlierUnitType}
        changePayAndRequestOutlier={changePayAndRequestOutlier}
        isValueOutsideMeanHarvest={isValueOutsideMeanHarvest}
        canShowOutlierHarvest={canShowOutlierHarvest}
        statusOutlier={statusOutlier}
      />
    )
  } else if (tag === activityTypes.ACT_MONITORING.key) {
    return (
      <MonitoringForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        crop={crop}
        openBackdropStandardDeviation={openBackdropStandardDeviation}
        canShowOutlier={canShowOutlier}
        valueOutsideMean={valueOutsideMean}
        isNewActivity={isNewActivity}
        changeOutlierUnitType={changeOutlierUnitType}
        changePayAndRequestOutlier={changePayAndRequestOutlier}
        isValueOutsideMeanHarvest={isValueOutsideMeanHarvest}
        canShowOutlierHarvest={canShowOutlierHarvest}
        statusOutlier={statusOutlier}
      />
    )
  } else if (tag === activityTypes.ACT_AGREEMENTS.key) {
    return (
      <AgreementForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        crop={crop}
        formikInitialValues={formikInitialValues}
        dontShowGenerateCft={dontShowGenerateCft}
        setDontShowGenerateCft={setDontShowGenerateCft}
        goToEditFarm={goToEditFarm}
        alphaCodeCompany={alphaCodeCompany}
      />
    )
  } else if (tag === activityTypes.ACT_VERIFICATION.key) {
    return (
      <VerificationForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        openConfirmationChangeField={openConfirmationChangeField}
        crop={crop}
        verificationType={verificationType}
      />
    )
  } else if (tag === activityTypes.ACT_IRRIGATION.key) {
    return (
      <IrrigationForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        crop={crop}
        activityType={activityType}
      />
    )
  } else if (tag === activityTypes.ACT_FLOOD.key) {
    return (
      <FloodForm
        formik={formik}
        readOnly={isViewReadOnly}
        onPressLotsInput={onPressLotsInput}
        crop={crop}
        activityType={activityType}
      />
    )
  } else if (isDirectAchievement) {
    return (
      <AchievementUnplannedForm
        formik={formik}
        tag={tag}
        crop={crop}
        onPressLotsInput={onPressLotsInput}
        activityType={activityType}
        isConnected={isConnected}
        readOnly={isViewReadOnly}
      />
    )
  }
}

getFormByActivityType.propTypes = {
  tag: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  isViewReadOnly: PropTypes.bool.isRequired,
  onPressLotsInput: PropTypes.func.isRequired,
  openConfirmationChangeField: PropTypes.func,
  crop: PropTypes.obj,
  isDirectAchievement: PropTypes.bool,
  activityType: PropTypes.string,
  isConnected: PropTypes.bool,
  openBackdropStandardDeviation: PropTypes.func,
  outlierHarvestLevel: PropTypes.number,
  canShowOutlier: PropTypes.bool,
  changeOutlierUnitType: PropTypes.func,
  changePayAndRequestOutlier: PropTypes.func,
  isValueOutsideMeanHarvest: PropTypes.bool,
  canShowOutlierHarvest: PropTypes.bool,
  statusOutlier: PropTypes.string,
  isNewActivity: PropTypes.bool,
}
