export default {
  TEXT_1: 'Lotes selecionados',
  TEXT_2: 'Superfície planejada',
  TEXT_3: 'Colaboradores',
  TEXT_4: 'Realização',
  TEXT_5: 'Do planejado',
  TEXT_6: 'Feito',
  TEXT_7: 'Adicionar realização',
  TEXT_8: 'Superficie realizada',
  TEXT_9: 'Validações',
  TEXT_10: 'Validado',
  TEXT_11: 'Validar',
  TEXT_12: 'Descarregar relatório pdf',
  TEXT_13: 'Rascunho',
  TEXT_14: 'Versão',
  TEXT_15: 'Em revisão',
  TEXT_16: '%{establishments} Campo - %{lots} Lotes',
  TEXT_17: 'Tipo de fertilización',
  TEXT_18: 'Insumos',
  TEXT_19: 'Desde',
  TEXT_20: 'Hasta',
}
