import { useState, useEffect } from 'react'
import useNetwork from '@utils/network'
import { diffInMinutes } from '@utils/date'
import { useDispatch, useSelector } from 'react-redux'
import { cipher, decipher } from '@utils/common'
import { setPin } from '@store/slices'
import { useAuth } from '@common/hooks'

const MINUTES_FOR_EXPIRATION_PIN = 10
let auxPin = null
const useValidatePin = () => {
  const { doRequest, loading } = useNetwork()
  const dispatch = useDispatch()
  const [isValidating, setIsValidating] = useState(false)
  const pinAuth = useSelector((state) => state.auth.pin)
  const { user } = useAuth()

  const getCipher = cipher(`mySecretKey ${user?._id}`)
  const getDecipher = decipher(`mySecretKey ${user?._id}`)

  useEffect(() => {
    auxPin = pinAuth
  }, [pinAuth])

  const setVerifyPin = async (pinCode) => {
    const response = await doRequest({
      method: 'POST',
      url: `users/validate/pin`,
      data: {
        pin: pinCode,
      },
    })
    const { validate } = response.data
    if (validate) {
      persitPinAuth(pinCode)
    }
    return response.data
  }

  const persitPinAuth = (pinCode) => {
    const pin = {
      code: getCipher(pinCode),
      initializationDate: new Date().toString(),
    }
    dispatch(
      setPin({
        pin,
      })
    )
  }

  const getPinDecipher = () => {
    const pin = pinAuth ?? auxPin
    return pin ? getDecipher(pin?.code) : ''
  }

  const verifyExpirationPin = () => {
    const pin = pinAuth ?? auxPin
    setIsValidating(true)
    if (!pin) {
      setIsValidating(false)
      return true
    }
    const initializationDate = new Date(pin?.initializationDate)
    const dateTimeNow = new Date()
    setIsValidating(false)
    return (
      diffInMinutes(initializationDate, dateTimeNow) >=
      MINUTES_FOR_EXPIRATION_PIN
    )
  }

  const geTokenAuthPin = async () => {
    const pin = pinAuth ?? auxPin
    return await setVerifyPin(getDecipher(pin?.code))
  }

  return {
    setVerifyPin,
    loading: isValidating || loading,
    verifyExpirationPin,
    geTokenAuthPin,
    persitPinAuth,
    getPinDecipher,
  }
}

export default useValidatePin
