import { formatDateLabel } from '@common/utils'
import { IItem } from '../components'

export const getDataRenderResumeFlood = (activityDTO: any, t: any) => {
  const {
    cropName,
    companyName,
    fieldsQuantity,
    surface,
    unitArea,
    dateAchievement,
    timeFloodUnderCrop,
    unitTime,
    paddyWaterCultivation,
    paddyWaterPreseason,
  } = activityDTO

  const itemsFlood: IItem[] = [
    {
      title: t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.BEFORE_CROP'),
      textValue: paddyWaterPreseason.label,
    },
    {
      title: t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.DURING_CROP'),
      textValue: paddyWaterCultivation.label,
    },
  ]

  const itemsTimeFlood: IItem[] = [
    {
      title: t('VIEWS.ACTIVITY_CREATE.FLOOD_FORM.TIME_FLOOD_UNDER_CROP'),
      textValue: `${timeFloodUnderCrop} ${unitTime.label}`,
    },
  ]

  const itemsCrop: IItem[] = [
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.GENERAL_INFO_CARD.CROP_NAME_TITLE'
      ),
      textValue: cropName,
    },
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.GENERAL_INFO_CARD.COMPANY_NAME_TITLE'
      ),
      textValue: companyName,
    },
  ]

  const itemsActivity: IItem[] = [
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.FIELDS_QUANTITY_TITLE'
      ),
      textValue: fieldsQuantity,
    },
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.SURFACE_TITLE'
      ),
      textValue: `${surface} ${unitArea}`,
    },
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.REALIZED_DATE_TITLE'
      ),
      textValue: formatDateLabel({ t, date: dateAchievement }),
    },
  ]

  return { itemsCrop, itemsActivity, itemsFlood, itemsTimeFlood }
}
