import { FC, ReactElement, useContext } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'

import { LanguageContext } from '@contextState/language'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'
import TraderMain from '../../traders/screens/trader-main'

const Stack = createStackNavigator()

export const TraderStack: FC<any> = (): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const stacks = [
    {
      name: RouterNames.TRADERS,
      component: TraderMain,
      options: {
        header: (props: any) => {
          if (Platform.OS === 'web') {
            return null
          }

          return <Header {...props} title={' '} drawer />
        },
      },
    },
  ]

  return (
    <Stack.Navigator initialRouteName={RouterNames.TRADERS}>
      {stacks.map((stack, key) => (
        <Stack.Screen {...stack} key={`TRADER_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
