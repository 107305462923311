export default {
  TEXT_1: 'Superficie neta',
  TEXT_2: 'Rendimento estimativo',
  TEXT_3: 'Volume esperado',
  TEXT_4: 'tn',
  TEXT_5: 'ha',
  TEXT_6: 'VERIFICADO',
  TEXT_7: '*Estado VERIFICADO - Ver actividad de verificación',
  TEXT_8: 'Verificación',
  TEXT_9: 'Verificadora',
  TEXT_10: 'Estado',
  TEXT_11: 'Fecha verificación',
  TEXT_12: 'Acordos',
  TEXT_13: '%{lotsQuantity} lotes',
  TEXT_14: '%{has} ha',
  TEXT_15: 'Data de validação',
  TEXT_16: 'Verificado',
  TEXT_17: 'Verificación final',
  TEXT_18: 'Evidencia %{evidenceNumber}',
}
