import React, { useState, useEffect, useRef, useContext } from 'react'
import { View, Pressable, Platform } from 'react-native'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core'
import momentUtils from '@date-io/moment'
import { PropTypes } from 'prop-types'

import { blackHighEmphasis, primary500, redError, white } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import useFadeAnimation from '@hooks/useFadeAnimation'
import useModal from '@hooks/useModal'
import { isEmptyValue } from '@utils/common'
import { formatDateMoment } from '@utils/date'
import DateTimePickerModal from 'react-native-modal-datetime-picker'
import { CustomInputDate } from './CustomInputDate'

const materialTheme = createTheme({
  palette: {
    primary: {
      main: primary500,
      contrastText: white,
    },
  },
})

const InputDate = ({
  id,
  name,
  containerStyle = {},
  inputStyle = {},
  leftIcon = false,
  rightIcon = false,
  onChangeText,
  value,
  inputRef,
  editable = true,
  disabled = false,
  placeholder = '',
  label = '',
  disablePast = false,
  touched,
  error,
  minDate = undefined,
  maxDate = undefined,
  onBlur,
}) => {
  const { i18n, t } = useContext(LanguageContext)

  const [isFocused, setIsFocused] = useState(false)

  const fadeParams = useRef({
    durationIn: 200,
    durationOut: 0,
  }).current

  const {
    animatedValue: placeholderOpacity,
    fadeIn: fadeInPlaceholder,
    fadeOut: fadeOutPlaceholder,
  } = useFadeAnimation(fadeParams)

  const { isModalVisible, toggleModal, closeModal } = useModal()

  useEffect(() => {
    if (isEmptyValue(value.label)) {
      fadeOutPlaceholder()

      return
    }

    fadeInPlaceholder()
  }, [value.label])

  useEffect(() => {
    if (isModalVisible) {
      setIsFocused(true)
    } else {
      setIsFocused(false)
    }
  }, [isModalVisible])

  const onPress = () => {
    if (disabled || !editable) {
      return
    }

    toggleModal()
  }

  const selectDate = (date) => {
    closeModal()

    const valueDate = {
      value: date,
      label: formatDateMoment(date, 'DD/MMM/YYYY', i18n.locale, true),
    }

    onChangeText(valueDate)
  }

  const InputCustom = (params) => {
    return (
      <View {...params}>
        <CustomInputDate
          containerStyle={containerStyle}
          id={id}
          placeholderOpacity={placeholderOpacity}
          touched={touched}
          error={error}
          isFocused={isFocused}
          disabled={disabled}
          label={label}
          placeholder={placeholder}
          inputStyle={inputStyle}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          value={value}
          inputRef={inputRef}
          locale={i18n.locale}
        />
      </View>
    )
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={momentUtils} locale={i18n.locale}>
            <DatePicker
              disablePast={disablePast}
              value={value.value}
              format='DD/MM/YYYY'
              onChange={(momentDate) => selectDate(momentDate.toDate())}
              readOnly={!editable}
              disabled={disabled}
              TextFieldComponent={InputCustom}
              minDate={minDate}
              maxDate={maxDate}
              onClose={onBlur}
              fullWidth
              autoOk
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      ) : (
        <>
          <Pressable onPress={onPress}>
            <CustomInputDate
              containerStyle={containerStyle}
              id={id}
              placeholderOpacity={placeholderOpacity}
              touched={touched}
              error={error}
              isFocused={isFocused}
              disabled={disabled}
              label={label}
              placeholder={placeholder}
              inputStyle={inputStyle}
              leftIcon={leftIcon}
              rightIcon={rightIcon}
              value={value}
              inputRef={inputRef}
              locale={i18n.locale}
            />
          </Pressable>

          {isModalVisible && (
            <DateTimePickerModal
              locale={i18n.locale}
              mode='date'
              isVisible={isModalVisible}
              date={value.value ? value.value : new Date()}
              onConfirm={(value) => {
                selectDate(value)
              }}
              onCancel={() => {
                closeModal()
                onBlur()
              }}
              minimumDate={minDate}
              maximumDate={maxDate}
              buttonTextColorIOS={primary500}
            />
          )}
        </>
      )}
    </>
  )
}

InputDate.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  leftIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    label: PropTypes.string,
  }),
  inputRef: PropTypes.object,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disablePast: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onBlur: PropTypes.func,
}

export default InputDate
