import NOTIFICATION_EMPTY_STATE from '../../components/EmptyState/translate/pt'
import NOTIFICATION_LIST from '../../components/NotificationList/translate/pt'
import NOTIFICATION_PENDING_TASK from '../../components/NotificationPendingTask/translate/pt'

export default {
  NAVIGATION: {
    TITLE: 'Notificações',
  },
  COMPONENTS: {
    NOTIFICATION_EMPTY_STATE,
    NOTIFICATION_LIST,
    NOTIFICATION_PENDING_TASK,
  },
}
