export default {
  TEXT_1: 'Composición',
  TEXT_2: 'EIQ',
  TEXT_3: 'Clases toxicológicas',
  TEXT_4: 'Superficie %{areaUnit}',
  TEXT_5: 'Unidad de medida',
  TEXT_6: 'Cantidad de %{unit}/%{areaUnit}',
  TEXT_7: 'ha',
  TEXT_8: 'Total',
  TEXT_9: 'Seleccionar',
  TEXT_10: 'Clase',
  TEXT_11: 'Escasa',
}
