import herbalMedicine from '@constants/herbalMedicine'
import { getFileType } from '@utils/files'

export const allowedImagesFormat = ['jpeg', 'jpg', 'png', 'heic']

export const getIconSupply = (supplyCode) => {
  return supplyCode.includes('Se') ? 'spa' : 'test-tube'
}

export const getIconHerbalMedicine = (supplyCode) => {
  return herbalMedicine.includes(supplyCode) ? 'test-tube' : 'spa'
}

/**
 * DEPRECATED
 *
 * GET FILE TYPE
 *
 * @param {*} result
 *
 * @returns String
 */
export const getType = (result) => {
  const localUri = result.uri
  const filename = localUri.split('/').pop()

  // Infer the type of the image
  const match = /\.(\w+)$/.exec(filename)
  const type = match ? `image/${match[1]}` : `image`

  return type
}

/**
 * VERIFY IF FILE IS IMAGE OR NOT
 *
 * @param {*} path
 *
 * @returns Boolean
 */
export const isFileImage = (path) => {
  if (!path) return false
  let fileType
  if (!path) return false
  try {
    fileType = getFileType(path)?.toLocaleLowerCase()
  } catch (error) {
    console.error(error)
  }

  return allowedImagesFormat.includes(fileType)
}
