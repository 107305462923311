import React, { useContext } from 'react'
import { StyleSheet, SectionList, Platform, Text } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { CompanyDetails } from '@modules/common/components'
import { useCompanyInfo } from '@modules/common/hooks'
import { formatDate } from '@modules/common/utils'
import { EmptyState, Notification } from '../'
import { blackMediumEmphasis, grayBackground } from '@styles/palette'
import { getOulierLevel } from '../../utils'
export const NotificationList = ({
  isLoading,
  notifications,
  onPress,
  onEndReached,
  hasMoreData = false,
}) => {
  const { t } = useContext(LanguageContext)

  const { identifier: companyIdentifier, companyName } = useCompanyInfo()

  const renderSectionHeader = ({ section: { date } }) => {
    return (
      <Text style={styles.title}>
        {t('VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_LIST.DATE', {
          day: formatDate({ date, format: 'D' }),
          month: t('CALENDAR').MONTHS[formatDate({ date, format: 'M' })],
          year: formatDate({ date, format: 'YYYY' }),
        })}
      </Text>
    )
  }

  const renderItem = ({
    item: {
      _id,
      type,
      isRead,
      realizedAt,
      createdAt,
      areaUnit,
      activity,
      achievement,
      crop,
      farms,
      outlierSowingLevel,
      outlierHarvestLevel,
      outlierFertilizerLevel,
      outlierActiveIngredientLevel,
    },
  }) => {
    const outlierLevel = getOulierLevel(
      outlierSowingLevel,
      outlierHarvestLevel,
      outlierFertilizerLevel,
      outlierActiveIngredientLevel
    )

    return (
      <Notification
        _id={_id}
        type={type}
        isRead={isRead}
        realizedAt={realizedAt}
        createdAt={createdAt}
        areaUnit={areaUnit}
        activity={activity}
        achievement={achievement}
        crop={crop}
        farms={farms}
        onPress={onPress}
        outlierLevel={outlierLevel}
      />
    )
  }

  const keyExtractor = (item, index) =>
    `NOTIFICATION_GROUPED_BY_DATE_${index.toString()}`
  const ListHeaderComponent = () => (
    <CompanyDetails
      companyName={companyName}
      companyIdentifier={companyIdentifier}
      style={styles.companyDetails}
    />
  )

  return (
    <SectionList
      sections={notifications}
      keyExtractor={keyExtractor}
      contentContainerStyle={styles.container}
      ListEmptyComponent={EmptyState}
      ListHeaderComponent={ListHeaderComponent}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      refreshing={isLoading}
      onEndReachedThreshold={0.5}
      onEndReached={hasMoreData && onEndReached}
      showsVerticalScrollIndicator={Platform.OS === 'web'}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: grayBackground,
  },
  companyDetails: {
    margin: 0,
  },
  title: {
    fontSize: 12,
    fontWeight: '400',
    color: blackMediumEmphasis,
    marginLeft: 5,
    marginVertical: 20,
  },
})

NotificationList.propTypes = {
  isLoading: PropTypes.bool,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          isRead: PropTypes.bool.isRequired,
          realizedAt: PropTypes.string.isRequired,
          areaUnit: PropTypes.string.isRequired,
          activity: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            surface: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
          }),
          achievement: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            surface: PropTypes.number.isRequired,
          }),
          crop: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
          farms: PropTypes.arrayOf(
            PropTypes.shape({
              _id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
              surface: PropTypes.number.isRequired,
              areaUnit: PropTypes.string.isRequired,
              fields: PropTypes.arrayOf(
                PropTypes.shape({
                  _id: PropTypes.string.isRequired,
                  surface: PropTypes.number.isRequired,
                  areaUnit: PropTypes.string.isRequired,
                })
              ),
            })
          ),
        })
      ),
    })
  ),
  onPress: PropTypes.func,
  onEndReached: PropTypes.func,
  hasMoreData: PropTypes.bool,
}
