import FARM_SEARCH from '../../components/SearchFarm/translate/en'
import FARM_CARD from '../../components/FarmCard/translate/en'

export default {
  NOT_FOUND: 'No matches found',
  COMPONENTS: {
    FARM_SEARCH,
    FARM_CARD,
  },
}
