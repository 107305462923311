const supplies = {
  barley: require('@supplies/barleyseed.png'),
  canola: require('@supplies/canolaseed.png'),
  corn: require('@supplies/cornseed.png'),
  cotton: require('@supplies/cottonseed.png'),
  edt: require('@supplies/edt.png'),
  fertilizer: require('@supplies/fertilizer.png'),
  fungicide: require('@supplies/fungicide.png'),
  herbicide: require('@supplies/herbicide.png'),
  other: require('@supplies/other.png'),
  otherseed: require('@supplies/otherseed.png'),
  popcorn: require('@supplies/popseed.png'),
  soy: require('@supplies/soyseed.png'),
  sunflower: require('@supplies/sufseed.png'),
  wheat: require('@supplies/wheatseed.png'),
  carinata: require('@supplies/carinataseed.png'),
  oatmeal: require('@supplies/oatmealseed.png'),
  rye: require('@supplies/ryeseed.png'),
  ryegrass: require('@supplies/ryegrassseed.png'),
  sorghum: require('@supplies/sorghumseed.png'),
  vetch: require('@supplies/vetchseed.png'),
  camelina: require('@supplies/camelina.png'),
  vicia: require('@supplies/vicia.png'),
  Rice: require('@supplies/Rice.png'),
  Potato: require('@supplies/Potato.png'),
  Peanut: require('@supplies/Peanut.png'),
  vine: require('@supplies/vine.png'),
  walnut: require('@supplies/walnut.png'),
  adzukibean: require('@supplies/adzukibean.png'),
  sesame: require('@supplies/sesame.png'),
  mungbean: require('@supplies/mungbean.png'),
  bean: require('@supplies/bean.png'),
  onion: require('@supplies/onion.png'),
  watermelon: require('@supplies/watermelon.png'),
  whitecorn: require('@supplies/whitecorn.png'),
  chia: require('@supplies/chia.png'),
  hemp: require('@supplies/hemp.png'),
  chickpea: require('@supplies/chickpea.png'),
  lentil: require('@supplies/lentil.png'),
}

export default supplies
