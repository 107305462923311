import { ChemicalComposition } from '../hooks'
interface valuesForm {
  chemicalCompositions: ChemicalComposition
  optionsNitrogen?: string
}
export const hasChemicalCompositions = (values: valuesForm) => {
  const { chemicalCompositions, optionsNitrogen = 'total' } = values

  return Object.keys(chemicalCompositions).some((key) => {
    if (key === 'N') {
      const { N } = chemicalCompositions

      if (optionsNitrogen === 'composition') {
        const numericValues = [N.N_N, N.N_A, N.N_U]
        return numericValues.some((value) => parseFloat(value) >= 0.01)
      } else if (optionsNitrogen === 'total') {
        return parseFloat(N.N) >= 0.01
      }
    } else {
      return (
        chemicalCompositions[key].length &&
        parseFloat(chemicalCompositions[key]) >= 0.01
      )
    }
  })
}
