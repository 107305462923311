import React, { useContext } from 'react'
import { StyleSheet, Text } from 'react-native'

import { LanguageContext } from '@contextState/language'

import { SIZES } from '@common/components/icons/interfaces'
import { black, carbon200, carbon600, carbon800 } from '@styles/palette'

import { activityIcons } from '@modules/cropStory/screens/CropStoryLotDetail/v1/utils'

import { IconBox } from '../IconBox'

interface GenericSupplyHeaderProps {
  supplyName?: string
  iconBoxColor?: string
  iconColor?: string
}

export const GenericSupplyHeader: React.FC<GenericSupplyHeaderProps> = ({
  supplyName = '',
  iconColor = carbon600,
  iconBoxColor = undefined,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <>
      <IconBox
        iconName={activityIcons.ACT_FERTILIZATION}
        size={SIZES.MEDIUM}
        color={iconColor}
        style={{ backgroundColor: iconBoxColor, borderRadius: 4 }}
      />

      <Text style={styles.title}>
        {t('VIEWS.SUPPLIES.GENERIC')} {supplyName}
      </Text>
      <Text style={styles.paragraph}>
        {t('VIEWS.SUPPLIES.ENTER_THE_PERCENTAGE_OF_THE_COMPOSITION')}
      </Text>
    </>
  )
}

const styles = StyleSheet.create({
  contentIcon: {
    backgroundColor: carbon200,
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  title: {
    marginTop: 16,
    fontSize: 24,
    lineHeight: 32,
    fontWeight: '700',
    fontStyle: 'normal',
    color: carbon800,
    fontFamily: 'Inter',
  },
  paragraph: {
    marginTop: 16,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: carbon800,
    fontFamily: 'Inter',
    marginBottom: 32,
  },
})
