export default {
  TEXT_1: 'Crops',
  TEXT_2: 'Edit profile',
  TEXT_3: 'Company',
  TEXT_4: 'Integrations',
  TEXT_5: 'Ecosystems',
  TEXT_6: 'Sign out',
  TEXT_7: 'Version',
  TEXT_8: 'New company',
  TEXT_9: 'Date',
  TEXT_10: 'Pending activities',
  TEXT_11: 'List',
  TEXT_12: 'Dashboard',
  TEXT_13: 'See crop details',
  TEXT_14: 'Delete crop?',
  TEXT_15: 'Collaborators',
  TEXT_16: 'Crop story',
  TEXT_17: 'Are you sure you want to delete this crop?',
  TEXT_18: 'Delete',
  TEXT_19: 'Do not delete',
  TEXT_20: 'No crop loaded',
  TEXT_21: 'Pending authorization',
  TEXT_22:
    'You have not been authorized to sign validations in this company yet.',
  TEXT_23: 'Delete draft',
  TEXT_24: 'Collaborators',
  TEXT_25: 'Integrations with third parties',
  TEXT_26: 'Enable offline',
  TEXT_27: 'Disable offline',
  TEXT_28: 'Delete crop',
  TEXT_29: 'Associate company',
  TEXT_30: 'Pending activities',
  TEXT_31: 'To collaborate',
  TEXT_32:
    'Crop offline sync is enabled for %{country}.\nTo work offline with this crop, disable offline in the rest.',
  TEXT_33:
    'Start your first crop to begin recording your activities, measuring EIQ, and accessing dashboards.',
  TEXT_34: 'DONE',
  TEXT_35: 'Last uploaded',
  TEXT_36: 'Larger surface',
  TEXT_37: 'Pending activities',
  TEXT_38: 'Enabled offline',
  TEXT_39: 'A-Z',
  TEXT_40: 'Ordered by',
  TEXT_41:
    'Remember to add a field to the farm so it can be assigned to a new crop.',
  TEXT_42: 'Create your first crop to begin recording your activities.',
}
