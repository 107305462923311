export const ACTIVITY_CONDITIONS: any = {
  DRAFT: {
    key: 'DRAFT',
    name: (t: any) => t('COMMON.ACTIVITY_CONDITIONS.DRAFT'),
  },
  PLANNED: {
    key: 'PLANNED',
    name: (t: any) => t('COMMON.ACTIVITY_CONDITIONS.PLANNED'),
  },
  DONE: {
    key: 'DONE',
    name: (t: any) => t('COMMON.ACTIVITY_CONDITIONS.DONE'),
  },
  FINISHED: {
    key: 'FINISHED',
    name: (t: any) => t('COMMON.ACTIVITY_CONDITIONS.FINISHED'),
  },
}
