import React, { useContext } from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import { Button, Title, Text } from 'react-native-paper'
import { PropTypes } from 'prop-types'
import ModalCustom from './ModalCustom'
import LottieView from 'lottie-react-native'
import LoadingAnimation from '@assets/animations/lottie/loading'
import { LanguageContext } from '@contextState/language'

let Lottie
if (Platform.OS === 'web') {
  Lottie = require('react-lottie').default
}
function ModalLoading({
  isLoading,
  handleClose,
  title,
  animation,
  animationStyle,
  loadingLabels,
}) {
  const { t } = useContext(LanguageContext)
  const lottieWeb = () => {
    return (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animation ? animation : LoadingAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={animationStyle?.height}
        width={animationStyle?.width}
      />
    )
  }

  const lottieMobile = () => {
    return (
      <LottieView
        style={animationStyle}
        source={animation ? animation : LoadingAnimation}
        autoPlay
        loop
      />
    )
  }

  return (
    <ModalCustom visible={isLoading} hideModal={handleClose}>
      <Title style={styles.modalTitle}>{title}</Title>

      <View style={styles.animationContainer}>
        {Platform.OS === 'web' ? lottieWeb() : lottieMobile()}
      </View>

      <View style={styles.loadingLabelContainer}>
        {loadingLabels.map((loadingLabel, key) => (
          <Text key={key} style={styles.loadingLabel}>
            {loadingLabel}
          </Text>
        ))}
      </View>

      <Button onPress={handleClose}>
        {t('COMPONENTS').COMMON.MODAL_LOADING.TEXT_1}
      </Button>
    </ModalCustom>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    marginBottom: 20,
  },
  animationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingLabelContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  loadingLabel: {
    fontSize: 16,
  },
})

ModalLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  animation: PropTypes.object,
  animationStyle: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  loadingLabels: PropTypes.array,
}

export default ModalLoading
