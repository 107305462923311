import * as yup from 'yup'

export const harvestValidationSchema = (t) =>
  yup.object().shape({
    lots: yup
      .object()
      .shape({
        value: yup
          .array()
          .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
          .of(
            yup.object().shape({
              _id: yup.string().required(),
              surface: yup.number().required(),
              tag: yup.string(),
              wktFormat: yup.string(),
              area: yup.string(),
            })
          )
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    surface: yup.number().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
    dateHarvest: yup
      .object()
      .shape({
        value: yup
          .date(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    unitVolume: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    volume: yup
      .number()
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    pay: yup
      .number()
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    storages: yup
      .array()
      .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    evidences: yup
      .array()
      .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    signers: yup
      .array()
      .min(2, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 2 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
  })
