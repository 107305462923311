import React from 'react'
import { View, Text, StyleSheet, Platform, Linking } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import CardDefault from '@components/cards/CardDefault'
import {
  blackHighEmphasis,
  blackMediumEmphasis,
  secondary700,
  orange100,
  yellow600,
} from '@styles/palette'
import ChipCommon from '@common/components/chips/ChipCommon'
import ButtonCommunication from '../ButtonCommunication'
import { COLLABORATORS_REQUEST_STATUS } from '@utils/constants'
import Links from '@constants/links'
import { formattedNumber } from '@utils/phone'
import { validatePhoneNumber } from '@utils/validation'

const CardCollaborator = ({
  collaborator,
  t,
  hasPermissionAuthorizeCollaborator,
  isCompanyProducer,
  handleOpen,
  setCollaboratorSelected,
}) => {
  const isPendingAuthorization =
    collaborator.collaboratorRequest?.status ===
    COLLABORATORS_REQUEST_STATUS.PENDING

  const showClockIcon = () => collaborator.email === collaborator.fullName

  return (
    <CardDefault>
      <View style={styles.contentCard}>
        {isCompanyProducer && isPendingAuthorization && (
          <View style={styles.contentOptions}>
            <ChipCommon
              text={t(
                'VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.CARD_COLLABORATOR.PENDING_AUTHORIZATION'
              )}
              backgroundColor={orange100}
              textColor={secondary700}
            />
            {!collaborator?.mySelf && hasPermissionAuthorizeCollaborator && (
              <CommonIcon
                name='DOTS-VERTICAL'
                color={blackMediumEmphasis}
                size={24}
                onPress={() => {
                  handleOpen()
                  setCollaboratorSelected(collaborator)
                }}
              />
            )}
          </View>
        )}
        <View style={styles.contentTitle}>
          <Text style={styles.textTitle}>{collaborator?.fullName}</Text>
          {showClockIcon() && (
            <CommonIcon name='CLOCK' color={yellow600} size={20} />
          )}
        </View>

        {collaborator.email && (
          <Text style={styles.textContent}>{collaborator?.email ?? ''}</Text>
        )}

        {collaborator.role && (
          <Text style={styles.textContent}>
            {collaborator?.role?.keyLabel ?? ''}
          </Text>
        )}

        <Text style={styles.textContent}>
          {collaborator?.company?.name || collaborator.identifier}
        </Text>

        {Platform.OS !== 'web' &&
          !collaborator?.mySelf &&
          Boolean(collaborator?.phone) &&
          validatePhoneNumber(collaborator?.phone) && (
            <View style={styles.collaboratorContact}>
              <ButtonCommunication
                text={t(
                  'VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.CARD_COLLABORATOR.CALL'
                )}
                iconName='PHONE'
                onPress={() =>
                  Linking.canOpenURL(
                    `tel:${formattedNumber(
                      collaborator?.phone,
                      collaborator.company.country.alpha2Code
                    )}`
                  ).then(() => {
                    return Linking.openURL(
                      `tel:${formattedNumber(
                        collaborator?.phone,
                        collaborator.company.country.alpha2Code
                      )}`
                    ).catch(() => null)
                  })
                }
              />
              <View style={styles.space} />

              <ButtonCommunication
                text={t(
                  'VIEWS.COLLABORATORS_LIST_COMPANY.COMPONENTS.CARD_COLLABORATOR.WRITE'
                )}
                iconName='WHATSAPP'
                onPress={() =>
                  Linking.openURL(
                    Links.whatsappLink +
                      '&phone=' +
                      formattedNumber(
                        collaborator?.phone,
                        collaborator.company.country.alpha2Code
                      )
                  )
                }
              />
            </View>
          )}
      </View>
    </CardDefault>
  )
}

CardCollaborator.propTypes = {
  collaborator: PropTypes.object,
  t: PropTypes.func,
  hasPermissionAuthorizeCollaborator: PropTypes.bool,
}

const styles = StyleSheet.create({
  contentCard: {
    padding: 10,
    paddingHorizontal: 15,
  },
  contentTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3,
  },
  textTitle: {
    fontSize: 16,
    color: blackHighEmphasis,
    fontWeight: '500',
  },
  textContent: {
    color: blackMediumEmphasis,
  },
  contentOptions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  collaboratorContact: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  space: {
    width: 30,
  },
})

export default CardCollaborator
