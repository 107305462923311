export default {
  TEXT_1: 'Detalhe realização',
  TEXT_2: 'ha',
  TEXT_3: 'EIQ',
  TEXT_4: 'Validados',
  TEXT_5: 'Validar',
  TEXT_6: 'Lotes',
  TEXT_7: 'EIQ Realizada',
  TEXT_8: 'ha',
}
