import React, { useContext } from 'react'
import { StyleSheet, ScrollView, View, Text, Pressable } from 'react-native'
import { RadioButton } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { primary500, primary50, blackHighEmphasis } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { CommonIcon } from '@common/components'

export const CollaboratorsList = ({
  collaborators,
  onPressShowCollaboratorContact,
  onPressSelectResponsible,
  onPressCallContact,
  onPressSendWhatsappMessage,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <ScrollView
      contentContainerStyle={styles.container}
      showsVerticalScrollIndicator={false}
    >
      {collaborators.map((element, key) => (
        <View key={key} style={styles.collaboratorContainer}>
          <View style={styles.buttonsContainer}>
            <Pressable
              style={styles.collaboratorDescription}
              onPress={() => onPressShowCollaboratorContact(element)}
            >
              {element.isAdmin && (
                <Text style={styles.collaboratorAdminFlag}>
                  {t('VIEWS.CROP_SELECT_RESPONSIBLE.TEXT_4')}
                </Text>
              )}

              <Text style={styles.collaboratorName}>
                {`${element.firstName} ${element.lastName}`}
              </Text>

              <Text style={styles.collaboratorCompanyName}>
                {element.companyName}
              </Text>
            </Pressable>

            <View style={styles.radioButtonContainer}>
              <RadioButton.Android
                value={element.selected}
                status={element.selected ? 'checked' : 'unchecked'}
                onPress={() => onPressSelectResponsible(element)}
                color={primary500}
                uncheckedColor='#DADADA'
              />
            </View>
          </View>

          {element.displayContact && (
            <View style={styles.collaboratorContact}>
              <View style={styles.actionContainer}>
                <CommonIcon
                  name='PHONE'
                  size={20}
                  color='#606060'
                  onPress={() => onPressCallContact(element)}
                />

                <Text style={styles.textButton}>
                  {t('VIEWS.CROP_SELECT_RESPONSIBLE.TEXT_2')}
                </Text>
              </View>

              <View style={styles.actionContainer}>
                <CommonIcon
                  name='WHATSAPP'
                  size={20}
                  color='#606060'
                  onPress={() => onPressSendWhatsappMessage(element)}
                />

                <Text style={styles.textButton}>
                  {t('VIEWS.CROP_SELECT_RESPONSIBLE.TEXT_3')}
                </Text>
              </View>
            </View>
          )}
        </View>
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  collaboratorContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    marginVertical: 4,
    padding: 12,
    minHeight: 98,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  buttonsContainer: {
    flexDirection: 'row',
  },
  collaboratorDescription: {
    flex: 1,
  },
  collaboratorAdminFlag: {
    alignSelf: 'flex-start',
    fontSize: 10,
    fontWeight: '500',
    color: primary500,
    backgroundColor: primary50,
  },
  collaboratorName: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  collaboratorCompanyName: {
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  collaboratorContact: {
    flexDirection: 'row',
    marginTop: 10,
  },
  textContactButton: {
    textTransform: 'capitalize',
  },
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textButton: {
    fontSize: 12,
    textTransform: 'capitalize',
    lineHeight: 16,
    alignItems: 'center',
    letterSpacing: 0.4,
    marginLeft: 8,
  },
})

CollaboratorsList.propTypes = {
  collaborators: PropTypes.array.isRequired,
  onPressShowCollaboratorContact: PropTypes.func.isRequired,
  onPressSelectResponsible: PropTypes.func.isRequired,
  onPressCallContact: PropTypes.func.isRequired,
  onPressSendWhatsappMessage: PropTypes.func.isRequired,
}
