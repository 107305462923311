import { PropTypes } from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { View, FlatList, StyleSheet } from 'react-native'

import { primary500, white, whiteLight } from '@styles/palette'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { LanguageContext } from '@contextState/language'
import { languages } from '@constants'
import ItemListRadioButton from '@common/components/ItemListRadioButton'

const Languages = ({ navigation }) => {
  const { i18n, changeLanguage, t } = useContext(LanguageContext)
  const selected = languages.find((l) => i18n.locale.indexOf(l.key) !== -1)
  const [languageSelected, setLanguageSelected] = useState(
    selected || languages[1]
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setLanguageSelected(selected)
  }, [i18n.locale])

  const changeLanguageSelected = async () => {
    setIsLoading(true)

    if (selected?.key !== languageSelected.key) {
      i18n.locale = languageSelected.key

      await changeLanguage(i18n)
    }

    navigation.goBack()
  }

  const FooterCompany = (
    <View style={styles.contentButton}>
      <ButtonPrimary
        text={`${t('VIEWS.LANGUAGES.SAVE')}`}
        backgroundColor={primary500}
        colorText={white}
        onPress={changeLanguageSelected}
        fontSize={14}
        customTextStyle={styles.textButton}
        fontWeight={'700'}
        loading={isLoading}
      />
    </View>
  )

  return (
    <View style={styles.content}>
      <FlatList
        data={languages}
        renderItem={({ item: language }) => (
          <ItemListRadioButton
            title={language.text}
            isSelected={language.key === languageSelected.key}
            onPress={() => setLanguageSelected(language)}
          />
        )}
        keyExtractor={(language) => {
          return language.key
        }}
        contentContainerStyle={styles.contentContainerStyle}
        style={styles.flatStyle}
      />
      {FooterCompany}
    </View>
  )
}
const styles = StyleSheet.create({
  content: {
    backgroundColor: whiteLight,
    flex: 1,
  },
  contentButton: {
    paddingHorizontal: 20,
    marginBottom: 53,
  },
  flatStyle: {
    height: '100%',
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
  textButton: {
    lineHeight: 16,
    letterSpacing: 0.75,
  },
})

Languages.propTypes = {
  navigation: PropTypes.object,
}

export default Languages
