import { View, StyleSheet } from 'react-native'
import React from 'react'
import { gray200, gray800 } from '@common/styles'
import { carbon800, white } from '@styles/palette'
import { InputSelectChemicalCompositionProps } from './InputSelctChemicalComposition.props'
import { InputChemicalText } from '../InputChemicalText'
import AccordionItem from '@components/common/v2/AccordionItem'

export const InputSelectChemicalComposition = ({
  title,
  fieldName,
  firstText,
  secondText,
  firstOption,
  secondOption,
  optionValue,
  firstTextValue,
  secondTextValue,
  setFieldValue,
  onChangeFirstTextValue,
  onChangeSecondTextValue,
}: InputSelectChemicalCompositionProps) => {
  const [open, setOpen] = React.useState(true)
  const styleAccordion = open ? styles.accordionOpen : styles.accordionClosed
  return (
    <View style={styles.container}>
      <AccordionItem
        title={title}
        titleStyle={styles.titleStyle}
        titleContainerStyle={{ ...styles.contentAccordion, ...styleAccordion }}
        extraPressCustom={() => {
          setOpen(!open)
        }}
        extraBodyStyle={styles.bodyAccordion}
        openByDefault={true}
        colorArrow={gray800}
      >
        <InputChemicalText
          text={firstText}
          option={firstOption}
          optionValue={optionValue}
          onPress={() => {
            setFieldValue(fieldName, firstOption)
          }}
          widthBorder={true}
          textValue={firstTextValue}
          onChangeText={onChangeFirstTextValue}
        />

        <InputChemicalText
          widthBorder={true}
          text={secondText}
          option={secondOption}
          optionValue={optionValue}
          onPress={() => {
            setFieldValue(fieldName, secondOption)
          }}
          textValue={secondTextValue}
          onChangeText={onChangeSecondTextValue}
        />
      </AccordionItem>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 6,
  },
  contentAccordion: {
    borderWidth: 1,
    borderColor: gray200,
    backgroundColor: white,
    padding: 16,
  },
  accordionClosed: {
    borderRadius: 8,
  },
  accordionOpen: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  bodyAccordion: {
    backgroundColor: white,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: gray200,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 0,
    paddingLeft: 4,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: '700',
    lineHeight: 24,
    color: carbon800,
    fontFamily: 'Inter',
  },
})
