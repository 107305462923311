import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import ModalCustom from '../ModalCustom'
import { ModalDefault } from '@common/components'

const ModalConfirm = ({
  visible,
  onClose,
  onCancel,
  onConfirm,
  contentText,
  contentChildren,
  cancelText,
  confirmText = '',
  cancelButtonStyle = {},
  cancelButtonTextStyle = {},
  confirmButtonStyle = {},
  confirmButtonTextStyle = {},
  contentTextStyle = {},
}) => {
  const showContentText = Boolean(contentText)

  const onPressCancel = () => {
    if (onCancel) {
      onCancel()
    }

    onClose()
  }

  const onPressConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }

    onClose()
  }

  return (
    <ModalDefault isModalVisible={visible} closeModal={onClose}>
      <View style={styles.container}>
        {showContentText && (
          <Text style={[styles.contentText, contentTextStyle]}>
            {contentText}
          </Text>
        )}

        {contentChildren}

        <View style={styles.buttonsContainer}>
          {cancelText && (
            <TouchableOpacity
              style={[styles.cancelButton, cancelButtonStyle]}
              onPress={onPressCancel}
            >
              <Text style={[styles.cancelButtonText, cancelButtonTextStyle]}>
                {cancelText}
              </Text>
            </TouchableOpacity>
          )}

          <TouchableOpacity
            style={[styles.confirmButton, confirmButtonStyle]}
            onPress={onPressConfirm}
          >
            <Text style={[styles.confirmButtonText, confirmButtonTextStyle]}>
              {confirmText}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ModalDefault>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 22,
  },
  title: {
    flex: 1,
    fontSize: 16,
    fontWeight: '700',
    color: '#333333',
  },
  closeIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    width: 25,
  },
  contentText: {
    fontSize: 16,
    fontWeight: '400',
    color: '#333333',
    marginVertical: 12,
    marginBottom: 24,
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
  cancelButton: {
    borderColor: '#4CAF50',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 10,
    marginRight: 10,
  },
  cancelButtonText: {
    fontSize: 14,
    fontWeight: '700',
    color: '#4CAF50',
  },
  confirmButton: {
    borderRadius: 10,
    paddingVertical: 6,
    paddingHorizontal: 10,
    backgroundColor: '#4CAF50',
  },
  confirmButtonText: {
    fontSize: 14,
    fontWeight: '700',
    color: 'white',
  },
})

ModalConfirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string,
  contentChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  cancelText: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  cancelButtonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  cancelButtonTextStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  confirmButtonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  confirmButtonTextStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  titleTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  contentTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}

export default ModalConfirm
