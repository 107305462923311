import React from 'react'
import { StyleSheet, View } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { ENotificationType } from '@modules/common/utils'
import { NotificationPendingTask } from '../NotificationPendingTask'
import {
  onPressedColor,
  gray1,
  grayDA,
  white,
  primary500,
} from '@styles/palette'

export const Notification = ({
  _id,
  type,
  isRead,
  realizedAt,
  createdAt,
  areaUnit,
  activity,
  achievement = {},
  crop,
  farms,
  onPress,
  outlierLevel,
}) => {
  const NotificationByType = ({ notificationType }) => {
    const surface = achievement?.surface || activity.surface

    switch (notificationType) {
      case ENotificationType.PENDING_TASK:
        return (
          <NotificationPendingTask
            isRead={isRead}
            realizedAt={realizedAt}
            createdAt={createdAt}
            activityType={activity.type}
            surface={surface}
            areaUnit={areaUnit}
            cropName={crop.name}
            farms={farms}
            outlierLevel={outlierLevel}
          />
        )
      default:
        return false
    }
  }

  const NotificationStatus = ({ isRead }) => {
    switch (isRead) {
      case true:
        return (
          <CommonIcon
            name='CIRCLE'
            size={8}
            style={styles.notificationStatusIconViewed}
          />
        )
      default:
        return (
          <CommonIcon
            name='CIRCLE-FILLED'
            size={8}
            style={styles.notificationStatusIconNotViewed}
          />
        )
    }
  }

  const onPressNotification = () => {
    onPress({
      isNotificationRead: isRead,
      notificationId: _id,
      cropId: crop._id,
      activityType: activity.type,
      activityId: activity._id,
      achievementId: achievement?._id,
    })
  }

  return (
    <TouchableRipple
      style={styles.container}
      onPress={onPressNotification}
      rippleColor={onPressedColor}
      underlayColor={onPressedColor}
    >
      <View style={styles.notificationContent}>
        <NotificationByType notificationType={type} />

        <View style={styles.notificationStatus}>
          <NotificationStatus isRead={isRead} />
        </View>
      </View>
    </TouchableRipple>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 12,
    paddingHorizontal: 16,
    paddingVertical: 13,
    borderWidth: 1,
    borderColor: gray1,
    borderRadius: 8,
    backgroundColor: white,
  },
  notificationContent: {
    flexDirection: 'row',
  },
  notificationStatusIconViewed: {
    color: grayDA,
  },
  notificationStatusIconNotViewed: {
    color: primary500,
  },
})

Notification.propTypes = {
  _id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  realizedAt: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  areaUnit: PropTypes.string.isRequired,
  activity: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    surface: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }),
  achievement: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    surface: PropTypes.number.isRequired,
  }),
  crop: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  farms: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      surface: PropTypes.number.isRequired,
      areaUnit: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          surface: PropTypes.number.isRequired,
          areaUnit: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  outlierLevel: PropTypes.number,
}
