export default {
  LANGUAGE: 'Idioma',
  SIGN_OUT: 'Cerrar Sesión',
  DELETE_ACCOUNT: 'Eliminar cuenta',
  VERSION_BUILD: 'Versión %{version} Build %{build}',
  TERMS: 'Términos y privacidad',
  MODAL_CONFIRM_SIGN_OUT: {
    TITLE: '¿Deseas salir de UCROP.IT?',
    MESSAGE: 'Realizando esta acción eliminaras la cola de sincronización',
    ACCEPT: 'OK',
  },
}
