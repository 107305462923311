function validateEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
}

const phoneNumberValidator = (number) => {
  const patt = new RegExp('[A-Za-z]')
  const res = patt.test(number)
  return res
}

const cuitValidator = {
  name: 'cuit',
  test: (value) => {
    const mult = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
    let total = 0
    const cuit = []
    value = value ? value.toString().replace(/-/g, '') : ''
    for (let x = 0; x < 11; x++) {
      if (value.substr(x, 1) !== '') {
        cuit[x] = value.substr(x, 1)
      }
    }
    if (cuit.length === 0) return true
    if (cuit.length === 11) {
      const prim = Number(cuit[0] + cuit[1])
      if (
        prim === 27 ||
        prim === 20 ||
        prim === 23 ||
        prim === 30 ||
        prim === 33 ||
        prim === 34 ||
        prim === 24
      ) {
        for (let i = 0; i < mult.length; i++) {
          total += parseInt(cuit[i]) * mult[i]
        }
        const mod = total % 11
        const digito = mod === 0 ? 0 : mod === 1 ? 9 : 11 - mod
        return digito === parseInt(cuit[10])
      } else {
        return false
      }
    } else {
      return false
    }
  },
  message: (t) => t('UTILS').VALIDATION.TEXT_1,
}

function requiredNumber(value, t) {
  if (!value) return () => () => t('UTILS').VALIDATION.TEXT_2

  if (!/^[0-9.,]+$/.test(value)) {
    return () => t('UTILS').VALIDATION.TEXT_3
  }

  return null
}

function requiredSupply(value, t) {
  if (!value) return () => () => t('UTILS').VALIDATION.TEXT_2

  if (!/^\d+\.\d{0,2}$/.test(value)) {
    return () => t('UTILS').VALIDATION.TEXT_3
  }

  return null
}

function requiredSurface(value, t) {
  if (!value) return () => t('UTILS').VALIDATION.TEXT_2

  if (value <= 0) {
    return () => t('UTILS').VALIDATION.TEXT_4
  }

  if (!/^[0-9.,]+$/.test(value)) {
    return () => t('UTILS').VALIDATION.TEXT_3
  }

  return null
}

function validatePhoneNumber(phoneNumber) {
  return /((\d{12}\d{13})|(\d{3}[\\*\\.\-\s]){3}|(\d{2}[\\*\\.\-\s]){4}|(\d{4}[\\*\\.\-\s]){2})|\d{12}|\d{13}/.test(
    phoneNumber
  )
}

export {
  requiredNumber,
  validateEmail,
  requiredSupply,
  cuitValidator,
  requiredSurface,
  phoneNumberValidator,
  validatePhoneNumber,
}
