import { unitMeasureSystem } from '@utils/constants'
import { IItem } from '../components'
import { UNITS_TEMPERATURE } from '@common/constants'
import { useAuth } from '@common/hooks'

export const getDataRenderResumeWaterCalculation = (
  activityDTO: any,
  t: any
) => {
  const {
    user: { config },
  } = useAuth()
  const unitTemperature =
    config?.companySelected?.unitMeasureSystem === unitMeasureSystem.METRIC
      ? UNITS_TEMPERATURE.C
      : UNITS_TEMPERATURE.F
  const { weatherType, temperature, soilType, landType, humidityType } =
    activityDTO

  const itemsWaterCalculation: IItem[] = [
    {
      title: t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.WEATHER'),
      textValue: weatherType.label,
    },
    {
      title: t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.TEMPERATURE', {
        unitTemperature,
      }),
      textValue: temperature,
    },
    {
      title: t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.LAND_TYPE'),
      textValue: landType.label,
    },
    {
      title: t(
        'VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.SOIL_CHARACTERISTIC'
      ),
      textValue: soilType.label,
    },
    {
      title: t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.SOIL_MOISTURE'),
      textValue: humidityType.label,
    },
  ]

  return { itemsWaterCalculation }
}
