import FORM_EDIT_FARM_NAME from '../../components/FormEditFarmName/translate/pt'

export default {
  TEXT_1: 'Salvar alterações',
  TEXT_2: 'Insira pelo menos uma letra ou número.',
  TEXT_3: 'Você só pode inserir até %{quantity} letras e/ou números.',
  COMPONENTS: {
    FORM_EDIT_FARM_NAME,
  },
}
