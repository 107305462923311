export default {
  PARAGRAPH_BY_DELETING_ACCOUNT:
    'Al eliminar su cuenta, eliminaremos todos sus datos personales y de uso de nuestros sistemas.',
  PARAGRAPH_ACTION_IRREVERSIBLE:
    'Esta acción es irreversible y, una vez eliminada, no podremos restaurarla.',
  DELETE_ACCOUNT: 'Eliminar cuenta',
  CANCEL: 'CANCELAR',
  MODAL_CONFIRM_DELETE_ACCOUNT: {
    PARAGRAPH_1:
      'Esta acción es irreversible y, una vez eliminada, no podremos restaurarla.\n\n¿Confirma eliminar su cuenta?',
    DELETE: 'ELIMINAR',
  },
  MODAL_SUCCESS_DELETED_ACCOUNT: {
    DELETED_ACCOUNT: 'Cuenta eliminada',
    PARAGRAPH_HAVE_DELETED_ACCOUNT:
      'Hemos eliminado su cuenta y sus datos de nuestro sistema.',
    CLOSE: 'CERRAR',
  },
}
