import RouterNames from '@constants/routerNames'

export const goSettings = (navigation: any, callback: () => void) => {
  if (callback) {
    callback()
  }

  navigation.reset({
    index: 0,
    routes: [
      {
        name: RouterNames.SETTINGS,
      },
    ],
  })
}
