export const outlierType = {
  WARNING: 'WARNING',
  DANGER: 'DANGER',
}

export const outlierIcons = {
  InfoFill: 'INFO-FILLED',
}

export const statusOutlier = {
  atypical: 'WARNING',
  error: 'DANGER',
}
