import React, { useState, useCallback, useRef, useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { PropTypes } from 'prop-types'

import { EstablishmentContext } from '@contextState/establishment'

import { ActivityIndicator, CropDescription } from '@modules/common/components'
import { FarmList, FieldsAddButton, GoBackButton } from './components'
import { getFarmsDTO } from './utils'
import useNetwork from '@utils/network'
import { primary500 } from '@styles/palette'
import { EmptyState } from './components/EmptyState'
import { useSelector } from 'react-redux'
import { useAuth } from '@common/hooks'

const CropFarmList = ({ navigation, route }) => {
  const { cropId } = useRef(route.params).current

  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { user } = useAuth()
  const { fetchEstablishments, resetData } = useContext(EstablishmentContext)

  const [isViewReady, setIsViewReady] = useState(false)
  const [crop, setCrop] = useState({})
  const [farms, setFarms] = useState([])

  const { doRequest } = useNetwork()

  useFocusEffect(
    useCallback(() => {
      fetchInitData()
    }, [])
  )

  /**
   * FETCH INIT DATA
   */
  const fetchInitData = async () => {
    setIsViewReady(false)

    const [crop, farms] = await Promise.all([fetchCrop(), fetchFarms()])

    setCrop(crop)
    setFarms(farms)

    setIsViewReady(true)
  }

  /**
   * FETCH CROP
   */
  const fetchCrop = useCallback(async () => {
    let crop

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        version: 'v2',
        url: `crops/${cropId}`,
        params: {
          companyId: user.config.companySelected
            ? user.config.companySelected?._id
            : undefined,
        },
      })

      crop = response.data
    }

    return crop
  }, [isConnected])

  /**
   * FETCH FARMS OF CROP
   */
  const fetchFarms = useCallback(async () => {
    let farms = []

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        version: 'v1',
        url: `crops/${cropId}/farms`,
      })

      farms = getFarmsDTO(response.data)
    }

    return farms
  }, [isConnected])

  /**
   * GO TO FIELD DETAILS
   */
  const onPressFieldDetails = ({ farm: farmId, _id: fieldId }) => {
    navigation.navigate('CropFarmFieldDetails', { cropId, farmId, fieldId })
  }

  /**
   * GO TO CROP LOTS ADD
   */
  const onPressFieldsAddButton = () => {
    resetData()

    doFetchEstablishments(crop.dateCrop)

    navigation.navigate('LotsAdd', {
      cropId: cropId,
      lotsAdd: true,
      cropName: crop.name,
      onSubmit: onAddLots,
    })
  }

  /**
   * ADD LOTS TO CROPS
   *
   * @param { array } reusableLots
   *
   * @return {Promise<boolean}
   */
  const onAddLots = useCallback(
    async (reusableLots) => {
      if (isConnected) {
        try {
          await doRequest({
            method: 'PUT',
            url: `crops/${cropId}/lots`,
            data: {
              reusableLots,
            },
          })
          return true
        } catch (err) {
          console.warn(err)
          return false
        }
      }
    },
    [isConnected]
  )

  const doFetchEstablishments = async (
    dateCrop,
    identifier = user.config.companySelected.identifier
  ) => {
    const params = {
      identifier,
      dateCrop,
    }

    fetchEstablishments(params)
  }

  /**
   * GO BACK
   */
  const onPressGoBack = () => {
    navigation.goBack()
  }

  if (!isViewReady) {
    return <ActivityIndicator size='large' color={primary500} />
  }

  return (
    <View style={styles.container}>
      <FarmList
        header={<CropDescription crop={crop} />}
        farms={farms}
        onPressFieldDetails={onPressFieldDetails}
        emptyComponent={<EmptyState />}
      />

      <FieldsAddButton onSubmit={onPressFieldsAddButton} />

      <GoBackButton onSubmit={onPressGoBack} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
  },
})

CropFarmList.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      cropId: PropTypes.string.isRequired,
    }),
  }),
}

export default CropFarmList
