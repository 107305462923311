export default {
  PRODUCER_ADVISER: 'Asessor',
  PRODUCER_ADVISER_ENCOURAGED: 'Assesor promotor',
  PRODUCER: 'Produtor',
  PROVIDER: 'Fronecedor',
  MARKETER: 'Comerciante',
  CONTACT_REPRESENTATIVE: 'Responsável comercial',
  CAM: 'KAM',
  KAM: 'KAM',
  VERIFIER: 'Verificador',
}
