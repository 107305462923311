import { collaboratorTypes as type } from '../types'

export const collaboratorsActions = {
  setCollaborators: (payload) => ({
    type: type.SET_COLLABORATORS,
    payload,
  }),
  clearCollaborators: () => ({
    type: type.CLEAR_COLLABORATORS,
  }),
  setCreateCollaboratorConfig: (payload) => ({
    type: type.SET_CREATE_COLLABORATOR_CONFIG,
    payload,
  }),
  clearCreateCollaboratorConfig: (payload) => ({
    type: type.CLEAR_CREATE_COLLABORATOR_CONFIG,
    payload,
  }),
}
