import React from 'react'
import { PropTypes } from 'prop-types'
import { View, StyleSheet, Platform } from 'react-native'
import { Title } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import { green200, green100, green50 } from '@modules/common/styles'
import { blackHighEmphasis } from '@styles/palette'
import { useSelector } from 'react-redux'

const isWeb = Platform.OS === 'web'

export const WorkOrder = ({ t, seeWorkOrder, shareWorkOrder, show }) => {
  const { isConnected } = useSelector((state) => state.connectionReducer)

  if (!show) {
    return null
  }

  return (
    <View style={styles.workOrder}>
      <View style={styles.contentTitle}>
        <View style={styles.contentIconWorkOreder}>
          <CommonIcon name={'WORK-ORDER'} size={26} color={blackHighEmphasis} />
        </View>

        <Title style={styles.textWorkOrder}>
          {t(
            'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.WORK_ORDER.WORK_ORDER'
          )}
        </Title>
      </View>

      <View style={styles.contentIcons}>
        {isConnected && (
          <CommonIcon
            name={'EYE'}
            size={25}
            color={blackHighEmphasis}
            onPress={seeWorkOrder}
          />
        )}

        {!isWeb && (
          <CommonIcon
            name='SHARE'
            size={23}
            color={blackHighEmphasis}
            onPress={shareWorkOrder}
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  workOrder: {
    flexDirection: 'row',
    backgroundColor: green100,
    marginHorizontal: 7.5,
    marginBottom: 12,
    borderRadius: 8,
    alignItems: 'center',
    height: 64,
    marginTop: 10,
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderWidth: 1,
    borderColor: green200,
  },
  contentTitle: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentIconWorkOreder: {
    flex: 1,
    backgroundColor: green50,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    maxWidth: 100,
    paddingVertical: 11,
  },
  contentText: {
    flex: 3,
  },
  textWorkOrder: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0.15,
    color: blackHighEmphasis,
    marginLeft: 11,
    marginRight: 20,
  },
  contentIcons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
})

WorkOrder.propTypes = {
  t: PropTypes.func.isRequired,
  seeWorkOrder: PropTypes.func.isRequired,
  shareWorkOrder: PropTypes.func.isRequired,
  show: PropTypes.bool,
}
