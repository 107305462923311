import FIELDS_ADD_BUTTON from '../../components/FieldsAddButton/translate/es'
import GO_BACK_BUTTON from '../../components/GoBackButton/translate/es'
import EMPTY_STATE from '../../components/EmptyState/translate/es'

export default {
  COMPONENTS: {
    FIELDS_ADD_BUTTON,
    GO_BACK_BUTTON,
    EMPTY_STATE,
  },
}
