import { truncateSurface } from './truncateSurface'
/**
 *
 * @param {number | string} surface  The number to round.
 * @param {string} areaUnit - The area unit
 *
 * @return {string}
 */
export const formatSurface = (surface, areaUnit) =>
  `${truncateSurface(surface)} ${areaUnit}`
