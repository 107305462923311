const ROLES = {
  CAM: [
    {
      label: (t) => t('CONSTANTS').USER_TYPES.CAM,
      value: 'CAM',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER,
      value: 'PRODUCER',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER,
      value: 'PRODUCER_ADVISER',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER_ENCOURAGED,
      value: 'PRODUCER_ADVISER_ENCOURAGED',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.MARKETER,
      value: 'MARKETER',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PROVIDER,
      value: 'PROVIDER',
    },
  ],
  PRODUCER: [
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER,
      value: 'PRODUCER',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER,
      value: 'PRODUCER_ADVISER',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER_ENCOURAGED,
      value: 'PRODUCER_ADVISER_ENCOURAGED',
    },
  ],
  PRODUCER_ADVISER: [
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER,
      value: 'PRODUCER_ADVISER',
    },
  ],
  PRODUCER_ADVISER_ENCOURAGED: [
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER,
      value: 'PRODUCER',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER,
      value: 'PRODUCER_ADVISER',
    },
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER_ENCOURAGED,
      value: 'PRODUCER_ADVISER_ENCOURAGED',
    },
  ],
  MARKETER: [
    {
      label: (t) => t('CONSTANTS').USER_TYPES.MARKETER,
      value: 'MARKETER',
    },
  ],
  PROVIDER: [
    {
      label: (t) => t('CONSTANTS').USER_TYPES.PROVIDER,
      value: 'PROVIDER',
    },
  ],
  VERIFIER: [
    {
      label: (t) => t('CONSTANTS').USER_TYPES.VERIFIER,
      value: 'VERIFIER',
    },
  ],
}

const ROL = {
  PRODUCER: (t) => t('CONSTANTS').USER_TYPES.PRODUCER,
  CAM: (t) => t('CONSTANTS').USER_TYPES.CAM,
  KAM: (t) => t('CONSTANTS').USER_TYPES.KAM,
  PROVIDER: (t) => t('CONSTANTS').USER_TYPES.PROVIDER,
  PRODUCER_ADVISER: (t) => t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER,
  PRODUCER_ADVISER_ENCOURAGED: (t) =>
    t('CONSTANTS').USER_TYPES.PRODUCER_ADVISER_ENCOURAGED,
  MARKETER: (t) => t('CONSTANTS').USER_TYPES.MARKETER,
  DEFAULT: () => 'Collaborator',
  VERIFIER: (t) => t('CONSTANTS').USER_TYPES.VERIFIER,
}

const GET_ROLES = {
  PRODUCER: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_12,
  PRODUCER_ADVISER: () => false,
  PRODUCER_ADVISER_ENCOURAGED: () => false,
  CAM: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_13,
  KAM: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_13,
  PROVIDER: () => false,
  MARKETER: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_14,
  VERIFIER: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_34,
}

const FIND_ROLE = {
  PRODUCER: false,
  PRODUCER_ADVISER: true,
  PRODUCER_ADVISER_ENCOURAGED: true,
  CAM: false,
  KAM: false,
  PROVIDER: true,
  MARKETER: true,
  DEFAULT: false,
}

const ROL_DISABLED = {
  PRODUCER: false,
  PRODUCER_ADVISER: false,
  PRODUCER_ADVISER_ENCOURAGED: false,
  CAM: false,
  KAM: false,
  PROVIDER: false,
  MARKETER: false,
  DEFAULT: false,
  VERIFIER: false,
}

const ROL_PRODUCER_ADVISER_ENCOURAGED = {
  ...ROL_DISABLED,
  CAM: true,
  KAM: true,
  PROVIDER: true,
  MARKETER: true,
}

const ROL_KAM_CAM = {
  ...ROL_DISABLED,
  CAM: false,
  KAM: false,
}

const ITS_CAM = {
  CAM: true,
  KAM: true,
}

const ROL_PROVIDER = {
  ...ROL_DISABLED,
  PRODUCER: true,
  PRODUCER_ADVISER: true,
  PRODUCER_ADVISER_ENCOURAGED: true,
  CAM: true,
  KAM: true,
  MARKETER: true,
  DEFAULT: true,
}

const ROL_MARKETER = {
  ...ROL_DISABLED,
  PRODUCER: true,
  PRODUCER_ADVISER: true,
  PRODUCER_ADVISER_ENCOURAGED: true,
  CAM: true,
  KAM: true,
  PROVIDER: true,
  DEFAULT: true,
}

const ROL_COLLABORATOR_DISABLED = {
  PRODUCER: ROL_PRODUCER_ADVISER_ENCOURAGED,
  PRODUCER_ADVISER: ROL_PRODUCER_ADVISER_ENCOURAGED,
  PRODUCER_ADVISER_ENCOURAGED: ROL_PRODUCER_ADVISER_ENCOURAGED,
  CAM: ROL_KAM_CAM,
  KAM: ROL_KAM_CAM,
  PROVIDER: ROL_PROVIDER,
  MARKETER: ROL_MARKETER,
  DEFAULT: ROL_DISABLED,
  VERIFIER: ROL_DISABLED,
}

const VALIDATE_ROL_CUIT = {
  PRODUCER: false,
  PRODUCER_ADVISER: false,
  PRODUCER_ADVISER_ENCOURAGED: false,
  CAM: false,
  KAM: false,
  PROVIDER: true,
  MARKETER: true,
  DEFAULT: false,
}

const DISABLED_MENU = {
  PRODUCER: true,
  PRODUCER_ADVISER: true,
  PRODUCER_ADVISER_ENCOURAGED: true,
  KAM: false,
  CAM: false,
  PROVIDER: false,
  MARKETER: false,
  DEFAULT: false,
  VERIFIER: true,
}

const UNLINK = {
  PRODUCER: true,
  PRODUCER_ADVISER: true,
  PRODUCER_ADVISER_ENCOURAGED: true,
  KAM: false,
  CAM: false,
  PROVIDER: false,
  MARKETER: false,
  DEFAULT: false,
}

const ROL_VERIFIER = 'VERIFIER'

const DISABLED_MENU_VERIFIER = {
  PRODUCER: true,
  PRODUCER_ADVISER: true,
  PRODUCER_ADVISER_ENCOURAGED: true,
  MARKETER: true,
  PROVIDER: true,
  DEFAULT: true,
  KAM: false,
  CAM: false,
  VERIFIER: false,
}

export {
  ROLES,
  ROL,
  ROL_COLLABORATOR_DISABLED,
  GET_ROLES,
  FIND_ROLE,
  VALIDATE_ROL_CUIT,
  DISABLED_MENU,
  UNLINK,
  ITS_CAM,
  ROL_VERIFIER,
  DISABLED_MENU_VERIFIER,
}
