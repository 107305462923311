import React from 'react'
import { StyleSheet } from 'react-native'
import { FAB as Fab } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { green500, white } from '@common/styles'
import { CommonIcon } from '@common/components/icons'

export const FabButton = ({
  backgroundColor,
  color,
  iconName,
  small,
  style,
  onPress,
  visible,
  testID = null,
}) => {
  return (
    <Fab
      style={[styles.fab, { backgroundColor }, style]}
      color={color}
      icon={(props) => <CommonIcon {...props} name={iconName ?? 'PLUS'} />}
      onPress={onPress}
      small={small}
      visible={visible}
      testID={testID}
    />
  )
}

const FAB_MARGIN_BOTTOM = 40

const styles = StyleSheet.create({
  fab: {
    backgroundColor: green500,
    position: 'absolute',
    color: white,
    right: 15,
    bottom: FAB_MARGIN_BOTTOM,
    borderRadius: 100,
  },
})

FabButton.defaultProps = {
  backgroundColor: green500,
  icon: '',
  color: white,
  small: false,
  style: {},
  visible: true,
}

FabButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  small: PropTypes.bool,
  style: PropTypes.object,
  visible: PropTypes.bool,
}
