import { View, Text, StyleSheet } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field'
import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { isNumber } from '@utils'
import {
  carbon600,
  carbon300,
  carbon50,
  cherry400,
  alertText,
  white,
  primary500,
} from '@styles/palette'
import { black } from '@modules/common/styles'

import ButtonPrimary from '@components/buttons/ButtonPrimary'

const CELL_COUNT = 4

const ConfirmPin = ({ pinToConfirm, submitPin, isSubmit }) => {
  const { t } = useContext(LanguageContext)
  const [value, setValue] = useState('')
  const [incorrectPin, setIncorrectPin] = useState(false)
  const [enableMask, setEnableMask] = useState(true)

  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT })
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  })

  const toggleMask = () => setEnableMask((f) => !f)
  const renderCell = ({ index, symbol, isFocused }) => {
    let textChild = null

    if (symbol) {
      textChild = enableMask ? '•' : symbol
    } else if (isFocused) {
      textChild = <Cursor />
    }

    return (
      <Text
        key={index}
        style={[
          styles.cell,
          isFocused && styles.focusCell,
          incorrectPin && styles.cellError,
        ]}
        onLayout={getCellOnLayoutHandler(index)}
      >
        {textChild}
      </Text>
    )
  }
  const pinValidation = () => {
    return value === pinToConfirm
  }

  const onChangeText = (text) => {
    if (isNumber(text)) {
      setValue(text)
    }
  }
  useEffect(() => {
    ref?.current?.focus()
  }, [])

  useEffect(() => {
    if (value.length === CELL_COUNT && !pinValidation()) {
      setIncorrectPin(true)
    } else if (value.length < CELL_COUNT) {
      setIncorrectPin(false)
    }
  }, [value])

  return (
    <View style={styles.main}>
      <View style={styles.pinContainer}>
        <Text style={styles.title}>
          {t('VIEWS.CREATE_PIN_SCREEN.COMPONENTS.CONFIRM_PIN.TITLE')}
        </Text>
        <Text style={styles.description}>
          {t('VIEWS.CREATE_PIN_SCREEN.COMPONENTS.CONFIRM_PIN.DESCRIPTION')}
        </Text>
        <View style={styles.fieldRow}>
          <View>
            <CodeField
              ref={ref}
              {...props}
              value={value}
              onChangeText={onChangeText}
              cellCount={CELL_COUNT}
              rootStyle={styles.codeFieldRoot}
              keyboardType='number-pad'
              textContentType='oneTimeCode'
              renderCell={renderCell}
              autoFocus={true}
            />
          </View>

          <View style={styles.toggleContainer}>
            <Text style={styles.toggle} onPress={toggleMask}>
              {enableMask ? (
                <CommonIcon name={'EYE-OFF'} size={32} color={black} />
              ) : (
                <CommonIcon name={'EYE'} size={32} color={black} />
              )}
            </Text>
          </View>
        </View>
        {incorrectPin && (
          <Text style={styles.incorrectText}>
            {t('VIEWS.CREATE_PIN_SCREEN.COMPONENTS.CONFIRM_PIN.ALERT')}
          </Text>
        )}
      </View>
      <View style={styles.buttonContainer}>
        <ButtonPrimary
          text={t('VIEWS.CREATE_PIN_SCREEN.NEXT')}
          backgroundColor={
            value.length < 4 || incorrectPin || isSubmit
              ? carbon300
              : primary500
          }
          colorText={white}
          onPress={() => submitPin(value)}
          fontSize={14}
          customTextStyle={styles.textButton}
          fontWeight={'700'}
          marginBottom={32}
          height={48}
          disabled={value.length < 4 || incorrectPin || isSubmit}
        />
      </View>
    </View>
  )
}

export default ConfirmPin

const styles = StyleSheet.create({
  main: {
    backgroundColor: white,
    flex: 1,
    justifyContent: 'space-between',
  },
  pinContainer: {
    paddingHorizontal: 24,
  },
  title: {
    marginTop: 30,
    fontSize: 24,
    fontWeight: '700',

    lineHeight: 28,
  },
  description: {
    fontSize: 14,
    color: carbon600,
    marginTop: 16,

    lineHeight: 20,
  },
  fieldRow: {
    marginTop: 36,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cell: {
    width: 56,
    height: 56,
    lineHeight: 56,
    fontSize: 32,
    fontWeight: '500',
    borderWidth: 1,
    borderColor: carbon300,
    borderRadius: 12,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginEnd: 8,
    backgroundColor: carbon50,
  },
  cellError: {
    borderColor: cherry400,
  },
  toggleContainer: {
    height: 56,
    alignItems: 'center',
    justifyContent: 'center',
    marginStart: 22.5,
  },
  toggle: {
    lineHeight: 55,
    fontSize: 24,
    textAlign: 'center',
  },
  focusCell: {
    borderColor: carbon600,
  },
  buttonContainer: {
    paddingHorizontal: 16,
  },
  textButton: {
    lineHeight: 16,
    letterSpacing: 0.75,
  },
  incorrectText: {
    color: alertText,
    fontSize: 14,
    lineHeight: 22,
    marginTop: 8,
  },
})
