import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { primary500, white, blackMediumEmphasis } from '@styles/palette'
import { LanguageContext } from '@contextState/language'

const TagNew = ({ description }) => {
  const { t } = React.useContext(LanguageContext)
  return (
    <View>
      <View style={styles.tagNew}>
        <Text style={styles.textNew}>{t('COMMON.TAG_NEW.TEXT_1')}</Text>
      </View>
      <Text style={styles.descriptionTagNew}>{description}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  tagNew: {
    backgroundColor: primary500,
    width: 65,
    borderRadius: 4,
    paddingVertical: 2,
  },
  textNew: { color: white, textAlign: 'center' },
  descriptionTagNew: {
    fontSize: 12,
    fontWeight: '400',
    color: blackMediumEmphasis,
  },
})

export default TagNew
