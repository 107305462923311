import ACHIEVEMENTS_FORM from './AchievementsForm'
import AGREEMENTS_FORM from './AgreementsForm'
import COMMON_FORM from './CommonForm'
import HARVEST_FORM from './HarvestForm'
import MONITORING_FORM from './MonitoringForm'
export default {
  ACHIEVEMENTS_FORM,
  AGREEMENTS_FORM,
  COMMON_FORM,
  HARVEST_FORM,
  MONITORING_FORM,
}
