import { makeRequest } from '../../common/services/makeRequest'

export interface PaddyWaterCultivationInterface {
  _id: string
  key: string
  cftId: number
  name: {
    en: string
    es: string
    pt: string
  }
  createdAt: string
  updatedAt: string
  __v: number
  keyLabel: string
}

export const fetchPaddyWaterCultivations = async () => {
  const response = await makeRequest({
    url: `paddy-water-cultivation`,
    prefix: 'activities',
    method: 'GET',
  })
  return response.data
}
