export default {
  FARMS: 'Campos',
  FARM: 'Campo',
  FIELDS: 'Lotes',
  FIELD: 'Lote',
  SURFACE: 'Superficie: %{surface} %{areaUnit}',
  SUB_ACTIVITY_TYPE: 'Tipo de %{activityType}: %{subActivityType}',
  ESTIMATED_YIELD: 'Rinde estimado: %{pay} %{unitPay}',
  YIELD: 'Rinde: %{pay} %{unitPay}',
  EIQ: 'EIQ Generado: %{eiq}',
  PERCENTAGE_REALIZED: 'Realizado %{percentageRealized}%',
  PENDING_SIGNS: '%{pendingSignsQuantity} Firmas pendientes',
  PENDING_SIGN: '%{pendingSignsQuantity} Firma pendiente',
  VERIFICATION_STATUS_VALID: 'Verificado',
  VERIFICATION_STATUS_INVALID: 'No conforme',
  BUTTON_TEXT: 'Ver detalle',
  WATER_CONSUMPTION: 'Consumo de agua',
}
