import { activitiesTypes as type } from '../types'

export const activitiesActions = {
  setFilters: (payload) => ({
    type: type.SET_FILTERS,
    payload,
  }),
  removeFilters: () => ({
    type: type.REMOVE_FILTERS,
  }),
  setIsActivities: (payload) => ({
    type: type.SET_IS_ACTIVITIES,
    payload,
  }),
  setCanSeeVerification: (payload) => ({
    type: type.SET_CAN_SEE_VERIFICATION,
    payload,
  }),
}
