import HEADER from '../../components/Header/translate/pt'
import APP_INTEGRATOR_DETAILS from '../../screens/AppIntegratorDetails/translate/pt'

export default {
  COMPONENTS: {
    HEADER,
    APP_INTEGRATOR_DETAILS,
  },
  CONNECTED_APPLICATIONS: 'Aplicativos conectados',
  OTHER_APPLICATIONS: 'Outros aplicativos',
  SYNCHRONIZATION_DESCRIPTION:
    'O tempo de processamento pode variar de acordo com a quantidade de dados em sua conta.',
  LAST_UPDATE: 'Última atualização',
  CONNECTED_TITLE: 'Conectado',
  OBTAINING_TITLE: 'Obtendo informações',
  INFORMATION_OBTAINED_TITLE: 'Informações recuperadas',
  VALIDATING: 'Validação pendente',
  PENDING_VALIDATION: 'Validação pendente',
  PENDING_VALIDATION_DESCRIPTION:
    'Para concluir o processo de sincronização, você deve verificar e validar os dados obtidos.',
}
