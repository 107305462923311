import Constants from 'expo-constants'
import { useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import { parseToUrl } from '@common/utils'
import { setWebViewValues } from '@store/slices'
import { useAuth } from '@common/hooks'

const url = process.env.CFT_URL ?? Constants.expoConfig.extra.CFT_URL
const formToken =
  process.env.CFT_TOKEN_FORM ?? Constants.expoConfig.extra.CFT_TOKEN_FORM
const WEB_VIEW_CFT_MESSAGES = {
  SEND_CFT_PAYLOAD: 'SEND_CFT_PAYLOAD',
}

export const useCftForm = ({ values, setFieldValue, cropTypeId }) => {
  const { user } = useAuth()

  const navigation = useNavigation()
  const dispatch = useDispatch()

  const onCftMessage = (event) => {
    const { data: eventData } = event.nativeEvent || event || {}

    let message
    let data

    try {
      const parsedData = eventData ? JSON.parse(eventData) : {}

      message = parsedData.message
      data = parsedData.data
    } catch (error) {
      return
    }

    if (message === WEB_VIEW_CFT_MESSAGES.SEND_CFT_PAYLOAD) {
      setFieldValue('dataForCFT', data)

      navigation.goBack()
    }
  }

  const navigateToCftForm = () => {
    if (
      values?.cropId &&
      values?.farms?.length &&
      values?.company?.identifier
    ) {
      const farmId = values?.farms[0].farm
      const query = {
        farmId,
        cacheBusterReloaded: new Date().getTime(),
        formToken,
        taxId: values.company.identifier,
        cropId: values.cropId,
        cropType: cropTypeId,
        fromWebView: true,
        lng: user.config.languageSelected,
      }

      const queryUrl = parseToUrl(query)

      const dataToSend = {
        url: `${url}?${queryUrl}`,
        onMessage: onCftMessage,
      }

      dispatch(setWebViewValues(dataToSend))

      navigation.navigate('WebView')
    }
  }

  return { navigateToCftForm }
}

export default useCftForm
