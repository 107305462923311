import { enumUnitMeasureSystem } from '@common/utils/enumUnitMeasureSystem'
import { enumUnitArea } from '@common/utils/enumUnitArea'

/**
 * Enum for unit area.
 * @readonly
 * @enum {string}
 */
export const getUnitAreaByUnitMeasureSystem = (unitMeasureSystem) => {
  switch (unitMeasureSystem) {
    case enumUnitMeasureSystem.METRIC:
      return enumUnitArea.HECTARE

    case enumUnitMeasureSystem.IMPERIAL:
      return enumUnitArea.ACRE
  }
}
