import { useContext } from 'react'
import { Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { t } from 'i18n-js'

import useOfflineCrops from '@offline/queries/crops'
import useOfflineDrafts from '@offline/queries/drafts'
import {
  syncDraft as syncDraftFirebase,
  getDraftsByCropId,
  getDraftsVerificationByCropIdAndIdentifier,
  insertFileToStorage,
} from '@services/firebase'
import { urlToBlob } from '@utils/files'
import useNetwork from '@utils/network'
import { ROL_VERIFIER } from '@constants/roles'
import activityTypes from '@constants/activityTypes'
import { lotsActions } from '@store/actions'
import { getObjectId } from '@utils/common'
import { CommonContext } from '@contextState/common'

export const useSyncDrafts = () => {
  const { doRequest } = useNetwork()
  const dispatch = useDispatch()
  const { selectAllCrops } = useOfflineCrops()
  const {
    findDraftsByUnsynchronizedOffline,
    deleteAllDraftOffline,
    insertDraftOffline,
  } = useOfflineDrafts()
  const { setIsDraftsSynchronizing } = useContext(CommonContext)

  const syncDrafts = async (user, config, displayToggleModalNotification) => {
    if (Platform.OS === 'web') {
      return true
    }

    const [errorFindDraftsOffline, unsynchronizedDrafts] =
      await findDraftsByUnsynchronizedOffline()

    if (errorFindDraftsOffline) {
      alert(errorFindDraftsOffline)

      return
    }

    if (!unsynchronizedDrafts.length) {
      return true
    }

    setIsDraftsSynchronizing(true)

    const allLotsUnsynchronized = []
    const activitiesSearched = []

    for (const unsynchronizedDraft of unsynchronizedDrafts) {
      const crop = await fetchCrop({
        cropId: unsynchronizedDraft.crop,
        companyId: unsynchronizedDraft.company._id,
      })

      const activityHasLotsRemoved = hasLotsRemoved(
        crop.establishments,
        unsynchronizedDraft
      )

      if (activityHasLotsRemoved) {
        displayToggleModalNotification({
          text: t('VIEWS').OFFLINE_QUEUE.INVALID_LOTS,
          duration: 15000,
        })

        continue
      }

      if (unsynchronizedDraft.activity) {
        let activity = activitiesSearched.find(
          (element) => element._id === unsynchronizedDraft.activity
        )

        if (!activity) {
          const response = await doRequest({
            method: 'GET',
            url: `activities/${unsynchronizedDraft.activity}`,
          })

          if (!response.data) {
            continue
          }

          activity = response.data

          activitiesSearched.push(activity)
        }

        const percentOfAchievement = activity.achievements.reduce(
          (accumulator, currentElement) => {
            return accumulator + currentElement.percent
          },
          0
        )

        if (percentOfAchievement >= 100) {
          continue
        }
      }

      await Promise.all(
        unsynchronizedDraft.evidences.map(async (evidence) => {
          evidence.date = new Date(evidence.date)

          if (!evidence.persisted) {
            const evidenceFile = await saveFileInStorage({
              ...evidence,
              ...evidence?.file,
            })

            if (evidenceFile) {
              evidence.file = evidenceFile
            }
          }

          evidence.persisted = true
        })
      )

      const lotsVerified = []

      const lotsSelectedQuantity = unsynchronizedDraft.lots.length

      if (unsynchronizedDraft.tag === activityTypes.ACT_VERIFICATION.key) {
        for (const lot of unsynchronizedDraft.lots) {
          for (const verificationType of crop.verificationTypes) {
            if (
              unsynchronizedDraft.verificationType === verificationType._id &&
              verificationType.lots.find(
                (element) => lot._id === element._id && element.isVerified
              )
            ) {
              lotsVerified.push(lot)
            }
          }
        }

        unsynchronizedDraft.lots = unsynchronizedDraft.lots.filter(
          (element) =>
            !lotsVerified.find((subElement) => subElement._id === element._id)
        )

        const surface = unsynchronizedDraft.lots.reduce(
          (accumulator, { surface }) => accumulator + surface,
          0
        )

        unsynchronizedDraft.surface = surface !== 0 ? surface : undefined
      }

      const user = {
        _id: unsynchronizedDraft.updatedByUserId,
        name: unsynchronizedDraft.updatedByUsername,
      }

      unsynchronizedDraft.isSynchronized = true

      unsynchronizedDraft.establishments = undefined

      unsynchronizedDraft.createdAt = unsynchronizedDraft.createdAt
        ? new Date(unsynchronizedDraft.createdAt)
        : undefined

      unsynchronizedDraft.updatedAt = unsynchronizedDraft.updatedAt
        ? new Date(unsynchronizedDraft.updatedAt)
        : undefined

      unsynchronizedDraft.deletedAt = unsynchronizedDraft.deletedAt
        ? new Date(unsynchronizedDraft.deletedAt)
        : undefined

      unsynchronizedDraft.dateAchievement = unsynchronizedDraft.dateAchievement
        ? new Date(unsynchronizedDraft.dateAchievement)
        : undefined

      unsynchronizedDraft.dateHarvest = unsynchronizedDraft.dateHarvest
        ? new Date(unsynchronizedDraft.dateHarvest)
        : undefined

      unsynchronizedDraft.dateObservation = unsynchronizedDraft.dateObservation
        ? new Date(unsynchronizedDraft.dateObservation)
        : undefined

      unsynchronizedDraft.dateEstimatedHarvest =
        unsynchronizedDraft.dateEstimatedHarvest
          ? new Date(unsynchronizedDraft.dateEstimatedHarvest)
          : undefined

      unsynchronizedDraft.observations = unsynchronizedDraft.observations
        ?.length
        ? unsynchronizedDraft.observations.map((element) => ({
            ...element,
            createdAt: new Date(element.createdAt),
          }))
        : undefined

      const draft = await syncDraftFirebase(unsynchronizedDraft, user)

      if (lotsVerified.length) {
        const lotsUnsynchronized = {
          draft: draft.id,
          verificationType: crop.verificationTypes.find(
            (element) => element._id === draft.verificationType
          ),
          lotsVerified,
          lotsSelectedQuantity,
        }

        dispatch(lotsActions.setLotsUnsynchronized(lotsUnsynchronized))

        allLotsUnsynchronized.push(lotsUnsynchronized)
      }
    }

    await deleteAllDraftOffline()

    const offlineCrops = await selectAllCrops()

    for (const offlineCrop of offlineCrops) {
      const currentCollaborator = offlineCrop.members?.find(
        (element) => element.user._id === user?._id
      )

      let drafts

      if (currentCollaborator?.type === ROL_VERIFIER) {
        drafts = await getDraftsVerificationByCropIdAndIdentifier(
          offlineCrop._id,
          config.companySelected?.identifier
        )
      } else {
        const requestData = {
          method: 'GET',
          url: 'activities-drafts',
          params: {
            cropId: offlineCrop._id,
          },
          displayAlert: false,
        }

        const { data } = await doRequest(requestData)

        drafts = data.data
      }

      for (const draft of drafts) {
        draft.isSynchronized = true
        draft._id = draft.id

        const user = {
          _id: draft.createdByUserId,
          name: draft.createdByUsername,
        }

        await insertDraftOffline(draft, user)
      }
    }

    setIsDraftsSynchronizing(false)

    if (allLotsUnsynchronized.length) {
      let delay = 200

      for (const element of allLotsUnsynchronized) {
        setTimeout(
          () =>
            displayToggleModalNotification({
              text: t('CONTEXT_STATE.CONNECTION.TEXT_2', {
                verificationType: element.verificationType?.codeLabel,
                lotsVerifiedQuantity: element.lotsVerified.length,
                lotsQuantity: element.lotsSelectedQuantity,
              }),
              duration: 15000,
            }),
          delay
        )

        delay += 16000
      }
    }

    return true
  }

  const fetchCrop = async ({ cropId, companyId }) => {
    const response = await doRequest({
      method: 'GET',
      url: `crops/${cropId}`,
      version: 'v2',
      params: {
        companyId,
      },
      displayAlert: false,
    })

    return response.data
  }

  const saveFileInStorage = async ({ fileNamePath, uri, path }) => {
    const blobFile = await urlToBlob({
      url: uri || path,
    })

    if (!blobFile) {
      return null
    }

    const evidenceUrl = await insertFileToStorage({
      blobFile,
      fileName: fileNamePath,
    })

    return evidenceUrl
  }

  /**
   *
   * @param {array | undefined} establishments
   * @param {object} unsynchronizedDraft
   *
   * @return {boolean}
   */
  const hasLotsRemoved = (establishments, unsynchronizedDraft) => {
    if (!establishments || !establishments.length) {
      return true
    }

    const lots = establishments.flatMap((establishment) => establishment.lots)

    let hasLotsRemovedFlag = false

    for (const lotActivity of unsynchronizedDraft.lots) {
      const lot = lots.find((lot) => lot._id === getObjectId(lotActivity))

      if (!lot) {
        hasLotsRemovedFlag = true

        break
      }
    }

    return hasLotsRemovedFlag
  }

  return {
    syncDrafts,
  }
}
