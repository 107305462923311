export default {
  VALIDATED: 'VALIDADO',
  TEXT_1: 'Área realizada: %{surface} %{areaUnit}',
  TEXT_2: 'Tipo de %{activityType}: %{subTypeActivity}',
  TEXT_3: 'EIQ realizada',
  TEXT_4: 'INSUMOS',
  TEXT_5: 'VER DETALHE',
  TEXT_6: 'Rascunho',
  TEXT_7: 'Rascunho: Version',
}
