import React, { useCallback, useContext, useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { View, StyleSheet, Platform } from 'react-native'
import { Text } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import { blackHighEmphasis } from '@styles/palette'

import { LanguageContext } from '@contextState/language'
import { ORDER_MENU_ENUM } from '@modules/common/utils'
import { useSelector } from 'react-redux'
import { MenuDefault } from '@common/components/menu/MenuDefault'
import { ItemMenuDefault } from '@common/components/menu/ItemMenuDefault'

export const OrderMenu = ({
  orderMenuOptions,
  orderSelectedOption,
  handleOrderChange,
}) => {
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { t } = useContext(LanguageContext)

  const handleSelectedOption = (option) => {
    handleOrderChange(option)
  }

  useEffect(() => {
    if (!isConnected) {
      const option = orderMenuOptions.find(
        (option) => option.key === ORDER_MENU_ENUM.ENABLED_OFFLINE
      )

      handleOrderChange(option)
    }
  }, [isConnected])

  const hiddenMenu = (option) => {
    return (Platform.OS === 'web' &&
      option.key === ORDER_MENU_ENUM.ENABLED_OFFLINE) ||
      (!isConnected && !option.activeOffline)
      ? false
      : true
  }

  return (
    <View style={styles.container}>
      <Text style={styles.textFilter} numberOfLines={1} ellipsizeMode='tail'>
        {t('VIEWS').CROP_LIST.COMPONENTS.ORDER_MENU.TEXT_1}:{' '}
        {orderSelectedOption?.title(t)}
      </Text>

      <MenuDefault
        anchor={
          <CommonIcon
            name={'SORT'}
            size={25}
            color={blackHighEmphasis}
            style={styles.anchor}
          />
        }
      >
        {orderMenuOptions.filter(hiddenMenu).map((option, index) => (
          <ItemMenuDefault
            key={index}
            title={option.title(t)}
            onPress={() => handleSelectedOption(option)}
            disabled={!isConnected && !option.activeOffline}
          />
        ))}
      </MenuDefault>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    paddingRight: 0,
  },
  textFilter: {
    flex: 1,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    color: blackHighEmphasis,
  },
  anchor: {
    marginRight: 20,
  },
})

OrderMenu.propTypes = {
  handleOrderChange: PropTypes.func.isRequired,
  orderMenuOptions: PropTypes.array.isRequired,
  orderSelectedOption: PropTypes.object,
}
