export default {
  TEXT_1: 'Processing landscape sustainability...',
  TEXT_2: 'Sustainability Report',
  TEXT_3: '%{value} %{areaUnit} with no overlapping',
  TEXT_4: '%{value} %{areaUnit} with overlapping',
  TEXT_5: 'Suitable',
  TEXT_6: 'ha',
  TEXT_7: 'Detail',
  TEXT_8: 'Native forest management',
  TEXT_9: '%{value}% de solapamiento',
  TEXT_10: 'CATEGORY %{value}',
  TEXT_11: 'Valuable grassland areas',
  TEXT_12: 'Bird conservation areas',
  TEXT_13: 'Deforestation areas',
  TEXT_14: 'Wetlands and peatlands',
  TEXT_15: 'Protected areas',
  TEXT_16: 'High biodiversity areas',
  TEXT_17: 'Nature reserves',
  TEXT_18:
    '*Total porcentages take into account the overlapping between layers.',
}
