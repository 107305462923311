import getDatabase from './getDataBase'

export const CREATE_TABLE_QUERY__EVIDENCE_DRAFTS =
  'CREATE TABLE IF NOT EXISTS evidence_drafts (id integer primary key not null, achievementId text, fileName text, fileType text, fileUri text, date text, description text, location text, meta text, persisted bool, settings text);'
const DROP_QUERY = 'DROP TABLE IF EXISTS evidence_drafts;'
const INSERT_QUERY =
  'INSERT INTO evidence_drafts (achievementId, fileName, fileType, fileUri, date, description, location, meta, persisted, settings) values (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)'
const SELECT_QUERY = 'SELECT * FROM evidence_drafts'
const DELETE_QUERY = 'DELETE FROM evidence_drafts'

const useOfflineEvidenceDrafts = () => {
  const db = getDatabase('db.offlinedata')
  const initOfflineEvidenceDrafts = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          CREATE_TABLE_QUERY__EVIDENCE_DRAFTS,
          [],
          () => resolve(),
          (_, err) => {
            console.warn(`ERROR Create Table EvidenceDrafts`)
            console.warn(err)
          }
        )
      })
    })

    return promise
  }

  const dropEvidenceDraftsTable = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve()
      }
      db.transaction((tx) => {
        tx.executeSql(
          DROP_QUERY,
          [],
          () => resolve(),
          (_, err) => {
            console.warn(`ERROR Drop Table EvidenceDrafts`)
            console.warn(err)
          }
        )
      })
    })

    return promise
  }

  const findEvidenceDrafts = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve([])
      }
      db.transaction((tx) => {
        tx.executeSql(
          SELECT_QUERY,
          [],
          (tx, { rows }) => {
            const results = rows._array ?? Array.from(rows)

            resolve(
              results.map((element) => ({
                achievementId: element.achievementId,
                fileName: element.fileName,
                fileType: element.fileType,
                fileUri: element.fileUri,
                date: element.date,
                description: element.description,
                location: element.location ? JSON.parse(element.location) : '',
                meta: element.meta ? JSON.parse(element.meta) : '',
                persisted: element.persisted,
                settings: element.settings ? JSON.parse(element.settings) : '',
              }))
            )
          },
          (_, err) => {
            console.warn(`ERROR Find EvidenceDrafts`)
            console.warn(err)
          }
        )
      })
    })

    return promise
  }

  const saveEvidenceDraftsOffLine = async ({
    achievementId,
    fileName,
    fileType,
    fileUri,
    date,
    description,
    location,
    meta,
    persisted,
    settings,
  }) => {
    const promise = new Promise((resolve) => {
      const dataToInsert = [
        achievementId,
        fileName,
        fileType,
        fileUri,
        date,
        description,
        location,
        meta,
        persisted,
        settings,
      ]

      db.transaction((tx) => {
        tx.executeSql(
          INSERT_QUERY,
          dataToInsert,
          () => resolve(true),
          (_, err) => {
            console.warn(
              `ERROR Save EvidenceDrafts > achievementId: ${achievementId}`
            )
            console.warn(err)
          }
        )
      })
    })

    return promise
  }

  const deleteEvidenceDrafts = async () => {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve(null)
      }
      db.transaction((tx) => {
        tx.executeSql(
          DELETE_QUERY,
          [],
          () => resolve(true),
          (_, err) => {
            console.warn(`ERROR Delete EvidenceDrafts`)
            console.warn(err)
          }
        )
      })
    })

    return promise
  }

  return {
    initOfflineEvidenceDrafts,
    dropEvidenceDraftsTable,
    findEvidenceDrafts,
    saveEvidenceDraftsOffLine,
    deleteEvidenceDrafts,
  }
}

export default useOfflineEvidenceDrafts
