import { PropTypes } from 'prop-types'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { blackHighEmphasis } from '@styles/palette'

const ItemList = ({ title, description }) => {
  return (
    <View style={styles.containerItem}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subtitle}>{description}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  containerItem: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
    lineHeight: 16,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
})

ItemList.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default ItemList
