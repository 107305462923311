export default {
  TEXT_1: 'Aplicar licencia',
  TEXT_2:
    'Por favor, seleccionar los lotes asociados a la licencia del ecosistema.',
  TEXT_3:
    'Se actualizó la superficie disponible.\n %{hasSelected} %{areaUnit} seleccionadas. Revisa que queden seleccionadas entre %{hasMinAvailable} y %{hasAvailable} %{areaUnit}',
  TEXT_4: 'ha',
  TEXT_5: 'Todos los establecimientos',
  TEXT_6: 'Lotes ya aplicados a este ecosistema:',
  TEXT_7: 'Estos lotes ya han sido aplicados a este ecosistema.',
  TEXT_8: 'lote(s)',
  TEXT_9: 'Aplicar a %{lotsQuantity}lote(s)',
  TEXT_10: '¡Felicitaciones!',
  TEXT_11: 'El ecosistema se aplicó a',
  TEXT_12: 'los lotes seleccionados',
  TEXT_13: 'ASOCIAR OTRO ECOSISTEMA',
  TEXT_14: 'VOLVER A CULTIVOS',
  TEXT_15: 'Lo sentimos',
  TEXT_16: 'Se agotó la superficie\ndisponible para este ecosistema.',
  TEXT_17: '%{overlappingPercentage}% solapadmiento',
  TEXT_18:
    'Lote no disponible para esta ecosistema; supera la superficie predefinida.',
  FIELD_1: {
    NAME: 'Buscar por establecimiento...',
  },
  TEXT_19: 'SELECCIONAR EMPRESA',
  TEXT_20:
    'Superó la superficie disponible.\n %{hasSelected} %{areaUnit} seleccionadas sobre %{hasAvailable} disponibles',
  MODAL_UNASSOCIATE: {
    TITLE: 'Desasociar lote',
    INFO: 'Este lote ya no estará asociado a el ecosistema seleccionado.',
    CONFIRM: 'Desasociar',
    CANCEL: 'Cancelar',
  },
  CAN_APPLY_LICENSE:
    '%{hasSelected} %{areaUnit} seleccionadas sobre un rango de licencia del ecosistema de %{hasMinAvailable} a %{hasAvailable} %{areaUnit}.',
  INVALID_APPLY_MINIMUM_ALLOWED:
    '%{hasSelected} %{areaUnit} seleccionada.\n Mínima superficie asociado con el ecosistema de la licencia es %{hasMinAvailable} %{areaUnit}.',
  INVALID_APPLY_MAXIMUM_ALLOWED:
    '%{hasSelected} %{areaUnit} seleccionadas.\n Maxima superficie asociada con el ecosistema de la licencia es %{hasAvailable} %{areaUnit}.',
}
