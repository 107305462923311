import React from 'react'
import { TouchableRipple } from 'react-native-paper'

const ButtonRipple = ({
  children,
  onPress,
  style = {},
  testID = undefined,
}) => {
  return (
    <TouchableRipple onPress={onPress} style={style} testID={testID}>
      {children}
    </TouchableRipple>
  )
}

export default ButtonRipple
