export const validateAchievementUnplanned = async ({
  activityToDelete,
  isRealized,
  doRequest,
  dataToSend,
  values,
  pinToken,
}) => {
  const formData = new FormData()

  formData.append('data', JSON.stringify(dataToSend))

  values.files.forEach((element) => formData.append('files', element))

  let newActivityId

  if (!values.activityId && !isRealized) {
    if (activityToDelete) {
      await doRequest({
        method: 'DELETE',
        url: `activities/${activityToDelete}`,
      })
    }

    const responseActivity = await doRequest({
      headers: {
        'Content-Type': 'multipart/form-data',
        'validate-token-pin': `Bearer ${pinToken}`,
      },
      method: 'POST',
      url: 'activities',
      data: formData,
      version: 'v3',
    })

    newActivityId = responseActivity.data._id
  }

  await doRequest({
    headers: {
      'validate-token-pin': `Bearer ${pinToken}`,
    },
    method: 'PUT',
    url: `activities/${newActivityId ?? values.activityId}/crops/${
      values.cropId
    }/signed`,
    data: {
      draftId: values.draftId,
      dateSigned: new Date(),
    },
  })

  return true
}
