export const workOrderShareMessage = (t, activity) => {
  const title = t(
    'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.WORK_ORDER.SHARE.TITLE'
  )

  const message = `${t(
    'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.WORK_ORDER.SHARE.I_SEND_THE_FOLLOWING_WORK_ORDE'
  )}
${t('VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.WORK_ORDER.SHARE.LABOR', {
  labor: activity.labor,
})}
${t('VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.WORK_ORDER.SHARE.CROP', {
  crop: activity.crop,
})}
${t(
  'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.WORK_ORDER.SHARE.TOTAL_PLANNED_AREA',
  { total_planned_area: activity.total_planned_area }
)}
${t(
  'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.WORK_ORDER.SHARE.ESTIMATED_START',
  { estimated_start: activity.estimated_start }
)}
${t(
  'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.COMPONENTS.WORK_ORDER.SHARE.FOR_MORE_DETAILS',
  { url: activity.url }
)}`

  return {
    title,
    message,
  }
}
