export default {
  TEXT_1: 'Atualizado com sucesso',
  TEXT_2: 'Erro interno',
  TEXT_3: 'Adicionar documentação',
  FIELD_1: {
    LABEL: 'Nome',
    PLACEHOLDER: 'Nome',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_2: {
    LABEL: 'Conceito',
    PLACEHOLDER: 'Conceito',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_3: {
    LABEL: 'Data de realização',
    PLACEHOLDER: 'Data de realização',
  },
  TEXT_4: 'selecionar documento',
  TEXT_5: 'Cancelar',
  TEXT_6: 'AGREGAR',
  FIELD_4: {
    LABEL: 'País',
  },
  TEXT_7: 'CNPJ:',
  FIELD_5: {
    LABEL: 'CNPJ',
  },
  FIELD_6: {
    LABEL: 'Razão social',
    PLACEHOLDER: 'Razão social',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_7: {
    LABEL: 'Personalidade Jurídica',
    OPTION_1: 'Física',
    OPTION_2: 'Jurídica',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_8: {
    LABEL: 'Endereço',
    PLACEHOLDER: 'Endereço',
    VALIDATE: 'Campo obrigatório',
  },
  FIELD_9: {
    LABEL: 'Andar / Apartamento / Escritório',
    PLACEHOLDER: 'Andar / Apartamento / Escritório',
  },
  TEXT_8: 'Adicionar documentação',
  TEXT_9: 'Salvar',
}
