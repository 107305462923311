export default {
  TEXT_1: 'El archivo no tiene formato KMZ',
  TEXT_2: 'Superficie',
  TEXT_3: 'ha',
  TEXT_4: 'No hay lotes cargados aún.',
  TEXT_5: 'Los lotes se cargan subiendo',
  TEXT_6: 'archivos .KMZ',
  TEXT_7: 'kmz',
  TEXT_8: 'Nombre del campo',
  TEXT_9: 'Ingresa el nombre del campo',
  TEXT_10: 'SUBIR LOTES (KMZ)',
  TEXT_11: 'Guardar',
}
