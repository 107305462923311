import { SafeAreaView, StyleSheet } from 'react-native'
import React from 'react'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { WebViewRender } from '@modules/webView/components'
import { useQR } from '@modules/traders/hooks/useQR'

const QR = () => {
  const { url, isReady }: any = useQR()
  

  return (
    <SafeAreaView style={styles.container}>
      {!isReady ? <LoaderCenterContainer /> : <WebViewRender url={url} />}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default QR
