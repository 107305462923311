export default {
  WIZARD_1: {
    WELCOME: 'Welcome to ucrop.it',
    PARAGRAPH:
      'Collaborative farming platform where sustainability is + profitability.',
  },
  WIZARD_2: {
    TRACE_HISTORY_CROPS: 'Trace your crop story',
    PARAGRAPH:
      'Access and manage environmental indicators.\nConnect with companies that value and reward good practices.',
  },
  WIZARD_3: {
    DIGITAL_BACKUP: 'Digital backup',
    ITEMS:
      '+ Digital signatures\n+ Field verification of records\n+ Traceability of activities\n+ Detailed crop history\n+ Crypto-confidential documentation',
  },
}
