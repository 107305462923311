import React, { useState, createContext } from 'react'

export const SelectorContext = createContext({})

export const SelectorProvider = ({ children }) => {
  const [lotSelector, setLotSelector] = useState([])
  const [lotSelectorData, setLotSelectorData] = useState([])
  const [lotSelectorTotalSurface, setSelectorTotalSurface] = useState(0)
  const [collaboratorSelector, setCollaboratorSelector] = useState([])
  const [supplySelector, setSupplySelector] = useState([])
  const [destinationSelector, setDestinationSelector] = useState([])
  const [establishmentsSelector, setEstablishmentsSelector] = useState([])
  const [jsonWkt, setJsonWkt] = useState({})

  function clearSelectors() {
    setLotSelector([])
    setCollaboratorSelector([])
    setSupplySelector([])
    setDestinationSelector([])
    setLotSelectorData([])
    setEstablishmentsSelector([])
    setSelectorTotalSurface(0)
    setJsonWkt({})
  }

  return (
    <SelectorContext.Provider
      value={{
        lotSelector,
        setLotSelector,
        lotSelectorTotalSurface,
        setSelectorTotalSurface,
        collaboratorSelector,
        setCollaboratorSelector,
        supplySelector,
        setSupplySelector,
        destinationSelector,
        setDestinationSelector,
        clearSelectors,
        lotSelectorData,
        setLotSelectorData,
        establishmentsSelector,
        setEstablishmentsSelector,
        jsonWkt,
        setJsonWkt,
      }}
    >
      {children}
    </SelectorContext.Provider>
  )
}
