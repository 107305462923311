import * as yup from 'yup'

export const irrigationValidationSchema = (t: any) =>
  yup.object().shape({
    subTypeActivity: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    lots: yup
      .object()
      .shape({
        value: yup
          .array()
          .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
          .of(
            yup.object().shape({
              _id: yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
              surface: yup.number().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
              tag: yup.string(),
              wktFormat: yup.string(),
              area: yup.string(),
            })
          )
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    surface: yup.number().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
    dateAchievement: yup
      .object()
      .shape({
        value: yup
          .date()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    irrigationWaterSource: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    pumpingDepth: yup
      .string()
      .test(
        'is-decimal',
        t('ERRORS.FORM_ERRORS.INVALID_FORMAT'),
        (value: any) => {
          if (value != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(value)
          }

          return true
        }
      )
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    waterConsumption: yup
      .string()
      .test(
        'is-decimal',
        t('ERRORS.FORM_ERRORS.INVALID_FORMAT'),
        (value: any) => {
          if (value != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(value)
          }

          return true
        }
      )
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    horizontalWateringDepth: yup
      .string()
      .test(
        'is-decimal',
        t('ERRORS.FORM_ERRORS.INVALID_FORMAT'),
        (value: any) => {
          if (value != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(value)
          }

          return true
        }
      )
      .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    irrigationPowerSource: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    evidences: yup.array(),
    signers: yup
      .array()
      .min(2, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 2 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
  })
