import React, { useContext } from 'react'
import { FormikProvider } from 'formik'

import { LanguageContext } from '@contextState/language'

import { InputSelectDefault } from '@common/components'
import InputText from '@components/inputs/InputText'

interface GenericSupplyFormProps {
  formik: any
  options: any
}

export const GenericSupplyForm: React.FC<GenericSupplyFormProps> = ({
  formik,
  options,
}) => {
  const { t } = useContext(LanguageContext)

  return (
    <FormikProvider value={formik}>
      <InputText
        label={t('VIEWS.SUPPLIES.N_NITROGEN_PERCENT')}
        placeholder={t('VIEWS.SUPPLIES.N_NITROGEN_PERCENT')}
        value={formik.values.chemicalCompositions.N}
        onChangeText={(value: any) =>
          formik.setFieldValue('chemicalCompositions.N', value?.trimStart())
        }
        onPress={() => {
          formik.setFieldTouched('chemicalCompositions.N')
        }}
        touched={formik.touched.chemicalCompositions?.N}
        error={formik.errors.chemicalCompositions?.N}
      />

      <InputText
        label={t('VIEWS.SUPPLIES.P_PHOSPHORUS_PERCENT')}
        placeholder={t('VIEWS.SUPPLIES.P_PHOSPHORUS_PERCENT')}
        value={formik.values.chemicalCompositions.P}
        onChangeText={(value: any) =>
          formik.setFieldValue('chemicalCompositions.P', value?.trimStart())
        }
        onPress={() => {
          formik.setFieldTouched('chemicalCompositions.P')
        }}
        touched={formik.touched.chemicalCompositions?.P}
        error={formik.errors.chemicalCompositions?.P}
      />

      <InputText
        label={t('VIEWS.SUPPLIES.K_POTASSIUM_PERCENT')}
        placeholder={t('VIEWS.SUPPLIES.K_POTASSIUM_PERCENT')}
        value={formik.values.chemicalCompositions.K}
        onChangeText={(value: any) =>
          formik.setFieldValue('chemicalCompositions.K', value?.trimStart())
        }
        onPress={() => {
          formik.setFieldTouched('chemicalCompositions.K')
        }}
        touched={formik.touched.chemicalCompositions?.K}
        error={formik.errors.chemicalCompositions?.K}
      />

      <InputText
        label={t('VIEWS.SUPPLIES.CA_CALCIUM_PERCENT')}
        placeholder={t('VIEWS.SUPPLIES.CA_CALCIUM_PERCENT')}
        value={formik.values.chemicalCompositions.CA}
        onChangeText={(value: any) =>
          formik.setFieldValue('chemicalCompositions.CA', value?.trimStart())
        }
        onPress={() => {
          formik.setFieldTouched('chemicalCompositions.CA')
        }}
        touched={formik.touched.chemicalCompositions?.CA}
        error={formik.errors.chemicalCompositions?.CA}
      />

      <InputText
        label={t('VIEWS.SUPPLIES.S_SULFUR_PERCENT')}
        placeholder={t('VIEWS.SUPPLIES.S_SULFUR_PERCENT')}
        value={formik.values.chemicalCompositions.S}
        onChangeText={(value: any) =>
          formik.setFieldValue('chemicalCompositions.S', value?.trimStart())
        }
        onPress={() => {
          formik.setFieldTouched('chemicalCompositions.S')
        }}
        touched={formik.touched.chemicalCompositions?.S}
        error={formik.errors.chemicalCompositions?.S}
      />

      <InputSelectDefault
        id='unit'
        containerStyle={{}}
        onChangeText={(value: any) => {
          formik.setFieldValue('unit', value.id)
        }}
        onBlur={() => setTimeout(() => formik.setFieldTouched('unit'))}
        value={options[formik.values.unit]}
        label={t('VIEWS.SUPPLIES.UNIT_OF_MEASURE')}
        placeholder={t('VIEWS.SUPPLIES.UNIT_OF_MEASURE')}
        options={options}
        disabled={Boolean(false)}
        touched={formik.touched.unit}
        error={formik.errors.unit}
      />
    </FormikProvider>
  )
}
