import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { Card, Text, Menu } from 'react-native-paper'

import { PropTypes } from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { GET_ROLES } from '@constants/roles'
import { MENU_COLLABORATOR_NEW } from '@constants/menuCollaborator'
import { CommonIcon } from '@modules/common/components'
import { MenuDefault } from '@common/components/menu/MenuDefault'

function CollaboratorCard({
  title,
  subtitle,
  pendingValidation,
  collaboratorType,
  menuItems,
  onPress,
  open,
  handleOpenMenu,
  disabled,
  status,
  actionCard,
  companyName,
  tag,
  right,
  currentTitle = '',
  ...props
}) {
  const { t } = useContext(LanguageContext)

  const getStyleItem = () =>
    GET_ROLES[collaboratorType](t) ? styles.menuItemTitle : styles.menuItem

  const getTitleIcon = () => {
    let tittle = ''
    switch (true) {
      case pendingValidation <= 0:
        tittle = `${t('VIEWS').CROP_COLLABORATOR.TEXT_30}`
        break
      case pendingValidation === 1:
        tittle = `${pendingValidation} ${t('VIEWS').CROP_COLLABORATOR.TEXT_16}`
        break
      case pendingValidation > 1:
        tittle = `${pendingValidation} ${t('VIEWS').CROP_COLLABORATOR.TEXT_17}`
        break
    }

    return tittle
  }

  const getStyleCard = () =>
    status && !disabled ? styles.cardActive : styles.cardDisable

  const menuItem = (props) => (
    <>
      {menuItems && !disabled && status && (
        <MenuDefault disabled={disabled}>{menuItems}</MenuDefault>
      )}
      {!disabled && status && right}
    </>
  )

  return (
    <Card
      onPress={status && !disabled && onPress}
      disabled={disabled}
      style={[styles.card, props.style]}
    >
      <Text style={styles.roleText}>
        {(tag && MENU_COLLABORATOR_NEW[tag](t)) || tag}
      </Text>
      {!status && (
        <Text style={styles.roleTextDisabled}>
          {t('VIEWS').CROP_COLLABORATOR.TEXT_15}
        </Text>
      )}
      {title && subtitle && (
        <Card.Title
          title={`${title} ${currentTitle}`}
          style={styles.titleFrame}
          rightStyle={getStyleItem()}
          right={() => menuItem(props)}
          titleStyle={[getStyleCard(), props.titleStyle]}
          subtitleStyle={styles.cardSubtitle}
          subtitleNumberOfLines={3}
          subtitle={`${subtitle} ${status ? '- ' + companyName : ''}`}
        />
      )}
      {status && (
        <View>
          <Text style={styles.pendingSignatures}>
            <CommonIcon
              name={'SIGN'}
              size={20}
              color='#FF7043'
              style={styles.iconPending}
            />

            {getTitleIcon()}
          </Text>
        </View>
      )}

      {actionCard}
    </Card>
  )
}

const styles = StyleSheet.create({
  cardStyles: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  card: {
    marginBottom: 8,
    padding: 12,
  },
  roleText: {
    backgroundColor: '#edf7ee',
    color: '#388E3C',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 16,
    letterSpacing: 1.5,
    textAlign: 'left',
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  roleTextDisabled: {
    backgroundColor: '#FBE9E7',
    color: 'rgba(51, 51, 51, 0.7)',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 16,
    letterSpacing: 1.5,
    textAlign: 'left',
    alignSelf: 'flex-start',
  },
  title: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 0.15,
    textAlign: 'left',
  },
  menuItemTitle: {
    marginTop: -20,
    marginRight: -10,
  },
  menuItem: {
    marginTop: -20,
    marginRight: -10,
  },
  titleFrame: {
    paddingLeft: 0,
    minHeight: 20,
  },
  subtitle: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.25,
    textAlign: 'left',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  pendingSignatures: {
    paddingTop: 5,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    textAlign: 'left',
    color: '#FF7043',
  },
  getLoading: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 2,
  },
  iconButton: {
    fontSize: 20,
  },
  cardDisable: {
    opacity: 0.5,
    fontSize: 16,
  },
  cardActive: {
    opacity: 1,
    fontSize: 16,
  },
  cardSubtitle: {
    fontSize: 14,
  },
  iconPending: {
    marginRight: 16,
  },
})
CollaboratorCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  pendingValidation: PropTypes.number,
  collaboratorType: PropTypes.string,
  menuItems: PropTypes.object,
  onPress: PropTypes.func,
  open: PropTypes.bool,
  handleOpenMenu: PropTypes.func,
  disabled: PropTypes.bool,
  downloaded: PropTypes.bool,
  status: PropTypes.any,
  actionCard: PropTypes.any,
  tag: PropTypes.string,
  loading: PropTypes.bool,
  right: PropTypes.object,
  currentTitle: PropTypes.string,
}

export default CollaboratorCard
