export const getFormikInitialValues = () => {
  return {
    country: {
      label: '',
      value: '',
    },
    identifier: '',
    name: '',
    typePerson: {
      label: '',
      value: '',
    },
    address: '',
    specificAddress: '',
    files: [],
    users: [],
  }
}
