export default {
  TEXT_1: 'Disponible solo con conexión',
  TEXT_2: '¿Estas seguro de realizar esta acción?',
  TEXT_3:
    'Esta acción es permanente, podes perder datos de operación. (Puede demorar unos segundos)',
  TEXT_4: 'Cancelar',
  TEXT_5: 'Limpiando...',
  TEXT_6: 'Limpiar',
  TEXT_7: 'Visualización de tareas a realizar con conexión',
  TEXT_8: 'Cola de sincronización',
  TEXT_9: 'Esta opción eliminara todos los datos sin conexión',
  TEXT_10: 'Limpiar datos offline',
  TEXT_11: 'Esta opción eliminara todos los datos sin conexión',
}
