export default {
  TEXT_1: 'Selected field',
  TEXT_2: 'Required field',
  TEXT_3: 'Select fields',
  TEXT_4: 'Observation date',
  TEXT_5: 'Yield unit',
  TEXT_6: 'Yield estimation',
  TEXT_7: 'Observation',
  TEXT_8: 'Harvest estimation',
}
