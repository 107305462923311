import React from 'react'
import { Svg, Path } from 'react-native-svg'
import { primary500 } from '@styles/palette'

const IconLoading = ({
  color = primary500,
  height = 72,
  width = 84.011,
  style,
}) => {
  return (
    <Svg
      style={style}
      xmlns='http://www.w3.org/2000/svg'
      height={height}
      width={width}
      viewBox='0 0 183 186'
      fill='none'
    >
      <Path
        d='M128.829 92.9443C126.755 80.0245 115.5 70.1061 102.004 70.1061C99.3859 70.1061 96.7762 70.4868 94.2773 71.2261C86.0721 60.6614 71.9017 55.1168 57.5078 57.2372C38.0761 60.0881 23.9234 75.8632 23.9942 93.5308C14.3835 97.8382 8 107.447 8 118.295C8 130.106 15.5846 140.156 26.1343 143.883C28.6225 144.762 30.8824 142.565 30.8824 139.927C30.8824 136.488 28.6673 133.396 25.863 131.407C21.7544 128.492 19.0671 123.704 19.0671 118.292C19.0671 110.915 24.0429 104.507 31.168 102.708C33.9148 102.015 35.6944 99.3544 35.2894 96.5499C33.346 83.1255 44.0346 70.4005 59.1169 68.1848C60.6309 67.9613 62.1383 67.855 63.6301 67.855C73.6083 67.855 82.7918 72.7157 87.3559 80.7372C88.819 83.307 92.0439 84.2721 94.6868 82.9263C96.9577 81.7642 99.4213 81.1733 102.007 81.1733C110.849 81.1733 118.072 88.3536 118.105 97.1763C118.116 99.7705 119.924 102.01 122.461 102.562C129.911 104.184 135.115 110.654 135.115 118.292C135.115 123.704 132.428 128.492 128.319 131.407C125.515 133.396 123.3 136.488 123.3 139.927C123.3 142.565 125.56 144.761 128.048 143.882C138.598 140.155 146.182 130.106 146.182 118.295C146.18 106.827 139.225 96.9218 128.829 92.9443Z'
        fill={color}
      />
      <Path
        d='M77.0899 102.599C74.6618 102.599 72.2447 102.841 69.8742 103.308C71.4833 101.103 71.0229 98.013 68.8294 96.3795C66.6182 94.7371 63.5017 95.1953 61.8615 97.3999L53.2734 108.934C52.439 110.056 52.1136 111.477 52.377 112.85C52.6426 114.222 53.4704 115.422 54.6613 116.152L66.9945 123.722C67.8068 124.222 68.7055 124.459 69.5953 124.459C71.2686 124.459 72.9043 123.614 73.8428 122.084C75.2816 119.74 74.5489 116.672 72.2049 115.234L70.8835 114.421C72.8933 113.932 74.9628 113.662 77.0877 113.662C91.5746 113.662 103.361 125.448 103.361 139.935C103.361 144.495 102.23 148.818 99.9945 152.791C97.6328 157.003 94.1533 160.483 89.9345 162.849C85.968 165.08 81.6452 166.211 77.0855 166.211C62.5986 166.211 50.8121 154.424 50.8121 139.938C50.8121 135.911 51.693 132.08 53.4306 128.547C54.7786 125.805 53.6497 122.489 50.9073 121.139C48.1648 119.787 44.8491 120.92 43.4989 123.662C41.0066 128.729 39.7427 134.205 39.7427 139.94C39.7427 160.529 56.494 177.28 77.0833 177.28C83.4757 177.28 89.7928 175.627 95.3507 172.502C101.342 169.139 106.283 164.199 109.641 158.214C112.77 152.649 114.424 146.332 114.424 139.94C114.43 119.35 97.6793 102.599 77.0899 102.599Z'
        fill={color}
      />
      <Path
        d='M165.997 75.574C167.911 76.125 168.19 71.5446 169.728 70.6931C171.403 69.3634 174.825 70.6081 174.992 68.5846C175.2 66.0552 171.167 67.4826 169.632 64.7102C168.098 61.9379 170.56 60.5755 168.169 59.8868C165.777 59.198 166.115 63.438 163.824 65.1083C161.533 66.7787 159.245 64.8253 158.867 67.0466C158.488 69.268 162.385 68.3188 163.885 70.3048C165.387 72.2908 164.085 75.023 165.997 75.574Z'
        fill={color}
      />
      <Path
        d='M126.728 63.7384C129.325 63.3846 127.225 57.721 128.625 55.8777C129.935 53.386 134.717 53.0656 133.843 50.5403C132.749 47.3839 128.651 51.2469 125.328 48.7248C122.007 46.2021 124.248 43.2527 121.003 43.6944C117.757 44.1365 120.417 49.0627 118.546 52.292C116.676 55.5213 112.882 54.3853 113.608 57.2613C114.333 60.1373 118.521 56.9228 121.383 58.5164C124.247 60.1095 124.132 64.0917 126.728 63.7384Z'
        fill={color}
      />
      <Path
        d='M160.674 8.57338C158.059 5.95441 153.48 13.1566 150.21 13.1566C146.287 13.8113 141.839 8.70433 139.746 11.8471C137.13 15.7756 144.978 17.085 144.978 22.9777C144.978 28.8704 139.746 28.8704 143.016 32.1441C146.287 35.4178 149.557 28.2156 154.788 27.5609C160.021 26.9062 161.983 32.1441 164.599 28.8704C167.215 25.5967 160.021 23.6325 159.367 19.0493C158.713 14.4661 163.291 11.1924 160.674 8.57338Z'
        fill={color}
      />
      <Path
        d='M65.5755 25.4736C66.9697 24.8537 64.4833 21.7854 64.8576 20.4595C65.0332 18.7584 67.6769 17.8148 66.6028 16.4373C65.2602 14.7155 63.8145 17.6771 61.3515 16.6854C58.8884 15.6936 59.4873 13.5723 57.7446 14.3471C56.0019 15.1219 58.638 17.6599 58.3128 19.8915C57.9875 22.1229 55.5735 22.0369 56.6424 23.6486C57.7114 25.2602 59.3559 22.674 61.3465 23.1802C63.3371 23.6863 64.1814 26.0935 65.5755 25.4736Z'
        fill={color}
      />
    </Svg>
  )
}

export default IconLoading
