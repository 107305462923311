export default {
  TEXT_1: 'Crop Protection',
  TEXT_2: 'ha',
  TEXT_3: 'Planned',
  TEXT_4: 'From',
  TEXT_5: 'Until',
  TEXT_6: 'Selected field',
  TEXT_7: 'Planned area',
  TEXT_8: 'Projected EIQ',
  TEXT_9: 'products',
  TEXT_10: 'execution',
  TEXT_11: 'What was planned',
  TEXT_12: 'Done',
  TEXT_13: 'Add execution',
  TEXT_14: 'Validation',
  TEXT_15: 'Generated EIQ',
  TEXT_16: 'Draft',
  TEXT_17: 'version',
  TEXT_18: 'Executed Area',
}
