export default {
  TEXT_1: 'Available only with internet connection',
  TEXT_2: 'Are you sure you want to do this?',
  TEXT_3:
    'This option is permanent, you may lose information. (It may take a few seconds)',
  TEXT_4: 'Cancel',
  TEXT_5: 'Cleaning...',
  TEXT_6: 'Clean up',
  TEXT_7: 'Viewing tasks to be completed when online',
  TEXT_8: 'Synchronization queue',
  TEXT_9: 'This option will delete all the offline information ',
  TEXT_10: 'Clear offline information',
  TEXT_11: 'This option will delete all the offline information ',
}
