import React from 'react'
import { StyleSheet, Animated, Text } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { onPressedColor, blackHighEmphasis } from '@styles/palette'

export const AccordionHeader = ({
  arrowAngle,
  onPress,
  otherParams: { farm },
}) => {
  return (
    <TouchableRipple
      onPress={onPress}
      rippleColor={onPressedColor}
      underlayColor={onPressedColor}
      style={styles.headerTextContainer}
    >
      <>
        <Text style={styles.headerText} numberOfLines={1}>
          {farm.name}
        </Text>

        <Animated.View
          style={[
            styles.headerAccordionRightIcon,
            { transform: [{ rotateZ: arrowAngle }] },
          ]}
        >
          <CommonIcon name='CHEVRON-DOWN' size={25} color={blackHighEmphasis} />
        </Animated.View>
      </>
    </TouchableRipple>
  )
}

const styles = StyleSheet.create({
  headerTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingVertical: 16,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .12)',
  },
  headerText: {
    flex: 1,
    fontWeight: '500',
    fontSize: 24,
    color: blackHighEmphasis,
  },
  headerAccordionRightIcon: {
    marginLeft: 10,
  },
})

AccordionHeader.propTypes = {
  arrowAngle: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
  otherParams: PropTypes.object.isRequired,
}
