export default {
  CREATE_NEW_FERTILIZER: 'Criar novo fertilizante',
  GENERIC: 'Genérico',
  COMPOSITION: 'Composição',
  ENTER_THE_PERCENTAGE_OF_THE_COMPOSITION:
    'Insira a porcentagem da composição e a unidade de medida a ser usada em sua aplicação.',
  THE_COMPOSITION_CANNOT_EXCEED_HUNDRED_PERCENT:
    'A composição não pode exceder 100%.',
  ENTER_THE_AMOUNT_APPLIED_IN_KG_OF_FERTILIZER:
    'Digite a quantidade aplicada em {{kg}} de fertilizante.',
  NUMBER_OF_KG: '{{Número de kg}}',
  N_NITROGEN_PERCENT: 'N Nitrogênio %',
  P_PHOSPHORUS_PERCENT: 'P Fósforo %',
  K_POTASSIUM_PERCENT: 'K Potássio %',
  CA_CALCIUM_PERCENT: 'Ca Cálcio %',
  S_SULFUR_PERCENT: 'S Enxofre %',
  UNIT_OF_MEASURE: 'Unidade de medida',
  ADD_NEW_GENERIC_FERTILIZER: 'Adicione seus fertilizantes genéricos',
  ADD_NEW_GENERIC_FERTILIZER_EASILY:
    'Adicione-os facilmente à lista a partir do botão',
  PHOSPHORUS: 'Fósforo',
  P2O5: 'P₂O₅',
  NITROGEN: 'Nitrogênio',
  BY_COMPOSITION: 'Por composição',
  FOUNTAIN: 'Fonte',
  CONVENTIONAL: 'Convencional',
  GREEN: 'Ecológico',
  N_AMMONIACAL: 'N Amoniacal',
  N_NITRIC: 'N Nítrico',
  N_UREAIC: 'N Ureaico',
  POTASSIUM: 'Potássio',
  K2O5: 'K₂O₅',
  CALCIUM: 'Cálcio',
  CACO3: 'CaCO₃',
  SULFUR: 'Enxofre',
  SO4: 'SO₄',
  CREATE_AND_SELECTED_FERTILIZER: 'Crie e selecione novo fertilizante',
  CHEMICAL_COMPOSITION: 'Composição química',
  ENTER_CHEMICAL_COMPOSITION: 'Insira a composição química do fertilizante.',
}
