import { ActivityIndicator } from '@common/components'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { LanguageContext } from '@contextState/language'
import { white } from '@styles/palette'
import React, { useContext } from 'react'
import { View, FlatList, StyleSheet } from 'react-native'
import { ItemFarm } from './components'
import useFetchFarmListByCrop from './hooks/useFetchFarmListByCrop'

const FarmsListSelectByCrop = ({ route }) => {
  const { isViewReadOnly } = route?.params ?? {}
  const { farms, farmSelected, onSelectFarm, selectFarm, isLoading } =
    useFetchFarmListByCrop()
  const { t } = useContext(LanguageContext)
  return (
    <View style={styles.content}>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <FlatList
          data={farms ?? []}
          renderItem={({ item }) => (
            <ItemFarm
              farm={item}
              onSelectFarm={onSelectFarm}
              farmSelected={farmSelected}
            />
          )}
          keyExtractor={(item) => `$farm-selected-${item._id}`}
        />
      )}

      {!isViewReadOnly && (
        <View style={styles.contentButton}>
          <ButtonDefault
            typeButton={farmSelected ? 'primary' : 'disabled'}
            disabled={!farmSelected}
            text={t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')}
            onPress={selectFarm}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: white,
  },
  contentButton: {
    paddingHorizontal: 24,
    paddingVertical: 45,
  },
})
export default FarmsListSelectByCrop
