import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { makeRequest } from '@common/services'
import useOfflineTypes from '@offline/queries/types'
import { GlobalStateInterface } from '@store/interfaces'
import { setStorageTypes } from '@store/slices/storageType.slice'

export const useStorageTypes = () => {
  const dispatch = useDispatch()
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )

  const { storeType, getTypes } = useOfflineTypes()

  const fetchStorageTypes = async () => {
    let data = []

    if (isConnected) {
      const response = await makeRequest({ url: 'commons/storagetypes' })
      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('storagetypes', data)
      }
    } else {
      data = await getTypes('storagetypes')
    }

    const storageTypes = data.map(
      (storageType: { keyLabel: string; _id: string }) => ({
        label: storageType.keyLabel,
        value: storageType._id,
      })
    )

    dispatch(setStorageTypes(storageTypes))
  }

  return {
    fetchStorageTypes,
  }
}
