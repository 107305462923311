export default {
  TEXT_1: 'Composição',
  TEXT_2: 'EIQ',
  TEXT_3: 'Classes toxicológicas',
  TEXT_4: 'Superfície %{areaUnit}',
  TEXT_5: 'Unidade de Medida',
  TEXT_6: 'Quantidade de %{unit}/%{areaUnit}',
  TEXT_7: 'ha',
  TEXT_8: 'Total',
  TEXT_9: 'Selecionar',
  TEXT_10: 'Clase',
  TEXT_11: 'Escasa',
}
