import FARM_LIST from '../../components/FarmList/translate/es'
import NO_LOTS_AVAILABLE from '../../components/NoLotsAvailable/translate/es'
import EMPTY_STATE from '../../components/EmptyState/translate/es'
import EMPTY_ITEM_FARM from '../../components/EmptyItemFarm/translate/es'

export default {
  COMPONENTS: {
    FARM_LIST,
    NO_LOTS_AVAILABLE,
    EMPTY_STATE,
    EMPTY_ITEM_FARM,
  },
}
