import React, { useContext, useState, useRef, useEffect } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { PropTypes } from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { Menu } from 'react-native-paper'
import orderSeed from './constants/order'
import { SnackbarAddGenericSupply } from '@modules/supplies/components'
import { SIZES } from '@common/components/icons/interfaces'

const SupplyOrderBy = ({
  setSearchCode,
  selectedTab,
  genericSupplySnackBarShown,
  setGenericSupplySnackBarShown,
}) => {
  const { t } = useContext(LanguageContext)
  const hasEIQ = selectedTab === 'fitoterapicos' || selectedTab === 'insumos'
  const [visibleMenu, setVisibleMenu] = useState(false)
  const menu = useRef()
  const [searchMode, setSearchMode] = useState(
    orderSeed.defaultMode.nameSearch(t)
  )
  const openMenu = () => setVisibleMenu(true)
  const closeMenu = () => setVisibleMenu(false)
  const [visibleSnackbar, setVisibleSnackbar] = useState(false)

  useEffect(() => {
    if (!genericSupplySnackBarShown && selectedTab === 'fertilizantes') {
      setVisibleSnackbar(true)
      setGenericSupplySnackBarShown(true)
    }
    if (selectedTab !== 'fertilizantes') {
      setVisibleSnackbar(false)
    }
  }, [selectedTab, genericSupplySnackBarShown])

  const handlePress = (mode) => {
    setSearchMode(orderSeed[mode]?.nameSearch(t))
    setSearchCode(mode)
    closeMenu()
  }

  return (
    <>
      <SnackbarAddGenericSupply
        visible={visibleSnackbar}
        onPress={() => setVisibleSnackbar(false)}
      />

      <TouchableOpacity style={styles.menu} onPress={openMenu}>
        <View style={styles.orderCard}>
          <Text style={styles.orderSeedByText}>
            {t('COMPONENTS').SUPPLIES.ORDER_BY.TEXT_1}
            <Text style={styles.searchMode}>{searchMode}</Text>
          </Text>
          <View style={styles.menuButton}>
            <Menu
              visible={visibleMenu}
              onDismiss={closeMenu} // Use onDismiss instead of onRequestClose
              onVisibleChange={setVisibleMenu} // Use onVisibleChange to handle the visibility of the menu
            >
              <Menu.Item
                title={t('SORT')}
                onPress={openMenu}
                icon={'SORT'}
                size={SIZES.MEDIUM}
              />
              {hasEIQ && (
                <Menu.Item
                  title={orderSeed.eiq.nameSearch(t)}
                  onPress={() => handlePress(orderSeed.eiq.searchOrder)}
                />
              )}
              <Menu.Item
                title={orderSeed.company.nameSearch(t)}
                onPress={() => handlePress(orderSeed.company.searchOrder)}
              />
              <Menu.Item
                title={orderSeed.name.nameSearch(t)}
                onPress={() => handlePress(orderSeed.name.searchOrder)}
              />
            </Menu>
          </View>
        </View>
      </TouchableOpacity>
    </>
  )
}
const styles = StyleSheet.create({
  menu: {
    // paddingTop: 8,
  },
  orderCard: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
  },
  cardText: {
    fontSize: 16,
    fontWeight: '500',
    color: '#3A3B3C',
  },
  searchMode: {
    fontWeight: 'bold',
    color: 'black',
  },
  menuButton: {
    marginLeft: 'auto',
  },
  orderSeedByText: {
    color: '#3333338A',
  },
})
SupplyOrderBy.propTypes = {
  setSearchCode: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
}
export default SupplyOrderBy
