import { FC, ReactElement } from 'react'
import { StyleSheet } from 'react-native'
import { FAB as Fab } from 'react-native-paper'

import {blackUltraLight, green500, white, blackHighEmphasis} from '@common/styles/palette'
import { IFabButton } from './interfaces'
import { CommonIcon } from '@modules/common/components'

const FAB = {
  THEME: {
    colors: {
      text: blackHighEmphasis,
    },
    dark: true,
  },
}

export const FabButton: FC<IFabButton> = ({
  isFabOptionsOpened,
  onPressFabButton,
  fabActions,
}): ReactElement => {

  return (
    <Fab.Group
      visible={true}
      open={isFabOptionsOpened}
      color={white}
      icon={(props) => <CommonIcon {...props} name={'PLUS'} />}
      actions={fabActions}
      onStateChange={onPressFabButton}
      fabStyle={styles.buttonFab}
      theme={FAB.THEME}
      backdropColor={blackUltraLight}
    />
  )
}

const styles = StyleSheet.create({
  buttonFab: {
    borderRadius: 28,
    backgroundColor: green500
  }
})
