export default {
  TEXT_1: 'Campo obrigatório',
  TEXT_2: 'O código deve conter 6 dígitos',
  TEXT_3: 'O código foi enviado a seu email',
  FIELD_1: {
    HELP_TEXT: 'Digite o código que recebeu no seu email',
    LABEL: 'Código de acesso*',
    PLACEHOLDER: 'xxxxxxx',
  },
  TEXT_4: 'Inserir',
  TEXT_5: '¿Problema de acesso?',
  TEXT_6: 'Digitar novamente',
}
