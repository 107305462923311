export enum EAgreementTypes {
  'SUSTAINABILITY' = 'SUSTAINABILITY',
  '2BSVS_REPORT' = '2BSVS_REPORT',
  'COTTON_BETS' = 'COTTON_BETS',
  'SEED_USE' = 'SEED_USE',
  'SELFDEV' = 'SELFDEV',
  'EVIDENCE_CFT' = 'EVIDENCE_CFT',
  'INPUTSIN' = 'INPUTSIN',
  'EXPLO' = 'EXPLO',
  'COMMER' = 'COMMER',
  'FINAN' = 'FINAN',
  'SELFDEC' = 'SELFDEC',
  'SELFDEM' = 'SELFDEM',
  'SELFDEA' = 'SELFDEA',
  'HARVEST_MANAGEMENT' = 'HARVEST_MANAGEMENT',
  'RESPONSIBLE_USE' = 'RESPONSIBLE_USE',
  'TILLAGE_CONTRACT' = 'TILLAGE_CONTRACT',
  '2BSVS_CALCULATION' = '2BSVS_CALCULATION',
  'SUSTAINABILITY_2BSVS' = 'SUSTAINABILITY_2BSVS',
  'SUSTAINABILITY_EPA' = 'SUSTAINABILITY_EPA',
  'SUSTAINABILITY_MOA' = 'SUSTAINABILITY_MOA',
  'SUSTAINABILITY_CAN' = 'SUSTAINABILITY_CAN',
  'WATER_CALCULATION' = 'WATER_CALCULATION',
  'SEED_PROD' = 'SEED_PROD',
  'ONBOARDING_AURA' = 'ONBOARDING_AURA',
  'INITIAL_REGISTRATION_AURA' = 'INITIAL_REGISTRATION_AURA',
}
