import React, { useContext, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { StyleSheet, View, Text } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { FormikProvider } from 'formik'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import CollaboratorsButton from '@components/collaborators/CollaboratorsButton'
import { white, primary50, blackMediumEmphasis } from '@styles/palette'
import FormCompany from '../../../components/FormCompany'
import ModalSuccess from '@components/common/v1/ModalSuccess'
import { ChildrenModalSuccess } from '@common/components/modals/ChildrenModalSuccess'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { useCompanyAdd } from './hooks/useCompanyAdd'
import { farmActions } from '@store/actions'
import { useDispatch } from 'react-redux'

const AddCompanyScreen = ({ navigation, route }) => {
  const dispatch = useDispatch()
  const { isAdviser = false } = route?.params ?? {}
  const { allCountryData } = useContext(CommonContext)
  const { t } = useContext(LanguageContext)
  const {
    countriesData,
    formik,
    isModalSuccess,
    onPressAddFile,
    onDeleteFile,
    onChangeCountry,
    navigateListCollaborator,
    onEndEditingIdentifier,
    canPressCollaborator,
    errorIdentifier,
    navigateHome,
  } = useCompanyAdd({ isAdviser, t })

  useEffect(() => {
    navigation.addListener('beforeRemove', () => {
      dispatch(farmActions.setFullScreen(false))
    })
  }, [navigation])

  useEffect(() => {
    if (isModalSuccess) {
      dispatch(farmActions.setFullScreen(true))
    }
  }, [isModalSuccess])
  const { values, handleSubmit, isValid, dirty, isSubmitting } = formik

  const textWithBold = (text) => {
    const words = text.split(' ')
    const regex = /\*(.*?)\*/g

    const newWords = words.map((word, index) => {
      if (word.match(regex)) {
        const newWord = word.replace(/\*/g, '')
        return (
          <Text key={index} style={{ fontWeight: '700' }}>
            {newWord}
            {` `}
          </Text>
        )
      }
      return (
        <Text key={index}>
          {word}
          {` `}
        </Text>
      )
    })
    return newWords
  }

  return (
    <View style={styles.content}>
      <ScrollView style={styles.container}>
        <FormikProvider value={formik}>
          <FormCompany
            formik={formik}
            isAdviser={isAdviser}
            countriesData={countriesData}
            allCountryData={allCountryData}
            onDeleteFile={onDeleteFile}
            onChangeCountry={onChangeCountry}
            onPressAddFile={onPressAddFile}
            onEndEditingIdentifier={onEndEditingIdentifier}
            errorIdentifier={errorIdentifier}
          />
          <View style={styles.collaboratorsContainer}>
            <CollaboratorsButton
              title={t('COMPONENTS.COLLABORATORS_BUTTON.COMPANY_COLLABORATORS')}
              pendingAuthorization={false}
              quantity={values?.users?.length}
              onPress={
                canPressCollaborator ? navigateListCollaborator : undefined
              }
              testID='add_collaborator_button'
            />
          </View>
          {isAdviser && (
            <View style={styles.adviserTextContainer}>
              <Text style={styles.adviserText}>
                {textWithBold(
                  t(
                    `COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.ADVSER_RECOMENDATION`
                  )
                )}
              </Text>
            </View>
          )}
          <ButtonDefault
            typeButton={
              dirty && isValid && !isSubmitting && !errorIdentifier
                ? 'primary'
                : 'disabled'
            }
            text={t(
              `COMPONENTS.COMPONENTS_COMPANY.FORM_COMPANY.SAVE_COMPANY`
            ).toUpperCase()}
            marginTop={50}
            marginBottom={50}
            onPress={handleSubmit}
            disabled={!(dirty && isValid)}
            testID='create_company'
          />
        </FormikProvider>
      </ScrollView>
      <ModalSuccess
        visible={isModalSuccess}
        title={''}
        description={''}
        style={styles.buttonContainer}
      >
        <ChildrenModalSuccess
          onPress={navigateHome}
          textSuccess={t('VIEWS.ADD_COMPANY_SCREEN.TEXT_SUCCESS')}
          textButton={t('VIEWS.ADD_COMPANY_SCREEN.TEXT_BUTTON')}
          testID='success_button'
        />
      </ModalSuccess>
    </View>
  )
}

const styles = StyleSheet.create({
  content: { flex: 1 },
  container: {
    paddingHorizontal: 16,
    backgroundColor: white,
  },
  buttonContainer: {
    marginTop: 128,
    marginBottom: 0,
  },
  adviserTextContainer: {
    backgroundColor: primary50,
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 7,
    marginTop: 8,
  },
  adviserText: {
    color: blackMediumEmphasis,
  },
})

AddCompanyScreen.propTypes = {
  route: PropTypes.object.isRequired,
}

export default AddCompanyScreen
