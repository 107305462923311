import React, { useContext } from 'react'
import { View, Text, Image, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'

import { notificationEmpty, notificationFilterEmpty } from '@constants/images'
import ButtonCustom from '@components/common/ButtonCustom'
import { primary500, blackHighEmphasis } from '@styles/palette'
import { capitalizeFirstLetter } from '@utils/strings'
import RouterNames from '@constants/routerNames'

export const EmptyState = () => {
  const { t } = useContext(LanguageContext)
  const {
    notificationFilters: { hasAnyFilterActive },
    resetNotificationFilters,
  } = useContext(CommonContext)

  const navigation = useNavigation()

  const onGoToFarms = () => {
    navigation.navigate(RouterNames.FARMS)
  }

  return (
    <View style={styles.container}>
      <View style={styles.emptyStateContainer}>
        <Image
          source={
            hasAnyFilterActive ? notificationFilterEmpty : notificationEmpty
          }
          style={styles.emptyImage}
        />

        <Text style={styles.message}>
          {t(
            `VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_EMPTY_STATE.${
              hasAnyFilterActive ? 'NO_NOTIFICATION_FILTER' : 'NO_NOTIFICATION'
            }`
          )}
        </Text>

        {hasAnyFilterActive && (
          <Text style={styles.subTitle}>
            {t(
              'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_EMPTY_STATE.NOT_RESULTS'
            )}
          </Text>
        )}
      </View>

      {hasAnyFilterActive ? (
        <ButtonCustom
          onPress={resetNotificationFilters}
          styles={styles.button}
          uppercase={false}
          labelStyle={styles.buttonLabel}
        >
          {capitalizeFirstLetter(
            t(
              'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_EMPTY_STATE.CLEAN_FILTERS'
            )
          )}
        </ButtonCustom>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  emptyStateContainer: {
    flex: 1,
  },
  emptyImage: {
    width: 280,
    height: 220,
    marginTop: 55,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  message: {
    paddingHorizontal: 26,
    marginTop: 63,
    alignSelf: 'center',
    fontSize: 20,
    color: blackHighEmphasis,
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: 23,
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '400',
    letterSpacing: 0.25,
    color: blackHighEmphasis,
    textAlign: 'center',
    marginTop: 10,
  },
  button: {
    backgroundColor: primary500,
    marginTop: 16,
  },
  buttonLabel: {
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.75,
  },
})
