export const getCurrentRouteName = ({ currentRoute }: any) => {
  // const routesLength = currentRoute.state?.routes?.length - 1
  // const lastRoute = currentRoute.state?.routes[routesLength]
  // const routeName = lastRoute
  //   ? lastRoute?.name
  //   : !currentRoute.state || currentRoute.state?.routes?.length === 1
  //   ? currentRoute.name
  //   : ''

  return currentRoute.name
}
