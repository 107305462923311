import { ICropType } from '../interfaces'

const contains = (item: ICropType, searchTxt: string) => {
  if (item) {
    const itemStringField = item.label.toString().toLowerCase()
    const searchTxtStringField = searchTxt.toString().toLowerCase()

    if (itemStringField.includes(searchTxtStringField)) {
      return true
    }
  }

  return false
}

export const deepSearchCropTypeInArr = (query: string, arr: ICropType[]) => {
  let array = []

  for (let i = 0; i <= arr.length - 1; i++) {
    if (contains(arr[i], query)) {
      array.push(arr[i])
    }
  }

  return array
}
