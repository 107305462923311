export default {
  FIELD_1: {
    LABEL: 'E-Mail',
    PLACEHOLDER: 'Digite seu email',
  },
  FIELD_2: {
    LABEL: 'Nome',
    PLACEHOLDER: 'Insire seu nome',
  },
  FIELD_3: {
    LABEL: 'Sobrenome',
    PLACEHOLDER: 'Insire seu sobrenome',
  },
  FIELD_4: {
    LABEL: 'Telefone',
    PLACEHOLDER: 'Insire seu telefone',
  },
  FIELD_5: {
    LABEL: 'PIN',
    PLACEHOLDER: 'Insire seu pin',
    HELPTEXT: 'Deixe em branco para manter seu código',
    TITLE: 'PIN de Seguridad',
    SUBTITLE:
      'Ayudará a verificar tu identidad en las actividades que realices.',
  },
  TEXT_1: 'Atualizar',
  TEXT_2: 'Perfil salvo com sucesso',
}
