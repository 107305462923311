export default {
  TEXT_1:
    'Antes de adicionar um colaborador você deve adicionar pelo menos uma peça de evidência',
  TEXT_2: 'deve selecionar pelo menos um lote',
  TEXT_3: 'Agregar evidência',
  TEXT_4: 'Salvar',
  TEXT_5: 'assinar',
  TEXT_6: 'Assinado',
  TEXT_7: 'assinar',
}
