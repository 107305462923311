import React, { useContext, useCallback } from 'react'
import { StyleSheet, View, Text, Image } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import Constants from 'expo-constants'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { getFileType } from '@utils/files'
import { isFileImage } from '@utils/images'
import { blackHighEmphasis, onPressedColor } from '@styles/palette'
import { formatDateMoment } from '@utils/date'
import evidenceConceptTypes from '@constants/evidenceConceptTypes'
import { CommonIcon } from '@modules/common/components'
import { PropTypes } from 'prop-types'

const apiUrl = Constants.expoConfig.extra.EXPO_HOST || ''
const pattern = new RegExp('^http')

const EvidencesList = ({
  evidences,
  onPress,
  onDelete,
  disabled,
  canRemovePersisted = true,
  evidenceNameTitle = false,
  isNewActivity = true,
}) => {
  const { t, i18n } = useContext(LanguageContext)
  const { evidenceConcept: evidenceConcepts } = useContext(CommonContext)

  const EvidenceIcon = useCallback(({ evidence }) => {
    const fileType = getEvidenceFileType(evidence)

    return (
      <View style={styles.iconContainer}>
        {isImage(evidence) ? (
          <Image
            source={{
              uri: getSourcePath(evidence),
            }}
            style={styles.image}
          />
        ) : fileType === 'pdf' ? (
          <CommonIcon
            name={'FILETYPE-PDF'}
            size={35}
            color={blackHighEmphasis}
          />
        ) : fileType === 'txt' ? (
          <CommonIcon
            name={'FILETYPE-TXT'}
            size={35}
            color={blackHighEmphasis}
          />
        ) : (
          <CommonIcon
            name={'FILETYPE-DOC'}
            size={35}
            color={blackHighEmphasis}
          />
        )}
      </View>
    )
  }, [])

  const EvidenceDescription = useCallback(({ evidence }) => {
    const evidenceType = getEvidenceType(evidence)
    const evidenceName = getEvidenceName(evidence)
    const evidenceUploadedBy = getEvidenceUploadedBy(evidence)
    const evidenceUploadedAt = getEvidenceUploadedAt(evidence)

    return (
      <View style={styles.descriptionContainer}>
        {evidenceType && (
          <Text style={styles.evidenceTypeText}>{evidenceType.label}</Text>
        )}

        {evidenceName && (
          <Text style={styles.evidenceTypeText}>{evidenceName}</Text>
        )}

        {evidenceUploadedBy && (
          <Text style={styles.evidenceUploadedByText}>
            {evidenceUploadedBy}
          </Text>
        )}

        {evidenceUploadedAt && (
          <Text style={styles.evidenceUploadedAtText}>
            {evidenceUploadedAt}
          </Text>
        )}

        {evidenceType &&
          evidenceType?.code === evidenceConceptTypes.PHOTO_IN_LOT &&
          evidence?.settings?.isInLot === false &&
          evidence?.settings?.isValidateLot && (
            <Text style={styles.evidenceUploadedAtText}>
              {`⚠️ ${t('COMPONENTS').COMMON.FILES_LIST.TEXT_5}`}
            </Text>
          )}
      </View>
    )
  }, [])

  const EvidenceRemove = useCallback(
    ({ position, disabled, onDelete, isPersisted }) => {
      if (!onDelete || !isNewActivity || (!canRemovePersisted && isPersisted)) {
        return null
      }
      return (
        <View style={styles.removeIconContainer}>
          <CommonIcon
            name={'X'}
            size={22}
            color={blackHighEmphasis}
            onPress={() => !disabled && onDelete(position)}
            disabled={disabled}
          />
        </View>
      )
    },
    []
  )

  const getEvidenceType = useCallback(({ evidenceConcept }) => {
    return evidenceConcepts.find(
      (concept) =>
        concept._id === evidenceConcept || concept._id === evidenceConcept?._id
    )
  }, [])

  const getEvidenceName = useCallback(
    ({ originalName, name, path, file, nameFile }) => {
      const evidenceName =
        originalName ||
        name ||
        file?.name ||
        nameFile ||
        t('COMPONENTS').COMMON.FILES_LIST.TEXT_4

      const fileType = getFileType(
        nameFile ||
          path ||
          file?.uri ||
          file?.nameFile ||
          file?.name ||
          file?.path ||
          name
      )

      if (!fileType) {
        return
      }

      return `${
        evidenceName.split(`.${fileType?.toLowerCase()}`)[0]
      }.${fileType?.toLowerCase()}`
    },
    []
  )

  const getEvidenceUploadedBy = useCallback(({ user, userCreate }) => {
    const userRender = userCreate ?? user
    return userRender?.firstName
      ? `${userRender.firstName} ${userRender.lastName}`
      : undefined
  }, [])

  const getEvidenceUploadedAt = useCallback(({ date: uploadedAt }) => {
    const date = uploadedAt
      ? formatDateMoment(uploadedAt, 'DD/MMM/YYYY', i18n.locale, true)
      : undefined

    return date
  }, [])

  const isImage = useCallback(
    ({ originalName, name, file, path, nameFile }) => {
      return isFileImage(
        originalName ||
          nameFile ||
          path ||
          file?.uri ||
          file?.nameFile ||
          file?.path ||
          file ||
          name
      )
    },
    []
  )

  const getSourcePath = useCallback((evidence) => {
    if (evidence.isSatelliteImage) {
      return evidence.imageSatellite
    }

    if (evidence.file?.uri) {
      return evidence.file.uri
    }

    if (typeof evidence.file === 'string' || evidence.file instanceof String) {
      return evidence.file
    }

    if (evidence.pathThumbnails || evidence.path) {
      const path = evidence.pathThumbnails
        ? evidence.pathThumbnails
        : evidence.path

      if (pattern.test(path)) {
        return path
      }

      return `${apiUrl}/${path}`
    }

    return URL.createObjectURL(evidence.file)
  }, [])

  const getEvidenceFileType = useCallback(({ name, file, path }) => {
    const fileType = getFileType(
      file?.uri || file?.nameFile || file?.path || name || path
    )

    return fileType ?? undefined
  }, [])

  return evidences.map((element, key) => (
    <TouchableRipple
      key={key}
      style={styles.evidenceContainer}
      onPress={() => (onPress ? onPress(element) : null)}
      rippleColor={onPressedColor}
      underlayColor={onPressedColor}
    >
      <>
        <EvidenceIcon evidence={element} />

        <EvidenceDescription evidence={element} />

        <EvidenceRemove
          key={key}
          position={key}
          disabled={disabled}
          onDelete={onDelete}
          isPersisted={element?.persisted}
        />
      </>
    </TouchableRipple>
  ))
}

const styles = StyleSheet.create({
  evidenceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
  },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
  },
  icon: {
    color: blackHighEmphasis,
  },
  descriptionContainer: {
    flex: 1,
  },
  evidenceTypeText: {
    fontSize: 16,
    fontWeight: '500',
    color: blackHighEmphasis,
  },
  evidenceNameText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  evidenceUploadedByText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  evidenceUploadedAtText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
  removeIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginRight: 8,
  },
})

EvidencesList.propTypes = {
  evidences: PropTypes.array,
  onPress: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  canRemovePersisted: PropTypes.bool,
  evidenceNameTitle: PropTypes.bool,
}

export default EvidencesList
