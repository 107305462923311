import ENTER_PIN from '../../components/EnterPin/translate/pt'
import CONFIRM_PIN from '../../components/ConfirmPin/translate/pt'
import WIZARD_PIN from '../../components/WizardPin/translate/pt'

export default {
  COMPONENTS: {
    ENTER_PIN,
    CONFIRM_PIN,
    WIZARD_PIN,
  },
  NEXT: 'Continuar',
}
