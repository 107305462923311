import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { WebViewRender } from '@modules/webView/components'
import { resetWebViewValues } from '@store/slices'

const WebView = () => {
  const dispatch = useDispatch()

  const { url, onMessage, onNavigationStateChange } = useSelector((state) => {
    return state.webViewReducer
  })

  useEffect(() => {
    return () => {
      dispatch(resetWebViewValues())
    }
  }, [])

  if (!url) {
    return null
  }

  return (
    <WebViewRender
      url={url}
      onMessage={onMessage}
      onNavigationStateChange={onNavigationStateChange}
    />
  )
}

export default WebView
