import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { LanguageContext } from '@contextState/language'
import Screen from './Screen'
import useNetwork from '@utils/network'
import useModal from '@hooks/useModal'
import { isAppliedLots } from './utils'
import { statusSubLicenses } from '@utils/constants'

const SubLicenseSelectCompanies = ({ route, navigation }) => {
  const { t } = React.useContext(LanguageContext)
  const { doRequest, loading } = useNetwork()
  const [subLicensesSelected, setSubLicensesSelected] = useState([])
  const [subLicenses, setSubLicenses] = useState([])
  const [selectedAll, setSelectedAll] = useState(false)
  const [applyLicenseLoading, setApplyLicenseLoading] = useState(false)
  const [disabledAll, setDisabledAll] = useState(true)
  const [areaUnit, setAreaUnit] = useState('')

  const { isModalVisible, toggleModal, closeModal } = useModal()
  const {
    isModalVisible: isModalErrorVisible,
    toggleModal: toggleModalError,
    closeModal: closeModalError,
  } = useModal()

  const {
    lotsSelected,
    has,
    licenseId,
    cropId,
    companyIdentifier,
    lots,
    cropNameText,
  } = route.params
  const lotsIndex = lots.map((lot) => lot._id)
  const textLots = `${lotsSelected} ${
    t('VIEWS').SUBLICENSES_SELECT_COMPANIES.TEXT_2
  } - ${has} ${areaUnit}.`

  const {
    isModalVisible: isModalConfirmApplyLicenseVisible,
    toggleModal: displayToggleModalConfirmApplyLicense,
    closeModal: closeModalConfirmApplyLicense,
  } = useModal()

  useEffect(() => {
    fetchSubLicenses()
  }, [])

  const fetchSubLicenses = async () => {
    try {
      const request = await doRequest({
        method: 'GET',
        url: `licenses/${licenseId}/sub-licenses?cropId=${cropId}`,
      })
      setSubLicenses(request.data)
      checkDisabledAll(request.data)
      if (request?.data?.length) {
        setAreaUnit(request.data[0]?.areaUnit)
      }
    } catch (error) {
      console.error('error cargando sublicencias', error)
    }
  }

  const checkDisabledAll = (data) => {
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      const status = isAppliedLots(lotsIndex, element.lotsApplied)
      if (
        status !== statusSubLicenses.DISABLED &&
        !(element.isApplied && element.diffHectare === 0)
      ) {
        setDisabledAll(false)
        index = data.length
      }
    }
  }

  const isSelected = (item) => {
    const status = isAppliedLots(lotsIndex, item.lotsApplied)
    const checked =
      status === statusSubLicenses.DISABLED ||
      subLicensesSelected.indexOf(item._id) !== -1
    return checked
  }

  const selectAndUnselectSubLicense = (_id) => {
    const indexSubLicenses = subLicensesSelected.indexOf(_id)
    if (indexSubLicenses === -1) {
      setSubLicensesSelected([...subLicensesSelected, _id])
    } else {
      const filterLicenses = subLicensesSelected
      filterLicenses.splice(indexSubLicenses, 1)
      if (filterLicenses.length !== lotsIndex.length) {
        setSelectedAll(false)
      }
      setSubLicensesSelected([...filterLicenses])
    }
  }

  const selectAndUnselectAll = () => {
    if (selectedAll) {
      setSelectedAll(false)
      setSubLicensesSelected([])
    } else {
      setSelectedAll(true)
      const idLicenses = subLicenses.map((subLicense) => {
        const status = isAppliedLots(lotsIndex, subLicense.lotsApplied)
        if (
          status !== statusSubLicenses.DISABLED &&
          !(subLicense.isApplied && subLicense.diffHectare === 0)
        ) {
          return subLicense._id
        }
      })
      setSubLicensesSelected([...idLicenses])
    }
  }

  const applyLicense = async () => {
    setApplyLicenseLoading(true)

    const data = {
      cropId: route.params.cropId,
      lots: lotsIndex,
      companyIdentifier,
      subLicenses: subLicensesSelected,
    }

    const response = await doRequest({
      method: 'POST',
      url: `licenses/${licenseId}/apply`,
      data,
    })

    setApplyLicenseLoading(false)

    if (response) {
      toggleModal()
    } else {
      toggleModalError()
    }
  }

  /**
   * GO TO LICENSES
   */
  const goToLicenses = () => {
    closeModal()
    closeModalError()
    navigation.navigate('Licenses', {
      cropId,
    })
  }

  /**
   * GO TO HOME
   */
  const goToHome = () => {
    closeModal()
    closeModalError()
    navigation.navigate('CropList')
  }

  return (
    <Screen
      textLots={textLots}
      subLicenses={subLicenses}
      subLicensesSelected={subLicensesSelected}
      isSelected={isSelected}
      selectAndUnselectSubLicense={selectAndUnselectSubLicense}
      selectedAll={selectedAll}
      selectAndUnselectAll={selectAndUnselectAll}
      t={t}
      applyLicense={applyLicense}
      isModalVisible={isModalVisible}
      toggleModal={toggleModal}
      goToLicenses={goToLicenses}
      goToHome={goToHome}
      loading={loading}
      has={has}
      applyLicenseLoading={applyLicenseLoading}
      lotsLength={lots.length}
      isModalConfirmApplyLicenseVisible={isModalConfirmApplyLicenseVisible}
      displayToggleModalConfirmApplyLicense={
        displayToggleModalConfirmApplyLicense
      }
      closeModalConfirmApplyLicense={closeModalConfirmApplyLicense}
      isModalErrorVisible={isModalErrorVisible}
      cropNameText={cropNameText}
      disabledAll={disabledAll}
      lotsSelected={lotsIndex}
      areaUnit={areaUnit}
    />
  )
}

SubLicenseSelectCompanies.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  goToLicenses: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default SubLicenseSelectCompanies
