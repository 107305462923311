import Constants from 'expo-constants'
import firebase from 'firebase/compat/app'
import { getAnalytics } from 'firebase/analytics'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import { v4 as uuidv4 } from 'uuid'
import { ObjectId } from '@utils/common'
import activityTypes from '@constants/activityTypes'

const config = {
  apiKey: Constants?.expoConfig?.extra.FIREBASE_API_KEY,
  authDomain: Constants?.expoConfig?.extra.FIREBASE_AUTH_DOMAIN,
  projectId: Constants?.expoConfig?.extra.FIREBASE_PROJECT_ID,
  storageBucket: Constants?.expoConfig?.extra.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Constants?.expoConfig?.extra.FIREBASE_MESSAGING_SENDER_ID,
  appId: Constants?.expoConfig?.extra.FIREBASE_APP_ID,
  measurementId: Constants?.expoConfig?.extra.FIREBASE_MEASUREMENT_ID,
  databaseURL: Constants?.expoConfig?.extra.FIREBASE_DATABASE_URL,
}

export const initializeFirebase = () => {
  let app
  if (!firebase.apps.length) {
    app = firebase.initializeApp(config)
    firebase.firestore().settings({
      merge: true,
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
      ignoreUndefinedProperties: true,
      persistence: false,
    })
  } else {
    app = firebase.app()
  }
  //analytics
  getAnalytics(app)
}

export const getDrafts = async () => {
  try {
    const result = await firebase.firestore().collection('drafts').get()
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      createdAt: doc.createdAt.toDate(),
      updatedAt: doc.updatedAt.toDate(),
    }))
    return data
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * GET DRAFT BY ID
 *
 * @param {string} id draft id
 * @return {json} object achievement
 */
export const getDraftById = async (id) => {
  try {
    const result = await firebase.firestore().collection('drafts').doc(id).get()
    const draft = result.exists ? result.data() : undefined

    return draft
      ? {
          ...draft,
          id: draft.id,
          createdAt: draft?.createdAt?.toDate(),
          updatedAt: draft?.updatedAt?.toDate(),
          rejectedAt: draft?.rejectedAt
            ? typeof draft?.rejectedAt === 'string'
              ? draft.rejectedAt
              : draft.rejectedAt?.toDate()
            : undefined,
          dateAchievement: draft.dateAchievement
            ? draft.dateAchievement?.toDate()
            : undefined,
          dateHarvest: draft.dateHarvest
            ? draft.dateHarvest?.toDate()
            : undefined,
          dateObservation: draft.dateObservation
            ? draft.dateObservation?.toDate()
            : undefined,
          dateEstimatedHarvest: draft.dateEstimatedHarvest
            ? draft.dateEstimatedHarvest?.toDate()
            : undefined,
          evidences: draft.evidences?.map((evidence) => ({
            ...evidence,
            date: evidence.date?.toDate(),
          })),
          signers: draft.signers?.map((signer) => ({
            ...signer,
            dateToSign: signer.dateToSign?.toDate
              ? signer.dateToSign?.toDate()
              : undefined,
          })),
          observations: draft.observations?.map((observation) => ({
            ...observation,
            createdAt: observation.createdAt?.toDate(),
          })),
        }
      : null
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * INSERT DRAFT
 *
 * @param {json} data  data form achievement
 * @param {json} user user who is filling out the form
 * @return {json} draft
 */
export const insertDraft = async (data, user) => {
  try {
    const date = new Date()
    data.createdAt = date
    data.updatedAt = date
    data.updatedByUserId = user._id
    data.createdByUserId = user._id
    data.createdByUsername = user.name
    data.updatedByUsername = user.name
    data.version = data.version || 1
    data.hasMoreVersion = data.version > 1 ? true : false
    data.draftGroupId = data.draftGroupId || uuidv4()
    data.groupActivity = data.activity || uuidv4()
    data._id = ObjectId()
    const result = await firebase.firestore().collection('drafts').add(data)
    return { ...data, id: result.id }
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * UPDATE DRAFT
 *
 * @param {json} data  data form achievement
 * @param {json} user user who is filling out the form
 * @return {json} draft
 */
export const updateDraft = async (
  data,
  user,
  updateUser = true,
  updateVersions = true,
  updateRejected = true
) => {
  try {
    const date = new Date()
    data.updatedAt = date
    if (updateUser) {
      data.updatedByUserId = user._id
      data.updatedByUsername = user.name
    }
    if (data.isRejected && updateRejected) {
      data.isRejected = false
    }

    await firebase
      .firestore()
      .collection('drafts')
      .doc(data.id)
      .set(data, { merge: true })

    if (updateVersions) {
      const drafts = await getDraftsByGroup(data.draftGroupId)
      if (drafts.length > 1) {
        updateUpdateAtDrafts(drafts, data.updatedAt)
      }
    }

    return { ...data }
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

const updateUpdateAtDrafts = async (drafts, updatedAt) => {
  drafts.forEach((draft) => {
    firebase.firestore().collection('drafts').doc(draft.id).update({
      updatedAt,
    })
  })
}

/**
 * DELETE DRAFT BY ID
 *
 * @param {json} id draft id
 * @return {json} draft
 */
export const deleteDraft = async (id, draftGroupId) => {
  try {
    const result = await firebase
      .firestore()
      .collection('drafts')
      .doc(id)
      .delete()
    const groupDrafts = await getDraftsByGroup(draftGroupId, true)

    if (groupDrafts?.size === 1) {
      const idDraft = groupDrafts.docs[0].id
      await updateDraft(
        {
          id: idDraft,
          hasMoreVersion: false,
          version: 1,
        },
        {},
        false,
        false
      )
    }

    return result
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * SYNCHRONIZE A DRAFT OFFLINE TO ONLINE, IF IT IS THE SAME USER, UPDATE IT AND IF IT IS A DIFFERENT USER, CREATE A NEW VERSION
 *
 * @param {json} data  data form achievement, need group activity
 * @param {json} user user who is filling out the form
 * @return {json} draft
 */
export const syncDraft = async (newDraft, user) => {
  try {
    const result = await firebase
      .firestore()
      .collection('drafts')
      .doc(newDraft.id)
      .get()

    const draft = result.data()

    if (draft) {
      if (draft?.updatedByUserId === user._id) {
        return await updateDraft(newDraft, user, true, false)
      } else {
        await updateDraft(
          {
            ...draft,
            hasMoreVersion: true,
            id: newDraft.id,
          },
          {},
          false
        )
        const drafts = await getDraftsByGroup(draft.draftGroupId, true)
        const lastVersion = drafts?.size || 0
        const version = lastVersion + 1
        delete newDraft.id

        return await insertDraft(
          { ...newDraft, version, draftGroupId: draft.draftGroupId },
          user
        )
      }
    } else if (newDraft.tag === activityTypes.ACT_VERIFICATION.key) {
      const verificationDraftsResult = await firebase
        .firestore()
        .collection('drafts')
        .where('crop', '==', newDraft.crop)
        .where('tag', '==', activityTypes.ACT_VERIFICATION.key)
        .where('companyIdentifier', '==', newDraft.companyIdentifier)
        .where('verificationType', '==', newDraft.verificationType)
        .get()

      if (!verificationDraftsResult.empty) {
        const verificationDraft = {
          ...verificationDraftsResult.docs[0].data(),
          id: verificationDraftsResult.docs[0].id,
        }

        await updateDraft(
          {
            ...verificationDraft,
            hasMoreVersion: true,
            id: verificationDraft.id,
          },
          {},
          false
        )

        const verificationDrafts = await getDraftsByGroup(
          verificationDraft.draftGroupId,
          true
        )

        const lastVersion = verificationDrafts?.size || 0
        const version = lastVersion + 1

        delete newDraft.id

        return await insertDraft(
          {
            ...newDraft,
            version,
            draftGroupId: verificationDraft.draftGroupId,
          },
          user
        )
      }
    }

    return await insertDraft(
      {
        ...newDraft,
        version: 1,
      },
      user
    )
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * GET DRAFTS BY ACTIVITY ID
 *
 * @param {json} groupActivity activity id
 * @return {array} drafts
 */
export const getDraftsByActivityId = async (groupActivity, withOutData) => {
  try {
    const result = await firebase
      .firestore()
      .collection('drafts')
      .where('groupActivity', '==', groupActivity)
      .orderBy('updatedAt', 'desc')
      .get()
    if (!withOutData) {
      const data = result.docs.map((doc) => {
        const draft = doc.data()

        return {
          ...draft,
          id: doc.id,
          createdAt: draft.createdAt?.toDate(),
          updatedAt: draft.updatedAt?.toDate(),
          rejectedAt: draft?.rejectedAt
            ? typeof draft?.rejectedAt === 'string'
              ? draft.rejectedAt
              : draft.rejectedAt?.toDate()
            : undefined,
          dateAchievement: draft.dateAchievement
            ? draft.dateAchievement?.toDate()
            : undefined,
          dateHarvest: draft.dateHarvest
            ? draft.dateHarvest?.toDate()
            : undefined,
          dateObservation: draft.dateObservation
            ? draft.dateObservation?.toDate()
            : undefined,
          dateEstimatedHarvest: draft.dateEstimatedHarvest
            ? draft.dateEstimatedHarvest?.toDate()
            : undefined,
          evidences: draft.evidences?.map((evidence) => ({
            ...evidence,
            date: evidence.date?.toDate(),
          })),
          observations: draft.observations?.map((observation) => ({
            ...observation,
            createdAt: observation.createdAt?.toDate(),
          })),
        }
      })

      return data.sort((a, b) => b.version - a.version)
    }
    return result
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * GET DRAFTS BY CROP ID
 *
 * @param {json} cropId crop id
 * @return {array} drafts
 */
export const getDraftsByCropId = async (cropId, withOutData = false) => {
  try {
    const result = await firebase
      .firestore()
      .collection('drafts')
      .where('crop', '==', cropId)
      .orderBy('updatedAt', 'desc')
      .get()
    if (!withOutData) {
      const data = result.docs.map((doc) => {
        const draft = doc.data()
        return {
          ...draft,
          id: doc.id,
          createdAt: draft.createdAt?.toDate(),
          updatedAt: draft.updatedAt?.toDate(),
          rejectedAt: draft?.rejectedAt
            ? typeof draft?.rejectedAt === 'string'
              ? draft.rejectedAt
              : draft.rejectedAt?.toDate()
            : undefined,
          dateAchievement: draft.dateAchievement
            ? draft.dateAchievement?.toDate()
            : undefined,
          dateHarvest: draft.dateHarvest
            ? draft.dateHarvest?.toDate()
            : undefined,
          dateObservation: draft.dateObservation
            ? draft.dateObservation?.toDate()
            : undefined,
          dateEstimatedHarvest: draft.dateEstimatedHarvest
            ? draft.dateEstimatedHarvest?.toDate()
            : undefined,
          evidences: draft.evidences?.map((evidence) => ({
            ...evidence,
            date: evidence.date?.toDate(),
          })),
          observations: draft.observations?.map((observation) => ({
            ...observation,
            createdAt: observation.createdAt?.toDate(),
          })),
        }
      })
      return data
    }
    return result
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * GET DRAFTS TYPE VERIFICATION BY CROP ID AND COMPANY ID
 *
 * @param {json} cropId crop id
 * @param {json} identifier identifier of company
 * @return {array} drafts
 */
export const getDraftsVerificationByCropIdAndIdentifier = async (
  cropId,
  identifier,
  withOutData = false
) => {
  try {
    const result = await firebase
      .firestore()
      .collection('drafts')
      .where('crop', '==', cropId)
      .where('companyIdentifier', '==', identifier)
      .where('tag', '==', activityTypes.ACT_VERIFICATION.key)
      .orderBy('updatedAt', 'desc')
      .get()
    if (!withOutData) {
      const data = result.docs.map((doc) => {
        const draft = doc.data()

        return {
          ...draft,
          id: doc.id,
          createdAt: draft.createdAt?.toDate(),
          updatedAt: draft.updatedAt?.toDate(),
          rejectedAt: draft?.rejectedAt
            ? typeof draft?.rejectedAt === 'string'
              ? draft.rejectedAt
              : draft.rejectedAt?.toDate()
            : undefined,
          dateAchievement: draft.dateAchievement
            ? draft.dateAchievement?.toDate()
            : undefined,
          dateHarvest: draft.dateHarvest
            ? draft.dateHarvest?.toDate()
            : undefined,
          dateObservation: draft.dateObservation
            ? draft.dateObservation?.toDate()
            : undefined,
          dateEstimatedHarvest: draft.dateEstimatedHarvest
            ? draft.dateEstimatedHarvest?.toDate()
            : undefined,
          evidences: draft.evidences?.map((evidence) => ({
            ...evidence,
            date: evidence.date?.toDate(),
          })),
          observations: draft.observations?.map((observation) => ({
            ...observation,
            createdAt: observation.createdAt?.toDate(),
          })),
        }
      })
      return data
    }
    return result
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * GET DRAFTS VERSIONS
 *
 * @param {string} groupActivity activity id
 * @param {string} draftGroupId draft group id, group versions
 * @param {bool} withOutData return data array or not
 * @return {array} drafts
 */
export const getDraftsByGroup = async (draftGroupId, withOutData) => {
  try {
    const result = await firebase
      .firestore()
      .collection('drafts')
      .where('draftGroupId', '==', draftGroupId)
      .orderBy('updatedAt', 'desc')
      .get()
    if (!withOutData) {
      const data = result.docs.map((doc) => {
        const draft = doc.data()

        return {
          ...draft,
          id: doc.id,
          dateAchievement: draft.dateAchievement
            ? draft.dateAchievement.toDate()
            : undefined,
          createdAt: draft.createdAt ? draft.createdAt.toDate() : undefined,
          updatedAt: draft.updatedAt ? draft.updatedAt.toDate() : undefined,
        }
      })
      return data
    }
    return result
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * DELETE DRAFTS BY GROUP ACTIVITY
 *
 * @param {json} groupActivity activity id
 * @return {json} draft
 */
export const deleteMultiDrafts = async (groupActivity) => {
  try {
    const result = await getDraftsByActivityId(groupActivity, true)
    result.forEach((element) => {
      element.ref.delete()
    })
    return true
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * DELETE DRAFT BY MULTIPLES ID
 *
 * @param {array} draftsIds id drafts
 * @return {boolean}
 */
export const deleteDraftsByIds = async (draftsIds) => {
  try {
    for (const draftId of draftsIds) {
      const result = await firebase
        .firestore()
        .collection('drafts')
        .doc(draftId)
        .get()
      result && result.ref.delete()
    }

    return true
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * DELETE DRAFTS BY ID
 *
 * @param {json} id activity id
 * @return {json} draft
 */
export const deleteGroupDraft = async (draftGroupId) => {
  try {
    const result = await getDraftsByGroup(draftGroupId, true)
    result.forEach((element) => {
      element.ref.delete()
    })
    return true
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * INSERT FILE TO STORAGE
 *
 * @param {file} blobFile evidence file
 * @param {string} fileName file name
 * @return {promise} url file
 */
export const insertFileToStorage = async ({ blobFile, fileName }) => {
  try {
    const ref = firebase.storage().ref('evidences').child(fileName)

    const promise = new Promise((resolve) => {
      ref.put(blobFile).then(async () => {
        firebase
          .storage()
          .ref(`evidences/${fileName}`)
          .getDownloadURL()
          .then((imageUrl) => {
            return resolve(imageUrl)
          })
      })
    })

    return promise
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * DELETE FILE IN STORAGE
 *
 * @param {array} evidences names files
 */
export const deleteFilesInStorage = async (groupId, draftId, filesNames) => {
  try {
    const drafts = await getDraftsByGroup(groupId)
    if (drafts.length) {
      for (const draft of drafts) {
        for (const evidence of draft.evidences) {
          const nameEvidence = evidence.fileNamePath || evidence.name
          for (const fileName of filesNames) {
            if (draftId !== draft.id && fileName === nameEvidence) {
              filesNames = filesNames.filter(
                (fileName) => fileName !== nameEvidence
              )

              continue
            }
          }
        }
      }
    }

    filesNames.forEach((fileName) => {
      const ref = firebase.storage().ref('evidences').child(fileName)
      ref.delete()
    })

    return true
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

/**
 * ENABLE PERSISTENCE FIREBASE
 *
 * @return {void}
 */
export const firestoreEnablePersistence = async () =>
  firebase.firestore().enablePersistence()

/**
 * @function firestoreEnableNetwork
 * @see https://firebase.google.com/docs/firestore/manage-data/enable-offline#web-v8
 */
export const firestoreEnableNetwork = async () =>
  firebase.firestore().enableNetwork()

/**
 * @function firestoreDisableNetwork
 * @see https://firebase.google.com/docs/firestore/manage-data/enable-offline#web-v8
 */
export const firestoreDisableNetwork = () =>
  firebase.firestore().disableNetwork()

/**
 * Insertar realizacion
 *
 * @param {json} data data del formulario de la realizacion debe tener el id de la planificacion como activity
 * @param {json} user usuario que esta llenando el formulario
 * @return {json} objeto de la realizacion
 */
export const onSnapshotsInSync = (_id) => {
  // eslint-disable-next-line no-async-promise-executor
  const promise = new Promise(async (resolve) => {
    if (!_id) {
      return resolve(null)
    }
    let data = {}
    firebase
      .firestore()
      .collection('drafts')
      .where('_id', '==', _id)
      .onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
        const docChanges = snapshot.docChanges()
        if (docChanges.length) {
          for (const change of docChanges) {
            if (['added', 'modified'].includes(change.type)) {
              data = {
                id: change.doc.id,
                ...change.doc.data(),
              }
            }
            return resolve({
              _id: data._id ?? _id,
              type: change.type,
              data,
            })
          }
        }
        resolve({ _id })
      })
  })
  return promise
}

/**
 * VERIFY DRAFT DELETE AND DELETE FILE TO STORAGE
 *
 * @param {string} groupActivity activity id
 */
export const onSnapDelete = (groupActivity, removeDrafts) => {
  const unsubscribeOnSnapDelete = firebase
    .firestore()
    .collection('drafts')
    .where('groupActivity', '==', groupActivity)
    .onSnapshot((snapshot) => {
      const docChanges = snapshot.docChanges()
      const changeDelete = docChanges.filter((doc) => doc.type === 'removed')
      const idDrafts = []
      changeDelete.forEach((change) => {
        idDrafts.push(change.doc.id)
      })
      if (idDrafts.length && removeDrafts) {
        removeDrafts(idDrafts)
      }
    })

  return unsubscribeOnSnapDelete
}

/**
 * GET AND SET USER ACTIVE REALTIME DATABASE
 *
 * @param {string} draftId id draft
 * @param {user} user user Active
 * @param {function} disableScreen disableScreen observer
 */
export const getUserActive = async (
  draftId,
  user,
  disableScreen,
  enableScreen
) => {
  const reference = await firebase.database().ref(`/online/${draftId}`)

  let alreadyConnected = false
  let isOtherUserOnDraft = false

  reference.on(
    'value',
    (snapshot) => {
      const value = snapshot.val()

      if (value?._id && value._id !== user._id) {
        isOtherUserOnDraft = true

        disableScreen(value?.name || '')
      } else {
        if (!alreadyConnected) {
          alreadyConnected = true

          reference.set(user)

          reference.onDisconnect().remove()

          enableScreen(isOtherUserOnDraft)
        }
      }
    },
    (errorObject) => {
      console.error('The read failed: ' + errorObject.name)
    }
  )
}

/**
 * DELETE USER ACTIVE
 *
 * @param {json} id draft id
 */
export const deleteUserActive = async (id, userId) => {
  try {
    const reference = firebase.database().ref(`/online/${id}`)

    const snapshot = await reference.get()

    if (snapshot.exists()) {
      const val = snapshot.val()

      if (val._id === userId) {
        reference.remove()
      }
    }
  } catch (error) {
    console.error('Error Firebase', error.message)
  }
}

export const signInAnonymously = async (user) => {
  try {
    const userFirebase = firebase.auth().currentUser
    if (!userFirebase) {
      await firebase.auth().signInAnonymously()
      const nowUser = firebase.auth().currentUser
      await nowUser.updateProfile({ displayName: user._id })
    }
  } catch (error) {
    console.error('error', error)
  }
}

export const signOut = async () => {
  try {
    const userFirebase = firebase.auth().currentUser
    await firebase.auth().signOut()
    await userFirebase.delete()
  } catch (error) {
    console.error('error', error)
  }
}
