import { FC, ReactElement, useContext } from 'react'

import { createStackNavigator } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import { LanguageContext } from '@contextState/language'
import { carbon50, carbon800 } from '@styles/palette'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'
import ActivityList from '@modules/activities/screens/activitiesList/v2'
import ActivityCreate from '@modules/activities/screens/activityCreate/v1'
import OptionalPlanned from '@modules/activities/screens/optionalPlanned/v1'
import CommonActivitiesScreen from '../../../screens/CommonActivitiesScreen'
import CommonActivitiesDetail from '../../../screens/CommonActivitiesDetail'
import ActivityLotsAdd from 'src/screens/v1/ActivityLotsAdd'
import AchievementsActivityList from '@modules/activities/screens/achievementsActivityList/v1'
import AchievementsScreen from '@modules/activities/screens/Achievement/v1/AchievementsScreen'
import SupplyDetail from '../../../screens/v1/supplyDetail/index'
import ActivityCreateConfirm from '@modules/activities/screens/ActivityCreateConfirm/v1'
import activityTypesConst from '@constants/activityTypes'
import { SelectItemsToParameters } from '@common/screens'
import { getTitleActivityType } from '../utils'
import { GlobalStateInterface } from '@store/interfaces'
import ActivityMinimumUnitField from '@modules/activities/screens/ActivityMinimumUnitField'
import LotsSelectorScreen from '../../../screens/LotsSelectorScreen'
import FarmsListSelectByCrop from '@modules/farms/screens/FarmsListSelectByCrop'
import FilterActivitiesScreen from '@modules/activities/screens/Filter/v1'
import ModalErrorsDeepLinks from '@common/ModalErrorsDeepLinks'
import { SelectItemsWithMultiLevels } from '@common/screens/SelectItemsWithMultiLevels'
import SupplySelector from 'src/screens/v1/supplySelector'
import SupplyLoaderScreen from '@modules/activities/screens/SupplyLoader/v1/SupplyLoaderScreen'
import CreateGenericSupplyV2 from '@modules/supplies/screens/CreateGenericSupply/v2'
import CollaboratorsSelectorScreen from 'src/screens/CollaboratorsSelectorScreen'
import WebView from '@modules/webView/screens/WebView'

const Stack = createStackNavigator()

export const getActivityStackScreens = () => {
  const { activityTypes } = useSelector(
    (state: GlobalStateInterface) => state.activityTypesReducer
  )
  const { t } = useContext(LanguageContext)

  const activityStacks = [
    {
      name: RouterNames.ACTIVITY_LIST,
      component: ActivityList,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_19'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_19')}
            goBack
            showFiltersActivities
          />
        ),
      },
    },
    {
      name: RouterNames.ACTIVITY_LIST_FILTER,
      component: FilterActivitiesScreen,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.FILTERS'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.FILTERS')}
            close
            showClearOutFiltersActivities
          />
        ),
      },
    },
    {
      name: RouterNames.ACTIVITY_CREATE,
      component: ActivityCreate,
      options: ({ route }: any) => {
        const activityNameTitle =
          route?.params?.activityTypeName ??
          getTitleActivityType(activityTypes, route.params.tag)

        return {
          title: t(
            route.params.isDirectAchievement
              ? 'NAVIGATION.STACK_NAVIGATOR.TEXT_61'
              : 'NAVIGATION.STACK_NAVIGATOR.TEXT_46',
            {
              activityType: activityNameTitle,
              Realize: activityNameTitle,
            }
          ),
          header: (props: any) => (
            <Header
              {...props}
              title={t(
                route.params.isDirectAchievement
                  ? 'NAVIGATION.STACK_NAVIGATOR.TEXT_61'
                  : 'NAVIGATION.STACK_NAVIGATOR.TEXT_46',
                {
                  activityType: activityNameTitle,
                  Realize: activityNameTitle,
                }
              )}
              goBack
            />
          ),
        }
      },
    },
    {
      name: RouterNames.ACTIVITY_UPDATE,
      component: ActivityCreate,
      options: ({ route }: any) => {
        const activityNameTitle =
          route?.params?.activityTypeName ??
          getTitleActivityType(activityTypes, route.params.tag)

        return {
          title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_47', {
            activityType: activityNameTitle,
          }),
          header: (props: any) => (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_47', {
                activityType: activityNameTitle,
              })}
              goBack
            />
          ),
        }
      },
    },
    {
      name: RouterNames.ACTIVITY_FINISHED,
      component: ActivityCreate,
      options: ({ route }: any) => {
        const activityNameTitle =
          route?.params?.activityTypeName ??
          getTitleActivityType(activityTypes, route.params.tag)

        const title = t('NAVIGATION.STACK_NAVIGATOR.TEXT_48', {
          activityType: activityNameTitle,
        })

        return {
          title,
          header: (props: any) => {
            return <Header {...props} title={title} goBack />
          },
        }
      },
    },
    {
      name: RouterNames.ACTIVITY_COMMON_OPTIONAL_PLANED,
      component: OptionalPlanned,
      options: ({ route }: any) => {
        return {
          header: (props: any) => {
            const activityTypeName =
              props?.route?.params?.activityTypeName ??
              props.scene?.route?.params?.activityTypeName
            const title = `${t(
              'NAVIGATION.STACK_NAVIGATOR.ACTIVITY'
            )} ${activityTypeName}`
            return <Header {...props} title={title} goBack />
          },
        }
      },
    },
    {
      name: RouterNames.ACTIVITY_COMMON,
      component: CommonActivitiesScreen,
      options: {
        header: (props: any) => {
          const { route } = props
          const title = `${t('NAVIGATION.STACK_NAVIGATOR.PLAN')} ${
            route?.params?.activityTypeName ??
            props.scene?.route?.params?.activityTypeName
          }`

          return <Header {...props} title={title} goBack />
        },
      },
    },
    {
      name: RouterNames.ACTIVITY_COMMON_DETAIL,
      component: CommonActivitiesDetail,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_41'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_41')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.ACTIVITY_LOT_ADD,
      component: ActivityLotsAdd,
      options: ({ route }: any) => {
        const title = t('VIEWS.ACHIEVEMENT_LOTS_ADD.TEXT_1')

        return {
          title,
          header: (props: any) => {
            const { previous } = props
            const title =
              previous?.route?.params?.tag ===
              activityTypesConst.ACT_VERIFICATION.key
                ? t('VIEWS.ACHIEVEMENT_LOTS_ADD.TEXT_7')
                : t('VIEWS.ACHIEVEMENT_LOTS_ADD.TEXT_1')

            return <Header {...props} title={title} goBack />
          },
        }
      },
    },
    {
      name: RouterNames.ACTIVITY_ACHIEVEMENT_LIST,
      component: AchievementsActivityList,
      options: ({ route }: any) => {
        let mode = ''

        const names: Record<string, string> = {
          sowing: t('NAVIGATION.STACK_NAVIGATOR.TEXT_31'),
          tillage: t('NAVIGATION.STACK_NAVIGATOR.TEXT_32'),
          fertilization: t('NAVIGATION.STACK_NAVIGATOR.TEXT_33'),
          application: t('NAVIGATION.STACK_NAVIGATOR.TEXT_34'),
          harvest: t('NAVIGATION.STACK_NAVIGATOR.TEXT_35'),
        }

        mode = names[route?.params?.mode]

        return {
          title: mode,
          header: (props: any) => {
            return <Header {...props} title={mode} goBack />
          },
        }
      },
    },
    {
      name: RouterNames.ACHIEVEMENT_SCREEN,
      component: AchievementsScreen,
      options: ({ route }: any) => {
        let mode = ''

        const names: Record<string, string> = {
          ACT_SOWING: t('NAVIGATION.STACK_NAVIGATOR.TEXT_36'),
          ACT_TILLAGE: t('NAVIGATION.STACK_NAVIGATOR.TEXT_37'),
          ACT_FERTILIZATION: t('NAVIGATION.STACK_NAVIGATOR.TEXT_38'),
          ACT_APPLICATION: t('NAVIGATION.STACK_NAVIGATOR.TEXT_39'),
          ACT_HARVEST: t('NAVIGATION.STACK_NAVIGATOR.TEXT_40'),
        }

        mode = names[route?.params?.tag]

        return {
          title: mode,
          header: (props: any) => {
            return <Header {...props} title={mode} goBack />
          },
        }
      },
    },
    {
      name: RouterNames.SUPPLY_DETAIL,
      component: SupplyDetail,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_44'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_44')}
            goBack
            titleStyle={{
              fontSize: 18,
              fontFamily: 'Inter_500_medium',
              fontWeight: '500',
              lineHeight: 28,
              color: carbon800,
            }}
          />
        ),
      },
    },
    {
      name: RouterNames.SEARCHER_SUPPLIES,
      component: SupplySelector,
      options: {
        title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_9'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.INDEX_PRINCIPAL.TEXT_9')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.SUPPLY_LOADER,
      component: SupplyLoaderScreen,
      options: {
        title: t('ACTIVITIES.SUPPLIES_ACTIVITY.SELECTED_INPUT'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('ACTIVITIES.SUPPLIES_ACTIVITY.SELECTED_INPUT')}
            goBack
            titleStyle={{
              fontSize: 18,
              fontFamily: 'Inter_500_medium',
              fontWeight: '500',
              lineHeight: 28,
            }}
          />
        ),
      },
    },
    {
      name: RouterNames.CREATE_GENERIC_SUPPLY,
      component: CreateGenericSupplyV2,
      options: {
        title: t('VIEWS.SUPPLIES.CREATE_NEW_FERTILIZER'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.SUPPLIES.CREATE_NEW_FERTILIZER')}
            goBack
            containerStyles={{ backgroundColor: carbon50 }}
            titleStyle={{
              fontFamily: 'Inter_500_medium',
              fontSize: 18,
              fontWeight: '500',
              lineHeight: 28,
              color: carbon800,
            }}
          />
        ),
      },
    },
    {
      name: RouterNames.ACTIVITY_CREATE_CONFIRM,
      component: ActivityCreateConfirm,
      options: {
        title: t('VIEWS.ACTIVITY_CREATE_CONFIRM.NAVIGATION.TITLE'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('VIEWS.ACTIVITY_CREATE_CONFIRM.NAVIGATION.TITLE')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.SELECT_ITEMS_TO_PARAMETER,
      component: SelectItemsToParameters,
      options: ({ route, scene }: any) => {
        const title = route?.params?.title ?? scene?.route?.params?.title

        return {
          title: '',
          header: (props: any) => {
            return <Header {...props} title={title} goBack />
          },
        }
      },
    },
    {
      name: RouterNames.SELECT_ITEMS_WITH_MULTI_LEVELS,
      component: SelectItemsWithMultiLevels,
      options: ({ route, scene }: any) => {
        const title = route?.params?.title ?? scene?.route?.params?.title

        return {
          title: '',
          header: (props: any) => {
            return <Header {...props} title={title} goBack />
          },
        }
      },
    },
    {
      name: RouterNames.ACTIVITY_MINIMUM_UNIT_LOT,
      component: ActivityMinimumUnitField,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_42'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_42')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.ACTIVITY_LOT_SELECTOR,
      component: LotsSelectorScreen,
      options: {
        title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_7'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.INDEX_PRINCIPAL.TEXT_7')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.ACTIVITY_FARMS_LIST_SELECT_BY_CROP,
      component: FarmsListSelectByCrop,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.ACTIVITY_MODAL_ERROR_DEEP_LINK,
      component: ModalErrorsDeepLinks,
      options: {
        header: (props: any) => <Header {...props} title={''} />,
      },
    },
    {
      name: RouterNames.ACTIVITY_COLLABORATOR_SELECTOR,
      component: CollaboratorsSelectorScreen,
      options: {
        title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_8'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.INDEX_PRINCIPAL.TEXT_8')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.WEB_VIEW,
      component: WebView,
      options: {
        header: (props: any) => {
          const title =
            props?.route?.params?.title ?? props.scene?.route?.params?.title

          return <Header {...props} title={title} goBack />
        },
      },
    },
  ]

  return activityStacks
}

export const ActivityStack: FC<any> = (): ReactElement | null => {
  const stacks = getActivityStackScreens()

  return (
    <Stack.Navigator initialRouteName={RouterNames.ACTIVITY_LIST}>
      {stacks.map((stack: any, key) => (
        <Stack.Screen {...stack} key={`ACTIVITY_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
