export default {
  WORK_ORDER: 'Orden de trabajo',
  SHARE: {
    TITLE: 'Ucropit',
    I_SEND_THE_FOLLOWING_WORK_ORDE: 'Envío la siguiente orden de trabajo:',
    LABOR: 'Labor: %{labor}',
    CROP: 'Cultivo: %{crop}',
    TOTAL_PLANNED_AREA: 'Superficie total planificada: %{total_planned_area}',
    ESTIMATED_START: 'Inicio estimado: %{estimated_start}',
    FOR_MORE_DETAILS: 'Para más detalles, ingresa a %{url}',
  },
}
