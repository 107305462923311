import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Constants from 'expo-constants'
import AsyncStorage from '@react-native-async-storage/async-storage'

interface RequestOptions {
  method?: string
  url: string
  data?: any
  params?: any
  headers?: any
  version?: string
  needAuthorizationHeaders?: boolean
  domainUrl?: string
  prefix?: string
  apiUrl?: string
}

const EXPO_API_HOST =
  Constants?.expoConfig?.extra?.EXPO_API_HOST ?? process.env.EXPO_API_HOST ?? ''
export const API_URL = EXPO_API_HOST.replace('/v1', '')

export const makeRequest = async ({
  method = 'GET',
  url,
  data = [],
  params = {},
  headers = {},
  version = 'v1',
  needAuthorizationHeaders = true,
  prefix,
  apiUrl = API_URL,
}: RequestOptions): Promise<AxiosResponse> => {
  try {
    headers.Accept = 'application/json'

    if (needAuthorizationHeaders) {
      const token = await AsyncStorage.getItem('USER_TOKEN')
      headers.Authorization = `Bearer ${token}`
    }

    const locale = await AsyncStorage.getItem('language')

    if (locale) {
      headers['Accept-Language'] = locale
    }
    const prefixUrl = prefix ? `/${prefix}` : ''
    const urlConfig = `${apiUrl}${prefixUrl}/${version}/${url}`

    const options: AxiosRequestConfig = {
      method,
      url: urlConfig,
      data,
      params,
      headers,
    }

    if (method === 'GET' || method === 'get') {
      delete options.data
    }

    const response: AxiosResponse = await Axios(options)

    return response
  } catch (err: any) {
    const { status = 500, data = {} } = err?.response ?? {}
    const { code, message = 'Bad Request' } = data
    let responseError: any

    if (code) {
      responseError = { status, ...data }
    }

    if (status === 400 && !responseError) {
      responseError = { status, ...data }
    }

    if (status !== 401 && status !== 404 && !responseError) {
      responseError = { status, error: 'Network Error' }
    }

    if (!responseError) {
      const error = data?.error || err.toString()
      responseError = { status, error }
    }
    console.warn(`ERROR AT ${apiUrl}/${version}/${url}`, err?.response)

    return Promise.reject(responseError)
  }
}
