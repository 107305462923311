import useFetchPagination from '@common/hooks/useFetchPagination'
import { CommonContext } from '@contextState/common'
import { useAuth, useResponsive } from '@modules/common/hooks'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { canSeeLicense } from '@utils/common'
import { MODAL_ERRORS } from '@utils/constants'
import { useCallback, useContext, useEffect, useState } from 'react'

const TWO_ITEMS = 120
const WIDTH_ITEMS = 60

const useLicenseList = () => {
  const navigation = useNavigation()
  const { config } = useAuth()
  const { errorDeepLink } = useContext(CommonContext)
  const initialParams = {
    currentDate: new Date(),
    companyId: config?.companySelected?._id,
    limit: 8,
  }
  const { widthBody } = useResponsive()
  const [params, setParams] = useState(initialParams)
  const [first, setFirst] = useState(false)
  const [cropTypes, setCropTypes] = useState(null)
  const [licensesFiltered, setLicenseFiltered] = useState(null)
  const { roleSelected } = config
  const numberColumns = parseInt((widthBody - TWO_ITEMS) / WIDTH_ITEMS)

  const { response, isLoading, nextPage, refreshPage, setResponse } =
    useFetchPagination({
      url: `licenses/search-by-company`,
      version: 'v2',
      method: 'GET',
      params,
    })

  useFocusEffect(
    useCallback(() => {
      refreshPage()
      setFirst(false)
    }, [])
  )

  useFocusEffect(
    useCallback(() => {
      if (!canSeeLicense({ roleSelected }) || errorDeepLink) {
        navigation.replace('ModalErrorDeepLinks', {
          error: errorDeepLink ?? MODAL_ERRORS.UNAUTHORIZED,
        })
      }
    }, [])
  )

  useEffect(() => {
    clearFiltersCropTypes()
    setFirst(false)
    setResponse({})
    setCropTypes(null)
  }, [config?.companySelected?._id, config?.languageSelected])

  useEffect(() => {
    if (!first && response?.cropTypes) {
      const firstCropTypes = response.cropTypes.slice(0, numberColumns)
      const allCropTypes = response?.cropTypes
      setCropTypes({ firstCropTypes, allCropTypes })
      setFirst(true)
    }
  }, [response?.cropTypes, first])

  const changeCropType = (cropType) => {
    setParams((prevState) => ({ ...prevState, cropType: cropType._id }))
    const cropTypeSelected = cropTypes.firstCropTypes.find(
      (cType) => cType._id === cropType._id
    )
    if (!cropTypeSelected) {
      const firstCropTypes = [cropType, ...cropTypes.firstCropTypes]
      firstCropTypes.pop()
      setCropTypes((prevState) => ({ ...prevState, firstCropTypes }))
    }
    refreshPage()
  }

  const getLicensesByCompany = () => {
    const companyIdToFilter = initialParams?.companyId
    const filteredLicenses = response?.data?.filter(
      (result) => result?.companyId === companyIdToFilter
    )
    setLicenseFiltered(filteredLicenses)
  }
  useEffect(() => {
    if (response?.data?.length) getLicensesByCompany()
  }, [response?.data])

  const clearFiltersCropTypes = () => {
    setParams(initialParams)
    refreshPage()
  }

  return {
    licenses: response?.data,
    isLoading,
    nextPage,
    refreshPage,
    cropTypes,
    changeCropType,
    clearFiltersCropTypes,
    widthBody,
    numberColumns,
    licensesFiltered,
  }
}

export default useLicenseList
