/**
 * Enum for tri-state values.
 * @readonly
 * @enum {string}
 */
export const CROP_MENU_OPTIONS = {
  CROP_STORY: 'crop_story',
  LICENSES: 'licenses',
  DELETE_DRAFT: 'delete_draft',
  COLLABORATORS: 'collaborators',
  INTEGRATIONS_WITH_THIRD_PARTIES: 'integrations_with_third_parties',
  ENABLE_OFFLINE: 'enable_offline',
  DISABLE_OFFLINE: 'disable_offline',
  FARM_AND_FIELD_MANAGEMENT: 'farm_and_field_management',
  DELETE_CROP: 'delete_crop',
}
