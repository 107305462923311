import { makeRequest } from '../../common/services/makeRequest'

export const fetchPaddyWaterPreseasons = async () => {
  const response = await makeRequest({
    url: `paddy-water-preseason`,
    prefix: 'activities',
    method: 'GET',
  })
  return response.data
}
