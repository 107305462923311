/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsCredentials = {
  Auth: {
    production: false,
    region: process.env.EXPO_AWS_REGION ?? process.env.EXPO_REGION,
    userPoolId:
      process.env.EXPO_AWS_USER_POOL_ID ?? process.env.EXPO_USER_POOL_ID,
    userPoolWebClientId:
      process.env.EXPO_AWS_USER_POOL_CLIENT ??
      process.env.EXPO_USER_POOL_CLIENT,
  },
}
export default awsCredentials
