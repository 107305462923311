import React, { useCallback, useContext, useState, useEffect } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { View, ScrollView, StyleSheet, Platform, Linking } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { Formik } from 'formik'
import ActionSheet from 'react-native-actions-sheet'
import { green500 } from '@common/styles/palette'

import { CropContext } from '@contextState/crop'
import AchievementsForm from '@components/activities/AchievementsForm'
import CollaboratorsButton from '@components/collaborators/CollaboratorsButton'
import ButtonCustom from '@components/common/ButtonCustom'
import CommonList from '@components/common/CommonList'
import Layout from '@components/common/Layout'
import ModalEvidence from '@components/common/ModalEvidence'
import {
  VerifyPin,
  ListSupplies,
  ModalDefault,
} from '@modules/common/components'
import { ModalConfirmDecline } from '@modules/activities/screens/activityCreate/v1/components'
import { SelectorContext } from '@contextState/selectors'
import { CommonContext } from '@contextState/common'
import useModal from '@hooks/useModal'
import useValidatePin from '@hooks/useValidatePin'
import useActionSheetManagement from '@hooks/useActionSheetManagement'
import { useAuth, useStandardDeviation } from '@common/hooks'
import useValidateAchievement from '@hooks/useValidateAchievement'
import useOfflineCommon from '@offline/queries/common'
import useNetwork from '@utils/network'
import { LanguageContext } from '@contextState/language'
import { secondary500, gray12 } from '@styles/palette'
import EvidencesList from '@components/common/v1/EvidencesList'
import { formatDateMoment } from '@utils/date'
import { preventLeaveViewListener } from '@modules/activities/screens/activityCreate/v1/utils'
import { activityNotValidate } from '@services/analytics'
import {
  truncateSurface,
  validateCompanyCountryHasOutlierOff,
} from '@common/utils'
import { useSelector } from 'react-redux'

let auxParams = null

function CommonActivitiesDetail({ route, navigation }) {
  const { tag } = route.params
  const { doRequest, loading } = useNetwork()
  const { activityTypes } = useSelector((state) => state.activityTypesReducer)
  const { currentCrop, setCurrentCropId } = useContext(CropContext)
  const {
    supplySelector,
    collaboratorSelector,
    setLotSelector,
    setSupplySelector,
    setCollaboratorSelector,
    setDestinationSelector,
    destinationSelector,
    setEstablishmentsSelector,
    setSelectorTotalSurface,
    clearSelectors,
  } = useContext(SelectorContext)
  const { user } = useAuth()
  const { config } = user
  const companySelected = config?.companySelected ?? {}
  const country = companySelected?.country || {}
  const { alpha3Code } = country
  const { subTypeActivities } = useContext(CommonContext)
  const [evidences, setEvidences] = useState([])
  const [files, setFiles] = useState([])
  const [modal, setModal] = useState(false)
  const [signer, setSigner] = useState(null)
  const { showIndividuals } = useOfflineCommon()
  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const [initialData, setInitialData] = useState({
    lots: '',
    surface: '',
    dateAchievement: new Date(),
  })
  const [activityType, setActivityType] = useState('')
  const { t, i18n } = useContext(LanguageContext)
  const { ComponentStandarDeviation, openBackdropStandardDeviation } =
    useStandardDeviation({ t })

  const { isOpenModal, actionSheetRef, openActionSheet, closeActionSheet } =
    useActionSheetManagement()
  const [isFetchingAchievement, setIsFetchingAchievement] = useState(true)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { isModalVisible, toggleModal, closeModal } = useModal()
  const [isActivityNotValidate, setIsActivityNotValidate] = useState(true)
  const [dataAchievement, setDataAchievement] = useState(null)
  const [canShowOutlier, setCanShowOutlier] = useState(false)

  const {
    loading: loadingValidate,
    setParams,
    validateAchievement,
  } = useValidateAchievement()

  /**
   * INIT FUNCTIONS
   */

  /**
   * VALIDATE IF USER ATTEMPT GO BACK WITHOUT SAVE USING NAVIGATOR IN THE APP
   *
   */
  const beforeRemoveListener = useCallback(() => {
    if (isConnected && isActivityNotValidate) {
      activityNotValidate({
        activityType: tag,
        date: new Date(),
        userId: user._id,
        email: user.email,
      })
    }
  }, [isActivityNotValidate])
  /**
   * VALIDATE IF USER ATTEMPT LEAVE OR REFRESH WITHOUT SAVE ON WEB
   *
   */
  const beforeunloadListener = useCallback(() => {
    if (isConnected && isActivityNotValidate) {
      activityNotValidate({
        activityType: tag,
        date: new Date(),
        userId: user._id,
        email: user.email,
      })
    }
  }, [isActivityNotValidate])

  useFocusEffect(
    useCallback(() => {
      if (currentCrop) {
        fetchAchievement()
        setActivityType(activityTypes.find((el) => el.tag === route.params.tag))
        return () => true
      }
    }, [currentCrop])
  )

  useFocusEffect(
    useCallback(() => {
      setCurrentCropId(route?.params?.id)
    }, [isConnected, route?.params?.id])
  )

  useEffect(() => {
    return () => {
      clearSelectors()
    }
  }, [])

  /**
   * VALIDATE SHOW/HIDE OUTLIER WHEN NOT IS REALIZATED
   */
  useEffect(() => {
    setCanShowOutlier(
      dataAchievement?.signers.some((signer) => !signer.signed) &&
        !validateCompanyCountryHasOutlierOff(alpha3Code)
    )
  }, [dataAchievement])

  /**
   * ADD LISTENERS FOR PREVENT USER LEAVE SCREEN WITHOUT SAVE
   *
   * ABLE/DISABLE SAVE DRAFT BUTTON IF HAVE ANY CHANGE
   */
  useEffect(() => {
    if (isConnected && signer && !signer.signed) {
      preventLeaveViewListener(
        navigation,
        beforeRemoveListener,
        beforeunloadListener
      )
    }
  }, [signer, isActivityNotValidate])

  const fetchAchievement = useCallback(async () => {
    setIsFetchingAchievement(true)

    let achievement

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `achievements/${route.params.achievement}`,
      })

      achievement = response.data
    } else {
      const offlineData = await showIndividuals(
        'activities',
        route.params.activity
      )

      achievement = offlineData.achievements.find(
        (el) => el._id === route.params.achievement
      )
    }

    let signer
    const listSameSigner = achievement.signers.filter(
      (el) => el.userId === user._id
    )
    const singleSigner = achievement.signers.find(
      (el) => el.userId === user._id
    )
    const signerCount = listSameSigner.length

    if (signerCount > 1) {
      const signerNotSigned = listSameSigner.filter((signer) => !signer.signed)
      signer = signerNotSigned.length ? signerNotSigned[0] : singleSigner
    } else {
      signer = singleSigner
    }

    setSigner(signer)
    setDataAchievement(achievement)

    const result = {
      lots: {
        value: achievement.lots,
        label: `${achievement.lots.length} ${t(
          'COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_1'
        )}`,
      },
      surface: truncateSurface(achievement.surface),
      dateAchievement: {
        value: achievement.dateAchievement,
        label: formatDateMoment(
          achievement.dateAchievement,
          'DD/MMM/YYYY',
          i18n.locale,
          true
        ),
      },
    }

    const valueSubtype = subTypeActivities.find(
      (subType) => subType.value === achievement.subTypeActivity
    )

    if (valueSubtype) {
      result.subTypeActivity = {
        label: valueSubtype.label,
        value: valueSubtype.value,
      }
    }

    setInitialData({ ...result })

    setLotSelector(achievement.lots.map((el) => el._id))

    setEvidences(
      achievement.files.map((el) => ({
        ...el,
        date: el.date,
        description: el.description,
        name: el.name,
        persisted: true,
      }))
    )

    setSupplySelector(achievement.supplies)

    setDestinationSelector(achievement.destination)

    setFiles(achievement.files.map((el) => ({ ...el, persisted: true })))

    setCollaboratorSelector(
      achievement.signers.map((el) => ({
        userId: el.userId,
        fullName: el.fullName,
        email: el.email,
        type: el.type || 'Colaborador',
        signed: el.signed,
      }))
    )

    const newEstablishments = []

    if (achievement.lotsWithSurface && achievement.lotsWithSurface.length) {
      achievement.lotsWithSurface.forEach((element) => {
        let establishmentName = ''

        const establishments = currentCrop?.establishments ?? currentCrop?.lots

        const lotsInCrop = establishments.find((subElement) => {
          const lots = subElement.lots ?? subElement.data

          return lots.find(
            (subSubElement) => subSubElement._id === element.lot._id
          )
        })

        if (lotsInCrop) {
          establishmentName = lotsInCrop.tag
        }

        if (!newEstablishments.length) {
          newEstablishments.push({
            tag: establishmentName,
            lots: [
              {
                ...element,
                ...element.lot,
                surfaceRealized: element.surfaceAchievement,
              },
            ],
          })
        } else {
          let exist = false

          newEstablishments.map((subElement) => {
            if (subElement.tag === establishmentName) {
              subElement.lots.push({
                ...element,
                ...element.lot,
                surfaceRealized: element.surfaceAchievement,
              })

              exist = true
            }
          })

          if (!exist) {
            newEstablishments.push({
              tag: establishmentName,
              lots: [
                {
                  ...element,
                  ...element.lot,
                  surfaceRealized: element.surfaceAchievement,
                },
              ],
            })
          }
        }
      })
    } else {
      achievement.lots.forEach((element) => {
        let establishmentName = ''

        const establishments = currentCrop.establishments ?? currentCrop?.lots

        const lotsInCrop = establishments.find((subElement) => {
          const lots = subElement.lots ?? subElement.data

          return lots.find((subSubElement) => subSubElement._id === element._id)
        })

        if (lotsInCrop) {
          establishmentName = lotsInCrop.tag
        }

        if (!newEstablishments.length) {
          newEstablishments.push({
            tag: establishmentName,
            lots: [
              {
                ...element,
                surfaceRealized: element.surface,
              },
            ],
          })
        } else {
          let exist = false

          newEstablishments.map((subElement) => {
            if (subElement.tag === establishmentName) {
              subElement.lots.push({
                ...element,
                surfaceRealized: element.surface,
              })

              exist = true
            }
          })

          if (!exist) {
            newEstablishments.push({
              tag: establishmentName,
              lots: [
                {
                  ...element,
                  surfaceRealized: element.surface,
                },
              ],
            })
          }
        }
      })
    }

    setEstablishmentsSelector(newEstablishments)

    setSelectorTotalSurface(truncateSurface(achievement.surface))

    setIsFetchingAchievement(false)
  })

  function handleSelectEvidence({ values, file }) {
    setEvidences([...evidences, { ...values }])
    setFiles([...files, { file }])
  }

  const callbackPin = () => {
    successPin(auxParams)
  }

  async function sign(id, updatedPin = false) {
    const params = {
      crop: route.params.id,
      activity: route.params.activity,
      achievements: id,
      mode: route.params.mode,
      tag: route.params.tag,
    }
    setParams(params)
    auxParams = params
    if (config.hasPin || updatedPin) {
      if (verifyExpirationPin()) {
        toggleModal(true)
      } else {
        const { validate } = await geTokenAuthPin()
        if (!validate) {
          toggleModal(true)
          return
        }
        successPin(params)
      }
    } else {
      navigation.navigate('CreatePin', {
        callbackPin,
      })
    }
  }

  const successPin = async (data = null) => {
    try {
      await validateAchievement(typeof data === 'object' ? data : null)
      closeModal()
      setIsActivityNotValidate(false)
      navigation.goBack()
    } catch (error) {
      console.error('error', error.message)
      closeModal()
    }
  }

  function navigateToSupplyDetail(supplyId, key) {
    navigation.navigate('SupplyDetail', {
      id: supplyId,
      key: key,
      readOnly: true,
    })
  }

  if (isFetchingAchievement) {
    return (
      <ActivityIndicator size='large' color={green500} style={styles.loader} />
    )
  }

  const onPressEvidenceFile = (evidence) => {
    let url
    if (evidence.persisted || evidence.fileId) {
      url = evidence?.file
      if (/^http/.test(evidence.path)) {
        url = evidence.path
      }
    }
    if (url) {
      Platform.OS === 'web' ? window.open(url, '_blank') : Linking.openURL(url)
    }
  }

  return (
    <>
      <Formik initialValues={initialData}>
        {({ ...rest }) => {
          return (
            <View style={styles.formInner}>
              <ScrollView contentContainerStyle={styles.container}>
                <Layout>
                  <AchievementsForm
                    {...rest}
                    navigation={navigation}
                    route={route}
                    activityType={activityType}
                    readOnly
                  />
                  {destinationSelector &&
                    destinationSelector.map((el, key) => (
                      <CommonList
                        key={key}
                        index={key}
                        icon={'map-marker'}
                        data={{
                          name: 'Destino',
                          description: `${el.tonsHarvest} ${el.label} - ${el.destinationAddress}`,
                        }}
                      />
                    ))}
                  <ListSupplies
                    supplies={supplySelector}
                    navigateToSupplyDetail={navigateToSupplyDetail}
                    areaUnit={currentCrop.areaUnit}
                    canShowOutlier={canShowOutlier}
                    t={t}
                    outlierLevelSowing={dataAchievement?.outlierLevelSowing}
                    outlierActiveIngredientsLevel={
                      dataAchievement?.outlierActiveIngredientsLevel
                    }
                    outlierFertilizerLevel={
                      dataAchievement?.outlierFertilizerLevel
                    }
                    openBackdropStandardDeviation={
                      openBackdropStandardDeviation
                    }
                  />
                  <EvidencesList
                    evidences={files}
                    disabled
                    onPress={onPressEvidenceFile}
                  />
                  <CollaboratorsButton
                    title={t('COMPONENTS.COLLABORATORS_BUTTON.COLLABORATORS')}
                    quantity={collaboratorSelector.length}
                    onPress={() => {
                      navigation.navigate('CollaboratorsSelectorModal', {
                        id: route.params.id,
                        status: 'readOnly',
                      })
                    }}
                  />
                </Layout>
              </ScrollView>
              {signer && route.params.canWrite && (
                <>
                  <ButtonCustom
                    isLoading={loading}
                    disabled={signer.signed || !isConnected}
                    onPress={() => sign(route.params.achievement)}
                    styles={styles.button}
                  >
                    {signer.signed
                      ? t('VIEWS').COMMON_ACTIVITIES_DETAILS.TEXT_2
                      : t('VIEWS').COMMON_ACTIVITIES_DETAILS.TEXT_3}
                  </ButtonCustom>

                  <ButtonCustom
                    isLoading={loading}
                    disabled={signer.signed || !isConnected}
                    onPress={openActionSheet}
                    styles={
                      !isConnected || signer.signed
                        ? styles.rejectButtonDisabled
                        : styles.rejectButton
                    }
                    labelStyle={
                      !isConnected || signer.signed
                        ? styles.rejectTextButtonDisabled
                        : styles.rejectTextButton
                    }
                  >
                    {t('VIEWS').COMMON_ACTIVITIES_DETAILS.TEXT_4}
                  </ButtonCustom>
                </>
              )}
            </View>
          )
        }}
      </Formik>

      <VerifyPin
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
        onSuccess={successPin}
        externalLoading={loadingValidate}
      />

      <ModalEvidence
        visible={modal}
        onSelect={handleSelectEvidence}
        handleClose={() => setModal(false)}
      />
      <ComponentStandarDeviation />

      {Platform.OS !== 'web' ? (
        <ActionSheet ref={actionSheetRef} containerStyle={styles.actionStyles}>
          <View style={styles.containerActionSheet}>
            <ModalConfirmDecline
              closeActionSheet={closeActionSheet}
              onSuccess={() => navigation.goBack()}
              paramsRequest={{
                method: 'PATCH',
                url: `achievements/${route.params.achievement}/reject`,
                version: 'v2',
                data: {
                  cropId: route.params.id,
                  activityId: route.params.activity,
                },
              }}
            />
          </View>
        </ActionSheet>
      ) : (
        <ModalDefault
          isModalVisible={isOpenModal}
          closeModal={closeActionSheet}
          hasIconCloseModal={false}
        >
          <ModalConfirmDecline
            modal
            closeActionSheet={closeActionSheet}
            onSuccess={() => navigation.goBack()}
            paramsRequest={{
              method: 'PATCH',
              url: `achievements/${route.params.achievement}/reject`,
              version: 'v2',
              data: {
                cropId: route.params.id,
                activityId: route.params.activity,
              },
            }}
          />
        </ModalDefault>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  formInner: {
    flex: 1,
    padding: 16,
  },
  listItem: { paddingLeft: 0 },
  rejectButton: {
    marginTop: 16,
    borderColor: secondary500,
    backgroundColor: 'transparent',
    borderWidth: 1,
    elevation: 0,
  },
  rejectTextButton: {
    color: secondary500,
    textTransform: 'uppercase',
  },
  rejectButtonDisabled: {
    marginTop: 16,
    borderColor: gray12,
    backgroundColor: 'transparent',
    borderWidth: 1,
    elevation: 0,
  },
  rejectTextButtonDisabled: {
    color: gray12,
    textTransform: 'uppercase',
  },
  containerActionSheet: {
    marginHorizontal: 10,
  },
  actionStyles: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
})

export default CommonActivitiesDetail
