import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Card } from '@modules/common/components'
import { carbon400, blackHighEmphasis } from '@styles/palette'

interface CardInfoProps {
  items: IItem[]
  header?: React.JSX.Element
}

export interface IItem {
  title: string
  textValue: string
}

export const CardInfoParameters = ({ items, header }: CardInfoProps) => {
  return (
    <Card>
      {header ? header : null}
      {items.map((item) => (
        <View style={styles.infoContainer} key={item.title}>
          <Text style={styles.textTitle}>{item.title}</Text>

          <Text style={styles.textBold}>{item.textValue}</Text>
        </View>
      ))}
    </Card>
  )
}

const styles = StyleSheet.create({
  infoContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  textTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: carbon400,
    marginBottom: 2,
  },
  textBold: {
    fontSize: 16,
    fontWeight: '700',
    color: blackHighEmphasis,
    marginBottom: 18,
  },
})
