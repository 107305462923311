import { makeRequest } from '@common/services'
import {
  setIrrigationPowerSources,
  setIrrigationWaterSources,
} from '@store/slices'
import { useDispatch } from 'react-redux'

export const useIrrigationData = () => {
  const dispatch = useDispatch()
  const fetchIrrigationPowerSources = async () => {
    const response = await makeRequest({
      url: 'commons/irrigation-power-sources',
    })
    const result = response.data.map((el: any) => ({
      label: el.keyLabel,
      value: el._id,
    }))
    dispatch(setIrrigationPowerSources(result))
  }

  const fetchIrrigationWaterSources = async () => {
    const response = await makeRequest({
      url: 'commons/irrigation-water-sources',
    })
    const result = response.data.map((el: any) => ({
      label: el.keyLabel,
      value: el._id,
    }))
    dispatch(setIrrigationWaterSources(result))
  }

  const fetchIrrigationData = () => {
    fetchIrrigationPowerSources()
    fetchIrrigationWaterSources()
  }
  return {
    fetchIrrigationData,
  }
}
