import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { getDaysDifference } from '@modules/common/utils'
import activityTypesConst from '@constants/activityTypes'
import {
  blackMediumEmphasis,
  blackHighEmphasis,
  yellow200,
  brown400,
} from '@styles/palette'

import { NotificationChip } from '../'
import { outlierLevels } from '../../utils'
import { useSelector } from 'react-redux'

export const NotificationPendingTask = ({
  isRead,
  createdAt,
  realizedAt,
  activityType,
  surface,
  areaUnit,
  cropName,
  farms,
  outlierLevel = 0,
}) => {
  const { t } = useContext(LanguageContext)
  const { activityTypes } = useSelector((state) => state.activityTypesReducer)

  const currentActivityType = activityTypes.find(
    (activity) => activity.tag === activityType
  )
  const daysDifference = getDaysDifference(
    new Date(),
    new Date(createdAt ?? realizedAt)
  )

  const dateTranslateOptions = {
    daysQuantity: daysDifference,
    daysWord:
      daysDifference === 1
        ? t(
            'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.SINGULAR_DAYS'
          )
        : t(
            'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.PLURAL_DAYS'
          ),
  }

  let createdAtText = ''

  if (activityType === activityTypesConst.ACT_HARVEST.key) {
    createdAtText = t(
      'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.HARVESTED_AT',
      dateTranslateOptions
    )
  } else if (activityType === activityTypesConst.ACT_MONITORING.key) {
    createdAtText = t(
      'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.MONITORED_AT',
      dateTranslateOptions
    )
  } else {
    createdAtText = t(
      'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.EXECUTED_AT',
      dateTranslateOptions
    )
  }

  const farmsText = farms
    .map(
      (farm) =>
        `${farm.name} ${farm.surface} ${areaUnit} - ${farm.fields.length} ${
          farm.fields.length === 1
            ? t(
                'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.SINGULAR_FIELDS'
              )
            : t(
                'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.PLURAL_FIELDS'
              )
        }`
    )
    .join(', ')

  return (
    <View style={styles.container}>
      <Text style={styles.createdAtText}>{createdAtText}</Text>

      <View style={styles.messageContainer}>
        <Text style={[styles.messageText, isRead ? styles.textViewed : {}]}>
          {t(
            'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.MESSAGE_1'
          )}
        </Text>

        <Text style={[styles.messageTextBold, isRead ? styles.textViewed : {}]}>
          {t(
            'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.MESSAGE_2'
          )}
        </Text>

        <Text style={[styles.messageText, isRead ? styles.textViewed : {}]}>
          {t(
            'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.MESSAGE_3'
          )}
        </Text>

        <Text style={[styles.messageTextBold, isRead ? styles.textViewed : {}]}>
          {t(
            'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.MESSAGE_4',
            {
              activityType: currentActivityType.label,
            }
          )}
        </Text>

        <Text style={[styles.messageText, isRead ? styles.textViewed : {}]}>
          {t(
            'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.MESSAGE_5',
            {
              surface,
              areaUnit,
            }
          )}
        </Text>
      </View>

      <Text
        style={[styles.cropNameText, isRead ? styles.textViewed : {}]}
        numberOfLines={1}
        ellipsizeMode='tail'
      >
        {cropName}
      </Text>

      <Text style={styles.farmNameText} numberOfLines={1} ellipsizeMode='tail'>
        {farmsText}
      </Text>

      {outlierLevel > outlierLevels.TWO && (
        <NotificationChip
          text={t(
            'VIEWS.NOTIFICATIONS.COMPONENTS.NOTIFICATION_PENDING_TASK.VERIFY_VALUE_MESSAGE'
          )}
          backgroundColor={yellow200}
          textColor={brown400}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  outlierLevelWarning: {
    backgroundColor: '#FCF1CB',
    color: '#A84E20',
  },
  outlierLevelDanger: {
    backgroundColor: 'red',
  },
  container: {
    flex: 1,
  },
  createdAtText: {
    fontSize: 12,
    fontWeight: '400',
    color: blackMediumEmphasis,
  },
  messageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
  },
  messageText: {
    fontSize: 14,
    fontWeight: '400',
    flexShrink: 1,
    color: blackHighEmphasis,
  },
  messageTextBold: {
    fontSize: 14,
    fontWeight: '600',
    flexShrink: 1,
    color: blackHighEmphasis,
  },
  textViewed: {
    color: blackMediumEmphasis,
  },
  cropNameText: {
    fontSize: 12,
    fontWeight: '400',
    color: blackHighEmphasis,
    marginTop: 24,
  },
  farmNameText: {
    fontSize: 12,
    fontWeight: '400',
    color: blackMediumEmphasis,
    marginTop: 4,
  },
})

NotificationPendingTask.propTypes = {
  isRead: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  areaUnit: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  cropName: PropTypes.string.isRequired,
  farms: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      surface: PropTypes.number.isRequired,
      areaUnit: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          surface: PropTypes.number.isRequired,
          areaUnit: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  outlierLevel: PropTypes.number,
}
