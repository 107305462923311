import CODE_FORM from '../../components/CodeForm/translate/es'
export default {
  SECRET_LOGIN_CODE: 'Código secreto de acceso',
  PARAGRAPH_ENTER_YOU_SECRET_CODE:
    'Ingresa tu código secreto de acceso de 6 dígitos que te hemos enviado a ',
  IF_HAVENT_RECEIVED_EMAIL: `Si no has recibido el email, revisa tu casilla de spam.`,
  LOGIN: 'Acceder',
  CODE_INCORRECT: 'El código ingresado es incorrecto.',
  COMPONENTS: {
    CODE_FORM,
  },
}
