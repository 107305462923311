import FIELD_DETAILS from '../../components/FieldDetails/translate/en'
import REMOVE_FIELD_BUTTON from '../../components/RemoveFieldButton/translate/en'
import DISASSOCIATE_FIELD_MODAL from '../../components/DisassociateFieldModal/translate/en'

export default {
  COMPONENTS: {
    FIELD_DETAILS,
    REMOVE_FIELD_BUTTON,
    DISASSOCIATE_FIELD_MODAL,
  },
}
