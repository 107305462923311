import React, { useRef } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import SelectDropdown from 'react-native-select-dropdown'
import * as Animatable from 'react-native-animatable'
import { CommonIcon } from '@common/components/icons'
import {
  blackMediumEmphasis,
  borderInput,
  placeholderDisabled,
  redError,
} from '@styles/palette'
import { SIZES } from '@common/components/icons/interfaces'
import UcropitThema, { white, black } from '@common/styles'
import { InputSelectDefaultProps } from './InputSelectDefault.props'

const DURATION = 200
const NUM_ITERATIONS = 1
export const InputSelectDefault = ({
  options,
  id,
  onBlur,
  onChangeText,
  label,
  placeholder,
  value,
  touched,
  error,
  disabled,
  containerStyle,
  search,
  onChangeSearchInputText,
  selectRef,
  placeholderSearch,
  testID = undefined,
  animatedLabel = true,
  labelStyle,
  dropdownIconStyle,
  selectButtonStyle,
  buttonTextStyle,
}: InputSelectDefaultProps) => {
  const ref = useRef(null)
  React.useEffect(() => {
    if (selectRef) {
      selectRef.current = ref.current
    }
  }, [selectRef, ref])
  const isError = touched && error
  return (
    <View
      style={[styles.container, containerStyle ?? {}]}
      id={id}
      testID={testID}
    >
      {animatedLabel && label && value?.value ? (
        <Animatable.View
          style={styles.containerLabel}
          duration={DURATION}
          iterationCount={NUM_ITERATIONS}
          animation='fadeIn'
        >
          <Text
            style={[
              styles.label,
              labelStyle,
              isError ? styles.errorLabel : {},
              disabled ? styles.labelDisabled : {},
            ]}
          >
            {label}
          </Text>
          <View style={styles.placeholderLabel} />
        </Animatable.View>
      ) : null}

      {!animatedLabel && label ? (
        <Text style={[styles.nonAnimatedLabel, labelStyle]} numberOfLines={1}>
          {label}
        </Text>
      ) : null}

      <SelectDropdown
        search={search}
        data={options.length ? options : []}
        onSelect={onChangeText}
        buttonTextAfterSelection={(selectedItem) => {
          return selectedItem.label
        }}
        rowTextForSelection={(item) => {
          return item.label
        }}
        buttonStyle={[
          styles.button,
          selectButtonStyle,
          isError ? styles.errorButton : {},
        ]}
        buttonTextStyle={[
          styles.buttonText,
          value?.value ? styles.textSelected : {},
          disabled ? styles.buttonTextDisabled : {},
          buttonTextStyle,
        ]}
        defaultButtonText={value?.label || placeholder || ' '}
        defaultValue={value}
        renderDropdownIcon={() => (
          <CommonIcon
            name={'CHEVRON-DOWN'}
            size={SIZES.MEDIUM}
            color={!disabled ? black : blackMediumEmphasis}
            style={[styles.icon, dropdownIconStyle]}
          />
        )}
        dropdownIconPosition='right'
        dropdownStyle={styles.dropdown}
        rowTextStyle={styles.rowText}
        rowStyle={styles.row}
        onBlur={onBlur}
        disabled={disabled}
        searchInputStyle={styles.searchInputStyle}
        searchInputTxtStyle={styles.searchInputTxtStyle}
        onChangeSearchInputText={onChangeSearchInputText}
        ref={selectRef}
        renderSearchInputLeftIcon={() => (
          <CommonIcon
            name={'SEARCH'}
            size={SIZES.MEDIUM}
            color={placeholderDisabled}
          />
        )}
        searchPlaceHolder={placeholderSearch}
      />
      {isError && <Text style={styles.textError}>{error}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  textError: {
    color: redError,
    fontSize: 12,
    marginHorizontal: 14,
    marginVertical: 5,
  },
  container: {
    marginBottom: 20,
  },
  containerLabel: {
    position: 'absolute',
    top: -8,
    left: 11,
    zIndex: 2,
  },
  label: {
    fontSize: 12,
    color: UcropitThema.colors.grayRGB51,
    marginHorizontal: 5,
    zIndex: 4,
  },
  nonAnimatedLabel: {
    paddingLeft: 10,
    fontSize: 18,
    lineHeight: 28,
    fontWeight: '600',
    fontStyle: 'normal',
    color: black,
  },
  placeholderLabel: {
    width: '100%',
    backgroundColor: white,
    position: 'absolute',
    height: 4,
    zIndex: 3,
    marginTop: 8.05,
  },
  errorLabel: {
    color: redError,
  },
  labelDisabled: {
    color: placeholderDisabled,
  },
  button: {
    width: '100%',
    height: 62,
    backgroundColor: white,
    borderWidth: 2,
    borderColor: borderInput,
    borderRadius: 2,
  },
  errorButton: {
    borderColor: redError,
  },
  buttonText: {
    textAlign: 'left',
    fontSize: 15,
    color: blackMediumEmphasis,
  },
  buttonTextDisabled: {
    color: placeholderDisabled,
  },
  dropdownIcon: {
    paddingRight: 20,
  },
  dropdown: {
    padding: 0,
    backgroundColor: white,
    paddingTop: 8,
  },
  rowText: {
    height: 45,
    textAlign: 'left',
    flex: 1,
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    color: UcropitThema.colors.grayRGB51,
    fontSize: 15,
  },
  row: {
    borderBottomColor: white,
    paddingHorizontal: 16,
  },
  textSelected: {
    color: black,
  },
  icon: {
    marginRight: 12,
  },
  searchInputTxtStyle: {
    flex: 1,
    height: '100%',
    textAlignVertical: 'center',
    paddingVertical: 0,
    marginLeft: '-3%',
    color: placeholderDisabled,
  },
  searchInputStyle: {
    width: '95%',
    flex: 1,
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: '0%',
    paddingLeft: 16,
  },
})
