import React, { useContext } from 'react'
import { StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import ButtonCustom from '@components/common/ButtonCustom'
import { primary500 } from '@styles/palette'

export const FieldsAddButton = ({ onSubmit }) => {
  const { t } = useContext(LanguageContext)

  return (
    <ButtonCustom
      onPress={onSubmit}
      styles={styles.button}
      labelStyle={styles.textButton}
    >
      {t('VIEWS.CROP_FARM_LIST.COMPONENTS.FIELDS_ADD_BUTTON.ADD_FIELD_TO_CROP')}
    </ButtonCustom>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: primary500,
    marginVertical: 5,
  },
  textButton: {
    color: 'white',
  },
})

FieldsAddButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
