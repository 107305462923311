import MAP_CARD from '../../components/MapCard/translate/es'
import ACTIVITY_CARD from '../../components/ActivityCard/translate/es'
import ACTIVITY_CARD_REALIZATION from '../../components/ActivityCardRealization/translate/es'
import FARM_LIST from '../../components/FarmList/translate/es'
import ACCORDION_HEADER from '../../components/AccordionHeader/translate/es'
import PLANNED_ACTIVITY_DATE from '../../components/PlannedActivityDate/translate/es'

export default {
  COMPONENTS: {
    MAP_CARD,
    ACTIVITY_CARD,
    ACTIVITY_CARD_REALIZATION,
    FARM_LIST,
    ACCORDION_HEADER,
    PLANNED_ACTIVITY_DATE,
  },
}
