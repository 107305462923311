import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import moment from 'moment'
import { PropTypes } from 'prop-types'

import { LanguageContext } from '@contextState/language'
import { blackHighEmphasis } from '@styles/palette'

export const DraftDescription = ({ draft }) => {
  if (!draft) {
    return false
  }

  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.container}>
      <Text style={styles.draftVersionText}>
        {t('VIEWS.ACTIVITY_CREATE.TEXT_5', {
          version: draft.version,
        })}
      </Text>
      <Text style={styles.draftLastUpdatedText}>
        {t('VIEWS.ACTIVITY_CREATE.TEXT_6', {
          user: draft.updatedByUsername,
          date: moment(draft.updatedAt).format('DD/MM/YYYY'),
          hour: moment(draft.updatedAt).format('HH:mm'),
        })}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#CFD8DC',
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 17.2,
    paddingBottom: 10.8,
    marginTop: 16,
    marginBottom: 28,
    borderRadius: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  draftVersionText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: blackHighEmphasis,
  },
  draftLastUpdatedText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
})

DraftDescription.propTypes = {
  draft: PropTypes.object,
}
