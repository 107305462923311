import { View, StyleSheet } from 'react-native'
import { white } from '@styles/palette'
import { useResponsive } from '../../hooks'
import { WITH_NOTEBOOK } from '@common/constants/width'

export const ResponsiveLayout = ({
  children,
  fullScreen = null,
  maxWidth = WITH_NOTEBOOK,
  backgroundColor = white,
}) => {
  const { isScreenDesktop } = useResponsive()

  return (
    <View style={{ ...styles.container, backgroundColor }}>
      <View
        style={[
          styles.content,
          isScreenDesktop && !fullScreen ? { maxWidth } : {},
        ]}
      >
        {children}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: '100%',
  },
})
