export default {
  TEXT_1: 'Cerrar',
  UNAUTHORIZED: {
    TEXT_1: 'Sin permisos',
    TEXT_2:
      'Para acceder a esta información deberás solicitar permisos al administrador.',
  },
  NOT_AVAILABLE: {
    TEXT_1: 'Verifica el enlace',
    TEXT_2:
      'La información a la que intentas acceder ya no se encuentra disponible.',
  },
  ERROR_UNEXPECTED: {
    TEXT_1: 'No pudimos acceder a la información',
    TEXT_2: 'Intentalo más tarde.',
  },
  EXIST_VERIFICATION_DRAFT: {
    TEXT_1: 'Actividad verificador',
    TEXT_2:
      'Hay una o más actividades de verificación en borrador. No se puede crear una nueva hasta no finalizar al menos una.',
  },
  NO_LOTS_AVAILABLE: {
    TEXT_1: 'Actividad verificador',
    TEXT_2: 'No hay lotes disponibles para generar una nueva verificación.',
  },
}
