import { FC, ReactElement, useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import { LanguageContext } from '@contextState/language'
import { ResponsiveLayout, HeaderOnlyBack } from '@common/components'
import SignIn from '@modules/authentication/screens/SignIn/v2'
import SignUp from '@modules/authentication/screens/SignUp/v1'
import VerifyCode from '@modules/authentication/screens/VerifyCode'

const RootStack = createStackNavigator()
const Stack = createStackNavigator()

export const AuthenticationStack: FC<any> = (): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const stacks = [
    {
      name: 'SignIn',
      component: SignIn,
      options: {
        title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_1'),
        header: () => null,
      },
    },
    {
      name: 'SignUp',
      component: SignUp,
      options: {
        title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_2'),
        header: (props: any) => (
          <HeaderOnlyBack {...props} title={''} testID='back_button' />
        ),
      },
    },
    {
      name: 'VerifyCode',
      component: VerifyCode,
      options: {
        title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_3'),
        header: (props: any) => (
          <HeaderOnlyBack {...props} title={''} testID='back_button' />
        ),
      },
    },
  ]

  const Stacks = () => {
    return (
      <Stack.Navigator initialRouteName={'SignIn'}>
        {stacks.map((stack, key) => (
          <Stack.Screen {...stack} key={`AUTHENTICATION_STACK_${key}`} />
        ))}
      </Stack.Navigator>
    )
  }

  return (
    <ResponsiveLayout>
      <RootStack.Navigator>
        <RootStack.Screen
          name='Authentication'
          options={{ headerShown: false }}
          component={Stacks}
        />
      </RootStack.Navigator>
    </ResponsiveLayout>
  )
}
