import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, Text, View, BackHandler, Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { ScrollView } from 'react-native-gesture-handler'

import { LanguageContext } from '@contextState/language'
import { primary500, white, transparent } from '@styles/palette'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { setEmailWelcomeCompanyList } from '@store/slices'
import { useAuth } from '@common/hooks'

const WelcomeCompany = ({ navigation }) => {
  const { t } = useContext(LanguageContext)
  const { user } = useAuth()

  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const backAction = () => {
      return true
    }

    let backHandler

    if (Platform.OS !== 'web') {
      backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction
      )
    }

    setTimeout(() => setIsReady(true), 300)

    return () => backHandler?.remove()
  }, [])

  const goJoinCompany = () => {
    navigation.navigate('ValidateTaxId')
  }
  const onHandlerSkipNow = () => {
    dispatch(setEmailWelcomeCompanyList({ email: user?.email, hide: true }))
  }
  if (!isReady) {
    return (
      <View style={styles.scaffold}>
        <LoaderCenterContainer />
      </View>
    )
  }

  return (
    <View style={styles.scaffold}>
      <ScrollView style={styles.containerContent}>
        <Text style={styles.title}>{t(`VIEWS.WELCOME_COMPANY.WELCOME`)}</Text>
        <Text style={styles.description}>
          {t(`VIEWS.WELCOME_COMPANY.MANAGE_OR_JOIN_COMPANY`)}
        </Text>
        <ButtonPrimary
          text={t(`VIEWS.WELCOME_COMPANY.MANAGE_NEW_COMPANY`)}
          backgroundColor={primary500}
          colorText={white}
          borderColor={primary500}
          borderRadius={8}
          fontSize={14}
          fontWeight={'700'}
          marginTop={28}
          onPress={goJoinCompany}
          testID={'new_company_button'}
        />
        <ButtonPrimary
          text={t(`VIEWS.WELCOME_COMPANY.JOIN_COMPANY`)}
          backgroundColor={transparent}
          colorText={primary500}
          borderColor={primary500}
          borderRadius={8}
          fontSize={14}
          fontWeight={'700'}
          marginTop={15}
          onPress={goJoinCompany}
          testID={'join_company_button'}
        />
      </ScrollView>
      <View style={styles.containerLink}>
        <Text
          style={styles.link}
          onPress={onHandlerSkipNow}
          testID={'skip_button'}
        >
          {t('VIEWS.WELCOME_COMPANY.SKIP_FOR_NOW')}{' '}
        </Text>
      </View>
    </View>
  )
}

export default WelcomeCompany

const styles = StyleSheet.create({
  scaffold: {
    flex: 1,
    paddingTop: 30,
    backgroundColor: white,
  },
  containerContent: {
    padding: 16,
    marginTop: 32,
  },
  title: {
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 28,
    marginBottom: 16,
  },
  description: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
  },
  containerLink: {
    marginBottom: 48,
  },
  link: {
    textAlign: 'center',
    color: primary500,
    fontWeight: '700',
  },
})
