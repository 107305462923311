import useNetwork from '@utils/network'
import useOfflineCommon from '@offline/queries/common'
import { useSelector } from 'react-redux'

const useFetchFarmListByCrop = () => {
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { doRequest } = useNetwork()
  const { showIndividuals } = useOfflineCommon()

  const fetchFarm = async (cropId, activityId, farmId) => {
    let farmsResult
    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `crops/${cropId}/establishments`,
        params: {
          cropId: cropId,
          activityId: activityId,
        },
      })

      farmsResult = response.data
    } else {
      const cropResponse = await showIndividuals('crops', cropId)

      let establishments
      if (activityId) {
        const offlineActivityData = await showIndividuals(
          'activities',
          activityId
        )
        establishments = cropResponse.establishments.map((element) => ({
          ...element,
          lots: element.lots.filter((lot) =>
            offlineActivityData.lots.some(
              (lotActivity) => lotActivity._id === lot._id
            )
          ),
        }))
      } else {
        establishments = cropResponse.establishments.map((element) => ({
          ...element,
          lots: element.lots,
        }))
      }
      farmsResult = establishments
    }
    const auxFarmSelected = farmsResult.find(
      (fResult) => String(fResult.farm) === String(farmId)
    )
    return { farmSelected: auxFarmSelected, farms: farmsResult }
  }

  return { fetchFarm }
}

export default useFetchFarmListByCrop
