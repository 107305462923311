import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { makeRequest } from '@common/services'
import useOfflineTypes from '@offline/queries/types'
import { GlobalStateInterface } from '@store/interfaces'
import { setCropTypes } from '@store/slices'

export const useCropTypes = () => {
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )
  const { storeType, getTypes } = useOfflineTypes()
  const dispatch = useDispatch()

  const fetchCropTypes = async () => {
    let data = []

    if (isConnected) {
      const response = await makeRequest({ url: 'commons/croptypes' })

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('croptypes', data)
      }
    } else {
      data = await getTypes('croptypes')
    }

    const cropTypes = data.map(
      (cropType: { keyLabel: string; _id: string; key: string }) => ({
        label: cropType.keyLabel,
        value: cropType._id,
        key: cropType.key,
      })
    )

    dispatch(setCropTypes(cropTypes))
  }

  return {
    fetchCropTypes,
  }
}
