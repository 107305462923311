import { Platform } from 'react-native'
import { insertFileToStorage } from '@services/firebase'

import { urlToBlob } from '@utils/files'

export const parseAndSetEvidencesToStorage = async (isConnected, evidences) => {
  for (let evidence of evidences) {
    if (evidence.persisted || evidence.fileId) {
      continue
    }

    if (isConnected) {
      let blobFile

      if (Platform.OS !== 'web') {
        blobFile = await urlToBlob({
          url: evidence.file.uri,
        })
      }

      const fileUrl = await insertFileToStorage({
        blobFile: blobFile ?? evidence.file,
        fileName: evidence.fileNamePath,
      })

      evidence.file = fileUrl
      evidence.persisted = true
    } else {
      evidence = {
        fileName: evidence.name,
        fileNamePath: evidence.fileNamePath,
        fileType: evidence.file.type,
        fileUri: evidence.file.uri,
        description: evidence.description,
        persisted: evidence.persisted,
        date: new Date(evidence.date).toISOString(),
        location: evidence.location ? JSON.stringify(evidence.location) : '',
        meta: evidence.meta ? JSON.stringify(evidence.meta) : '',
        settings: evidence.settings ? JSON.stringify(evidence.settings) : '',
      }
    }
  }

  return evidences
}
