import getDatabase from '../queries/getDataBase'
const db = getDatabase('db.offlinedata')

const ALTER_TABLE_ADD_COLUMNS = 'ALTER TABLE table_name ADD COLUMN'

/**
 *
 * @param {string} tableName
 * @param {Array.<{name: String, type: String}>} columns
 *
 * @return {promise}
 */
export const alterTableAddColumns = async (tableName, columns) => {
  const tableColumns = await getTableColumns('crops')
  const diffColumns = diffBetweenArrays(mapColumns(columns), tableColumns)

  if (!diffColumns.length) return false

  const queries = buildQueryText(tableName, diffColumns)

  if (!queries.length) return false

  const promise = new Promise((resolve) => {
    if (!db) {
      return resolve()
    }

    db.transaction((tx) => {
      for (const query of queries) {
        tx.executeSql(
          query,
          [],
          () => resolve(true),
          (_, err) => console.warn(`ERR ALTER TABLE ${tableName}: ${err}`)
        )
      }
    })
  })

  return promise
}

/**
 *
 * @param {string} tableName
 * @param {array} columns
 *
 * @return {Array.<String>}
 */
const buildQueryText = (tableName, columns) => {
  const ALTER_TABLE_QUERY = ALTER_TABLE_ADD_COLUMNS.replace(
    'table_name',
    tableName
  )

  return columns.map(
    (column) => `${ALTER_TABLE_QUERY} ${column.name} ${column.type};`
  )
}

/**
 *
 * @param {string} tableName
 *
 * @return {promise}
 */
const getTableColumns = (tableName) => {
  const promise = new Promise((resolve) => {
    if (!db) {
      return resolve([])
    }

    db.transaction((tx) => {
      tx.executeSql(
        `PRAGMA table_info(${tableName})`,
        [],
        (_, { rows }) => {
          const results = rows._array ?? Array.from(rows)
          resolve(results)
        },
        (_, err) => console.warn(`ERR GET TABLE COLUMNS ${tableName}: ${err}`)
      )
    })
  })

  return promise
}

/**
 *
 * @param {array} columns
 *
 * @return {Array.<{name: String, type: String}>}
 */
const mapColumns = (columns) => {
  return columns.map((column) => {
    return {
      name: column.name,
      type: column.type,
    }
  })
}

/**
 *
 * @param {array} itemsA
 * @param {array} itemsB
 *
 * @returns {Array.<{name: String, type: String}>}
 */
const diffBetweenArrays = (itemsA, itemsB) => {
  return itemsA.filter(
    (item) => !itemsB.find((column) => column.name === item.name)
  )
}
