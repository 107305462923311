import { Platform } from 'react-native'
import AccordionWeb from './AccordionWeb'
import AccordionMobile from './AccordionMobile'
import { grayInput } from '@styles/palette'

const AccordionItem = ({
  title = '',
  children,
  ContentHeader = undefined,
  titleContainerStyle = {},
  titleStyle = {},
  openByDefault = false,
  extraBodyStyle = {},
  otherParams = {},
  contentTop = false,
  extraPressCustom,
  colorArrow = grayInput,
}) => {
  return Platform.OS === 'web' ? (
    <AccordionWeb
      title={title}
      ContentHeader={ContentHeader}
      titleContainerStyle={titleContainerStyle}
      titleStyle={titleStyle}
      openByDefault={openByDefault}
      extraBodyStyle={extraBodyStyle}
      otherParams={otherParams}
      contentTop={contentTop}
      extraPressCustom={extraPressCustom}
      colorArrow={colorArrow}
    >
      {children}
    </AccordionWeb>
  ) : (
    <AccordionMobile
      title={title}
      ContentHeader={ContentHeader}
      titleContainerStyle={titleContainerStyle}
      titleStyle={titleStyle}
      openByDefault={openByDefault}
      extraBodyStyle={extraBodyStyle}
      otherParams={otherParams}
      contentTop={contentTop}
      extraPressCustom={extraPressCustom}
      colorArrow={colorArrow}
    >
      {children}
    </AccordionMobile>
  )
}

export default AccordionItem
