export default {
  LANGUAGE: 'Língua',
  SIGN_OUT: 'Fechar Sessão',
  DELETE_ACCOUNT: 'Eliminar conta',
  VERSION_BUILD: 'Versão %{version} Build %{build}',
  TERMS: 'Termos e Privacidade',
  MODAL_CONFIRM_SIGN_OUT: {
    TITLE: '¿Deseja sair de UCROP.IT?',
    MESSAGE_MODAL: 'Esta ação deletará a fila de sincronização',
    ACCEPT: 'OK',
  },
}
