import React, { useContext } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import activityTypes from '@constants/activityTypes'
import statusEiq from '@constants/statusEiq'
import { LanguageContext } from '@contextState/language'
import { ActivityIcon } from '@modules/common/components'

const EiqCard = ({ title, subTitle, eiqRange }) => {
  const { t } = useContext(LanguageContext)
  const getStars = () => {
    const stars = []

    for (let i = 0; i < statusEiq[eiqRange].starsQuantity; i++) {
      stars.push(
        <CommonIcon
          key={i}
          name='STAR'
          size={10}
          color={statusEiq[eiqRange].color}
        />
      )
    }

    return <>{stars}</>
  }

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.eiqIconContainer,
          { backgroundColor: statusEiq[eiqRange].background },
        ]}
      >
        <ActivityIcon
          name={activityTypes.ACT_APPLICATION.key}
          size={22}
          style={{ color: statusEiq[eiqRange].color }}
        />
      </View>

      <View style={styles.contentContainer}>
        <Text style={styles.contentTitle}>{title}</Text>

        <Text style={styles.contentSubtitle}>{subTitle}</Text>

        <View style={styles.contentRangeContainer}>
          <View style={styles.contentRangeStarsContainer}>{getStars()}</View>

          <Text style={styles.contentRangeText}>
            {statusEiq[eiqRange].name(t)}
          </Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: '#FFFFFF',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  eiqIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    borderRadius: 100,
    marginRight: 16,
  },
  contentContainer: {
    flexDirection: 'column',
  },
  contentTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333333',
  },
  contentSubtitle: {
    fontSize: 14,
    fontWeight: '400',
    color: '#333333',
  },
  contentRangeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  contentRangeStarsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 9,
  },
  contentRangeText: {
    fontSize: 12,
    fontWeight: '400',
    color: '#333333',
  },
  angleIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 16,
  },
  angleIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
})

EiqCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  eiqRange: PropTypes.string,
}

export default EiqCard
