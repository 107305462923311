export const parseDataToValidateV2 = (values) => {
  const dataToSend = {
    draftId: values?.draftId,
    cropId: values.cropId,
    tag: values.tag,
    verificationTypeId: values.verificationType?.value,
    lotsIds: values.lots.value.map((element) => element._id),
    evidences: values.evidences.length
      ? values.evidences.map((element) => ({
          fileId: element.fileId,
          name: element.originalName,
          description: element.description,
          uploadedBy: element.user._id,
          uploadedAt: element.date,
        }))
      : undefined,
    observations: values.observations.map((element) => ({
      createdBy: element.createdBy._id,
      createdAt: element.createdAt,
      text: element.text,
    })),
    collaboratorsIds: values.signers.map((element) => element.userId),
    verificationFile: values.verificationFile
      ? {
          fileId: values.verificationFile.fileId,
          name: values.verificationFile.originalName,
          description: values.verificationFile.description,
          uploadedBy: values.verificationFile.uploadedBy._id,
          uploadedAt: values.verificationFile.uploadedAt,
        }
      : undefined,
    verificationStatus: values.verificationStatus,
    irrigationWaterSource: values.irrigationWaterSource?.value,
    pumpingDepth: values.pumpingDepth,
    waterConsumption: values.waterConsumption,
    horizontalWateringDepth: values.horizontalWateringDepth,
    irrigationPowerSource: values.irrigationPowerSource?.value,
    dateAchievement: values.dateAchievement?.value,
    formResponse: values.formResponse,
    timeFloodUnderCrop: values.timeFloodUnderCrop,
    unitTime: values.unitTime?.value,
    paddyWaterCultivation: values.paddyWaterCultivation?.value,
    paddyWaterPreseason: values.paddyWaterPreseason?.value,
  }
  return dataToSend
}
