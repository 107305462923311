import React from 'react'
import { Text, StyleSheet } from 'react-native'
import { TouchableRipple } from 'react-native-paper'
import { PropTypes } from 'prop-types'
import {
  primary500,
  white,
  buttonDisabled,
  blackDisabled,
  redV2,
  transparent,
} from '@styles/palette'
import { gray300 } from '@common/styles'

const ButtonDefault = ({
  text,
  onPress,
  height,
  width,
  textAlign,
  fontSize,
  flex,
  icon,
  loading,
  disabled,
  customTextStyle,
  marginTop,
  marginBottom,
  marginLeft = 0,
  marginRight = 0,
  paddingLeft,
  fontWeight = 'bold',
  borderRadius = 4,
  justifyContent = 'center',
  typeButton = 'primary',
  iconStart = false,
  testID = undefined,
}) => {
  return (
    <TouchableRipple
      disabled={disabled || loading}
      style={[
        {
          height: height || 53,
          flex,
          marginTop,
          marginBottom,
          marginLeft,
          marginRight,
          borderRadius,
          justifyContent,
          paddingLeft: paddingLeft || (icon ? 15 : 0),
          flexDirection: iconStart ? 'row-reverse' : 'row',
          alignItems: 'center',
          width: width || '100%',
          cursor: onPress ? 'pointer' : 'auto',
        },
        styles[`btn_${typeButton}`],
      ]}
      onPress={onPress}
      testID={testID}
    >
      <>
        <Text
          style={[
            {
              textAlign: textAlign || 'center',
              fontSize: fontSize || 16,
              marginLeft: icon ? 10 : 0,
              fontWeight,
            },
            styles[`text_${typeButton}`],
            customTextStyle,
          ]}
        >
          {text}
        </Text>
        {icon && icon}
      </>
    </TouchableRipple>
  )
}

const styles = StyleSheet.create({
  btn_disabled: {
    backgroundColor: gray300,
  },
  text_disabled: {
    color: white,
  },
  btn_primary: {
    backgroundColor: primary500,
  },
  text_primary: {
    color: white,
  },
  btn_inline: {
    backgroundColor: white,
    borderWidth: 1,
    borderColor: primary500,
  },
  text_inline: {
    color: primary500,
  },
  btn_inline_red: {
    borderColor: redV2,
    borderWidth: 1,
  },
  text_inline_red: {
    color: redV2,
  },
  btn_outline_success: {
    borderColor: primary500,
    borderWidth: 1,
  },
  text_outline_success: {
    color: primary500,
  },
  btn_without_background: {
    borderColor: transparent,
  },
  text_without_background: {
    color: primary500,
  },
})

ButtonDefault.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  textAlign: PropTypes.string,
  fontSize: PropTypes.number,
  flex: PropTypes.number,
  icon: PropTypes.element,
  shadow: PropTypes.bool,
  borderColor: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  customTextStyle: PropTypes.object,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  paddingLeft: PropTypes.number,
  fontWeight: PropTypes.string,
  borderRadius: PropTypes.number,
  backgroundColor: PropTypes.string,
  colorText: PropTypes.string,
  iconStart: PropTypes.bool,
}

export default ButtonDefault
