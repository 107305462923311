import { verificationTypes as type } from '../types'

export const verificationTypesActions = {
  setVerificationTypes: (payload) => ({
    type: type.SET_VERIFICATION_TYPES,
    payload,
  }),
  setVerificationSelected: (payload) => ({
    type: type.SET_VERIFICATION_SELECTED,
    payload,
  }),
  clearVerificationTypes: () => ({
    type: type.CLEAR_VERIFICATION_TYPES,
  }),
  loadVerificationTypes: (payload) => ({
    type: type.LOAD_VERIFICATION_TYPES,
    payload,
  }),
}
