import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import { Button, Paragraph, Dialog, Portal } from 'react-native-paper'

const DialogCustom = ({
  visible,
  toggleDialog,
  title,
  content,
  labelDone,
  labelCancel,
  onCancel,
  onDone,
  loading,
  labelLoading,
  dismissable = true,
  buttonStyle = styles.button,
}) => {
  return (
    <View>
      <Portal>
        <Dialog
          visible={visible}
          onDismiss={toggleDialog}
          dismissable={dismissable}
        >
          <Dialog.Title style={styles.title}>{title}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{content}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            {labelCancel && (
              <Button disabled={loading} onPress={onCancel}>
                {labelCancel}
              </Button>
            )}
            {loading ? (
              <Button onPress={onDone} disabled>
                {labelLoading}
              </Button>
            ) : (
              <Button
                mode='contained'
                onPress={onDone}
                labelStyle={styles.buttonText}
                style={buttonStyle}
              >
                {labelDone}
              </Button>
            )}
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
  },
  buttonText: {
    color: '#FFFFFF',
  },
  button: {
    backgroundColor: '#4CAF50',
  },
})

export default DialogCustom
