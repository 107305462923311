import DESCRIPTION from '../../components/Description/translate/pt'
import ERROR_MESSAGE from '../../components/DeleteModal/translate/pt'

export default {
  TEXT_1: 'Cultivos',
  TEXT_2: 'Campanha',
  TEXT_3: 'Mapa digital',
  TEXT_4: 'Excluir lote',
  TEXT_5: 'Sem cultivo',
  TEXT_6: 'Sem campanha',
  TO_DATE: 'a',
  COMPONENTS: {
    DESCRIPTION,
    ERROR_MESSAGE,
  },
}
