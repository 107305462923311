import Constants from 'expo-constants'
import { Amplify, Auth } from 'aws-amplify'
import awsCredentials from './aws-export'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

// Todo: Forzar cambio de Config Amplify
if (Platform.OS === 'web') {
  // eslint-disable-next-line no-console
  // console.log(awsCredentials)
  // eslint-disable-next-line no-console
  // console.clear()
  //Todo web only at the moment
  Amplify.configure(awsCredentials)
}

export const awsSignIn = async (email) => {
  email = email.trim()
  return await Auth.signIn(email)
}

export const awsIsAuthenticated = async () => {
  try {
    await Auth.currentSession()
    return true
  } catch {
    return false
  }
}

export const awsSignUp = async (email, name) => {
  const params = {
    username: email,
    password: Constants.expoConfig.extra.EXPO_USER_PASSWORD,
    attributes: {
      name,
      email,
    },
  }
  await Auth.signUp(params)
}

export const awsSignOut = async () => {
  await Auth.signOut()
}

export const answerCustomChallenge = async (answer, cognitoUser) => {
  await Auth.sendCustomChallengeAnswer(cognitoUser, answer)
  return awsIsAuthenticated()
}

export const getTokenAws = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  return await Auth.userSession(cognitoUser)
}

export const redirectQuickSight = async () => {
  const thisUrlEncoded = encodeURIComponent(window.location.hostname)
  const accessTokenUser = await AsyncStorage.getItem('USER_TOKEN')
  const endpoint = `${Constants.expoConfig.extra.EXPO_API_HOST}/auth/quicksight?accessToken=${accessTokenUser}&Issuer=${thisUrlEncoded}`
  window.open(endpoint, '_blank')
}

export const getQuickSightUrl = async () => {
  const thisUrlEncoded = window?.location?.origin
  const quickSightUrl = `auth/quicksight?issuer=${thisUrlEncoded}&dashboardType=TRADERS`

  return quickSightUrl
}

/**
 * Genera una URL para QuickSight con los parámetros de consulta proporcionados.
 *
 * @async
 * @function getUrlQuickSight
 
 * @param {Object} params - Objeto conteniendo los parámetros necesarios.
 * @param {string} params.dashboardType - Tipo de dashboard de QuickSight.
 * @param {string} [params.dashboardId] - ID del dashboard de QuickSight (opcional).
 * @returns {Promise<string>} Una URL de QuickSight con los parámetros de consulta apropiados.
 *
 * @example
 * getUrlQuickSight({ dashboardId: '1234587676r', dashboardType: 'TRADERS' })
 * .then(url => console.log(url));
 */
export const getUrlQuickSight = async ({ dashboardId, dashboardType }) => {
  const thisUrlEncoded = window?.location?.origin

  const params = {
    issuer: thisUrlEncoded,
    dashboardType,
    dashboardTraderId: dashboardId,
  }

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v !== undefined)
  )

  const queryString = new URLSearchParams(filteredParams).toString()

  const quickSightUrl = `auth/quicksight?${queryString}`

  return quickSightUrl
}
