import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { primary500 } from '@styles/palette'
import { CollaboratorsList, SubmitButton } from './components'

const Screen = ({
  isViewReady,
  collaborators,
  onPressShowCollaboratorContact,
  onPressSelectResponsible,
  onPressCallContact,
  onPressSendWhatsappMessage,
  isSubmitButtonAvailable,
  onPressSubmitButton,
}) => {
  if (!isViewReady) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator animating size='large' color={primary500} />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <CollaboratorsList
        collaborators={collaborators}
        onPressShowCollaboratorContact={onPressShowCollaboratorContact}
        onPressSelectResponsible={onPressSelectResponsible}
        onPressCallContact={onPressCallContact}
        onPressSendWhatsappMessage={onPressSendWhatsappMessage}
      />

      <SubmitButton
        isSubmitButtonAvailable={isSubmitButtonAvailable}
        onPressSubmitButton={onPressSubmitButton}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  container: {
    flex: 1,
    backgroundColor: '#F2F2F2',
    paddingTop: 10,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
})

Screen.propTypes = {
  isViewReady: PropTypes.bool.isRequired,
  collaborators: PropTypes.array.isRequired,
  onPressShowCollaboratorContact: PropTypes.func.isRequired,
  onPressSelectResponsible: PropTypes.func.isRequired,
  onPressCallContact: PropTypes.func.isRequired,
  onPressSendWhatsappMessage: PropTypes.func.isRequired,
  isSubmitButtonAvailable: PropTypes.bool.isRequired,
  onPressSubmitButton: PropTypes.func.isRequired,
}

export default Screen
