export default {
  TEXT_1:
    'Antes de agregar un colaborador debes agregar al menos una evidencia',
  TEXT_2: 'ADICIONE DESTINO',
  TEXT_3: 'Agregar evidência',
  TEXT_4: 'Tonelada',
  TEXT_5: 'Salvar',
  TEXT_6: 'assinar',
  TEXT_7: 'Assinado',
  TEXT_8: 'assinar',
  TEXT_9: 'Tonelada curta',
}
