import React, { useContext, useState, useEffect, useCallback } from 'react'
import { View, StyleSheet, FlatList } from 'react-native'
import useNetwork from '@utils/network'
import { userPermissions } from '@utils'
import { useAuth, useCompanyInfo } from '@modules/common/hooks'
import { CompanyDetails } from '@common/components/CompanyDetails'
import { FabButton } from '@common/components'
import CardCollaborator from './components/CardCollaborator'
import { white } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import useActionSheetManagement from '@hooks/useActionSheetManagement'
import { validateRoleInUserConfigService } from '@utils'
import ActionSheetComponent from './components/ActionSheet'
import useFetchPagination from '@common/hooks/useFetchPagination'
import { COLLABORATORS_REQUEST_STATUS } from '@utils/constants'
import { useFocusEffect } from '@react-navigation/native'
import { CommonIcon } from '@modules/common/components'

const CollaboratorListCompany = ({ navigation, route }) => {
  const { doRequest, loading } = useNetwork()
  const { user, config, isCompanyProducer } = useAuth()
  const { t } = useContext(LanguageContext)
  const [collaboratorList, setCollaboratorList] = useState([])
  const [collaboratorSelected, setCollaboratorSelected] = useState(null)
  const [actionSheetIsVisible, setActionSheetIsVisible] = useState(false)
  const [userTypeOptions, setUserTypeOptions] = useState([])

  const { isOpenModal, actionSheetRef, openActionSheet, closeActionSheet } =
    useActionSheetManagement()

  const { _id, identifier, companyName } = useCompanyInfo()
  const {
    roleSelected,
    isAdmin: isAdminConfig,
    isResponsible: isResponsibleConfig,
  } = config
  const hasPermissionAuthorizeCollaborator = validateRoleInUserConfigService(
    userPermissions.AUTHORIZE_COLLABORATOR,
    roleSelected,
    isAdminConfig,
    isResponsibleConfig
  )
  const hasPermissionAddCollaborator = validateRoleInUserConfigService(
    userPermissions.CREATE_COLLABORATOR,
    roleSelected,
    isAdminConfig,
    isResponsibleConfig
  )

  const {
    response: { data },
    isLoading,
    nextPage,
    refreshPage,
  } = useFetchPagination({
    url: `companies/${_id}/collaborators`,
    version: 'v2',
    method: 'GET',
    params: {
      created: Date.now(),
    },
  })

  useFocusEffect(
    useCallback(() => {
      const unsubscribe = navigation.addListener('focus', async () => {
        const navigationState = navigation.getState()
        const currentRouter = navigationState.routes.find(
          (item) => item.key === route.key
        )
        if (currentRouter?.params?.isNewCollaborator) {
          refreshPage()
        }
      })
      return unsubscribe
    }, [refreshPage, navigation])
  )

  useEffect(() => {
    setCollaboratorList(data)
  }, [data])

  const fetchUpdateCollaboratorRequest = async (collaborator, payload) => {
    const { role, isAdmin, status } = payload
    try {
      const response = await doRequest({
        method: 'PUT',
        url: `collaborator-request/${collaborator?.collaboratorRequest._id}`,
        version: 'v2',
        data: {
          status,
          user: collaborator?._id,
          company: collaborator?.company?._id,
          role: role?.value,
          isAdmin,
        },
      })
      if (response.status === 200) {
        const { data } = response
        const index =
          collaboratorList &&
          collaboratorList?.findIndex(
            (collaborator) =>
              collaborator?.collaboratorRequest?._id === data._id
          )
        const newCollaboratorList = [...collaboratorList]

        const { status } = payload
        status === COLLABORATORS_REQUEST_STATUS.ACCEPTED
          ? newCollaboratorList.splice(index, 1, {
              ...collaborator,
              isAdmin: Boolean(data?.isAdmin),
              isResponsible: Boolean(data?.isResponsible),
              role: role,
              collaboratorRequest: data,
            })
          : newCollaboratorList.splice(index, 1)

        setCollaboratorList(newCollaboratorList)
        setActionSheetIsVisible(false)
        handleClose()
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const { roleSelected, isAdmin, isResponsible } = user?.config || {}
    const options =
      isAdmin || isResponsible
        ? roleSelected?.assignableCompany?.withFlagAdmin
        : roleSelected?.assignableCompany?.withoutFlagAdmin
    const result = options?.map((option) => ({
      value: option.name,
      keyLabel: option.keyLabel,
      isAdmin: option.isAdmin,
      isResponsible: option.isResponsible,
    }))
    setUserTypeOptions(result)
  }, [user?.config])

  const handleClose = () => {
    closeActionSheet()
    setActionSheetIsVisible(false)
  }
  const handleOpen = () => {
    openActionSheet()
    setActionSheetIsVisible(true)
  }

  const navigateAddCollaborator = () => {
    navigation.navigate('AddCollaborator')
  }

  return (
    <View style={styles.container}>
      <CompanyDetails
        companyName={companyName}
        companyIdentifier={identifier}
      />
      <View
        style={[
          hasPermissionAddCollaborator ? styles.marginBotton : {},
          styles.containerCollaborators,
        ]}
      >
        <FlatList
          data={collaboratorList}
          renderItem={({ item }) => (
            <CardCollaborator
              collaborator={item}
              t={t}
              hasPermissionAuthorizeCollaborator={
                hasPermissionAuthorizeCollaborator
              }
              isCompanyProducer={isCompanyProducer}
              handleOpen={handleOpen}
              setCollaboratorSelected={setCollaboratorSelected}
            />
          )}
          keyExtractor={(item) =>
            `${item._id} ${item?.role?.value}` || item.email
          }
          onEndReachedThreshold={0.1}
          onEndReached={nextPage}
          onRefresh={refreshPage}
          refreshing={isLoading || loading}
        />
      </View>
      {hasPermissionAddCollaborator && (
        <FabButton
          icon={(props) => <CommonIcon {...props} name={'PLUS'} />}
          onPress={navigateAddCollaborator}
        />
      )}
      {actionSheetIsVisible && (
        <ActionSheetComponent
          isOpenModal={isOpenModal}
          actionSheetRef={actionSheetRef}
          handleClose={handleClose}
          fetchUpdateCollaboratorRequest={fetchUpdateCollaboratorRequest}
          collaboratorSelected={collaboratorSelected}
          userTypeOptions={userTypeOptions}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    flex: 1,
  },
  containerCollaborators: {
    paddingHorizontal: 10,
    flex: 1,
    marginBottom: 70,
  },
  marginBotton: {
    marginBottom: 20,
  },
})

export default CollaboratorListCompany
