export const typePersonOptions = (t) => {
  return [
    {
      value: 'PHYSICAL_PERSON',
      label: t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_7.OPTION_1,
    },
    {
      value: 'LEGAL_PERSON',
      label: t('COMPONENTS').COMPANIES.FORM_UPDATE.FIELD_7.OPTION_2,
    },
  ]
}
