import FORM_EDIT_FARM_NAME from '../../components/FormEditFarmName/translate/es'

export default {
  TEXT_1: 'Guardar cambios',
  TEXT_2: 'Ingrese al menos una letra o un número',
  TEXT_3: 'Solo puede ingresar hasta %{quantity} letras y/o números',
  COMPONENTS: {
    FORM_EDIT_FARM_NAME,
  },
}
