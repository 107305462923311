import { EActiveAgreementTypes } from '@common/utils'
import { ESubtypeAgreements } from '@common/enum'
import * as yup from 'yup'

const validateTypeAgreementRequiredEvidence = (
  typeAgreement,
  dataForCFT,
  evidences = []
) => {
  if (dataForCFT) {
    return false
  }

  let requiredEvidence = false

  if (typeAgreement.evidenceRequired) {
    requiredEvidence = typeAgreement?.evidenceTypesAllowed.some(
      (evidenceAllowed) => {
        if (evidenceAllowed.required) {
          return evidences.some(
            (selectedEvidence) =>
              evidenceAllowed._id === selectedEvidence.evidenceTypeSelected._id
          )
        }
        return true
      }
    )
  }

  return typeAgreement?.key && requiredEvidence
}

yup.addMethod(yup.array, 'signersLength', function (errorMessage) {
  return this.test(`test-signers-length`, errorMessage, function (value) {
    const { path, createError } = this
    const { typeAgreement } = this.parent
    let signersRequired = 2

    if (typeAgreement?.signersRequired) {
      signersRequired = typeAgreement.signersRequired
    }

    return (
      value.length >= signersRequired ||
      createError({ path, message: errorMessage })
    )
  })
})

export const agreementValidationSchema = (t) =>
  yup.object().shape({
    lots: yup
      .object()
      .shape({
        value: yup
          .array()
          .min(
            1,
            t('ERRORS.FORM_ERRORS.MIN_QUANTITY', {
              quantity: 1,
            })
          )
          .of(
            yup.object().shape({
              _id: yup.string().required(),
              surface: yup.number().required(),
              tag: yup.string().nullable(),
              wktFormat: yup.string(),
              area: yup.string(),
              farm: yup.string(),
            })
          )
          .nullable()
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .nullable()
      .required(),
    surface: yup.number().typeError(),
    seedDryContent: yup
      .number()
      .typeError(t('ERRORS.FORM_ERRORS.IS_NUMBER'))
      .min(0, t('ERRORS.FORM_ERRORS.IS_POSITIVE'))
      .max(100, `${t('ERRORS.FORM_ERRORS.LESS_THAN')} 100`)
      .nullable(),
    typeAgreement: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    evidences: yup.array().when(['typeAgreement', 'dataForCFT'], {
      is: validateTypeAgreementRequiredEvidence,
      then: yup
        .array()
        .min(
          1,
          t('ERRORS.FORM_ERRORS.MIN_QUANTITY', {
            quantity: 1,
          })
        )
        .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    }),
    signers: yup
      .array()
      .signersLength(
        t('ERRORS.FORM_ERRORS.MIN_QUANTITY', {
          quantity: 2,
        })
      )
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    temperature: yup.string().when('typeAgreement', {
      is: (typeAgreement) =>
        typeAgreement.key === EActiveAgreementTypes.WATER_CALCULATION,
      then: yup
        .string()
        .test('is-decimal', t('ERRORS.FORM_ERRORS.INVALID_FORMAT'), (value) => {
          if (value != undefined) {
            return /^\d+(\.\d{0,2})?$/.test(value)
          }

          return true
        })
        .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    }),
    weatherType: yup.object().when('typeAgreement', {
      is: (typeAgreement) => {
        return typeAgreement.key === EActiveAgreementTypes.WATER_CALCULATION
      },
      then: yup
        .object()
        .shape({
          value: yup
            .string()
            .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
            .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
        })
        .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    }),
    soilType: yup.object().when('typeAgreement', {
      is: (typeAgreement) => {
        return typeAgreement.key === EActiveAgreementTypes.WATER_CALCULATION
      },
      then: yup
        .object()
        .shape({
          value: yup
            .string()
            .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
            .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
        })
        .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    }),
    landType: yup.object().when('typeAgreement', {
      is: (typeAgreement) => {
        return typeAgreement.key === EActiveAgreementTypes.WATER_CALCULATION
      },
      then: yup
        .object()
        .shape({
          value: yup
            .string()
            .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
            .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
        })
        .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    }),
    humidityType: yup.object().when('typeAgreement', {
      is: (typeAgreement) => {
        return typeAgreement.key === EActiveAgreementTypes.WATER_CALCULATION
      },
      then: yup
        .object()
        .shape({
          value: yup
            .string()
            .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
            .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
        })
        .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    }),
    formResponse: yup.array().when(['typeAgreement', 'signers'], {
      is: (typeAgreement, signers) =>
        typeAgreement.subType === ESubtypeAgreements.FORM &&
        !signers.some(({ signed }) => signed),
      then: yup.array().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    }),
  })
