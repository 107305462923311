export default {
  LANGUAGE: 'Language',
  SIGN_OUT: 'Sign Out',
  DELETE_ACCOUNT: 'Delete account',
  VERSION_BUILD: 'Version %{version} Build %{build}',
  TERMS: 'Terms and Privacy',
  MODAL_CONFIRM_SIGN_OUT: {
    TITLE: 'Do you want to exit UCROP.IT?',
    MESSAGE:
      'If you carry out this action the synchronization queue will be deleted',
    ACCEPT: 'OK',
  },
}
