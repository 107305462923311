export default {
  TEXT_1: 'El colaborador ya existe en el cultivo',
  TEXT_2: 'Colaborador Agregado con éxito',
  TEXT_3: 'Campo Requerido',
  TEXT_4: 'Ingresa un numero',
  TEXT_5: 'CUIT Invalido',
  TEXT_6: 'El CUIT inválido',
  FIELD_1: {
    LABEL: 'CUIT',
    HELPTEXT: 'De la empresa física o jurídica a cargo de la producción.',
  },
  FIELD_2: {
    LABEL: 'Tax ID',
    HELPTEXT: 'De la empresa física o jurídica a cargo de la producción.',
  },
  FIELD_3: {
    LABEL: 'País',
  },
  FIELD_4: {
    LABEL: 'Email',
  },
  FIELD_5: {
    LABEL: 'Tipo de colaborador',
    HELPTEXT: 'El productor podrá ver, editar y validar todas las actividades',
  },
  TEXT_7: 'GUARDAR',
  TEXT_8: 'Formato de correo no válido. ejemplo@mail.com',
}
