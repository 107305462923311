export default {
  TEXT_1: 'Planting',
  TEXT_2: 'Harvest',
  TEXT_3: 'Tillage',
  TEXT_4: 'Fertilization',
  TEXT_5: 'Crop Protection',
  TEXT_6: 'Monitoring',
  TEXT_7: 'Declaration',
  TEXT_8: 'Expected yield',
  TEXT_9: 'Estimated harvest date',
  TEXT_10: 'Realization',
  TEXT_11: 'Validation',
  TEXT_12: 'Area',
  TEXT_13: 'ha',
  TEXT_14: 'Area',
  TEXT_15: 'inputs',
  TEXT_16: 'Collaborators',
  TEXT_17: 'Draft',
  TEXT_18: 'Planned',
  TEXT_19: 'Projected EIQ',
  TEXT_20: 'Executed, pending validation',
  TEXT_21: 'Observation',
  TEXT_22: 'Pending validation',
  TEXT_23: 'Completed',
  TEXT_24: 'EIQ',
  TEXT_25: 'Activities',
  TEXT_26: 'Draft',
  TEXT_27: 'Version',
  TEXT_28: '%{lotsQuantity} fields - %{surface} ha',
  TEXT_29: '%{lotsQuantity} field - %{surface} ha',
  TEXT_30: 'Expected yield: %{pay} %{unitType}',
  TEXT_31: 'Yield: %{pay} %{unitType}',
  TEXT_32: 'Est. Harvest Date: %{dateEstimatedHarvest}',
  TEXT_33: 'Destination: %{storage}',
  TEXT_34:
    "You didn't select any fields for the %{licenseName} ecosystem so far!",
  TEXT_35:
    "You applied to several licenses where you don't have associated fields yet! Go to the crop ecosystems section to make sure they're properly associated.",
  TEXT_36:
    'License %{licenseName} does not yet have associated fields or selected companies!',
  TEXT_37:
    'There are several licenses without associated fields or companies so far! Please navigate to the associated crops to assign fields to each license from there.',
  TEXT_38: 'GO TO LICENSE',
  TEXT_39: 'GO TO LICENSES',
  MODAL_VALIDATE: {
    TEXT_1: 'Verification activity',
    TEXT_2:
      'Currently there are one or more verification activities in draft. A new one cannot be created until at least one is completed.',
    TEXT_3: 'OK',
  },
  MODAL_NO_LOGER_LOTS: {
    TEXT_1: 'Verification activity',
    TEXT_2:
      'There are currently no fields available to generate a new verification.',
    TEXT_3: 'OK',
  },
  TEXT_40: 'Not compliant',
  TEXT_41: 'Verified',
  TEXT_42: 'In verification',
  TEXT_43: 'Verification',
  TEXT_44: 'Type of %{activityType}: %{subTypeActivity}',
  TEXT_45: 'Generated EIQ',
  TEXT_46: 'PENDING',
  TEXT_47: '%{value} PENDING',
  TEXT_48: '%{establishmentsQuantity} Farms',
  TEXT_49: '%{establishmentsQuantity} Farm',
  TEXT_50: '%{lotsQuantity} fields',
  TEXT_51: '%{lotsQuantity} field',
  TEXT_52: 'FARM',
  TEXT_53: 'FIELDS',
  TEXT_54: 'START',
  TEXT_55: 'SEE DETAIL',
  TEXT_56: 'EDIT',
  TEXT_57: 'Draft date and time:',
  TEXT_58: 'TO COMPLETE',
  TEXT_59: 'EXECUTION',
  TEXT_60: 'Drafts',
  TEXT_61: 'IN REVIEW',
  TEXT_62: 'to',
  NO_ACTIVITIES: 'You have no activities yet',
  ADD_ACTIVITIES:
    'Add a new activity to register tasks to be executed in the farm.',
  ABOUT_ACTIVITIES: 'Learn more about activities',
  IRRIGATION: 'Irrigation',
}
