import React, { useContext } from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { Modal, Portal } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { white, blackHighEmphasis } from '@styles/palette'
import ButtonDefault from '@common/components/buttons/ButtonDefault'

const ApproveModal = ({ isModalVisible, closeModal, updateCompanyStatus }) => {
  const { t } = useContext(LanguageContext)
  return (
    <Portal>
      <Modal
        visible={isModalVisible}
        onDismiss={closeModal}
        contentContainerStyle={styles.modal}
      >
        <View style={styles.body}>
          <View style={styles.header}>
            <Text style={styles.headerTitle}>
              {t('VIEWS.COMPANY_DETAIL.COMPONENTS.APPROVE_MODAL.TITLE')}
            </Text>
            <TouchableOpacity onPress={closeModal}>
              <CommonIcon name={'X'} size={14} />
            </TouchableOpacity>
          </View>
          <View style={styles.content}>
            <Text style={styles.description}>
              {t('VIEWS.COMPANY_DETAIL.COMPONENTS.APPROVE_MODAL.DESCRIPTION')}
            </Text>
          </View>
          <View style={styles.footer}>
            <ButtonDefault
              text={t('VIEWS.COMPANY_DETAIL.COMPONENTS.APPROVE_MODAL.CANCEL')}
              typeButton={'outline_success'}
              height={32}
              width={77}
              borderRadius={8}
              fontSize={14}
              fontWeight={'700'}
              marginRight={8}
              onPress={closeModal}
            />
            <ButtonDefault
              text={t('VIEWS.COMPANY_DETAIL.COMPONENTS.APPROVE_MODAL.APPROVE')}
              height={32}
              width={77}
              borderRadius={8}
              fontSize={14}
              fontWeight={'700'}
              marginLeft={8}
              onPress={updateCompanyStatus}
            />
          </View>
        </View>
      </Modal>
    </Portal>
  )
}
const styles = StyleSheet.create({
  modal: {
    alignSelf: 'center',
  },
  body: {
    backgroundColor: white,
    width: 312,
    minHeight: 243,
    borderRadius: 2,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginStart: 28,
    marginTop: 28,
    marginEnd: 21,
  },
  headerTitle: {
    color: blackHighEmphasis,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    letterSpacing: 0.15,
  },
  content: {
    marginStart: 40,
    marginTop: 24,
    marginEnd: 24,
  },
  description: {
    color: blackHighEmphasis,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
    letterSpacing: 0.15,
  },
  footer: {
    marginTop: 24,
    marginHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 15,
  },
})

ApproveModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  closeModal: PropTypes.func,
  updateCompanyStatus: PropTypes.func,
}

export default ApproveModal
