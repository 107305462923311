import { useContext } from 'react'
import { Linking, Platform, SafeAreaView, StyleSheet } from 'react-native'
import * as FileSystem from 'expo-file-system'
import * as Sharing from 'expo-sharing'

import { WebViewRender } from '@modules/webView/components'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { usePolygonsTrader } from '../../hooks'
import { LanguageContext } from '@contextState/language'

const PolygonsTrader = () => {
  const { t } = useContext(LanguageContext)

  const { urlWebView } = usePolygonsTrader()

  const handleIFrameMessages = async (event: any) => {
    try {
      const dataStr =
        Platform.OS === 'web' ? event.data : event.nativeEvent.data

      if (dataStr) {
        const data = JSON.parse(dataStr)

        if (data?.url) {
          Linking.openURL(data.url)

          return
        }

        if (data?.fileBase64) {
          const fileBase64 = data.fileBase64.replace(
            /^data:([A-Za-z-+/]+);base64,/,
            ''
          )

          const fileUri = FileSystem.cacheDirectory + data?.fileName

          await FileSystem.writeAsStringAsync(fileUri, fileBase64, {
            encoding: FileSystem.EncodingType.Base64,
          })

          const info = await FileSystem.getInfoAsync(fileUri)

          if (info.exists) {
            await Sharing.shareAsync(fileUri)
          } else {
            alert('Error')
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      {!urlWebView ? (
        <LoaderCenterContainer />
      ) : (
        <WebViewRender url={urlWebView} onMessage={handleIFrameMessages} />
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default PolygonsTrader
