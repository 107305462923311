export default {
  TEXT_1: 'Procesando la sustentabilidad del paisaje...',
  TEXT_2: 'Sustentabilidad del paisaje',
  TEXT_3: '%{value} %{areaUnit} sin solapamiento',
  TEXT_4: '%{value} %{areaUnit} se solapan',
  TEXT_5: 'Apto',
  TEXT_6: 'ha',
  TEXT_7: 'Detalle',
  TEXT_8: 'Ordenamiento de bosques nativos',
  TEXT_9: '%{value}% de solapamiento',
  TEXT_10: 'CATEGORIA %{value}',
  TEXT_11: 'Áreas valiosas de pastizal',
  TEXT_12: 'Áreas de conservación de aves',
  TEXT_13: 'Áreas de desmonte',
  TEXT_14: 'Humedales y turberas',
  TEXT_15: 'Áreas protegidas',
  TEXT_16: 'Áreas de alta biodiversidad',
  TEXT_17: 'Reservas naturales',
  TEXT_18:
    '*Los porcentajes totales tienen en cuenta el solapamiento entre capas.',
}
