import { SafeAreaView, StyleSheet } from 'react-native'
import React from 'react'
import { useExporterCircuit } from '../../hooks'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { WebViewRender } from '@modules/webView/components'

const TraderMain = () => {
  const { url, isReady }: any = useExporterCircuit()

  return (
    <SafeAreaView style={styles.container}>
      {!isReady ? <LoaderCenterContainer /> : <WebViewRender url={url} />}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default TraderMain
