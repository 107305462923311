/* eslint-disable no-undef */
import React, { useContext } from 'react'
import { StyleSheet, ScrollView, View, Platform, Text } from 'react-native'
import { FormikProvider } from 'formik'
import { ActivityIndicator, TouchableRipple } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { CommonIcon } from '@modules/common/components'
import { blackHighEmphasis, onPressedColor } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import activityTypes from '@constants/activityTypes'
import verificationStatus from '@constants/verificationStatus'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import CollaboratorsButton from '@components/collaborators/CollaboratorsButton'
import EvidencesList from '@components/common/v1/EvidencesList'
import { primary500 } from '@styles/palette'
import {
  OtherUserOnDraft,
  CropDescription,
  DraftDescription,
  AddDestination,
  AddEvidence,
  DestinationsList,
  ModalValidateVerification,
  SaveDraftButton,
  ValidateButton,
  VerificationStatus,
  DeclineButton,
  ModalConfirmDecline,
} from './components'
import ObservationList from '@components/common/v1/ObservationList'
import { SnackbarRejectedActivity } from '@common/components/snackbar'
import ActionSheet from 'react-native-actions-sheet'
import AddSupply from './components/AddSupply'
import EiqCardSimple from '@components/common/v1/EiqCardSimple'
import { ModalLoading } from '@modules/common/components/ModalLoading'
import {
  VerifyPin,
  ListSupplies,
  ModalDefault,
} from '@modules/common/components'

const Screen = ({
  isViewReady,
  isViewReadOnly = false,
  tag,
  activity,
  isRealized,
  isFinished,
  formik,
  form,
  crop,
  draft,
  isOtherUserOnDraft,
  isSaveDraftButtonAvailable,
  isSaveDraftButtonLoading,
  onPressSaveDraftButton,
  isValidateButtonAvailable,
  isValidateButtonLoading,
  onPressValidateButton,
  isValidateButtonVisible,
  isModalConfirmGoBackVisible,
  closeModalConfirmGoBack,
  onPressConfirmGoBackWithoutSaveChanges,
  isModalDeleteDraftVisible,
  closeModalDeleteDraft,
  onPressConfirmDeleteDraft,
  isModalValidateVisible,
  onPressCancelValidateVersion,
  onPressConfirmValidate,
  isModalVerifyPinVisible,
  onPressCancelModalVerifyPin,
  onSuccessVerifyPin,
  onPressAddCollaborator,
  signerQuantity,
  isModalEvidenceVisible,
  toggleModalEvidences,
  closeModalEvidences,
  onPressAddEvidence,
  evidences,
  onPressRemoveEvidence,
  onPressEvidenceFile,
  lots,
  isModalDestinationVisible,
  toggleModalDestination,
  closeModalDestination,
  onPressAddDestination,
  storages,
  onPressRemoveDestination,
  observations,
  deleteObservation,
  copyObservation,
  isModalConfirmChangeVerification,
  closeModalConfirmChangeVerification,
  confirmVerification,
  isModalValidateVerificationVisible,
  closeModalValidateVerification,
  isAddVerificationFileButtonDisabled,
  onSelectVerificationFile,
  verificationType,
  verificationFile,
  onPressRemoveVerificationFile,
  onPressVerificationInvalidButton,
  onPressVerificationValidButton,
  isModalConfirmValidateVerificationVisible,
  closeModalConfirmValidateVerification,
  onPressConfirmConfirmValidateVerification,
  modalConfirmDecline,
  isDeclineButtonVisible,
  isDeclineButtonAvailable,
  isDirectAchievement,
  supplies,
  navigateToSupplyDetail,
  areaUnit,
  pressAddSupply,
  totalEiqAllSupplies,
  eiqRange,
  isModalLoadingVisible,
  titleModalLoading,
  subtitleModalLoading,
  canShowOutlier,
  openBackdropStandardDeviation,
  ComponentStandarDeviation,
  setDontShowGenerateCft,
  evidenceCft,
  isDeclarationHasLazyLoadEvidence,
  isNewActivity,
}) => {
  const { t, i18n } = useContext(LanguageContext)

  const { values, setFieldValue } = formik

  if (!isViewReady) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator animating size='large' color={primary500} />
      </View>
    )
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <OtherUserOnDraft isOtherUserOnDraft={isOtherUserOnDraft} />
      <CropDescription crop={crop} />
      <SnackbarRejectedActivity draft={draft} i18n={i18n} />
      <DraftDescription draft={draft} />
      <FormikProvider value={formik}>{form}</FormikProvider>
      <AddDestination
        isAddDestinationVisible={!isViewReadOnly}
        tag={tag}
        isModalDestinationVisible={isModalDestinationVisible}
        toggleModalDestination={toggleModalDestination}
        closeModalDestination={closeModalDestination}
        handleDestination={onPressAddDestination}
        company={crop.company}
      />

      {!isViewReadOnly &&
        (tag === activityTypes.ACT_SOWING.key ||
          tag === activityTypes.ACT_FERTILIZATION.key ||
          tag === activityTypes.ACT_APPLICATION.key) && (
          <AddSupply
            pressAddSupply={pressAddSupply}
            disabled={isViewReadOnly || lots?.value?.length <= 0}
          />
        )}
      <AddEvidence
        isAddEvidenceVisible={!isViewReadOnly}
        isModalEvidenceVisible={isModalEvidenceVisible}
        toggleModalEvidences={toggleModalEvidences}
        closeModalEvidences={closeModalEvidences}
        handleSelectEvidence={onPressAddEvidence}
        lots={lots}
        tag={values?.tag}
        itemSelected={values?.typeAgreement}
        values={values}
        setFieldValue={setFieldValue}
        setDontShowGenerateCft={setDontShowGenerateCft}
        evidenceCft={evidenceCft}
      />
      <DestinationsList
        tag={tag}
        storages={storages}
        handleDeleteDestination={onPressRemoveDestination}
        readOnly={isViewReadOnly}
        company={crop.company}
      />
      {isDirectAchievement &&
        tag === activityTypes.ACT_APPLICATION.key &&
        eiqRange && (
          <EiqCardSimple
            title={`${t('VIEWS.ACHIEVEMENT.TEXT_3')}  ${totalEiqAllSupplies}`}
            range={eiqRange}
            tag={activityTypes.ACT_APPLICATION.key}
          />
        )}
      {tag === activityTypes.ACT_VERIFICATION.key && (
        <ObservationList
          observations={observations}
          deleteObservation={deleteObservation}
          copyObservation={copyObservation}
          showMenu={Boolean(draft || (!isRealized && !isFinished))}
        />
      )}
      {isDirectAchievement && tag !== activityTypes.ACT_TILLAGE.key && (
        <ListSupplies
          supplies={supplies}
          navigateToSupplyDetail={navigateToSupplyDetail}
          areaUnit={areaUnit}
          canShowOutlier={canShowOutlier}
          t={t}
          outlierLevelSowing={activity?.outlierLevelSowing}
          outlierActiveIngredientsLevel={
            activity?.outlierActiveIngredientsLevel
          }
          outlierFertilizerLevel={activity?.outlierFertilizerLevel}
          openBackdropStandardDeviation={openBackdropStandardDeviation}
          surface={values?.surface}
        />
      )}
      {tag === activityTypes.ACT_AGREEMENTS.key &&
        isDeclarationHasLazyLoadEvidence(
          values?.typeAgreement?.key,
          values.evidences
        ) && (
          <TouchableRipple
            key={0}
            style={styles.evidenceContainer}
            onPress={() => {}}
            rippleColor={onPressedColor}
            underlayColor={onPressedColor}
          >
            <>
              <View style={styles.iconContainer}>
                <CommonIcon
                  name={'CLOCK'}
                  size={27}
                  color={blackHighEmphasis}
                />
              </View>
              <View style={styles.descriptionContainer}>
                <Text style={styles.disclaimerLazyLoad}>
                  {
                    t('VIEWS').ACTIVITY_CREATE
                      .AGREEMENT_FORM_DISCLAIMER_EVIDENCE[
                      values?.typeAgreement?.key
                    ].TITLE
                  }
                </Text>
                <Text style={styles.disclaimerDescriptionText}>
                  {
                    t('VIEWS').ACTIVITY_CREATE
                      .AGREEMENT_FORM_DISCLAIMER_EVIDENCE[
                      values?.typeAgreement?.key
                    ].DESCRIPTION
                  }
                </Text>
              </View>
            </>
          </TouchableRipple>
        )}
      <EvidencesList
        evidences={evidences}
        onDelete={onPressRemoveEvidence}
        onPress={onPressEvidenceFile}
        disabled={isViewReadOnly}
        isNewActivity={isNewActivity}
      />
      <CollaboratorsButton
        title={t('COMPONENTS.COLLABORATORS_BUTTON.COLLABORATORS')}
        quantity={signerQuantity}
        onPress={onPressAddCollaborator}
      />
      <VerificationStatus
        tag={tag}
        isRealized={isRealized}
        isFinished={isFinished}
        activity={activity}
      />
      <SaveDraftButton
        tag={tag}
        isSaveDraftButtonAvailable={isSaveDraftButtonAvailable}
        isSaveDraftButtonLoading={isSaveDraftButtonLoading}
        onPressSaveDraftButton={onPressSaveDraftButton}
        isSaveDraftButtonVisible={!isViewReadOnly}
      />
      <ValidateButton
        isValidateButtonAvailable={isValidateButtonAvailable}
        isValidateButtonLoading={isValidateButtonLoading}
        onPressValidateButton={onPressValidateButton}
        isValidateButtonVisible={isValidateButtonVisible}
        isModalVerifyPinVisible={isModalVerifyPinVisible}
      />
      <DeclineButton
        isDeclineButtonAvailable={isDeclineButtonAvailable}
        isDeclineButtonVisible={isDeclineButtonVisible}
        onPressDeclineButton={modalConfirmDecline.openActionSheet}
      />
      <ModalConfirm
        visible={isModalConfirmGoBackVisible}
        onClose={closeModalConfirmGoBack}
        onCancel={onPressConfirmGoBackWithoutSaveChanges}
        onConfirm={closeModalConfirmGoBack}
        title={
          t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES
            .TEXT_1
        }
        contentText={
          t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES
            .TEXT_2
        }
        cancelText={
          t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES
            .TEXT_3
        }
        confirmText={
          t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES
            .TEXT_4
        }
      />
      <ModalConfirm
        visible={isModalDeleteDraftVisible}
        onClose={closeModalDeleteDraft}
        onConfirm={onPressConfirmDeleteDraft}
        title={t('VIEWS').ACTIVITY_CREATE.MODAL_DELETE.TEXT_1}
        contentText={t('VIEWS').ACTIVITY_CREATE.MODAL_DELETE.TEXT_2}
        cancelText={t('VIEWS').ACTIVITY_CREATE.MODAL_DELETE.TEXT_3}
        confirmText={t('VIEWS').ACTIVITY_CREATE.MODAL_DELETE.TEXT_4}
        cancelButtonStyle={styles.deleteModalCancelButtonStyle}
        cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
        confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
      />
      <ModalConfirm
        visible={isModalValidateVisible}
        onClose={onPressCancelValidateVersion}
        onConfirm={onPressConfirmValidate}
        title={t('VIEWS').ACTIVITY_CREATE.MODAL_VALIDATE.TEXT_1}
        contentText={t('VIEWS').ACTIVITY_CREATE.MODAL_VALIDATE.TEXT_2}
        cancelText={t('VIEWS').ACTIVITY_CREATE.MODAL_VALIDATE.TEXT_3}
        confirmText={t('VIEWS').ACTIVITY_CREATE.MODAL_VALIDATE.TEXT_4}
        cancelButtonStyle={styles.deleteModalCancelButtonStyle}
        cancelButtonTextStyle={styles.deleteModalCancelButtonTextStyle}
        confirmButtonStyle={styles.deleteModalConfirmButtonStyle}
      />
      <ModalConfirm
        visible={isModalConfirmChangeVerification}
        title={t(
          'VIEWS.ACTIVITY_CREATE.MODAL_CONFIRM_ACTIVITY_VERIFICATION.TEXT_1'
        )}
        onConfirm={confirmVerification}
        contentText={t(
          'VIEWS.ACTIVITY_CREATE.MODAL_CONFIRM_ACTIVITY_VERIFICATION.TEXT_2'
        )}
        confirmText={t(
          'VIEWS.ACTIVITY_CREATE.MODAL_CONFIRM_ACTIVITY_VERIFICATION.TEXT_3'
        )}
        cancelText={t(
          'VIEWS.ACTIVITY_CREATE.MODAL_CONFIRM_ACTIVITY_VERIFICATION.TEXT_4'
        )}
        onClose={closeModalConfirmChangeVerification}
      />
      <ModalValidateVerification
        isModalVisible={isModalValidateVerificationVisible}
        closeModal={closeModalValidateVerification}
        isAddVerificationFileButtonDisabled={
          isAddVerificationFileButtonDisabled
        }
        onSelectVerificationFile={onSelectVerificationFile}
        verificationType={verificationType}
        verificationStatus={verificationStatus.VALID.name(t)}
        verificationFileName={verificationFile?.originalName}
        verificationFileUploadedBy={verificationFile?.uploadedBy}
        verificationFileUploadedAt={verificationFile?.uploadedAt}
        onPressRemoveVerificationFile={onPressRemoveVerificationFile}
        onPressVerificationInvalidButton={onPressVerificationInvalidButton}
        onPressVerificationValidButton={onPressVerificationValidButton}
      />
      <ModalConfirm
        visible={isModalConfirmValidateVerificationVisible}
        onClose={closeModalConfirmValidateVerification}
        onConfirm={onPressConfirmConfirmValidateVerification}
        title={
          t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_VALIDATE_VERIFICATION.TEXT_1
        }
        contentText={
          t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_VALIDATE_VERIFICATION.TEXT_2
        }
        cancelText={
          t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_VALIDATE_VERIFICATION.TEXT_3
        }
        confirmText={
          t('VIEWS').ACTIVITY_CREATE.MODAL_CONFIRM_VALIDATE_VERIFICATION.TEXT_4
        }
      />
      <VerifyPin
        isModalVisible={isModalVerifyPinVisible}
        toggleModal={onPressCancelModalVerifyPin}
        onSuccess={onSuccessVerifyPin}
      />
      {Platform.OS !== 'web' ? (
        <ActionSheet
          ref={modalConfirmDecline.actionSheetRef}
          containerStyle={styles.actionStyles}
        >
          <View style={styles.containerActionSheet}>
            <ModalConfirmDecline {...modalConfirmDecline} />
          </View>
        </ActionSheet>
      ) : (
        <ModalDefault
          isModalVisible={modalConfirmDecline.isOpenModal}
          closeModal={modalConfirmDecline.closeActionSheet}
          hasIconCloseModal={false}
        >
          <ModalConfirmDecline modal {...modalConfirmDecline} />
        </ModalDefault>
      )}
      <ModalLoading
        visible={isModalLoadingVisible}
        title={titleModalLoading}
        subtitle={subtitleModalLoading}
        animationStyle={styles.animationStyle}
      />
      <ComponentStandarDeviation />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  loader: {
    marginTop: 100,
  },
  container: {
    backgroundColor: '#F2F2F2',
    paddingBottom: 40,
    paddingHorizontal: 16,
  },
  evidenceContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 5,
    width: 70,
  },
  descriptionContainer: {
    flex: 1,
  },
  disclaimerLazyLoad: {
    fontSize: 16,
    lineHeight: 23,
    fontWeight: '600',
    color: blackHighEmphasis,
  },
  disclaimerDescriptionText: {
    width: '84%',
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '300',
    color: blackHighEmphasis,
  },
  deleteModalCancelButtonStyle: {
    borderColor: '#FF7043',
  },
  deleteModalCancelButtonTextStyle: {
    color: '#FF7043',
  },
  deleteModalConfirmButtonStyle: {
    backgroundColor: '#FF7043',
  },
  actionStyles: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  containerActionSheet: {
    marginHorizontal: 10,
  },
  animationStyle: {
    width: 300,
    height: 300,
  },
})

Screen.propTypes = {
  isViewReady: PropTypes.bool.isRequired,
  isViewReadOnly: PropTypes.bool,
  tag: PropTypes.string.isRequired,
  activity: PropTypes.object,
  isRealized: PropTypes.bool,
  isFinished: PropTypes.bool,
  formik: PropTypes.object,
  form: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  crop: PropTypes.object.isRequired,
  draft: PropTypes.object,
  isOtherUserOnDraft: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isValidateButtonAvailable: PropTypes.bool.isRequired,
  isValidateButtonLoading: PropTypes.bool.isRequired,
  onPressValidateButton: PropTypes.func.isRequired,
  isSaveDraftButtonAvailable: PropTypes.bool.isRequired,
  isSaveDraftButtonLoading: PropTypes.bool.isRequired,
  onPressSaveDraftButton: PropTypes.func.isRequired,
  isModalConfirmGoBackVisible: PropTypes.bool.isRequired,
  closeModalConfirmGoBack: PropTypes.func.isRequired,
  onPressConfirmGoBackWithoutSaveChanges: PropTypes.func.isRequired,
  isModalDeleteDraftVisible: PropTypes.bool.isRequired,
  closeModalDeleteDraft: PropTypes.func.isRequired,
  onPressConfirmDeleteDraft: PropTypes.func.isRequired,
  isModalValidateVisible: PropTypes.bool.isRequired,
  onPressCancelValidateVersion: PropTypes.func.isRequired,
  onPressConfirmValidate: PropTypes.func.isRequired,
  isModalVerifyPinVisible: PropTypes.bool.isRequired,
  toggleModalVerifyPin: PropTypes.func.isRequired,
  onSuccessVerifyPin: PropTypes.func.isRequired,
  onPressAddCollaborator: PropTypes.func.isRequired,
  signerQuantity: PropTypes.number.isRequired,
  isModalEvidenceVisible: PropTypes.bool.isRequired,
  toggleModalEvidences: PropTypes.func.isRequired,
  closeModalEvidences: PropTypes.func.isRequired,
  onPressAddEvidence: PropTypes.func.isRequired,
  evidences: PropTypes.array.isRequired,
  onPressRemoveEvidence: PropTypes.func.isRequired,
  onPressEvidenceFile: PropTypes.func.isRequired,
  lots: PropTypes.object.isRequired,
  isModalDestinationVisible: PropTypes.bool.isRequired,
  toggleModalDestination: PropTypes.func.isRequired,
  closeModalDestination: PropTypes.func.isRequired,
  onPressAddDestination: PropTypes.func.isRequired,
  storages: PropTypes.array,
  onPressRemoveDestination: PropTypes.func.isRequired,
  observations: PropTypes.array,
  deleteObservation: PropTypes.func.isRequired,
  copyObservation: PropTypes.func.isRequired,
  isModalValidateVerificationVisible: PropTypes.bool.isRequired,
  closeModalValidateVerification: PropTypes.func.isRequired,
  isAddVerificationFileButtonDisabled: PropTypes.bool.isRequired,
  onSelectVerificationFile: PropTypes.func.isRequired,
  verificationType: PropTypes.object,
  verificationFile: PropTypes.object,
  onPressRemoveVerificationFile: PropTypes.func.isRequired,
  onPressVerificationInvalidButton: PropTypes.func.isRequired,
  onPressVerificationValidButton: PropTypes.func.isRequired,
  isModalConfirmValidateVerificationVisible: PropTypes.bool.isRequired,
  closeModalConfirmValidateVerification: PropTypes.func.isRequired,
  onPressConfirmConfirmValidateVerification: PropTypes.func.isRequired,
  modalConfirmDecline: PropTypes.shape({
    isOpenModal: PropTypes.bool,
    actionSheetRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
    openActionSheet: PropTypes.func,
    closeActionSheet: PropTypes.func,
    onSuccess: PropTypes.func,
    paramsRequest: PropTypes.object,
  }),
  isDeclineButtonVisible: PropTypes.bool,
  isDeclineButtonAvailable: PropTypes.bool,
  canShowOutlier: PropTypes.bool,
  openBackdropStandardDeviation: PropTypes.func,
}

export default Screen
