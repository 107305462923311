import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import ActionSheet from 'react-native-actions-sheet'
import ConfirmUnlink from './ConfirmUnlink'
import { PropTypes } from 'prop-types'

const ActionSheetConfirmUnlink = ({
  reference,
  unlinkCollaborator,
  findCropCollaborator,
  selectCollaborator,
  isFetchingCrop,
  setDisabled,
  getTitleMenu,
  goToUnlinkCollaborators,
  onClose,
}) => {
  return (
    Platform.OS !== 'web' && (
      <ActionSheet ref={reference} containerStyle={styles.actionStyles}>
        <View style={styles.containerActionSheet}>
          <ConfirmUnlink
            unlinkCollaborator={unlinkCollaborator}
            selectCollaborator={selectCollaborator}
            findCropCollaborator={findCropCollaborator}
            isFetchingCrop={isFetchingCrop}
            setDisabled={setDisabled}
            getTitleMenu={getTitleMenu}
            goToUnlinkCollaborators={goToUnlinkCollaborators}
            onClose={onClose}
          />
        </View>
      </ActionSheet>
    )
  )
}

const styles = StyleSheet.create({
  actionStyles: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  containerActionSheet: {
    height: 500,
    marginHorizontal: 10,
  },
})

ActionSheetConfirmUnlink.propTypes = {
  reference: PropTypes.object.isRequired,
  unlinkCollaborator: PropTypes.object.isRequired,
  findCropCollaborator: PropTypes.array.isRequired,
  selectCollaborator: PropTypes.object.isRequired,
  isFetchingCrop: PropTypes.bool,
  setDisabled: PropTypes.func.isRequired,
  getTitleMenu: PropTypes.func.isRequired,
  goToUnlinkCollaborators: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ActionSheetConfirmUnlink
