export default {
  TEXT_1: 'ADICIONAR INSUMO',
  TEXT_2: 'Adicionar evidência ',
  TEXT_3: 'EIQ Realizada',
  TEXT_4: 'VALIDAR',
  TEXT_5:
    'Esta postagem está sendo editada por outro colaborador (%{userFullname}).',
  TEXT_6: 'SALVAR RASCUNHO',
  TEXT_7: 'por %{user} em %{date} às %{hour} hs.',
  TEXT_8: 'Preliminar: versão %{version}',
  MODAL_CONFIRM: {
    TEXT_1: 'Pretende sair sem guardar?',
    TEXT_2: 'As alterações não serão mantidas.',
    TEXT_3: 'DEIXAR',
    TEXT_4: 'FICAR',
  },
  MODAL_DELETE: {
    TEXT_1: 'Excluir rascunho?',
    TEXT_2: 'As evidências enviadas serão excluídas permanentemente.',
    TEXT_3: 'MANTER',
    TEXT_4: 'REMOVER',
  },
  MODAL_VALIDATE: {
    TEXT_1: 'Validar esta versão do rascunho?',
    TEXT_2: 'Existem outras versões deste mesmo registro',
    TEXT_3: 'CANCELAR',
    TEXT_4: 'VALIDAR',
  },
}
