import React, { useCallback, useContext, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'formik'

import { LanguageContext } from '@contextState/language'
import InputText from '@components/inputs/InputText'

export const FormEditLotName = ({ formik }) => {
  const { t } = useContext(LanguageContext)
  const label = useCallback(
    t('VIEWS.FARM_LOT_EDIT_NAME.COMPONENTS.FORM_EDIT_LOT_NAME.TEXT_1'),
    [t]
  )
  const { setFieldValue, values, setFieldTouched, touched, errors } = formik

  useEffect(() => {
    setFieldTouched('lotName')
  }, [])

  return (
    <Field
      component={InputText}
      name='lotName'
      id='lotName'
      label={label}
      placeholder={label}
      value={values?.lotName}
      onChangeText={(value) => setFieldValue('lotName', value?.trimStart())}
      onBlur={() => setFieldTouched('lotName')}
      touched={touched.lotName}
      error={errors.lotName}
    />
  )
}

FormEditLotName.propTypes = {
  formik: PropTypes.object.isRequired,
}
