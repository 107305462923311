export default {
  TEXT_1: 'Composição',
  TEXT_2: 'EIQ',
  TEXT_3: 'Classes toxicologicas',
  TEXT_4: 'Superfície %{areaUnit}',
  TEXT_5: 'Unidade de medida',
  TEXT_6: 'Quantidade de',
  TEXT_7: 'Total',
  MODAL_DELETE: {
    TEXT_1: '¿remover o suprimento?',
    TEXT_2: 'Este suprimento será removido desta atividade.',
    TEXT_3: 'MANTER',
    TEXT_4: 'REMOVER',
  },
}
