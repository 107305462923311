import { useContext } from 'react'
import { round } from 'lodash'

import { CommonContext } from '@contextState/common'
import { useCompanyInfo, useChangeOutliersHarvest } from '@modules/common/hooks'
import { parseSupplies, parseEvidences, parseStorages } from '../utils'
import { useSelector } from 'react-redux'

export const useGetActivityDTO = ({ activityTypeTag }) => {
  const activityTemporalData = useSelector(
    (state) => state.activityTemporalData
  )
  const {
    data: {
      cropName,
      cropType,
      supplies: selectorSupplies = [],
      evidences: selectorEvidences = [],
      dataToValidate: {
        lots: activityLots = [],
        surface: activitySurface,
        subTypeActivity: activitySubTypeActivity,
        dateStart: activityDateStart,
        dateEnd: activityDateEnd,
        dateAchievement: activityDateAchievement,
        dateHarvest: activityDateHarvest,
        dateEstimatedHarvest: activityDateEstimatedHarvest,
        dateObservation: activityDateObservation,
        pay: activityPay,
        volume: activityVolume,
        unitVolume: activityUnitVolume,
        unitType: activityUnitType,
        observation: activityObservation,
        signers: activitySigners = [],
        typeAgreement: activityAgreementType,
        storages: activityStorages = [],
        supplies: activitySupplies = [],
        irrigationPowerSource,
        irrigationWaterSource,
        pumpingDepth,
        waterConsumption,
        horizontalWateringDepth,
        weatherType,
        temperature,
        soilType,
        landType,
        humidityType,
        timeFloodUnderCrop,
        unitTime,
        paddyWaterCultivation,
        paddyWaterPreseason,
      },
    },
  } = activityTemporalData
  const { subTypeActivities: subActivityTypes } = useContext(CommonContext)

  const { irrigationPowerSources, irrigationWaterSources } = useSelector(
    (state) => state.irrigationDataReducer
  )
  const { unitTypes } = useSelector((state) => state.unitTypesReducer)
  const { typeAgreements } = useSelector((state) => state.typeAgreementsReducer)
  const { activityTypes } = useSelector((state) => state.activityTypesReducer)
  const { storageTypes } = useSelector((state) => state.storageTypesReducer)

  const { unitArea, companyName } = useCompanyInfo()

  const {
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    changeOutLierHarvest,
    statusOutlier,
  } = useChangeOutliersHarvest()

  const fieldsQuantity = Number(activityLots.length)
  const surface = round(Number(activitySurface), 4)
  const activityTypeName = activityTypes
    .find(({ tag }) => tag === activityTypeTag)
    ?.label?.toLowerCase()
  const subActivityTypeName = subActivityTypes.find(
    ({ value }) => value === activitySubTypeActivity
  )?.label
  const dateStart = activityDateStart ? String(activityDateStart) : undefined
  const dateEnd = activityDateEnd ? String(activityDateEnd) : undefined
  const dateAchievement = activityDateAchievement
    ? String(activityDateAchievement)
    : undefined
  const dateHarvest = activityDateHarvest
    ? String(activityDateHarvest)
    : undefined
  const dateEstimatedHarvest = activityDateEstimatedHarvest
    ? String(activityDateEstimatedHarvest)
    : undefined
  const dateObservation = activityDateObservation
    ? String(activityDateObservation)
    : undefined
  const pay = activityPay ? Number(activityPay) : undefined
  const volume = activityVolume ? Number(activityVolume) : ''
  const unitVolume = activityUnitVolume ? activityUnitVolume : ''
  const unitType = unitTypes.find(({ value }) => value === activityUnitType)
  const unitTypeName = unitType?.label
  const unitTypeKey = unitType?.key
  const observation = activityObservation
    ? String(activityObservation)
    : undefined
  const collaboratorsQuantity = Number(activitySigners.length)
  const agreementTypeName = typeAgreements.find(
    ({ key }) => key === activityAgreementType?.key
  )?.label
  const agreementTypeKey = typeAgreements.find(
    ({ key }) => key === activityAgreementType?.key
  )?.key
  const {
    suppliesSeeds = {},
    suppliesFertilizers = {},
    suppliesPhytotherapics = {},
  } = parseSupplies({
    supplies: selectorSupplies.length ? selectorSupplies : activitySupplies,
  })
  const storages = parseStorages({ storageTypes, storages: activityStorages })
  const evidences = parseEvidences({ evidences: selectorEvidences })
  let irrigationPowerSourceName = null
  let irrigationWaterSourceName = null

  if (irrigationPowerSource) {
    irrigationPowerSourceName = irrigationPowerSources.find(
      (element) => element.value === irrigationPowerSource
    )?.label
  }
  if (irrigationWaterSource) {
    irrigationWaterSourceName = irrigationWaterSources.find(
      (element) => element.value === irrigationWaterSource
    )?.label
  }

  const activityDTO = {
    cropName,
    cropType,
    companyName,
    fieldsQuantity,
    surface,
    unitArea,
    activityTypeName,
    subActivityTypeName,
    dateStart,
    dateEnd,
    dateAchievement,
    dateHarvest,
    dateEstimatedHarvest,
    dateObservation,
    pay,
    volume,
    unitVolume,
    unitTypeName,
    unitTypeKey,
    observation,
    agreementTypeName,
    agreementTypeKey,
    suppliesSeeds,
    suppliesFertilizers,
    suppliesPhytotherapics,
    storages,
    evidences,
    collaboratorsQuantity,
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    changeOutLierHarvest,
    statusOutlier,
    irrigationPowerSourceName,
    irrigationWaterSourceName,
    pumpingDepth,
    waterConsumption,
    horizontalWateringDepth,
    weatherType,
    temperature,
    soilType,
    landType,
    humidityType,
    timeFloodUnderCrop,
    unitTime,
    paddyWaterCultivation,
    paddyWaterPreseason,
  }

  return {
    activityDTO,
  }
}
