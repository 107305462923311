export default {
  TEXT_1: 'Campo Requerido',
  TEXT_2: 'El código debe ser de seis dígitos',
  TEXT_3: 'Un código ha sido enviado a su correo',
  FIELD_1: {
    HELP_TEXT: 'Ingrese el código que recibió en su email',
    LABEL: 'Código de acceso*',
    PLACEHOLDER: 'xxxxxxx',
  },
  TEXT_4: 'Ingresar',
  TEXT_5: '¿Problemas en el ingreso?',
  TEXT_6: 'Volver a ingresar',
}
