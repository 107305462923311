import { FC, useCallback, useContext, useState } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { AppCard } from '../AppCard'
import useNetwork from '@utils/network'
import networkAdapter from '@common/utils/adapters/networkAdapter'
import { useFocusEffect } from '@react-navigation/native'
import { LanguageContext } from '@contextState/language'
import React from 'react'

export const PluginList: FC<any> = ({ route, navigation }) => {
  const {
    params: { isS2, id: companyId },
  } = route
  const { t } = useContext(LanguageContext)
  const { doRequest: origianlDoRequest } = useNetwork()
  const { doRequest } = networkAdapter(origianlDoRequest)
  const [plugins, setPlugins] = useState([])
  useFocusEffect(
    useCallback(() => {
      const fetchData = async () => {
        const response = await doRequest({
          method: 'GET',
          url: 'ms-integrator/plugins',
          identifier: isS2 ? 'companies/plugins/s2' : '',
          useMock: isS2,
        })
        const plugins = response.data
        plugins.sort((a: any, b: any) => a.name.localeCompare(b.name))
        setPlugins(plugins)
      }

      fetchData()

      return () => true
    }, [])
  )

  if (!plugins) {
    return null
  }
  const pluginsConnected = plugins.filter((plugin: any) => plugin?.connected)
  const pluginsDisconnected = plugins.filter(
    (plugin: any) => !plugin?.connected
  )

  return (
    <>
      <ScrollView>
        {pluginsConnected.length > 0 && (
          <>
            <Text style={styles.textConnected}>
              {t('COMPONENTS.APP_INTEGRATOR.CONNECTED_APPLICATIONS')}
            </Text>
            {pluginsConnected.map((plugin, index) => (
              <View style={styles.viewCard} key={index}>
                <AppCard
                  plugin={plugin}
                  companyId={companyId}
                  navigation={navigation}
                  isS2={isS2}
                  key={String(index)}
                />
              </View>
            ))}
          </>
        )}
        {pluginsDisconnected.length > 0 && (
          <>
            {pluginsConnected.length > 0 && (
              <Text style={styles.textDisconnected}>
                {t('COMPONENTS.APP_INTEGRATOR.OTHER_APPLICATIONS')}
              </Text>
            )}
            {pluginsDisconnected.map((plugin, index) => (
              <View style={styles.viewCard} key={index}>
                <AppCard
                  plugin={plugin}
                  companyId={companyId}
                  navigation={navigation}
                  isS2={isS2}
                  key={String(index)}
                />
              </View>
            ))}
          </>
        )}
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  textConnected: {
    fontWeight: '700',
    paddingBottom: 10,
    fontFamily: 'Inter',
    fontSize: 14,
  },
  textDisconnected: {
    fontWeight: '700',
    paddingTop: 15,
    paddingBottom: 10,
    fontFamily: 'Inter',
    fontSize: 14,
  },
  viewCard: { paddingBottom: 15 },
})
