/**
 * Aqui se debe crear los mocks necesarios por endpoint, se puede importar tambien los mocks
 * Se puede utilizar como index una url o un identificador
 */
const endpointMocks: Record<string, any> = {
  'companies/integrations/show': {
    data: [
      {
        label: 'auravant',
        identifier: '1',
      },
    ],
  },
  'companies/plugins': {
    data: [
      {
        _id: '651ecdcb9d3f6395594dc34d',
        key: 'john_deere',
        name: 'John Deere Operation Center™ (Sincronizando)',
        category: {
          en: 'Information management.',
          es: 'Gerenciamiento de la información',
          pt: 'Gerenciamento de informações.',
        },
        description: {
          en: 'Visualize and plan your field activities. Monitor the status of operations and analyze the data generated by your equipment. Share information with your business partners.',
          es: 'Visualiza y planifica las actividades de tu campo. Monitorea el estado de las operaciones y analiza los datos generados por tus equipos. Comparte información con tus socios comerciales.',
          pt: 'Visualize e planeje suas atividades no campo. Monitore o status das operações e analise os dados gerados pelo seu equipamento. Compartilhe informações com seus parceiros de negócios.',
        },
        status: [
          {
            name: 'Conectado',
            key: 'CONNECTED',
            updateAt: '2023-10-10T17:23:47.392Z',
            _id: '652588a3d408d960aa9fa65a',
          },
          {
            name: 'Sincronizando',
            key: 'SYNCHRONIZING',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
        ],
        features: [],
        icon64: 'https://ucropit-logos.s3.amazonaws.com/johndeere_64x.png',
        icon48: 'https://ucropit-logos.s3.amazonaws.com/johndeere_48x.png',
        url: '',
        provider: 'Jonh Deere',
        disabled: false,
        createdAt: '2023-10-05T14:52:59.055Z',
        updatedAt: '2023-10-05T14:52:59.055Z',
        __v: 0,
        connected: true,
        redirectUri:
          'https://connections.deere.com/connections/0oa2dg22vkASBfVQu5d7/select-organizations?redirect_uri=https://developer-team-onboarding.ucrop.it',
      },
      {
        _id: '651ecdcb9d3f6395594dc34d',
        key: 'john_deere',
        name: 'John Deere Operation Center™ (Sincronizado)',
        category: {
          en: 'Information management.',
          es: 'Gerenciamiento de la información',
          pt: 'Gerenciamento de informações.',
        },
        description: {
          en: 'Visualize and plan your field activities. Monitor the status of operations and analyze the data generated by your equipment. Share information with your business partners.',
          es: 'Visualiza y planifica las actividades de tu campo. Monitorea el estado de las operaciones y analiza los datos generados por tus equipos. Comparte información con tus socios comerciales.',
          pt: 'Visualize e planeje suas atividades no campo. Monitore o status das operações e analise os dados gerados pelo seu equipamento. Compartilhe informações com seus parceiros de negócios.',
        },
        status: [
          {
            name: 'Conectado',
            key: 'CONNECTED',
            updateAt: '2023-10-10T17:23:47.392Z',
            _id: '652588a3d408d960aa9fa65a',
          },
          {
            name: 'Sincronizando',
            key: 'SYNCHRONIZING',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
          {
            name: 'Sincronizado',
            key: 'SYNCHRONIZED',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
        ],
        features: [],
        icon64: 'https://ucropit-logos.s3.amazonaws.com/johndeere_64x.png',
        icon48: 'https://ucropit-logos.s3.amazonaws.com/johndeere_48x.png',
        url: '',
        provider: 'Jonh Deere',
        disabled: false,
        createdAt: '2023-10-05T14:52:59.055Z',
        updatedAt: '2023-10-05T14:52:59.055Z',
        __v: 0,
        connected: true,
        redirectUri:
          'https://connections.deere.com/connections/0oa2dg22vkASBfVQu5d7/select-organizations?redirect_uri=https://developer-team-onboarding.ucrop.it',
      },
      {
        _id: '651ece634159689baa91f35c',
        key: 'auravant',
        name: 'Auravant',
        category: {
          en: 'Precision agriculture',
          es: 'Agricultura de precisión',
          pt: 'Agricultura de precisão',
        },
        description: {
          en: 'Some description of Auravant.',
          es: 'Alguna descripcion de Auravant.',
          pt: 'Alguma descrição de Auravant.',
        },
        status: [
          {
            name: 'Conectado',
            key: 'CONNECTED',
            updateAt: '2023-10-10T17:23:47.392Z',
            _id: '652588a3d408d960aa9fa65a',
          },
          {
            name: 'Sincronizando',
            key: 'SYNCHRONIZING',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
          {
            name: 'Sincronizado',
            key: 'SYNCHRONIZED',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
        ],
        features: [],
        icon64: 'https://ucropit-logos.s3.amazonaws.com/auravant-64x.png',
        icon48: 'https://ucropit-logos.s3.amazonaws.com/auravant-48x.png',
        url: '',
        provider: 'Auravant',
        disabled: false,
        createdAt: '2023-10-05T14:55:31.082Z',
        updatedAt: '2023-10-05T14:55:31.082Z',
        __v: 0,
        connected: false,
      },
    ],
  },
  'companies/plugins/s2': {
    data: [
      {
        _id: '651ecdcb9d3f6395594dc34d',
        key: 'john_deere',
        name: 'John Deere Operation Center™ sincronizado',
        category: {
          en: 'Information management.',
          es: 'Gerenciamiento de la información',
          pt: 'Gerenciamento de informações.',
        },
        description: {
          en: 'Visualize and plan your field activities. Monitor the status of operations and analyze the data generated by your equipment. Share information with your business partners.',
          es: 'Visualiza y planifica las actividades de tu campo. Monitorea el estado de las operaciones y analiza los datos generados por tus equipos. Comparte información con tus socios comerciales.',
          pt: 'Visualize e planeje suas atividades no campo. Monitore o status das operações e analise os dados gerados pelo seu equipamento. Compartilhe informações com seus parceiros de negócios.',
        },
        url: '',
        provider: 'Jonh Deere',
        disabled: false,
        features: [],
        icon64: 'https://ucropit-logos.s3.amazonaws.com/johndeere_64x.png',
        icon48: 'https://ucropit-logos.s3.amazonaws.com/johndeere_48x.png',

        status: [
          {
            name: 'Conectado',
            key: 'CONNECTED',
            updateAt: '2023-10-10T17:23:47.392Z',
            _id: '652588a3d408d960aa9fa65a',
          },
          {
            name: 'Sincronizando',
            key: 'SYNCHRONIZING',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
          {
            name: 'Sincronizado',
            key: 'SYNCHRONIZED',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
        ],
        createdAt: '2023-10-05T14:52:59.055Z',
        updatedAt: '2023-10-05T14:52:59.055Z',
        __v: 0,
        connected: true,
      },
      {
        _id: '651ecdcb9d3f6395594dc34d',
        key: 'john_deere',
        name: 'John Deere Operation Center™ Validation',
        category: {
          en: 'Information management.',
          es: 'Gerenciamiento de la información',
          pt: 'Gerenciamento de informações.',
        },
        description: {
          en: 'Visualize and plan your field activities. Monitor the status of operations and analyze the data generated by your equipment. Share information with your business partners.',
          es: 'Visualiza y planifica las actividades de tu campo. Monitorea el estado de las operaciones y analiza los datos generados por tus equipos. Comparte información con tus socios comerciales.',
          pt: 'Visualize e planeje suas atividades no campo. Monitore o status das operações e analise os dados gerados pelo seu equipamento. Compartilhe informações com seus parceiros de negócios.',
        },
        url: '',
        provider: 'Jonh Deere',
        disabled: false,
        features: [],
        icon64: 'https://ucropit-logos.s3.amazonaws.com/johndeere_64x.png',
        icon48: 'https://ucropit-logos.s3.amazonaws.com/johndeere_48x.png',

        status: [
          {
            name: 'Conectado',
            key: 'CONNECTED',
            updateAt: '2023-10-10T17:23:47.392Z',
            _id: '652588a3d408d960aa9fa65a',
          },
          {
            name: 'Sincronizando',
            key: 'SYNCHRONIZING',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
          {
            name: 'Sincronizado',
            key: 'SYNCHRONIZED',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
          {
            name: 'Validacion Pendiente',
            key: 'PENDING_VALIDATION',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
        ],
        createdAt: '2023-10-05T14:52:59.055Z',
        updatedAt: '2023-10-05T14:52:59.055Z',
        __v: 0,
        connected: true,
      },
      {
        _id: '651ecdcb9d3f6395594dc34d',
        key: 'john_deere',
        name: 'John Deere Operation Center™ disconnected',
        category: {
          en: 'Information management.',
          es: 'Gerenciamiento de la información',
          pt: 'Gerenciamento de informações.',
        },
        description: {
          en: 'Visualize and plan your field activities. Monitor the status of operations and analyze the data generated by your equipment. Share information with your business partners.',
          es: 'Visualiza y planifica las actividades de tu campo. Monitorea el estado de las operaciones y analiza los datos generados por tus equipos. Comparte información con tus socios comerciales.',
          pt: 'Visualize e planeje suas atividades no campo. Monitore o status das operações e analise os dados gerados pelo seu equipamento. Compartilhe informações com seus parceiros de negócios.',
        },
        features: [],
        icon64: 'https://ucropit-logos.s3.amazonaws.com/johndeere_64x.png',
        icon48: 'https://ucropit-logos.s3.amazonaws.com/johndeere_48x.png',
        url: '',
        provider: 'Jonh Deere',
        disabled: false,
        status: [],
        createdAt: '2023-10-05T14:52:59.055Z',
        updatedAt: '2023-10-05T14:52:59.055Z',
        __v: 0,
        connected: false,
      },
      {
        _id: '651ecdcb9d3f6395594dc34d',
        key: 'john_deere',
        name: 'John Deere Operation Center™ sincronizando',
        category: {
          en: 'Information management.',
          es: 'Gerenciamiento de la información',
          pt: 'Gerenciamento de informações.',
        },
        description: {
          en: 'Visualize and plan your field activities. Monitor the status of operations and analyze the data generated by your equipment. Share information with your business partners.',
          es: 'Visualiza y planifica las actividades de tu campo. Monitorea el estado de las operaciones y analiza los datos generados por tus equipos. Comparte información con tus socios comerciales.',
          pt: 'Visualize e planeje suas atividades no campo. Monitore o status das operações e analise os dados gerados pelo seu equipamento. Compartilhe informações com seus parceiros de negócios.',
        },
        features: [],
        icon64: 'https://ucropit-logos.s3.amazonaws.com/johndeere_64x.png',
        icon48: 'https://ucropit-logos.s3.amazonaws.com/johndeere_48x.png',
        url: '',
        provider: 'Jonh Deere',
        disabled: false,
        status: [
          {
            name: 'Conectado',
            key: 'CONNECTED',
            updateAt: '2023-10-10T17:23:47.392Z',
            _id: '652588a3d408d960aa9fa65a',
          },
          {
            name: 'Sincronizando',
            key: 'SYNCHRONIZING',
            updateAt: '2023-10-10T17:30:39.766Z',
            _id: '65258a3f40ca0b8167ebadba',
          },
        ],
        createdAt: '2023-10-05T14:52:59.055Z',
        updatedAt: '2023-10-05T14:52:59.055Z',
        __v: 0,
        connected: true,
        redirectUri:
          'https://connections.deere.com/connections/0oa2dg22vkASBfVQu5d7/select-organizations?redirect_uri=https://developer-team-onboarding.ucrop.it',
      },
      {
        _id: '651ece634159689baa91f35c',
        key: 'auravant',
        name: 'Auravant',
        category: {
          en: 'Precision agriculture',
          es: 'Agricultura de precisión',
          pt: 'Agricultura de precisão',
        },
        features: [],
        status: [],
        icon64: 'https://ucropit-logos.s3.amazonaws.com/auravant-64x.png',
        icon48: 'https://ucropit-logos.s3.amazonaws.com/auravant-48x.png',
        url: '',
        provider: 'Auravant',
        disabled: false,
        createdAt: '2023-10-05T14:55:31.082Z',
        updatedAt: '2023-10-05T14:55:31.082Z',
        __v: 0,
        connected: false,
      },
    ],
  },
}

export default endpointMocks
