import { PropTypes } from 'prop-types'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import { carbon600, white } from '@styles/palette'
import { CommonIcon } from '@modules/common/components'

export const HeaderOnlyBack = ({
  navigation,
  onPress,
  icon = 'ARROW-LEFT',
  style,
  iconSize = 24,
  iconColor = carbon600,
  testID = null,
}) => {
  return (
    <View style={[styles.container, style]}>
      <CommonIcon
        name={icon}
        size={iconSize}
        color={iconColor}
        onPress={() => (onPress ? onPress() : navigation?.goBack())}
        testID={testID}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    paddingTop: 40,
  },
})

HeaderOnlyBack.propTypes = {
  navigation: PropTypes.object,
  onPress: PropTypes.func,
  icon: PropTypes.string,
  style: PropTypes.object,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
}
