import React, { useContext } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import CollaboratorCardDetails from '../../collaborators/CollaboratorCardDetails'
import { ROL } from '@constants/roles'
import { LanguageContext } from '@contextState/language'
import ButtonCustom from '../../common/ButtonCustom'
import { PropTypes } from 'prop-types'

const DelegateValidations = ({
  onClose,
  notfound = false,
  modal = false,
  currentKey,
  findCropCollaborator,
  isFetchingCrop,
  setDisabled,
  getTitleMenu,
  goToCollaboratorsDelegateSignatures,
}) => {
  const contentModal = (notfound) =>
    notfound ? styles.containerModalNotFound : styles.containerModal
  const headerModal = (modal) =>
    modal
      ? [styles.containerHeaderModalGeneral, styles.containerHeaderIsModal]
      : [styles.containerHeaderModalGeneral, styles.containerHeaderModal]
  const bodyModal = (modal) =>
    modal ? styles.contentIsModal : styles.contentModal
  const { t } = useContext(LanguageContext)

  return (
    <View style={contentModal(notfound)}>
      <View style={headerModal(modal)}>
        <TouchableOpacity onPress={onClose}>
          <CommonIcon name={'X'} size={20} style={styles.buttonClose} />
        </TouchableOpacity>
        <Text style={styles.titleModal}>
          {t('VIEWS').CROP_COLLABORATOR.TEXT_18}
        </Text>
      </View>
      <View style={bodyModal(modal)}>
        {currentKey != null && (
          <CollaboratorCardDetails
            notfound={notfound}
            collaborator={findCropCollaborator[currentKey]}
            loading={isFetchingCrop}
            disabled={setDisabled(findCropCollaborator[currentKey])}
            companyName={findCropCollaborator[currentKey].company.name}
            collaboratorType={findCropCollaborator[currentKey].rol}
            title={getTitleMenu(findCropCollaborator[currentKey])}
            subtitle={
              ROL[findCropCollaborator[currentKey].rol](t) || ROL['DEFAULT'](t)
            }
            status={!!findCropCollaborator[currentKey].firstName}
            pendingValidation={
              findCropCollaborator[currentKey].pendingValidations.length
            }
            tag={findCropCollaborator[currentKey].tag}
          />
        )}
        {!notfound && (
          <View style={styles.containerButton}>
            <ButtonCustom onPress={goToCollaboratorsDelegateSignatures}>
              {t('VIEWS').CROP_COLLABORATOR.MODAL_ACTION_SHEET.TEXT_1}
            </ButtonCustom>
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  containerModal: {
    backgroundColor: 'white',
  },
  containerModalNotFound: {
    backgroundColor: '#FBE9E7',
  },
  containerHeaderModalGeneral: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 16,
  },
  containerHeaderModal: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  containerHeaderIsModal: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  buttonClose: {
    alignSelf: 'flex-start',
  },
  titleModal: {
    color: '#333333',
    fontWeight: 'bold',
    marginLeft: 20,
    fontSize: 18,
  },
  contentModal: {
    paddingHorizontal: 16,
  },
  contentIsModal: {
    paddingHorizontal: 0,
  },
  containerButton: {
    paddingVertical: 15,
    marginTop: 15,
  },
})

DelegateValidations.propTypes = {
  onClose: PropTypes.func.isRequired,
  notfound: PropTypes.bool,
  modal: PropTypes.bool,
  currentKey: PropTypes.number,
  findCropCollaborator: PropTypes.array.isRequired,
  isFetchingCrop: PropTypes.bool,
  setDisabled: PropTypes.func.isRequired,
  getTitleMenu: PropTypes.func.isRequired,
  goToCollaboratorsDelegateSignatures: PropTypes.func.isRequired,
}

export default DelegateValidations
