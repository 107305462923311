export default {
  TEXT_1: 'license',
  TEXT_2: 'I read the license and agree to participate in this ecosystem.',
  TEXT_3: 'Sign',
  TEXT_4: 'Information visibility',
  TEXT_5:
    'When you sign this license, the information of this crop will be shared with the issuing company.',
  TEXT_6: 'Reject',
  TEXT_7: 'Accept',
}
