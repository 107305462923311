export default {
  TEXT_1: 'Required field',
  TEXT_2: 'Invalid email',
  TEXT_3: "We can't find you in the system",
  TEXT_4: 'CREATE YOUR ACCOUNT',
  FIELD_1: {
    HELP_TEXT: 'The code to access the platform will be sent to this address',
    LABEL: 'Email',
    PLACEHOLDER: 'Enter your email',
  },
  FIELD_2: {
    LABEL: 'First Name',
    PLACEHOLDER: 'Enter your first name',
  },
  FIELD_3: {
    LABEL: 'Last Name',
    PLACEHOLDER: 'Enter your last name',
  },
  FIELD_4: {
    LABEL: 'Mobile phone',
    PLACEHOLDER: 'Enter your mobile phone',
  },
  TEXT_5: 'I accept the use policies',
  TEXT_6: 'Get login code',
  TEXT_7: 'I accept the',
  TEXT_8: 'terms and conditions of use',
  PRIVACY_POLICY_URL: 'en/privacy-policy/',
}
