import { useCallback, useState } from 'react'
import RouterNames from '@constants/routerNames'
import { TYPE_RENDER_WEB_VIEW } from '@modules/webView/constants/typesRendes'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { ResponsePaginationInterface } from '@common/interfaces'
import { Platform } from 'react-native'
import { getUrlDrawFields } from '@modules/webView/utils'
import { makeRequest } from '@common/services'
import { useAuth } from '@common/hooks'

export const useFarmsMain = () => {
  const navigation = useNavigation()
  const [urlWebView, setUrlWebView] = useState('')
  const { config } = useAuth()
  const identifier = config.companySelected?.identifier

  const fetchCompanies = async (identifierCompany: string) => {
    const response = await makeRequest({
      url: `farms/companies/${identifierCompany}`,
      version: 'v1',
      method: 'GET',
      params: {
        limit: 1,
      },
    })

    const { meta }: ResponsePaginationInterface = response.data
    if (!meta.totalItems !== undefined && meta.totalItems === 0) {
      navigation.navigate(RouterNames.FARM_MAP_TO_URL_WEB_VIEW, {
        resource: TYPE_RENDER_WEB_VIEW.CREATE_FARM,
        notClose: true,
        noneFarms: true,
      })
    }
  }

  useFocusEffect(
    useCallback(() => {
      fetchCompanies(identifier)
    }, [identifier])
  )

  const fetchWebViewUrl = async () => {
    const url = await getUrlDrawFields.getUrlFarMap()
    setUrlWebView(url)
  }

  useFocusEffect(
    useCallback(() => {
      fetchWebViewUrl()
    }, [identifier])
  )

  const validateStateChange = (url: string) => {
    if (url?.indexOf('/redirect') !== -1) {
      console.log('url', url)
    }
  }
  const handleWebViewNavigationStateChange = (newNavState: any) =>
    validateStateChange(newNavState?.url)

  const handleIFrameMessages = (event: MessageEvent | any) => {
    const data = Platform.OS === 'web' ? event.data : event.nativeEvent.data

    try {
      if (data) {
        const dataParsed = JSON.parse(data)

        if (dataParsed.message === 'REDIRECT_URL') {
          const defaultParams = {
            initialRouteName: dataParsed.url,
          }

          const params: object = dataParsed.params
            ? {
                ...defaultParams,
                ...JSON.parse(dataParsed.params),
              }
            : defaultParams

          //@ts-ignore
          navigation.navigate(RouterNames.CROPS, params)
        }
      }
    } catch (error) {}
  }

  return {
    handleWebViewNavigationStateChange,
    handleIFrameMessages,
    urlWebView,
  }
}
