import getDatabase from './getDataBase'

function useOfflineTypes() {
  const db = getDatabase('db.offlinedata')

  function getTypes(name) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve([])
      }

      db.transaction((tx) => {
        tx.executeSql(
          `SELECT * FROM ${name}_types`,
          [],
          (_, { rows }) => {
            resolve(rows._array.map((el) => JSON.parse(el.data)))
          },
          null,
          (_, err) => console.warn(err)
        )
      })
    })

    return promise
  }

  function storeType(name, data) {
    const promise = new Promise((resolve) => {
      if (!db) {
        return resolve([])
      }

      db.transaction((tx) => {
        tx.executeSql(
          `CREATE TABLE IF NOT EXISTS ${name}_types (id integer primary key not null, _id text, data text);`,
          [],
          null,
          (_, err) => console.warn(`Error in ${name}_types create`, err)
        )
      })

      db.transaction(
        (tx) => {
          data.map((el) => {
            tx.executeSql(
              `SELECT * FROM ${name}_types where _id = '${el._id}'`,
              [],
              (_, { rows }) => {
                if (rows.length === 0) {
                  tx.executeSql(
                    `INSERT INTO ${name}_types (_id, data) values (?,?)`,
                    [el._id, JSON.stringify(el)],
                    null,
                    (_, err) => console.warn(`ERR STORE ${name} type: ${err}`)
                  )
                }
              },
              (_, err) => console.warn(err)
            )
          })
        },
        null,
        () => resolve()
      )
    })
    return promise
  }
  return { storeType, getTypes }
}

export default useOfflineTypes
