import { parse } from 'wkt'
import { polygon, buffer, point, booleanPointInPolygon } from '@turf/turf'

export const validateLocationInLot = ({ location, lots, jsonWkt }) => {
  const isInLot = lots.find((element) => {
    let geoJson = null
    const auxJsonWkt = jsonWkt[element._id]
    if (auxJsonWkt?.wktFormat) {
      geoJson = parse(auxJsonWkt.wktFormat)
    } else if (auxJsonWkt?.area) {
      geoJson = polygon([element.area])
    }
    if (geoJson) {
      const buffered = buffer(geoJson, 20, { units: 'meters' })

      const currentPoint = point([location.longitude, location.latitude])

      const isInside = booleanPointInPolygon(currentPoint, buffered)

      return isInside
    }

    return false
  })

  return isInLot
}
