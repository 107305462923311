/**
 * @param {Array.<object>} activityTypes
 * @param {string} activityTypes.value
 * @param {string} activityTypes.label
 * @param {string} activityTypes.tag
 */
export const activityTypesNotification = (activityTypes) => {
  const activityTypesCopy = [...activityTypes]
  return activityTypesCopy.sort((activityTypeA, activityTypeB) =>
    activityTypeA.label.localeCompare(activityTypeB.label)
  )
}
