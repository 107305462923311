export default {
  ACTIVITY_TYPE_TITLE: 'Tipo de %{activityType}',
  FIELDS_QUANTITY_TITLE: 'Lotes selecionados',
  SURFACE_TITLE: 'Superficie',
  PLANNED_DATE_TITLE: 'Planned planting date %{activityType}',
  SINCE: 'Desde %{date}',
  UNTIL: 'Até %{date}',
  REALIZED_DATE_TITLE: 'Data de realização',
  HARVEST_DATE_TITLE: 'Harvest date',
  MONITORING_DATE_TITLE: 'Scouting date',
  AGREEMENT_TYPE_TITLE: 'Tipo de declaração',
}
