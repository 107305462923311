import NOTIFICATION_EMPTY_STATE from '../../components/EmptyState/translate/es'
import NOTIFICATION_LIST from '../../components/NotificationList/translate/es'
import NOTIFICATION_PENDING_TASK from '../../components/NotificationPendingTask/translate/es'

export default {
  NAVIGATION: {
    TITLE: 'Notificaciones',
  },
  COMPONENTS: {
    NOTIFICATION_EMPTY_STATE,
    NOTIFICATION_LIST,
    NOTIFICATION_PENDING_TASK,
  },
}
