export default {
  COUNTRY: {
    PLACEHOLDER: 'País',
    LABEL: 'País',
  },
  EMAIL: {
    PLACEHOLDER: 'Email',
    LABEL: 'Email',
  },
  CONFIRM_EMAIL: {
    PLACEHOLDER: 'Confirmar email',
    LABEL: 'Confirmar email',
  },
  COLLABORATOR_TYPE: {
    PLACEHOLDER: 'Tipo de contribuidor',
    LABEL: 'Tipo de contribuidor',
  },
  SAVE: 'SALVE',
  NEXT: 'PRÓXIMO',
}
