import * as yup from 'yup'
import { cuitValidator } from '@utils/validation'
import {
  notSpecialCharacters,
  onlyNumbers,
} from '@common/utils/regularsExpressions'

export const getFormikValidationSchema = (
  t,
  countries,
  isAdviser,
  firstEmailUser,
  identifierCompany
) => {
  return yup.object().shape({
    country: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(),
    identifier: identifierCompany
      ? yup
          .string()
          .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .when('country', {
            is: (value) =>
              value?.value ===
              countries.find((element) => element.alpha3Code === 'ARG')?._id,
            then: yup
              .string()
              .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
              .test('CuitValid', t('UTILS.VALIDATION.TEXT_1'), (val) =>
                cuitValidator.test(val)
              )
              .matches(onlyNumbers, t('ERRORS.FORM_ERRORS.IS_NUMBER')),
            otherwise: yup
              .string()
              .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
              .matches(notSpecialCharacters, {
                message: t('ERRORS.FORM_ERRORS.NOT_SPECIAL_CHARACTERS'),
                excludeEmptyString: true,
              }),
          })
          .oneOf(
            [identifierCompany ?? null],
            identifierCompany ? t('ERRORS.FORM_ERRORS.THIS_VALUE_IS') : null
          )
      : yup
          .string()
          .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .when('country', {
            is: (value) =>
              value?.value ===
              countries.find((element) => element.alpha3Code === 'ARG')?._id,
            then: yup
              .string()
              .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
              .matches(onlyNumbers, t('ERRORS.FORM_ERRORS.IS_NUMBER'))
              .test('CuitValid', t('UTILS.VALIDATION.TEXT_1'), (val) =>
                cuitValidator.test(val)
              ),
            otherwise: yup
              .string()
              .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
              .matches(notSpecialCharacters, {
                message: t('ERRORS.FORM_ERRORS.NOT_SPECIAL_CHARACTERS'),
                excludeEmptyString: true,
              }),
          }),
    email: yup
      .string()
      .email(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .notOneOf([firstEmailUser ?? null], t('ERRORS.FORM_ERRORS.EMAIL_EXIST')),
    confirmEmail: yup
      .string()
      .email(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED'))
      .oneOf([yup.ref('email'), null], t('ERRORS.FORM_ERRORS.MUST_MATCH')),
    userType: !isAdviser
      ? yup
          .object()
          .shape({
            value: yup
              .string()
              .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
              .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
          })
          .required()
      : null,
  })
}
