import PropTypes from 'prop-types'
import React from 'react'
import useActionSheetCustom from '@hooks/useActionSheetCustom'
import { AboutActivities } from '../components'

export const useAboutActivities = ({ t }) => {
  const { ActionSheetComponent, openActionSheet } = useActionSheetCustom(t)

  const ComponentAboutActivities = () => {
    return (
      <ActionSheetComponent>
        <AboutActivities t={t} />
      </ActionSheetComponent>
    )
  }
  return {
    ComponentAboutActivities,
    openBackdropAboutActivities: openActionSheet,
  }
}

useAboutActivities.propTypes = {
  t: PropTypes.func.isRequired,
}
