export const parseStorages = ({ storages, storageTypes }) => {
  const storagesParsed = storages.map(({ tonsHarvest, storageType }) => {
    const tons = tonsHarvest

    const storageTypeName = storageTypes.find(
      ({ value }) => value === storageType
    )?.label

    return {
      tons,
      storageTypeName,
    }
  })

  return storagesParsed
}
