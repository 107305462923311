export default {
  TEXT_1: 'UCROP.IT necesita permisos de localización',
  TEXT_2: 'La extensión permitida es .kmz',
  TEXT_3: 'Las extensiones permitidas serian .doc,.docx,.pdf,.png,.jpg,.zip',
  TEXT_4: 'Extension del archivo no esta permitido',
  TEXT_5: 'Aceptar',
  TEXT_6: 'Agregar evidencia',
  TEXT_7: 'Buscando georreferencia...',
  TEXT_8: 'Por favor, no salir de esta pantalla',
  TEXT_9: 'El archivo que intenta subir no posee los datos de localización.',
  TEXT_10: 'Sin permisos de la camara',
  TEXT_11: 'Por favor vaya a la configuración y activar manualmente',
  TEXT_12: 'Cancelar',
  TEXT_13: 'Permitir',
  TEXT_14: 'Galeria',
  TEXT_15: 'Documentos',
  TEXT_16: 'Cámara',
  TEXT_17: 'Cancelar',
  TEXT_18: 'Intenta subir otro archivo',
  TEXT_19:
    'El archivo que intentas subir no es un tipo de formato KMZ o se ha dañado.',
}
