import React, { useContext, useCallback } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { PropTypes } from 'prop-types'
import { blackHighEmphasis, onPressedColor } from '@styles/palette'
import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import activityTypes from '@constants/activityTypes'
import { TouchableRipple } from 'react-native-paper'
import { unitMeasureSystem } from '@utils/constants'

export const DestinationsList = ({
  tag,
  storages,
  handleDeleteDestination,
  readOnly,
  company,
}) => {
  if (tag !== activityTypes.ACT_HARVEST.key) {
    return false
  }

  const { t } = useContext(LanguageContext)

  const DestinationRemove = useCallback(
    ({ position, disabled, onDelete, isPersisted }) => {
      if (!onDelete || isPersisted) {
        return null
      }
      return (
        <View style={styles.removeIconContainer}>
          <CommonIcon
            name={'X'}
            size={22}
            color={blackHighEmphasis}
            onPress={() => !disabled && onDelete(position)}
            disabled={disabled}
          />
        </View>
      )
    },
    []
  )

  return storages.map((el, key) => (
    <TouchableRipple
      key={key}
      style={styles.destinationContainer}
      onPress={() => null}
      rippleColor={onPressedColor}
      underlayColor={onPressedColor}
    >
      <>
        <View style={styles.iconContainer}>
          <CommonIcon name={'STORAGE'} size={35} color={blackHighEmphasis} />
        </View>

        <View style={styles.descriptionContainer}>
          <Text style={styles.destinationNameText}>
            {t('VIEWS').ACTIVITY_CREATE.DESTINATION_LIST.TEXT_1}
          </Text>
          <Text style={styles.destinationNameText}>
            {`${el.tonsHarvest} ${
              company?.unitMeasureSystem === unitMeasureSystem.IMPERIAL
                ? t('VIEWS.HARVEST.TEXT_9')
                : t('VIEWS.HARVEST.TEXT_4')
            }`}
          </Text>
        </View>

        <DestinationRemove
          key={key}
          position={key}
          disabled={readOnly}
          onDelete={() => handleDeleteDestination(key)}
          isPersisted={el?.persisted}
        />
      </>
    </TouchableRipple>
  ))
}

const styles = StyleSheet.create({
  destinationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, .1)',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
  },
  removeIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginRight: 8,
  },
  descriptionContainer: {
    flex: 1,
  },
  destinationNameText: {
    fontSize: 14,
    fontWeight: '400',
    color: blackHighEmphasis,
  },
})

DestinationsList.propTypes = {
  tag: PropTypes.string.isRequired,
  storages: PropTypes.array,
  handleDeleteDestination: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
}
