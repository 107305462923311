import { ReactElement } from 'react'
import { StyleSheet, Text, Platform } from 'react-native'
import InputText from '@components/inputs/InputText'
import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import { truncateSurface } from '@common/utils/truncateSurface'
import { SIZES } from '@common/components/icons/interfaces'
import { useFetchWaterCalculation } from '../hooks/useFetchWaterCalculation'
import { gray400 } from '@common/styles'

interface StatementWaterCalculationProps {
  onPressLotsInput?: () => void
  formik: any
  areaUnit: string
  readOnly: boolean
}

export const StatementWaterCalculation = ({
  formik,
  areaUnit,
  readOnly,
}: StatementWaterCalculationProps): ReactElement => {
  const { setFieldValue, values, setFieldTouched, touched, errors } = formik
  const {
    weatherTypes,
    soilTypes,
    landTypes,
    humidityTypes,
    t,
    onPressLotsInput,
    isLotsSelected,
    unitTemperature,
    weatherRef,
    soilRef,
    landRef,
    humidityRef,
  } = useFetchWaterCalculation({ formik })
  const isDisabled = Boolean(readOnly) || !isLotsSelected

  return (
    <>
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={SIZES.MEDIUM}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_2.PLACEHOLDER'
        )}
        label={t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_2.PLACEHOLDER')}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <InputSelectDefault
        id='weatherType'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('weatherType', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('weatherType'))}
        value={weatherTypes.length ? values.weatherType : null}
        label={t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.WEATHER')}
        placeholder={t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.WEATHER')}
        options={weatherTypes}
        touched={touched.weatherType}
        error={errors?.weatherType?.value}
        disabled={isDisabled}
        selectRef={weatherRef}
      />

      <InputText
        id='temperature'
        containerStyle={styles.containerInput}
        value={values.temperature}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.TEMPERATURE',
          { unitTemperature }
        )}`}
        label={`${t(
          'VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.TEMPERATURE',
          {
            unitTemperature,
          }
        )}`}
        editable={!isDisabled}
        disabled={isDisabled}
        onBlur={() => setFieldTouched('temperature')}
        touched={touched.temperature}
        error={errors.temperature}
        setFieldValue={setFieldValue}
      />

      <InputSelectDefault
        id='landType'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('landType', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('landType'))}
        value={landTypes.length ? values.landType : null}
        label={t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.LAND_TYPE')}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.LAND_TYPE'
        )}
        options={landTypes}
        touched={touched.landType}
        error={errors?.landType?.value}
        disabled={isDisabled}
        selectRef={landRef}
      />
      <InputSelectDefault
        id='soilType'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('soilType', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('soilType'))}
        value={soilTypes.length ? values.soilType : null}
        label={t(
          'VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.SOIL_CHARACTERISTIC'
        )}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.SOIL_CHARACTERISTIC'
        )}
        options={soilTypes}
        touched={touched.soilType}
        error={errors?.soilType?.value}
        disabled={isDisabled}
        selectRef={soilRef}
      />
      <InputSelectDefault
        id='humidityType'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('humidityType', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('humidityType'))}
        value={humidityTypes.length ? values.humidityType : null}
        label={t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.SOIL_MOISTURE')}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.SOIL_MOISTURE'
        )}
        options={humidityTypes}
        touched={touched.humidityType}
        error={errors?.humidityType?.value}
        disabled={isDisabled}
        selectRef={humidityRef}
      />
      <Text style={styles.textDescriptionHumidity}>
        {t('VIEWS.ACTIVITY_CREATE.WATER_CALCULATION_FORM.MEASURED_AT_PLANTING')}
      </Text>
    </>
  )
}

StatementWaterCalculation.displayName = 'StatementWaterCalculation'

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
  textDescriptionHumidity: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 16,
    color: gray400,
    marginLeft: 16,
    marginTop: Platform.OS === 'web' ? -12 : -24,
  },
})
