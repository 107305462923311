import { useState, useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { PropTypes } from 'prop-types'

import useNetwork from '@utils/network'
import { useOfflineCrops } from '@modules/common/hooks'

export const useDisassociateField = ({ cropId, fieldId }) => {
  const { doRequest } = useNetwork()
  const { updateFarmsCropOffline } = useOfflineCrops()

  const navigation = useNavigation()

  const [isLoading, setIsLoading] = useState(false)

  const onDisassociateField = useCallback(async () => {
    setIsLoading(true)

    const requestData = {
      method: 'PUT',
      url: `crops/${cropId}/lot`,
      params: {
        lotId: fieldId,
      },
      displayAlert: false,
      version: 'v2',
    }

    try {
      await doRequest(requestData)
    } catch (error) {
      console.error(error)
    }

    setIsLoading(false)

    updateFarmsCropOffline({ cropId }).then(({ error }) => {
      if (error) {
        console.error(error)
      }
    })

    navigation.goBack()
  }, [])

  return {
    isLoading,
    onDisassociateField,
  }
}

useDisassociateField.propTypes = {
  cropId: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
}
