import React, { useState, useContext, useEffect } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import { Divider } from 'react-native-paper'

import { LanguageContext } from '@contextState/language'
import { SelectorContext } from '@contextState/selectors'
import { CropContext } from '@contextState/crop'
import { enumUnitArea, formatSurface, truncateSurface } from '@common/utils'
import { unitMeasureSystem as UnitMeasureSystem } from '@utils/constants'
import {
  EstablishmentInterface,
  LotInterface,
} from '@modules/activities/interfaces'
import { EditFieldSurface } from '@modules/fields/components'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { useAuth } from '@common/hooks'

const INCREMENT = 10

interface ActivityMinimumUnitLotProps {
  route: any
  navigation: any
}

const ActivityMinimumUnitField = ({
  route,
  navigation,
}: ActivityMinimumUnitLotProps) => {
  const { activityImport = false } = route.params
  const { currentCrop }: any = useContext(CropContext)
  const { config } = useAuth()
  const { t } = useContext(LanguageContext)
  const {
    lotSelector,
    establishmentsSelector,
    setEstablishmentsSelector,
    lotSelectorTotalSurface,
    setSelectorTotalSurface,
  }: any = useContext(SelectorContext)

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [establishments, setEstablishments] = useState(establishmentsSelector)
  const [fieldsError, setFieldsError] = useState<string[]>([])

  useEffect(() => {
    getTotalSurface()
  }, [establishments])

  const onPressAdd = (
    surfaceValue: number,
    indexFarm: number,
    indexField: number
  ) => {
    const auxEstablishments = establishments
    const resultSurface = surfaceValue + INCREMENT
    const surfaceField = auxEstablishments[indexFarm].lots[indexField].surface
    auxEstablishments[indexFarm].lots[indexField].surfaceRealized =
      resultSurface > surfaceField ? surfaceField : resultSurface
    setEstablishments([...auxEstablishments])
  }

  const onPressSubtract = (
    surfaceValue: number,
    indexFarm: number,
    indexField: number
  ) => {
    const auxEstablishments = establishments
    const resultSurface = Number(surfaceValue - INCREMENT).toFixed(4)
    auxEstablishments[indexFarm].lots[indexField].surfaceRealized =
      Number(resultSurface) > 0 ? resultSurface : 0
    setEstablishments([...auxEstablishments])
  }

  const editSurfaceLot = (
    lotId: string,
    surface: number,
    indexFarm: number,
    indexField: number
  ) => {
    const auxEstablishments = establishments
    if (auxEstablishments[indexFarm].lots[indexField].surface < surface) {
      if (fieldsError.indexOf(lotId) === -1) {
        setFieldsError([...fieldsError, lotId])
      }
    } else {
      if (fieldsError.indexOf(lotId) !== -1) {
        setFieldsError(fieldsError.filter((item) => item !== lotId))
      }
    }
    auxEstablishments[indexFarm].lots[indexField].surfaceRealized = surface

    setEstablishments([...auxEstablishments])
  }

  const getTotalSurface = () => {
    let total = 0

    establishments.forEach((establishment: EstablishmentInterface) => {
      if (establishment.lots) {
        establishment.lots.forEach((lot: LotInterface) => {
          if (lot.surfaceRealized) {
            total += lot.surfaceRealized
          }
        })
      }
    })

    setSelectorTotalSurface(truncateSurface(total))
  }

  const renderLotsInEstablishment = (
    establishments: EstablishmentInterface[]
  ) =>
    establishments &&
    establishments.map((establishment, indexFarm) => (
      <View key={indexFarm}>
        <View style={styles.contentTitle}>
          <Text style={styles.title}>{establishment.tag}</Text>
        </View>
        {establishment.lots.map((lot, indexField) => (
          <View key={indexField}>
            <EditFieldSurface
              key={lot._id}
              id={lot._id}
              name={lot.name}
              cityName={lot.cityName}
              provinceName={lot.provinceName}
              imageUrl={lot.imageUrl}
              surface={lot.surface ?? 0}
              surfacePlanned={
                lot.surfacePlanned !== undefined
                  ? lot.surfacePlanned
                  : lot.surface
              }
              surfaceRealized={
                lot.surfaceRealized ?? lot.surfacePlanned ?? lot.surface ?? 0
              }
              onPressAdd={onPressAdd}
              onPressSubtract={onPressSubtract}
              editSurfaceLot={editSurfaceLot}
              readOnly={route.params.readOnly}
              hideSurfacePlanned={route.params.hideSurfacePlanned}
              areaUnit={lot?.areaUnit ?? ''}
              indexFarm={indexFarm}
              indexField={indexField}
              error={fieldsError.indexOf(lot._id) !== -1}
            />
            <Divider />
          </View>
        ))}
      </View>
    ))

  const goToAchievementForm = () => {
    if (activityImport) {
      window.parent.postMessage(establishments, '*')
      return
    }
    if (isSubmitted) {
      return
    }

    setIsSubmitted(true)

    if (route.params.callback) {
      const dataToReturn = establishments
        .flatMap((element: EstablishmentInterface) =>
          element.lots.map((subElement) => ({
            ...subElement,
            tag: element.tag,
          }))
        )
        .map((element: any) => ({
          _id: element._id,
          surface: element.surfaceRealized,
          tag: element.tag,
          farm: element.farm,
        }))

      route.params.callback(dataToReturn)

      return
    }

    const newEstablishments = establishments
      .flatMap((element: EstablishmentInterface) =>
        element.lots.map((subElement) => ({
          ...subElement,
          tag: element.tag,
        }))
      )
      .map((element: any) => ({
        lot: element._id,
        tag: element.tag,
        surfacePlanned: element.surfacePlanned ?? element.surfaceRealized,
        surfaceAchievement: element.surfaceRealized,
        farm: element.farm,
      }))

    setEstablishmentsSelector(newEstablishments)

    if (route.params.valuesParams && route.params.routeRedirect) {
      const valuesForm = route.params.valuesParams.valuesForm
      if (lotSelector.length) {
        valuesForm.lots = {
          value: lotSelector,
          label: `${lotSelector.length} ${t(
            'COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_1'
          )}`,
        }
        valuesForm.surface = String(lotSelectorTotalSurface)
      }
      navigation.navigate(route.params.routeRedirect, {
        ...route.params.valuesParams,
        valuesForm,
      })
    } else {
      navigation.navigate('AchievementsScreen', {
        id: route.params.cropId,
        activity: route.params.activityId,
      })
    }
  }

  const getUnitSurface = () => {
    return currentCrop?.areaUnit
      ? currentCrop?.areaUnit
      : (config?.companySelected?.unitMeasureSystem ===
        UnitMeasureSystem.IMPERIAL
          ? enumUnitArea.ACRE
          : enumUnitArea.HECTARE) || ''
  }

  return (
    <>
      <ScrollView style={styles.scroll}>
        <View style={styles.container}>
          {renderLotsInEstablishment(establishments)}

          <View style={styles.contentSurface}>
            <Text style={styles.surfaceTitle}>
              {t('VIEWS').ACHIEVEMENT_LOTS_SURFACE.TEXT_7}
            </Text>
            <Text style={styles.surfaceSubTitle}>
              {formatSurface(lotSelectorTotalSurface ?? 0, getUnitSurface())}
            </Text>
          </View>

          {!route.params.readOnly && (
            <View style={styles.contentButton}>
              <ButtonDefault
                text={t('VIEWS.ACHIEVEMENT_LOTS_SURFACE.TEXT_8')}
                typeButton={fieldsError.length ? 'disabled' : 'primary'}
                disabled={Boolean(fieldsError.length)}
                onPress={goToAchievementForm}
              />
            </View>
          )}
        </View>
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  scroll: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  container: {
    padding: 16,
    backgroundColor: '#FFFFFF',
    flex: 1,
  },
  contentTitle: {
    paddingVertical: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: '700',
  },
  contentSurface: {
    paddingVertical: 10,
    marginBottom: 20,
  },
  surfaceTitle: {
    fontSize: 20,
    fontWeight: '700',
    paddingVertical: 10,
  },
  surfaceSubTitle: {
    fontSize: 14,
    fontWeight: '400',
  },
  contentButton: {
    paddingVertical: 10,
  },
  titleSign: {
    color: '#FFFFFF',
  },
})

export default ActivityMinimumUnitField
