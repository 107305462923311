export default {
  TITLE: 'Conoce más acerca de las actividades',
  DRAFT: {
    TITLE: 'Borrador',
    DESCRIPTION:
      'Son las actividades que no se finalizaron de cargar o fueron modificadas por dos o más colaboradores y necesitan confirmar una versión.',
  },
  PLANNED: {
    TITLE: 'Planificadas',
    DESCRIPTION:
      'Son las actividades que fueron planificadas y se encuentran pendientes a realizarse.',
  },
  PENDING_VALIDATION: {
    TITLE: 'Pendientes de validación',
    DESCRIPTION:
      'Son las actividades que fueron realizas que cuentan con validaciones pendientes.',
  },
  COMPLETED: {
    TITLE: 'Finalizadas',
    DESCRIPTION: 'Son las actividades que fueron realizadas y validadas.',
  },
}
