import { Field, Formik } from 'formik'
import React, { useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { Button, List, Title } from 'react-native-paper'
import Input from '@components/common/Input'
import { LanguageContext } from '@contextState/language'
import CustomDatePicker from '@components/common/CustomDatePicker'
import UploadInput from '@components/common/UploadInput'
import { ALLOW_IMAGES_FORMAT } from '@constants/common'
import { CommonIcon } from '@modules/common/components'
import { ModalDefault } from '@common/components'

const ModalUploadFile = ({
  visible,
  setVisible,
  handleFileSubmit,
  handleFile,
  selectedFile,
}) => {
  const { t } = useContext(LanguageContext)
  return (
    <ModalDefault isModalVisible={visible} closeModal={() => setVisible(false)}>
      <Title style={styles.modalTitle}>
        {t(
          'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.ADD_DOCUMENTATION'
        )}
      </Title>
      <Formik
        initialValues={{ name: '', description: '', date: new Date() }}
        onSubmit={handleFileSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <Field
              component={Input}
              name='name'
              inputProps={{
                placeholder: t(
                  'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.FIELD_NAME.PLACEHOLDER'
                ),
                label: t(
                  'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.FIELD_NAME.LABEL'
                ),
              }}
              validate={(value) =>
                !value &&
                t(
                  'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.FIELD_NAME.VALIDATE'
                )
              }
            />
            <Field
              component={Input}
              name='description'
              inputProps={{
                placeholder: t(
                  'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.FIELD_CONCEPT.VALIDATE'
                ),
                label: t(
                  'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.FIELD_CONCEPT.LABEL'
                ),
              }}
              validate={(value) =>
                !value &&
                t(
                  'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.FIELD_CONCEPT.VALIDATE'
                )
              }
            />
            <Field
              component={CustomDatePicker}
              name='date'
              placeholder={t(
                'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.FIELD_DATE.PLACEHOLDER'
              )}
              label={t(
                'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.FIELD_DATE.LABEL'
              )}
            />
            <UploadInput
              cancelOnPress={() => setVisible(false)}
              handleFile={handleFile}
              accept={`.doc,.docx,.pdf,${ALLOW_IMAGES_FORMAT}`}
            >
              <View style={styles.containerText}>
                <Text style={styles.textModal}>
                  {t(
                    'VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.SELECT_DOCUMENT'
                  )}
                </Text>
              </View>
            </UploadInput>
            {selectedFile && (
              <List.Item
                title={selectedFile.name}
                left={(props) => (
                  <CommonIcon {...props} name={'FILETYPE-LEGAL'} size={20} />
                )}
              />
            )}
            <View style={styles.actions}>
              <Button onPress={() => setVisible(false)}>
                {t('VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.CANCEL')}
              </Button>
              <Button
                disabled={!selectedFile}
                onPress={handleSubmit}
                labelStyle={{ color: 'white' }}
                mode='contained'
              >
                {t('VIEWS.COMPANY_DETAIL.COMPONENTS.MODAL_UPLOAD_FILE.ADD')}
              </Button>
            </View>
          </>
        )}
      </Formik>
    </ModalDefault>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    marginBottom: 8,
  },
  containerText: {
    borderRadius: 2,
    padding: 4,
    fontWeight: '400',
  },
  textModal: {
    color: 'green',
    textAlign: 'center',
    padding: 4,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
  },
})

export default ModalUploadFile
