export default {
  COUNTRY: 'Country',
  COMPANY_NAME: 'Company name',
  COMPANY_TYPE: 'Legal Identity',
  ADDRESS: 'Address',
  SPECIFIC_ADDRESS: 'Floor/Apartment/Office',
  ADVSER_RECOMENDATION:
    'You must add as collaborators an *administrator* *producer* and a *responsible* *advisor* to be able to finalize the creation of the company.',
  SAVE_COMPANY: 'Create company',
}
