export default {
  TEXT_1: 'APPLY',
  TEXT_2: 'Fields',
  TEXT_3: 'ha',
  TEXT_4:
    'The company has a limited surface capacity. Only %{hectareLimit} has. from %{surfaceTotal}ha will be applied..',
  TEXT_5:
    'This company already has %{lotsApplied} fields - %{surfaceApplied}ha. associated to this ecosystem.',
  TEXT_6: 'These fields have already been applied to this company',
  TEXT_7: 'superficie neta',
  TEXT_8: 'Select all',
  TEXT_9:
    'The company has reached its surface capacity limit. Only previously selected fields will be applied.',
  MODAL_CONFIRM_COMPANIES: {
    TEXT_1: 'Company confirmation',
    TEXT_2:
      'Once you have selected the company associated with the ecosystem, you will not be able to deselect it',
    TEXT_3: 'CANCEL',
    TEXT_4: 'ASSOCIATE',
  },
}
