import React, { useContext } from 'react'
import { View, FlatList, StyleSheet, Text } from 'react-native'
import { primary500, white } from '@styles/palette'
import ItemCompany from './components/ItemCompany'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import { LanguageContext } from '@contextState/language'
import { InputSearch } from '@common/components/inputs/InputSearch'
import useFetchCompanies from '@modules/companies/hooks/useFetchCompanies'
import { gray400 } from '@common/styles'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import { TypeButton } from '@common/constants/typesButton'

const CompanyList = () => {
  const { t } = useContext(LanguageContext)
  const {
    onChangeCompany,
    companySelected,
    loading,
    goCompanyAdd,
    isAdviser,
    isCompanyUcropit,
    isVerifierUcropit,
    data,
    inputRef,
    handleChange,
    nextPage,
    refreshPage,
    isLoading,
    handleSearch,
    searchValue,
    handleDebounceSearch,
    clearSearchCompany,
    isTrader,
  } = useFetchCompanies()

  const FooterCompany = (
    <View style={styles.contentButton}>
      <ButtonDefault
        typeButton={data.length ? TypeButton.PRIMARY : TypeButton.DISABLED}
        text={t('VIEWS.COMPANY_LIST.TEXT_1')}
        onPress={() => {
          if (data.length) onChangeCompany(companySelected)
        }}
        marginBottom={15}
      />
      {!isTrader && (
        <ButtonPrimary
          onPress={goCompanyAdd}
          text={
            isAdviser
              ? t('VIEWS.COMPANY_LIST.TEXT_2')
              : t('VIEWS.COMPANY_LIST.TEXT_3')
          }
          borderColor={primary500}
          disabled={(!isAdviser && isCompanyUcropit) || isVerifierUcropit}
        />
      )}
    </View>
  )

  return (
    <View style={styles.content}>
      <FlatList
        data={data}
        ListHeaderComponent={
          <InputSearch
            placeholder={t('VIEWS.COMPANY_LIST.SEARCH_COMPANY')}
            inputRef={inputRef}
            value={searchValue}
            onChangeText={handleSearch}
            onChange={handleDebounceSearch}
            handleClearSearch={clearSearchCompany}
          />
        }
        renderItem={({ item }) => (
          <ItemCompany
            name={item?.name}
            identifier={item?.identifier}
            isSelected={item._id === companySelected._id}
            onPress={() => {
              handleChange(item)
            }}
            searchText={searchValue}
          />
        )}
        keyExtractor={(item) => {
          return item._id
        }}
        contentContainerStyle={styles.contentContainerStyle}
        style={styles.flatStyle}
        ListFooterComponentStyle={styles.listFooterComponent}
        onEndReachedThreshold={0.1}
        onEndReached={nextPage}
        onRefresh={refreshPage}
        refreshing={isLoading || loading}
        ListEmptyComponent={
          <Text
            style={{ textAlign: 'center', fontWeight: '400', color: gray400 }}
          >
            {t('VIEWS.COMPANY_LIST.NOT_FOUND')}
          </Text>
        }
      />
      {FooterCompany}
    </View>
  )
}
const styles = StyleSheet.create({
  content: {
    backgroundColor: white,
    flex: 1,
  },
  contentButton: {
    padding: 15,
  },
  flatStyle: {
    height: '100%',
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
  listFooterComponent: {
    flex: 1,
    justifyContent: 'flex-end',
    height: '100%',
  },
})

export default CompanyList
