export default {
  TEXT_1: 'Confirmar Superficie Realizada',
  TEXT_2: 'Superficie lote',
  TEXT_3: 'Superficie planificada',
  TEXT_4: 'Superficie',
  TEXT_5: 'realizada',
  TEXT_6: 'ha',
  TEXT_7: 'Superficie total realizada',
  TEXT_8: 'Confirmar Superficie',
  TEXT_9: 'planificada',
  TEXT_10: 'Superficie total planificada',
  ERROR_SURFACE:
    'La superficie realizada deber ser igual o menor a la del lote.',
}
