import React from 'react'
import { View, StyleSheet, StatusBar } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { carbon100, carbon300, white } from '@styles/palette'

export const LayoutWithOutResponsive = ({ children }) => {
  return (
    <SafeAreaView style={styles.container}>
      <StatusBar backgroundColor={white} barStyle='dark-content' />
      <View style={[styles.content]}>{children}</View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: carbon100,
  },
  content: {
    flex: 1,
    width: '100%',
    borderLeftColor: carbon300,
    borderLeftWidth: 1,
    borderRightColor: carbon300,
    borderRightWidth: 1,
  },
  fixedWidth: {
    width: 960,
  },
})
