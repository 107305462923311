import moment from 'moment'

export const formatDateLabel = ({ t, date }) => {
  const dateObject = new Date(date)

  const day = moment(dateObject).format('DD')
  const month = t('CALENDAR').MONTHS_SHORT[moment(dateObject).format('M')]
  const year = moment(dateObject).format('YYYY')

  const dateText = `${day}/${month}/${year}`

  return dateText
}
