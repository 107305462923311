import { enumUnitMeasureSystem } from './enumUnitMeasureSystem'

const METRIC_UNITS = {
  UNIT_1: 'mt',
  UNIT_2: 'mm',
}

const IMPERIAL_UNITS = {
  es: {
    UNIT_1: 'pie',
    UNIT_2: 'pulgada',
  },
  en: {
    UNIT_1: 'feet',
    UNIT_2: 'inch',
  },
  pt: {
    UNIT_1: 'polegada',
    UNIT_2: 'pés',
  },
}

type Language = 'es' | 'en' | 'pt'

export const getUnitsLongitude = (
  unitMeasureSystem: string,
  language: Language
) => {
  return unitMeasureSystem === enumUnitMeasureSystem.IMPERIAL
    ? IMPERIAL_UNITS[language]
    : METRIC_UNITS
}
