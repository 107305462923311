export default {
  TEXT_1: 'Confirme a área realizada',
  TEXT_2: 'Superfície do lote',
  TEXT_3: 'Área planejada',
  TEXT_4: 'Superficie',
  TEXT_5: 'realizada',
  TEXT_6: 'ha',
  TEXT_7: 'Área total realizada',
  TEXT_8: 'Confirmar Superfície',
  TEXT_9: 'planejada',
  TEXT_10: 'Área total planejada',
  ERROR_SURFACE: 'A área realizada deve ser igual ou menor que a área do lote.',
}
