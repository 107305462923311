import { FC, ReactElement, useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import { LanguageContext } from '@contextState/language'
import Header from 'src/components/common/Header'
import RouterNames from '@constants/routerNames'
import CompanyList from '@modules/companies/screens/CompanyList/v1'
import CompanyDetail from '@modules/companies/screens/CompanyDetail/v1'
import CollaboratorListCompany from '@modules/collaborators/screens/CollaboratorListCompany/v1'
import AddCollaborator from '@modules/collaborators/screens/AddCollaborator/v1'
import EditCompany from '@modules/companies/screens/EditCompany/v1'
import AddCompanyScreen from '@modules/companies/screens/AddCompanyScreen/v1'
import CollaboratorsListNewCompany from '@modules/collaborators/screens/CollaboratorsListNewCompany/v1'
import CompanyServiceIntegrationsSelectorScreen from 'src/screens/CompanyServiceIntegrationsSelectorScreen'
import CompanyIntegrationAppListScreen from 'src/screens/CompanyIntegrationAppListScreen'
import CompanyServiceIntegrationAccountScreen from 'src/screens/CompanyServiceIntegrationsAccountScreen'
import CompanyIntegrationAgreementScreen from 'src/screens/CompanyIntegrationAgreementScreen'
import CompanyIntegrationLinkScreen from 'src/screens/CompanyIntegrationLinkScreen'
import CompanyServiceIntegrationAddScreen from 'src/screens/CompanyServiceIntegrationAddScreen'
import CompanyUnlinkServiceIntegrationScreen from 'src/screens/CompanyUnlinkServiceIntegrationScreen'
import { useResponsive } from '@common/hooks'
import { AppIntegratorDetailsScreen } from '@modules/AppIntegrator/screens/AppIntegratorDetails'
import WebViewIntegrator from '@modules/webView/screens/WebViewIntegrator'

const Stack = createStackNavigator()

export const CompanyStack: FC<any> = (props): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const { isScreenDesktop } = useResponsive()

  const stacks = [
    {
      name: RouterNames.COMPANY_LIST,
      component: CompanyList,
      initialParams: {},
      options: {
        header: (props: any) => {
          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_66')}
              drawer={!isScreenDesktop}
            />
          )
        },
      },
    },
    {
      name: RouterNames.COMPANY_LIST_UCROPIT,
      component: CompanyList,
      initialParams: {
        isAdviser: true,
      },
      options: {
        header: (props: any) => {
          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_65')}
              drawer={!isScreenDesktop}
            />
          )
        },
      },
    },
    {
      name: RouterNames.COMPANY_ADD,
      component: AddCompanyScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_9'),
        header: (props: any) => {
          const { isAdviser } =
            props?.route?.params ?? props.scene?.route?.params

          const title = isAdviser
            ? t('NAVIGATION.STACK_NAVIGATOR.ADVISE_COMPANY')
            : t('NAVIGATION.STACK_NAVIGATOR.MANAGE_COMPANY')

          return <Header {...props} title={title} goBack />
        },
      },
    },
    {
      name: RouterNames.COMPANY_ADD_COLLABORATOR_LIST,
      component: CollaboratorsListNewCompany,
      initialParams: {},
      options: {
        header: (props: any) => {
          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.COLLABORATORS')}
              goBack
            />
          )
        },
      },
    },
    {
      name: RouterNames.COMPANY_DETAIL,
      component: CompanyDetail,
      options: {
        header: (props: any) => {
          return (
            <Header
              {...props}
              title={t('VIEWS.COMPANY_DETAIL.TEXT_10')}
              drawer={!isScreenDesktop}
            />
          )
        },
      },
    },
    {
      name: RouterNames.COMPANY_COLLABORATOR_LIST,
      component: CollaboratorListCompany,
      initialParams: {},
      options: {
        header: (props: any) => {
          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.COLLABORATORS')}
              goBack
            />
          )
        },
      },
    },
    {
      name: RouterNames.COMPANY_COLLABORATOR_ADD,
      component: AddCollaborator,
      initialParams: {},
      options: ({ route }: any) => ({
        title: route.params.isNewCompany
          ? t('NAVIGATION.STACK_NAVIGATOR.ADD_PRODUCER_ADMIN')
          : t('NAVIGATION.STACK_NAVIGATOR.ADD_COLLABORATORS'),

        header: (props: any) => (
          <Header
            {...props}
            title={
              route.params.isNewCompany
                ? t('NAVIGATION.STACK_NAVIGATOR.ADD_PRODUCER_ADMIN')
                : t('NAVIGATION.STACK_NAVIGATOR.ADD_COLLABORATORS')
            }
            goBack
          />
        ),
      }),
    },
    {
      name: RouterNames.COMPANY_COLLABORATOR_ADD_ADVISER,
      component: AddCollaborator,
      initialParams: {},
      options: {
        header: (props: any) => {
          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.ADD_ADVISER_RESPONSIBLE')}
              goBack
            />
          )
        },
      },
    },
    {
      name: RouterNames.COMPANY_EDIT,
      component: EditCompany,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.EDIT_COMPANY'),
        header: (props: any) => {
          return (
            <Header
              {...props}
              title={t('NAVIGATION.STACK_NAVIGATOR.EDIT_COMPANY')}
              goBack
            />
          )
        },
      },
    },
    {
      name: RouterNames.COMPANY_SERVICE_INTEGRATIONS,
      component: CompanyServiceIntegrationsSelectorScreen,
      initialParams: {},
      options: {
        title: `${t('NAVIGATION.STACK_NAVIGATOR.TEXT_14')}`,
        header: (props: any) => (
          <Header
            {...props}
            title={`${t('NAVIGATION.STACK_NAVIGATOR.TEXT_14')}`}
            drawer={!isScreenDesktop}
          />
        ),
      },
    },
    {
      name: `${RouterNames.COMPANY_SERVICE_INTEGRATIONS}S2`,
      component: CompanyServiceIntegrationsSelectorScreen,
      initialParams: { isS2: true },
      options: {
        title: `${t('NAVIGATION').STACK_NAVIGATOR.TEXT_14} - S2`,

        header: (props: any) => (
          <Header
            {...props}
            title={`${t('NAVIGATION').STACK_NAVIGATOR.TEXT_14}  - S2`}
            drawer={!isScreenDesktop}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_SERVICE_INTEGRATIONS_LIST,
      component: CompanyIntegrationAppListScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_14'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_14')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_SERVICE_INTEGRATIONS_DETAIL,
      component: CompanyServiceIntegrationAccountScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_15'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_15')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_SERVICE_INTEGRATIONS_ADD,
      component: CompanyIntegrationAgreementScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_13'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_13')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_SERVICE_INTEGRATIONS_LINK,
      component: CompanyIntegrationLinkScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_13'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_13')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_SERVICE_INTEGRATIONS_LOGIN,
      component: CompanyServiceIntegrationAddScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_17'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_17')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_SERVICE_INTEGRATIONS_UNLINK,
      component: CompanyUnlinkServiceIntegrationScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_13'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_13')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_APP_INTEGRATION_DETAIL,
      component: AppIntegratorDetailsScreen,
      initialParams: {},
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.TEXT_14'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.TEXT_14')}
            goBack
          />
        ),
      },
    },
    {
      name: RouterNames.COMPANY_APP_INTEGRATION_WEB_VIEW,
      component: WebViewIntegrator,
      initialParams: {},
      options: {
        title: '',
        header: (props: any) => (
          <Header {...props} title={props?.route?.params?.title} goBack />
        ),
      },
    },
  ]

  const initialRouteName =
    props.route.params.initialRouteName || RouterNames.COMPANY_LIST

  return (
    <Stack.Navigator initialRouteName={initialRouteName}>
      {stacks.map((stack, key) => (
        <Stack.Screen {...stack} key={`COMPANY_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
