import { FC, useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { CommonIcon } from '@common/components'
import { SIZES } from '@common/components/icons/interfaces'
import { LanguageContext } from '@contextState/language'
import { formatDateMoment } from '@utils/date'
import { carbon400, primary500 } from '@styles/palette'
import { EAppIntegrationStatus } from '@common/enum/appIntegrationStatus'
import { EIconName } from '@common/enum/iconName'
import React from 'react'

export const TimeLineDetail: FC<any> = ({ status }) => {
  const { t, i18n } = useContext(LanguageContext)

  const { key } = status

  let iconName = ''
  let color = ''
  let description = undefined
  let title = undefined

  switch (key) {
    case EAppIntegrationStatus.CONNECTED:
      iconName = EIconName.CHECK
      title = t('COMPONENTS.APP_INTEGRATOR.CONNECTED_TITLE')
      color = primary500
      break
    case EAppIntegrationStatus.SYNCHRONIZING:
      description = t('COMPONENTS.APP_INTEGRATOR.SYNCHRONIZATION_DESCRIPTION')
      iconName = EIconName.SYNCHRONIZING
      title = t('COMPONENTS.APP_INTEGRATOR.OBTAINING_TITLE')
      color = primary500
      break
    case EAppIntegrationStatus.SYNCHRONIZED:
      iconName = 'CHECK-CIRCLE'
      title = t('COMPONENTS.APP_INTEGRATOR.INFORMATION_OBTAINED_TITLE')
      /* prettier-ignore*/
      description = `${t('COMPONENTS.APP_INTEGRATOR.LAST_UPDATE')} ${formatDateMoment(new Date(), 'DD/MMM/YYYY', i18n.locale, true)}`
      color = primary500
      break
    case EAppIntegrationStatus.PENDING_VALIDATION:
      iconName = 'ALERT-CIRCLE'
      title = t('COMPONENTS.APP_INTEGRATOR.PENDING_VALIDATION')
      description = t(
        'COMPONENTS.APP_INTEGRATOR.PENDING_VALIDATION_DESCRIPTION'
      )
      color = '#E99537'
      break
    case EAppIntegrationStatus.ERROR:
      iconName = EIconName.ERROR
      color = 'red'
      break
  }

  return (
    <>
      <View>
        {((key === EAppIntegrationStatus.SYNCHRONIZING ||
          key === EAppIntegrationStatus.SYNCHRONIZED) && (
          <View style={styles.containerLine}>
            <View style={styles.verticalLine} />
          </View>
        )) ||
          (key === EAppIntegrationStatus.PENDING_VALIDATION && (
            <View style={styles.containerLine}>
              <View style={styles.verticalLinePending} />
            </View>
          ))}
        <View style={styles.logoContainer}>
          <CommonIcon name={iconName} size={SIZES.MEDIUM} color={color} />
          <Text
            style={{
              ...styles.title,
              color: color,
            }}
          >
            {title}
          </Text>
        </View>
        {description && (
          <View style={styles.containerDescription}>
            <Text style={styles.description}>{description}</Text>
          </View>
        )}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  logoContainer: {
    flexDirection: 'row',
  },
  title: {
    paddingLeft: 10,
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24,
  },
  containerDescription: {
    width: 310,
    height: 2,
  },
  description: {
    paddingLeft: 35,
    fontFamily: 'Inter',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,
    color: carbon400,
  },
  containerLine: {
    paddingLeft: 10,
  },
  verticalLine: {
    width: 2,
    height: 8,
    backgroundColor: primary500,
  },
  verticalLinePending: {
    width: 2,
    height: 26,
    backgroundColor: '#E99537',
  },
})
