const icons = {
  ACT_MONITORING: 'eye',
  ACT_HARVEST: 'tractor-variant',
  ACT_APPLICATION: 'test-tube',
  ACT_SOWING: 'seed',
  ACT_FERTILIZATION: 'barley',
  ACT_TILLAGE: 'shovel',
}
const ALLOW_IMAGES_FORMAT = '.png,.jpg,.jpeg'
export { icons, ALLOW_IMAGES_FORMAT }
