export default {
  TEXT_1: 'Licencia',
  TEXT_2: 'He leído la licencia y acepto participar en este ecosistema',
  TEXT_3: 'Firmar',
  TEXT_4: 'Visibilidad de los datos',
  TEXT_5:
    'Al firmar esta licencia la empresa emisora comenzará a tener acceso a los datos específicos del cultivo que la licencia requiere.',
  TEXT_6: 'RECHAZAR',
  TEXT_7: 'ACEPTAR',
  TEXT_8: '¡Licencia Firmada!',
  TEXT_9: 'El n° de licencia es #%{numberLicense}',
  TEXT_10: 'Elige los lotes que quieres asociar a este ecosistema',
  TEXT_11: 'DEFINIR LOTES PARA LA LICENCIA',
  TEXT_12: 'VOLVER A CULTIVOS',
}
