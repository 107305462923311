import React from 'react'
import { StyleSheet } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import {
  stateLicenseGreen,
  stateLicenseGreenLight,
  stateLicenseYellow,
  white,
  blackHighEmphasis,
  blackMediumEmphasis,
} from '@styles/palette'

const styles = StyleSheet.create({
  licenseImageHeaderIcon: {
    marginLeft: 'auto',
    marginRight: 15,
  },
  textWhite: {
    fontSize: 16,
    color: white,
    fontWeight: '500',
  },
  textGray: {
    fontSize: 10,
    fontWeight: '700',
    color: blackMediumEmphasis,
    minWidth: 112,
  },
})

const licenseStates = {
  APPLIED: {
    key: 'APPLIED',
    name: (t) => t('CONSTANTS.LICENSE_STATES.APPLIED'),
    color: white,
    background: stateLicenseGreen,
    styleText: styles.textWhite,
    icon: (
      <CommonIcon
        name='CHECK'
        size={24}
        color={white}
        style={styles.licenseImageHeaderIcon}
      />
    ),
  },
  SIGNED: {
    key: 'SIGNED',
    name: (t) => t('CONSTANTS.LICENSE_STATES.SIGNED'),
    color: white,
    background: stateLicenseGreen,
    styleText: styles.textWhite,
    icon: (
      <CommonIcon
        name='SIGN'
        size={24}
        color={white}
        style={styles.licenseImageHeaderIcon}
      />
    ),
  },
  SHORT_HAS: {
    key: 'SHORT_HAS',
    name: (t) => t('CONSTANTS.LICENSE_STATES.SHORT_HAS'),
    color: blackHighEmphasis,
    background: stateLicenseYellow,
    styleText: styles.textGray,
    icon: (
      <CommonIcon
        name='CLOCK'
        size={24}
        color={blackHighEmphasis}
        style={styles.licenseImageHeaderIcon}
      />
    ),
  },
  SHORT_TIME: {
    key: 'SHORT_TIME',
    name: (t) => t('CONSTANTS.LICENSE_STATES.SHORT_TIME'),
    color: blackHighEmphasis,
    background: stateLicenseYellow,
    styleText: styles.textGray,
    icon: (
      <CommonIcon
        name='CLOCK'
        size={24}
        color={blackHighEmphasis}
        style={styles.licenseImageHeaderIcon}
      />
    ),
  },
  NEW: {
    key: 'NEW',
    name: (t) => t('CONSTANTS.LICENSE_STATES.NEW'),
    color: white,
    background: stateLicenseGreenLight,
    styleText: styles.textWhite,
  },
  NEW_COMPANY: {
    key: 'NEW_COMPANY',
    name: (t) => t('CONSTANTS.LICENSE_STATES.APPLIED'),
    color: white,
    background: stateLicenseGreenLight,
    styleText: styles.textWhite,
    icon: (
      <CommonIcon
        name='CHECK'
        size={24}
        color={white}
        style={styles.licenseImageHeaderIcon}
      />
    ),
  },
  SELECT_COMPANY: {
    key: 'SELECT_COMPANY',
    name: (t) => t('CONSTANTS.LICENSE_STATES.SIGNED'),
    color: white,
    background: stateLicenseGreenLight,
    styleText: styles.textWhite,
    icon: (
      <CommonIcon
        name='SIGN'
        size={24}
        color={white}
        style={styles.licenseImageHeaderIcon}
      />
    ),
  },
}

export default licenseStates
