import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  isConnectionReady: false,
  isConnected: true,
  lowConnection: false,
}

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    /**
     * @param {object} state
     * @property {boolean} state.isConnected
     * @property {boolean} state.lowConnection
     *
     * @param {object} action
     * @property {object} action.payload
     * @property {boolean} action.payload.isConnected
     * @property {boolean} action.payload.lowConnection
     */
    setConnectionValues: (state, action: PayloadAction<any>) => {
      state = {
        ...action.payload,
      }

      return state
    },
    /**
     * @param {object} state
     * @property {boolean} state.isConnected
     * @property {boolean} state.lowConnection
     *
     * @returns {object} Initial state
     */
    resetConnectionValues: (state) => {
      state = initialState

      return state
    },
  },
})

export const { setConnectionValues, resetConnectionValues } =
  connectionSlice.actions

export const { reducer: connectionReducer } = connectionSlice
