import FIELD_DETAILS from '../../components/FieldDetails/translate/pt'
import REMOVE_FIELD_BUTTON from '../../components/RemoveFieldButton/translate/pt'
import DISASSOCIATE_FIELD_MODAL from '../../components/DisassociateFieldModal/translate/pt'

export default {
  COMPONENTS: {
    FIELD_DETAILS,
    REMOVE_FIELD_BUTTON,
    DISASSOCIATE_FIELD_MODAL,
  },
}
