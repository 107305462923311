import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { round } from 'lodash'

import { CommonIcon } from '@modules/common/components'
import InputCheckbox from '@components/inputs/InputCheckbox'
import CardDefault from '@components/cards/CardDefault'
import { blackMediumEmphasis } from '@styles/palette'
import { gray1, teal600, blackDisabled } from '@styles/palette'
import TextNewCompany from './TextNewCompany'
import { isAppliedLots } from '../utils'
import { statusSubLicenses } from '@utils/constants'

const InputCompany = ({
  checked,
  textLots,
  subLicense,
  selectAndUnselectSubLicense,
  t,
  has,
  lotsSelected,
  areaUnit,
}) => {
  const {
    totalSurfaceApplied,
    isNewSubLicense,
    company,
    lotsApplied,
    diffHectare,
    isApplied,
    hectareLimitIdentifier,
  } = subLicense
  const status = isAppliedLots(lotsSelected, lotsApplied)
  const isDisabled =
    status === statusSubLicenses.DISABLED || (isApplied && diffHectare === 0)
      ? styles.colorDisabled
      : {}
  const disabled =
    status === statusSubLicenses.DISABLED || (isApplied && diffHectare === 0)
  const totalSurfaceAvailable = round(parseFloat(has) - totalSurfaceApplied, 2)

  return (
    <View style={styles.content}>
      <CardDefault>
        <View style={styles.content}>
          <View style={styles.containerRow}>
            <InputCheckbox
              checked={checked}
              disabled={disabled}
              onPress={() => selectAndUnselectSubLicense(subLicense._id)}
            />
            <View style={styles.styleText}>
              <Text style={[styles.textAll, isDisabled]}>{company.name}</Text>
              <Text style={[styles.styleSubTitle, isDisabled]}>{textLots}</Text>
              {isNewSubLicense && <TextNewCompany />}
            </View>
          </View>
          {isApplied && diffHectare === 0 && (
            <View style={[styles.extraInfo, styles.containerRow]}>
              <CommonIcon name='INFO' size={20} color={teal600} />
              <View style={{ paddingLeft: 15, paddingRight: 10 }}>
                <Text style={[styles.textExtraInfo, isDisabled]}>
                  {t('VIEWS.SUBLICENSES_SELECT_COMPANIES.TEXT_9')}
                </Text>
              </View>
            </View>
          )}
          {((diffHectare < totalSurfaceAvailable && diffHectare > 0) ||
            (hectareLimitIdentifier < totalSurfaceAvailable &&
              hectareLimitIdentifier > 0 &&
              diffHectare > 0)) && (
            <View style={[styles.extraInfo, styles.containerRow]}>
              <CommonIcon name='INFO' size={20} color={teal600} />
              <View style={{ paddingLeft: 15, paddingRight: 10 }}>
                <Text style={[styles.textExtraInfo, isDisabled]}>
                  {t('VIEWS.SUBLICENSES_SELECT_COMPANIES.TEXT_4', {
                    hectareLimit:
                      hectareLimitIdentifier > 0
                        ? round(hectareLimitIdentifier, 2)
                        : round(diffHectare, 2),
                    surfaceTotal: totalSurfaceAvailable,
                    areaUnit,
                  })}
                </Text>
              </View>
            </View>
          )}
          {status === statusSubLicenses.IS_APPLIED && (
            <View style={[styles.extraInfo, styles.containerRow]}>
              <Text style={[styles.textExtraInfo, isDisabled]}>
                {t('VIEWS.SUBLICENSES_SELECT_COMPANIES.TEXT_5', {
                  lotsApplied: lotsApplied.length,
                  surfaceApplied: round(totalSurfaceApplied, 2),
                  areaUnit,
                })}
              </Text>
            </View>
          )}

          {status === statusSubLicenses.DISABLED && (
            <View style={[styles.extraInfo, styles.containerRow]}>
              <Text style={[styles.textExtraInfo, isDisabled]}>
                {t('VIEWS.SUBLICENSES_SELECT_COMPANIES.TEXT_6')}
              </Text>
            </View>
          )}
        </View>
      </CardDefault>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 10,
  },
  styleText: {
    marginLeft: 5,
  },
  containerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },
  textAll: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#000',
  },
  styleSubTitle: {
    fontSize: 12,
    color: blackMediumEmphasis,
  },
  extraInfo: {
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: gray1,
  },
  textExtraInfo: {
    fontSize: 12,
    color: blackMediumEmphasis,
    fontStyle: 'italic',
  },
  colorDisabled: {
    color: blackDisabled,
  },
})

export default InputCompany
