export default {
  TEXT_1: 'Successfully updated',
  TEXT_2: 'Internal error',
  TEXT_3: 'Add documentation',
  FIELD_1: {
    LABEL: 'Name',
    PLACEHOLDER: 'Name',
    VALIDATE: 'Required field',
  },
  FIELD_2: {
    LABEL: 'Concept',
    PLACEHOLDER: 'Concept',
    VALIDATE: 'Required field',
  },
  FIELD_3: {
    LABEL: 'Date of execution',
    PLACEHOLDER: 'Date of execution',
  },
  TEXT_4: 'Select document',
  TEXT_5: 'Cancel',
  TEXT_6: 'Add',
  FIELD_4: {
    LABEL: 'Country',
  },
  TEXT_7: 'Tax ID:',
  FIELD_5: {
    LABEL: 'Tax ID',
  },
  FIELD_6: {
    LABEL: 'Company name',
    PLACEHOLDER: 'Company name',
    VALIDATE: 'Required field',
  },
  FIELD_7: {
    LABEL: 'Legal Identity',
    OPTION_1: 'Physical',
    OPTION_2: 'Legal',
    VALIDATE: 'Required field',
  },
  FIELD_8: {
    LABEL: 'Address',
    PLACEHOLDER: 'Address',
    VALIDATE: 'Required field',
  },
  FIELD_9: {
    LABEL: 'Floor / Apartment / Office',
    PLACEHOLDER: 'Floor / Apartment / Office',
  },
  TEXT_8: 'Add documentation',
  TEXT_9: 'Save',
}
