export default {
  TEXT_1: 'lotes seleccionados',
  TEXT_2: 'Campo requerido',
  TEXT_3: 'Seleccionar Lotes',
  TEXT_4: 'Superficie %{areaUnit}',
  TEXT_5: 'Desde',
  TEXT_6: 'Hasta',
  TEXT_7: 'Unidad de rinde',
  TEXT_8: 'Estimación de rinde',
  TEXT_9: 'HASTA no puede ser fecha anterior a DESDE',
  TEXT_10: 'Tipo de',
  TEXT_11: 'Consideraciones',
  TEXT_12:
    'Agrega las consideraciones necesarias que deben incluirse en la orden de trabajo.',
  TEXT_13: 'Puedes ingresar hasta %{considerationsLength} caracteres.',
}
