export default {
  TEXT_1: 'Do you want to exit ucrop.it?',
  TEXT_2:
    'If you carry out this action the synchronization queue will be deleted',
  TEXT_3: 'No company assigned',
  TEXT_4: 'New company',
  TEXT_5: 'Edit profile',
  TEXT_6: 'Company',
  TEXT_7: 'Integrations',
  TEXT_8: 'Settings',
  TEXT_9: 'Sign out',
  TEXT_10: 'Version %{version} Build %{build}',
  TEXT_11: 'Profile',
  TEXT_12: 'Language',
  TEXT_13: 'Company data',
  TEXT_14: 'Sync offline',
  TEXT_15: 'Connected Apps',
  TEXT_16: 'Collaborate with company',
  SETTINGS: 'Settings',
  PRODUCTION_BOARD: 'Farmer dashboard',
  ADD_COMPANY: 'Add company',
  YOUR_COMPANY: 'YOUR COMPANY',
  ADVISED_COMPANY: 'ADVISED COMPANY',
  INTEGRATIONS: 'Integrations',
  TAX_ID: 'TAX ID',
  TRADERS: 'Traders',
  QR: 'Traceability',
  QR_TRADER_ADMIN: 'Product traceability',
  POLYGONS: 'Polygons',
  INFORMATION: 'Information',
  BULK_UPLOAD: 'Land use',
  PRODUCTIVE_UNITS: 'Productive units',
  EXPORT_ACTIVITIES: 'Export activities',
  SYNCHRONIZE_ACTIVITIES: 'Synchronize activities',
  CROP_STORY: 'Crop stories',
  PROGRAM_TRADER_ADMIN: 'Programs',
}
