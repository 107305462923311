import React, { useContext } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { ThemeProvider, withStyles } from '@material-ui/core'
import { createTheme } from '@material-ui/core'
import { View } from 'react-native'
import { LanguageContext } from '@contextState/language'

const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#4CAF50',
      contrastText: '#fff',
    },
  },
})

const styles = () => ({
  inputRoot: {
    backgroundColor: 'white',
  },
  notchedOutline: {},
})

const WebDatePicker = ({
  classes,
  field,
  form,
  placeholder,
  label,
  style,
  disablePast,
  readOnly,
  ...other
}) => {
  const { i18n } = useContext(LanguageContext)
  const currentError = form.errors[field.name]
  const currentTouched = form.touched[field.name]

  return (
    <View style={[{ paddingBottom: 16, flex: 1 }, style]}>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.locale}>
          <DatePicker
            placeholder={placeholder}
            label={label}
            disablePast={disablePast}
            name={field.name}
            value={field.value}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
              },
            }}
            format='DD/MM/YYYY'
            helperText={currentError && currentTouched ? currentError : null}
            inputVariant='outlined'
            error={Boolean(currentError && currentTouched)}
            fullWidth
            onChange={(date) => form.setFieldValue(field.name, date, false)}
            disabled={readOnly}
            {...other}
            onClose={() => form.setFieldTouched(field.name)}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </View>
  )
}

WebDatePicker.defaultProps = {
  disablePast: true,
}

export default withStyles(styles)(WebDatePicker)
