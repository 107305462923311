import React, { useContext } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import { PropTypes } from 'prop-types'

import { primary500, white } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import ButtonPrimary from '@components/buttons/ButtonPrimary'

export const ChildrenModalSuccess = ({
  onPress,
  title,
  textSuccess,
  textButton,
  titleStyle = {},
  textSuccessStyle = {},
  testID = undefined,
}) => {
  const { t } = useContext(LanguageContext)
  return (
    <View style={styles.container}>
      <Text style={[styles.successText, styles.successTitleText, titleStyle]}>
        {title ?? t('VIEWS.FARM_LOTS_ADD.COMPONENTS.MODAL_SUCCESS.TEXT_1')}
      </Text>
      <Text
        style={[styles.successText, styles.successSubText, textSuccessStyle]}
      >
        {textSuccess}
      </Text>
      <View style={styles.footer}>
        <ButtonPrimary
          text={textButton}
          backgroundColor={white}
          colorText={primary500}
          borderColor={white}
          borderRadius={8}
          fontSize={14}
          fontWeight={'700'}
          onPress={onPress}
          testID={testID}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  successTitleText: {
    fontSize: 34,
    fontWeight: '600',
    lineHeight: 40,
    letterSpacing: -0.5,
    marginBottom: 23,
    marginTop: 67,
  },
  successText: {
    color: white,
    fontStyle: 'normal',
    fontWeight: '500',
    textAlign: 'center',
  },
  successSubText: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 21,
    letterSpacing: -0.5,
  },
  button: {
    textTransform: 'uppercase',
    backgroundColor: white,
    marginVertical: 10,
  },
  textButton: {
    fontSize: 14,
    fontWeight: '700',
    color: primary500,
    lineHeight: 16,
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 36,
    padding: 32,
    paddingBottom: 5,
  },
})

ChildrenModalSuccess.propTypes = {
  title: PropTypes.string,
  textSuccess: PropTypes.string.isRequired,
  textButton: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  titleStyle: PropTypes.object,
  textSuccessStyle: PropTypes.object,
}
