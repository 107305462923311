import HeaderAccordionPlanning from '../components/HeaderAccordionPlanning/translate/pt'
import CardAchievement from '../components/CardAchievement/translate/pt'
import HeaderInfoAchievements from '../components/HeaderInfoAchievements/translate/pt'
import WORK_ORDER from '../components/WorkOrder/translate/pt'

export default {
  TEXT_1: 'CONCLUIR A ATIVIDADE',
  TEXT_2: 'VOLTAR ÀS ATIVIDADES',
  COMMON: {
    TEXT_1: 'Campo',
    TEXT_2: 'Campos',
    TEXT_3: 'Lote',
    TEXT_4: 'Lotes',
    TEXT_5: 'Insumos',
    TEXT_6: 'Insumo',
  },
  COMPONENTS: {
    HEADER_ACCORDION_PLANNING: HeaderAccordionPlanning,
    CARD_ACHIEVEMENT: CardAchievement,
    HEADER_INFO_ACHIEVEMENT: HeaderInfoAchievements,
    WORK_ORDER,
  },
  CONFIRM_FINISHED: {
    TEXT_1: 'Finaliza %{activity}',
    TEXT_2:
      'A superficie que não foram feita podem ser feita em uma nova atividade.',
    TEXT_3: 'FINALIZA',
    TEXT_4: 'CANCELAR',
  },
}
