import { View, Text, StyleSheet } from 'react-native'
import React from 'react'

import AccordionItem from '@components/common/v2/AccordionItem'
import { AccordionItemSelectionProps } from './AccordioItemsSelection.props'

export const AccordionItemSelection = ({
  item,
  index,
}: AccordionItemSelectionProps) => {
  return (
    <View>
      <AccordionItem
        ContentHeader={item.ElementRender}
        otherParams={{ ...item, index }}
        openByDefault={true}
      >
        {item.items &&
          item.items.map((subItem: any, indexField: number) => (
            <subItem.ElementRender
              item={{ ...subItem, disabledUp: item.disabled }}
              key={`lot-${subItem._id}`}
              onPress={() => subItem.onClickElement(subItem, index, indexField)}
            />
          ))}
      </AccordionItem>
    </View>
  )
}

const styles = StyleSheet.create({
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
