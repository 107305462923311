export default {
  TEXT_1: 'Novo cultivo',
  TEXT_2: 'Da empresa física ou jurídica a cargo da produção.',
  TEXT_3: 'Seguinte',
  TEXT_4:
    'O CNPJ já existe na plataforma, solicite permissões ao administrador da empresa',
  TEXT_5: 'A data de semeadura deve ser maior ou igual à data atual',
  TEXT_6: 'A data de semeadura não deve ser anterior à data de colheita',
  TEXT_7: 'A data da colheita deve ser maior que a data da semeadura',
  TEXT_8: 'Campo Requerido',
  TEXT_9: 'Você poderá editar o nome do cultivo mais tarde.',
  FIELD_1: {
    NAME: 'Tipo de cultivo',
    OPTION_1: 'Soja',
    OPTION_2: 'Girassol',
    OPTION_3: 'Milho',
    OPTION_4: 'Algodão',
    OPTION_5: 'Trigo',
    OPTION_6: 'Carinata',
  },
  FIELD_2: {
    NAME: 'Semeadura estimativa',
  },
  FIELD_3: {
    NAME: 'Colheita estimativa',
  },
  FIELD_4: {
    NAME: 'Unidade de rendimento',
    OPTION_1: 'Quilogramas/Ha',
    OPTION_2: 'Toneladas/Ha',
    OPTION_3: 'Sacos/Ha',
    OPTION_4: 'Fardos/Ha',
    OPTION_5: 'Quintales(1qq = 100kgs)/Ha',
  },
  FIELD_5: {
    NAME: 'Rendimento estimativo',
  },
  FIELD_6: {
    NAME: 'CNPJ',
  },
  FIELD_7: {
    NAME: 'Nome do cultivo',
  },
  FIELD_8: {
    NAME: 'País',
  },
  MODAL_1: {
    TEXT_1: '¿Deseja restabelecer o rascunho?',
    TEXT_2:
      'Tem um rascunho incompleto, pode iniciar um novo corte ou continuar o anterior',
    TEXT_3: 'Restabelecer',
    TEXT_4: 'iniciar novo',
  },
  STAGE_FIELD: {
    NAME: 'Fase da cultivo',
    FIRST: 'Safra',
    SECOND: 'Safrinha',
  },
  PLACEHOLDER_SEARCH: 'Pesquisar tipo de cultura',
}
