import React, { useContext } from 'react'
import { StyleSheet, Platform } from 'react-native'

import ActionSheet from 'react-native-actions-sheet'

import { LanguageContext } from '@contextState/language'
import ContentActionSheet from '../ContentActionSheet'
import { ModalDefault } from '@common/components'

const ActionSheetComponent = ({
  actionSheetRef,
  handleClose,
  isOpenModal,
  fetchUpdateCollaboratorRequest,
  collaboratorSelected,
  userTypeOptions,
}) => {
  const { t, T } = useContext(LanguageContext)

  return (
    <>
      {Platform.OS !== 'web' ? (
        <ActionSheet
          ref={actionSheetRef}
          containerStyle={[
            styles.actionStyles,
            !collaboratorSelected?.role && styles.heightWithoutRole,
          ]}
          onClose={handleClose}
        >
          <ContentActionSheet
            t={t}
            T={T}
            handleClose={handleClose}
            collaboratorSelected={collaboratorSelected}
            fetchUpdateCollaboratorRequest={fetchUpdateCollaboratorRequest}
            userTypeOptions={userTypeOptions}
            style={styles.actionSheetHeader}
          />
        </ActionSheet>
      ) : (
        <ModalDefault isModalVisible={isOpenModal} closeModal={handleClose}>
          <ContentActionSheet
            t={t}
            T={T}
            handleClose={handleClose}
            collaboratorSelected={collaboratorSelected}
            fetchUpdateCollaboratorRequest={fetchUpdateCollaboratorRequest}
            userTypeOptions={userTypeOptions}
            style={styles.modalHeader}
          />
        </ModalDefault>
      )}
    </>
  )
}

export default ActionSheetComponent

const styles = StyleSheet.create({
  actionStyles: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    minHeight: 210,
  },
  actionSheetHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 21,
    paddingTop: 21,
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  heightWithoutRole: {
    minHeight: 438,
  },
})
