interface ILanguage {
  [key: string]: string | undefined
  es?: string
  en?: string
  pt?: string
}

export const translateAnyLanguage = (object: ILanguage, lang: string) => {
  return object?.[lang] || object?.en || object?.es || object?.pt || ''
}
