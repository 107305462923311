import EMPTY_STATE_LICENSES from '../../components/LicensesEmpty/translate/es'
import CONTENT_BACKDROP_CROP_TYPES from '../../components/ContentBackDropCropTypes/translate/es'

export default {
  ALL: 'Todas',
  COMPONENTS: {
    EMPTY_STATE_LICENSES,
    CONTENT_BACKDROP_CROP_TYPES,
  },
}
