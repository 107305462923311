export default {
  TEXT_1: 'Disponível só com conexão',
  TEXT_2: '¿Tem certeza de que deseja realizar esta ação?',
  TEXT_3:
    'Esta ação é permanente, pode perder dados de operação. (Pode demorar alguns segundos)',
  TEXT_4: 'Cancelar',
  TEXT_5: 'Limpeza...',
  TEXT_6: 'Limpar',
  TEXT_7: 'Visualizando tarefas a serem feitas online',
  TEXT_8: 'Fila de sincronização',
  TEXT_9: 'Esta opção irá deletar todos os dados offline',
  TEXT_10: 'Limpar dados offline',
  TEXT_11: 'Esta opção irá deletar todos os dados offline',
}
