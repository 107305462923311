export default {
  TEXT_1: 'Fechar',
  UNAUTHORIZED: {
    TEXT_1: 'Nenhuma permissão',
    TEXT_2:
      'Para acessar essas informações, você deve solicitar permissões ao administrador.',
  },
  NOT_AVAILABLE: {
    TEXT_1: 'Verifique o link',
    TEXT_2:
      'As informações que você está tentando acessar não estão mais disponíveis.',
  },
  ERROR_UNEXPECTED: {
    TEXT_1: 'Não conseguimos acessar as informações',
    TEXT_2: 'Tente mais tarde.',
  },
  EXIST_VERIFICATION_DRAFT: {
    TEXT_1: 'Actividad verificador',
    TEXT_2:
      'Hay una o más actividades de verificación en borrador. No se puede crear una nueva hasta no finalizar al menos una.',
  },
  NO_LOTS_AVAILABLE: {
    TEXT_1: 'Actividad verificador',
    TEXT_2: 'No hay lotes disponibles para generar una nueva verificación.',
  },
}
