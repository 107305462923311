export const parseEvidences = ({ evidences }) => {
  const evidencesParsed = evidences.map(
    ({
      description,
      originalName,
      name,
      user,
      userCreate,
      date,
      path,
      file,
    }) => {
      const fileName = originalName || name
      const currentUser = userCreate || user
      const uploadedBy = `${currentUser.firstName} ${currentUser.lastName}`
      const uploadedAt = String(date)
      const filePath =
        typeof file === 'string'
          ? file
          : file?.uri
          ? file?.uri
          : path
          ? path
          : URL.createObjectURL(file)

      return {
        description,
        fileName,
        uploadedBy,
        uploadedAt,
        filePath,
      }
    }
  )

  return evidencesParsed
}
