export default {
  TEXT_1: 'Detalle Planificación',
  TEXT_2: 'ha',
  TEXT_3: 'EIQ',
  TEXT_4: 'EIQ planificado',
  TEXT_5: 'Lotes',
  TEXT_6: 'ha',
  TEXT_7: 'Desde',
  TEXT_8: 'Hasta',
}
