import { FC, ReactNode } from 'react'
import { View, StyleSheet, Pressable, Modal } from 'react-native'
import { Text } from 'react-native-paper'

import { CommonIcon } from '@modules/common/components'
import { white } from '@modules/common/styles'
import { SIZES } from '@common/components/icons/interfaces'

type ModalSuccessProps = {
  title: string
  description: string
  visible: boolean
  onDismiss: () => void
  children?: ReactNode
  style?: Record<string, unknown>
}

const ModalSuccess: FC<ModalSuccessProps> = ({
  title,
  description,
  visible,
  onDismiss,
  children,
  style,
}) => {
  return (
    <Modal visible={visible} style={styles.modal} animationType='slide'>
      <Pressable style={styles.container} onPress={onDismiss}>
        <Text style={[styles.successText, styles.successTitleText]}>
          {title}
        </Text>

        <View style={[styles.buttonContainer, style ?? style]}>
          <View style={styles.button}>
            <CommonIcon name={'CHECK'} size={SIZES.BIG} color={white} />
          </View>
        </View>

        <Text style={[styles.successText, styles.successSubText]}>
          {description}
        </Text>

        {children}
      </Pressable>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    padding: 0,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    backgroundColor: '#81C784',
  },
  successTitleText: {
    fontSize: 30,
    lineHeight: 40,
    letterSpacing: -0.5,
  },
  successText: {
    color: '#FFFFFF',
    fontStyle: 'normal',
    fontWeight: '500',
    textAlign: 'center',
  },
  successSubText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: -0.5,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: 171,
    height: 171,
    marginTop: 20,
    marginBottom: 20,
    borderRadius: 100,
    backgroundColor: 'rgba(76, 175, 80, .5)',
  },
  button: {
    width: 131,
    height: 131,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 100,
    backgroundColor: '#4CAF50',
    marginVertical: 30,
  },
  icon: {
    color: '#FFFFFF',
  },
})

export default ModalSuccess
