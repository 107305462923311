import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { filterSubActivityTypesByType } from '@utils/common'
import { CommonContext } from '@contextState/common'
import InputText from '@components/inputs/InputText'
import InputDate from '@components/inputs/InputDate'
import { truncateSurface } from '@common/utils/truncateSurface'

export const AchievementUnplannedForm = ({
  formik: { setFieldValue, values, setFieldTouched, touched, errors },
  readOnly = false,
  onPressLotsInput,
  crop: { areaUnit },
  activityType,
}) => {
  const { t } = useContext(LanguageContext)
  const { subTypeActivities } = useContext(CommonContext)
  const [filteredSubTypes, setFilteredSubTypes] = useState([])

  useEffect(() => {
    let filteredSubTypes = []

    if (subTypeActivities.length) {
      filteredSubTypes = filterSubActivityTypesByType(
        subTypeActivities,
        activityType?.value
      )
    }
    setFilteredSubTypes(filteredSubTypes)
  }, [activityType])

  return (
    <View>
      <InputSelectDefault
        id='subTypeActivity'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('subTypeActivity', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('subTypeActivity'))}
        value={filteredSubTypes.length ? values.subTypeActivity : null}
        label={`${t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_10')} ${
          t('CONSTANTS').ACTIVITY_TYPES[activityType?.tag]
        }`}
        placeholder={`${t('COMPONENTS.ACTIVITIES.COMMON_FORM.TEXT_10')} ${
          t('CONSTANTS').ACTIVITY_TYPES[activityType?.tag]
        }`}
        options={filteredSubTypes}
        touched={touched.subTypeActivity}
        error={errors?.subTypeActivity?.value}
        disabled={Boolean(readOnly)}
      />

      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={20}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_1.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_1.LABEL')}`}
        touched={touched.lots}
        error={errors.lots?.value}
        editable={false}
        disabled={Boolean(readOnly)}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_2.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_2.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled={true}
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <InputDate
        id='dateAchievement'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('dateAchievement', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('dateAchievement'))}
        value={values.dateAchievement}
        label={t('COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_4')}
        placeholder={t('COMPONENTS.ACTIVITIES.ACHIEVEMENTS_FORM.TEXT_4')}
        disablePast={false}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        touched={touched.dateAchievement}
        error={errors.dateAchievement?.value}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
})

AchievementUnplannedForm.propTypes = {
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onPressLotsInput: PropTypes.func.isRequired,
}
