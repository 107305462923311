export default {
  TEXT_1: 'Subir kmz de lotes',
  TEXT_2: 'Carregar outro KMZ',
  TEXT_3: 'Carregar lotes selecionados',
  TEXT_4: 'Todos os lotes',
  TEXT_5: 'O arquivo não tem formato KMZ',
  FIELD_1: {
    NAME: 'Nome do estabelecimento',
  },
}
