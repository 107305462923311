import * as yup from 'yup'

export const floodValidationSchema = (t: any) =>
  yup.object().shape({
    timeFloodUnderCrop: yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    unitTime: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    lots: yup
      .object()
      .shape({
        value: yup
          .array()
          .min(1, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 1 }))
          .of(
            yup.object().shape({
              _id: yup.string().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
              surface: yup.number().required(t('ERRORS.FORM_ERRORS.REQUIRED')),
              tag: yup.string(),
              wktFormat: yup.string(),
              area: yup.string(),
            })
          )
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    surface: yup.number().typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT')),
    dateAchievement: yup
      .object()
      .shape({
        value: yup
          .date()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    paddyWaterCultivation: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    paddyWaterPreseason: yup
      .object()
      .shape({
        value: yup
          .string()
          .typeError(t('ERRORS.FORM_ERRORS.INVALID_FORMAT'))
          .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
      })
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
    evidences: yup.array(),
    signers: yup
      .array()
      .min(2, t('ERRORS.FORM_ERRORS.MIN_QUANTITY', { quantity: 2 }))
      .required(t('ERRORS.FORM_ERRORS.REQUIRED')),
  })
