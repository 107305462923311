import { INIT_NOTIFICATION_FILTER } from '@modules/notifications/screens/Filters/v1/utils'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

/**
 * @property {string} url
 * @property {func} onMessage
 */
const initialState = {
  notifications: [],
  notificationsFilters: INIT_NOTIFICATION_FILTER,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    /**
     * @param {object} state
     * @property {string} state.url
     * @property {func} state.onMessage
     *
     * @param {object} action
     * @property {object} action.payload
     * @property {string} action.payload.url
     * @property {func} action.payload.onMessage
     */
    setNotifications: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        notifications: action.payload,
      }
      return state
    },
    setNotificationsFilters: (state, action: PayloadAction<any>) => {
      state = {
        ...state,
        notificationsFilters: action.payload,
      }
      return state
    },
    resetNotificationsFilter: (state) => {
      state.notificationsFilters = INIT_NOTIFICATION_FILTER
      return state
    },
  },
})

export const { setNotifications, resetNotificationsFilter } =
  notificationsSlice.actions

export const { reducer: notificationsReducer } = notificationsSlice
