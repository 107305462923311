const companyTypes = {
  VERIFIERS: {
    key: 'VERIFIERS',
    name: (t) => t('CONSTANTS').COMPANY_TYPES.VERIFIERS,
  },
  TRADERS: {
    key: 'TRADERS',
  },
  UCROPIT: {
    key: 'UCROPIT',
  },
  PRODUCER: {
    key: 'PRODUCER',
  },
  PROVIDER: {
    key: 'PROVIDER',
  },
  DEMO: {
    key: 'DEMO',
  },
}

export default companyTypes
