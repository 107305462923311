export default {
  TEXT_1: 'SALVAR RASCUNHO',
  TEXT_2: 'VALIDAR',
  TEXT_3: 'SINAL',
  TEXT_4: 'ASSINADO',
  TEXT_5: 'Preliminar: versão %{version}',
  TEXT_6: 'por %{user} em %{date} às %{hour} hs.',
  TEXT_7: 'A atividade de rascunho foi salva',
  TEXT_8:
    'Esta postagem está sendo editada por outro colaborador (%{userFullname}).',
  TEXT_9: 'EM VERIFICAÇÃO',
  TEXT_10: 'Rejeitar',
  CROP_DESCRIPTION: {
    TEXT_1: '%{surface} has',
  },
  HARVEST_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Selecionar lotes',
      LABEL: 'Lotes selecionados',
      LABEL_ALT: 'Lote selecionado',
    },
    FIELD_2: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    FIELD_3: {
      PLACEHOLDER: 'Data de colheita',
      LABEL: 'Data de colheita',
    },
    FIELD_4: {
      PLACEHOLDER: 'Unidade de rendimento',
      LABEL: 'Unidade de rendimento',
    },
    FIELD_5: {
      PLACEHOLDER: 'Volume',
      LABEL: 'Volume',
    },
    FIELD_6: {
      PLACEHOLDER: 'Rendimento',
      LABEL: 'Rendimento',
    },
    FIELD_VOLUME_UNIT: {
      PLACEHOLDER: 'Unidade de volume',
      LABEL: 'Unidade de volume',
    },
  },
  MONITORING_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Selecionar lotes',
      LABEL: 'Lotes selecionados',
      LABEL_ALT: 'Lote selecionado',
    },
    FIELD_2: {
      PLACEHOLDER: 'Data de monitoramento',
      LABEL: 'Data de monitoramento',
    },
    FIELD_3: {
      PLACEHOLDER: 'Unidade de rendimento',
      LABEL: 'Unidade de rendimento',
    },
    FIELD_4: {
      PLACEHOLDER: 'Estimativa de rendimento',
      LABEL: 'Estimativa de rendimento',
    },
    FIELD_5: {
      PLACEHOLDER: 'Observação',
      LABEL: 'Observação',
    },
    FIELD_6: {
      PLACEHOLDER: 'Estimativa de colheita',
      LABEL: 'Estimativa de colheita',
    },
  },
  AGREEMENT_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Tipo de declaração',
      LABEL: 'Tipo de declaração',
    },
    FIELD_2: {
      PLACEHOLDER: 'Selecionar lotes',
      LABEL: 'Lotes selecionados',
      LABEL_ALT: 'Lote selecionado',
    },
    FIELD_3: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Porcentagem de umidade da semente',
      LABEL: 'Porcentagem de umidade da semente',
    },
    FARM_FIELD: {
      SINGULAR: '%{num} Campo selecionado',
      PLURAL: '%{num} Campos selecionados',
    },
    SURFACE_FIELD: {
      LIMIT: 'A área não pode exceder %{surfaceMax} %{areaUnit}.',
    },
  },
  STATEMENT_CFT_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Selecione Campo',
      LABEL: 'Selecione Campo',
    },
    FIELD_2: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    EMISSION_TITLE: 'Cálculo de GHG',
    EMISSION_TEXT_BEFORE_SEND:
      'Preenchendo apenas alguns dados, vamos gerar um relatório calculando os gases de efeito estufa (GHG) com a metodologia CFT e adicioná-lo como evidência à cultura.',
    EMISSION_BUTTON: 'Gerar evidências',
    EMISSION_TEXT_AFTER_SEND:
      'Ao validar a declaração, geraremos o relatório e o acrescentaremos como prova à safra.',
  },
  STATEMENT_FORM: {
    SELECT_FIELDS: 'Selecionar lotes',
    DECLARATIVE_REPORT: 'Relatório declarativo',
    COMPLETE_THE_FORM:
      'Preencha o formulário para gerar o relatório e adicioná-lo como evidência à sua colheita.',
    COMPLETE_FORM: 'Formulário completo',
    FORM_TYPE_DECLARATION_REPORT: 'Relatório Declaração tipo formulário',
    BY_VALIDATING_THE_DECLARATION:
      'Ao validar a declaração, iremos gerar o relatório e adicioná-lo como prova à sua cultura',
  },
  IRRIGATION_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Método de irrigação',
      LABEL: 'Método de irrigação',
    },
    FIELD_2: {
      PLACEHOLDER: 'Parcelas selecionadas',
      LABEL: 'Parcelas selecionadas',
      LABEL_ALT: 'Parcela selecionada',
    },
    FIELD_3: {
      PLACEHOLDER: 'Área %{areaUnit}',
      LABEL: 'Área %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Data de implementação',
      LABEL: 'Data de implementação',
    },
    FIELD_5: {
      PLACEHOLDER: 'Fonte de água',
      LABEL: 'Fonte de água',
    },
    FIELD_6: {
      PLACEHOLDER: 'Profundidade de bombeamento %{unit}',
      LABEL: 'Profundidade de bombeamento %{unit}',
    },
    FIELD_7: {
      PLACEHOLDER: 'Consumo de água %{unit}',
      LABEL: 'Consumo de água %{unit}',
    },
    FIELD_8: {
      PLACEHOLDER: 'Distância de irrigação horizontal %{unit}',
      LABEL: 'Distância de irrigação horizontal %{unit}',
    },
    FIELD_9: {
      PLACEHOLDER: 'Fonte de energia',
      LABEL: 'Fonte de energia',
    },
  },
  STATEMENT_SDP_REPORT_NEED_SURFACE_FARM: {
    TITLE: 'Área do campo',
    DESCRIPTION:
      'Para gerar o relatório, precisamos que você carregue um arquivo KMZ com o perímetro do campo para calcular a área com precisão.',
    REDIRECT_EDIT_FARM_TEXT: 'Carregar arquivo KMZ',
  },
  VERIFICATION_FORM: {
    FIELD_1: {
      PLACEHOLDER: 'Tipo de verifica',
      LABEL: 'Tipo de verifica',
    },
    FIELD_2: {
      PLACEHOLDER: 'Selecionar lotes',
      LABEL: 'Lotes selecionados',
      LABEL_ALT: 'Lote selecionado',
    },
    FIELD_3: {
      PLACEHOLDER: 'Superficie %{areaUnit}',
      LABEL: 'Superficie %{areaUnit}',
    },
    FIELD_4: {
      PLACEHOLDER: 'Observação',
      LABEL: 'Observação',
    },
  },
  DESTINATION: {
    TEXT_1: 'ADICIONE DESTINO',
    MODAL_1: {
      TEXT_1: 'ADICIONE DESTINO',
    },
  },
  EVIDENCE: {
    TEXT_1: 'ADICIONE EVIDÊNCIA',
    MODAL_1: {
      TEXT_1: 'ADICIONE EVIDÊNCIA',
    },
  },
  SUPPLY: {
    TEXT_1: 'ADICIONE ENTRADA',
  },
  SUPPLIES_LIST: {
    TEXT_1: 'EIQ %{eiqQuantity}',
  },
  DESTINATION_LIST: {
    TEXT_1: 'Armazenar',
    TEXT_2: '%{tonQuantity} Toneladas',
  },
  COLLABORATORS: {
    TEXT_1: 'Colaboradores',
    TEXT_2: 'Adicionar colaboradores',
    TEXT_3: '%{collaboratorsQuantity} colaboradores selecionados',
  },
  MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES: {
    TEXT_1: 'Pretende sair sem guardar?',
    TEXT_2: 'As alterações não serão mantidas.',
    TEXT_3: 'SAIA',
    TEXT_4: 'PERMANECER',
  },
  MODAL_DELETE: {
    TEXT_1: 'Excluir rascunho?',
    TEXT_2: 'As evidências enviadas serão excluídas permanentemente.',
    TEXT_3: 'MANTER',
    TEXT_4: 'REMOVER',
  },
  MODAL_VALIDATE: {
    TEXT_1: 'Validar esta versão do rascunho?',
    TEXT_2: 'Existem outras versões deste mesmo registro',
    TEXT_3: 'CANCELAR',
    TEXT_4: 'VALIDAR',
  },
  MODAL_CONFIRM_ACTIVITY_VERIFICATION: {
    TEXT_1: 'Tipo de verificación',
    TEXT_2:
      'Al modificar el tipo de verificación se perderan todos los datos de la Actividad',
    TEXT_3: 'MODIFICAR',
    TEXT_4: 'CANCELAR',
  },
  MODAL_VALIDATE_VERIFICATION: {
    TEXT_1: 'Validar verificação',
    TEXT_2:
      'Você pode então carregar o documento final, se exigido pelo status de verificação.',
    TEXT_3: 'ADICIONAR VERIFICAÇÃO',
    TEXT_4: 'Verifica %{verificationType}',
    TEXT_5: 'Doença: %{verificationStatus}',
    TEXT_6: 'Selecione o status final da verificação:',
    TEXT_7: 'NÃO CONFORME',
    TEXT_8: 'VERIFICADO',
  },
  MODAL_CONFIRM_VALIDATE_VERIFICATION: {
    TEXT_1: 'Validar Verificación',
    TEXT_2:
      'Una vez validada la verificación no podrá modificar los datos cargados.',
    TEXT_3: 'CANCELAR',
    TEXT_4: 'VALIDAR',
  },
  MODAL_CONFIRM_DECLINE_SIGNATURE: {
    TEXT_1: 'Está prestes a rejeitar uma assinatura',
    TEXT_2: 'Considerar que esta ação implica que a atividade está suspensa.',
    TEXT_3: 'inserir uma observação',
    TEXT_4: 'Pode inserir até 140 caracteres.',
    TEXT_5: 'Confirmar rejeição',
  },
  MODAL_LOADING: {
    COMPLETING_THE_APPLICATION: 'Preenchendo o pedido',
    PLEASE_WAIT_A_FEW_MOMENTS: 'Por favor, aguarde alguns momentos.',
    PLEASE_WAIT_A_FEW_MORE_MOMENTS: 'Por favor, aguarde mais alguns momentos.',
  },
  AGREEMENT_FORM_DISCLAIMER_EVIDENCE: {
    SUSTAINABILITY_2BSVS: {
      TITLE: 'Relatório de Sustentabilidade da Paisagem - 2BSvs',
      DESCRIPTION:
        'Depois que a atividade for validada, geraremos o relatório em alguns minutos.',
    },
    SUSTAINABILITY_EPA: {
      TITLE: 'Relatório de Sustentabilidade da Paisagem - EPA',
      DESCRIPTION:
        'Depois que a atividade for validada, geraremos o relatório em alguns minutos.',
    },
    SUSTAINABILITY_MOA: {
      TITLE: 'Relatório de Sustentabilidade da Paisagem - MOA',
      DESCRIPTION:
        'Depois que a atividade for validada, geraremos o relatório em alguns minutos.',
    },
    SUSTAINABILITY_CAN: {
      TITLE: 'Relatório de Sustentabilidade da Paisagem - Genérica',
      DESCRIPTION:
        'Depois que a atividade for validada, geraremos o relatório em alguns minutos.',
    },
  },
  WATER_CALCULATION_FORM: {
    WEATHER: 'Clima',
    TEMPERATURE: 'Temperatura °%{unitTemperature}',
    LAND_TYPE: 'Tipo de solo',
    SOIL_CHARACTERISTIC: 'Características do solo',
    SOIL_MOISTURE: 'Umidade do solo',
    MEASURED_AT_PLANTING: 'Medida na semeadura',
  },
  FLOOD_FORM: {
    TIME_FLOOD_UNDER_CROP: 'Tempo de inundação em cultivo',
    WATER_MANAGEMENT: 'Gerência de água',
    BEFORE_CROP: 'Antes do cultivo',
    DURING_CROP: 'Durante o cultivo',
    DEFAULT_VALUES: 'Usar valores padrão',
    UNIT_TIME: 'Unidade de tempo',
  },
}
