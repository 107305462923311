export default {
  TEXT_1: 'Confirme o novo cultivo',
  TEXT_2: 'Da empresa física ou jurídica a cargo da produção',
  TEXT_3: 'Campo obrigatório',
  TEXT_4: 'A superfície deve ser maior que 0',
  TEXT_5: 'lotes',
  TEXT_6: 'ha',
  TEXT_7: 'Confirme o novo cultivo',
  TEXT_8: '¡Parabéns!',
  TEXT_9: 'Você já atribuiu os lotes ao cultivo',
  TEXT_10: 'Veja mais detalhes da sua cultura:',
  TEXT_11: 'História do cultivo',
  TEXT_12: 'Voltar às culturas',
  TEXT_13: 'ac',
  FIELD_1: {
    NAME: 'Tipo de colheita',
  },
  FIELD_2: {
    NAME: 'Semeadura estimativa',
  },
  FIELD_3: {
    NAME: 'Colheita estimativa',
  },
  FIELD_4: {
    NAME: 'Rendimento estimativo',
  },
  FIELD_5: {
    NAME: 'CNPJ',
  },
  FIELD_6: {
    NAME: 'Nome do cultivo',
  },
  FIELD_7: {
    NAME: 'Superfície Neta',
  },
  FIELD_8: {
    NAME: 'Unidade de rendimento',
  },
  FIELD_9: {
    NAME: 'País',
  },
  TEXT_14: '¡Parabéns!',
  TEXT_15: 'Cultivo criado com sucesso.',
  OUTLIER_TITLE: 'O valor está fora da média.',
  OUTLIER_DESCRIPTION: 'Como utilizar o desvio padrão?',
}
