import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from 'google-libphonenumber'

function formattedNumber(phone, country) {
  const phoneUtil = PhoneNumberUtil.getInstance()
  const number = phoneUtil.parse(phone, country ?? 'AR')
  if (phoneUtil.isPossibleNumber(number)) {
    return phoneUtil.format(number, PNF.INTERNATIONAL)
  }
}
export { formattedNumber }
