export default {
  TEXT_1: 'Planificada',
  TEXT_2: 'Desde',
  TEXT_3: 'Hasta',
  TEXT_4: '%{establishments} Campos %{lots} lotes',
  TEXT_5: 'Superficie planificada: %{surface} %{areaUnit}',
  TEXT_6: 'Tipo de %{activityType} : %{subTypeActivity}',
  TEXT_7: 'EIQ Planificado %{eiq}',
  TEXT_8: 'INSUMO',
  TEXT_9: 'INSUMOS',
}
