import { useContext, useState } from 'react'
import {
  awsSignIn,
  awsSignUp,
  awsIsAuthenticated,
  answerCustomChallenge,
  redirectQuickSight,
  getQuickSightUrl,
} from '@services/amplify'
import { errorsCognito } from '@utils/constants'
import { useNavigation } from '@react-navigation/native'
import { LanguageContext } from '@contextState/language'
import { useAuth } from './useAuth'

const useAuthCognito = () => {
  const navigation = useNavigation()
  const { user } = useAuth()

  const { t } = useContext(LanguageContext)

  const [isLoading, setIsLoading] = useState(false)

  let cognitoUser = null

  const signUpUserCognito = async (email, completeName) => {
    try {
      const user = await awsSignUp(email, completeName)
      setTimeout(async () => {
        authenticationUser()
      }, 1000)
      return user
    } catch (error) {
      console.error('error signUp aws', error)
      return null
    }
  }

  const submitCode = async (values, formik) => {
    try {
      const isSignIn = await answerCustomChallenge(values.code, cognitoUser)
      if (isSignIn) {
        redirectQuickSight()
        navigation.goBack()
      } else {
        formik.setErrors({ code: t('VIEWS.COGNITO_CODE.CODE_INCORRECT') })
        formik.setSubmitting(false)
      }
    } catch (error) {
      console.error('error submitCode', error)
      formik.setSubmitting(false)
    }
  }

  const authenticationUser = async () => {
    setIsLoading(true)
    try {
      const authenticate = await awsIsAuthenticated()
      if (authenticate) {
        redirectQuickSight()
      } else {
        const userLogin = await awsSignIn(user.email)
        cognitoUser = userLogin
        navigation.navigate('CognitoCode', {
          callback: submitCode,
        })
      }
    } catch (error) {
      if (error.code === errorsCognito.USER_NOT_FOUND_EXCEPTION) {
        const completeName = `${user.firstName} ${user.lastName}`
        await signUpUserCognito(user.email, completeName)
      } else {
        alert(`${error.code}: ${error.message}`)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { authenticationUser, redirectQuickSight, isLoading, getQuickSightUrl }
}

export default useAuthCognito
