/**
 * @returns {object}
 */
export const getFormikInitialValues = () => {
  return {
    typePerson: {
      label: '',
      value: '',
    },
    address: '',
    specificAddress: '',
    files: [],
  }
}
