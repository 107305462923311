export const languages = [
  { key: 'es', text: 'Español' },
  { key: 'en', text: 'English' },
  { key: 'pt', text: 'Português' },
]

export const SUPPLY_DEFAULT = 'default'

export const SURFACE_UNIT = {
  AC: 'ac',
  HA: 'ha',
}

export const keysSupplies = {
  all: 'ALL',
  seeds: 'SEEDS',
  fertilizer: 'FERTILIZERS',
  phytotherapics: 'PHYTOTHERAPICS',
}

export const outlierWarningValues = [3, 4, 5]
