import React, { useContext } from 'react'
import { View, Text, StyleSheet, Pressable } from 'react-native'
import { PropTypes } from 'prop-types'
import { primary500 } from '@styles/palette'

import { LanguageContext } from '@contextState/language'

export const SnackbarLicensesPending = ({
  visible,
  snackbarText,
  onPressGoToLicenseDetails,
  licensesPending,
}) => {
  if (!visible) {
    return null
  }

  const { t } = useContext(LanguageContext)

  return (
    <View style={styles.snackbarLicensesPendingContainer}>
      <Text style={styles.snackbarLicensesPendingText}>{snackbarText}</Text>

      <Pressable
        onPress={onPressGoToLicenseDetails}
        style={styles.snackbarLicensesPendingButton}
      >
        <Text style={styles.snackbarLicensesPendingButtonText}>
          {licensesPending.length === 1
            ? t('VIEWS.ACTIVITIES.TEXT_38')
            : t('VIEWS.ACTIVITIES.TEXT_39')}
        </Text>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  snackbarLicensesPendingContainer: {
    backgroundColor: '#4F4F4F',
    paddingHorizontal: 15,
    paddingVertical: 16,
    marginHorizontal: 12,
    marginTop: 4,
    borderRadius: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  snackbarLicensesPendingText: {
    fontSize: 14,
    fontWeight: '400',
    color: '#FFFFFF',
  },
  snackbarLicensesPendingButton: {
    marginTop: 4,
    padding: 6,
    marginLeft: 'auto',
  },
  snackbarLicensesPendingButtonText: {
    fontSize: 14,
    fontWeight: '700',
    color: primary500,
  },
})

SnackbarLicensesPending.propTypes = {
  snackbarText: PropTypes.string,
  onPressGoToLicenseDetails: PropTypes.func,
  licensesPending: PropTypes.array,
}
