export default {
  TEXT_1: 'Net area',
  TEXT_2: 'Estimated yield',
  TEXT_3: 'Expected volume',
  TEXT_4: 'tn',
  TEXT_5: 'ha',
  TEXT_6: 'VERIFIED',
  TEXT_7: '*status VERIFIED - Se verification activity',
  TEXT_8: 'Verification',
  TEXT_9: 'Verifier',
  TEXT_10: 'status',
  TEXT_11: 'Verification date',
  TEXT_12: 'Contracts',
  TEXT_13: '%{lotsQuantity} fields',
  TEXT_14: '%{has} ha',
  TEXT_15: 'Validation date',
  TEXT_16: 'Verified',
  TEXT_17: 'Final Verification',
  TEXT_18: 'Evidence %{evidenceNumber}',
}
