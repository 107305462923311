export default {
  TEXT_1: "Upload Fields' KMZ",
  TEXT_2: 'Upload another map',
  TEXT_3: 'Upload selected fields',
  TEXT_4: 'All fields',
  TEXT_5: 'The file does not have KMZ format',
  FIELD_1: {
    NAME: 'Farms name',
  },
}
