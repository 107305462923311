import React from 'react'
import { StyleSheet, View } from 'react-native'

import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import InputText from '@components/inputs/InputText'
import InputDate from '@components/inputs/InputDate'
import { truncateSurface } from '@common/utils/truncateSurface'
import { SIZES } from '@common/components/icons/interfaces'
import { useIrrigationForm } from '../hooks/useIrrigationForm'

export const IrrigationForm = ({
  formik,
  readOnly = false,
  crop: { areaUnit },
  activityType,
  onPressLotsInput,
}: any) => {
  const { setFieldValue, values, setFieldTouched, touched, errors } = formik
  const {
    filteredSubTypes,
    unitsLongitude,
    irrigationPowerSources,
    irrigationWaterSources,
    t,
  } = useIrrigationForm({ activityType })
  return (
    <View>
      <InputSelectDefault
        id='subTypeActivity'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('subTypeActivity', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('subTypeActivity'))}
        value={filteredSubTypes.length ? values.subTypeActivity : null}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_1.LABEL')}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_1.PLACEHOLDER'
        )}
        options={filteredSubTypes}
        touched={touched.country}
        error={errors?.subTypeActivity?.value}
        disabled={Boolean(readOnly)}
      />

      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={SIZES.MEDIUM}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_2.LABEL')}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_2.PLACEHOLDER'
        )}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_3.PLACEHOLDER',
          { areaUnit }
        )}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_3.LABEL', {
          areaUnit,
        })}
        editable={false}
        disabled
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <InputDate
        id='dateAchievement'
        containerStyle={styles.containerInput}
        onChangeText={(value: any) => {
          setFieldValue('dateAchievement', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('dateAchievement'))}
        value={values.dateAchievement}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_4.PLACEHOLDER'
        )}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_4.LABEL')}
        disablePast={false}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        touched={touched.dateAchievement}
        error={errors.dateAchievement?.value}
      />

      <InputSelectDefault
        id='irrigationWaterSource'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('irrigationWaterSource', value)
        }}
        onBlur={() =>
          setTimeout(() => setFieldTouched('irrigationWaterSource'))
        }
        value={values.irrigationWaterSource}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_5.LABEL')}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_5.PLACEHOLDER'
        )}
        options={irrigationWaterSources}
        touched={touched.irrigationWaterSource}
        error={errors?.irrigationWaterSource?.value}
        disabled={Boolean(readOnly)}
      />

      <InputText
        id='pumpingDepth'
        containerStyle={styles.containerInput}
        onChangeText={(value: string) => {
          setFieldValue('pumpingDepth', value)
        }}
        value={values.pumpingDepth}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_6.LABEL', {
          unit: unitsLongitude.UNIT_1,
        })}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_6.PLACEHOLDER',
          { unit: unitsLongitude.UNIT_1 }
        )}
        keyboardType='decimal-pad'
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        onBlur={() => setFieldTouched('pumpingDepth')}
        touched={touched.pumpingDepth}
        error={errors.pumpingDepth}
      />

      <InputText
        id='waterConsumption'
        containerStyle={styles.containerInput}
        onChangeText={(value: string) => {
          setFieldValue('waterConsumption', value)
        }}
        value={values.waterConsumption}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_7.LABEL', {
          unit: unitsLongitude.UNIT_2,
        })}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_7.PLACEHOLDER',
          { unit: unitsLongitude.UNIT_2 }
        )}
        keyboardType='decimal-pad'
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        onBlur={() => setFieldTouched('waterConsumption')}
        touched={touched.waterConsumption}
        error={errors.waterConsumption}
      />

      <InputText
        id='horizontalWateringDepth'
        containerStyle={styles.containerInput}
        onChangeText={(value: string) => {
          setFieldValue('horizontalWateringDepth', value)
        }}
        value={values.horizontalWateringDepth}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_8.LABEL', {
          unit: unitsLongitude.UNIT_1,
        })}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_8.PLACEHOLDER',
          { unit: unitsLongitude.UNIT_1 }
        )}
        keyboardType='decimal-pad'
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        onBlur={() => setFieldTouched('horizontalWateringDepth')}
        touched={touched.horizontalWateringDepth}
        error={errors.horizontalWateringDepth}
      />

      <InputSelectDefault
        id='irrigationPowerSource'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('irrigationPowerSource', value)
        }}
        onBlur={() =>
          setTimeout(() => setFieldTouched('irrigationPowerSource'))
        }
        value={values.irrigationPowerSource}
        label={t('VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_9.LABEL')}
        placeholder={t(
          'VIEWS.ACTIVITY_CREATE.IRRIGATION_FORM.FIELD_9.PLACEHOLDER'
        )}
        options={irrigationPowerSources}
        touched={touched.irrigationPowerSources}
        error={errors?.irrigationPowerSource?.value}
        disabled={Boolean(readOnly)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
})
