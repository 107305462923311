import { truncateNumber } from '@common/utils'

export interface MapperChemicalCompositionsParams {
  [key: string]: number
}

export interface MapperChemicalCompositionsReturn {
  name: string
  value: number
}

export const mapperChemicalCompositions = (
  chemicalCompositions: MapperChemicalCompositionsParams
): MapperChemicalCompositionsReturn[] | null => {
  if (
    typeof chemicalCompositions !== 'object' ||
    !Object.keys(chemicalCompositions).length
  ) {
    return null
  }

  const newChemicalCompositions = Object.keys(chemicalCompositions)
    .map((key) => ({
      name: `% ${key}`,
      value: truncateNumber(chemicalCompositions[key], 2),
    }))
    .filter((composition) => composition.value)

  return newChemicalCompositions
}
