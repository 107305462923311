import React from 'react'
import { InputPhoneProps } from './inputsPhone.prop'
import { Platform } from 'react-native'
import { InputPhoneWeb } from '../InputPhoneWeb'
import { InputPhoneMobile } from '../InputPhoneMobile'

const InputPhone = (props: InputPhoneProps) => {
  return Platform.OS === 'web' ? (
    <InputPhoneWeb {...props} />
  ) : (
    <InputPhoneMobile {...props} />
  )
}

export default InputPhone
