export default {
  TEXT_1: 'El cultivo no tiene colaboradores',
  TEXT_2: 'Productor',
  TEXT_3: 'PRODUCTOR DEL CULTIVO',
  TEXT_4: 'AUTORIZACION PENDIENTE',
  TEXT_5: 'Remover Etiqueta',
  TEXT_6: 'Desvincular del colaborador',
  TEXT_7: '¿Desvincular colaborador?',
  TEXT_8: 'Está por desvincular un colaborador del cultivo, ¿Está seguro?',
  TEXT_9: 'CANCELAR',
  TEXT_10: 'DESVINCULAR',
  TEXT_11: 'No hay colaboradores',
  TEXT_12: 'Responsable del cultivo',
  TEXT_13: 'Asesor ucrop.it (KAM)',
  TEXT_14: 'Comercializador del cultivo',
  TEXT_15: 'Autorización Pendiente',
  TEXT_16: 'Validación pendiente',
  TEXT_17: 'Validaciones pendientes',
  TEXT_18: 'Delegar Validaciones',
  TEXT_19: 'Desvincular Colaborador',
  TEXT_20: 'Delegar',
  TEXT_21: 'yo',
  TEXT_22: 'OK',
  TEXT_23:
    'Todas las validaciones pendientes serán delegadas a este colaborador',
  TEXT_24: '¡Se delegaron las validaciones exitosamente!',
  TEXT_25:
    'No se ha podido delegar correctamente. Por favor, verificar la conexión e intentar nuevamente.',
  MODAL_ACTION_SHEET: {
    TEXT_1: 'elegir remplazo',
    TEXT_2:
      'No hay colaboradores aptos para recibir los pendientes. Por favor, agregar un nuevo colaborador.',
    TEXT_3:
      'Todas las validaciones pendientes serán delegadas a otro colaborador habilitado con el mismo rol (%{rol}) en este cultivo.',
    TEXT_4: 'Desvincular del Cultivo',
    TEXT_5: '¡Este colaborador no tiene validaciones pendientes!',
    TEXT_6: 'Designar un nuevo ',
    TEXT_7: ' antes de desvincular a este colaborador',
    TEXT_8:
      'Todas las validaciones pendientes deben ser delegadas a otro colaborador en este cultivo.',
  },
  TEXT_26: 'Ok',
  MODAL_RESPONSIBLE: {
    TEXT_1: 'Nuevo responsable',
    TEXT_2: 'Se reemplazara al',
    TEXT_3: 'actual',
    TEXT_4: 'Cancelar',
    TEXT_5: 'OK',
  },
  TEXT_27: 'confirmar delegación',
  TEXT_28: 'Confirmar Reemplazo',
  TEXT_29: '[Yo]',
  MODAL_CONFIRM: {
    TEXT_1: 'Confirmar Delegar y Desvincular',
    TEXT_2:
      'Todas las validaciones pendientes serán delegadas a este colaborador',
    TEXT_3: 'DELEGAR Y DESVINCULAR COLABORADOR',
  },
  MODAL_UNLINK: {
    TEXT_1:
      'Todas las validaciones pendientes deben ser delegadas a otro colaborador en este cultivo.',
    TEXT_2:
      'No hay colaboradores aptos para recibir los pendientes. Por favor, agregar un nuevo colaborador. ',
    TEXT_3: 'delegar pendientes',
  },
  TEXT_30: 'No tiene validaciones pendientes',
  TEXT_31: 'Error desvincular colaborador.',
  TEXT_32: 'Se desvinculó el colaborador exitosamente.',
  TEXT_33: 'Se reemplazó el colaborador exitosamente.',
  MODAL_UNLINK_VERIFIER: {
    TEXT_1: '¿Estas seguro de que deseas eliminar al',
    TEXT_2: ' Verificador ',
    TEXT_3:
      'del cultivo? Al hacerlo puede estar incumpliendo ciertas normativas',
  },
  TEXT_34: 'Responsable verificador',
}
