import MODAL_UPLOAD_FILE from '../../components/ModalUploadFile/translate/pt'
import APPROVE_MODAL from '../../components/ApproveCompanyModal/translate/pt'
export default {
  TEXT_1: 'Física',
  TEXT_2: 'Jurídica',
  TEXT_3: 'País',
  TEXT_4: 'Personalidade Jurídica',
  TEXT_5: 'Endereço',
  TEXT_6: 'Andar/Apartamento/Escritório',
  TEXT_7: 'Editar empresa',
  TEXT_8: 'ADICIONAR DOCUMENTAÇÃO',
  TEXT_9: 'Retornar aos campos',
  TEXT_10: 'Dados da empresa',
  APPROVE: 'Aprovar a empresa',
  COMPONENTS: {
    MODAL_UPLOAD_FILE,
    APPROVE_MODAL,
  },
}
