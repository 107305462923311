import React from 'react'
import { Text, StyleSheet, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { CropIcon } from '@modules/common/components'
import { carbon600, primary500, black } from '@styles/palette'
import ButtonRipple from '@common/components/buttons/ButtonRipple'

export const ItemCropType = ({
  item,
  onPress,
  iconName,
  label,
  isSelected,
  Icon,
}) => {
  return (
    <ButtonRipple onPress={() => onPress(item)}>
      <View style={styles.contentItem}>
        {!Icon ? (
          <CropIcon
            name={iconName.toUpperCase()}
            size={30}
            color={isSelected ? primary500 : black}
          />
        ) : (
          <Icon />
        )}
        {label && <Text style={styles.itemLabel}>{label}</Text>}
      </View>
    </ButtonRipple>
  )
}

const styles = StyleSheet.create({
  contentItem: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
  },
  itemLabel: {
    fontSize: 10,
    color: carbon600,
  },
})

ItemCropType.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string,
}
