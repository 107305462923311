import CODE_FORM from '../../components/CodeForm/translate/pt'
export default {
  SECRET_LOGIN_CODE: 'Código de acesso secreto',
  PARAGRAPH_ENTER_YOU_SECRET_CODE:
    'Digite seu código de login secreto de 6 dígitos que lhe enviamos para ',
  IF_HAVENT_RECEIVED_EMAIL: `Caso não tenha recebido o e-mail, verifique sua caixa de spam.`,
  LOGIN: 'Conecte-se',
  CODE_INCORRECT: 'O código inserido está incorreto.',
  COMPONENTS: {
    CODE_FORM,
  },
}
