import CardAchievement from '../components/CardAchievement/translate/es'
import HeaderAccordionPlanning from '../components/HeaderAccordionPlanning/translate/es'
import HeaderInfoAchievements from '../components/HeaderInfoAchievements/translate/es'
import WORK_ORDER from '../components/WorkOrder/translate/es'

export default {
  TEXT_1: 'TERMINAR ACTIVIDAD',
  TEXT_2: 'VOLVER A ACTIVIDADES',
  COMMON: {
    TEXT_1: 'Campo',
    TEXT_2: 'Campos',
    TEXT_3: 'Lote',
    TEXT_4: 'Lotes',
    TEXT_5: 'Insumos',
    TEXT_6: 'Insumo',
  },
  COMPONENTS: {
    HEADER_ACCORDION_PLANNING: HeaderAccordionPlanning,
    CARD_ACHIEVEMENT: CardAchievement,
    HEADER_INFO_ACHIEVEMENT: HeaderInfoAchievements,
    WORK_ORDER,
  },
  CONFIRM_FINISHED: {
    TEXT_1: 'Terminar %{activity}',
    TEXT_2:
      'La superficie que no se haya realizado, se podrá completar en una nueva actividad.',
    TEXT_3: 'TERMINAR',
    TEXT_4: 'CANCELAR',
  },
}
