export default {
  DELETE_DRAFT: 'Eliminar Borrador',
  CROP_STORY: 'Historia del cultivo',
  COLLABORATORS: 'Colaboradores',
  LICENSES: 'Ecosistemas',
  INTEGRATIONS_WITH_THIRD_PARTIES: 'Integración con terceros',
  ENABLE_OFFLINE: 'Habilitar offline',
  DISABLE_OFFLINE: 'Deshabilitar offline',
  FARM_AND_FIELD_MANAGEMENT: 'Gestión campo y lotes',
  DELETE_CROP: 'Eliminar cultivo',
}
