export default {
  FARMS: 'Farms',
  FARM: 'Farm',
  FIELDS: 'Fields',
  FIELD: 'Field',
  SURFACE: 'Area: %{surface} %{areaUnit}',
  SUB_ACTIVITY_TYPE: 'Type of %{activityType}: %{subActivityType}',
  ESTIMATED_YIELD: 'Estimated yield: %{pay} %{unitPay}',
  YIELD: 'Yield: %{pay} %{unitPay}',
  EIQ: 'EIQ Generated: %{eiq}',
  PERCENTAGE_REALIZED: 'Executed %{percentageRealized}%',
  PENDING_SIGNS: '%{pendingSignsQuantity} Pending signatures',
  PENDING_SIGN: '%{pendingSignsQuantity} Pending signature',
  VERIFICATION_STATUS_VALID: 'Verified',
  VERIFICATION_STATUS_INVALID: 'Not compliant',
  BUTTON_TEXT: 'See detail',
  WATER_CONSUMPTION: 'Water consumption',
}
