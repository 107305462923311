import { Linking, Share } from 'react-native'
import { mapWorkOrder, workOrderShareMessage } from '../utils'
import { useSelector } from 'react-redux'
import { shareViewWorkOrder } from '@services/analytics'
import {
  WORK_ORDER_ANALYTICS_SHARE,
  WORK_ORDER_ANALYTICS_VIEW,
} from '@utils/constants'

const responseError = {
  shareWorkOrder: () => console.warn('does not have a work order number'),
  seeWorkOrder: () => console.warn('does not have a work order number'),
}

/**
 *
 * @param {Function} t
 * @param {Object} activity
 * @param {string} activity.name
 * @param {Object} activity.crop
 * @param {Object} activity.crop.cropType
 * @param {string} activity.crop.cropType.keyLabel
 * @param {number} activity.surface
 * @param {string} activity.areaUnit
 * @param {string} activity.dateStart
 * @param {string} activity.workOrderNumber
 * @param {string} activity.workOrderShortUrl
 * @param {string} activity.workOrderLongUrl
 * @param {string} locale
 *
 * @returns
 */
export const useWorkOrder = (t, activity, locale) => {
  const { user, config } = useSelector((state) => state.auth)
  const userType = config?.roleSelected?.value
  if (!activity?.workOrderNumber) {
    return responseError
  }

  const workOrder = mapWorkOrder(activity, locale)
  const shareWorkOrder = async () => {
    if (!workOrder || !workOrder?.url) {
      return
    }

    try {
      const { title, message } = workOrderShareMessage(t, workOrder)
      shareViewWorkOrder({
        date: new Date(),
        userType: userType,
        activityType: activity.type.tag,
        workOrderNumber: activity.workOrderNumber,
        email: user.email,
        action: WORK_ORDER_ANALYTICS_SHARE,
      })

      await Share.share({
        title,
        message,
      })
    } catch (err) {
      console.error(err.message)
    }
  }

  const seeWorkOrder = () => {
    shareViewWorkOrder({
      date: new Date(),
      userType: userType,
      activityType: activity.type.tag,
      workOrderNumber: activity.workOrderNumber,
      email: user.email,
      action: WORK_ORDER_ANALYTICS_VIEW,
    })

    Linking.canOpenURL(workOrder?.longUrl) &&
      Linking.openURL(workOrder?.longUrl)
  }

  return {
    shareWorkOrder,
    seeWorkOrder,
  }
}
