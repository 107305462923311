import RouterNames from '@constants/routerNames'

export const navigateCompanyListIsAdviser = (
  navigation: any,
  callback: () => void
) => {
  if (callback) {
    callback()
  }

  navigation.reset({
    index: 0,
    routes: [
      {
        name: RouterNames.COMPANY,
        params: {
          initialRouteName: RouterNames.COMPANY_LIST_UCROPIT,
        },
      },
    ],
  })
}
