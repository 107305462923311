const PRODUCER_ADVISER_ENCOURAGED = [
  {
    title: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_18,
    style: true,
    menu: 'delegate',
  },
  {
    title: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_19,
    style: false,
    menu: 'unlink',
  },
]

const MARKETER_PROVIDER = [
  {
    title: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_19,
    style: false,
    menu: 'unlink',
  },
]

const KAM = [
  {
    title: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_18,
    style: true,
    menu: 'delegate',
  },
  {
    title: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_19,
    style: false,
    menu: 'unlink',
  },
]
const VERIFIER = [
  {
    title: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_18,
    style: true,
    menu: 'delegate',
  },
  {
    title: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_19,
    style: false,
    menu: 'unlink',
  },
]

const MENU_COLLABORATOR = {
  PRODUCER_ADVISER: PRODUCER_ADVISER_ENCOURAGED,
  PRODUCER_ADVISER_ENCOURAGED: PRODUCER_ADVISER_ENCOURAGED,
  PRODUCER: PRODUCER_ADVISER_ENCOURAGED,
  PROVIDER: MARKETER_PROVIDER,
  MARKETER: MARKETER_PROVIDER,
  CAM: KAM,
  KAM: KAM,
  VERIFIER,
}

const MENU_COLLABORATOR_NEW = {
  ucropit_adviser: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_13,
  crop_responsible: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_12,
  crop_marketer: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_14,
  verifier_responsible: (t) => t('VIEWS').CROP_COLLABORATOR.TEXT_34,
}

export { MENU_COLLABORATOR, MENU_COLLABORATOR_NEW }
