import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, ScrollView, Platform } from 'react-native'
import { PropTypes } from 'prop-types'

import {
  Layout,
  ActivityIndicator,
  CompanyDetails,
} from '@modules/common/components'
import { useCompanyInfo } from '@modules/common/hooks'
import useModal from '@hooks/useModal'
import {
  FieldDetails,
  RemoveFieldButton,
  CantRemoveReasonToast,
  DisassociateFieldModal,
} from './components'
import { useFetchData, useDisassociateField } from './hooks'
import { primary500 } from '@styles/palette'

const CropFarmFieldDetails = ({ route, navigation }) => {
  const { cropId, fieldId } = useRef(route.params).current

  const [isViewReady, setIsViewReady] = useState(false)

  const { identifier: companyIdentifier, companyName } = useCompanyInfo()

  const {
    isLoading: isFetchDataLoading,
    fieldDetails: {
      farmUuid,
      fieldUuid,
      fieldNameLabel,
      surfaceLabel,
      farmNameLabel,
      locationLabel,
      cropTypeNameLabel,
      campaignLabel,
      fieldImage,
      canRemoveField,
      cantRemoveFieldReason,
    },
  } = useFetchData({ cropId, fieldId })

  const { isLoading: isDisassociateFieldLoading, onDisassociateField } =
    useDisassociateField({ cropId, fieldId })

  const {
    isModalVisible: isModalDisassociateFieldVisible,
    toggleModal: displayToggleModalDisassociateField,
    closeModal: closeModalDisassociateField,
  } = useModal()

  useEffect(() => {
    if (isFetchDataLoading) {
      return
    }

    setIsViewReady(true)
  }, [isFetchDataLoading])

  const goToEditLotName = () => {
    navigation.navigate('FarmLotEditName', {
      cropId,
      farmUuid,
      lotUuid: fieldUuid,
    })
  }

  if (!isViewReady) {
    return <ActivityIndicator size='large' color={primary500} />
  }

  return (
    <Layout>
      <ScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
      >
        <CompanyDetails
          companyName={companyName}
          companyIdentifier={companyIdentifier}
          style={styles.companyDetails}
        />

        <FieldDetails
          fieldNameLabel={fieldNameLabel}
          surfaceLabel={surfaceLabel}
          farmNameLabel={farmNameLabel}
          locationLabel={locationLabel}
          cropTypeNameLabel={cropTypeNameLabel}
          campaignLabel={campaignLabel}
          fieldImage={fieldImage}
          goToEditLotName={goToEditLotName}
        />

        <RemoveFieldButton
          showButton={Boolean(canRemoveField)}
          onSubmit={displayToggleModalDisassociateField}
          isLoading={isDisassociateFieldLoading}
        />

        <CantRemoveReasonToast
          showToast={Boolean(!canRemoveField)}
          reason={cantRemoveFieldReason}
        />
      </ScrollView>

      <DisassociateFieldModal
        visible={isModalDisassociateFieldVisible}
        onClose={closeModalDisassociateField}
        onConfirm={onDisassociateField}
      />
    </Layout>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  companyDetails: {
    margin: 0,
  },
})

CropFarmFieldDetails.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      cropId: PropTypes.string.isRequired,
      fieldId: PropTypes.string.isRequired,
    }),
  }),
}

export default CropFarmFieldDetails
