import { FC, ReactElement, useContext } from 'react'
import { Platform } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'

import { LanguageContext } from '@contextState/language'
import RouterNames from 'src/constants/routerNames'
import Header from 'src/components/common/Header'
import SettingsScreen from '@modules/settings/screens/SettingsScreen/v1'
import DeleteAccount from '@modules/settings/screens/DeleteAccount/v1'
import Languages from '@modules/settings/screens/Languages/v1'

const Stack = createStackNavigator()

export const SettingsStack: FC<any> = (): ReactElement | null => {
  const { t } = useContext(LanguageContext)

  const stacks = [
    {
      name: RouterNames.SETTINGS_LIST,
      component: SettingsScreen,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.SETTINGS'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.SETTINGS')}
            drawer={Platform.OS !== 'web'}
          />
        ),
      },
    },
    {
      name: 'Languages',
      component: Languages,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.LANGUAGES'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.LANGUAGES')}
            goBack
          />
        ),
      },
    },
    {
      name: 'DeleteAccount',
      component: DeleteAccount,
      options: {
        title: t('NAVIGATION.STACK_NAVIGATOR.DELETE_ACCOUNT'),
        header: (props: any) => (
          <Header
            {...props}
            title={t('NAVIGATION.STACK_NAVIGATOR.DELETE_ACCOUNT')}
            goBack
          />
        ),
      },
    },
  ]

  return (
    <Stack.Navigator initialRouteName={RouterNames.SETTINGS_LIST}>
      {stacks.map((stack, key) => (
        <Stack.Screen {...stack} key={`SETTINGS_STACK_${key}`} />
      ))}
    </Stack.Navigator>
  )
}
