export default {
  TEXT_1: 'Agregar evidencia',
  TEXT_2: 'Campo requerido',
  TEXT_3: 'Concepto',
  TEXT_4: 'Fecha de realización',
  TEXT_5: 'Foto dentro del Lote',
  TEXT_6: 'SELECCIONAR ARCHIVO',
  TEXT_7: 'CANCELAR',
  TEXT_8: 'AGREGAR',
}
