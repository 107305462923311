import { Platform, Linking } from 'react-native'
import Constants from 'expo-constants'

const apiUrl = Constants.expoConfig.extra.EXPO_HOST || ''

export const onPressEvidenceFile = (evidence) => {
  let url

  if (evidence.persisted) {
    url = evidence.file
  } else {
    if (evidence.isSatelliteImage) {
      url = evidence.imageSatellite
    } else if (evidence.path) {
      if (evidence.path.indexOf('http') !== -1) {
        url = evidence.path
      } else {
        url = `${apiUrl}/${evidence.path}`
      }
    }
  }

  if (!url) {
    return
  }

  Platform.OS === 'web' ? window.open(url, '_blank') : Linking.openURL(url)
}
