/**
 *
 * @param {string} farmName
 * @returns {object}
 */
export const getFormikInitialValues = (
  valueCodeArea = {
    value: '',
    label: '',
  },
  email = '',
  firstName = '',
  lastName = '',
  phoneNumber = ''
) => {
  return {
    valueCodeArea,
    email,
    firstName,
    lastName,
    phoneNumber,
  }
}
