import React from 'react'
import { StyleSheet, FlatList } from 'react-native'
import { PropTypes } from 'prop-types'

import { FieldItem } from '../'

export const FieldList = ({ onPressFieldDetails, fields, index }) => {
  const fieldRenderItem = ({ item: field }) => (
    <FieldItem field={field} onPressFieldDetails={onPressFieldDetails} />
  )

  return (
    <FlatList
      data={fields}
      style={styles.fieldsContainer}
      renderItem={fieldRenderItem}
      keyExtractor={(item, subIndex) => `FARM_${index}_FIELD_${subIndex}`}
    />
  )
}

const styles = StyleSheet.create({
  fieldsContainer: {
    paddingVertical: 10,
  },
})

FieldList.propTypes = {
  onPressFieldDetails: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
}
