import React from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'
import { largeSizeLoader } from '@constants/loaders'
import { primary500 } from '@styles/palette'

const LoaderCenterContainer = ({ size = largeSizeLoader }) => {
  return (
    <View style={styles.indicatorContainer}>
      <ActivityIndicator animating color={primary500} size={size} />
    </View>
  )
}

const styles = StyleSheet.create({
  indicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default LoaderCenterContainer
