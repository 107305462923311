export default {
  TEXT_1:
    'Before adding a collaborator you have to add at least one piece of evidence',
  TEXT_2: 'Select at least one Field',
  TEXT_3: 'Add evidence',
  TEXT_4: 'Save',
  TEXT_5: 'Sign',
  TEXT_6: 'Signed',
  TEXT_7: 'Sign',
}
