export default {
  TEXT_1: 'No tiene autorización para exportar el reporte',
  TEXT_2: 'El archivo KMZ tiene un fromato invalido para un lote',
  TEXT_3: 'Archivos de evidencias tienen extensiones no permitidas',
  TEXT_4: 'Servicio de tercero ya esta integrado en la empresa',
  TEXT_5: 'La compañía ya posee un servicio de integración',
  TEXT_6: 'La compañía no se encuentra integrada',
  TEXT_7: 'El lote ya ha sido exportado',
  TEXT_8: 'Error al intentar exportar los datos',
  TEXT_9: 'Las credenciales son inválidas',
  TEXT_10: 'El archivo KMZ tiene un fromato invalido para un lote.',
  TEXT_11: 'Hay lotes con mismo Nombre, debe modificarlos para continuar',
  TEXT_12: 'Algunos campos obligatorios están vacíos',
  TEXT_13: 'Alguna fecha del crop es inválida',
  TEXT_14: 'El conjunto de archivos enviados son inválidos',
  TEXT_15: 'Lotes no disponibles',
  TEXT_16: 'El conjunto de lotes enviados son inválidos',
  TEXT_17: 'Los datos enviados son inválidos',
  TEXT_18: 'No puede desvincular colaborador',
}
