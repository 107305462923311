import { enumUnitArea } from '@common/utils/enumUnitArea'

export const SURFACE_MAXIMUM_ALLOWED_IN_HA = 50000
export const SURFACE_MAXIMUM_ALLOWED_IN_AC = 123552.69

export const getMaximumSurfaceAllowed = (unit?: string) => {
  return enumUnitArea.ACRE === unit
    ? SURFACE_MAXIMUM_ALLOWED_IN_AC
    : SURFACE_MAXIMUM_ALLOWED_IN_HA
}

export const surfaceIsLessThanMaximumAllowed = (
  surface: number,
  surfaceMax: number
) => {
  return surface < surfaceMax
}
