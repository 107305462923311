import FORM_SIGN_UP from '../../components/FormSignUp/translate/es'
export default {
  WELCOME: 'Bienvenido a ucrop.it',
  INFO_WELCOME:
    'Completa tus datos, enviaremos un código de acceso a tu email para finalizar la creación de tu cuenta.',
  CONTINUE: 'Continuar',
  COMPONENTS: {
    FORM_SIGN_UP,
  },
}
