export default {
  TEXT_1: 'Planting',
  TEXT_2: 'Harvest',
  TEXT_3: 'Tillage',
  TEXT_4: 'Apply',
  TEXT_5: 'Fertilization',
  TEXT_6: 'Monitoring',
  TEXT_7: 'Download report in pdf',
  TEXT_8: 'Legal use of land',
  TEXT_9: 'ha',
  TEXT_10: 'Sustainable crops',
  TEXT_11: 'Loaded Fields',
  TEXT_12: 'Insufficient information',
  TEXT_13: 'Total expected volume',
  TEXT_14: 'Metric Tons',
  TEXT_15: 'activities',
  TEXT_16: 'Validated contracts',
}
