import CARD_CROP_DETAIL from './CardCropDetail'
import ACTIVITIES_DETAILS_CARD from './ActivitiesDetailsCard'
import SECTION_LANDSCAPE_SUSTAINABILITY from './section-landscape-sustainability'
import LOT_DETAIL_CARD from './LotDetailCard'

export default {
  CARD_CROP_DETAIL,
  ACTIVITIES_DETAILS_CARD,
  SECTION_LANDSCAPE_SUSTAINABILITY,
  LOT_DETAIL_CARD,
}
