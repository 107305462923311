import React from 'react'
import { StyleSheet } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { PropTypes } from 'prop-types'

export const Layout = ({ style = {}, children }) => {
  return (
    <KeyboardAwareScrollView
      extraHeight={50}
      enableOnAndroid
      alwaysBounceVertical={false}
      contentContainerStyle={[styles.container, style]}
    >
      {children}
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

Layout.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
