export const parseDataToEdit = (values) => {
  const data = {
    ...values,
    typePerson: values.typePerson.value,
    addressFloor: values.specificAddress,
  }

  data.evidences = values.files
    .filter((el) => !el.persisted)
    .map((file) => ({
      name: file.name,
      description: file.description ?? '',
      date: file.date,
    }))

  const dataFiles = values.files.filter((el) => !el.persisted)
  const formData = new FormData()
  for (const file of dataFiles) {
    formData.append('files', file.file)
  }
  delete data.files
  formData.append('data', JSON.stringify(data))
  return formData
}
