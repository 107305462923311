import React from 'react'
import { StyleSheet } from 'react-native'
import { PropTypes } from 'prop-types'
import CardDefault from '@components/cards/CardDefault'
import { primary500 } from '@styles/palette'

export const ListAchievements = ({
  achievements,
  renderAchievement,
  renderHeaderAchievement,
}) => {
  return (
    <CardDefault extraStyle={styles.cardList}>
      {renderHeaderAchievement()}
      {achievements?.map((achievement) => renderAchievement(achievement))}
    </CardDefault>
  )
}

const styles = StyleSheet.create({
  cardList: {
    backgroundColor: primary500,
  },
})

ListAchievements.propTypes = {
  achievements: PropTypes.array.isRequired,
  renderAchievement: PropTypes.func.isRequired,
  renderHeaderAchievement: PropTypes.func.isRequired,
}
