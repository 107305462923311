import { View, Text, StyleSheet, Platform, Image } from 'react-native'
import React, { useContext } from 'react'
import { ItemSelectionContentFieldProps } from './ItemSelectionContenField.props'
import { CommonIcon } from '@common/components/icons'
import { SIZES } from '@common/components/icons/interfaces'
import { blackHighEmphasis, gray12, primary500 } from '@styles/palette'
import { LanguageContext } from '@contextState/language'
import { formatSurface } from '@common/utils'
import { gray500 } from '@common/styles'
import CachedImage from '@components/common/CachedImage'

export const ItemSelectionContentField = ({
  item: {
    selected,
    disabled,
    name,
    cityName,
    provinceName,
    surface,
    areaUnit,
    imageUrl,
    disabledUp
  },
  onPress,
}: ItemSelectionContentFieldProps) => {
  const { t } = useContext(LanguageContext)
  return (
    <View style={[styles.content, disabled || disabledUp ? styles.disabled : {}]}>
      <View>
        <CommonIcon
          name={selected ? 'CHECKBOX-SELECTED' : 'CHECKBOX-UNSELECTED'}
          size={SIZES.MEDIUM}
          color={selected && !disabled && !disabledUp ? primary500 : blackHighEmphasis}
          onPress={!disabled && !disabledUp && onPress}
        />
      </View>
      <View style={styles.contentInfo}>
        <View>
          <Text style={styles.name}>{name}</Text>
          <Text style={styles.textInfo}>{`${cityName} ${
            provinceName && `, ${provinceName}`
          }`}</Text>
          <Text style={styles.textInfo}>
            {`${t(
              'VIEWS.ACTIVITY_CREATE_CONFIRM.COMPONENTS.ACTIVITY_CARD.SURFACE_TITLE'
            )} ${formatSurface(surface, areaUnit)}`}
          </Text>
        </View>
        <View>
          {Platform.OS === 'web' ? (
            <Image
              source={{
                uri: imageUrl,
              }}
              style={styles.lotImage}
            />
          ) : imageUrl ? (
            <CachedImage
              source={{
                uri: imageUrl,
              }}
              style={styles.lotImage}
              isBackground={undefined}
              children={undefined}
            />
          ) : (
            false
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingTop: 8,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: gray12,
  },
  contentInfo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 32,
  },
  name: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 24,
  },
  textInfo: {
    color: gray500,
    lineHeight: 20,
    fontSize: 14,
  },
  lotImage: {
    height: 60,
    width: 100,
  },
  disabled: {
    opacity: 0.5,
  },
})
