import React, { useEffect, useRef, useState, useContext } from 'react'
import {
  View,
  Text,
  SafeAreaView,
  Modal as ModalMobile,
  StyleSheet,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from 'react-native'
import PinView from 'react-native-pin-view'
import ModalWeb from 'modal-react-native-web'

import { CommonIcon } from '@modules/common/components'
import { white } from '@modules/common/styles'
import { LanguageContext } from '@contextState/language'
import useValidatePin from '@hooks/useValidatePin'

/**
 * @deprecated
 * @use src/modules/common/components/pin/VerifyPin
 * */
const VerifyPinModal = ({
  isModalVisible,
  toggleModal,
  onSuccess,
  externalLoading,
}) => {
  const { loading, setVerifyPin } = useValidatePin()
  const Modal = Platform.OS === 'web' ? ModalWeb : ModalMobile
  const pinView = useRef(null)
  const [pinCode, setPinCode] = useState('')
  const [isValidating, setIsValidating] = useState(false)
  const [error, setError] = useState(false)
  const { t } = useContext(LanguageContext)

  const verifyPin = async () => {
    setIsValidating(true)
    try {
      const { validate, tokenPin } = await setVerifyPin(pinCode)
      if (!validate) {
        setError(true)
        setIsValidating(false)
      } else {
        if (onSuccess) {
          onSuccess(tokenPin)
        }
      }
    } catch (error) {
      setError(true)
      setIsValidating(false)
    }
    pinView.current.clearAll()
    setPinCode('')
  }

  useEffect(() => {
    if (isValidating) {
      return
    }

    if (pinCode.length === 4) {
      verifyPin()
    } else if (error && pinCode > 0) {
      setError(false)
    }
  }, [isValidating, pinCode])

  return (
    <Modal
      animationType='slide'
      transparent={false}
      visible={isModalVisible}
      ariaHideApp={false}
    >
      <SafeAreaView style={styles.contentPin}>
        <View style={styles.contentText}>
          <Text style={styles.textStyle}>
            {t('COMPONENTS').MODAL.VERIFY_PIN_MODAL.TEXT_1}
          </Text>
          {error && (
            <Text style={styles.errorPin}>
              {t('COMPONENTS').MODAL.VERIFY_PIN_MODAL.TEXT_2}
            </Text>
          )}
        </View>
        <PinView
          pinLength={4}
          ref={pinView}
          inputSize={12}
          buttonSize={60}
          onValueChange={(value) => !isValidating && setPinCode(value)}
          inputViewEmptyStyle={styles.inputViewEmptyStyle}
          inputViewFilledStyle={styles.inputViewFilledStyle}
          buttonViewStyle={styles.buttonViewStyle}
          buttonTextStyle={styles.buttonTextStyle}
          customRightButton={
            pinCode.length > 0 &&
            !loading &&
            !externalLoading && (
              <TouchableOpacity
                onPress={() => {
                  pinView.current.clear()
                }}
              >
                <CommonIcon name={'ARROW-LEFT'} size={30} color={white} />
              </TouchableOpacity>
            )
          }
          customLeftButton={
            !loading &&
            !externalLoading && (
              <TouchableOpacity
                onPress={toggleModal}
                style={styles.contentLeftButton}
              >
                <Text style={styles.leftButton}>
                  {t('COMPONENTS').MODAL.VERIFY_PIN_MODAL.TEXT_3}
                </Text>
              </TouchableOpacity>
            )
          }
        />
        {(loading || externalLoading) && (
          <ActivityIndicator size='large' color='#fff' />
        )}
      </SafeAreaView>
    </Modal>
  )
}

const styles = StyleSheet.create({
  contentPin: {
    flex: 1,
    backgroundColor: '#81C784',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  contentText: {
    marginBottom: 70,
    alignItems: 'center',
  },
  textStyle: {
    color: '#fff',
    fontSize: 34,
  },
  errorPin: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#fff',
    marginTop: 15,
  },
  inputViewEmptyStyle: {
    backgroundColor: 'rgba(0,0,0,0.12)',
  },
  inputViewFilledStyle: {
    backgroundColor: '#FFF',
  },
  buttonViewStyle: {
    backgroundColor: 'rgba(0,0,0,0.12)',
  },
  buttonTextStyle: {
    fontSize: 32,
    color: '#fff',
    fontWeight: '100',
  },
  leftButton: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
  },
  contentLeftButton: {
    width: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default VerifyPinModal
