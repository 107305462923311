import { useEffect, useState } from 'react'
import Constants from 'expo-constants'

import AsyncStorage from '@react-native-async-storage/async-storage'
const EXPORTER_CIRCUIT_URL =
  Constants.expoConfig.extra.EXPORTER_CIRCUIT_URL ?? ''
const EXPORTER_CIRCUIT_TOKEN =
  Constants.expoConfig.extra.EXPORTER_CIRCUIT_TOKEN ?? ''

export const useExporterCircuit = () => {
  const [url, setUrl] = useState<string | null>(null)
  const [isReady, setIsReady] = useState<boolean>(false)
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      const token = await AsyncStorage.getItem('USER_TOKEN')
      setAccessToken(token)
    })()
  }, [])

  useEffect(() => {
    fetchWebViewUrl()
  }, [accessToken])

  const fetchWebViewUrl = async () => {
    if (!accessToken) return null

    const params = {
      accessToken,
      isApp: 'true',
      tokenFromApp: EXPORTER_CIRCUIT_TOKEN,
      date: `${new Date().getTime()}`,
    }

    const searchParams = new URLSearchParams(params)
    const queryParams = searchParams.toString()

    setUrl(`${EXPORTER_CIRCUIT_URL}?${queryParams}`)

    setIsReady(true)
  }

  return {
    url,
    isReady,
  }
}
